import * as React from 'react';
import { Divider, Stack } from '@mui/material';
import PInput from '../../../components/library/PInput'; // Assuming correct path
import PButton from '../../../components/library/PButton';
import PTypography from '@/components/library/PTypography';
import { setBccAndSignature } from '@/helper/apiHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setUser as setAuthSliceUser } from '@/redux/authSlice';

const headerStyle = {
  paddingLeft: '40px',
  paddingRight: '40px',
  paddingTop: '14px',
  paddingBottom: '14px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const contentStyle = {
  padding: '40px',
  maxWidth: '560px',
};

export default function Email() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [emailSignature, setEmailSignature] = React.useState(user?.emailSignature || '');
  const [sequenceBcc, setSequenceBcc] = React.useState(user?.sequenceBcc || '');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleEmailSignatureChange = (event) => {
    setEmailSignature(event.target.value);
  };

  const handleSequenceBccChange = (event) => {
    setSequenceBcc(event.target.value);
  };

  const saveEmailSettings = async () => {
    setIsLoading(true);

    try {
      const successful = await setBccAndSignature({ emailSignature, sequenceBcc });
      if (successful) {
        dispatch(
          setAuthSliceUser({
            ...user,
            emailSignature,
            sequenceBcc,
          }),
        );
      } else {
        throw new Error('Failed to update settings');
      }
    } catch (error) {
      // Show error toast
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div style={headerStyle}>
        <PTypography size="h1" weight="regular">
          Email
        </PTypography>
        <PButton onClick={saveEmailSettings}>Save</PButton>
      </div>

      <Divider />

      <Stack gap="20px" style={contentStyle}>
        <Stack gap="8px">
          <PTypography size="body2" weight="bold">
            Email signature
          </PTypography>
          <PInput
            multiline
            rows={7}
            value={emailSignature}
            onChange={handleEmailSignatureChange}
            // "Your Name\nTitle\nCompany" does not work, it renders the \n as text
            placeholder="Your Name&#13;&#10;Title&#13;&#10;Company&#13;&#10;Website"
          />
        </Stack>

        <Stack gap="8px">
          <PTypography size="body2" weight="bold">
            Email address for CRM updates via BCC
          </PTypography>
          <PInput value={sequenceBcc} onChange={handleSequenceBccChange} placeholder="name@email.com" />
        </Stack>
      </Stack>
    </div>
  );
}
