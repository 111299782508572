import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { TextField, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { useSelector, useDispatch } from "react-redux";
import { setUserBio, setUserScrapedBio } from "@/redux/authSlice";
import MessageLoader from "@/components/messageLoader";

import Http from "@/http/httpClient";

import { ReactComponent as Back } from "@/assets/Icons/Arrow-Left.svg";
import { Stack } from "@mui/system";

export default function BioModal(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { onClose, open, setupList } = props;
  const nameInputRef = React.useRef(null);
  const benefitsInputRef = React.useRef(null);
  const descInputRef = React.useRef(null);
  const [name, setName] = React.useState("");
  const [benefits, setBenefits] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const bio = useSelector((state) => state.auth.userBio);
  const companyWebsite = useSelector(
    (state) => state.auth.userCompanyWebsite?.companyWebsite
  );
  const userScrapedBio = useSelector(
    (state) => state.auth.userScrapedBio?.response
  );

  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  React.useEffect(() => {
    const populateBio = async () => {
      if (userScrapedBio && Object.keys(userScrapedBio).length !== 0) {
        setName(userScrapedBio.companyName || "");
        setDesc(userScrapedBio.product || "");
        const benefitsString = Array.isArray(userScrapedBio.customerBenefits) ? userScrapedBio.customerBenefits.join(", ") : userScrapedBio.customerBenefits || "";
        setBenefits(benefitsString);
        dispatch(
          setUserBio({
            companyName: userScrapedBio.companyName || "",
            product: userScrapedBio.product || "",
            customerBenefits: benefitsString,
          })
        );
      } else {
        const response = await Http.getData("setup/getBio");
        setName(response.bio.companyName);
        setDesc(response.bio.product);
        const benefitsString = Array.isArray(response.bio.customerBenefits) ? response.bio.customerBenefits.join(", ") : response.bio.customerBenefits;
        setBenefits(benefitsString);
        dispatch(
          setUserBio({
            companyName: response.bio.companyName,
            product: response.bio.product,
            customerBenefits: benefitsString,
          })
        );
      }
    };

    populateBio();
  }, [dispatch, userScrapedBio]);
  
  React.useEffect(() => {
    companyWebsite && updateWebsiteUrl();
  }, [companyWebsite]);

  const handleContinue = async () => {
    if (
      name.trim().length === 0 ||
      desc.trim().length === 0 ||
      benefits.trim().length === 0
    ) {
      setAlertMessage("Please fill all the required fields.");
      setShowAlert(true);
      return;
    }

    await updateBio();
    handleClose();
  };

  const updateWebsiteUrl = async () => {
    setIsLoading(true);
    try {
      Http.postData("setup/parseBioFromURL", { url: companyWebsite })
        .then(async (response) => {
          dispatch(setUserScrapedBio({ response }));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const updateBio = async () => {
    try {
      Http.postData("setup/setBio", { bio })
        .then(async (response) => {
          if (response && response.status) {
            // handle if anything required
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullScreen>
        <DialogTitle sx={{ p: 0 }}>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ pt: 2, pl: 2 }}
              >
                <Back fill={theme.palette.primary.black} />
              </IconButton>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={10} sx={{ my: 1.5 }}>
                {setupList.map((item) => (
                  <Stack justifyContent="center" alignItems="center">
                    <Avatar
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        bgcolor: item.active
                          ? theme.palette.primary.blue
                          : theme.palette.primary.white,
                        color: item.active
                          ? theme.palette.primary.white
                          : theme.palette.primary.grey,
                        border: item.active ? `none` : `1px solid #E0E0E0`,
                        my: 1,
                      }}
                    >
                      {item.index}
                    </Avatar>
                    <Typography
                      sx={{
                        color: item.active
                          ? theme.palette.primary.black
                          : theme.palette.primary.grey,
                        fontWeight: 500,
                      }}
                    >
                      {item.listText}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid item>
              <Button
                onClick={handleContinue}
                sx={{
                  maxWidth: "200px",
                  backgroundColor: theme.palette.primary.blue,
                  color: theme.palette.primary.white,
                  textTransform: "none",
                  width: "167px",
                  py: 1,
                  px: 2,
                  mr: 2,
                  mt: 2,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px 0px #2C64E31F",
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={0} md={3} />
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                  pt: 1,
                  lineHeight: "normal",
                  fontSize: {
                    xs: "28px",
                    md: "35px",
                  },
                }}
              >
                Tell us about yourself.
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: theme.palette.primary.grey,
                  pb: 5,
                }}
              >
                This will allow you to craft messages that feel more
                personalized.
              </Typography>

              {isLoading ? (
                <MessageLoader
                  sentences={[
                    "Finding your website...",
                    "Loading website content...",
                    "Processing your company information...",
                    "Generating with AI...",
                  ]}
                />
              ) : (
                <div>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Your company's name as seen by customers
                    <span style={{ color: theme.palette.primary.orange }}>
                      *
                    </span>
                  </Typography>
                  <Paper
                    component="form"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      boxShadow: 0,
                      mt: 1.5,
                      mb: 3,
                      p: 1,
                      border: `1px solid #E0E0E0`,
                      borderRadius: 4,
                      width: {
                        xs: "100%",
                        md: "90%",
                      },
                    }}
                  >
                    <InputBase
                      ref={nameInputRef}
                      type="text"
                      value={name}
                      placeholder="E.g, Acme inc."
                      onBlur={(e) => (e.target.placeholder = "E.g, Acme inc.")}
                      sx={{
                        ml: 1,
                        flex: 1,
                      }}
                      onChange={(event) => {
                        setName(event.target.value);
                        dispatch(
                          setUserBio({
                            companyName: event.target.value,
                            product: desc,
                            customerBenefits: benefits,
                          })
                        );
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          descInputRef.current.focus();
                        }
                      }}
                    />
                  </Paper>

                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Describe what your company does
                    <span style={{ color: theme.palette.primary.orange }}>
                      *
                    </span>
                  </Typography>

                  <Paper
                    component="form"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      boxShadow: 0,
                      mt: 1.5,
                      mb: 3,
                      p: 1,
                      border: `1px solid #E0E0E0`,
                      borderRadius: 4,
                      width: {
                        xs: "100%",
                        md: "90%",
                      },
                    }}
                  >
                    <TextField
                      inputRef={descInputRef}
                      value={desc}
                      multiline
                      rows={6}
                      type="text"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      placeholder="E.g, We provide expert advice and solutions for technology-related challenges"
                      onBlur={(e) =>
                        (e.target.placeholder =
                          "E.g, We provide expert advice and solutions for technology-related challenges")
                      }
                      sx={{ ml: 1, flex: 1 }}
                      onChange={(event) => {
                        setDesc(event.target.value);
                        dispatch(
                          setUserBio({
                            companyName: name,
                            product: event.target.value,
                            customerBenefits: benefits,
                          })
                        );
                      }}
                    />
                  </Paper>

                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      color: theme.palette.primary.black,
                    }}
                  >
                    List the benefits you offer potential customers
                    <span style={{ color: theme.palette.primary.orange }}>
                      *
                    </span>
                  </Typography>

                  <Paper
                    component="form"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      boxShadow: 0,
                      mt: 1.5,
                      mb: 3,
                      p: 1,
                      border: `1px solid #E0E0E0`,
                      borderRadius: 4,
                      width: {
                        xs: "100%",
                        md: "90%",
                      },
                    }}
                  >
                    <TextField
                      ref={benefitsInputRef}
                      value={benefits}
                      multiline
                      rows={6}
                      type="text"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      placeholder="E.g, Recommendations for smoother operations, bring efficiency and innovation to your technology strategies."
                      onBlur={(e) =>
                        (e.target.placeholder =
                          "E.g, Recommendations for smoother operations, bring efficiency and innovation to your technology strategies.")
                      }
                      sx={{ ml: 1, flex: 1 }}
                      onChange={(event) => {
                        setBenefits(event.target.value);
                        dispatch(
                          setUserBio({
                            companyName: name,
                            product: desc,
                            customerBenefits: event.target.value,
                          })
                        );
                      }}
                    />
                  </Paper>
                </div>
              )}
            </Grid>
            <Grid item xs={0} md={3} />
          </Grid>
        </DialogContent>
      </Dialog>
      {alertMessage && (
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
