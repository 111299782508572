/**
 * This context provider is used to display Snackbar messages to the user.
 *
 * It allows queueing multiple messages and displaying them sequentially,
 * solving the issue of multiple Snackbars overlapping, hiding each other.
 *
**/

import React from "react";
import PSnackbar from "../components/library/PSnackbar";

const SnackbarContext = React.createContext();

export const TRANSITION_TIME = 250; // ms
export const useSnackbar = () => React.useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbars, setSnackbars] = React.useState([]);
  const [snackbarClosing, setSnackbarClosing] = React.useState(false);

  const removeSnackbar = async (id) => {
    // Tell the Snackbar that it's closing.
    // This will trigger the transition out animation.
    setSnackbarClosing(true);

    // Wait for the transition animation to complete.
    await new Promise((resolve) => setTimeout(resolve, TRANSITION_TIME));

    // Delete the Snackbar object from the queue (and the DOM)
    setSnackbars((prevSnackbars) =>
      prevSnackbars.filter((snack) => snack.id !== id)
    );

    setSnackbarClosing(false);
  };

  // Adds a Snackbar to the queue
  const addSnackbar = ({
    message = "",
    pVariant = "default",
    duration = 5000,
    onClose = null,
    transitionType = "fade",
    hideSeverityIcon = false,
    hideAction = false,
    customAction = null,
  }) => {
    const newSnackbar = {
      id: Date.now(),
      message,
      pVariant,
      duration,
      transitionType,
      hideSeverityIcon,
      hideAction,
      customAction,

      // This is called when the user clicks the `X` button on the Snackbar,
      // or when the Snackbar's internal timeout is reached (based on `duration`)
      onClose: () => {
        // We have to make sure the Snackbar is removed from the queue.
        removeSnackbar(newSnackbar.id);

        // Handle callback passed down by parent component
        onClose && onClose();
      },
    };
    setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
  };

  return (
    <SnackbarContext.Provider value={{ addSnackbar }}>
      {children}
      {snackbars.length > 0 && (
        <PSnackbar
          key={snackbars[0].id}
          open={!snackbarClosing} // We want this to always be true, except when transitioning out
          message={snackbars[0].message}
          pVariant={snackbars[0].pVariant}
          duration={snackbars[0].duration} // calls onClose after duration
          onClose={snackbars[0].onClose}
          transitionType={snackbars[0].transitionType}
          hideSeverityIcon={snackbars[0].hideSeverityIcon}
          hideAction={snackbars[0].hideAction}
          customAction={snackbars[0].customAction}
        />
      )}
    </SnackbarContext.Provider>
  );
};
