'use client';

import { useState, useMemo, useRef, useEffect } from 'react';
import {
  Mail,
  Linkedin,
  Calendar,
  ArrowUpRight,
  ArrowDownLeft,
  ChevronUp,
  ChevronDown,
  Search,
  Plus,
} from 'lucide-react';

import { Button, Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter,
  Input, Badge, Checkbox, Textarea, Select, SelectContent, SelectItem, SelectTrigger, 
  SelectValue, Label } from '@/components/ui';
import { useToast } from '@Hooks/use-toast.tsx';
import {
  getContactInteractions,
  getCompanyClients,
  getCompanyRelationships,
  updateClientStage,
} from '@/helper/apiHelper';
import { CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDate, compareDates, getMostRecentDate, isValidDate } from '@/helper/utilities';

const SortableHeader = ({ label, sortKey, currentSort, onSort, style }) => (
  <th
    className="h-10 px-2 text-left align-middle font-medium text-muted-foreground cursor-pointer hover:bg-gray-100"
    onClick={() => onSort(sortKey)}
    style={style}
  >
    <div className="flex items-center gap-1">
      {label}
      {currentSort?.key === sortKey &&
        (currentSort?.direction === 'asc' ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />)}
    </div>
  </th>
);

const getStageBadge = (stage) => {
  const colorMap = {
    'lead': 'bg-blue-100 text-blue-800',
    'positive meeting': 'bg-yellow-100 text-yellow-800',
    'signed client': 'bg-green-100 text-green-800',
    'lost': 'bg-red-100 text-red-800',
  };
  return <Badge className={`${colorMap[stage] || 'bg-gray-100 text-gray-800'} font-semibold`}>{stage}</Badge>;
};

const getSortValue = (obj: any, key: string) => {
  const value = obj?.[key];
  if (typeof value === 'string') return value.toLowerCase();
  if (typeof value === 'number') return value;
  return '';
};

const getContactId = (relationship: any) => {
  if (!relationship?.contacts || !Array.isArray(relationship.contacts)) {
    return null;
  }
  const contact = relationship.contacts[0];
  return contact?._id || null;
};

const isValidClient = (client: any): boolean => {
  return (
    client &&
    typeof client === 'object' &&
    typeof client._id === 'string' &&
    typeof client.name === 'string'
  );
};

const isValidRelationship = (relationship: any): boolean => {
  return (
    relationship &&
    typeof relationship === 'object' &&
    Array.isArray(relationship.contacts) &&
    relationship.contacts.length > 0
  );
};

export default function ERMDashboard() {
  const availableStages = ['lead', 'positive meeting', 'signed client', 'lost'];

  const [selectedClient, setSelectedClient] = useState(null);
  const [clientSortCriteria, setClientSortCriteria] = useState({ key: 'name', direction: 'asc' });
  const [relationshipSortCriteria, setRelationshipSortCriteria] = useState({ key: 'name', direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStages, setSelectedStages] = useState(availableStages);
  const [composeOpen, setComposeOpen] = useState(false);
  const [emailDraft, setEmailDraft] = useState('');
  const [selectedSender, setSelectedSender] = useState('me');
  const [messageType, setMessageType] = useState('email');
  const [notesOpen, setNotesOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newNote, setNewNote] = useState('');
  const relationshipsRef = useRef(null);
  const { toast } = useToast();
  const [companyClients, setCompanyClients] = useState(null);
  const [contactInteractions, setContactInteractions] = useState([]);
  const [contactId, setContactId] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [relatedEmployees, setRelatedEmployees] = useState([]);
  const [clientRelationshipsCache, setClientRelationshipsCache] = useState({});
  const [isLoadingRelationships, setIsLoadingRelationships] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [editingStageClientId, setEditingStageClientId] = useState(null);
  const [loadingStages, setLoadingStages] = useState({});

  const user = useSelector((state) => state.auth?.user);
  const teamMembers = useSelector((state) => state.auth?.company?.users);

  const getLastInteraction = (interactionObj) => {
    if (!interactionObj) return null;
    
    if (!interactionObj || 
        (!isValidDate(interactionObj.lastOutgoingInteraction?.actionDate) && 
         !isValidDate(interactionObj.lastIncomingInteraction?.actionDate))) return null;
    
    const mostRecentDate = getMostRecentDate(interactionObj.lastOutgoingInteraction?.actionDate, 
                                           interactionObj.lastIncomingInteraction?.actionDate);

    return mostRecentDate === interactionObj.lastOutgoingInteraction?.actionDate 
           ? interactionObj.lastOutgoingInteraction 
           : interactionObj.lastIncomingInteraction;
  };

  useEffect(() => {
    setIsLoadingClients(true);
    getCompanyClients()
      .then((data) => {
        const augmentedClients = (data?.clients || [])
          .filter(isValidClient)
          .map((client) => {
            const lastInteraction = getLastInteraction(client);
            const stage = (client?.stage && availableStages.includes(client.stage)) 
              ? client.stage 
              : availableStages[0];
            return { 
              ...client,
              name: client?.name || 'Unnamed Client',
              lastInteraction, 
              stage 
            };
          });
        setCompanyClients(augmentedClients);
      })
      .catch((error) => {
        console.error('Error fetching clients:', error);
        toast({
          title: 'Error',
          description: 'Failed to load clients',
        });
      })
      .finally(() => {
        setIsLoadingClients(false);
      });
  }, [user?.companyId]);

  const filteredAndSortedClients = useMemo(() => {
    if (!Array.isArray(companyClients)) return [];

    return [...companyClients]
      .filter(isValidClient)
      .filter((client) =>
        client?.name?.toLowerCase().includes(searchTerm?.toLowerCase() || '') &&
        (!client?.stage || selectedStages?.includes(client?.stage))
      )
      .sort((clientA, clientB) => {
        if (clientSortCriteria?.key === 'lastInteraction') {
          return compareDates(
            clientA?.lastInteraction?.actionDate,
            clientB?.lastInteraction?.actionDate,
            clientSortCriteria?.direction
          );
        }
        if (clientA[clientSortCriteria?.key] < clientB[clientSortCriteria?.key])
          return clientSortCriteria?.direction === 'asc' ? -1 : 1;
        if (clientA[clientSortCriteria?.key] > clientB[clientSortCriteria?.key])
          return clientSortCriteria?.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [clientSortCriteria, searchTerm, selectedStages, companyClients]);

  const filteredAndSortedRelationships = useMemo(() => {
    if (!selectedClient || !clientRelationshipsCache[selectedClient?._id]) {
      return [];
    }

    const relationships = clientRelationshipsCache[selectedClient?._id];
    if (!Array.isArray(relationships)) return [];

    const mostRecentInteractionsMap = new Map();

    relationships
      .filter(isValidRelationship)
      .forEach((relationship) => {
        const contactId = getContactId(relationship);
        if (!contactId) return;

        const currentMostRecent = mostRecentInteractionsMap.get(contactId);
        const newInteractionDate = relationship?.lastInteraction?.actionDate;
        
        if (!currentMostRecent || compareDates(
          newInteractionDate,
          currentMostRecent?.lastInteraction?.actionDate,
          'desc'
        ) > 0) {
          mostRecentInteractionsMap.set(contactId, relationship);
        }
      });

    return Array.from(mostRecentInteractionsMap.values())
      .sort((a, b) => {
        if (relationshipSortCriteria?.key === 'lastInteraction') {
          return compareDates(
            a?.lastInteraction?.actionDate,
            b?.lastInteraction?.actionDate,
            relationshipSortCriteria?.direction
          );
        }
        return getSortValue(a, relationshipSortCriteria?.key) < getSortValue(b, relationshipSortCriteria?.key)
          ? (relationshipSortCriteria?.direction === 'asc' ? -1 : 1)
          : (relationshipSortCriteria?.direction === 'asc' ? 1 : -1);
      });
  }, [relationshipSortCriteria, selectedClient, clientRelationshipsCache]);

  const handleClientSort = (key) => {
    setClientSortCriteria((prev) => ({
      key,
      direction: prev?.key === key && prev?.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleRelationshipSort = (key) => {
    setRelationshipSortCriteria((prev) => ({
      key,
      direction: prev?.key === key && prev?.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const getInteractionIcon = (messagingPlatform) => {
    const validTypes = ['email', 'linkedin'];
    
    if (!messagingPlatform || !validTypes.includes(messagingPlatform)) {
      return null;
    }
    switch (messagingPlatform) {
      case 'email':
        return <Mail className="h-4 w-4 text-gray-500" />;
      case 'linkedin':
        return <Linkedin className="h-4 w-4 text-gray-500" />;
      default:
        return null;
    }
  };

  const getDirectionIcon = (direction) => {
    if (direction === 'incoming') {
      return <ArrowDownLeft className="h-4 w-4 text-green-500" />;
    } else if (direction === 'outgoing') {
      return <ArrowUpRight className="h-4 w-4 text-blue-500" />;
    }
    return null;
  };

  const getTierBadge = (tier) => {
    const colorMap = {
      'Tier 1': 'bg-green-100 text-green-800',
      'Tier 2': 'bg-yellow-100 text-yellow-800',
      'Tier 3': 'bg-red-100 text-red-800',
    };
    return <Badge className={`${colorMap[tier]} font-semibold`}>{tier}</Badge>;
  };

  const handleStageToggle = (stage) => {
    setSelectedStages((prev) => (prev?.includes(stage) ? prev?.filter((s) => s !== stage) : [...prev, stage]));
  };

  const handleSendEmail = () => {
    if (!selectedClient) {
      toast({
        title: 'Error',
        description: 'No client selected. Please select a client to send an email.',
      });
      return;
    }
    if (!clientRelationshipsCache[selectedClient?._id]) {
      return;
    }

    const selectedRelationship = clientRelationshipsCache[selectedClient?._id];

    toast({
      title: 'Email Sent',
      description: `Email sent to ${selectedRelationship?.name} from ${selectedSender === 'me' ? 'you' : selectedSender}`,
    });
    setComposeOpen(false);
  };

  const handleAddNote = () => {
    if (!selectedClient) {
      toast({
        title: 'Error',
        description: 'No client selected. Please select a client to add a note.',
      });
      return;
    }
    if (newNote?.trim() === '') return;

    setClientNotes((prev) => ({
      ...prev,
      [selectedClient?._id]: [
        {
          id: Date.now(),
          date: new Date().toISOString().split('T')[0],
          content: newNote,
          author: 'Current User',
        },
        ...(prev[selectedClient?._id] || []),
      ],
    }));

    setNewNote('');
    toast({
      title: 'Note Added',
      description: 'Your note has been successfully added.',
    });
  };

  const handleRemoveNote = (noteId) => {
    if (!selectedClient) {
      toast({
        title: 'Error',
        description: 'No client selected. Please select a client to remove a note.',
      });
      return;
    }
    setClientNotes((prev) => ({
      ...prev,
      [selectedClient?._id]: prev[selectedClient?._id]?.filter((note) => note?.id !== noteId),
    }));

    toast({
      title: 'Note Removed',
      description: 'The note has been successfully removed.',
    });
  };

  const notesPerPage = 5;
  // const paginatedNotes =
  //   clientNotes[selectedClient?.id]?.slice((currentPage - 1) * notesPerPage, currentPage * notesPerPage) || [];
  // const totalPages = Math.ceil((clientNotes[selectedClient?.id]?.length || 0) / notesPerPage);

  useEffect(() => {
    if (selectedClient && relationshipsRef?.current) {
      relationshipsRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedClient]);

  const handleInteractionsClick = async (relationship) => {
    try {
      const contactId = relationship?.contacts[0]?._id;
      const response = await getContactInteractions(
        contactId,
        1, // page
        100, // limit
        false, // successOnly
        false, // ownInteractionsOnly
      );
      setContactInteractions(response?.interactions);
    } catch (error) {
      console.error('Error fetching contact interactions:', error);
    }
  };

  const handleEmployeesClick = (relationship) => {
    const relatedUserIds = new Set();

    // Extract user IDs from interactions related to the selected relationship
    companyClientInteractions?.interactions?.forEach((interaction) => {
      interaction?.contacts?.forEach((contact) => {
        if (contact?._id === relationship?.id) {
          if (interaction?.lastOutgoingInteraction) {
            relatedUserIds.add(interaction?.lastOutgoingInteraction?.userId);
          }
          if (interaction?.lastIncomingInteraction) {
            relatedUserIds.add(interaction?.lastIncomingInteraction?.userId);
          }
        }
      });
    });

    // Filter internal colleagues who have interacted with the selected relationship
    const interactedEmployees = teamMembers?.filter((employee) => relatedUserIds.has(employee?._id));

    setRelatedEmployees(interactedEmployees);
  };

  const featureFlags = useSelector((state) => state.featureFlags);
  const ermDashboardStrengthFeature = featureFlags?.find((flag) => flag?.name === 'ui.erm_dashboard.strength');

  useEffect(() => {
    if (selectedClient && !clientRelationshipsCache[selectedClient?._id]) {
      setIsLoadingRelationships(true);
      getCompanyRelationships(selectedClient?._id)
        .then((response) => {
          const relationships = response?.relationships || [];
          const augmentedRelationships = relationships
            .filter(isValidRelationship)
            .map((relationship) => {
              const lastInteraction = getLastInteraction(relationship);
              return { 
                ...relationship, 
                lastInteraction,
                name: relationship?.contacts[0]?.name || 'Unknown Contact'
              };
            });

          setClientRelationshipsCache((prevCache) => ({
            ...prevCache,
            [selectedClient?._id]: augmentedRelationships,
          }));
        })
        .catch((error) => {
          console.error('Error fetching relationships:', error);
          toast({
            title: 'Error',
            description: 'Failed to load relationships',
          });
        })
        .finally(() => {
          setIsLoadingRelationships(false);
        });
    }
  }, [selectedClient, clientRelationshipsCache]);

  const handleStageChange = async (clientId?: string, newStage?: string) => {
    if (!clientId || !newStage || !availableStages.includes(newStage)) {
      toast({
        title: 'Error',
        description: 'Invalid stage or client ID provided',
      });
      return;
    }

    const client = companyClients?.find((c) => c?._id === clientId);
    if (!client) {
      toast({
        title: 'Error',
        description: 'Client not found',
      });
      return;
    }

    setLoadingStages((prev) => ({ ...prev, [clientId]: true }));

    try {
      const result = await updateClientStage(clientId, newStage);

      if (result?.success) {
        setCompanyClients((prevClients) =>
          prevClients?.map((c) => (c?._id === clientId ? { ...c, stage: newStage } : c)),
        );
        setEditingStageClientId(null);
      } else {
        throw new Error(result?.message);
      }
    } catch (error) {
      console.error('Error updating client stage:', error);
      toast({
        title: 'Error',
        description: 'Failed to update client stage. Reverting to previous stage.',
      });
    } finally {
      setLoadingStages((prev) => ({ ...prev, [clientId]: false }));
    }
  };

  const renderInteractionDate = (interaction) => {
    if (!interaction?.actionDate && !interaction?.user?.name) return 'No interaction recorded';

    const dateValue = interaction?.actionDate ? formatDate(interaction.actionDate) : '';
    const userValue = interaction?.user?.name ? <span className="text-muted-foreground ml-1">with {interaction.user.name}</span> : '';

    return (
      <>
        {dateValue}
        {userValue}
      </>
    );
  };

  return (
    <>
      <CssBaseline />
      <div className="flex flex-col">
        <header className="flex h-14 lg:h-[60px] items-center gap-4 border-b bg-gray-100/40 px-6">
          <div className="w-full flex-1">
            <h1 className="font-semibold text-lg">Clients</h1>
          </div>
        </header>
        <main className="flex flex-1 flex-col gap-2 p-2 md:gap-4 md:p-4">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-lg md:text-2xl">Client List</h1>
          </div>
          {isLoadingClients ? (
            <div className="flex justify-center items-center">
              <CircularProgress size={32} color="inherit" />
            </div>
          ) : (
            <div className="border shadow-sm rounded-lg">
              <div className="relative w-full overflow-auto">
                <table className="w-full caption-bottom text-sm">
                  <thead className="[&_tr]:border-b">
                    <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                      <SortableHeader
                        label="Client Name"
                        sortKey="name"
                        currentSort={clientSortCriteria}
                        onSort={handleClientSort}
                      />
                      <SortableHeader
                        label="Strength"
                        sortKey="strength"
                        currentSort={clientSortCriteria}
                        onSort={handleClientSort}
                        style={{ display: ermDashboardStrengthFeature?.isFeatureEnabled ? 'table-cell' : 'none' }}
                      />
                      <SortableHeader
                        label="Stage"
                        sortKey="stage"
                        currentSort={clientSortCriteria}
                        onSort={handleClientSort}
                      />
                      <SortableHeader
                        label="Last Interaction"
                        sortKey="lastInteraction"
                        currentSort={clientSortCriteria}
                        onSort={handleClientSort}
                      />
                      <th className="h-10 px-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="[&_tr:last-child]:border-0">
                    {filteredAndSortedClients?.map((client) => (
                      <tr
                        key={client?._id}
                        className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
                      >
                        <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                          <Button
                            variant="link"
                            onClick={() => {
                              setSelectedClient(client);
                            }}
                          >
                            {client?.name}
                          </Button>
                        </td>
                        <td
                          className="p-2 align-middle [&:has([role=checkbox])]:pr-0"
                          style={{ display: ermDashboardStrengthFeature?.isFeatureEnabled ? 'table-cell' : 'none' }}
                        >
                          {client?.strength}
                        </td>
                        <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                          <div className="relative">
                            <div onClick={() => setEditingStageClientId(client?._id)}>
                              {loadingStages[client?._id] ? (
                                <CircularProgress size={16} color="inherit" />
                              ) : (
                                getStageBadge(client?.stage)
                              )}
                            </div>
                            {editingStageClientId === client?._id && (
                              <div className="absolute z-10 mt-2 bg-white border rounded shadow-lg flex space-x-2 p-2">
                                {availableStages?.map((stage) => (
                                  <div
                                    key={stage}
                                    className="cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleStageChange(client?._id, stage)}
                                  >
                                    {getStageBadge(stage)}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                          <div className="flex items-center gap-2">
                            {getInteractionIcon(client?.lastInteraction?.messagingPlatform)}
                            {getDirectionIcon(client?.lastInteraction?.messagingTraffic)}
                            <span>
                              {renderInteractionDate(client?.lastInteraction)}
                            </span>
                          </div>
                        </td>
                        <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                          <Button size="sm" onClick={() => setSelectedClient(client)}>
                            Relationships
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {selectedClient && (
            <div
              ref={relationshipsRef}
              className="border shadow-sm rounded-lg mt-4 transition-all duration-300 ease-in-out"
            >
              <div className="p-2">
                <h2 className="font-semibold text-lg mb-2">Relationships for {selectedClient?.name}</h2>
                {isLoadingRelationships ? (
                  <div className="flex justify-center items-center">
                    <CircularProgress size={32} color="inherit" />
                  </div>
                ) : filteredAndSortedRelationships?.length > 0 ? (
                  <div className="relative w-full overflow-auto">
                    <table className="w-full caption-bottom text-sm">
                      <thead className="[&_tr]:border-b">
                        <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                          <SortableHeader
                            label="Name"
                            sortKey="name"
                            currentSort={relationshipSortCriteria}
                            onSort={handleRelationshipSort}
                          />
                          <SortableHeader
                            label="Position"
                            sortKey="position"
                            currentSort={relationshipSortCriteria}
                            onSort={handleRelationshipSort}
                          />
                          <SortableHeader
                            label="Strength"
                            sortKey="strength"
                            currentSort={relationshipSortCriteria}
                            onSort={handleRelationshipSort}
                            style={{ display: ermDashboardStrengthFeature?.isFeatureEnabled ? 'table-cell' : 'none' }}
                          />
                          <SortableHeader
                            label="Last Interaction"
                            sortKey="lastInteraction"
                            currentSort={relationshipSortCriteria}
                            onSort={handleRelationshipSort}
                          />
                          <th className="h-10 px-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="[&_tr:last-child]:border-0">
                        {filteredAndSortedRelationships?.map((relationship) => (
                          <tr
                            key={relationship?.id}
                            className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
                          >
                            <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                              {relationship?.contacts[0]?.name}
                            </td>
                            <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                              {relationship?.contacts[0]?.title}
                            </td>
                            <td
                              className="p-2 align-middle [&:has([role=checkbox])]:pr-0"
                              style={{ display: ermDashboardStrengthFeature?.isFeatureEnabled ? 'table-cell' : 'none' }}
                            >
                              {relationship?.strength}
                            </td>
                            <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                              <div className="flex items-center gap-2">
                                {getInteractionIcon(relationship?.lastInteraction?.messagingPlatform)}
                                {getDirectionIcon(relationship?.lastInteraction?.messagingTraffic)}
                                <span>
                                  {renderInteractionDate(relationship?.lastInteraction)}
                                </span>
                              </div>
                            </td>
                            <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                              <div className="flex items-center gap-2">
                                <Dialog>
                                  <DialogTrigger asChild>
                                    <Button size="sm" onClick={() => handleInteractionsClick(relationship)}>
                                      Interactions
                                    </Button>
                                  </DialogTrigger>
                                  <DialogContent className="sm:max-w-[425px]">
                                    <DialogHeader>
                                      <DialogTitle>Interactions with {relationship?.contacts[0]?.name}</DialogTitle>
                                    </DialogHeader>
                                    <hr className="my-2" />
                                    <div className="grid gap-2 py-2">
                                      {contactInteractions?.map((interaction, index) => (
                                        <div key={interaction?._id} className="py-2">
                                          <div className="flex items-center gap-2">
                                            {getInteractionIcon(interaction?.messagingPlatform, interaction?.direction)}
                                            {getDirectionIcon(interaction?.messagingTraffic)}
                                            <span className="font-semibold">
                                              {interaction?.user?.name || ''}
                                            </span>
                                            <span className="ml-auto">
                                              {formatDate(interaction?.actionDate)}
                                            </span>
                                          </div>
                                          <p className="text-gray-600">
                                            {interaction?.actionData?.notes || 'No additional notes available.'}
                                          </p>
                                          {index < contactInteractions?.length - 1 && <hr className="my-2 border-gray-300" />}
                                        </div>
                                      ))}
                                    </div>
                                  </DialogContent>
                                </Dialog>
                                {/* <Dialog>
                                  <DialogTrigger asChild>
                                    <Button size="sm" onClick={() => handleEmployeesClick(relationship)}>
                                      Employees
                                    </Button>
                                  </DialogTrigger>
                                  <DialogContent className="sm:max-w-[425px]">
                                    <DialogHeader>
                                      <DialogTitle>Employees who know {relationship?.name}</DialogTitle>
                                    </DialogHeader>
                                    <div className="grid gap-4 py-4">
                                      {relatedEmployees?.map((employee) => (
                                        <div key={employee?.id} className="flex items-center justify-between">
                                          <div>
                                            <p className="font-medium">{employee?.name}</p>
                                            <p className="text-sm text-gray-500">User ID</p>
                                          </div>
                                          <span className="font-semibold">{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </DialogContent>
                                </Dialog> */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-muted-foreground">There are currently no relationships for this client.</p>
                )}
              </div>
            </div>
          )}
        </main>
      </div>
      <Dialog open={composeOpen} onOpenChange={setComposeOpen}>
        <DialogContent className="sm:max-w-[625px]">
          <DialogHeader>
            <DialogTitle>Compose Email</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="sender" className="text-right">
                From:
              </Label>
              <Select value={selectedSender} onValueChange={setSelectedSender}>
                <SelectTrigger className="col-span-3">
                  <SelectValue placeholder="Select sender" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="me">Me</SelectItem>
                  {relatedEmployees?.map((employee) => (
                    <SelectItem key={employee?.id} value={employee?.name}>
                      {employee?.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="type" className="text-right">
                Type:
              </Label>
              <Select value={messageType} onValueChange={setMessageType}>
                <SelectTrigger className="col-span-3">
                  <SelectValue placeholder="Select message type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="email">Email</SelectItem>
                  <SelectItem value="linkedin">LinkedIn</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="to" className="text-right">
                To:
              </Label>
              <Input
                id="to"
                value={
                  selectedClient && messageType === 'email'
                    ? clientRelationshipsCache[selectedClient?._id]?.email || ''
                    : (selectedClient && clientRelationshipsCache[selectedClient?._id]?.linkedinUrl) || ''
                }
                className="col-span-3"
                readOnly
                disabled
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="subject" className="text-right">
                Subject:
              </Label>
              <Input id="subject" value="Follow-up" className="col-span-3" />
            </div>
            <div className="grid grid-cols-4 gap-4">
              <Label htmlFor="message" className="text-right">
                Message:
              </Label>
              <Textarea
                id="message"
                value={emailDraft}
                onChange={(e) => setEmailDraft(e.target.value)}
                className="col-span-3"
                rows={10}
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="submit" onClick={handleSendEmail}>
              {selectedSender === 'me' ? 'Send Email' : `Queue for ${selectedSender}`}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={notesOpen} onOpenChange={setNotesOpen}>
        <DialogContent className="sm:max-w-[625px]">
          <DialogHeader>
            <DialogTitle>Notes for {selectedClient?.name}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex items-center space-x-2">
              <Input placeholder="Add a new note..." value={newNote} onChange={(e) => setNewNote(e.target.value)} />
              <Button onClick={handleAddNote}>
                <Plus className="h-4 w-4 mr-2" />
                Add
              </Button>
            </div>
            <div className="space-y-2">
              {/* {paginatedNotes?.map((note) => (
                <div key={note?.id} className="flex items-center justify-between p-2 bg-gray-50 rounded-md">
                  <div className="flex-1">
                    <p className="text-sm font-medium">{note?.content}</p>
                    <p className="text-xs text-gray-500">
                      {note?.date} - {note?.author}
                    </p>
                  </div>
                  <Button variant="ghost" size="sm" onClick={() => handleRemoveNote(note?.id)}>
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))} */}
            </div>
            <div className="flex justify-between items-center">
              <Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                Previous
              </Button>
              {/* <span>
                Page {currentPage} of {totalPages}
              </span>
              <Button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
*/}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <div>
        {/* Render contact interactions */}
        {/* {contactInteractions?.map((interaction) => (
          <div key={interaction?._id}>
            <p>{interaction?.actionType}</p>
            <p>{interaction?.actionData}</p>
          </div>
        ))} */}
      </div>
    </>
  );
}
