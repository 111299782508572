import * as React from 'react';
import { useSelector } from 'react-redux';

import ReactTimeAgo from 'react-time-ago';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RepeatIcon from '@mui/icons-material/Repeat';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Http from '../../http/httpClient';
import EditModal from './editModal';
import EditModalNew from './components/editModalNew';
import TagModal from './components/tagModal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import dayjs from 'dayjs';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PoliticsIcon from '../../assets/logos/Politics.png';
import EditContactModal from '../../components/editContactModal';
import ContactDetailsModal from './contactDetailsModal';
import { UserConst } from '../../constants/userConst';
import { GetCookie } from '../../helper/cookieManager';
import { TopicType } from '../../constants/constant';
import PublishModal from './components/publishModal';
import ProxyImage from '@/components/ProxyImage';

const PostItem = (props) => {
  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation);

  const { user } = props;
  const { handleDelete } = props;
  const controls = props.controls;
  const { handlePublish } = props;
  const { isLoading } = props;
  const { isDeleting } = props;
  const { ori_post } = props;
  const { index } = props;
  const { isTourOpen } = props;
  const { isAdmin } = props;
  const { postUserId } = props;
  const { handleApprove } = props;
  const [userPost, setUserPost] = React.useState(user);
  const [originalPost, setOriginalPost] = React.useState(ori_post);
  const [autoScheduled, setAutoScheduled] = React.useState(originalPost.isAutoScheduled);
  const [scheduledTime, setScheduledTime] = React.useState(originalPost.scheduledTime);
  const [open, setOpen] = React.useState(false);
  const [snackmessage, setSnackmessage] = React.useState('');
  const [isRecycling, setIsRecycling] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = React.useState(false);
  const [openContactDetails, setOpenContactDetails] = React.useState(false);
  const [contactLoading, setContactLoading] = React.useState(false);
  const [contactDetails, setContactDetails] = React.useState({});
  const [isPublishModalOpen, setPublishModalOpen] = React.useState(false);

  const [isHovered, setIsHovered] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [buttonLoad, setButtonLoad] = React.useState(false);

  const {
    _id,
    name,
    created_at,
    createdAt,
    post_create_date,
    message,
    source,
    ImageUrl,
    topic,
    topic_type,
    responseTo,
    articleImageUrl,
  } = userPost;
  const preSignedURL = userPost.uploadDetails?.preSignedURL;
  const dateTime = new Date(post_create_date);
  const time = dateTime.toLocaleTimeString();
  const politicalKeywords = [
    'politics',
    'government',
    'election',
    'democracy',
    'legislation',
    'public policy',
    'governance',
    'diplomacy',
  ];

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTagOpen = () => {
    setIsTagModalOpen(true);
  };

  const handleTagClose = () => {
    setIsTagModalOpen(false);
  };

  const handleTagSubmit = (e) => {
    handleTagClose();
  };

  const handleEditOpen = () => {
    const curUser = GetCookie('user');

    if (controls === false && curUser && curUser.userType === UserConst.UserTypes.DEMO_USER) {
      setEditOpen(true);
    } else {
      setEditOpen(false);
    }
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    handleEditClose();
  };

  const handleCloseModal = (post, updattedPost) => {
    if (post && updattedPost) {
      const params = { post, textToUpdate: updattedPost, postUserId };
      if (isAdmin) {
        params.postUserId = postUserId;
      }
      const endpoint = authSliceSelectedDelegation?.userId
        ? `updateUserPost/${authSliceSelectedDelegation.userId}`
        : 'updateUserPost';

      Http.postData(endpoint, params)
        .then((res) => {
          const _post = res.post.responseTo || res.post;
          setUserPost(_post);
          setOriginalPost(res.post);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    setIsModalOpen(false);
  };

  const uploadPostImageFile = async (image) => {
    let localUpload = null;
    try {
      const filePromise = new Promise((resolve, reject) => {
        const file = image.file;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = async () => {
          try {
            const fileContent = image.data_url;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('originalname', file.name);
            formData.append('path', file.path);
            formData.append('fileContent', fileContent);

            const response = await fetch('/api/uploadPostImage', {
              method: 'POST',
              body: formData,
            });

            if (response.ok) {
              const json = await response.json();
              localUpload = json.localUpload;
              resolve(response);
            } else {
              console.error('Post Item File upload error:', response.statusText);
              reject(response.statusText);
            }
          } catch (err) {
            reject(err);
          }
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      await filePromise;
    } catch (error) {
      console.error('PostItem File upload error:', error);
    }

    return localUpload;
  };

  const handleImagePostCloseModal = async (event, data) => {
    setButtonLoad(true);
    try {
      if (data) {
        const { post, textToUpdate, orgImageExist, updatedImageList } = data;
        if (!post && !orgImageExist && textToUpdate?.length === 0 && updatedImageList?.length === 0) {
          throw 'No post data found for update.';
        }

        const params = {};
        if (updatedImageList?.length > 0) {
          const image = updatedImageList[0];
          const localUpload = await uploadPostImageFile(image);
          if (!localUpload || localUpload.length === 0) {
            throw 'Failed to upload the image for post update.';
          }

          params.localUpload = localUpload;
        }

        params.post = post;
        params.textToUpdate = textToUpdate;
        params.orgImageExist = orgImageExist;

        if (
          (params.localUpload && Object.keys(params.localUpload).length > 0) ||
          (params.textToUpdate && params.textToUpdate.length > 0)
        ) {
          const endpoint = authSliceSelectedDelegation?.userId
            ? `updateUserPost/${authSliceSelectedDelegation.userId}`
            : 'updateUserPost';

          const res = await Http.postData(endpoint, params);
          if (res && res.post) {
            const _post = res.post.responseTo || res.post;
            setUserPost(_post);
            setOriginalPost(res.post);
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsModalOpen(false);
      setButtonLoad(false);
    }
  };

  const handlePublishModalOpen = () => {
    setPublishModalOpen(true);
  };

  const handleRecycle = () => {
    setIsRecycling(true);
    Http.getData('recyclePost', { post: originalPost })
      .then((res) => {
        const _post = res.post.responseTo || res.post;
        setUserPost(_post);
        setOriginalPost(res.post);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsRecycling(false);
      });
  };

  const handleAction = (actionType) => {
    Http.postData(actionType, { post: originalPost })
      .then((res) => {
        setOpen(true);
        setSnackmessage(res.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReTweet = () => {
    handleAction('reTweetPost');
  };

  const handleLikeUserTweet = () => {
    handleAction('likeUserTweet');
  };

  const handleContactDetails = () => {
    if (controls || controls === 'true') {
      return null;
    }

    setContactLoading(true);
    Http.getData('contactDetails/show', { contact: { id: user.authorId, source: user.source } })
      .then((res) => {
        const contactDetail = res.contactDetails.contact;
        contactDetail.last_tweet_pull_time = res.contactDetails.last_tweet_pull_time
          ? new Date(contactDetail.last_tweet_pull_time).toString()
          : '';
        contactDetail.source = user.source;
        setContactDetails(contactDetail);
        setContactLoading(false);
      })
      .catch((error) => {
        console.log('Error while fetching contact details. ', error);
      });
    setOpenContactDetails(true);
  };

  const findAndFormatHyperlinks = (message) => {
    if (!message) {
      return null;
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = message.split(urlRegex);
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <Link href={part} target="_blank" key={index} rel="noreferrer">
            {part}
          </Link>
        );
      } else {
        return part;
      }
    });
  };

  const handleContactDetailsClose = () => {
    setOpenContactDetails(false);
  };

  return (
    <>
      <ListItem
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: 3,
          py: 1,
          my: 1.5,
          justifyContent: 'flex-end',
          background:
            controls === true
              ? topic_type === TopicType.TWITTER_TRENDING
                ? 'linear-gradient(to left, #FFFFFF, 99%, #f5c6e7)'
                : 'linear-gradient(to left, #FFFFFF, 99%, #CEDBED)'
              : '#FFFFFF',
        }}
      >
        <Box
          id={responseTo ? `step-social-post-${index}` : `step-leadership-post-${index}`}
          sx={{ flexDirection: 'column', width: '100%' }}
        >
          <Box sx={{ flexGrow: 1, mx: 2, width: '100%' }}>
            <Box
              id={responseTo ? `step-social-post-original-${index}` : null}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClick={handleEditOpen}
                badgeContent={
                  source === 'twitter' ? <TwitterIcon sx={{ fontSize: 12 }} /> : <LinkedInIcon sx={{ fontSize: 12 }} />
                }
                sx={{
                  '& .MuiBadge-badge': {
                    color: '#4999E9',
                    backgroundColor: 'white',
                    border: '1px solid #4999E9',
                    mx: 1,
                  },
                }}
              >
                <Avatar sx={{ mx: 1 }} src={ImageUrl} />
              </Badge>
              <Box sx={{ flexGrow: 1, mx: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography
                      sx={{ 'fontWeight': 'bold', 'fontSize': '16px', 'mx': 1, '&:hover': { cursor: 'pointer' } }}
                      onClick={handleContactDetails}
                    >
                      {name} {/* &bull;{' '} */}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#020202',
                        marginLeft: '0.5rem',
                      }}
                    >
                      <ReactTimeAgo date={controls === 'true' ? createdAt : post_create_date} locale="en-US" />
                    </Typography>
                    {topic &&
                      politicalKeywords.some((keyword) => topic.toLowerCase().includes(keyword.toLowerCase())) && (
                        <img
                          src={PoliticsIcon}
                          alt={topic}
                          style={{ width: 20, height: 20, marginLeft: 5, background: 'transparent' }}
                        />
                      )}
                    {topic_type && topic_type === TopicType.TWITTER_TRENDING && (
                      <Chip
                        icon={<TrendingUpIcon />}
                        label={topic}
                        variant="outlined"
                        sx={{ height: 20, marginLeft: 1, color: '#F765C9' }}
                      />
                    )}
                  </Box>

                  {controls != false && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <>
                        <Box sx={{ visibility: isHovered || isTourOpen ? 'visible' : 'hidden' }}>
                          <IconButton onClick={handleTagOpen}>
                            <SellOutlinedIcon fontSize="small" />
                          </IconButton>
                          <TagModal open={isTagModalOpen} onClose={handleTagClose} post={ori_post} />
                          {topic_type !== TopicType.IMAGE_POST && (
                            <IconButton
                              onClick={handleRecycle}
                              id={
                                controls === 'true'
                                  ? `step-social-post-regenerate-${index}`
                                  : `step-leadership-post-action-regenerate-${index}`
                              }
                            >
                              {isRecycling ? null : <SyncIcon fontSize="small" />}
                              <CircularProgress
                                size={24}
                                color="inherit"
                                thickness={4}
                                style={{ display: isRecycling ? 'block' : 'none' }}
                              />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={handleOpenModal}
                            id={
                              controls === 'true'
                                ? `step-social-post-edit-${index}`
                                : `step-leadership-post-action-edit-${index}`
                            }
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          {isModalOpen && (
                            <Box>
                              {topic_type === TopicType.IMAGE_POST ? (
                                <EditModal
                                  open={isModalOpen}
                                  onClose={handleImagePostCloseModal}
                                  ori_post={ori_post}
                                  preSignedURL={preSignedURL}
                                  loading={buttonLoad}
                                />
                              ) : (
                                <EditModalNew open={isModalOpen} onClose={handleCloseModal} ori_post={originalPost} />
                              )}
                            </Box>
                          )}

                          <IconButton
                            onClick={() => handleDelete(originalPost)}
                            id={
                              controls === 'true'
                                ? `step-social-post-delete-${index}`
                                : `step-leadership-post-action-delete-${index}`
                            }
                          >
                            {isDeleting ? null : <DeleteOutlineIcon fontSize="small" />}
                            <CircularProgress
                              size={24}
                              color="inherit"
                              thickness={4}
                              style={{ display: isDeleting ? 'block' : 'none' }}
                            />
                          </IconButton>
                        </Box>
                        {isAdmin === false && (
                          <Box>
                            {autoScheduled ? (
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '12px',
                                  backgroundColor: '#b0e57c',
                                  marginTop: '10px',
                                  display: 'inline-block',
                                  padding: '4px 8px',
                                  borderRadius: '4px',
                                }}
                                title={`${dayjs(scheduledTime).format('MMM DD, YYYY hh:mm a')}`}
                              >
                                Scheduled
                              </span>
                            ) : (
                              <Button
                                variant="outlined"
                                id={
                                  controls === 'true'
                                    ? `step-social-post-publish-${index}`
                                    : `step-leadership-post-action-${index}`
                                }
                                onClick={() => handlePublishModalOpen()}
                                sx={{
                                  'color': 'black',
                                  'border': '1px solid #9A9A9A',
                                  'backgroundColor': 'white',
                                  'fontWeight': 'bold',
                                  'textTransform': 'none',
                                  'display': 'flex',
                                  'alignItems': 'center',
                                  'fontSize': 11,
                                  'borderRadius': 16,
                                  '&:hover': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                  },
                                }}
                              >
                                {isLoading ? (
                                  <Box
                                    sx={{
                                      position: 'relative',
                                      width: '34px',
                                      height: '24px',
                                      display: 'flex',
                                      borderRadius: 16,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <CircularProgress color="inherit" />
                                  </Box>
                                ) : (
                                  'Publish'
                                )}
                              </Button>
                            )}
                          </Box>
                        )}
                        <PublishModal
                          open={isPublishModalOpen}
                          onClose={() => setPublishModalOpen(false)}
                          onSubmit={handlePublish}
                          posts={originalPost}
                        />
                        {isAdmin && (
                          <Button
                            variant="outlined"
                            onClick={handleApprove}
                            sx={{
                              'color': 'black',
                              'border': '1px solid #9A9A9A',
                              'backgroundColor': 'white',
                              'fontWeight': 'bold',
                              'textTransform': 'none',
                              'display': 'flex',
                              'alignItems': 'center',
                              'fontSize': 11,
                              'borderRadius': 16,
                              'marginLeft': 1,
                              '&:hover': {
                                backgroundColor: 'black',
                                color: 'white',
                              },
                            }}
                          >
                            Approve
                          </Button>
                        )}
                      </>
                    </Box>
                  )}
                  {controls == false && isAdmin == false && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton id={responseTo ? `step-social-post-like-${index}` : null}>
                        <FavoriteIcon fontSize="small" onClick={() => handleLikeUserTweet()} />
                      </IconButton>
                      <IconButton id={responseTo ? `step-social-post-retweet-${index}` : null}>
                        <RepeatIcon fontSize="small" onClick={() => handleReTweet()} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', px: 4, py: 1 }}>
                  {' '}
                  {findAndFormatHyperlinks(message)}
                </Typography>
                {topic_type === TopicType.IMAGE_POST && preSignedURL && (
                  <Box
                    component="img"
                    sx={{ height: 250, width: 350, m: 2, borderRadius: 3 }}
                    alt="imagepost"
                    src={preSignedURL}
                  />
                )}
                {articleImageUrl && (
                  <ProxyImage
                    sx={{ height: 200, width: 350, m: 3, borderRadius: 3, objectFit: 'cover' }}
                    alt="imagepost"
                    src={articleImageUrl}
                  />
                )}
              </Box>
            </Box>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={3000} message={snackmessage} />
            {responseTo && (
              <Box
                id={`step-social-post-response-${index}`}
                sx={{ width: '100%', marginTop: '1rem', borderLeft: '2px solid #4999E9' }}
              >
                <PostItem
                  isAdmin={isAdmin}
                  postUserId={postUserId}
                  key={responseTo._id}
                  user={responseTo}
                  ori_post={originalPost}
                  index={index}
                  isTourOpen={isTourOpen}
                  handleDelete={handleDelete}
                  handleApprove={handleApprove}
                  handlePublish={handlePublish}
                  isLoading={isLoading}
                  controls="true"
                />
              </Box>
            )}
          </Box>
        </Box>
      </ListItem>
      <EditContactModal open={editOpen} onClose={handleEditClose} onSubmit={handleEditSubmit} user={user} />
      {openContactDetails && (
        <ContactDetailsModal
          open={openContactDetails}
          onClose={handleContactDetailsClose}
          contact={contactDetails}
          contactLoading={contactLoading}
        />
      )}
    </>
  );
};

export default PostItem;
