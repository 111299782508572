import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, Box, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { PCollapsableContainer, PTypography } from '@/components/library';
import { alpha, useTheme } from '@mui/material/styles';
import Http from '@/http/httpClient';
import { GetCookie } from '@/helper/cookieManager';
import useUserNews from '@/hooks/useUserNews';
export default function NewsCards() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const theme = useTheme();
  const { news, loading, error, refetch } = useUserNews();
  const [retryCount, setRetryCount] = useState(0);

  const containerColor = '#f0f2f4';
  const navButton = {
    color: alpha(theme.palette.primaryCL.Black100, 0.02),
    hoverColor: alpha(theme.palette.primaryCL.Black100, 0.1),
    arrowColor: alpha(theme.palette.primaryCL.Black100, 0.8),
  };
  const indicatorColor = {
    selected: alpha(theme.palette.primaryCL.Black100, 0.8),
    unselected: alpha(theme.palette.primaryCL.Black100, 0.4),
  };

  const slideRefs = useRef([]);

  useEffect(() => {
    if (news) {
      slideRefs.current = news.map((_, i) => slideRefs.current[i] ?? React.createRef());
    }
  }, [news]);

  useEffect(() => {
    if (news.length === 0 && retryCount < 3) {
      const retryIntervals = [5000, 10000, 15000];
      const delay = retryIntervals[retryCount];
      
      const retryTimeout = setTimeout(() => {
        setRetryCount((prev) => prev + 1);
        refetch();
      }, delay);
      return () => clearTimeout(retryTimeout);
    }
  }, [news, retryCount, refetch]);

  useEffect(() => {
    const options = {
      root: carouselRef.current,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute('data-index'));
          setCurrentIndex(index);
        }
      });
    }, options);

    slideRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [news]);

  const navigateCarousel = (direction) => {
    setCurrentIndex((prevIndex) => {
      const newIndex = direction === 'prev'
        ? prevIndex === 0 ? news.length - 1 : prevIndex - 1
        : (prevIndex + 1) % news.length;

      const scrollPosition = slideRefs.current[newIndex]?.current?.offsetLeft || 0;
      carouselRef.current.scrollLeft = scrollPosition;

      return newIndex;
    });
  };

  /**
   * Truncates a given text to a specified maximum number of words.
   * Appends ellipsis (...) only if truncation occurs.
   *
   * @param {string} text - The original text to be truncated.
   * @param {number} maxWords - The maximum number of words to retain.
   * @returns {string} - The truncated text with ellipsis if it exceeds maxWords.
   */
  function truncateWords(text, maxWords = 35) {
    if (typeof text !== 'string') {
      console.warn('truncateWords: Expected a string as the first argument.');
      return '';
    }

    const words = text.trim().split(/\s+/); // Split text into words based on whitespace
    if (words.length <= maxWords) {
      return text;
    }

    const truncated = words.slice(0, maxWords).join(' ');
    return truncated + '...';
  }

  return news.length > 0 ? (
    <PCollapsableContainer title="News" backgroundColor={containerColor}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        <Box sx={{ backgroundColor: 'white', padding: '28px 0', borderRadius: 2 }}>
          <Box
            ref={carouselRef}
            sx={{
              'display': 'flex',
              'overflowX': 'auto',
              'scrollSnapType': 'x mandatory',
              'WebkitOverflowScrolling': 'touch',
              'scrollBehavior': 'smooth',
              'scrollbarWidth': 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
            }}
          >
            {news?.map((article, index) => (
              <Card
                key={index}
                data-index={index}
                ref={slideRefs.current[index]}
                sx={{
                  'flexShrink': 0,
                  'width': '100%',
                  'scrollSnapAlign': 'center',
                  'boxShadow': 'none',
                  'borderRadius': 0,
                  'backgroundColor': 'transparent',
                  '&:last-child': {
                    paddingBottom: '8px',
                  },
                }}
              >
                <CardContent sx={{ padding: '0px 64px' }}>
                  {/* Title */}
                  <PTypography size="body2" gutterBottom sx={{ marginTop: '8px' }}>
                    {article.title}
                  </PTypography>

                  {/* Summary */}
                  <PTypography size="body2" sx={{ fontWeight: 400, fontSize: '0.90rem' }}>
                    {truncateWords(article.summary)}
                  </PTypography>

                  {/* Date */}
                  <PTypography
                    size="body2"
                    sx={{
                      fontWeight: 400,
                      fontSize: '0.75rem',
                      marginTop: '8px',
                      color: theme.palette.primaryCL.Black100,
                    }}
                  >
                    Published on {new Date(article.publishedDate).toLocaleDateString()}
                  </PTypography>
                  {/* Source Label */}
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '4px 16px',
                      marginTop: 1,
                      borderRadius: '48px',
                      backgroundColor: alpha(theme.palette.primaryCL.Black100, 0.08),
                      color: 'black',
                      marginBottom: '10px',
                    }}
                  >
                    <PTypography
                      size="body2"
                      sx={{
                        'fontWeight': 400,
                        'fontSize': '0.75rem',
                        '&:hover': {
                          color: theme.palette.primaryCL.Black100,
                        },
                      }}
                    >
                      <a href={article.url} target="_blank" rel="noopener noreferrer">
                        source
                      </a>
                    </PTypography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>

          {/* Navigation Buttons */}
          {news?.length > 1 && (
            <>
              <IconButton
                onClick={() => navigateCarousel('prev')}
                sx={{
                  'position': 'absolute',
                  'top': '50%',
                  'left': 8,
                  'transform': 'translateY(-50%)',
                  'backgroundColor': navButton.color,
                  '&:hover': { backgroundColor: navButton.hoverColor },
                }}
                aria-label="Previous slide"
              >
                <ChevronLeft sx={{ color: navButton.arrowColor }} />
              </IconButton>
              <IconButton
                onClick={() => navigateCarousel('next')}
                sx={{
                  'position': 'absolute',
                  'top': '50%',
                  'right': 8,
                  'transform': 'translateY(-50%)',
                  'backgroundColor': navButton.color,
                  '&:hover': { backgroundColor: navButton.hoverColor },
                }}
                aria-label="Next slide"
              >
                <ChevronRight sx={{ color: navButton.arrowColor }} />
              </IconButton>
            </>
          )}

          {/* Indicators */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {news?.map((_, index) => (
              <Box
                key={index}
                onClick={() => {
                  const scrollPosition = slideRefs.current[index]?.current?.offsetLeft || 0;
                  carouselRef.current.scrollTo({
                    left: scrollPosition,
                    behavior: 'smooth',
                  });
                  setCurrentIndex(index);
                }}
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: currentIndex === index ? indicatorColor.selected : indicatorColor.unselected,
                  margin: '0 4px',
                  cursor: 'pointer',
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </PCollapsableContainer>
  ) : null;
}
