import { useEffect, useState, useRef } from 'react';
import {
  useTheme,
  CircularProgress,
  DialogTitle,
  DialogContent,
  Button,
  Dialog,
  IconButton,
  Grid,
  Alert,
} from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import Dart from '@Assets/Icons/Dart.png';
import File from '@Assets/Icons/File.png';
import Person from '@Assets/Icons/Person.png';
import Star from '@Assets/Icons/Star.png';

import { ReactComponent as Back } from '@Assets/Icons/Arrow-Left.svg';

import ChooseList from '@/components/list/chooseList';
import AIListPrompt from '@/components/list/AIListPrompt';
import AIResults from '@/components/list/AIResults';
import ImportCSV from '@/components/list/importCSV';
import ManualContact from '@/components/list/manualContact';
import ImportCRM from '@/components/list/importCRM';
import CRMFilters from '@/components/list/crmFilters';

import { validatedContacts } from '@/helper/utilities';
import { addContactsToList } from '@/helper/apiHelper';
import { ErrorCodes, ErrorMessages } from '@/constants/errorHandlers';
import ListContactsReview from './listContactsReview';
import AssociatedSequenceReview from './associatedSequenceReview';
import AIListPayload from '@/components/list/AIListPayload';
import { generateAISearchPayload } from '@/helper/apiHelper';

export default function ListModal(props) {
  const theme = useTheme();
  const { onClose, open, selectedItem, setCreatedListId, targetAudience } = props;

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [listItem, setListItem] = useState({});
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isUpdateProgress, setIsUpdateProgress] = useState(false);
  const [contactList, setContactList] = useState([]);
  const childComponentRef = useRef();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [contactsToReview, setContactsToReview] = useState([]);
  const [sequenceNames, setSequenceNames] = useState([]);
  const [listContacts, setlistContacts] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [audienceType, setAudienceType] = useState(
    [
      {
        id: 'AI',
        icon: Star,
        title: 'Start with AI',
        subtitle: 'Quickly create targeted lists.',
        active: false,
        popular: true,
      },
      {
        id: 'ManualContact',
        icon: Person,
        title: 'Add people manually',
        subtitle: 'Simple way to add an audience.',
        active: false,
      },
      { id: 'csv', icon: File, title: 'Import CSV file', subtitle: 'Easily add your marketing lists.', active: false },
      { id: 'crm', icon: Dart, title: 'From your CRM', subtitle: 'HubSpot, Salesforce, Affinity.', active: false },
    ].filter((item) => (targetAudience ? targetAudience[item.id.toLowerCase()] : true)),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [aiSearchPayload, setAiSearchPayload] = useState({});
  const [aiPayloadFormData, setAiPayloadFormData] = useState({});
  useEffect(() => {
    if (aiSearchPayload && Object.keys(aiSearchPayload).length > 0) {
      // Payload is ready, now transition to AI_PAYLOAD
      setStep('AI_PAYLOAD');
    }
  }, [aiSearchPayload]);

  const listSteps = [
    {
      id: 'TARGET_LIST',
      stepId: null,
      title: 'Select who this sequence will target',
      content: (
        <ChooseList
          listItem={listItem}
          audienceType={audienceType}
          setTargetListItem={(item) => {
            setListItem((prev) => ({
              ...prev,
              providers: item?.providers,
              name: item?.name,
              provider: item?.provider,
              lisId: item?.lisId,
            }));
          }}
          fromEdit={false}
          fromListAddContacts={true}
          targetAudience={targetAudience}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'AI',
      stepId: null,
      title: 'Start with AI',
      content: (
        <AIListPrompt
          setAiPrompt={(description) => {
            setListItem((prev) => {
              const providers = (prev.providers || []).slice();
              const index = providers.findIndex((provider) => provider.providerType === 'AI');
              if (index !== -1) {
                providers[index] = {
                  ...providers[index],
                  provider: 'rocketreach',
                  AIRequest: description,
                  isExpanded: false,
                };
              }

              return {
                ...prev,
                providers: providers,
                provider: 'rocketreach',
                selectedContacts: [],
                allContacts: [],
              };
            });
          }}
          listItem={listItem}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'AI_PAYLOAD',
      stepId: null,
      title: 'Start with AI',
      content: (
        <AIListPayload
          updateFormData={(data) => setAiPayloadFormData(data)}
          formData={aiPayloadFormData}
          aiSearchPayload={aiSearchPayload}
          setAiSearchPayload={(data) => setAiSearchPayload(data)}
          setAiPrompt={(description) => {
            setListItem((prev) => {
              const providers = (prev.providers || []).slice();
              const index = providers.findIndex((provider) => provider.providerType === 'AI');
              if (index !== -1) {
                providers[index] = {
                  ...providers[index],
                  provider: 'rocketreach',
                  AIRequest: description,
                  isExpanded: false,
                };
              }

              return {
                ...prev,
                providers,
                provider: 'rocketreach',
                selectedContacts: [],
                allContacts: [],
              };
            });
          }}
          listItem={listItem}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'AI_RESULT',
      stepId: null,
      title: 'Start with AI',
      content: (
        <AIResults
          formData={aiPayloadFormData}
          listItem={listItem}
          setselectedTargetContacts={(c) => {
            setListItem((prev) => ({
              ...prev,
              selectedContacts: c.selectedContacts,
              allContacts: c.allContacts,
            }));
          }}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'csv',
      stepId: null,
      title: 'Start with CSV',
      content: <ImportCSV setSelectedItem={setListItem} listItem={listItem} />,
      next: 'true',
      back: 'true',
    },
    {
      id: 'ManualContact',
      stepId: null,
      title: 'Create manual contact list',
      content: (
        <ManualContact
          listItem={listItem}
          setContactList={setContactList}
          ref={childComponentRef}
          setListItem={setListItem}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'crm',
      stepId: null,
      title: 'Start with CRM',
      content: <ImportCRM setSelectedItem={setListItem} listItem={listItem} />,
      next: 'true',
      back: 'true',
    },
    {
      id: 'CRM_FILTERS',
      stepId: null,
      title: 'Start with CRM',
      content: <CRMFilters listItem={listItem} setSelectedItem={setListItem} />,
      next: 'true',
      back: 'true',
    },
    {
      id: 'CONTACTS_REVIEW',
      stepId: null,
      title: 'Review the contacts',
      content: <ListContactsReview contactsToReview={contactsToReview} setlistContacts={setListItem} />,
      next: 'true',
      back: 'true',
    },
    {
      id: 'SEQUENCE_REVIEW',
      stepId: null,
      title: 'Review the sequence',
      content: <AssociatedSequenceReview sequenceToReview={sequenceNames} listItem={selectedItem} />,
      next: 'true',
      back: 'true',
    },
  ];

  const [currentStep, setCurrentStep] = useState(listSteps[0]);

  const handleClose = () => {
    onClose();
  };

  const handleSaveAndAddAnother = (addAnother = true) => {
    const result = validatedContacts(contactList, !addAnother);
    if (result.code === ErrorCodes.VALID_CONTACT) {
      const validContact = result.contact;
      if (validContact) {
        setListItem((prev) => ({
          ...prev,
          selectedContacts: [...(prev.selectedContacts ?? []), validContact],
        }));

        if (addAnother) {
          childComponentRef.current.addSavedContact(validContact);
        } else {
          setShowSaveButton(false);
        }
        setContactList([]);
        return true;
      }
    } else {
      if (!addAnother && listItem?.selectedContacts?.length > 0) {
        return true;
      }

      setAlertMessage(ErrorMessages[result.code]);
      setShowAlert(true);
    }
    return false;
  };

  const handleContinue = () => {
    switch (currentStep.id) {
      case 'TARGET_LIST':
        if (listItem?.providers?.length > 0) {
          const providerType = listItem?.providers?.[0]?.providerType;
          if (providerType === 'ManualContact') {
            setShowSaveButton(true);
          }

          if (selectedProvider !== providerType) {
            setSequenceNames((prev) => []);
            setContactsToReview((prev) => []);
            setListItem((prev) => ({
              ...prev,
              selectedContacts: [],
              allContacts: [],
              removedContacts: [],
              providerContacts: [],
            }));
            setSelectedProvider((prev) => providerType);
          }

          setStep(providerType);
        } else {
          setAlertMessage('Please choose the contacts provider.');
          setShowAlert(true);
        }
        break;
      case 'AI':
        const aiProvider = listItem?.providers?.find((provider) => provider.providerType === 'AI');
        if (aiProvider?.AIRequest?.length > 0) {
          setIsLoading(true);
          generateAISearchPayload(aiProvider.AIRequest)
            .then((response) => {
              if (!response.error) {
                setAiSearchPayload(response.payload);
                setIsLoading(false);
              } else {
                setIsLoading(false);
                setAlertMessage(response.error);
                setShowAlert(true);
              }
            })
            .catch((error) => {
              console.error('Error:', error);
              setIsLoading(false);
              setAlertMessage('Error generating AI search payload.');
              setShowAlert(true);
            });
        } else {
          setAlertMessage('Please input your required prompt.');
          setShowAlert(true);
        }
        break;
      case 'AI_PAYLOAD':
        setStep('AI_RESULT');
        break;
      case 'crm':
        const crmProvider = listItem?.providers?.find((provider) => provider.providerType === 'crm');
        if (crmProvider) {
          setStep(`CRM_FILTERS`);
        } else {
          setAlertMessage('Please select the crm provider.');
          setShowAlert(true);
        }
        break;
      case 'ManualContact':
        if (!handleSaveAndAddAnother(false)) {
          setStep('ManualContact');
        } else {
          setTriggerUpdate(true);
        }
        break;
      case 'AI_RESULT':
      case 'csv':
      case 'CRM_FILTERS':
        performUpdate(true, 'review');
        break;
      case 'CONTACTS_REVIEW':
        if (sequenceNames.length) {
          setStep('SEQUENCE_REVIEW');
        } else {
          performUpdate(true, 'addContacts');
        }
        break;
      case 'SEQUENCE_REVIEW':
        performUpdate(true, 'addContacts');
        break;
      default:
        console.log('no case matched');
    }
  };

  useEffect(() => {
    if (triggerUpdate) {
      return;
    }

    if (contactsToReview.length) {
      setStep(`CONTACTS_REVIEW`);
    } else if (sequenceNames.length) {
      setStep(`SEQUENCE_REVIEW`);
    }
  }, [contactsToReview, sequenceNames, triggerUpdate]);

  const setStep = (step) => {
    const stepToSet = listSteps?.find((item) => item.id === step);
    setCurrentStep(stepToSet);
  };

  useEffect(() => {
    performUpdate(triggerUpdate, 'review');
  }, [triggerUpdate]);

  const performUpdate = async (triggered, type) => {
    if (triggered) {
      try {
        const continueNext = await updateContactsToList(type);
        if (!continueNext) {
          handleClose();
        } else {
          /** Need to review this code */
          if (contactsToReview.length) {
            setStep(`CONTACTS_REVIEW`);
          } else if (sequenceNames.length) {
            setStep(`SEQUENCE_REVIEW`);
          }
        }
      } catch (error) {
        console.error('Error during update:', error);
      }

      setTriggerUpdate((prev) => false);
      setShowSaveButton(false);
    }
  };

  useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const updateContactsToList = (type) => {
    return new Promise((resolve, reject) => {
      if ((sequenceNames?.length > 0 || contactsToReview?.length > 0) && type !== 'addContacts') {
        resolve(true);
        return;
      }

      setIsUpdateProgress(true);
      addContactsToList(selectedItem, { ...listItem, actionType: type })
        .then((res) => {
          let filteredContacts = [];
          let associatedSequenceNames = [];

          if (res.status) {
            filteredContacts = res.associatedContacts?.filter((contact) => {
              return !contact.listNames.includes(selectedItem.name);
            });
            associatedSequenceNames = res.sequenceNames;
            setlistContacts((prev) => res.allContacts);
            setListItem((prev) => ({ ...prev, providerContacts: res.allContacts }));
          }

          if (filteredContacts.length > 0 || associatedSequenceNames.length > 0) {
            setContactsToReview((prev) => filteredContacts);
            setSequenceNames((prev) => associatedSequenceNames);
            resolve(true);
          } else {
            setCreatedListId(res.listId);
            resolve(false);
          }
          setIsUpdateProgress(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setIsUpdateProgress(false);
          reject(error);
        });
    });
  };

  const handleBack = () => {
    switch (currentStep.id) {
      case 'SEQUENCE_REVIEW':
        if (contactsToReview.length) {
          setStep(`CONTACTS_REVIEW`);
        } else {
          const providerType = listItem?.providers?.[0]?.providerType;
          if (providerType === 'AI') {
            setStep('AI_RESULT');
          } else if (providerType === 'csv') {
            setStep(providerType);
          } else if (providerType === 'ManualContact') {
            setShowSaveButton(true);
            setStep(providerType);
          } else {
            setStep('CRM_FILTERS');
          }
        }
        break;
      case 'CONTACTS_REVIEW':
        const providerType = listItem?.providers?.[0]?.providerType;
        if (providerType === 'AI') {
          setStep('AI_RESULT');
        } else if (providerType === 'csv') {
          setStep(providerType);
        } else if (providerType === 'ManualContact') {
          setShowSaveButton(true);
          setStep(providerType);
        } else {
          setStep('CRM_FILTERS');
        }
        break;
      case 'CRM_FILTERS':
        setSequenceNames((prev) => []);
        setContactsToReview((prev) => []);
        setStep('crm');
        break;
      case 'AI_RESULT':
        setStep('AI');
        break;
      case 'AI':
      case 'csv':
      case 'crm':
      case 'ManualContact':
        if (currentStep.id === 'ManualContact' && listItem.providers?.[0].providerType === 'ManualContact') {
          setShowSaveButton(false);
        }
        setStep('TARGET_LIST');
        break;
      default:
        handleClose();
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullScreen>
        <DialogTitle sx={{ p: 0 }}>
          <Grid container alignItems="flex-start" justifyContent="space-between">
            <Grid item>
              <IconButton aria-label="close" onClick={handleBack} sx={{ pt: 2, pl: 2 }}>
                <Back fill={theme.palette.primary.grey} />
              </IconButton>
            </Grid>
            <Grid item>{showAlert && <Alert severity="error">{alertMessage}</Alert>}</Grid>
            <Grid item>
              {showSaveButton && (
                <Button
                  onClick={handleSaveAndAddAnother}
                  sx={{
                    'maxWidth': '200px',
                    'backgroundColor': theme.palette.primary.lightBlue,
                    'color': theme.palette.primary.blue,
                    'textTransform': 'none',
                    'width': '167px',
                    'py': 1,
                    'px': 2,
                    'mx': 2,
                    'mt': 2,
                    'whiteSpace': 'nowrap',
                    'overflow': 'hidden',
                    'textOverflow': 'ellipsis',
                    'borderRadius': 2,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.white,
                      color: theme.palette.primary.blue,
                    },
                  }}
                >
                  Save & add another
                </Button>
              )}
              <Button
                onClick={handleContinue}
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'mr': 2,
                  'mt': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.lightBlue,
                    color: theme.palette.primary.blue,
                  },
                }}
              >
                {isUpdateProgress ? (
                  <CircularProgress size={24} color="inherit" />
                ) : currentStep.id === 'SEQUENCE_REVIEW' ? (
                  'Done'
                ) : (
                  'Continue'
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12}>
              <LinearProgress
                variant={isLoading ? 'indeterminate' : 'determinate'}
                value={75}
                sx={{
                  my: 2,
                  height: 6,
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: theme.palette.primary.lightBlue,
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: '#87E554',
                  },
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>{currentStep.content}</DialogContent>
      </Dialog>
    </>
  );
}
