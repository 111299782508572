import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Divider } from '@mui/material';
import PTypography from '@Library/PTypography';
import PScheduledPostCard from "@Library/PScheduledPostCard";

export default function Scheduled({ scheduledPosts, setPostItem, setModalOpen }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setPosts(scheduledPosts);
  }, [scheduledPosts]);

  const handleOnClick = (post) => {
    setPostItem(post);
    setModalOpen(true);
  };

  return (
    <Container sx={{ py: 2 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          paddingTop: '28px',
          paddingLeft: '14px',
          paddingRight: '14px',
        }}>
        <PTypography size="h3" weight="bold" children="Scheduled" />
      </div>

      <Divider flexItem sx={{ width: '100%', my: 2 }} />

      {posts?.length > 0 ? (
        posts.map((post) => (
          <div key={post.id} style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            paddingTop: '8px'
          }}>
            <PScheduledPostCard post={post} onClick={handleOnClick} />
          </div>
        ))
      ) : (
        <PTypography size="footnote" weight="regular" children="No posts scheduled." />
      )}
    </Container>
  );
}

Scheduled.propTypes = {
  scheduledPosts: PropTypes.array.isRequired,
  setPostItem: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};
