import * as React from "react";
import PTypography from "../library/PTypography";
import PTextfield from "../library/PTextfield";
import Grid from "@mui/material/Grid";
import PQuerySuggestionCard from "../../components/library/PQuerySuggestionCard";
import imgCFOs from "../../assets/images/icpPrompt/CFOs.jpg";
import imgEngineers from "../../assets/images/icpPrompt/Engineers.jpg";
import imgHRManagers from "../../assets/images/icpPrompt/HRManagers.jpg";
import imgNYC from "../../assets/images/icpPrompt/NYC.jpg";
import imgProjectManagers from "../../assets/images/icpPrompt/ProjectManagers.jpg";
import imgSalesDirectors from "../../assets/images/icpPrompt/SalesDirectors.jpg";

const suggestionCards = [
  {
    text: "10 sales directors in Texas with experience in companies that have a revenue of over 50 million dollars",
    image: imgSalesDirectors,
  },
  {
    text: "Five CFOs in California from startups in the financial industry",
    image: imgCFOs,
  },
  {
    text: "50 marketers in New York City from technology companies with at least 500 employees",
    image: imgNYC,
  },
  {
    text: "15 HR managers in London from the fashion industry with companies of at least 200 employees",
    image: imgHRManagers,
  },
  {
    text: "30 product managers from enterprise-sized software companies in San Francisco",
    image: imgProjectManagers,
  },
  {
    text: "20 engineers with a tenure of over 2 years working in medium-sized telecom companies",
    image: imgEngineers,
  },
];

const textFieldContainerStyle = {
  marginTop: "24px",
  marginBottom: "24px",
};

export default function AIListPrompt(props) {
  const { listItem, setAiPrompt } = props;
  const aiProvider = listItem?.providers?.find((provider) => provider.providerType === "AI");
  const [inputValue, setInputValue] = React.useState(aiProvider?.AIRequest ?? "");

  const handleDescChange = (event) => {
    const description = event.target.value;
    setInputValue(description);
    if (description?.length > 0) {
      setAiPrompt(description);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleCardClick = (text) => {
    if (text?.length > 0) {
      setInputValue(text);
      if (setAiPrompt) {
        setAiPrompt(text);
      }
    }
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={3} />

      <Grid item xs={6}>
        <div>
          <PTypography size="h3" weight="bold">
            Tell us who you're targeting, our AI will curate a list of contacts just for you.
          </PTypography>
        </div>

        <div style={textFieldContainerStyle}>
          <PTextfield
            multiline
            rows={4}
            paperStyle={{
              width: "100%",
            }}
            inputStyle={{
              width: "100%",
            }}
            placeholder="Describe your target audience"
            onChange={handleDescChange}
            onKeyDown={handleKeyDown}
            value={inputValue}
          />
        </div>

        <Grid container rowSpacing="12px" columnSpacing="9px">
        <Grid item xs={12}>
          <PTypography size="body1" weight="bold">
            What others have been typing
          </PTypography>
        </Grid>
        {suggestionCards.map((card) => (
          <Grid item key={card.text} sm={6} md={4}>
            <PQuerySuggestionCard image={card.image} text={card.text} onClick={() => handleCardClick(card.text)} />
          </Grid>
        ))}
      </Grid>
      </Grid>

      <Grid item xs={3} />
    </Grid>
  );
}
