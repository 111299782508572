import React, { useEffect, useState } from 'react'
import { fetchPostTemplates, deletePostTemplate, importPostTemplates } from './postTemplateService'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Box, Button, LinearProgress, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


const networkColors = {
  twitter: '#1DA1F2',  // Twitter's brand color
  linkedin: '#0077B5',  // LinkedIn's brand color
  // Add more networks and their colors as needed
};


const PostTemplatesList = ({ editTemplate }) => {
  const [postTemplates, setPostTemplates] = useState([])
  const [maxWeight, setMaxWeight] = useState(0)

  useEffect(() => {
    loadPostTemplates()
  }, [])

  const getColor = (weight, maxWeight) => {
    const ratio = weight / maxWeight
    if (ratio < 0.25) return 'success.main'
    if (ratio < 0.5) return 'warning.light'
    if (ratio < 0.75) return 'warning.main'
    return 'error.main'
  }

  const loadPostTemplates = async () => {
    try {
      const response = await fetchPostTemplates()
      response.sort((a, b) => b.weight - a.weight)
      setPostTemplates(response)
      const maxWeight = Math.max(...response.map(t => t.weight))
      setMaxWeight(maxWeight)
    } catch (error) {
      console.error('Error fetching post templates:', error)
    }
  }

  const handleExport = async () => {
    const data = postTemplates.map(({ _id, createdAt, updatedAt, createdBy, lastUpdatedBy, __v, ...rest }) => rest);
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }); // Pretty print JSON
    const now = new Date();
    const formattedDateTime = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}-${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}`;
    const filename = `postTemplates_${formattedDateTime}.json`;
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const response = await importPostTemplates(file);
        console.log('Import successful:', response);
        loadPostTemplates();  // Reload the list to show new templates
      } catch (error) {
        console.error('Error importing post templates:', error);
      }
    }
  };


  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      await deletePostTemplate(id)
      loadPostTemplates() // Refresh the list
    }
  }

  return (
    <>
      <Button
        onClick={handleExport} variant='contained'
        color='primary' sx={{ margin: 1 }}
      >Export Templates
      </Button>
      <input
        accept="application/json"
        type="file"
        onChange={handleFileSelect}
        style={{ display: 'none' }}
        id="raised-button-file"
      />
      <label htmlFor="raised-button-file">
        <Button
          component="span"
          variant="contained"
          color="primary"
          sx={{ margin: 1 }}
        >
          Import Templates
        </Button>
      </label>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table aria-label='post template table' sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '10%' }}>Title</TableCell>
              <TableCell sx={{ width: '50%' }}>Description</TableCell>
              <TableCell sx={{ width: '8%' }}>Weight</TableCell>
              <TableCell sx={{ width: '10%' }}>Social Networks</TableCell>
              <TableCell sx={{ width: '10%' }}>Active</TableCell>
              <TableCell sx={{ width: '10%' }}>Article</TableCell>
              <TableCell sx={{ width: '7%' }} align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {postTemplates.map((template) => (
              <TableRow key={template._id}>
                <TableCell component='th' scope='row'>
                  {template.title}
                </TableCell>
                <TableCell>
                  {template.content.length > 200
                    ? `${template.content.substring(0, 200)}...`
                    : template.content}
                </TableCell>
                <TableCell>
                  <Box position='relative' display='inline-flex' width='100%'>
                    <LinearProgress
                      variant='determinate'
                      value={(template.weight / maxWeight) * 100}
                      sx={{
                        width: '100%',
                        height: '20px',
                        backgroundColor: 'grey.400',
                        // Target the bar color directly
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: getColor(template.weight, maxWeight)
                        }
                      }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position='absolute'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Typography variant='caption' component='div' color='textPrimary' sx={{ fontWeight: 700, '&.MuiTypography-root': { fontWeight: 700 } }}>
                        {`${template.weight}`}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  {Object.entries(template.socialNetworks)
                    .filter(([_, enabled]) => enabled)
                    .map(([network, _]) => (
                      <div
                        key={network}
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          color: networkColors[network] || '#333',
                          fontWeight: 'bold'
                        }}
                      >
                        {network.charAt(0).toUpperCase() + network.slice(1)}
                      </div>
                    ))}
                </TableCell>
                <TableCell>
                  {template.published ? <CheckIcon color="success" /> : <CloseIcon style={{ color: 'red' }} />}
                </TableCell>
                <TableCell>
                  {template.article ? <CheckIcon color="success" /> : <CloseIcon style={{ color: 'red' }} />}
                </TableCell>
                <TableCell align='right'>
                  <IconButton onClick={() => editTemplate(template._id)} color='primary'>
                    <EditIcon color='info' />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(template._id)} color='secondary'>
                    <DeleteIcon color='error' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PostTemplatesList
