import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'

import Http from '../../../http/httpClient'
import CompanyItem from './companyItem'
import AddCompanyModal from './addCompanyModal'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import featureFlagService from '../../../services/featureFlagService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

export default function CompanyList () {
  const [isLoading, setIsLoading] = React.useState(false)
  const [companies, setCompanies] = React.useState([])
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = React.useState(false)
  const [buttonLoad, setButtonLoad] = React.useState(false)
  const featureFlags = useFeatureFlags();

  React.useEffect(() => {
    const fetchUserOnboardings = async () => {
      setIsLoading(true)
      Http.getData('companies/list')
        .then((res) => {
          setCompanies(res.companies)
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          setIsLoading(false)
        })
    }

    fetchUserOnboardings()
  }, [])

  const handleOpenCompanyModal = () => {
    setIsAddCompanyModalOpen(true)
  }

  // This is where we actually make the API call to create / update a company
  const handleCloseModal = async (companyDetails) => {
    if (companyDetails) {
      try {
        if(companyDetails.featureFlags) {
          await featureFlagService.updateFeatureFlags(companyDetails.companyId, companyDetails.featureFlags)
        }
        const response = await Http.postData('companies/updateOrCreate', { companyDetails })
        if (response && response.status) {
          setCompanies(prevCompanies => {
            const index = prevCompanies.findIndex(company => company?._id === response?.company?._id)
            if (index === -1) {
              const newCompanies = [...prevCompanies, response.company]
              return newCompanies
            } else {
              const updated = [...prevCompanies]
              updated[index] = response.company
              return updated
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    setIsAddCompanyModalOpen(false)
  }

  const handleDeleteCompany = async (company) => {
    setButtonLoad(true)
    Http.postData('companies/deleteCompany', company)
      .then((res) => {
        if (res && res.status) {
          setCompanies(companies.filter(comp => comp._id !== company._id))
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setButtonLoad(false)
      })
  }

  return (
    <>
      <AddCompanyModal open={isAddCompanyModalOpen} onClose={handleCloseModal} loading={buttonLoad} featureFlags={featureFlags} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <Button
          variant='outlined'
          sx={{
            color: 'white',
            backgroundColor: '#3983F7',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px',
            borderRadius: 10,
            '&:hover': {
              backgroundColor: 'black',
              color: 'white'
            }
          }}
          startIcon={<AddIcon />}
          onClick={handleOpenCompanyModal}
        >
          Add a Company
        </Button>
      </Box>
      {isLoading
        ? <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
        : <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Email Domains</StyledTableCell>
                <StyledTableCell>Website URL</StyledTableCell>
                <StyledTableCell>Connections Count</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                companies.length > 0
                  ? companies.map((company) => {
                      if (!company) return null;
                      return (
                        <CompanyItem
                          key={company.name}
                          company={company}
                          handleCloseModal={handleCloseModal}
                          handleDeleteCompany={handleDeleteCompany}
                          buttonLoad={buttonLoad}
                          featureFlags={featureFlags}
                        />
                      );
                    })
                  : (
                    <Typography align='right' gutterBottom>
                      There is no company exists with this environment.
                    </Typography>
                  )
              }
            </TableBody>
          </Table>
        </TableContainer>}
    </>
  )
}
