import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case "primary":
      colorScheme = {
        text: theme.palette.primaryCL.White110,
        background: theme.palette.primaryCL.Blue100,
        hoverText: theme.palette.primaryCL.White110,
        hoverBackground: theme.palette.primaryCL.Blue90,
        activeText: theme.palette.primaryCL.White110,
        activeBackground: theme.palette.primaryCL.Blue110,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.Black50,
      };
      break;

    case "secondary":
      colorScheme = {
        text: theme.palette.primaryCL.Blue100,
        background: theme.palette.primaryCL.Blue50,
        hoverText: theme.palette.primaryCL.Blue100,
        hoverBackground: theme.palette.primaryCL.Blue40,
        activeText: theme.palette.primaryCL.Blue100,
        activeBackground: theme.palette.primaryCL.Blue60,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.Black50,
      };
      break;

    case "outlined":
      colorScheme = {
        text: theme.palette.primaryCL.Blue100,
        background: theme.palette.primaryCL.White110,
        hoverText: theme.palette.primaryCL.Blue90,
        hoverBackground: theme.palette.primaryCL.White110,
        activeText: theme.palette.primaryCL.Blue110,
        activeBackground: theme.palette.primaryCL.White110,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.White110,
        border: `1px solid ${theme.palette.primaryCL.Black70}`,
        hoverBorder: `1px solid ${theme.palette.primaryCL.Blue100}`,
        activeBorder: `1px solid ${theme.palette.primaryCL.Blue110}`,
        disabledBorder: `1px solid ${theme.palette.primaryCL.Black50}`,
      };
      break;

    case "plain":
      colorScheme = {
        text: theme.palette.primaryCL.Blue100,
        background: theme.palette.primaryCL.White100,
        hoverText: theme.palette.primaryCL.Blue90,
        hoverBackground: theme.palette.primaryCL.Blue40,
        activeText: theme.palette.primaryCL.Blue110,
        activeBackground: theme.palette.primaryCL.White110,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.White110,
      };
      break;

    case "black":
      colorScheme = {
        text: theme.palette.primaryCL.White100,
        background: theme.palette.primaryCL.Black200,
        hoverText: theme.palette.primaryCL.Black70,
        hoverBackground: theme.palette.primaryCL.White100,
        activeText: theme.palette.primaryCL.Black70,
        activeBackground: theme.palette.primaryCL.Black70,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.Black50,
      };
      break;

    case "black50":
      colorScheme = {
        text: theme.palette.primaryCL.Black200,
        background: theme.palette.primaryCL.Black50,
        hoverText: theme.palette.primaryCL.Black200,
        hoverBackground: theme.palette.primaryCL.Black50,
        activeText: theme.palette.primaryCL.Black70,
        activeBackground: theme.palette.primaryCL.Black200,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.Black50,
        hoverBorder: `1px solid ${theme.palette.primaryCL.Black70}`,
      };
      break;

    case "red":
      colorScheme = {
        text: theme.palette.primaryCL.Red100,
        background: theme.palette.primaryCL.Red50,
        hoverText: theme.palette.primaryCL.Red100,
        hoverBackground: theme.palette.primaryCL.Red40,
        activeText: theme.palette.primaryCL.Red100,
        activeBackground: theme.palette.primaryCL.Red60,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.Black50,
        hoverBorder: `1px solid ${theme.palette.primaryCL.Red100}`,
      };
      break;

    case "blue":
      colorScheme = {
        text: theme.palette.primaryCL.Blue100,
        background: theme.palette.primaryCL.Blue50,
        hoverText: theme.palette.primaryCL.Blue100,
        hoverBackground: theme.palette.primaryCL.Blue50,
        activeText: theme.palette.primaryCL.Blue110,
        activeBackground: theme.palette.primaryCL.Blue110,
        disabledText: theme.palette.primaryCL.Blue50,
        disabledBackground: theme.palette.primaryCL.Blue110,
        hoverBorder: `1px solid ${theme.palette.primaryCL.Blue100}`,
      };
      break;

    case "green":
      colorScheme = {
        text: theme.palette.primaryCL.Green100,
        background: theme.palette.primaryCL.Green50,
        hoverText: theme.palette.primaryCL.Green50,
        hoverBackground: theme.palette.primaryCL.Green100,
        activeText: theme.palette.primaryCL.Green50,
        activeBackground: theme.palette.primaryCL.Green100,
        disabledText: theme.palette.primaryCL.Green50,
        disabledBackground: theme.palette.primaryCL.Green110,
        hoverBorder: `1px solid ${theme.palette.primaryCL.Green100}`,
      };
      break;

    case "plainBlack":
      colorScheme = {
        text: theme.palette.primaryCL.Black200,
        background: theme.palette.primaryCL.White100,
        hoverText: theme.palette.primaryCL.Blue100,
        hoverBackground: theme.palette.primaryCL.Blue40,
        activeText: theme.palette.primaryCL.Blue100,
        activeBackground: theme.palette.primaryCL.Black70,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.White110,
      };
      break;

    case 'plainBlackOutlined':
      colorScheme = {
        text: theme.palette.primaryCL.Black200,
        background: theme.palette.primaryCL.White100,
        hoverText: theme.palette.primaryCL.Blue100,
        hoverBackground: theme.palette.primaryCL.Blue40,
        activeText: theme.palette.primaryCL.Blue100,
        activeBackground: theme.palette.primaryCL.Black70,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.White110,
      };
      break;

    case "plainRed":
      colorScheme = {
        text: theme.palette.primaryCL.Red100,
        background: theme.palette.primaryCL.White100,
        hoverText: theme.palette.primaryCL.Red100,
        hoverBackground: theme.palette.primaryCL.Red50,
        activeText: theme.palette.primaryCL.Red50,
        activeBackground: theme.palette.primaryCL.Red100,
        disabledText: theme.palette.primaryCL.Red60,
        disabledBackground: theme.palette.primaryCL.White110,
      };
      break;

    case "plainGreen":
      colorScheme = {
        text: theme.palette.primaryCL.Green100,
        background: theme.palette.primaryCL.White100,
        hoverText: theme.palette.primaryCL.Green100,
        hoverBackground: theme.palette.primaryCL.Green50,
        activeText: theme.palette.primaryCL.Green50,
        activeBackground: theme.palette.primaryCL.Green100,
        disabledText: theme.palette.primaryCL.Green60,
        disabledBackground: theme.palette.primaryCL.White110,
      };
      break;

    case "stepper":
      colorScheme = {
        text: theme.palette.primaryCL.Black200,
        background: theme.palette.primaryCL.Black50,
        hoverText: theme.palette.primaryCL.Blue90,
        hoverBackground: theme.palette.primaryCL.Blue40,
        activeText: theme.palette.primaryCL.Blue110,
        activeBackground: theme.palette.primaryCL.White110,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.White110,
        padding: "14px",
        height: "40px",
        width: "40px !important",
      };
      break;
    default: // same as primary
      colorScheme = {
        text: theme.palette.primaryCL.White110,
        background: theme.palette.primaryCL.Blue100,
        hoverText: theme.palette.primaryCL.White110,
        hoverBackground: theme.palette.primaryCL.Blue90,
        activeText: theme.palette.primaryCL.White110,
        activeBackground: theme.palette.primaryCL.Blue110,
        disabledText: theme.palette.primaryCL.Black70,
        disabledBackground: theme.palette.primaryCL.Black50,
      };
      break;
  }

  return colorScheme;
};

// If a button has any borders, we must ensure it has transparent borders of the
// same width in all states, unless otherwise specified
const getBiggestBorder = (borders) => {
  try {
    const borderSizes = borders.map((b) => b && parseInt(b.split(" ")[0].replace("px", ""))).filter((b) => b);
    const biggestBorder = Math.max(...borderSizes);
    if (!biggestBorder) {
      return undefined;
    }
    return `${biggestBorder}px solid transparent`;
  } catch (e) {
    console.log(e);
  }
};

const StyledPButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "pVariant",
})(({ theme, active, pVariant, sx }) => {
  const {
    background,
    text,
    hoverBackground,
    hoverText,
    activeBackground,
    activeText,
    disabledBackground,
    disabledText,
    border,
    hoverBorder,
    activeBorder,
    disabledBorder,
    padding,
    height,
    width,
  } = getVariantStyles(theme, pVariant);

  // Ensure the buttons don't have different border sizes in different states.
  // This prevents the button from jumping around when hovered or clicked when
  // the border is added / removed.
  const trueBorder = border ?? getBiggestBorder([hoverBorder, activeBorder, disabledBorder]);
  const trueHoverBorder = hoverBorder ?? getBiggestBorder([border, activeBorder, disabledBorder]);
  const trueActiveBorder = activeBorder ?? getBiggestBorder([border, hoverBorder, disabledBorder]);
  const trueDisabledBorder = disabledBorder ?? getBiggestBorder([border, hoverBorder, activeBorder]);

  return {
    borderRadius: "14px",
    border: trueBorder || "none",
    padding: padding || "16px 20px",
    height: height || "52px",
    width: width || "auto",
    minWidth: width || "auto",
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "120%",
    textTransform: "none",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    color: active ? activeText : text,
    backgroundColor: background,
    "& svg": {
      fill: text,
    },
    "&:hover": {
      backgroundColor: hoverBackground,
      color: hoverText,
      border: trueHoverBorder || "none",
      "& svg": {
        fill: hoverText,
      },
    },
    "&:active": {
      backgroundColor: activeBackground,
      color: activeText,
      border: trueActiveBorder || "none",
      "& svg": {
        fill: activeText,
      },
    },
    "&:disabled": {
      backgroundColor: disabledBackground,
      color: disabledText,
      border: trueDisabledBorder || "none",
      "& svg": {
        fill: disabledText,
      },
    },
    ...sx,
  };
});

function PButton({ children, active, Icon, pVariant, disabled, onClick, additionalStyles, isIconicButton, iconProps, sx }) {
  // Map our variant to the MUI variant
  const translatedVariant =
    {
      primary: "contained",
      secondary: "contained", // 'secondary' is just a different color scheme
      black: "contained", // 'black' is just a different color scheme
      outlined: "outlined",
      plain: "text",
      plainBlack: "text",
    }[pVariant] || "contained";

  return isIconicButton ? (
    <StyledPButton
      active={active}
      variant={translatedVariant}
      disableElevation
      pVariant={pVariant}
      disabled={disabled}
      onClick={onClick}
      sx={{ additionalStyles, ...sx }}
    >
      <Icon {...iconProps} />
    </StyledPButton>
  ) : (
    <StyledPButton
      active={active}
      variant={translatedVariant}
      disableElevation
      pVariant={pVariant}
      startIcon={Icon ? <Icon {...iconProps} /> : null}
      disabled={disabled}
      onClick={onClick}
      sx={{ additionalStyles, ...sx }}
    >
      {children}
    </StyledPButton>
  );
}

PButton.propTypes = {
  /** React children */
  children: PropTypes.node,

  /** Icon component to display in the button.
   * NOTE: Storybook does not support React components in controls */
  Icon: PropTypes.elementType,

  /** Postilize design variant, separate from MUI button variant. MUI button variant is determined automatically */
  pVariant: PropTypes.oneOf([
    "primary",
    "secondary",
    "outlined",
    "plain",
    "black",
    "red",
    "blue",
    "green",
    "plainBlack",
    "plainRed",
    "plainGreen",
    "stepper",
  ]),

  /** Disabled state of the button */
  disabled: PropTypes.bool,

  /** Click handler */
  onClick: PropTypes.func,

  /** Additional styles to apply to the Button component */
  additionalStyles: PropTypes.object,

  /** Sets the button to icon-only mode */
  isIconicButton: PropTypes.bool,

  /** Extra props to pass to the Icon component */
  iconProps: PropTypes.object,
};

PButton.defaultProps = {
  Icon: null,
  disabled: false,
  pVariant: "primary",
  additionalStyles: {},
  iconProps: {},
  isIconicButton: false,
};

export default PButton;
