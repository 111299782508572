import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbar } from '@/contexts/SnackbarContext';
import { ReactComponent as Search } from '@Assets/Icons/Search.svg';
import {
  fetchRemainingInvites,
  getCompany,
  removeTeamMembers,
  roleChange,
  getSequences,
  getLists,
  getListContacts,
} from '@/helper/apiHelper';
import {
  setDelegationSequences,
  setDelegationAccessId,
  setActionFlag,
  setDelegationLists,
  setDelegationListContacts,
} from '@/redux/authSlice';

import InviteMember from '@CommonScreens/InviteMember';
import featureFlagService from '@/services/featureFlagService';
import DelegationSequences from '@/pages/ClientAcquisition/DelegationSequences';
import DelegationLists from '@/pages/Lists/DelegationLists';

import { PModal, PContactList, PYesNoDialog } from '@Library';

const Members = ({ currentUser }) => {
  const dispatch = useDispatch();
  const { addSnackbar } = useSnackbar();
  const [company, setCompany] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [contacts, setContacts] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [remainingInvites, setRemainingInvites] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [isTeamsAdminEnabled, setIsTeamsAdminEnabled] = useState(false);
  const [isManageAssistantsModalOpen, setIsManageAssistantsModalOpen] = useState(false);
  const [isManageContactsModalOpen, setIsManageContactsModalOpen] = useState(false);
  const [shouldOpenAssistantsModal, setShouldOpenAssistantsModal] = useState(false);
  const [shouldOpenListsModal, setShouldOpenListsModal] = useState(false);
  const [isDelegateModalOpen, setIsDelegateModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);

  const { refreshDelegationSequences, refreshDelegationLists } = useSelector((state) => state.auth.actionFlags);
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const delegationAccessId = useSelector((state) => state.auth.delegationAccessId);

  const fetchCompanyDetails = useCallback(async () => {
    setLoading(true);
    try {
      const companyDetails = await getCompany();
      if (companyDetails) {
        setCompany({ ...companyDetails.company, users: companyDetails.users });
        setContacts(companyDetails.users);
        setIsAdmin(companyDetails.company.admins.includes(currentUser?._id));
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    fetchCompanyDetails();
  }, [fetchCompanyDetails]);

  const checkRemainingInvites = useCallback(async () => {
    if (company) {
      const invites = await fetchRemainingInvites(company._id);
      setRemainingInvites(invites);
    }
  }, [company]);

  useEffect(() => {
    checkRemainingInvites();
  }, [company, checkRemainingInvites]);

  useEffect(() => {
    const isEnabled = featureFlagService.isFeatureEnabled('ui.teamsAdministrationEnabled', featureFlags);
    setIsTeamsAdminEnabled(isEnabled);
  }, [featureFlags]);

  const getFilteredContacts = useCallback(() => {
    const filteredByRoleAndInvites =
      contacts?.filter((c) => {
        if (activeFilter === 'All') return true;

        // Does this currently do anything?
        if (activeFilter === 'Invited') return c.status === 'invited'; // Assuming 'status' is the field to check

        if (activeFilter === 'Delegates') return c.userType === 'Delegate';

        const isAdmin = company.admins.includes(c._id);
        return (
          (activeFilter === 'Admins' && isAdmin) ||
          (activeFilter === 'Members' && !isAdmin && c.userType !== 'Delegate')
        );
      }) || [];

    if (!searchQuery) return filteredByRoleAndInvites;
    return filteredByRoleAndInvites.filter((c) => c.name.toLowerCase().includes(searchQuery));
  }, [contacts, activeFilter, searchQuery, company]);

  const handleFilterChange = useCallback((filter) => {
    setActiveFilter(filter);
    setSelectedContacts([]);
  }, []);

  const handleAction = useCallback(
    async (action) => {
      if (!selectedContacts.length) {
        return;
      }

      let shouldRefetch = false; // Initialize shouldRefetch to false

      switch (action) {
        case 'Promote':
          // Assuming 'admin' is the role for promotion
          const promoteSuccess = await roleChange(selectedContacts, 'admin');
          if (promoteSuccess) {
            shouldRefetch = true;
            addSnackbar({
              message: `Promoted successfully`,
              pVariant: 'success',
              hideAction: true,
            });
          } else {
            console.error(`Failed to promote member ${selectedContacts}`);
          }
          break;
        case 'Demote':
          const demoteSuccess = await roleChange(selectedContacts, 'member');
          if (demoteSuccess) {
            shouldRefetch = true;
            addSnackbar({
              message: `Demoted successfully`,
              pVariant: 'success',
              hideAction: true,
            });
          } else {
            console.error(`Failed to demote member ${selectedContacts}`);
          }
          break;
        case 'Remove':
          const removeSuccess = await removeTeamMembers(selectedContacts);
          if (removeSuccess) {
            shouldRefetch = true;
            addSnackbar({
              message: `Removed successfully`,
              pVariant: 'success',
              hideAction: true,
            });
            setCompany((prev) => ({
              ...prev,
              users: prev.users.filter((user) => user._id !== selectedContacts),
            }));
          } else {
            console.error(`Failed to remove member ${selectedContacts}`);
          }
          break;
        default:
          return;
      }

      // Refetch the latest company data if any action was successful
      if (shouldRefetch) {
        await fetchCompanyDetails();
      }

      // Clear the selection after action is complete
      setSelectedContacts([]);
    },
    [selectedContacts, addSnackbar, fetchCompanyDetails],
  );

  const custFilterButtons = useMemo(
    () => [
      { label: 'All', isDestructive: false },
      { label: 'Admins', isDestructive: false },
      { label: 'Members', isDestructive: false },
      // { label: 'Delegates', isDestructive: false },
      // { label: "Invited", isDestructive: false },
    ],
    [],
  );

  const filterButtons = useMemo(() => [{ label: 'Remove', isDestructive: true }], []);

  const handleToggleAll = useCallback(
    (newChecked) => {
      if (newChecked) {
        setSelectedContacts(getFilteredContacts().map((contact) => contact._id));
      } else {
        setSelectedContacts([]);
      }
    },
    [getFilteredContacts],
  );

  const handleSearchUser = useCallback((event) => {
    event.preventDefault();
    setSelectedContacts([]);
    setSearchQuery(event.target.value.toLowerCase());
  }, []);

  const handleContactSelection = useCallback((contactId) => {
    setSelectedContacts((prevSelected) => {
      const index = prevSelected.indexOf(contactId);
      const newSelected = index === -1 ? [...prevSelected, contactId] : prevSelected.filter((id) => id !== contactId);
      return newSelected;
    });
  }, []);

  const handleContactDelegate = useCallback((contact) => {
    setSelectedContact(contact);
    setIsDelegateModalOpen(true);
  }, []);

  const handleContactManageAssistants = useCallback(
    async (contact) => {
      const accessId = contact._id;
      dispatch(setDelegationAccessId(accessId));
      dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: true }));
      setShouldOpenAssistantsModal(true);
    },
    [dispatch],
  );

  const handleContactManageContacts = useCallback(
    async (contact) => {
      const accessId = contact._id;
      dispatch(setDelegationAccessId(accessId));
      dispatch(setActionFlag({ flag: 'refreshDelegationLists', value: true }));
      setShouldOpenListsModal(true);
    },
    [dispatch],
  );

  useEffect(() => {
    if (refreshDelegationSequences && delegationAccessId) {
      dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: false }));

      async function fetchSequences() {
        const sequences = await getSequences(delegationAccessId);
        dispatch(setDelegationSequences(sequences));
        if (shouldOpenAssistantsModal) {
          setIsManageAssistantsModalOpen(true);
          setShouldOpenAssistantsModal(false);
        }
      }
      fetchSequences();
    }
  }, [refreshDelegationSequences, delegationAccessId, shouldOpenAssistantsModal, dispatch]);

  useEffect(() => {
    if (refreshDelegationLists && delegationAccessId) {
      dispatch(setActionFlag({ flag: 'refreshDelegationLists', value: false }));

      async function fetchLists() {
        const lists = await getLists(delegationAccessId);
        dispatch(setDelegationLists(lists));
        if (shouldOpenListsModal) {
          setIsManageContactsModalOpen(true);
          setShouldOpenListsModal(false);
        }
      }
      fetchLists();
    }
  }, [delegationAccessId, dispatch, refreshDelegationLists, shouldOpenListsModal]);

  const handleContactRemove = useCallback(
    (contact) => {
      const isAdmin = company.admins.includes(contact._id);
      if (!isAdmin) {
        setContactToDelete(contact);
        setIsDeleteDialogOpen(true);
      } else {
        addSnackbar({
          message: `Cannot remove an admin`,
          pVariant: 'error',
          hideAction: true,
        });
      }
    },
    [company, addSnackbar]
  );

  const handleDeleteConfirm = useCallback(async () => {
    if (contactToDelete) {
      try {
        const removeSuccess = await removeTeamMembers([contactToDelete._id]);
        if (removeSuccess) {
          addSnackbar({
            message: `Removed successfully`,
            pVariant: 'success',
            hideAction: true,
          });
          setCompany((prev) => ({
            ...prev,
            users: prev.users.filter((user) => user._id !== contactToDelete._id),
          }));
        } else {
          console.error(`Failed to remove member ${contactToDelete._id}`);
          addSnackbar({
            message: `Failed to remove member`,
            pVariant: 'error',
            hideAction: true,
          });
        }
      } catch (error) {
        console.error('Error removing contact:', error);
        addSnackbar({
          message: `Failed to remove member`,
          pVariant: 'error',
          hideAction: true,
        });
      }
    }
    setIsDeleteDialogOpen(false);
    setContactToDelete(null);
  }, [contactToDelete, addSnackbar, setCompany]);

  const hasRemainingInvites = remainingInvites > 0;
  const disableAddMemberBtn = !isAdmin || !hasRemainingInvites;

  const pContactListProps = useMemo(() => {
    const props = {
      headerText: 'Members',
      inputPlaceholder: 'Search name, role, company',
      inputIcon: Search,
      inputOnChange: handleSearchUser,
      custFilterButtons: selectedContacts.length ? filterButtons : custFilterButtons,
      activeFilter: activeFilter,
      filterAction: handleAction,
      filterChange: handleFilterChange,
      filterToggleAll: handleToggleAll,
      filterTotalCount: getFilteredContacts().length,
      filterSelectedCount: selectedContacts.length,
      contacts: getFilteredContacts()?.map((user) => ({
        _id: user._id,
        name: user.name?.trim().length > 0 ? user.name : user.primaryEmail,
        photo_url: user.profileUrl,
        subtitle: user.permissionLevel,
        checked: selectedContacts.includes(user._id),
        isChecked: selectedContacts.includes(user._id),
        onCheck: () => handleContactSelection(user._id),
        optionHandlers: {
          delegate: () => handleContactDelegate(user),
          manageAssistants: () => handleContactManageAssistants(user),
          manageContacts: () => handleContactManageContacts(user),
          remove: () => handleContactRemove(user),
        },
      })),
      disableCheckbox: !isAdmin,
      loadingState: isLoading,
    };

    if (isTeamsAdminEnabled) {
      props.header1Text = 'Invite';
      props.header1Theme = 'primary';
      props.header1OnClick = () => setOpenInviteModal(true);
      props.header1Disabled = disableAddMemberBtn;
    }

    return props;
  }, [
    activeFilter,
    custFilterButtons,
    disableAddMemberBtn,
    filterButtons,
    getFilteredContacts,
    handleAction,
    handleContactDelegate,
    handleContactManageAssistants,
    handleContactManageContacts,
    handleContactRemove,
    handleContactSelection,
    handleFilterChange,
    handleSearchUser,
    handleToggleAll,
    isAdmin,
    isLoading,
    isTeamsAdminEnabled,
    selectedContacts,
  ]);

  // const StyledStack = ({ theme, children }) => {
  //   return (
  //     <Stack gap="16px" border={`1px solid ${theme.palette.primaryCL.Black70}`} borderRadius="4px" padding="16px">
  //       {children}
  //     </Stack>
  //   );
  // };

  // const DelegateTaskModal = ({ open, onClose, contact }) => {
  //   const theme = useTheme();
  //   const [selectedUser, setSelectedUser] = useState('');

  //   const handleDelegate = () => {
  //     // Logic to delegate tasks
  //     console.log('Delegating tasks from', contact, 'to', selectedUser);
  //     onClose();
  //   };

  //   return (
  //     <PModal
  //       floating
  //       floatingDimensions={{ width: '744px', height: 'fit-content' }}
  //       ultraWideContent
  //       inlineHeaderText
  //       headerText="Delegate Tasks"
  //       pVariant="primary"
  //       open={open}
  //       onClose={onClose}
  //       actionButtons={[
  //         <PButton pVariant="plain" onClick={onClose}>
  //           <Close fill="black" />
  //         </PButton>,
  //       ]}
  //     >
  //       <Stack gap="28px">
  //         <Box>
  //           <PTypography size="body2" weight="bold">
  //             Delegate tasks from {contact?.name}
  //           </PTypography>
  //         </Box>
  //         <StyledStack theme={theme}>
  //           <PTypography size="body2" weight="bold">
  //             Select User to Delegate To
  //           </PTypography>
  //           <PDropdownVDeux
  //             pVariant="grey"
  //             displayAs="text"
  //             buttonText="Select User"
  //             inputValue={selectedUser}
  //             menu={{
  //               menuList: members.map((member) => ({
  //                 isEnabled: true,
  //                 label: member.name,
  //                 onSubmit: () => setSelectedUser(member.name),
  //                 isSelected: selectedUser === member.name,
  //               })),
  //             }}
  //           />
  //         </StyledStack>
  //         <Box display="flex" justifyContent="flex-end">
  //           <PButton pVariant="black" onClick={handleDelegate}>
  //             Delegate
  //           </PButton>
  //         </Box>
  //       </Stack>
  //     </PModal>
  //   );
  // };

  return (
    <>
      <PContactList {...pContactListProps} />
      {openInviteModal && (
        <InviteMember
          members={contacts.filter((contact) => contact.userType !== 'Delegate')}
          delegates={contacts.filter((contact) => contact.userType === 'Delegate')}
          open={openInviteModal}
          onClose={() => setOpenInviteModal(false)}
          fetchCompanyDetails={fetchCompanyDetails}
        />
      )}
      {isManageAssistantsModalOpen && (
        <PModal
          floating
          ultraWideContent
          showCloseButton
          onClose={() => setIsManageAssistantsModalOpen(false)}
          padding="0"
        >
          <DelegationSequences onClose={() => setIsManageAssistantsModalOpen(false)} />
        </PModal>
      )}
      {isManageContactsModalOpen && (
        <PModal
          floating
          ultraWideContent
          showCloseButton
          floatingDimensions={{
            width: 'auto',
            height: '95vh',
          }}
          onClose={() => setIsManageContactsModalOpen(false)}
        >
          <DelegationLists />
        </PModal>
      )}
      {/* {isDelegateModalOpen && (
        <DelegateTaskModal
          open={isDelegateModalOpen}
          onClose={() => setIsDelegateModalOpen(false)}
          contact={selectedContact}
        />
      )} */}
      <PYesNoDialog
        open={isDeleteDialogOpen}
        onYes={handleDeleteConfirm}
        onNo={() => setIsDeleteDialogOpen(false)}
        message="Delete Member?"
        detailedMessage={`Are you sure you want to remove ${contactToDelete?.name} from the team?`}
        yesText="Delete"
        noText="Cancel"
      />
    </>
  );
};

export default Members;
