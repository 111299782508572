import React from 'react';
import { Home, Users, Settings, Briefcase } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '@/redux/authSlice';
import { GetCookie } from '@/helper/cookieManager';
import { UserConst } from '@/constants/userConst';

import PSidebar from '@Library/PSidebar';

import BrandTopics from '@/pages/setup/simpleSetupComponents/Brands/brandTopics';
import Socials from '@/pages/setup/simpleSetupComponents/Brands/socials';
import httpClient from '@/http/httpClient';

function Sidebar({ full }) {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.auth.page);
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const [socialsModalOpen, setSocialsModalOpen] = React.useState(false);
  const [topicsModalOpen, setTopicsModalOpen] = React.useState(false);
  const [isFetchingTopics, setIsFetchingTopics] = React.useState(false);

  const [menu, setMenu] = React.useState([]);
  const [topics, setTopics] = React.useState();
  const [user, setUser] = React.useState({ name: null, type: null });

  const curUser = React.useMemo(() => GetCookie('user'), []);

  // When the topics modal is closed, we want to fetch the topics again,
  // so we check the previous value of topicsModalOpen to see if it was open.
  React.useEffect(() => {
    const fetchFn = async () => {
      if (topicsModalOpen) {
        try {
          const topicsResponse = await fetchTopics();
          if (topicsResponse?.topics?.length > 0) {
            setTopics(topicsResponse.topics);
          }
        } catch (error) {
          console.error('Failed to set modal:', error);
        }
      }
    };

    fetchFn();
  }, [topicsModalOpen]);

  const updateMenu = (baseMenu) => {
    const updatedMenu = baseMenu.map((item) => ({
      ...item,
      active: item.page === currentPage,
    }));

    setMenu(updatedMenu);
  }

  React.useEffect(() => {
    console.log('featureFlags', featureFlags);
    if (featureFlags) {
      const sbClientRetention = { title: 'Home', icon: Home, page: 'retention', active: false };
      const sbClientAcquisition = { title: 'Assistants', icon: Users, page: 'acquisition', active: false };
      const sbSettings = { title: 'Settings', icon: Settings, page: 'settings', active: false };
      const sbTeams = { title: 'Teams', icon: Users, page: 'teams', active: false };
      const sbClients = { title: 'Clients', icon: Briefcase, page: 'clients', active: false };

      let sbItems = [sbClientRetention];
      let sequencesFeature = featureFlags?.find((x) => x.name === 'sequences');
      if (sequencesFeature?.isFeatureEnabled) {
        sbItems.push(sbClientAcquisition);
      }
      if (curUser.companyId) {
        sbItems.push(sbTeams);
      }
      const ermDashboardFeature = featureFlags?.find((x) => x.name === 'ui.erm_dashboard');
      if (ermDashboardFeature?.isFeatureEnabled) {
        sbItems.push(sbClients);
      }
      sbItems.push(sbSettings);

      updateMenu(sbItems);
    }
  }, [featureFlags]);

  React.useEffect(() => {
    updateMenu(menu);
  }, [currentPage]);

  React.useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      name: curUser.name,
      type: curUser.userType === UserConst.UserTypes.TRIAL_USER ? 'Free Plan' : 'Premium',
    }));
  }, [curUser]);

  const handleMenuItemClick = (title, hasSubmenu, page) => {
    if (page) {
      dispatch(setPage(page));
    }
  };

  const fetchTopics = async () => {
    try {
      const res = await httpClient.getData('getTopics');
      return res;
    } catch (error) {
      console.error('Failed to fetch topics:', error);
      return null;
    }
  };

  const setModal = async () => {
    setIsFetchingTopics(true);
    setTopicsModalOpen(true);

    try {
      const topicsResponse = await fetchTopics();
      if (topicsResponse?.topics?.length > 0) {
        setTopics(topicsResponse.topics);
      }
    } catch (error) {
      console.error('Failed to set modal:', error);
    } finally {
      setIsFetchingTopics(false);
    }
  };

  const closeSocialsModal = () => setSocialsModalOpen(false);
  const closeTopicsModal = () => setTopicsModalOpen(false);

  return (
    <>
      <PSidebar
        pVariant="primary"
        // full={full}
        full={true}
        user={user}
        menu={menu}
        postOnClick={setModal}
        menuOnClick={handleMenuItemClick}
      />
      {topicsModalOpen && (
        <BrandTopics
          open={topicsModalOpen}
          onClose={closeTopicsModal}
          source="ComposePost"
          selectedTopicsSetter={setTopics}
          initialSelectedTopics={topics}
          isLoading={isFetchingTopics}
        />
      )}
      {socialsModalOpen && (
        <Socials open={socialsModalOpen} onClose={closeSocialsModal} source="ComposePost" topics={topics} />
      )}
    </>
  );
}

export default Sidebar;
