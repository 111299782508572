import React from 'react';
import { useTheme, InputLabel } from '@mui/material';

export default function FundzDataRecordMatches() {
  return (
    <div>
      <h1>FundzDataRecordMatches</h1>
    </div>
  );
}
