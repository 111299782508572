import * as React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";

import PProgressStepper from "./PProgressStepper";
import PProgressBar from "./PProgressBar";

const dialogTitleStyle = { px: "40px", pb: "24px" };
const dialogContentStyle = { px: "40px", pb: "24px" };
const progressStepperStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};
const progressBarStyle = { width: "100%", paddingTop: "24px" };
const backButtonContainerStyle = { width: "60px" };
const actionButtonsContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "16px",
};
function PModalFullscreen(props) {
  const {
    children,
    open,
    onClose,
    progressList,
    progressPercent,
    backButton,
    actionButtons,
  } = props;

  const [showDialogHeader, setShowDialogHeader] = React.useState(true);
  React.useEffect(() => {
    const conditions = [
      progressPercent !== undefined && progressPercent !== null, // render progress bar?
      progressList?.length > 0, // render progress stepper?
      !!backButton, // render back button?
      actionButtons?.length > 0, // render action buttons?
    ];

    // Show the dialog header if any of the render conditions are true
    setShowDialogHeader(conditions.some((condition) => condition));
  }, [progressPercent, progressList, backButton, actionButtons]);

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const progressStepperElement = React.useMemo(
    () =>
      progressList && (
        <div style={progressStepperStyle}>
          <PProgressStepper list={progressList} />
        </div>
      ),
    [progressList]
  );

  const progressBarElement = React.useMemo(
    () =>
      progressPercent && (
        <span style={progressBarStyle}>
          <PProgressBar progress={progressPercent} />
        </span>
      ),
    [progressPercent]
  );

  const backButtonElement = React.useMemo(
    () =>
      backButton && <span style={backButtonContainerStyle}>{backButton}</span>,
    [backButton]
  );

  const actionButtonsElement = React.useMemo(() => {
    if (!actionButtons || !actionButtons?.length) {
      return null;
    }

    return (
      <div style={actionButtonsContainerStyle}>
        {actionButtons.map((button, index) => (
          <React.Fragment key={index}>{button}</React.Fragment>
        ))}
      </div>
    );
  }, [actionButtons]);

  const headerContainerElement = React.useMemo(
    () => {
      // If there are only action buttons on the top row,
      // we want to right-align them so they're in the top right
      let justifyContent = "space-between";
      const stepperOnFirstRow = actionButtons?.length <= 1 && !!progressStepperElement;
      if (!stepperOnFirstRow && !backButtonElement && actionButtonsElement) {
        justifyContent = "flex-end";
      }

      return (
        <>
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent={justifyContent}
            display="flex"
          >
            {backButtonElement}
            {
              // Render the stepper on the top row if there aren't too many action buttons
              actionButtons?.length <= 1 && progressStepperElement
            }
            {actionButtonsElement}
          </Grid>
          <Grid item xs={12}>
            {
              // Render the stepper on the bottom row if there are too many action buttons
              actionButtons?.length > 1 && progressStepperElement
            }
            {progressBarElement}
          </Grid>
        </>
      );
    },
    [
      backButtonElement,
      actionButtons?.length,
      progressStepperElement,
      actionButtonsElement,
      progressBarElement,
    ]
  );

  const fullscreenDialogWrapper = React.useMemo(
    () => (
      <Dialog fullScreen open={open} onClose={handleClose}>
        {showDialogHeader && (
          <DialogTitle sx={dialogTitleStyle}>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
            >
              {headerContainerElement}
            </Grid>
          </DialogTitle>
        )}
        <DialogContent sx={dialogContentStyle}>{children}</DialogContent>
      </Dialog>
    ),
    [open, handleClose, showDialogHeader, headerContainerElement, children]
  );

  return fullscreenDialogWrapper;
}

PModalFullscreen.propTypes = {
  /** Dialog open status */
  open: PropTypes.bool,

  /** Close function - back button does not show if this is not provided */
  onClose: PropTypes.func,

  /** Optional progress stepper list - required for progress stepper */
  progressList: PropTypes.array,

  /** Optional progress percent - required for progress bar  - either string or number */
  progressPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Optional, shows a loading spinner instead of any children */
  loadingNext: PropTypes.bool,

  /** Optional back button */
  backButton: PropTypes.node,

  /** Optional action buttons */
  actionButtons: PropTypes.array,
};

PModalFullscreen.defaultProps = {
  progressStepper: false,
  progressBar: false,
  loadingNext: false,
  backButton: null,
  actionButtons: null,
};

export default PModalFullscreen;
