import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';

import CustomModal from '../../login/components/confirmModal';
import { Regexes } from '../../../constants/constant';
import userInviteService from '../../../services/userInviteService';

export default function AddInviteModal({ open, onClose, loading, users }) {
  const [email, setEmail] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [alertText, setAlertText] = React.useState('');
  const [validateModalOpen, setValidateModalOpen] = React.useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(true);

  const handleSubmit = (event) => {
    if (email.length === 0) {
      let alertPrompt = `email shouldn't be empty.`;
      setAlertText(alertPrompt);
      setValidateModalOpen(true);
      setIsValidEmail(false);
      return;
    }
    if (!isValidEmail) {
      let alertPrompt = `email is invalid.`;
      setAlertText(alertPrompt);
      setValidateModalOpen(true);
      return;
    }
    const invite = {
      invitee: email,
      inviter: selectedUser,
    };
    const response = userInviteService
      .createInvite(invite)
      .then((res) => {
        if (res.error) {
          let alertPrompt = res.error || `Error in creating invite.`;
          setAlertText(alertPrompt);
          setValidateModalOpen(true);
          return;
        }
        onClose(null);
      })
      .catch((error) => {
        let alertPrompt = `Error in creating invite.`;
        setAlertText(alertPrompt);
      });
  };

  const handleModalAction = (action) => {
    setValidateModalOpen(false);
    setAlertText('');
  };

  return (
    <>
      {validateModalOpen && (
        <CustomModal clickAction={handleModalAction} alertText={alertText} />
      )}
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth="true">
        <DialogTitle
          sx={{
            fontWeight: 800,
            fontSize: 18,
            letterSpacing: '-0.02em',
            py: 3,
          }}
        >
          Invite a User
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="email"
                fullWidth
                value={email}
                onChange={(e) => {
                  let emailVal = e.target.value;
                  setEmail(emailVal);
                  setIsValidEmail(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailVal)
                  );
                }}
                sx={{ my: 2 }}
                error={!isValidEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="invitee-label"
                options={users}
                getOptionLabel={(option) => (`${option.name || ''} (${option.email})`)}
                value={selectedUser || null}
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Inviter" fullWidth />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose(null)}
            sx={{
              mx: 1,
              my: 2,
              backgroundColor: '#EEEEEE',
              color: '#09092E',
              fontSize: '1.2rem',
              textTransform: 'none',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            sx={{
              mx: 1,
              my: 2,
              backgroundColor: '#3983F7',
              color: 'white',
              fontSize: '1.2rem',
              textTransform: 'none',
            }}
            disabled={loading}
            startIcon={
              loading && <CircularProgress size={20} color="inherit" />
            }
          >
            {loading ? (
              <React.Fragment>
                <span style={{ color: 'white' }}>Saving...</span>
              </React.Fragment>
            ) : (
              'Add'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
