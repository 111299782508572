import { useState, useCallback } from 'react';
import { Paper, InputBase, IconButton, Box } from '@mui/material';
import { useTheme } from '@mui/material';
import PTypography from '@Library/PTypography';

export default function PTextfield(props) {
  const {
    label,
    labelStyle,
    value,
    rows,
    multiline,
    disabled = false,
    onChange,
    placeholder,
    paperStyle,
    inputStyle,
    onKeyDown,
    onKeyUp,
    onFocus,
    onBlur,
    onClick,
    name,
    InputProps = {},
    Icon,
    fieldName,
  } = props;
  const THEME = useTheme();
  const [isInputFocused, setInputFocused] = useState(false);
  const [isInputHovered, setInputHovered] = useState(false);

  const handleKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const handleKeyUp = (e) => {
    if (onKeyUp) {
      onKeyUp(e);
    }
  };

  const handleFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }

    setInputFocused(true);
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }

    setInputFocused(false);
  };

  const handleClick = useCallback(
    (event) => {
      // If there is a specific handler passed, call it
      if (onClick) {
        onClick(event);
      }
      // Otherwise, do nothing or add default behavior here if necessary
    },
    [onClick],
  );

  const handleMouseEnter = () => setInputHovered(true);
  
  const handleMouseLeave = () => setInputHovered(false);

  const handleChange = (e) => {
    if (fieldName) {
      onChange(fieldName, e.target.value);
    } else {
      onChange(e);
    }
  };

  const typographyStyle = {
    mb: 1,
    ...labelStyle,
  };
  const paperFormStyle = {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 0,
    py: '16px',
    px: '14px',
    margin: '8px',
    borderRadius: '14px',
    border: `1px solid ${isInputFocused || isInputHovered ? THEME.palette.primary.blue : THEME.palette.primaryCL.Black70}`,
    ...paperStyle,
  };
  const iconButtonStyle = { pr: 1 };
  const iconStyle = { width: 2.5, height: 2.5 };
  const boxStyle = { cursor: 'pointer', width: '100%' };
  const inputBaseStyle = {
    width: "100%",
    fontFamily: 'Inter',
    px: 0,
    py: 0,
    my: 0,
    '.MuiInputBase-input': {
      padding: 0,
      fontSize: '16px',
      '&::placeholder': {
      },
    },
    ...inputStyle,
  };

  return (
    <Box>
      {label && (
        <PTypography size="body2" weight="bold" sx={typographyStyle}>
          {label}
        </PTypography>
      )}
      <Paper component="form" sx={paperFormStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {Icon && (
          <IconButton onClick={onClick} sx={iconButtonStyle}>
            <Icon fill={THEME.palette.primaryCL.Black100} sx={iconStyle} />
          </IconButton>
        )}
        <Box onClick={handleClick} sx={boxStyle}>
          <InputBase
            id={props?.id}
            type="text"
            name={name}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            multiline={multiline}
            rows={rows}
            sx={inputBaseStyle}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            {...InputProps}
          />
        </Box>
      </Paper>
    </Box>
  );
}
