import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { useTheme, CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Stack from '@mui/system/Stack';
import Avatar from '@mui/material/Avatar';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import { ReactComponent as Delete } from '../../../../assets/Icons/Delete.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/Icons/Arrow-Left.svg';

import Http from '../../../../http/httpClient';
import SequenceTemplate from '../../../../components/sequence/sequenceTemplate';
import DateTimePrompt from '../../../CommonScreens/DateTimePrompt';

export default function SequenceModal(props) {
  const theme = useTheme();
  const { onClose, open, selectedGoal, createdListId, setupList } = props;

  const [userStrategy, setUserStrategy] = React.useState(selectedGoal?.goal);
  const [sequenceItem, setSequenceItem] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [openChooseTiming, setOpenChooseTiming] = React.useState(false);

  const defaultGoal = { id: 'acquire', title: 'acquire new clients' };
  const selectedGoalItem = selectedGoal?.goal || defaultGoal;
  const [goal, setGoal] = React.useState(selectedGoalItem);
  const [emailDrafted, setEmailDrafted] = React.useState(false);
  const childComponentRef = React.useRef();

  const getUserStrategy = async () => {
    if (userStrategy === undefined) {
      Http.getData('strategy/user')
        .then(function (response) {
          if (response && response.status) {
            setUserStrategy(response.userStrategy);
            setGoal(response.userStrategy);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };

  React.useEffect(() => {
    getUserStrategy();
  }, []);

  React.useEffect(() => {
    setSequenceItem({
      sequenceName: goal.id,
      targetLists: [createdListId],
      template: {
        steps: [],
      },
    });
  }, [userStrategy]);

  const validGiftChoosen = (steps) => {
    let giftChoosen = true;
    steps.forEach((step) => {
      if (
        step.id === 'gift' &&
        !step.emailTemplate?.giftName &&
        !step.emailTemplate?.giftPrice &&
        !step.emailTemplate?.giftDetails?.touchId
      ) {
        setAlertMessage('Please choose a gift.');
        setShowAlert(true);
        return (giftChoosen = false);
      }
    });

    return giftChoosen;
  };

  const handleContinue = (event) => {
    setIsLoading(true);
    if (sequenceItem?.template?.steps?.length === 0) {
      setIsLoading(false);
      setShowAlert(true);
      setAlertMessage('Please add at least one step to continue');
    } else {
      const buttonText = event.target?.innerText.trim();
      console.log('buttontext: ', buttonText);
      if (buttonText === 'Draft Email') {
        childComponentRef.current
          .generateSequenceTemplateEmail()
          .then((result) => {
            if (result === 'Success') {
              setEmailDrafted((prev) => false);
            }
          })
          .catch((error) => {
            console.error('Error generating emails:', error);
          });
      } else if (buttonText === 'Continue') {
        const giftStep = sequenceItem.template?.steps?.filter((s) => s.id === 'gift');
        const isValidStep = giftStep ? validGiftChoosen(giftStep) : true;
        if (isValidStep) setOpenChooseTiming(true);
        setIsLoading(false);
      }
    }
  };

  const handleTimingContinue = () => {
    setIsLoading(true);
    if (sequenceItem.timing) {
      const seqDetails = {
        sequenceDetails: {
          ...sequenceItem,

          template: {
            ...sequenceItem.template,
            steps: sequenceItem.template.steps.map((step) => {
              if (Number.isInteger(step.day)) {
                return {
                  ...step,
                  day: step.day,
                  emailTemplate: step.emailTemplate ? { ...step.emailTemplate, reviewed: true } : null,
                };
              }
              return step;
            }),
          },
        },
      };

      Http.postData('storeSequenceDetails', seqDetails)
        .then((res) => {
          if (res.status) {
            handleModalClose('Continue');
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setShowAlert(true);
      setAlertMessage('Please select a timing to continue');
    }
  };

  const handleDateTimeChange = (dateTime) => {
    setSequenceItem((prev) => {
      return {
        ...prev,
        timing: dateTime,
      };
    });
  };

  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const handleModalClose = React.useCallback(
    (action) => {
      if (action === 'Back') {
        setOpenChooseTiming(false);
      } else if (action === 'Continue') {
        onClose();
      }
    },
    [onClose],
  );

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  const MemoizedDateTimePromptElement = React.useMemo(() => {
    return (
      <DateTimePrompt
        initialDateTime={sequenceItem.timing}
        headerMessage="When do you want this assistant to begin?"
        subheaderMessage="You can pause it at any time."
        handleDateTimeChange={handleDateTimeChange}
      />
    );
  }, [handleDateTimeChange]);

  return (
    <>
      <Dialog onClose={onClose} open={open} fullScreen>
        <DialogTitle sx={{ p: 0 }}>
          <Grid container alignItems="flex-start" justifyContent="space-between">
            <Grid item>
              <IconButton aria-label="close" onClick={onClose} sx={{ pt: 2, pl: 2 }}>
                <Delete fill={theme.palette.primary.black} />
              </IconButton>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={10} sx={{ my: 1.5 }}>
                {setupList.map((item) => (
                  <Stack justifyContent="center" alignItems="center">
                    <Avatar
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        bgcolor: item.active ? theme.palette.primary.blue : theme.palette.primary.white,
                        color: item.active ? theme.palette.primary.white : theme.palette.primary.grey,
                        border: item.active ? `none` : `1px solid #E0E0E0`,
                        my: 1,
                      }}
                    >
                      {item.index}
                    </Avatar>
                    <Typography
                      sx={{
                        color: item.active ? theme.palette.primary.black : theme.palette.primary.grey,
                        fontWeight: 500,
                      }}
                    >
                      {item.listText}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'mr': 2,
                  'mt': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
                onClick={(event) => {
                  handleContinue(event);
                }}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : emailDrafted ? 'Draft Email' : 'Continue'}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <SequenceTemplate
            selectedGoalType={goal?.id}
            sequenceData={sequenceItem}
            ref={childComponentRef}
            setSequenceTemplate={(templates) => {
              setSequenceItem((prev) => ({
                ...prev,
                template: templates.template,
              }));
            }}
            setIsEmailLoading={setIsLoading}
            setEmailDrafted={setEmailDrafted}
            setupList={setupList}
          />
        </DialogContent>
      </Dialog>
      {openChooseTiming && (
        <Dialog onClose={() => handleModalClose('Back')} open={open} fullScreen>
          <DialogTitle>
            <Grid container alignItems="flex-start" justifyContent="space-between">
              <Grid item>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    handleModalClose('Back');
                  }}
                >
                  <ArrowLeft fill={theme.palette.primary.grey} />
                </IconButton>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={10} sx={{ my: 1.5 }}>
                  {setupList.map((item) => (
                    <Stack justifyContent="center" alignItems="center">
                      <Avatar
                        sx={{
                          fontSize: '12px',
                          fontWeight: 500,
                          bgcolor: item.active ? theme.palette.primary.blue : theme.palette.primary.white,
                          color: item.active ? theme.palette.primary.white : theme.palette.primary.grey,
                          border: item.active ? `none` : `1px solid #E0E0E0`,
                          my: 1,
                        }}
                      >
                        {item.index}
                      </Avatar>
                      <Typography
                        sx={{
                          color: item.active ? theme.palette.primary.black : theme.palette.primary.grey,
                          fontWeight: 500,
                        }}
                      >
                        {item.listText}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    'maxWidth': '200px',
                    'backgroundColor': theme.palette.primary.blue,
                    'color': theme.palette.primary.white,
                    'textTransform': 'none',
                    'width': '167px',
                    'py': 1,
                    'px': 2,
                    'whiteSpace': 'nowrap',
                    'overflow': 'hidden',
                    'textOverflow': 'ellipsis',
                    'borderRadius': 2,
                    'boxShadow': '0px 4px 8px 0px #2C64E31F',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                    },
                  }}
                  onClick={handleTimingContinue}
                >
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
            <Grid container justifyContent="space-between">
              <Grid item xs={3} />
              <Grid item xs={6}>
                {MemoizedDateTimePromptElement}
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {alertMessage && (
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
