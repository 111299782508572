import Cookie from 'js-cookie'

export function SetCookie (name, value) {
  const userAgent = navigator.userAgent
  let isSecure = true

  if (userAgent.match(/safari/i)) {
    isSecure = false
  } else {
    isSecure = true
  }

  Cookie.set(name, encodeURIComponent(value), {
    expires: new Date(new Date().getTime() + (14 * 24 * 60 * 60 * 1000)),
    secure: isSecure,
    sameSite: 'strict',
    path: '/'
  })
};

export function GetCookie (name) {
  const value = Cookie.get(name)
  return value ? JSON.parse(decodeURIComponent(value)) : String.empty
};

export function IsProviderConnected (provider) {
  const userCookie = GetCookie('user');
  if (userCookie) {
    const foundProvider = userCookie.providers.find(account => account !== null && account.provider === provider);
    if (foundProvider && foundProvider.profileId) {
      return true;
    }
  }

  return false;
}

export function GetConnectedProviders () {
  const userCookie = GetCookie('user');
  if (userCookie) {
    return userCookie.providers.filter(account => account !== null && account.profileId);
  }

  return [];
}

export function RemoveCookie (name) {
  Cookie.remove(name)
};
