import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import { UserConst } from '../../../constants/userConst'
import { Regexes } from '../../../constants/constant'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '5px',
  boxShadow: 2,
  p: 4
}

export default function EditUserOnboardingModal ({ open, onClose, userData }) {
  const userName = userData.name
  const availableUserTypes = [UserConst.UserTypes.TRIAL_USER, UserConst.UserTypes.FULL_USER, UserConst.UserTypes.DEMO_USER]
  const approvalStatuses = [UserConst.OnboardingStatus.PENDING, UserConst.OnboardingStatus.APPROVED]
  const [selectedUserType, setSelectedUserType] = React.useState(userData.userType)
  const [selectedStatus, setSelectedStatus] = React.useState(userData.status)
  const [email, setEmail] = React.useState(userData.email)
  const [isEmailValid, setIsEmailValid] = React.useState(true)

  const capitalize = (word) => {
    if (word.length <= 0) {
      return word
    } else if (word.length == 1) {
      return word.toUpperCase()
    }
    return (word[0].toUpperCase() + word.slice(1))
  }

  const handleTypeOptionChange = (event) => {
    setSelectedUserType(event.target.value)
  }

  const handleStatusOptionChange = (event) => {
    setSelectedStatus(event.target.value)
  }

  const handleEmailChange = (event) => {
    let emailAddress = event.target.value
    setEmail(emailAddress)
    setIsEmailValid((emailAddress.length === 0) ? true : Regexes.EMAIL.test(emailAddress))
  }

  const resetWithOriginalValues = () => {
    setSelectedUserType(userData.userType)
    setSelectedStatus(userData.status)
    setEmail(userData.email)
    setIsEmailValid(true)
  }

  const handleOnClose = (event) => {
    resetWithOriginalValues()
    onClose(event)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleOnClose}
        aria-labelledby='edit-user-onboarding-modal-title'
        aria-describedby='edit-user-onboarding-modal-description'
      >
        <Box sx={style}>
          <Typography id='edit-user-onboarding-modal-title' variant='h6' component='h2'>
            {userName}
          </Typography>
          <Divider />
          <Box sx={{ mt: 3, mb: 3, width: '100%' }}>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <InputLabel id='edit-user-onboarding-modal-user-type-label'>Type</InputLabel>
                <Select
                  labelId='edit-user-onboarding-modal-user-type-label'
                  value={selectedUserType}
                  onChange={handleTypeOptionChange}
                  sx={{ minWidth: 200 }}
                >
                  {availableUserTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {capitalize(type)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel id='edit-user-onboarding-modal-user-status-label'>Status</InputLabel>
                <Select
                  labelId='edit-user-onboarding-modal-user-status-label'
                  value={selectedStatus}
                  onChange={handleStatusOptionChange}
                  sx={{ minWidth: 200 }}
                >
                  {approvalStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {capitalize(status)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel id='edit-user-onboarding-modal-user-email-label'>Email</InputLabel>
                <TextField
                  id='edit-user-onboarding-modal-user-email'
                  variant='outlined'
                  value={email}
                  onChange={handleEmailChange}
                  error={!isEmailValid}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ mt: 3, width: '100%' }}>
            <Button
              sx={{ mt: 1, xp: 1, width: '100%' }}
              variant='contained'
              disabled={!isEmailValid ? 'disabled' : ''}
              onClick={(e) => {
                userData.userType = selectedUserType
                userData.status = selectedStatus
                userData.email = email
                onClose(e, userData)
              }}
            >
              Save
            </Button>
            <Button
              sx={{ mt: 1, width: '100%' }}
              variant='outlined'
              color='error'
              onClick={(e) => {
                resetWithOriginalValues()
                onClose(e)
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
