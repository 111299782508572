import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Http from '../../../http/httpClient'
import OnboardingItem from './OnboardingItem'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export default function UserOnboardings() {
  const [isLoading, setIsLoading] = React.useState(false)
  const [users, setUsers] = React.useState([])

  React.useEffect(() => {
    const fetchUserOnboardings = async () => {
      setIsLoading(true)
      try {
        const res = await Http.getData('userOnboarding/list')
        setUsers(res.users)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchUserOnboardings()
  }, [])

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            overflowX: 'auto',
            overflowY: 'auto',
            maxHeight: 'calc(85vh - 100px)',
            maxWidth: '100%',
            '@media (max-height: 600px)': {
              maxHeight: 'calc(85vh - 50px)'
            }
          }}
        >
          <Table sx={{ maxWidth: '100%' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length > 0 ? (
                users.map((u) => <OnboardingItem key={u.id} user={u} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography align="center" gutterBottom>
                      There are no users for onboarding.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
