import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'

import Http from '../../../http/httpClient'
import { Providers } from './../../../constants/constant'
import CustomSnackBar from '../../../components/CustomSnackBar'
import { Severity } from '../../../constants/constant'

export default function ScriptManager () {
  const [isLoading, setIsLoading] = React.useState(false)
  const [scriptData, setScriptData] = React.useState('')
  const [snackmessage, setSnackmessage] = React.useState('')
  const [severity, setSeverity] = React.useState('')
  const [executed, setExecuted] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [users, setUsers] = React.useState([])
  const [models, setModels] = React.useState([])
  const [selectedUser, setSelectedUser] = React.useState('')
  const [selectedModel, setSelectedModel] = React.useState('')

  const executeScript = async (event) => {
    event.preventDefault()

    setIsLoading(true)
    Http.postData('script/execute', { script: scriptData })
      .then((res) => {
        if (res && res.status === true) {
          setSeverity(Severity.SUCCESS)
        } else {
          setSeverity(Severity.ERROR)
        }
        setSnackmessage(res?.message)
        setOpen(true)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
        setExecuted(false)
        setSelectedUser('')
        setSelectedModel('')
        setScriptData('')
      })
  }

  const handleReset = (event) => {
    event.preventDefault()

    setScriptData('')
    setSelectedUser('')
    setSelectedModel('')
    setExecuted(false)
  }

  const handleScriptDataChange = (event) => {
    setScriptData(event.target.value)
    setExecuted(event.target.value.length > 0)
  }

  const handleClose = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    const fetchScriptAssets = async () => {
      Http.getData('script/loadAssets')
        .then((res) => {
          if (res) {
            setUsers(res.users ?? [])
            setModels(res.models ?? [])
          }
        })
        .catch((error) => {
          console.error("Error while fetching script assets. ", error)
        })
    }

    fetchScriptAssets()
  }, [])

  const handleUserChange = (event) => {
    const value = event.target.value
    setSelectedUser(value)

    generateMongoScript(value, 'user')
  }

  const handleModelChange = (event) => {
    const value = event.target.value
    setSelectedModel(value)

    generateMongoScript(value, 'model')
  }

  const generateMongoScript = (value, callerType) => {
    let modelName = null
    let userId = null
    if (callerType === 'model') {
      modelName = value
    } else {
      userId = value
    }
    modelName ??= selectedModel
    userId ??= selectedUser

    if ((userId || userId === -1) && modelName) {
      const Query = userLevelScript(userId, modelName)

      if (userId === -1) {
        const allItems = `
          (async () => {
            const users = await User.find({})
            for (const user of users) {
              if (!user) continue

              ${Query}
            }
          })()
        `

        setScriptData(allItems)
      } else {
        const userQuery = `
          (async () => {
            ${Query}
          })()
        `
        setScriptData(userQuery)
      }
    }
  }

  const userLevelScript = (id, modelName) => {
    const modelNameToSearchKey = {
      'tagcategories': 'user',
      'users': '_id'
    }
    const userId = (id === -1) ? 'user._id' : `'${id}'`
    let searchKey = modelNameToSearchKey[modelName.toLowerCase()] || 'userId'

    return `try {
        const document = await ${modelName}.findOne({ ${searchKey}: ${userId} })
        if (document) {
          // Insert your code here
          console.log('Document updated successfully.')
        } else {
          console.log('Document not found.')
        }
      } catch (error) {
        console.error('Error while executing script:', error)
      }
    `
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="flex-start">
        <Typography variant="subtitle1" sx={{
            marginLeft: '1rem',
            marginTop: '0.5rem'
          }}>
          Users:
        </Typography>
        <Select
          value={selectedUser}
          onChange={handleUserChange}
          sx={{
            minWidth: 200,
            height: 40,
            lineHeight: '1.2rem',
            marginLeft: '1rem'
          }}
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user._id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="subtitle1" sx={{
            marginLeft: '2rem',
            marginRight: '1rem',
            marginTop: '0.5rem'
          }}>
          Models:
        </Typography>
        <Select
          value={selectedModel}
          onChange={handleModelChange}
          sx={{
            minWidth: 200,
            height: 40,
            lineHeight: '1.2rem'
          }}
        >
          {models.map((model) => (
            <MenuItem key={model.id} value={model.name}>
              {model.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box p={2} height="100%" width="100%">
        <TextField
          id="script-manager"
          variant="outlined"
          value={scriptData}
          multiline
          rows={15}
          sx={{
            width: '100%',
            height: '100%',
            fontSize: '1.0rem',
            fontFamily: 'Poppins',
            maxHeight: '30rem',
            overflowY: 'auto',
            flexGrow: 1
          }}
          onChange={handleScriptDataChange}
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          disabled={isLoading ? 'disabled' : ''}
          variant="outlined"
          color="error"
          sx={{
            fontSize: '1.2rem',
            textTransform: 'none',
            fontFamily: 'Poppins',
            marginRight: '1rem',
          }}
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          disabled={executed ? '' : 'disabled'}
          variant="contained"
          sx={{
            fontSize: '1.2rem',
            textTransform: 'none',
            fontFamily: 'Poppins',
          }}
          onClick={executeScript}
        >
          {isLoading ? (
            <Box
              sx={{
                position: 'relative',
                width: '52px',
                height: '34px',
                display: 'flex',
                borderRadius: 16,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress color="inherit" size={34}/>
            </Box>
          ) : (
            'Execute'
          )}
        </Button>
      </Box>
      <CustomSnackBar
        open={open}
        onClose={handleClose}
        message={snackmessage}
        severity={severity}
      />
    </Box>
  )
}
