import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Button,
  Stack,
  Avatar,
  Typography,
  Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { setPage } from "../redux/authSlice";
import AddPostModal from "./AddPostModal/addPostModal";
import SettingsDrawer from "../pages/settings/SettingsDrawer";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { ReactComponent as Capture } from "../assets/Icons/Capture.svg";
import { ReactComponent as Engage } from "../assets/Icons/Engage.svg";
import { ReactComponent as Settings } from "../assets/Icons/Settings.svg";
import { ReactComponent as Lists } from "../assets/Icons/Lists.svg";
import { ReactComponent as Delete } from "../assets/Icons/Delete.svg";
import { GetCookie } from "../helper/cookieManager";
import { UserConst } from "../constants/userConst";


const MobileNav = () => {
  const curUser = GetCookie("user");
  let page = useSelector((state) => state.auth.page);
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = React.useState(page);

  const [openNav, setOpenNav] = useState(false);
  const [openSettingsDrawer, setOpenSettingsDrawer] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const theme = useTheme();
  const menu = [
    { title: "Client Retention", icon: Engage, page: "retention" },
    { title: "Client Acquisition", icon: Capture, page: "acquisition" },
    { title: "Settings", icon: Settings, page: "settings" },
  ];
  const handleMenuItemClick = (title, hasSubmenu, page) => {
    if (title === "Settings") {
        setOpenSettingsDrawer(true);
        console.log("clicked");
      } else {
        if (!hasSubmenu) {
          setActiveItem(title);
        }
        if (page) {
          setMainPage(page);
        }
        setOpenNav(false);
        setOpenSettingsDrawer(false);
      }};

  const setMainPage = (page) => {
    dispatch(setPage(page));
  };
  const closeSettingsNav = () => {
    setOpenSettingsDrawer(false);
  };
  const setModal = (step = null) => {
    setOpenNav(false); 
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Drawer
        open={openNav}
        onClose={() => {
          setOpenNav(false);
        }}
      >
        <Paper
          sx={{
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            backgroundColor: theme.palette.primary.lightGrey,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpenNav(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              zIndex: 1,
            }}
          >
            <Delete fill={theme.palette.primary.grey} />
          </IconButton>
          <SettingsDrawer
            open={openSettingsDrawer}
            closeSettingsNav={closeSettingsNav}
          />
          <List sx={{ flexGrow: 1, marginTop: "30px" }}>
            {menu.map((item) => (
              <div key={item.title}>
                <ListItemButton
                  onClick={() => {
                    // handleChange(item.title);
                    handleMenuItemClick(item.title, !!item.submenu, item.page);
                  }}
                  sx={{
                    fontWeight: 500,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    my: 2,
                    mx: 2,
                    color:
                      page.payload === item.page
                        ? theme.palette.primary.blue
                        : theme.palette.primary.grey,
                    backgroundColor:
                      page.payload === item.page
                        ? theme.palette.primary.white
                        : theme.palette.primary.lightGrey,
                    borderRadius: 2,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.white,
                      color: theme.palette.primary.blue,
                    },
                    "& .MuiListItemText-root": {
                      fontWeight: `${theme.typography.fontWeightBold} !important`,
                    },
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon>
                      <item.icon
                        fill={
                          page.payload === item.page
                            ? theme.palette.primary.blue
                            : theme.palette.primary.grey
                        }
                      />
                    </ListItemIcon>
                    
                    <ListItemText
                      primary={item.title}
                      sx={{
                        fontWeight: 500,
                        color: theme.palette.primary.grey,
                      }}
                    />
                  </div>
                </ListItemButton>
              </div>
            ))}
            <Button
             onClick={setModal}
              sx={{
                boxShadow: "0px 4px 8px 0px #2C64E31F",
                textTransform: "none",
                fontWeight: 500,
                borderRadius: 2,
                width: "90%",
                backgroundColor: theme.palette.primary.blue,
                color: theme.palette.primary.white,
                mx: 2,
                my: 2,
                "&:hover": {
                  backgroundColor: theme.palette.primary.blue,
                  color: theme.palette.primary.white,
                },
              }}
            >
              Compose Post
            </Button>
          </List>
          <AddPostModal open={modalOpen} onClose={closeModal} />
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", mx: 4, my: 1 }}
          >
            <Avatar
              sx={{
                bgcolor: theme.palette.primary.lightGrey,
                color: theme.palette.primary.blue,
                borderRadius: 2,
                "&:hover": {
                  border: "1px solid",
                  borderColor: theme.palette.primary.blue,
                },
              }}
              alt={curUser.name}
              src={curUser.imageUrl}
              variant="square"
            />
            <Stack>
              <Typography
                sx={{ color: theme.palette.primary.grey, fontSize: "14px" }}
              >
                {curUser.name}
              </Typography>
              <Typography
                sx={{ color: theme.palette.primary.grey, fontSize: "12px" }}
              >
                {curUser.userType === UserConst.UserTypes.TRIAL_USER
                  ? "Free Plan"
                  : "Premium"}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Drawer>
      <IconButton
        sx={{ color: theme.palette.primary.grey }}
        onClick={() => {
          setOpenNav(!openNav);
        }}
      >
        <MenuIcon />
      </IconButton>
    </>
  )}

export default MobileNav;