import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

import { ReactComponent as Delete } from '../../../../assets/Icons/Delete.svg';

import Http from '../../../../http/httpClient'
import { toggleSequencePaused } from '@/helper/apiHelper';

export default function PauseModal(props) {
  const theme = useTheme();
  const { onClose, open, onRefresh, activeItem, forDelegation } = props;
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [sequenceData, setSequenceData] = React.useState(null);
  const delegationAccessId = useSelector((state) => state.auth.delegationAccessId);

  const openStatus = activeItem.status;

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const containerStyle = (item) => ({
    border: `1px solid ${selectedItem === item ? theme.palette.primary.blue : '#E0E0E0'}`,
    boxShadow: 0,
    borderRadius: 3,
    width: '100%',
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.black,
    my: 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    '&:hover': {
      borderColor: theme.palette.primary.blue,
    }
  });

  const handleDoneClick = async () => {
    if (selectedItem === 'pause') {
      const result = await toggleSequencePaused(activeItem, null, delegationAccessId);
      onClose();
      onRefresh(activeItem._id);
    }
    onClose();
  };

  return (
    <>
      <Dialog onClose={onClose} open={open} fullScreen sx={{ 
        px: {
          xs: 0,
          md: 2,
        },
        maxWidth: '100vw',
       }}>
        <DialogTitle sx={{ p: 0 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={9} lg={10}>
              <IconButton aria-label="close" onClick={onClose} sx={{ pt: 2, pl: 2 }}>
                <Delete fill={theme.palette.primary.grey} />
              </IconButton>
            </Grid>
            <Grid item xs={3} lg={2}>
              <Button
                onClick={handleDoneClick}
                sx={{
                  maxWidth: {
                    xs: '100%',
                    md: '200px',
                  },
                  backgroundColor: theme.palette.primary.blue,
                  color: theme.palette.primary.white,
                  textTransform: 'none',
                  width: {
                    xs: 'auto',
                    sm: '167px'
                  },
                  py: 1,
                  px: 2,
                  mr: 2,
                  mt: 2,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  borderRadius: 2,
                  boxShadow: '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={0} md={3} />
            <Grid item xs={12} md={6}>
              <Typography sx={{
                fontSize: {
                  xs: '28px',
                  md: '35px',
                },
                fontWeight: 700,
                color: theme.palette.primary.black,
                pt: {
                  xs: 0,
                  md: 5
                },
                pb: {
                  xs: 0,
                  md: 5
                },
                lineHeight: 'normal'
              }}>
                {openStatus === 'Active' ? 'Are you sure you want to pause this assistant?' : 'Are you sure you want to unpause this assistant?'}
              </Typography>

              <Container
                onClick={() => handleItemClick('pause')}
                sx={containerStyle('pause')}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 500, my: 1 }}>
                  {openStatus === 'Active' ? 'Yes, pause it.' : 'Yes, unpause it.'}
                </Typography>
              </Container>
              <Container
                onClick={() => handleItemClick('cancel')}
                sx={containerStyle('cancel')}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 500, my: 1 }}>No, never mind.</Typography>
              </Container>
            </Grid>
            <Grid item xs={0} md={3} />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
