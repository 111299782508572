import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';

import PSectionNavHeader from './PSectionNavHeader';
import PSectionNavMenuItem from './PSectionNavMenuItem';

function PSectionNav({ pVariant, children, headerText, headerOnClick, headerButtonText, buttonTheme, headerOnClick2, headerButtonText2, buttonTheme2, menu, width, topFixed }) {
    const theme = useTheme();

    const containerStyles = {
      height: '100%',
      backgroundColor: theme.palette.primaryCL.Black50,
      width: width ? width : 'auto',
      display: 'flex',
      flexDirection: 'column',
    };
  
    const headerStyles = {
      position: topFixed ? 'sticky' : 'relative',
      top: 0,
      zIndex: 1000,
      backgroundColor: theme.palette.primaryCL.Black50,
    };
  
    const menuStyles = {
      flexGrow: 1,
      overflowY: 'auto',
    };
  
    return (
      <div style={containerStyles}>
        <div style={headerStyles}>
          <PSectionNavHeader
            pVariant={pVariant}
            headerText={headerText}
            onClick={headerOnClick}
            buttonText={headerButtonText}
            buttonTheme={buttonTheme}
            onClick2={headerOnClick2}
            buttonText2={headerButtonText2}
            buttonTheme2={buttonTheme2}
          />
        </div>
  
        <div style={menuStyles}>
          {menu?.map((item) => (
            <PSectionNavMenuItem
              key={item.label}
              pVariant={pVariant}
              label={item.label}
              onClick={item.onClick}
              badgeText={item.badgeText}
              badgeTheme={item.badgeTheme}
              active={item.active}
            />
          ))}
        </div>
  
        {children}
      </div>
    );
  }

PSectionNavMenuItem.propTypes = {
    /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
    pVariant: PropTypes.oneOf(['primary']),

    /** Anything we want appended to the bottom */
    children: PropTypes.node,

    /** Header text */
    headerText: PropTypes.string.isRequired,

    /** Onclick for the header button*/
    headerOnClick: PropTypes.func,

    /** Text for the header button */
    headerButtonText: PropTypes.string,

    /** Button theme */
    buttonTheme: PropTypes.oneOf(['primary', 'secondary', 'outlined', 'plain', 'black']),

    /** Menu items - See PSectionNavMenuItem for props*/
    menu: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        badgeText: PropTypes.string,
        badgeTheme: PropTypes.oneOf(['blue', 'teal', 'green', 'pink', 'purple', 'orange', 'black', 'grey', 'red']),
        active: PropTypes.bool,
    })),

    /** Prop to fix the header on top */
    topFixed: PropTypes.bool,
};

PSectionNavMenuItem.defaultProps = {
    pVariant: 'primary',
    headerText: 'Header Text',
    buttonTheme: 'secondary',
    topFixed: false,
};

export default PSectionNav;