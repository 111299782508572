import * as React from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

import { composePostv2 } from "../../../../helper/apiHelper";
import Http from "../../../../http/httpClient";
import { Severity } from "../../../../constants/constant";

import FullScreenModal from "../../../../components/FullScreenModal";
import { ReactComponent as Delete } from "../../../../assets/Icons/Delete.svg";
import { ReactComponent as XLogo } from "../../../../assets/logos/XLogoCircle.svg";
import { ReactComponent as LinkedInLogo } from "../../../../assets/logos/LinkedInLogoCircle.svg";

import { Providers } from "../../../../constants/constant";

import PostModal from "../../../ClientRetention/components/PostModal";
import SocialButton from "./socialsButton";

export default function Socials(props) {
  const theme = useTheme();
  const { onClose, open, progress, onCloseProfessionals, onCloseTopics, onCloseSamplePosts, source, topics } = props;
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [posts, setPosts] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [snackmessage, setSnackmessage] = React.useState("");
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [postModalOpen, setPostModalOpen] = React.useState(false);
  const [selectedSocials, setSelectedSocials] = React.useState([]);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [postStatus, setPostStatus] = React.useState(false);
  const [post, setPost] = React.useState(null);
  const [isGeneratingPosts, setIsGeneratingPosts] = React.useState(false);
  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation)

  React.useEffect(() => {
    if (isGeneratingPosts) {
      if (!isLoading) {
        // The first post came back from the endpoint, we can proceed to the post edit screen
        setPostModalOpen(true);
      }
    }
  }, [isGeneratingPosts, isLoading]);

  const handleSetPosts = (posts) => {
    setPosts(posts);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item.title);
  };

  const toggleSocial = (social) => {
    setPost(null);
    setSelectedSocials([social]);
  };

  const containerStyle = (item) => ({
    border: `1px solid ${selectedItem === item ? theme.palette.primary.blue : "#E0E0E0"}`,
    boxShadow: 0,
    borderRadius: 3,
    width: "100%",
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.black,
    my: 1,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    "&:hover": {
      borderColor: theme.palette.primary.blue,
    },
  });

  //Functions for Post Modal

  const handlePublish = (userPost, priority = "immediate", time) => {
    setIsLoading((prevLoadingItems) => [...prevLoadingItems, userPost._id]);
    const endpoint = authSliceSelectedDelegation?.userId
      ? `publishPost/${authSliceSelectedDelegation.userId}`
      : 'publishPost';

    Http.postData(endpoint, { post: userPost, priority, time })
      .then((res) => {
        if (res && res.status) {
          setPosts((prevPosts) => prevPosts.filter((post) => post._id !== userPost._id));
          setStatus(Severity.SUCCESS, "Published!!");
        } else {
          setStatus(Severity.ERROR, "Failed!!");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
        } else {
          console.error(error);
        }
        setStatus(Severity.ERROR, "Failed!!");
      });
  };

  const setStatus = (severity, message) => {
    setSnackmessage(message);
    setSeverity(severity);
  };

  const handleDelete = (userPost) => {
    setIsDeleting(true);
    const endpoint = authSliceSelectedDelegation?.userId
      ? `deleteUserPost/${authSliceSelectedDelegation.userId}`
      : 'deleteUserPost';

    Http.postData(endpoint, { post: userPost })
      .then((res) => {
        if (res.success === true) {
          setPosts((prevPosts) => prevPosts.filter((post) => post._id !== userPost._id));
          setStatus(Severity.SUCCESS, "Post deleted successfully!");
        } else {
          setStatus(Severity.ERROR, "Failed to delete the post!");
        }
      })
      .catch((error) => {
        console.error(error);
        setStatus(Severity.ERROR, "Failed to delete the post!");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const getRefreshedPost = () => {
    if (selectedSocials.length < 1) {
      return;
    }

    setIsLoading(true);

    Http.getData("refreshedUserPostQueues", { selectedSocial: selectedSocials[0] })
      .then((res) => {
        if (!res) {
          setIsLoading(false);
          return;
        }

        const viablePosts = res.contactPosts.filter(
          (p) => p !== null && !p.approved && !p.published && p.source === selectedSocials[0] && !p.responseTo
        );
        setPosts(viablePosts);

        if (!post || (post && !viablePosts.includes(post))) {
          const randomPost = viablePosts[Math.floor(Math.random() * viablePosts.length)];
          setPost(randomPost);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const generatePosts = async () => {
    if (!selectedSocials?.length || !topics?.length) {
      return;
    }

    const selectedProvider = selectedSocials[0];

    if (!Object.values(Providers).includes(selectedProvider)) {
      throw new Error("Provider is not in the list of supported providers");
    }

    setIsLoading(true);
    setIsGeneratingPosts(true);
    setPosts([]);
    setPost(null);

    const promises = topics?.map(async (topic) => {
      const response = await composePostv2({
        provider: selectedProvider,
        topic,
      });

      const newPost = response?.post;

      // Make sure it's actually a post with the stuff we need
      if (newPost?.message && newPost?.topic) {
        setPosts((prevPosts) => [...prevPosts, newPost]);

        // PostModal requires a post to be set
        if (!post) {
          setPost(newPost);
        }

        // proceed as soon as the first post is in
        setIsLoading(false);
      } else {
        console.error("Post is missing required fields");
      }
    });

    await Promise.all(promises);

    setIsLoading(false);
    setIsGeneratingPosts(false);
  };

  const handleContinueCompose = async () => {
    if (posts?.length) {
      setPostModalOpen(true);
      return;
    }

    if (!isGeneratingPosts && !isLoading && !posts?.length) {
      generatePosts();
    }
  };

  const handleContinue = () => {
    setPostStatus(true);
    if (selectedSocials.length > 0) {
      setPostModalOpen(true);

      // refresh the post if the source is different or if the post is null
      if (!post || post?.source !== selectedSocials[0]) {
        getRefreshedPost();
      }
    } else {
      setAlertMessage("Please select at least one social media account");
    }
    setPostStatus(false);
  };

  const handlePostApprove = (userPost, message) => {
    const params = { post: userPost, textToUpdate: message };

    if (source === "ComposePost") {
      return;
    }

    const endpoint = authSliceSelectedDelegation?.userId
      ? `updateUserPost/${authSliceSelectedDelegation.userId}`
      : 'updateUserPost';

    Http.postData(endpoint, params)
      .then((res) => {
        const prevPost = post.responseTo || post;
        prevPost.message = message;
        setPost(prevPost);

        setPosts((posts) =>
          posts.map((post) => {
            if (post._id.toString() === userPost._id.toString()) {
              if (post.responseTo) {
                post.responseTo.message = message;
              } else {
                post.message = message;
              }
              return post;
            } else {
              return post;
            }
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const closePostModal = () => setPostModalOpen(false);

  const content = (
    <>
      <Typography
        sx={{ fontSize: "32px", fontWeight: 700, color: theme.palette.primary.black, pt: 1, lineHeight: "normal" }}
      >
        Where would you like to post?
      </Typography>

      <Container sx={containerStyle("LinkedIn")}>
        <Grid container spacing={2} alignItems="center">
          {/* Grid item for LinkedIn Logo and Typography */}
          <Grid item xs={9}>
            <Stack spacing={2} direction="row" alignItems="center">
              <LinkedInLogo />
              <Typography sx={{ fontSize: "16px", fontWeight: 500, my: 1 }}>LinkedIn</Typography>
            </Stack>
          </Grid>

          {/* Grid item for ConnectButton */}
          <Grid item xs={3}>
            <SocialButton
              currProvider={Providers.LINKED_IN}
              source={"social"}
              addSocial={toggleSocial}
              selectedSocials={selectedSocials}
            />
          </Grid>
        </Grid>
      </Container>

      <Container sx={containerStyle("Twitter")}>
        <Grid container spacing={2} alignItems="center">
          {/* Grid item for LinkedIn Logo and Typography */}
          <Grid item xs={9}>
            <Stack spacing={2} direction="row" alignItems="center">
              <XLogo />
              <Typography sx={{ fontSize: "16px", fontWeight: 500, my: 1 }}>X (Twitter)</Typography>
            </Stack>
          </Grid>

          {/* Grid item for ConnectButton */}
          <Grid item xs={3}>
            <SocialButton
              currProvider={Providers.TWITTER}
              source={"social"}
              addSocial={toggleSocial}
              selectedSocials={selectedSocials}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );

  return (
    <>
      {post && postModalOpen && (
        <PostModal
          newPost
          open={postModalOpen}
          onClose={closePostModal}
          post={post}
          posts={posts}
          setPosts={handleSetPosts}
          handlePublish={handlePublish}
          handleDelete={handleDelete}
          isDeleting={isDeleting}
          source={source}
          onCloseProfessionals={onCloseProfessionals}
          onCloseTopics={onCloseTopics}
          onCloseSocials={onClose}
          onCloseSamplePosts={onCloseSamplePosts}
          socials={selectedSocials}
          handlePostApprove={handlePostApprove}
        />
      )}
      <FullScreenModal
        continueText="Continue"
        onClose={onClose}
        open={open}
        progress={50}
        content={content}
        handleContinue={source === "ComposePost" ? handleContinueCompose : handleContinue}
        alertMessage={alertMessage}
        isLoading={isLoading}
      />
    </>
  );
}
