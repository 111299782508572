import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'

import Http from '../../../http/httpClient'
import { useDispatch } from 'react-redux'

import CloseIcon from '@mui/icons-material/Close'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

const testTags = [
  { tag: 'politics', color: '#3983F7' },
  { tag: 'promotion', color: '#CBC558' },
  { tag: 'wednesday', color: '#F76A6A' },
  { tag: 'morning', color: '#BA31B8' },
  { tag: 'throwback', color: '#7FEA9C' },
  { tag: 'inspiration', color: '#3983F7' },
  { tag: 'quoteoftheday', color: '#CBC558' },
  { tag: 'fitness', color: '#F76A6A' },
  { tag: 'foodie', color: '#BA31B8' },
  { tag: 'travel', color: '#7FEA9C' },
  { tag: 'nature', color: '#3983F7' },
  { tag: 'photography', color: '#CBC558' },
  { tag: 'love', color: '#F76A6A' },
  { tag: 'fashion', color: '#BA31B8' },
  { tag: 'selfie', color: '#7FEA9C' },
  { tag: 'art', color: '#3983F7' },
  { tag: 'music', color: '#CBC558' },
  { tag: 'motivation', color: '#F76A6A' },
  { tag: 'happy', color: '#BA31B8' },
  { tag: 'fun', color: '#7FEA9C' },
  { tag: 'workout', color: '#3983F7' },
  { tag: 'weekend', color: '#CBC558' }
]

export default function TagModal ({ open, onClose, post }) {
  const { _id, name, created_at, ImageUrl, time, message, source, responseTo } = post
  const [selectedTags, setSelectedTags] = React.useState(post.tags)
  const [tagInput, setTagInput] = React.useState('')
  const [tags, setTags] = React.useState([])

  const dispatch = useDispatch()

  const handleTagSelect = (tag) => {
    if (selectedTags.find((selTag) => selTag._id === tag._id)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag._id !== tag._id))
    } else {
      setSelectedTags([...selectedTags, tag])
    }
  }

  const handleTagRemove = (tag) => {
    setSelectedTags(selectedTags.filter((selectedTag) => selectedTag._id !== tag._id))
  }

  const handleTagInputChange = (event) => {
    setTagInput(event.target.value)
  }

  const handleAddTag = () => {
    if (tagInput.trim() !== '') {
      setSelectedTags([...selectedTags, tagInput.trim()])
      setTagInput('')
    }
  }

  const handleSave = async () => {
    await Http.postData('userTags/saveTagsToPost', { _id, tags: selectedTags })

    onClose()
  }

  React.useEffect(() => {
    const fetchTags = () => {
      Http.getData('userTags/recentTags')
        .then(function (response) {
          const respTags = response.tags
          dispatch(setTags(respTags))
        })
        .catch(function (error) {
          console.log('Error while retrieving user tags:')
          console.error(error)
        }
        )
    }
    fetchTags()
  }, [dispatch])

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle
        sx={{
          fontWeight: 800,
          fontSize: 18,
          letterSpacing: '-0.02em',
          py: 3,
          fontFamily: 'Poppins'
        }}
      >
        Tag Post
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={{ display: 'flex', alignItems: 'center', mx: 3, background: '#eaf1fb', px: 2, py: 2, borderRadius: 8 }}>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
                  source === 'twitter'
                    ? (
                      <TwitterIcon sx={{ fontSize: 12 }} />
                      )
                    : (
                      <LinkedInIcon sx={{ fontSize: 12 }} />
                      )
                }
          sx={{
            '& .MuiBadge-badge': {
              color: '#4999E9',
              backgroundColor: 'white',
              border: '1px solid #4999E9',
              mx: 1
            }
          }}
        >
          <Avatar sx={{ mx: 1 }} src={ImageUrl} />
        </Badge>
        <Box sx={{ flexGrow: 1, mx: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mx: 1, '&:hover': { cursor: 'pointer' } }}>
                {name} {/* &bull;{' '} */}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant='body2'
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden', px: 4, py: 1 }}
          >
            {' '}
            {message}
          </Typography>
        </Box>
      </Box>
      {/*<Paper
        component='form'
        elevation={0}
        sx={{
          my: 1,
          mx: 3,
          p: '2px 6px',
          display: 'flex',
          alignItems: 'center',
          borderRadius: 25,
          backgroundColor: '#F0F0F0'
        }}
      >
        <IconButton
          sx={{ p: '10px', color: '#3983F7' }}
          onClick={handleAddTag}
        >
          <AddIcon />
        </IconButton>
        <InputBase
          fullWidth
          elevation={0}
          onChange={handleTagInputChange}
          sx={{ ml: 1, flex: 1, color: 'black' }}
          placeholder='Search tags'
          inputProps={{ 'aria-label': 'Search Tags' }}
          value={tagInput}
        />
      </Paper>*/}
      <Typography
        component='h1'
        sx={{ fontWeight: 'bold', py: 3, fontSize: '18px', fontFamily: 'Poppins', ml: 5 }}
      >
        Recent tags
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1, mx: 2 }}>
        {tags
          .filter((tag) => tag.title.toLowerCase().includes(tagInput.toLowerCase()))
          .map((tag) => (
            <Chip
              key={tag.title}
              label={tag.title}
              onClick={() => handleTagSelect(tag)}
              sx={{
                m: 0.5,
                backgroundColor: tag.category.color,
                color: '#FFFFFF',
                fontWeight: 'bold'
              }}
            />
          ))}
         {tags.length === 0 && (
        <Typography
          sx={{
            fontSize: '14px',
            mx: 4
          }}
        >
          No tags available. Click the tag icon on the sidebar to start sorting and tracking your posts.
        </Typography>
      )}
      </Box>
      <Typography
        component='h1'
        sx={{ fontWeight: 'bold', py: 3, fontSize: '18px', fontFamily: 'Poppins', ml: 5 }}
      >
        Selected tags
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1, mx: 2 }}>
        {selectedTags.map((tag) => (
          <Chip
            key={tag.title}
            label={tag.title}
            onDelete={() => handleTagRemove(tag)}
            sx={{
              m: 0.5,
              backgroundColor: tag.category.color,
              color: '#FFFFFF',
              fontWeight: 'bold',
            }}
          />
        ))}
      </Box>
      <DialogActions>
      <Button
          variant='outlined'
          onClick={onClose}
          sx={{
            color: 'black',
            border: '1px solid #9A9A9A',
            backgroundColor: 'white',
            fontWeight: 'bold',
            textTransform: 'none',
            alignItems: 'center',
            fontSize: 13,
            borderRadius: 8,
            '&:hover': {
              backgroundColor: 'black',
              color: 'white'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant='outlined'
          onClick={handleSave}
          sx={{
            color: '#FFFFFF',
            backgroundColor: '#3983F7',
            fontWeight: 'bold',
            textTransform: 'none',
            alignItems: 'center',
            fontSize: 13,
            borderRadius: 8,
            my: 1,
            mx: 1,
            '&:hover': {
              backgroundColor: 'black',
              color: 'white'
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
