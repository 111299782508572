import React, { useState, useCallback } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme, Box } from "@mui/material";
import IconButton from '@mui/material/IconButton'

import { ReactComponent as Forward } from '../../../assets/Icons/Arrow-Right.svg'

const styles = {
  textOverflow: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
};

export default function ListContactsReview({ contactsToReview, setlistContacts }) {
  const theme = useTheme();
  const testContactsRef = React.useRef(contactsToReview);
  const [testContacts, setTestContacts] = React.useState(contactsToReview);
  const [checkboxChanged, setCheckboxChanged] = useState(false);
  const [removedContacts, setRemovedContacts] = React.useState(contactsToReview)
  const [selectedButton, setSelectedButton] = React.useState(null)

  const buttonStyles = {
    backgroundColor: theme.palette.primary.lightBlue,
    color: theme.palette.primary.blue,
    textTransform: "none",
    py: 1,
    px: 1,
    borderRadius: 3,
    minWidth: '99px'
  };

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault(); // Prevent default behavior on Enter key press
      }
    };

    // Add the keydown event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const clickedCheckbox = (contact) => {
    const exist = removedContacts.some(
      (s) => s !== null && s.contactId === contact.contactId
    )

    if (exist) {
      setRemovedContacts(
        removedContacts.filter((s) => s !== null && s.contactId !== contact.contactId)
      );
    } else {
      setRemovedContacts([...removedContacts, contact]);
    }

    const currentContact = testContacts.find((c) => c.contactId === contact.contactId)
    const newStatus = !currentContact.added
    onCheckBoxChange(newStatus, contact.contactId)
  }

  const onCheckBoxChange = useCallback((newStatus, contactId) => {
    // Create a new array to trigger rerender
    const initialContacts = testContactsRef.current;
    const updatedContacts = initialContacts.map((contact) => {
      if (contact.contactId === contactId) {
        return { ...contact, added: newStatus };
      }
      return contact;
    });

    testContactsRef.current = updatedContacts;
    setCheckboxChanged((prev) => !prev);
  }, [testContacts])

  React.useEffect(() => {
    setlistContacts((prev) => {
      return {
        ...prev,
        removedContacts: removedContacts
      }
    })
  }, [removedContacts])

  React.useEffect(() => {
    const initialContacts = testContactsRef.current
    setTestContacts(initialContacts)
  }, [checkboxChanged])

  const containerStyle = (item) => ({
    cursor: 'pointer',
    border: `1px solid ${selectedButton === item ? theme.palette.primary.blue : '#E0E0E0'}`,
    boxShadow: 0,
    borderRadius: 3,
    width: '100%',
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.black,
    my: 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    '&:hover': {
      borderColor: theme.palette.primary.blue,
    }
  })

  const handleItemClick = (item) => {
    setSelectedButton(item)
  }

  const addThemAll = () => {
    handleItemClick('ok')
    const initialContacts = testContactsRef.current
    const updatedContacts = initialContacts.map((contact) => ({
      ...contact,
      added: true
    }))

    testContactsRef.current = updatedContacts
    setCheckboxChanged((prev) => !prev)
    setRemovedContacts(prev => [])
  }

  const removeThemAll = () => {
    handleItemClick('cancel')
    const initialContacts = testContactsRef.current
    const updatedContacts = initialContacts.map((contact) => ({
      ...contact,
      added: false
    }))

    testContactsRef.current = updatedContacts
    setCheckboxChanged((prev) => !prev)
    setRemovedContacts(prev => updatedContacts)
  }

  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 2, lineHeight: 'normal' }}>
            {'Some Contacts are already in a list. Do you still want to add them?'}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, pt: 2 }}>
            <Container
              onClick={() => addThemAll()}
              sx={containerStyle('ok')}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 500, my: 1 }}>
                {`Yes, add them all.`}
              </Typography>
              <IconButton aria-label="close"  sx={{ pt: 2, pl: 2 }}>
                <Forward fill={theme.palette.primary.grey} />
              </IconButton>
            </Container>
            <Container
              onClick={() => removeThemAll()}
              sx={containerStyle('cancel')}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 500, my: 1 }}>
                No, don't add them.
              </Typography>
              <IconButton aria-label="close"  sx={{ pt: 2, pl: 2 }}>
                <Forward fill={theme.palette.primary.grey} />
              </IconButton>
            </Container>
          </Box>
          <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black, pb: 1, pt: 2 }}>
            Already in a list
          </Typography>
            {testContacts.map((contact, index) => (
                <Container
                  style={{ paddingLeft: '16px' }}
                  key={contact.contactId}
                  sx={{
                    backgroundColor: theme.palette.primary.white,
                    py: 2,
                    my: 1,
                    borderRadius: 3,
                    width: "100%",
                    border: '1px solid #E0E0E0',
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "97%" }}
                  >
                    <Grid container item xs={11} alignItems="center" spacing={2}>
                      <Grid item>
                        <Avatar
                          sx={{ my: 2, mt: 0, alignSelf: "flex-start" }}
                          src={contact.photo_url || null}
                        />
                      </Grid>
                      <Grid item sx={{ width: "80%" }}>
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              color: theme.palette.primary.black,
                              ...styles.textOverflow,
                            }}
                            title={contact.name}
                          >
                            {contact.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                              color: theme.palette.primary.black,
                              ...styles.textOverflow,
                            }}
                            title={contact.listNames}
                          >
                              {`Already in ${contact.listNames.length} other ${contact.listNames.length === 1 ? 'list' : 'lists'}`}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container item xs={1} alignItems="center">
                      <Button
                        onClick={() => clickedCheckbox(contact)}
                        sx={
                          !contact.added
                            ? buttonStyles
                            : {
                              ...buttonStyles,
                              backgroundColor: theme.palette.primary.white,
                              border: '1px solid #e0e0e0'
                          }
                        }
                      >
                        {!contact.added ? (
                          "Add anyway"
                        ) : (
                          "Remove"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              ))
            }
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </div>
  );
}
