import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import Badge from '@mui/material/Badge'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import Avatar from '@mui/material/Avatar'
import { Check, Close } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'

import SalesforceIcon from '../../../assets/logos/SalesforceIcon.png'
import HubspotImg from '../../../assets/logos/HubSpotImg.png'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  "&:hover": {
    cursor: "pointer"
  }
}))

export default function UserItem ({ user }) {
  const [editOpen, setEditOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [primarySource, setPrimarySource] = React.useState('')
  const [providers, setProviders] = React.useState([])

  React.useEffect(() => {
    const provider = user.providers.find(pr => pr !== null && pr.primary === true)
    if (provider) setPrimarySource(provider.provider);

    const providerIcons = user.providers.map((provider) => provider.provider)
    setProviders(providerIcons)

  }, [])

  function IconWithTooltip({ src, alt, tooltip }) {
    return (
      <Tooltip title={tooltip}>
        <img src={src} alt={alt} style={{ width: '22px', height: '22px', marginRight: '3px' }} />
      </Tooltip>
    )
  }

  function renderIcon(icon) {
    switch (icon) {
      case 'twitter':
        return (
          <Tooltip title="Twitter" key={icon}>
            <TwitterIcon sx={{color: '#1da1f2', marginRight: '3px'}}/>
          </Tooltip>
        )
      case 'linkedin':
        return (
          <Tooltip title="LinkedIn" key={icon}>
            <LinkedInIcon sx={{ marginRight: '3px' }}/>
          </Tooltip>
        )
      case 'hubspot':
        return <IconWithTooltip src={HubspotImg} alt="HubSpot" tooltip="HubSpot" />
      case 'salesforce':
        return <IconWithTooltip src={SalesforceIcon} alt="Salesforce" tooltip="Salesforce" />
      default:
        return null
    }
  }

  return (
    <>
      <ListItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: 3,
          py: 1,
          my: 1.5,
          justifyContent: 'flex-end',
          background: 'linear-gradient(to left, #FFFFFF 0%, #E5F1FB 50%, #FFFFFF 100%)'
        }}
      >
        <Box sx={{ flexDirection: 'column', width: '100%' }}>
          <Box sx={{ flexGrow: 1, mx: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Badge overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  primarySource === 'twitter'
                    ? (
                      <TwitterIcon sx={{ fontSize: 12 }} />
                      )
                    : (
                      <LinkedInIcon sx={{ fontSize: 12 }} />
                      )
                }
                sx={{
                  '& .MuiBadge-badge': {
                    color: '#4999E9',
                    backgroundColor: 'white',
                    border: '1px solid #4999E9',
                    mx: 1
                  }
                }}
              >
                <Avatar sx={{ mx: 1 }} src={user.profileUrl} />
              </Badge>
              <Box sx={{ flexGrow: 1, mx: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <StyledBox>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mx: 1 }}>
                      {user.name} - {user.email || user.precreatedEmail}
                    </Typography>
                    <Typography sx={{ fontSize: '14px', color: '#020202' }}>
                      {`(${user.id})`}
                    </Typography>
                  </StyledBox>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mx: 1 }}>
                      {user.userType}
                    </Typography>
                  </Box>
                  {providers && providers.length > 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      {providers.map((provider, index) => (
                        <React.Fragment key={index}>
                          {renderIcon(provider)}
                        </React.Fragment>
                      ))}
                    </Box>
                  )}
                  {user.tones && user.tones.length > 0 && (
                    <StyledBox>
                      <Tooltip title="Tones">
                        <div>
                          {user.tones.map((tone, index) => (
                            <Typography key={tone.name} component="div" sx={{ display: 'flex' }}>
                              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{tone.name}:&nbsp;</Typography>
                              <Typography variant="body1">{tone.value}</Typography>
                            </Typography>
                          ))}
                        </div>
                      </Tooltip>
                    </StyledBox>
                  )}
                  {user.twitterFilter && user.twitterFilter.length > 0 && (
                    <StyledBox>
                      <Tooltip title="Twitter Filter">
                        <div>
                          {user.twitterFilter.map((filter, index) => (
                            <Typography key={filter.name} component="div" sx={{ display: 'flex' }}>
                              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{filter.name}:&nbsp;</Typography>
                              <Typography variant="body1">{`${filter.value}%`}</Typography>
                            </Typography>
                          ))}
                        </div>
                      </Tooltip>
                    </StyledBox>
                  )}
                  {user.salesforceFilter && user.salesforceFilter.length > 0 && (
                    <StyledBox>
                      <Tooltip title="Salesforce Filter">
                        <div>
                          {user.salesforceFilter.map((filter, index) => (
                            <Typography key={filter.name} component="div" sx={{ display: 'flex' }}>
                              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{filter.name}:&nbsp;</Typography>
                              {filter.value ? <Check color="primary" /> : <Close color="error" />}
                            </Typography>
                          ))}
                        </div>
                      </Tooltip>
                    </StyledBox>
                  )}
                  {user.hubspotFilter && user.hubspotFilter.length > 0 && (
                    <StyledBox>
                      <Tooltip title="HubSpot Filter">
                        <div>
                          {user.hubspotFilter.map((filter, index) => (
                            <Typography key={filter.name} component="div" sx={{ display: 'flex' }}>
                              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{filter.name}:&nbsp;</Typography>
                              {filter.value ? <Check color="primary" /> : <Close color="error" />}
                            </Typography>
                          ))}
                        </div>
                      </Tooltip>
                    </StyledBox>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title="Queue Limit">
                      <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mx: 1 }}>
                        {user.queueLimit}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mx: 1 }}>
                      {user.status}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ListItem>
    </>
  )
}
