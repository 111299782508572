// useDefaultTemplates.js
import { useState, useEffect } from 'react';
import Http from '../http/httpClient';

export const useDefaultTemplates = () => {
  const [defaultTemplates, setDefaultTemplates] = useState([]);
  const [stepText, setStepText] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);

  useEffect(() => {
    const fetchDefaultTemplates = async () => {
      setTemplatesLoading(true);
      Http.getData('getDefaultTemplates', { withStepText: 1 })
        .then(function (response) {
          if (response && response.status) {
            setDefaultTemplates([...response.templates]);
            setStepText([...response.stepText]);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(() => {
          setTemplatesLoading(false);
        });
    };

    fetchDefaultTemplates();
  }, []);

  return {
    defaultTemplates,
    setDefaultTemplates,
    stepText,
    setStepText,
    templatesLoading,
    setTemplatesLoading,
  };
};
