import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { ReactComponent as Done } from "../../../assets/emptyState/Done.svg";
import { setPage } from "../../../redux/authSlice";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import Http from "../../../http/httpClient";
import dayjs from "dayjs";

import { ReactComponent as ArrowLeft } from "../../../assets/Icons/Arrow-Left.svg";

export default function DoneModal({
  onClose,
  open,
  post,
  timing,
  source,
  onCloseEditPostModal,
  onCloseWhenToPostModal,
  onCloseTopics,
  onCloseProfessionals,
  onCloseSocials,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation)

  const handleReturnToDashboardClick = () => {
    dispatch(setPage("retention"));
    onClose();
    onCloseWhenToPostModal();
    onCloseEditPostModal();
    if (typeof onCloseTopics === "function") {
      onCloseTopics();
    }

    if (typeof onCloseProfessionals === "function") {
      onCloseProfessionals();
    }

    if (typeof onCloseSocials === "function") {
      onCloseSocials();
    }

    window.location.href = "/main";
  };

  React.useEffect(() => {
    if (source === "ComposePost") {
      // We handle the API call in the parent component.
      // This component is just for display in the ComposePost flow.
      return;
    }

    if (open && timing && timeOptionHandlers[timing]) {
      timeOptionHandlers[timing]();
    }
  }, [open, timing]);

  // TODO: Use apiHelper.publishUserPost instead
  const publishUserPost = async (post, delayInDays) => {
    let result = false;
    if (post) {
      const postToPublish = { ...post };
      if (
        post.message &&
        post.message.length > 0 &&
        postToPublish.message !== post.message
      ) {
        postToPublish.message = post.message;
      }

      const endpoint = authSliceSelectedDelegation?.userId 
        ? `publishPost/${authSliceSelectedDelegation.userId}`
        : 'publishPost';

      try {
        const res = await Http.postData(endpoint, {
          post: postToPublish,
          priority: delayInDays <= 0 ? "immediate" : "scheduled",
          time: dayjs().add(delayInDays, "day").toISOString(),
        });

        if (res && res.status) {
          result = res.status;
        } else {
          throw new Error("Error in publishing post");
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    return result;
  };

  const timeOptionHandlers = {
    "Right Now": async () => {
      try {
        await publishUserPost(post, 0);
      } catch (error) {}
    },
    Tomorrow: async () => {
      try {
        await publishUserPost(post, 1);
      } catch (error) {}
    },
    "Next Week": async () => {
      try {
        await publishUserPost(post, 7);
      } catch (error) {}
    },
    "In 2 Weeks": async () => {
      try {
        await publishUserPost(post, 14);
      } catch (error) {}
    },
  };

  return (
    <Dialog onClose={onClose} open={open} fullScreen
      sx={{ 
        px: {
          xs: 0,
          md: 0,
        },
        m: 0,
        maxWidth: '100vw',
      }}>
      <DialogTitle sx={{ m: 0, p: 0 }}>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ px: 2, pt: 2 }}>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <ArrowLeft fill={theme.palette.primary.grey} />
            </IconButton>
          </Grid>
        </Grid>
        <Stack
          direction="column"
          alignItems="center"
          spacing={4}
          sx={{ mt: 1 }}
        >
          <Done />
          <Typography
            sx={{
              fontSize: "35px",
              fontWeight: 700,
              color: theme.palette.primary.black,
            }}
          >
            You're all set!
          </Typography>
          <Button
            onClick={handleReturnToDashboardClick}
            sx={{
              maxWidth: "200px",
              backgroundColor: theme.palette.primary.lightBlue,
              color: theme.palette.primary.blue,
              textTransform: "none",
              width: "167px",
              py: 1,
              px: 2,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              borderRadius: 2,
              boxShadow: "0px 4px 8px 0px #2C64E31F",
              "&:hover": {
                backgroundColor: theme.palette.primary.blue,
                color: theme.palette.primary.white,
              },
            }}
          >
            Return to dashboard
          </Button>
        </Stack>
      </DialogTitle>
    </Dialog>
  );
}
