import { useState, useEffect } from 'react';
import Http from '../http/httpClient';

export const useAllUsers = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchUserList = async () => {
      Http.getData('users/list')
        .then((res) => {
          const resUsers = res.users?.map((item) => ({
            id: item._id,
            name: item.name,
            email: item.email
          }));
          setUsers(resUsers);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchUserList();
  }, []);

  return users ? [users, setUsers] : [[], setUsers];
};
