import React, { forwardRef, useImperativeHandle } from 'react';
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import CloseIcon from '@mui/icons-material/Close'
import PTextfield from '../library/PTextfield';


const ManualContact = forwardRef(({ listItem, setContactList, setListItem }, ref) => {
  const theme = useTheme();
  const [savedContacts, setSavedContacts] = React.useState(listItem?.selectedContacts ?? []);
  const [contacts, setContacts] = React.useState([]);

  const handleFieldChange = (fieldName, value) => {
    setContacts((prevContact) => ({ 
        ...prevContact,
        [fieldName]: value,
    }))
    setContactList((prevContact) => ({
        ...prevContact,
        [fieldName]: value,
      }));
  };

  useImperativeHandle(ref, () => ({
    addSavedContact: (contact) => {
        setSavedContacts((prevContact) => [...prevContact, contact])
        setContacts([])
      },
  }));

  const handleDeleteContact = (index) => {
    const updatedContacts = [...savedContacts];
    updatedContacts.splice(index, 1);
    setSavedContacts(updatedContacts);

    setListItem((prevItem) => ({
        ...prevItem,
        selectedContacts: updatedContacts,

    }));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault(); 
    }
  };

  return (

    <>
        <Grid container justifyContent="space-between">
          <Grid item xs={3} />
          <Grid item xs={6}>
                <>
                  <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 5, lineHeight: 'normal' }}>
                    Add professionals manually
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 4 }}>
                    This will allow you to create a list of people to target.
                  </Typography>
                {savedContacts?.map((contactInfo, index) => (   
                <Chip
                    key={index}
                    label={contactInfo.name ? contactInfo.name : `${contactInfo.first_name} ${contactInfo.last_name}`}
                    onDelete={() => handleDeleteContact(index)}
                    deleteIcon={<CloseIcon style={{ color: theme.palette.primary.grey }} />}
                    sx={{
                    mr: 1,
                    color: theme.palette.primary.grey,
                    backgroundColor: theme.palette.primary.lightBlue,
                    '& .MuiChip-deleteIcon': {
                        color: theme.palette.primary.grey,
                    },
                    }}
                />  
                ))}
                </>
             
            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                  }}
                >
                  Name{' '}
                  <span style={{ color: theme.palette.primary.orange, marginLeft: '4px' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={6} paddingLeft='0px !important'>
                <PTextfield
                  placeholder='First name'
                  value={contacts.first_name ?? ''}
                  onChange={handleFieldChange }
                  required={true}
                  onKeyDown={handleKeyDown}
                  fieldName="first_name"
                />
              </Grid>
              <Grid item xs={6} paddingLeft='0px !important'>
                <PTextfield
                  placeholder='Last name'
                  value={contacts.last_name ?? ''}
                  onChange={handleFieldChange }
                  required={true}
                  onKeyDown={handleKeyDown}
                  fieldName="last_name"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                    marginTop: '16px',
                  }}
                >
                  Email
                  {/* Temporarily removing email as required field
                  {' '}
                  <span style={{ color: theme.palette.primary.orange, marginLeft: '4px' }}>*</span>
                  */}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft='0px !important'>
                 <PTextfield
                  placeholder='Email'
                  value={contacts.email ?? ''}
                  onChange={handleFieldChange}
                  required={true}
                  onKeyDown={handleKeyDown}
                  fieldName="email"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                    marginTop: '16px',
                  }}
                >
                  Phone Number
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft='0px !important'>
                <PTextfield
                  placeholder='Phone number'
                  value={contacts.phoneNumber ?? ''}
                  onChange={handleFieldChange}
                  required={true}
                  onKeyDown={handleKeyDown}
                  fieldName="phoneNumber"
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px !important' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                    marginTop: '16px',
                  }}
                >
                  Company & Role
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: theme.palette.primary.grey,
                    alignSelf: 'flex-end',
                  }}
                >
                  Optional
                </Typography>
              </Grid>
              <Grid item xs={6} paddingLeft='0px !important'>
                <PTextfield
                  placeholder='Company'
                  value={contacts.companyName ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="companyName"
                />
              </Grid>
              <Grid item xs={6} paddingLeft='0px !important'>
                <PTextfield
                  placeholder='Role'
                  value={contacts.role ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="role"
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px !important' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                    marginTop: '16px',
                  }}
                >
                  Social
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: theme.palette.primary.grey,
                    alignSelf: 'flex-end',
                  }}
                >
                  Optional
                </Typography>
              </Grid>
              <Grid item xs={6} paddingLeft='0px !important'>
                <PTextfield
                  placeholder='LinkedIn profile URL'
                  value={contacts.linkedin_url ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="linkedin_url"
                />
              </Grid>
              <Grid item xs={6} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="X (Twitter) handle"
                  value={contacts.twitter_url ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="twitter_url"
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px !important' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                    marginTop: '16px',
                  }}
                >
                  Birthday
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: theme.palette.primary.grey,
                    alignSelf: 'flex-end',
                  }}
                >
                  Optional
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: theme.palette.primary.grey,
                    alignSelf: 'flex-end',
                  }}
                >
                  Send messages or gifts on their birthday
                </Typography>
              </Grid>
              <Grid item xs={4} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="MM"
                  value={contacts.month ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="month"
                />
              </Grid>
              <Grid item xs={4} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="DD"
                  value={contacts.day ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="day"
                />
              </Grid>
              <Grid item xs={4} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="YYYY"
                  value={contacts.year ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="year"
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px !important' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                    marginTop: '16px',
                  }}
                >
                  Anniversary
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: theme.palette.primary.grey,
                    alignSelf: 'flex-end',
                  }}
                >
                  Optional
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: theme.palette.primary.grey,
                    alignSelf: 'flex-end',
                  }}
                >
                  Send messages or gifts on their anniversary
                </Typography>
              </Grid>
              <Grid item xs={4} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="MM"
                  value={contacts.anniversaryMonth ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="anniversaryMonth"
                />
              </Grid>
              <Grid item xs={4} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="DD"
                  value={contacts.anniversaryDay ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="anniversaryDay"
                /> 
              </Grid>

              <Grid item xs={4} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="YYYY"
                  value={contacts.anniversaryYear ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="anniversaryYear"
                /> 
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px !important' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                    marginTop: '16px',
                  }}
                >
                  Contract Renewal Date
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: theme.palette.primary.grey,
                    alignSelf: 'flex-end',
                  }}
                >
                  Optional
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: theme.palette.primary.grey,
                    alignSelf: 'flex-end',
                  }}
                >
                  Send messages or gifts on their contract renewal
                </Typography>
              </Grid>
              <Grid item xs={4} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="MM"
                  value={contacts.contractRenewalMonth ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="contractRenewalMonth"
                /> 
              </Grid>
              <Grid item xs={4} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="DD"
                  value={contacts.contractRenewalDay ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="contractRenewalDay"
                /> 
              </Grid>
              <Grid item xs={4} paddingLeft='0px !important'>
                <PTextfield
                  placeholder="YYYY"
                  value={contacts.contractRenewalYear ?? ''}
                  onChange={handleFieldChange}
                  onKeyDown={handleKeyDown}
                  fieldName="contractRenewalYear"
                /> 
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} />
        </Grid>
       </>
  );
});

export default ManualContact;