import * as React from 'react'
import { Button, Box, TextField, CircularProgress, Select, MenuItem, InputLabel, FormControl } from '@mui/material'

import Http from '../../../http/httpClient'
import CustomSnackBar from '../../../components/CustomSnackBar'
import { Severity } from '../../../constants/constant'

export default function MailScheduler () {
  const [isLoading, setIsLoading] = React.useState(false)
  const [payload, setPayload] = React.useState('')
  const [action, setAction] = React.useState(null)
  const [delay, setDelay] = React.useState('2')
  const [snackmessage, setSnackmessage] = React.useState('')
  const [severity, setSeverity] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const ActionTypes = {
    RESCHEDULE: 'Reschedule',
    PAUSE: 'Pause',
    UNPAUSE: 'Unpause',
    REVALIDATE_EMAIL_STEPS: 'RevalidateEmailSteps',
    RESCHEDULE_QUEUED_RECURRING_STEPS: 'RescheduleQueuedRecurringSteps'
  }

  React.useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => {
        setOpen(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [open]);

  const handlePayloadChange = (e) => {
    setPayload(e.target.value)
  }

  const handleActionChange = (e) => {
    setAction(e.target.value)
  }

  const handleDelayChange = (e) => {
    setDelay(e.target.value)
  }

  const setAlertMessage = (severity, message) => {
    setSeverity(severity)
    setSnackmessage(message)
    setOpen(true)
  }

  const handlePayloadSubmit = (event) => {
    event.preventDefault()

    if (!action) {
      setAlertMessage(Severity.ERROR, 'Please select anyone action.')
      return
    }

    if (payload?.length === 0) {
      setAlertMessage(Severity.ERROR, 'Please provide a valid payload.')
      return
    }

    let apiEndPoint = null
    let params = null
    try {
      const jsonObject = JSON.parse(payload)
      jsonObject.action = action

      if (action === ActionTypes.RESCHEDULE) {
        const delayInt = parseInt(delay)
        if (isNaN(delayInt)) {
          setAlertMessage(Severity.ERROR, 'Please provide a delay for the Reschedule action.')
          return
        }
        jsonObject.delay = delayInt // Set delay if action is Reschedule
      }

      switch (action) {
        case ActionTypes.RESCHEDULE:
          apiEndPoint = 'admin/mailScheduler'
          params = {
            payload: {
              userId: jsonObject.userId,
              sequenceId: jsonObject.sequenceId,
              contactId: jsonObject.contactId,
              contactStepId: jsonObject.contactStepId,
              stepStatusId: jsonObject.stepStatusId,
              delay,
              action
            }
          }
          break
        case ActionTypes.PAUSE:
        case ActionTypes.UNPAUSE:
          if (!jsonObject.sequenceId) {
            setAlertMessage(Severity.ERROR, 'Please provide sequence ID for Pause/Unpause actions.')
            return
          }

          if (!(jsonObject.contactId || jsonObject.contactIds?.length > 0)) {
            setAlertMessage(Severity.ERROR, 'Please provide a contactID or contactIDs for Pause/Unpause actions.')
            return
          }

          apiEndPoint = 'updateSequenceContactSettings'
          params = {
            type: jsonObject.action,
            sequenceData: {
              sequenceId: jsonObject.sequenceId,
              contactIds: jsonObject.contactIds?.length > 0 ? jsonObject.contactIds : [jsonObject.contactId]
            },
            userDetails: { userId: jsonObject.userId }
          }
          break
        case ActionTypes.REVALIDATE_EMAIL_STEPS:
          {
            apiEndPoint = 'admin/mailScheduler'
            params = {
              payload: {
                userIds: jsonObject.userIds,
                action
              }
            }
          }
          break
        case ActionTypes.RESCHEDULE_QUEUED_RECURRING_STEPS:
          {
            apiEndPoint = 'admin/mailScheduler'
            params = {
              payload: {
                userId: jsonObject.userId,
                sequenceId: jsonObject.sequenceId,
                testRun: jsonObject.testRun,
                action
              }
            }
          }
          break
        default:
          console.log('Invalid action detected')
          break
      }
    } catch (error) {
      setAlertMessage(Severity.ERROR, 'Invalid JSON format in payload.')
      return
    }

    console.log('apiEndPoint', apiEndPoint)
    console.log('params', params)

    if (!apiEndPoint) {
      console.error('Invalid params provided. Hence, return')
      setAlertMessage(Severity.ERROR, 'Invalid params provided. Please provide a valid payload.')
      return
    }

    // example params

    // for Reschedule - here the delay represents that delay in minutes
    // {"userId": "658139ee73322fe7da8c511f","sequenceId": "65813a9373322fe7da8c5275", "contactId": "65813a6273322fe7da8c524a",
    // "contactStepId": "65813a9373322fe7da8c527b", "stepStatusId": "65813a9373322fe7da8c5281", "delay": "2", "action": "Reschedule"}

    // for Pause one or more contacts
    // {"userId": "658139ee73322fe7da8c511f","sequenceId": "65813a9373322fe7da8c5275", "contactIds": ["65813a6273322fe7da8c524a"], "action": "Pause"}

    // for Unpause one or more contacts
    // {"userId": "658139ee73322fe7da8c511f","sequenceId": "65813a9373322fe7da8c5275", "contactIds": ["65813a6273322fe7da8c524a"], "action": "Unpause"}

    setIsLoading(true)
    Http.postData(apiEndPoint, params)
      .then((res) => {
        if (res && res.status === true) {
          setAlertMessage(Severity.SUCCESS, 'Action completed successfully!!')
        } else {
          setAlertMessage(Severity.ERROR, 'Action failed!!')
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
        setPayload('')
      })
  }

  return (
    <>
      <Box p={2} width='80%'>
        <FormControl fullWidth>
          <InputLabel id='action-label'>Action</InputLabel>
          <Select
            labelId='action-label'
            id='action-select'
            value={action}
            label='Action'
            onChange={handleActionChange}
          >
            <MenuItem value={ActionTypes.RESCHEDULE}>Reschedule</MenuItem>
            <MenuItem value={ActionTypes.PAUSE}>Pause</MenuItem>
            <MenuItem value={ActionTypes.UNPAUSE}>Unpause</MenuItem>
            <MenuItem value={ActionTypes.REVALIDATE_EMAIL_STEPS}>RevalidateEmailSteps</MenuItem>
            <MenuItem value={ActionTypes.RESCHEDULE_QUEUED_RECURRING_STEPS}>RescheduleQueuedRecurringSteps</MenuItem>
          </Select>
        </FormControl>
        {action === ActionTypes.RESCHEDULE && (
          <TextField
            id='delay-input'
            label='Delay (in minutes)'
            type='number'
            value={delay}
            defaultValue={delay}
            onChange={handleDelayChange}
            margin='normal'
            fullWidth
          />
        )}
        <TextField
          id='mail-scheduler'
          label='Payload'
          variant='outlined'
          value={payload}
          multiline
          rows={3}
          onChange={handlePayloadChange}
          margin='normal'
          fullWidth
        />
      </Box>
      <Box display='flex' alignItems='center' gap={2} flexDirection='row'>
        <Button
          sx={{
            mt: 3,
            ml: 1,
            px: 3,
            my: 2,
            backgroundColor: '#C4C4C4',
            color: '#000000',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            textTransform: 'none',
            fontFamily: 'Poppins'
          }}
          disabled={isLoading}
          onClick={handlePayloadSubmit}
        >
          {isLoading
            ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '52px',
                  height: '34px',
                  display: 'flex',
                  borderRadius: 16,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress color='inherit' size={34} />
              </Box>
              )
            : (
                'Schedule'
              )}
        </Button>
      </Box>

      <CustomSnackBar
        open={open}
        onClose={() => setOpen(false)}
        message={snackmessage}
        severity={severity}
      />
    </>
  )
}
