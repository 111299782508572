import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Modal from '@mui/material/Modal'
import Link from '@mui/material/Link'
import CircularProgress from '@mui/material/CircularProgress'

import Config from '../../../clientConfig/config'
import { GetCookie } from '../../../helper/cookieManager'
import { generateRandomId } from '../../../helper/utilities'
import { handleAuthentication } from '../../../helper/authUtility'
import { useTheme } from '@mui/material';

export default function AffinityAuthModal ({ open, onClose, from }) {
  const theme = useTheme();

  const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #3983F7',
          boxShadow: 24,
          [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
            width: '90%',
            p: 2,
          },
          [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
            width: '700px',
            p: 4,
          }
        }

  const [loading, setLoading] = React.useState(false)
  // const [userEmail, setUserEmail] = React.useState('')
  const [apiKey, setApiKey] = React.useState('')
  // const [crmId, setCrmId] = React.useState('')
  const [validationError, setValidationError] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState('')

  /* const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value)
  }

  const handleCrmIdChange = (event) => {
    setCrmId(event.target.value)
  } */

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value)
  }

  const handleConnect = () => {
    if (!apiKey.trim()) {
      setValidationError(true)
      setErrorMsg('Please fill in all the required fields.')
      return
    }

    setLoading(true)
    try {
      const user = GetCookie('user')
      const userEmail = user?.email
      const crmId = generateRandomId(12)
      const url = `/api/auth/affinity?route=${from}&userEmail=${encodeURIComponent(userEmail)}&apiKey=${encodeURIComponent(apiKey)}&crmId=${encodeURIComponent(crmId)}`
      handleAuthentication(url)
    } catch (error) {
      console.error('Failed while onboard affinity crm. Error :', error)
    } finally {
      setLoading(false)
      /* setUserEmail('')
      setCrmId('') */
      setApiKey('')
      onClose()
    }
  }

  const handleClose = () => {
    /* setUserEmail('')
    setCrmId('') */
    setApiKey('')
    setErrorMsg('')
    setValidationError(false)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Box sx={style}>
          <Button
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px'
            }}
          >
            <CloseIcon />
          </Button>
          <Typography
            component='h1'
            sx={{
              fontWeight: 700,
              fontSize: 28,
              py: 1,
              fontFamily: 'Poppins',
              color: '#000000'
            }}
          >
            Connect with Affinity CRM
          </Typography>
          <Typography sx={{ mt: 2, fontSize: 16 }}>
            To integrate Affinity CRM with Postilize, you'll need to generate an API secret key and configure it in this section. For detailed instructions on obtaining your API Key, please refer to this {' '}
            <Link href="https://support.affinity.co/hc/en-us/articles/360032633992-How-to-obtain-your-API-Key" target="_blank" rel="noopener noreferrer">
              article
            </Link>
            .
          </Typography>

          {/* <Box sx={{ display: 'flex', mt: 4, justifyContent: 'center' }}>
            <TextField
              label="User Email (required)"
              placeholder="Enter your email"
              variant="outlined"
              value={userEmail}
              onChange={handleUserEmailChange}
              sx={{
                mr: 2,
                mb: 2,
                height: '40px',
                width: '400px'
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
            <TextField
              label="CRM ID (required)"
              placeholder="Enter your CRM ID"
              variant="outlined"
              value={crmId}
              onChange={handleCrmIdChange}
              sx={{
                mr: 2,
                mb: 2,
                height: '40px',
                width: '400px'
              }}
            />
          </Box> */}

          <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
            <TextField
              label="API Key (required)"
              placeholder="Enter your API key"
              variant="outlined"
              value={apiKey}
              onChange={handleApiKeyChange}
              sx={{
                mr: 2,
                mb: 2,
                height: '40px',
                width: '400px'
              }}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Divider sx={{ height: 2, backgroundColor: '#E0E0E0', margin: '0 10px'}}/>
          </Box>
          <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column', alignItems: 'flex-end' }}>
            <Box sx={{ display: 'flex', mt: 0, justifyContent: 'flex-end' }}>
              <Button
                  variant="contained"
                  onClick={handleConnect}
                  sx={{
                    mt: 3,
                    ml: 1,
                    py: 1,
                    backgroundColor: '#3983F7',
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: 600,
                    textTransform: 'none',
                    width: 120,
                    borderRadius: '8px',
                    boxShadow: 0,
                  }}
                >
                  {loading ? (
                    <Box
                      sx={{
                        position: 'relative',
                        width: '84px',
                        height: '34px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress color='inherit' />
                    </Box>
                  ) : (
                    'Connect'
                  )}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{
                    mt: 3,
                    ml: 1,
                    py: 1,
                    color: '#3983F7',
                    fontSize: '14px',
                    fontWeight: 600,
                    textTransform: 'none',
                    width: 120,
                    borderRadius: '8px',
                    boxShadow: 0
                  }}
                >
                  Cancel
                </Button>
              </Box>
              {validationError && (
              <Typography sx={{ color: 'red', mt: 1 }}>
                { errorMsg }
              </Typography>
            )}
          </Box>
        </Box>
      </>
    </Modal>
  )
}
