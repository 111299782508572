import { Grid, InputBase } from "@mui/material";
import PTypography from "./PTypography";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState, useRef } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const PDatePicker = ({
  selectedDate,
  handleDateChange,
  setDateTimeError,
  readonly,
  autoCloseOnSelect = false,
}) => {
  const calendarRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const now = new Date();

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    if (showCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  const onDateSelected = (newDate) => {
    handleDateChange(newDate);
    if (autoCloseOnSelect) {
      setShowCalendar(false); // close the calendar if auto-close is enabled
    }
  };

  return (
    <>
      <Grid item xs={12}>
        { /* TODO: Refactor this string to a prop */ }
        <PTypography size={"body2"}>Date</PTypography>
      </Grid>
      <Grid item xs={12} sx={{ mt: "12px", mb: "12px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={{ position: "relative", width: "100%" }}>
            <InputBase
              disabled={readonly}
              value={
                new Date(selectedDate).getDate() === now.getDate() &&
                new Date(selectedDate).getMonth() === now.getMonth() &&
                new Date(selectedDate).getFullYear() === now.getFullYear()
                  ? "Today"
                  : selectedDate.format("MM/DD/YYYY")
              }
              onClick={() => {
                setShowCalendar(true);
              }}
              sx={{
                width: "100%",
                fontFamily: "Inter",
                px: 3,
                py: 1,
                border: `1px solid #E0E0E0`,
                borderRadius: "14px",
                height: "56px",
              }}
            />

            {showCalendar && !readonly && (
              <div
                ref={calendarRef}
                style={{ position: "absolute", top: 0, left: 0, zIndex: 100 }}
              >
                <DateCalendar
                  date={selectedDate}
                  disablePast={true}
                  onChange={onDateSelected}
                  onError={(error) => {
                    setDateTimeError(error);
                  }}
                  style={{
                    overflowY: "auto",
                    backgroundColor: "white",
                    borderRadius: "14px",
                    border: `1px solid #E0E0E0`,
                  }}
                />
              </div>
            )}
          </div>
        </LocalizationProvider>
      </Grid>
    </>
  );
};

export default PDatePicker;
