import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

import Http from '../../../http/httpClient'

export default function UserFocusContactSettings () {
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState('')
  const [users, setUsers] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [maxUserFocusContactLimit, setMaxUserFocusContactLimit] = React.useState('')
  const [success, setSuccess] = React.useState(false)

  const resetUserQueueHandle = () => {
    setSelectedOption('')
    setMaxUserFocusContactLimit('')
  }

  const handleUpdate = async () => {
    setIsLoading(true)
    try {
      await fetch('/api/setFocusContactLimit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: selectedOption,
          maxUserFocusContactLimit: maxUserFocusContactLimit
        })
      })

      console.log('Focus contact limit updated successfully')
      setOpen(false)
      setSuccess(true)
    } catch (error) {
      console.log('Failed to update focus contact limit', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOptionChange = async (e) => {
    setSelectedOption(e.target.value)
    try {
      const response = await fetch(`/api/getFocusContactLimit?userId=${e.target.value}`)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data = await response.json()
      setMaxUserFocusContactLimit(data.maxUserFocusContactLimit)
    } catch (error) {
      console.error(error)
    }
  }

  const handleMaxFocusContactLimitChange = (e) => {
    setMaxUserFocusContactLimit(e.target.value)
  }

  const handleConfirm = () => {
    if (selectedOption.length === 0 || maxUserFocusContactLimit.length === 0) {
      alert('Field values cannot be empty. Please provide some values.')
    } else {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSuccess(false)
  }

  React.useEffect(() => {
    const fetchAvailableUsers = async () => {
      Http.getData('users/list')
        .then((res) => {
          const resUsers = []
          res.users.map((item) => {
            resUsers.push({ id: item.id, name: item.name, email: item.email})
          })
          setUsers(resUsers)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    fetchAvailableUsers()
  }, [])

  return (
    <>
      <Box display='flex' flexDirection='column' gap={2} alignItems='flex-start'>
        <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
          <Box component='span' mr={2} sx={{ marginRight: 13 }}>Select User:</Box>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ minWidth: 200 }}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name} - {user.email}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
          <Box component='span' mr={2}>Max Focus Contact Limit:</Box>
          <TextField
            type='number'
            InputProps={{ inputProps: { min: 1, max: 100 } }}
            value={maxUserFocusContactLimit}
            onChange={handleMaxFocusContactLimitChange}
            sx={{ minWidth: 200 }}
          />
        </Box>
        <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
          <Button
            sx={{
              mt: 3,
              px: 3,
              backgroundColor: '#C4C4C4',
              color: '#000000',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textTransform: 'none',
              fontFamily: 'Poppins'
            }}
            onClick={handleConfirm}
          >
            {isLoading
              ? (
                <Box
                  sx={{
                    position: 'relative',
                    width: '52px',
                    height: '34px',
                    display: 'flex',
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress color='inherit' />
                </Box>
                )
              : (
                  'Confirm'
                )}
          </Button>
          <Button
            sx={{
              mt: 3,
              px: 3,
              backgroundColor: '#C4C4C4',
              color: '#000000',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textTransform: 'none',
              fontFamily: 'Poppins'
            }}
            onClick={resetUserQueueHandle}
          >
            Reset
          </Button>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>'Update Focus Contact Limit'</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure you want to update the focus contact limit for the selected user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpdate} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Snackbar open={success} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity='success' sx={{ width: '100%' }}>
          Focus contact limit updated successfully!
        </Alert>
      </Snackbar>
    </>
  )
}
