import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import CompanyList from './companyList'

export default function ManageCompanies () {
  const [selectedUserTab, setSelectedUserTab] = React.useState('Companies')

  const handleTabChange = (event, selectedTab) => {
    setSelectedUserTab(selectedTab)
  }

  let content
  switch (selectedUserTab) {
    case 'Companies':
      content = <CompanyList />
      break
    default:
      content = <CompanyList />
      break
  }

  return (
    <>
      <Box sx={{ maxWidth: { xs: 480, sm: 600 }, bgcolor: 'background.paper' }}>
        <Tabs
          value={selectedUserTab}
          onChange={handleTabChange}
          variant='fullWidth'
          aria-label='manage companies menu'
        >
          <Tab label='Companies' value='Companies' />
        </Tabs>
      </Box>
      <Toolbar />
      <>
        {content}
      </>
    </>
  )
}
