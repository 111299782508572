import { useState, useRef, useEffect } from 'react';
import { useTheme, Box } from '@mui/material';
import PTypography from '@/components/library/PTypography';
import PTextfield from '@/components/library/PTextfield';
import { getUserPersona } from '@/helper/apiHelper';

export default function Personas(props) {
  const theme = useTheme();
  const personaInputRef = useRef(null);
  const [persona, setPersona] = useState('');
  const [personaRows] = useState(1);

  useEffect(() => {
    getUserPersona()
      .then((response) => {
        setPersona(response);
      })
      .catch((error) => {
        console.error('Error getting user persona:', error);
      });
  }, []);

  const titleStyle = {
    fontSize: "32px",
    fontWeight: 700,
    color: theme.palette.primary.black,
    pt: { xs: 5, md: 2 },
    textAlign: "left",
    pl: { xs: 5 },
    mt: { xs: 4 },
    lineHeight: "normal",
  };

  const descriptionStyle = {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.primary.grey,
    pb: 5,
    pl: { xs: 5 },
    pr: { xs: 2 },
  };

  const labelStyle = {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.primary.black,
    pl: { xs: 5 },
  };

  const textFieldStyle = {
    ml: 1,
    flex: 1,
    minHeight: `${personaRows * 1.5}em`,
  };

  const textFieldPaperStyle = {
    mb: 3,
    width: { xs: "90vw", md: "80%" },
    ml: { xs: 5 },
  };

  return (
    <Box>
      <PTypography sx={titleStyle}>
        Personas
      </PTypography>
      <PTypography sx={descriptionStyle}>
        This will help you customize your messages to sound more like your writing style.
      </PTypography>
      <PTypography sx={labelStyle}>
        Your Persona
      </PTypography>

      <PTextfield
        ref={personaInputRef}
        type='text'
        value={persona}
        placeholder='Please install the chrome extension to enable persona'
        disabled
        multiline
        maxRows={10}
        sx={{
          ...textFieldStyle,
          '&::placeholder': {
            color: '#4a4a4a', // Custom darker grey color
          },
        }}
        paperStyle={textFieldPaperStyle}
      />
    </Box>
  );
}
