import React, { useState, useEffect } from 'react'
import { Box, MenuItem, Select, CircularProgress, Button,
         Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
         Paper, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
         Grid, List, ListItem, ListItemText, Divider,  } from '@mui/material'

import Http from '../../../http/httpClient';

const DisplayJson = ({ data }) => {
  const renderObject = (obj, indentLevel = 0) => {
    if (!obj) { return null; }
    return Object.keys(obj).map((key) => {
      const value = obj[key];
      const indent = { paddingLeft: `${indentLevel * 20}px` };

      if (typeof value === 'object' && !Array.isArray(value)) {
        return (
          <div key={key}>
            <strong style={indent}>{key}:</strong>
            {renderObject(value, indentLevel + 1)}
          </div>
        );
      } else if (Array.isArray(value)) {
        return (
          <div key={key}>
            <strong style={indent}>{key}:</strong>
            <ul style={indent}>
              {value.map((item, index) => (
                <li key={index} style={{listStyle: 'none'}}>{renderObject(item, indentLevel + 1)}</li>
              ))}
            </ul>
          </div>
        );
      } else {
        return (
          <div key={key} style={indent}>
            <strong>{key}:</strong> {value.toString()}
          </div>
        );
      }
    });
  };

  return <div>{renderObject(data)}</div>;
};

export default function RelationshipScores () {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState('');
  const [relationshipScores, setRelationshipScores] = useState(null);
  const [preferenceLoading, setPreferenceLoading] = useState(false);

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      Http.getData('users/list')
        .then((res) => {
          const resUsers = []
          res.users.map((item) => {
            resUsers.push({ id: item._id.toString(), name: item.name, email: item.email })
          })
          setUsers(resUsers)
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          setUsersLoading(false)
        })
    }

    fetchAvailableUsers()
  }, [])

  const fetchRelationshipScores = async () => {
    Http.getData(`user/relationshipScores`, { userId: selectedUser })
      .then((res) => {
        if (res && res.relationshipScores !== undefined) {
          setRelationshipScores(res.relationshipScores);
        }
      })
      .catch((error) => {
        console.error('Error fetching user preference:', error);
        setRelationshipScores(null);
      })
      .finally(() => {
        setPreferenceLoading(false);
      })
  }

  React.useEffect(() => {
    setPreferenceLoading(true)
    if (selectedUser.length > 0) {
      fetchRelationshipScores();
    } else {
      setPreferenceLoading(false)
    }
  }, [selectedUser])

  const handleUserChange = (e) => {
    setSelectedUser(prev => e.target.value)
  }

  const regenerateRelationshipScores = async () => {
    setPreferenceLoading(true)
    await Http.postData('user/relationshipScores', { userId: selectedUser });
    await new Promise((resolve) => setTimeout(resolve, 10000)); // wait 10 seconds
    fetchRelationshipScores();
  }

  const refreshRelationshipScores = async () => {
    setPreferenceLoading(true)
    fetchRelationshipScores();
  }

  return (
    <>
      <h3>Relationship Scores</h3>
      <Box display='flex' flexDirection='column' gap={2} alignItems='flex-start' sx={{ width: '100%' }}>
        <Box sx={{ flexGrow: 1, mx: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            { usersLoading ?
              (
                <Box
                  sx={{
                    position: 'relative',
                    width: '52px',
                    height: '34px',
                    display: 'flex',
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress color='inherit' />
                </Box>
              ) :
              (
                <>
                  <Select
                    value={selectedUser}
                    onChange={handleUserChange}
                    sx={{ minWidth: 200 }}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name} - {user.email}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button
                    variant='outlined'
                    sx={{
                      marginLeft: 2,
                      color: 'white',
                      backgroundColor: '#3983F7',
                      textTransform: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '15px',
                      borderRadius: 10,
                      '&:hover': {
                        backgroundColor: 'black',
                        color: 'white'
                      }
                    }}
                    onClick={refreshRelationshipScores}
                  >
                    Refresh
                  </Button>
                  <Button
                    variant='outlined'
                    sx={{
                      marginLeft: 2,
                      color: 'white',
                      backgroundColor: '#3983F7',
                      textTransform: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '15px',
                      borderRadius: 10,
                      '&:hover': {
                        backgroundColor: 'black',
                        color: 'white'
                      }
                    }}
                    onClick={regenerateRelationshipScores}
                  >
                    Re-generate
                  </Button>
                </>
              )
            }
          </Box>
          { preferenceLoading ?
              (
                <Box
                  sx={{
                    position: 'relative',
                    width: '52px',
                    height: '34px',
                    display: 'flex',
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress color='inherit' />
                </Box>
              ) :
              (relationshipScores &&
                <Box sx={{ my: 2, mr: 3, p: 2 }} >
                  <TableContainer component={Paper} sx={{maxHeight: 650}} >
                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="preference table">
                      <TableBody>
                        <TableRow
                          key={'Relationship Scores'}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left">
                            <DisplayJson data={relationshipScores} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )
            }
        </Box>
      </Box>
    </>
  )
}
