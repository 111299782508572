import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/system/Stack';
import Avatar from '@mui/material/Avatar';

import ConnectListItem from '../../../setup/simpleSetupComponents/connectListItem';
import CRMResults from './CRMResults';
import CRMFilters from './CRMFilters';

import { ReactComponent as Salesforce } from '../../../../assets/logos/Salesforce.svg';
import { ReactComponent as Affinity } from '../../../../assets/logos/Affinity.svg';
import { ReactComponent as Hubspot } from '../../../../assets/logos/Hubspot.svg';

import { Providers } from '../../../../constants/constant';
import { SetCookie, GetCookie } from '../../../../helper/cookieManager';
import Http from '../../../../http/httpClient';

import { ReactComponent as Back } from '../../../../assets/Icons/Arrow-Left.svg';

export default function CRMListModal(props) {
  const theme = useTheme();
  const { onClose, open, listItem, setListItem, handleListCreation, setupList } = props;
  const [activeProvider, setActiveProvider] = React.useState(null);
  const [isConnectedAsNew, setIsConnectedAsNew] = React.useState(false);
  const [openFiltersModal, setOpenFiltersModal] = React.useState(false);

  const [providerList, setProviderList] = React.useState([
    {
      title: 'Salesforce',
      logo: <Salesforce />,
      provider: Providers.SALESFORCE,
      connected: null,
      selected: false,
    },
    {
      title: 'Affinity',
      logo: <Affinity />,
      provider: Providers.AFFINITY,
      connected: null,
      selected: false,
    },
    {
      title: 'HubSpot',
      logo: <Hubspot />,
      provider: Providers.HUBSPOT,
      connected: null,
      selected: false,
    },
  ]);

  const handleClose = async () => {
    onClose();
  };

  const handleContinue = () => {
    setOpenFiltersModal(true);
  };

  async function getConnectedState(provider) {
    const luser = GetCookie('user');
    if (luser) {
      const p = luser.providers.find((acc) => acc !== null && acc.provider === provider) || [];
      if (p && p.profileId) {
        return {
          connected: true,
          profileId: p.profileId,
          primary: p.primary,
          profileImage: p && p.imageUrl ? p.imageUrl : luser.imageUrl,
        };
      }
    }
    return { connected: false, profileId: null, primary: null, profileImage: null };
  }

  const fetchData = async () => {
    const updatedList = await Promise.all(
      providerList.map(async (item) => {
        const profile = await getConnectedState(item.provider);
        return {
          ...item,
          connected: profile.connected,
          primary: profile.primary,
          profileId: profile.profileId,
          profileImage: profile.profileImage,
        };
      }),
    );

    setProviderList(updatedList);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    const bc = new BroadcastChannel('setupAuthChannel');
    bc.addEventListener('message', (event) => {
      if (event.data.status === 200) {
        fetchData();
        setIsConnectedAsNew(true);
      }
    });

    return () => {
      bc.removeEventListener('message', () => {});
      bc.close();
    };
  }, []);

  const handleSelectedProvider = (item) => {
    if (item.connected) {
      setActiveProvider(item.provider);
      setListItem((prev) => {
        const providers = (prev.providers || []).slice();
        const index = providers.findIndex((provider) => provider.providerType === 'crm');

        if (index !== -1) {
          providers[index] = {
            ...providers[index],
            provider: item.provider,
            isExpanded: false,
            enrichmentCompleted: !isConnectedAsNew,
          };
        }

        return {
          ...prev,
          providers: providers,
          provider: item.provider,
        };
      });
    }
  };

  const getContainerStyles = (provider) => ({
    boxShadow: 0,
    fontSize: '14px',
    borderRadius: 4,
    width: '25vw',
    padding: '0px 14px 14px 0px ',
    backgroundColor: activeProvider === provider ? theme.palette.primary.lightBlue : theme.palette.primary.white,
    color: activeProvider === provider ? theme.palette.primary.white : theme.palette.primary.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  });

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullScreen>
        <DialogTitle sx={{ p: 0 }}>
          <Grid container alignItems="flex-start" justifyContent="space-between">
            <Grid item>
              <IconButton aria-label="close" onClick={handleClose} sx={{ pt: 2, pl: 2 }}>
                <Back fill={theme.palette.primary.black} />
              </IconButton>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={10} sx={{ my: 1.5 }}>
                {setupList?.map((item) => (
                  <Stack justifyContent="center" alignItems="center">
                    <Avatar
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        bgcolor: item.active ? theme.palette.primary.blue : theme.palette.primary.white,
                        color: item.active ? theme.palette.primary.white : theme.palette.primary.grey,
                        border: item.active ? `none` : `1px solid #E0E0E0`,
                        my: 1,
                      }}
                    >
                      {item.index}
                    </Avatar>
                    <Typography
                      sx={{
                        color: item.active ? theme.palette.primary.black : theme.palette.primary.grey,
                        fontWeight: 500,
                      }}
                    >
                      {item.listText}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid item>
              <Button
                onClick={handleContinue}
                disabled={!activeProvider}
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'mr': 2,
                  'mt': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={0} md={3} />
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                  pt: 1,
                  fontSize: {
                    xs: '28px',
                    md: '32px',
                  },
                  lineHeight: 'normal',
                }}
              >
                Import your CRM
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: theme.palette.primary.grey,
                  pb: 5,
                }}
              >
                This will help you identify your most important connections.
              </Typography>

              <Grid container spacing={2}>
                {providerList.map((provider) => (
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    xl={3}
                    key={`${provider.title}${provider.connected}${new Date().toString()}`}
                    onClick={() => handleSelectedProvider(provider)}
                    sx={getContainerStyles(provider.provider)}
                  >
                    <ConnectListItem currProvider={provider} source={'setupAuth'} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={0} md={3} />
          </Grid>
        </DialogContent>
      </Dialog>

      {openFiltersModal && (
        <CRMFilters
          open={openFiltersModal}
          onClose={() => {
            setOpenFiltersModal(false);
          }}
          listItem={listItem}
          setListItem={setListItem}
          handleListCreation={handleListCreation}
        />
      )}
    </>
  );
}
