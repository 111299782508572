import React, { useState, useMemo, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import PDropdownSubmenu from './PDropdownSubmenu';
import CheckIcon from '@mui/icons-material/Check';

const makeStyles = (theme) => ({
  menuItem: {
    'fontSize': theme.typography.body1.fontSize,
    '&:hover': {
      backgroundColor: theme.palette.primaryCL.Blue40,
    },
  },
  icon: {
    marginRight: 16,
    width: '12px',
    height: '12px',
  },
  checkIcon: {
    width: '16px',
    height: '16px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    paddingRight: '4px',
    width: '24px',
  },
});

export default function PMenuItem({ item, selectedItem, onClick }) {
  const theme = useTheme();
  const styles = useMemo(() => makeStyles(theme), [theme]);

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleItemClick = useCallback(() => {
    if (typeof item?.onSubmit === 'function') {
      item.onSubmit();
    }
    if (typeof onClick === 'function') {
      onClick();
    }
  }, [item, onClick]);

  return (
    <MenuItem sx={styles.menuItem} key={item?.name} onClick={handleItemClick}>
      <div style={styles.container}>
        <div style={styles.leftSection}>
          {item?.icon && item?.isEnabled && React.createElement(item?.icon, { style: styles.icon })}
          {item?.name}
        </div>
        <div style={styles.rightSection}>
          {item?.name === selectedItem && item?.isEnabled && !item?.placeholder && (
            <CheckIcon style={styles.checkIcon} />
          )}
        </div>
        {item?.hasSubMenu && (
          <PDropdownSubmenu
            key={item?.name}
            menuList={item?.subMenu}
            anchorEl={anchorEl}
            isOpen={isOpen}
            onClick={handleClick}
            onClose={handleClose}
            onSubmit={item?.onSubmit}
          />
        )}
      </div>
    </MenuItem>
  );
}
