import * as React from "react";
import { ReactComponent as Delete } from "../../assets/Icons/Delete.svg";
import Box from "@mui/material/Box";
import { ReactComponent as Back } from "../../assets/Icons/Arrow-Left.svg";
import { useTheme } from "@mui/material/styles";

export default function PBackButton({ iconType, onClick }) {
  const theme = useTheme();
  const buttonStyle = {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "52px",
    borderRadius: "14px",
    backgroundColor: theme.palette.primary.main,
    padding: "16px, 20px, 16px, 20px",
    "&:hover": {
      backgroundColor: theme.palette.primaryCL.Blue40,
    },
  };
  return iconType === "back" ? (
    <Box onClick={onClick} sx={buttonStyle}>
      <Back fill={theme.palette.primaryCL.Blue100} />
    </Box>
  ) : (
    <Box onClick={onClick} sx={buttonStyle}>
      <Delete fill={theme.palette.primaryCL.Blue100} />
    </Box>
  );
}
