import { useState, useEffect } from 'react';
import {
  useTheme,
  DialogTitle,
  DialogContent,
  Button,
  Dialog,
  Typography,
  IconButton,
  Grid,
  Box,
  Alert,
  Stack,
  Avatar,
  Snackbar,
} from '@mui/material';

import Dart from '@Assets/Icons/Dart.png';
import File from '@Assets/Icons/File.png';
import Person from '@Assets/Icons/Person.png';
import Star from '@Assets/Icons/Star.png';

import { ReactComponent as Delete } from '@Assets/Icons/Delete.svg';

import ManualListModal from './Lists/ManualListModal';
import CSVListModal from './Lists/CSVListModal';
import CRMListModal from './Lists/CRMListModal';
import AISearchModal from './Lists/AISearchModal';

import { generateAISearchPayload } from '@/helper/apiHelper';

import { SetCookie, GetCookie } from '@/helper/cookieManager';
import Http from '@/http/httpClient';

export default function ListModal(props) {
  const theme = useTheme();
  const { onClose, open, setCreatedListId, setupList, targetAudience } = props;
  const [modalToOpen, setModalToOpen] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [listItem, setListItem] = useState({});
  const [openAIModal, setOpenAIModal] = useState(false);
  const [openAIPayloadModal, setOpenAIPayloadModal] = useState(false);
  const [openAIResultModal, setOpenAIResultModal] = useState(false);
  const [aiSearchPayload, setAiSearchPayload] = useState({});
  const [aiPayloadFormData, setAiPayloadFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (aiSearchPayload && Object.keys(aiSearchPayload).length > 0) {
      setOpenAIPayloadModal(true);
      setModalToOpen('AI_PAYLOAD');
    }
  }, [aiSearchPayload]);

  const goalText = [
    { id: 'retain', title: 'Make a list of new customers to acquire.' },
    { id: 'acquire', title: 'Make a list of current clients to retain and grow.' },
    { id: 'grow', title: 'Make a list of professionals to reach to grow your brand presence.' },
  ];

  const selectedGoal = goalText.find((item) => item.id === props.selectedGoal?.goal?.id);

  const [listMethod, setListMethod] = useState(
    [
      {
        id: 'AI',
        icon: Star,
        title: 'Start with AI',
        subtitle: 'Quickly create targeted lists.',
        active: false,
        popular: true,
      },
      {
        id: 'ManualContact',
        icon: Person,
        title: 'Add people manually',
        subtitle: 'Simple way to add an audience.',
        active: false,
      },
      { id: 'csv', icon: File, title: 'Import CSV file', subtitle: 'Easily add your marketing lists.', active: false },
      { id: 'crm', icon: Dart, title: 'From your CRM', subtitle: 'HubSpot, Salesforce, Affinity.', active: false },
    ].filter((item) => (targetAudience ? targetAudience[item.id.toLowerCase()] : true)),
  );

  const handleClose = () => {
    onClose(true);
  };

  const handleContinue = () => {
    const listStatus = listMethod.find((item) => item.active === true);
    console.log('handleContinue', listStatus);
    if (!listStatus) {
      setAlertMessage('Select any item to continue');
      setShowAlert(true);
    } else {
      setModalToOpen(listStatus.id);
      listStatus.id === 'AI' ? setOpenAIModal(true) : setOpenAIModal(false);
    }
  };

  useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const handleListClick = (listIndex) => {
    const updatedListMethod = listMethod.map((method, index) => {
      if (index === listIndex) {
        return { ...method, active: !method.active };
      } else {
        return { ...method, active: false };
      }
    });
    setListMethod(updatedListMethod);

    // Determine which modal to open based on the clicked button
    const selectedMethod = updatedListMethod[listIndex].id;
    setModalToOpen(selectedMethod);

    setListItem((prev) => {
      const providers = (prev.providers || []).slice();
      return {
        ...prev,
        providers: [
          {
            providerType: selectedMethod,
          },
        ],
        name: props.selectedGoal?.goal?.id,
      };
    });
  };

  useEffect(() => {
    const audienceCount = Object.values(targetAudience || []).filter((value) => value === true).length;
    if (audienceCount === 1) {
      const activeList = listMethod.find((audience) => targetAudience?.[audience.id.toLowerCase()]);

      if (activeList) {
        setListItem((prev) => {
          const providers = (prev.providers || []).slice();
          return {
            ...prev,
            providers: [
              {
                providerType: activeList.id,
              },
            ],
            name: props.selectedGoal?.goal?.id,
          };
        });

        const providerType = activeList.id;
        setModalToOpen(providerType);
      } else {
        setModalToOpen(null);
      }
    }
  }, []);

  const handleCloseListModal = () => {
    const audienceCount = Object.values(targetAudience || []).filter((value) => value === true).length;
    console.log(audienceCount);
    if (audienceCount === 1) {
      handleClose();
    } else {
      setModalToOpen(null);
    }
  };

  const handleAIResult = () => {
    setOpenAIResultModal(true);
    setModalToOpen('AI_RESULT');
  };
  const handleListCreation = () => {
    return new Promise((resolve, reject) => {
      if (listItem.provider === 'rocketreach') {
        listItem.name = listItem.providers[0].AIRequest;
      } else {
        listItem.name = listItem.provider;
      }
      Http.postData('createList', { listDetails: listItem })
        .then((res) => {
          if (res.status) {
            setCreatedListId(res.listId);
            const newProvider = listItem.providers?.find((pro) => pro !== null && pro.enrichmentCompleted === false);
            if (newProvider) {
              let userCookie = GetCookie('user');
              if (userCookie && userCookie.providers) {
                const p = userCookie.providers.find((p) => p.provider === newProvider.provider);
                if (p) {
                  p.isInitialEnrichDone = newProvider.enrichmentCompleted;
                  SetCookie('user', JSON.stringify(userCookie));
                }
              }
            }

            resolve();
          } else {
            reject('Error creating list.');
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          onClose();
        });
    });
  };

  const handleAI = () => {
    const aiProvider = listItem?.providers?.find((provider) => provider.providerType === 'AI');
    if (aiProvider?.AIRequest?.length > 0) {
      setIsLoading(true);
      generateAISearchPayload(aiProvider.AIRequest)
        .then((response) => {
          if (!response.error) {
            setAiSearchPayload(response.payload);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setAlertMessage(response.error);
            setShowAlert(true);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setIsLoading(false);
          setAlertMessage('Error generating AI search payload.');
          setShowAlert(true);
        });
    } else {
      setAlertMessage('Please input your required prompt.');
      setShowAlert(true);
    }
  };

  const aiListPromptProps = {
    open: true,
    setAiPrompt: (description) => {
      setListItem((prev) => {
        const providers = (prev.providers || []).slice();
        const index = providers.findIndex((provider) => provider.providerType === 'AI');
        if (index !== -1) {
          providers[index] = {
            ...providers[index],
            provider: 'rocketreach',
            AIRequest: description,
            isExpanded: false,
          };
        }
        return {
          ...prev,
          providers: providers,
          provider: 'rocketreach',
          selectedContacts: [],
          allContacts: [],
        };
      });
    },
    listItem,
  };

  const aiListPayloadProps = {
    updateFormData: (data) => setAiPayloadFormData(data),
    formData: aiPayloadFormData,
    aiSearchPayload: aiSearchPayload,
    setAiSearchPayload: (data) => setAiSearchPayload(data),
    setAiPrompt: (description) => {
      setListItem((prev) => {
        const providers = (prev.providers || []).slice();
        const index = providers.findIndex((provider) => provider.providerType === 'AI');
        if (index !== -1) {
          providers[index] = {
            ...providers[index],
            provider: 'rocketreach',
            AIRequest: description,
            isExpanded: false,
          };
        }
        return {
          ...prev,
          providers,
          provider: 'rocketreach',
          selectedContacts: [],
          allContacts: [],
        };
      });
    },
    listItem,
  };

  const aiResultsProps = {
    formData: aiPayloadFormData,
    listItem: listItem,
    setselectedTargetContacts: (c) => {
      setListItem((prev) => ({
        ...prev,
        selectedContacts: c.selectedContacts,
        allContacts: c.allContacts,
      }));
    },
  };

  const aiModalProps = {
    openAIModal: openAIModal,
    openAIPayloadModal: openAIPayloadModal,
    openAIResultModal: openAIResultModal,
    listItem: listItem,
    isLoading: isLoading,
    setModalToOpen: setModalToOpen,
    setOpenAIModal: setOpenAIModal,
    setOpenAIPayloadModal: setOpenAIPayloadModal,
    setOpenAIResultModal: setOpenAIResultModal,
    handleAI: handleAI,
    handleAIResult: handleAIResult,
    handleListCreation: handleListCreation,
    aiListPromptProps: aiListPromptProps,
    aiListPayloadProps: aiListPayloadProps,
    aiResultsProps: aiResultsProps,
  };

  const renderModal = () => {
    switch (modalToOpen) {
      case 'ManualContact':
        return (
          <ManualListModal
            open={true}
            onClose={() => handleCloseListModal()}
            setListItem={setListItem}
            handleListCreation={handleListCreation}
            forSetup={true}
            setupList={setupList}
          />
        );
      case 'csv':
        return (
          <CSVListModal
            open={true}
            onClose={() => handleCloseListModal()}
            setListItem={setListItem}
            handleListCreation={handleListCreation}
            setupList={setupList}
          />
        );
      case 'crm':
        return (
          <CRMListModal
            open={true}
            onClose={() => handleCloseListModal()}
            listItem={listItem}
            setListItem={setListItem}
            handleListCreation={handleListCreation}
            setupList={setupList}
          />
        );
      case 'AI':
      case 'AI_PAYLOAD':
      case 'AI_RESULT':
        return <AISearchModal modalType={modalToOpen} {...aiModalProps} />;
      default:
        return null;
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullScreen>
        <DialogTitle sx={{ p: 0 }}>
          <Grid container alignItems="flex-start" justifyContent="space-between">
            <Grid item>
              <IconButton aria-label="close" onClick={handleClose} sx={{ pt: 2, pl: 2 }}>
                <Delete fill={theme.palette.primary.grey} />
              </IconButton>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={10} sx={{ my: 1.5 }}>
                {setupList?.map((item) => (
                  <Stack justifyContent="center" alignItems="center">
                    <Avatar
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        bgcolor: item.active ? theme.palette.primary.blue : theme.palette.primary.white,
                        color: item.active ? theme.palette.primary.white : theme.palette.primary.grey,
                        border: item.active ? `none` : `1px solid #E0E0E0`,
                        my: 1,
                      }}
                    >
                      {item.index}
                    </Avatar>
                    <Typography
                      sx={{
                        color: item.active ? theme.palette.primary.black : theme.palette.primary.grey,
                        fontWeight: 500,
                      }}
                    >
                      {item.listText}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid item>
              <Button
                onClick={listMethod.length === 0 ? handleClose : handleContinue}
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'mr': 2,
                  'mt': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.lightBlue,
                    color: theme.palette.primary.blue,
                  },
                }}
              >
                {listMethod.length === 0 ? 'Close' : 'Continue'}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={0} md={3} />
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                  pt: 1,
                  fontSize: {
                    xs: '28px',
                    md: '32px',
                  },
                  lineHeight: 'normal',
                }}
              >
                Make a list of new customers to acquire.
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: theme.palette.primary.grey,
                  pb: 5,
                }}
              >
                This helps us find the best way to reach your target audience.
              </Typography>

              {listMethod.length === 0 ? (
                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
                  <Grid item>
                    <Typography variant="body1">
                      Unfortunately, you don't have permission to create the list at the moment. Please reach out to
                      your administrator for further information.
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {listMethod.map((method, index) => (
                    <Grid item key={method.id} xs={12} sm={6}>
                      <div
                        style={{
                          border: `1px solid ${method.active ? theme.palette.primary.lightBlue : '#E0E0E0'}`,
                          borderRadius: '10px',
                          padding: '24px 16px',
                          display: 'flex',
                          flexDirection: 'column',
                          minHeight: '100%', // Ensure all items are the same height
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alienItems: 'center',
                            height: '36px',
                          }}
                        >
                          <img src={method.icon} alt={method.title} style={{ height: '24px', width: '24px' }} />
                          {method.popular && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: theme.palette.primary.orange,
                                my: 1,
                                backgroundColor: theme.palette.primary.lightOrange,
                                textTransform: 'uppercase',
                                px: 1,
                                borderRadius: 2,
                                fontWeight: 500,
                              }}
                            >
                              Popular
                            </Typography>
                          )}
                        </Box>
                        <Typography
                          sx={{ fontWeight: 700, fontSize: '14px', color: theme.palette.primary.black, mt: 2 }}
                        >
                          {method.title}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '14px', color: theme.palette.primary.grey }}>
                          {method.subtitle}
                        </Typography>
                        <Button
                          sx={{
                            mt: 4,
                            ml: 1,
                            py: 1,
                            backgroundColor: method.active
                              ? theme.palette.primary.blue
                              : theme.palette.primary.lightBlue,
                            color: method.active ? theme.palette.primary.white : theme.palette.primary.blue,
                            fontSize: '14px',
                            fontWeight: 500,
                            textTransform: 'none',
                            width: '100%',
                            borderRadius: '6px',
                            boxShadow: 0,
                          }}
                          onClick={() => handleListClick(index)}
                        >
                          {method.active ? 'Selected' : 'Select'}
                        </Button>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid item xs={0} md={3} />
          </Grid>
        </DialogContent>
      </Dialog>
      {modalToOpen && renderModal()}
      {alertMessage && (
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
