// client/src/components/library/PPostCard/AwardsCard.js
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Stack } from '@mui/material';
import PTypography from '@Library/PTypography';
import { useTheme } from '@mui/material/styles';
import { truncateText } from '@/helper/utilities';

const avatarStyle = {
  height: '32px',
  width: '32px',
};
const boxStyle = {
  mt: '12px',
  maxWidth: '100%',
};
const messageStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const AwardsCard = ({ post, onContactClick }) => {
  const { milestoneDetails } = post.sequenceAttr;
  const theme = useTheme();
  const stackStyle = {
    backgroundColor: theme.palette.primaryCL.Black50,
    borderRadius: 'var(--8, 8px)',
    width: '100%',
    ml: '1px',
    mb: '20px',
    p: '12px',
    overflow: 'hidden',
  };

  const handleContactClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      if (onContactClick) {
        onContactClick();
      }
    },
    [onContactClick],
  );

  return (
    <Box>
      <Stack direction="column" spacing="24px">
        <Stack direction="column" justifyContent="space-between" sx={stackStyle}>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Box onClick={handleContactClick}>
              <Avatar sx={avatarStyle} src={post.ImageUrl} />
            </Box>
            <Stack direction="column" spacing="2px" alignItems="flex-start">
              {post?.name && (
                <PTypography size="body2" weight="bold">
                  {post?.name}
                </PTypography>
              )}
            </Stack>
          </Stack>

          <Box sx={boxStyle}>
            <PTypography size="body2" weight="regular" preformatted sx={messageStyle}>
              {truncateText(post.message, 1000) || ''}
            </PTypography>
          </Box>
        </Stack>
      </Stack>

      <Stack direction="row" sx={{ mt: 3 }} spacing={1} justifyContent="space-between" alignItems="flex-start">
        <Stack direction="column" spacing="2px" alignItems="flex-start">
          <PTypography size="body2" weight="bold">
            Awards Info
          </PTypography>
        </Stack>
      </Stack>
      <Box sx={{ mt: 0.5 }}>
        <PTypography size="footnote" weight="regular">
        {milestoneDetails?.title && `${milestoneDetails?.title} ᛫ `}
        {milestoneDetails?.organization && `${milestoneDetails?.organization} ᛫ `}
        {milestoneDetails?.issuerDate && `${milestoneDetails?.issuerDate}`}
        </PTypography>
      </Box>
    </Box>
  );
};

AwardsCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default AwardsCard;
