import { createTheme } from '@mui/material/styles'

export const lightTheme = createTheme({
    palette: {
      background: {
        default: '#FFFFFF'
      },
      primary: {
        main: '#FFFFFF',
        blue: '#3063EA',
        lightBlue: '#E8EFFD',
        lightGrey: '#F7F7F7',
        grey: '#7389A5',
        black: '#191B1D',
        orange: '#EA6830',
        lightOrange: '#FBFDE8',
        white: '#FFFFFF',
      },
      primaryCL: {
        Blue110: '#2659E0',
        Blue100: '#3063EA',
        Blue90: '#3A6DF4',
        Blue60: '#DEE5F3',
        Blue50: '#E8EFFD',
        Blue40: '#F2F9FF',
        Black200: '#191B1D',
        Black100: '#7389A5',
        Black70: '#E3E3E3',
        Black50: '#F7F7F7',
        White110: '#FBFBFB',
        White100: '#FFFFFF',
        Orange100: '#EA6830',
        Orange50: '#FBFDE8',
        Green100: '#64B73A',
        Green50: '#EDFBE4',
        Purple100: '#6134E1',
        Purple50: '#EFEBFC',
        Pink100: '#D743AE',
        Pink50: '#FBECF7',
        Red100: '#D74359',
        Red50: '#FBECEE',
        Red40: '#FCF4F5',
        Teal100: '#0EA795',
        Teal50: '#E7FBF9'
      }
    },
    typography: {
      fontFamily: '"Inter", sans-serif' ,
      fontSize: 14,
      fontRegular: 500,
      fontBold: 700,
      footnote: {
        fontSize: '12px',
        lineHeight: '14.4px'
      },
      body1: {
        fontSize: '14px',
        lineHeight: '18.2px'
      },
      body2: {
        fontSize: '16px',
        lineHeight: '19.2px'
      },
      h1: {
        fontSize: '20px',
        lineHeight: '22px'
      },
      h2: {
        fontSize: '24px',
        lineHeight: '26.4px'
      },
      h3: {
        fontSize: '32px',
        lineHeight: '35.2px'
      },
      regular: {
        footnote: {
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '14.4px'
        },
        body1: {
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '18.2px'
        },
        body2: {
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19.2px'
        },
        h1: {
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '22px'
        },
        h2: {
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '26.4px'
        },
        h3: {
          fontWeight: 500,
          fontSize: '32px',
          lineHeight: '35.2px'
        },
      },
      bold: {
        footnote: {
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '14.4px'
        },
        body1: {
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '18.2px'
        },
        body2: {
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '19.2px'
        },
        h1: {
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '22px'
        },
        h2: {
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '26.4px'
        },
        h3: {
          fontWeight: 700,
          fontSize: '32px',
          lineHeight: '35.2px'
        }
      },
      button: {
        fontWeight: 700,
      }
    },
    MuiLink: {
      textDecoration: 'none',
      color: '#ff0000'
    },
    customBadge: {
      backgroundColor: '#00AFD7',
      color: 'white'
    }
  });