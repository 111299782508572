import React, { useState, useEffect } from 'react'
import { Box, MenuItem, Select, Button,
         Grid, IconButton, Paper, InputBase, InputLabel, FormControl } from '@mui/material'
import Container from '@mui/material/Container';
import { Typography, useTheme, CircularProgress } from '@mui/material'

import { ReactComponent as Delete } from '../../../assets/Icons/Delete.svg'
import Http from '../../../http/httpClient'
import CustomSnackBar from '../../../components/CustomSnackBar'
import AddStep from '../../ClientAcquisition/components/addSequenceModal/addStep'
import { Frequencies, Severity } from '../../../constants/constant'

// custom hooks
import { useDefaultTemplates } from '../../../hooks/useDefaultTemplates'
import { useStrategies } from '../../../hooks/useStrategies'

function SelectDay({step, index, handleDayChange}) {
  const theme = useTheme();
  const maxdays = 50
  const menuItems = Array(maxdays).fill().map((_, index) => index + 1)

  return (
    <Select
      value={step.day}
      onChange={(event) => { handleDayChange(event, index)}}
      sx={{
        '&.Mui-focused': {
          outline: 'none'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiMenu-paper': {
          boxShadow: 0
        },
        '& .MuiMenuItem-root': {
          fontWeight: 500,
          color: theme.palette.primary.black
        }
      }}
    >
      {menuItems.map((i) => (<MenuItem value={i}>Day {i}</MenuItem>))}
    </Select>
  )
}

function SelectFrequency({step, index, handleFrequencyChange}) {
  const theme = useTheme();
  const menuItems = Frequencies;


  return (
    <Select
      value={step.frequency || 50}
      onChange={(event) => { handleFrequencyChange(event, index) }}
      sx={{
        '&.Mui-focused': {
          outline: 'none'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiMenu-paper': {
          boxShadow: 0
        },
        '& .MuiMenuItem-root': {
          fontWeight: 500,
          color: theme.palette.primary.black
        }
      }}
    >
      {Object.keys(menuItems).map((i) => ( <MenuItem key={i} value={i}> {`${menuItems[i]}`} </MenuItem> ))}
    </Select>
  )
}

export default function SequenceTemplate () {
  const theme = useTheme()
  // const [defaultTemplates, setDefaultTemplates] = React.useState([])
  // const [templatesLoading, setTemplatesLoading] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [templateDetails, setTemplateDetails] = React.useState(null)
  // const [stepText, setStepText] = React.useState([])
  const [isAddStepOpen, setIsAddStepOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = useState(false)
  const [snackmessage, setSnackmessage] = React.useState('')
  const [severity, setSeverity] = React.useState(Severity.SUCCESS)
  // const [strategies, setStrategies] = useState([]); // ['grow', 'acquire', 'retain']
  const [selectedStrategy, setSelectedStrategy] = useState({});

  const strategies = useStrategies();
  const { defaultTemplates, setDefaultTemplates, stepText, setStepText, templatesLoading, setTemplatesLoading } = useDefaultTemplates();

  useEffect(() => {
    if (!selectedTemplate || selectedTemplate.length === 0) { setTemplateDetails(null) }

    const details = defaultTemplates.find((template) => template !== null && template.id === selectedTemplate)
    // Find the strategy for the selected template if not found then set it to empty string
    const strategy = strategies.find((strategy) => strategy.sequenceTemplate === details?._id) || '';
    setSelectedStrategy(strategy);
    if (details) {
      setTemplateDetails({ ...details });
    }
  }, [selectedTemplate])
  
  const handleTemplateChange = (e) => {
    setSelectedTemplate(prev => e.target.value)
  }

  const handleSnackClose = () => {
    setOpenSnack(false)
  }

  const handleFrequencyChange = (event, index) => {
    const selValue = event.target.value

    const updatedSteps = [...templateDetails.content]
    updatedSteps[index].frequency = selValue

    setTemplateDetails({...templateDetails, content: [...updatedSteps]})
  }

  const handleDayChange = (event, index) => {
    const newDay = event.target.value;
    // first I want to get the current step and all the steps in the template
    const allSteps = [...templateDetails.content]
    const currentStep = {...allSteps[index]}
    const updatedSteps = []
    if (newDay === currentStep?.day) { return }
    // next I want to reorder the steps based on the new day value
    // next I want to remove the current step from the array
    allSteps.splice(index, 1)
    // add the steps which are smaller than the new day
    for (const step of allSteps) {
      if (step.day < newDay) {
        updatedSteps.push({...step})
      }
    }
    // push the current step with the new day value
    updatedSteps.push({...currentStep, day: newDay})
    // push the remaining steps
    for (const step of allSteps) {
      if (step.day >= newDay) {
        updatedSteps.push({...step})
      }
    }
    setTemplateDetails({...templateDetails, content: [...updatedSteps]})
  }

  const handleTemplateNameChange = (e) => {
    const title = e.target.value
    setTemplateDetails(prev => ({...prev, title: title}))
  }

  const handleTemplateDescriptionChange = (e) => {
    const desc = e.target.value
    setTemplateDetails(prev => ({...prev, desc: desc}))
  }

  const getMaxStepDay = () => {
    if (templateDetails?.content?.length > 0) {
      const lastStep = templateDetails?.content[templateDetails?.content?.length - 1]
      return lastStep.day
    }

    return 0
  }

  const handleAddStep = (stepId) => {
    let stepDay = parseInt(getMaxStepDay()) + 1
    const newStep = {
      step: stepId,
      day: stepDay,
      frequency: stepId === 'like' || stepId === 'share' ? 50 : 0,
    };
    setTemplateDetails({...templateDetails, content: [...templateDetails.content, newStep]});
  };

  const closeTemplateDetails = () => {
    setSelectedTemplate('')
    setTemplateDetails(null)
  }

  const validateTemplateField = (field, error) => {
    if (templateDetails[field].length === 0) {
      setSnackmessage(error)
      setSeverity(Severity.ERROR)
      setOpenSnack(true)

      return false
    }

    return true
  }

  const saveTemplateDetails = async () => {
    if (!validateTemplateField('title', 'Template title should not be empty!') ||
        !validateTemplateField('desc', 'Template description should not be empty!') ||
        !validateTemplateField('content', 'Template should have atleast one step!')) {
          return;
    }

    const url = templateDetails.id.length === 0 ? `addDefaultTemplate` : `updateDefaultTemplate`
    let createdTemplates = [];
    await Http.postData(url, { templateDetails })
      .then((res) => {
        if (res.status === true) {
          setSnackmessage('Template changes saved successfully!')
          setSeverity(Severity.SUCCESS)
          setDefaultTemplates(res.templates)
          createdTemplates = res.templates; // this is hacky but we need to find the sequenceTemplateId from the createdTemplates
          closeTemplateDetails()
        } else {
          if (res.data?.message) {
            setSnackmessage(res.data?.message)
          } else {
            setSnackmessage('Unable to save template changes!')
          }
          setSeverity(Severity.ERROR)
        }
        setOpenSnack(true)
      })
      .catch((error) => {
        console.log(error)
        closeTemplateDetails()
      })
      // check for undefined values in templateDetails._id
      let sequenceTemplateId = null;
      if (templateDetails._id !== undefined) {
        sequenceTemplateId = templateDetails._id.length === 0 ? null : templateDetails._id
      } else {
        const template = createdTemplates.find((template) => template.title === templateDetails.title);
        sequenceTemplateId = template._id? template._id : null;
      }
      if (selectedStrategy && sequenceTemplateId !== null && severity === Severity.SUCCESS) {
        updateStrategySequenceTemplate(sequenceTemplateId, selectedStrategy._id);
      }
  }

  // Update the strategy sequence template
  const updateStrategySequenceTemplate = (sequenceTemplateId, strategyId) => {
    // Find the index of the strategy in state and update the sequenceTemplate
    const index = strategies.findIndex((strategy) => strategy._id === strategyId);
    
    if (index === -1) {
      console.error(`Strategy with id ${strategyId} not found.`);
      return;
    }
  
    strategies[index].sequenceTemplate = sequenceTemplateId;
  
    Http.postData('strategy/update/template', {
      strategyId,
      sequenceTemplateId,
    })
    .catch((error) => {
      console.error(`Failed to update strategy sequence template: ${error}`);
    });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleAddTemplate = () => {
    setTemplateDetails({
      id: "",
      type: "default",
      title: "",
      desc: "",
      content: []
    })
  }

  const handleDeleteTemplate = () => {
    Http.postData('deleteDefaultTemplate', { templateDetailsId: templateDetails.id })
      .then((res) => {
        if (res.status === true) {
          setSnackmessage('Template deleted successfully!')
          setSeverity(Severity.SUCCESS)
          setDefaultTemplates(res.templates)
          closeTemplateDetails()
        } else {
          if (res.data?.message) {
            setSnackmessage(res.data?.message)
          } else {
            setSnackmessage('Unable to delete the template!')
          }
          setSeverity(Severity.ERROR)
        }
        setOpenSnack(true)
      })
      .catch((error) => {
        console.log(error)
        closeTemplateDetails()
      })
  }

  return (
    <>
      <Box display='flex' flexDirection='column' gap={2} alignItems='flex-start' sx={{ width: '100%' }}>
        <Box sx={{ flexGrow: 1, mx: 2, width: '100%' }}>
          <Box sx={{display: 'flex', justifyContent: 'space-between', width: '70%' }} >
            <Box sx={{ display: 'flex', alignItems: 'center', visibility: (templateDetails && (!templateDetails.id || templateDetails.id.length === 0)) ? 'hidden' : 'visible' }} >
              { templatesLoading ?
                (
                  <Box
                    sx={{
                      position: 'relative',
                      width: '52px',
                      height: '34px',
                      display: 'flex',
                      borderRadius: 16,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <CircularProgress color='inherit' />
                  </Box>
                ) :
                (
                  <>
                    <Select
                      value={selectedTemplate}
                      onChange={handleTemplateChange}
                      sx={{ minWidth: 200 }}
                    >
                      {defaultTemplates.map((template) => (
                        <MenuItem key={template.id} value={template.id}>
                          {template.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )
              }
            </Box>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleAddTemplate}
                sx={{
                  backgroundColor: theme.palette.primary.lightBlue,
                  color: theme.palette.primary.blue,
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: '16px',
                  py: 1,
                  px: 2,
                  my: 2,
                  borderRadius: 2,
                }}>New Template</Button>
            </div>
          </Box>
          {
            templateDetails && (
              <>
                <Box sx={{ my: 2, mr: 3, p: 2, width: '70%', backgroundColor: theme.palette.primary.lightGrey, flexShrink: 0 }} >
                  <Grid container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                    <Grid item xs={2} alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black }}>Name: </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} alignItems="center" spacing={1} sx={{ mb: 1, p: 2 }}>
                      <Grid item>
                        <Paper
                          component='form'
                          sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, width: '35vw' }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1, }}
                            placeholder={'Name of the template'}
                            value={templateDetails.title}
                            onChange={handleTemplateNameChange}
                            onKeyDown={handleKeyDown}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black }}>Description: </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} alignItems="center" spacing={1} sx={{ mb: 1, p: 2 }} >
                      <Grid item>
                        <Paper
                          component='form'
                          sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, width: '35vw' }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            multiline
                            rows={3}
                            value={templateDetails.desc}
                            placeholder={'Brief description about the template'}
                            onChange={handleTemplateDescriptionChange}
                            onKeyDown={handleKeyDown}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <InputLabel
                      sx={{
                        fontSize: '14px', 
                        fontWeight: 700, 
                        color: theme.palette.primary.black
                      }}
                    >
                      Assign to Strategy
                    </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">Strategies</InputLabel>
                      <Select
                        value={selectedStrategy === null? '' : selectedStrategy}
                        onChange={(event) => {
                          const strategy = event.target.value;
                          setSelectedStrategy(strategy);
                        }}
                        sx={{
                          '&.Mui-focused': {
                            outline: 'none'
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiMenu-paper': {
                            boxShadow: 0
                          },
                          '& .MuiMenuItem-root': {
                            fontWeight: 500,
                            color: theme.palette.primary.black
                          }
                        }}
                      >
                        {strategies.map((strategy) => (
                          <MenuItem key={strategy.id} value={strategy}>
                            {strategy.id}
                          </MenuItem>
                        ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <div style={{minWidth: '35vw'}}>
                    {templateDetails.content.sort((a, b) => a.day - b.day).map((step, index) => (
                      <Container key={index} sx={{ backgroundColor: theme.palette.primary.white, py: 1, my: 2, borderRadius: 3, width: '35vw', height: '100%' }}>
                        <Grid container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                          <Grid container item xs={1} alignItems="center" spacing={1}>
                            <Grid item>
                              <IconButton onClick={() => {
                                const updatedSteps = [...templateDetails.content];
                                updatedSteps.splice(index, 1);
                                setTemplateDetails({...templateDetails, content: [...updatedSteps]});
                              }}>
                                <Delete fill={theme.palette.primary.grey} />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Grid container item xs={6} alignItems="center" spacing={1}>
                            <Grid item>
                              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.black }}>{stepText?.find(s => s !== null && s.id === step.step)?.desc}</Typography>
                            </Grid>
                          </Grid>
                          <Grid container item xs={5} alignItems="center" justifyContent="right" spacing={1}>
                            <Grid item>
                               {step.step === 'like' || step.step === 'share' ? (
                                  <SelectFrequency id='frequency' step={step} handleFrequencyChange={handleFrequencyChange} index={index}/>
                                ) : (
                                  <SelectDay id='startDate' step={step} handleDayChange={handleDayChange} index={index} />
                                )
                              }
                            </Grid>
                          </Grid> 
                        </Grid>
                      </Container>
                    ))}

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={() => setIsAddStepOpen(true)}
                        sx={{
                          boxShadow: 0,
                          textTransform: 'none',
                          fontWeight: 700,
                          borderRadius: 2,
                          width: '70%',
                          backgroundColor: theme.palette.primary.lightBlue,
                          color: theme.palette.primary.blue,
                          mx: 2,
                          mt: 2,
                          '&:hover': {
                            backgroundColor: theme.palette.primary.blue,
                            color: theme.palette.primary.white
                          }
                        }}
                      >
                        Add a step
                      </Button>
                      {isAddStepOpen && <AddStep
                          open={isAddStepOpen}
                          onClose={() => setIsAddStepOpen(false)}
                          onSubmit={handleAddStep}
                          templateDetails={templateDetails}
                        />}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '70%' }}>
                        <Button
                          onClick={closeTemplateDetails}
                          sx={{
                            boxShadow: 0,
                            textTransform: 'none',
                            fontWeight: 700,
                            borderRadius: 2,
                            width: '70%',
                            backgroundColor: theme.palette.primary.white,
                            color: theme.palette.warning.dark,
                            mr: 1,
                            mt: 2,
                            '&:hover': {
                              backgroundColor: theme.palette.warning.dark,
                              color: theme.palette.primary.white
                            }
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={saveTemplateDetails}
                          sx={{
                            boxShadow: 0,
                            textTransform: 'none',
                            fontWeight: 700,
                            borderRadius: 2,
                            width: '70%',
                            backgroundColor: theme.palette.primary.lightBlue,
                            color: theme.palette.primary.blue,
                            ml: 1,
                            mt: 2,
                            '&:hover': {
                              backgroundColor: theme.palette.primary.blue,
                              color: theme.palette.primary.white
                            }
                          }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', visibility: (templateDetails && (!templateDetails.id || templateDetails.id.length === 0)) ? 'hidden' : 'visible' }}>
                      <Button
                        onClick={handleDeleteTemplate}
                        sx={{
                          boxShadow: 0,
                          textTransform: 'none',
                          fontWeight: 700,
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          borderColor: theme.palette.warning.dark,
                          borderRadius: 2,
                          width: '70%',
                          backgroundColor: theme.palette.primary.white,
                          color: theme.palette.warning.dark,
                          mx: 2,
                          mt: 2,
                          '&:hover': {
                            backgroundColor: theme.palette.warning.dark,
                            color: theme.palette.primary.white
                          }
                        }}
                      >
                        Delete Template
                      </Button>
                    </Box>
                  </div>
                </Box>
              </>
            )
          }
        </Box>
        <CustomSnackBar
          open={openSnack}
          onClose={handleSnackClose}
          message={snackmessage}
          severity={severity}
        />
      </Box>
    </>
  )
}