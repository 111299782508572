import React, { useState } from 'react'
import PostTemplatesList from './postTemplatesList'
import PostTemplateForm from './postTemplatesForm'
import { Button, Typography, Box, Container } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const PostTemplateManagement = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentTemplateId, setCurrentTemplateId] = useState(null)

  const handleEditClick = (templateId) => {
    setCurrentTemplateId(templateId)
    setIsEditing(true)
  }

  const handleNewTemplateClick = () => {
    setCurrentTemplateId(null) // Ensure form is reset for a new template
    setIsEditing(true)
  }

  return (
    <Container maxWidth={false}>
      <Box sx={{ margin: '20px 0' }}>
        <Typography variant='h4' gutterBottom>
          Post Templates Management
        </Typography>
        {!isEditing && (
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleNewTemplateClick}
            sx={{ margin: 2 }}
          >
            Add New Template
          </Button>
        )}
        {isEditing
          ? (
            <PostTemplateForm
              templateId={currentTemplateId}
              setEditing={setIsEditing}
            />
            )
          : (
            <PostTemplatesList editTemplate={handleEditClick} />
            )}
      </Box>
    </Container>
  )
}

export default PostTemplateManagement
