import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import Checkbox from '@mui/material/Checkbox';
import { styled, useTheme } from '@mui/material/styles';
import { ButtonBase } from '@mui/material';

// The exact path from Figma for the checkmark.
// Note that it has been translated to have the origin at the top left corner.
const figmaCheckmarkPath = `
M 7.94272 0.94267
L 2.80466 6.08130
L 0       3.27600
L 0.94266 2.33333
L 3.00466 4.19533
L 7.00001 0      
L 7.94271 0.94267 Z`;
const checkmarkElementHeight = 6.0813; // Largest Y value in the checkmark
const checkmarkElementWidth = 7.94272; // Largest X value in the checkmark

// Exact path from Figma for the dash for the indeterminate state
// Note that it has been translated to have the origin at the top left corner.
const figmaDashPath = `
M 7.333333 1.333333
H 0
V 0
H 7.333333
V 1.66667 Z`;
const dashElementHeight = 1.33333; // Largest Y value in the dash
const dashElementWidth = 7.33333; // Largest X value in the dash

// Size of the actual, interactive button. Slightly bigger than the icon for better UX
const buttonSize = {
  true: '25px', // big === true
  false: '20px', // big === false
};

// Map the `big` prop onto the inner viewbox size
const innerViewboxSize = {
  true: 13.33, // Smaller viewbox for big icon
  false: 16, // Normal viewbox for small icon (`small` is 1:1 in Figma)
};

function CheckmarkElement(props = {}) {
  const {
    big,
    color,
  } = props;

  const innerVBoxSize = innerViewboxSize[big];
  const innerViewbox = `${checkmarkElementWidth / 2} ${checkmarkElementHeight / 2} ${innerVBoxSize} ${innerVBoxSize}`;

  return (
    <div
      style={{
        position: 'absolute',
      }}
    >
      <SvgIcon
        viewBox={innerViewbox}
        style={{
          position: 'absolute',
          overflow: 'visible',
        }}
      >
        <path d={figmaCheckmarkPath} fill={color} />
      </SvgIcon>
    </div>
  );
}

function DashElement(props = {}) {
  const { big, color } = props;

  const innerVBoxSize = innerViewboxSize[big];
  const innerViewbox = `${dashElementWidth / 2} ${dashElementHeight / 2} ${innerVBoxSize} ${innerVBoxSize}`;

  return (
    <div
      style={{
        position: 'absolute',
      }}
    >
      <SvgIcon
        viewBox={innerViewbox}
        style={{
          position: 'absolute',
          overflow: 'visible',
        }}
      >
        <path d={figmaDashPath} fill={color} />
      </SvgIcon>
    </div>
  );
}

function BoxElement(props = {}) {
  const {
    color, fillColor, rx, ry, children,
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SvgIcon
        viewBox="0 0 16 16"
        style={{
          overflow: 'visible',
        }}
      >
        <rect
          xmlns="http://www.w3.org/2000/svg"
          x={0}
          y={0}
          rx={rx} // Use passed rx value
          ry={ry} // Use passed ry value
          strokeWidth={1}
          fill={fillColor || 'none'}
          width="16px"
          height="16px"
          stroke={color}
        />
      </SvgIcon>
      {children}
    </div>
  );
}

const StyledPBaseControl = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'big',
})(({ _theme, big }) => {
  const fontSize = big ? '20px' : '16px';

  const checkboxStyles = {
    width: buttonSize[!!big],
    height: buttonSize[!!big],
    '& .MuiSvgIcon-root': {
      fontSize,
    },
  };

  return checkboxStyles;
});

function PBaseControl({
  big,
  onChange,
  checked,
  indeterminate,
  disabled,
  rx,
  ry,
  hoverOverride, // Used for Storybook. Let this component manage its own hover state in real usage.
}) {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  if (!rx || !ry) {
    throw new Error(
      'rx and ry are required for PBaseControl. Did you mean to use PCheckbox or PRadio?',
    );
  }

  // TODO: Disabled colors & behaviour on the icons
  const getIcon = useCallback(
    (iconProps = {}) => {
      const { hover, check, indet } = iconProps;

      const boxProps = {
        color: theme.palette.primaryCL.Black100,
        rx,
        ry,
        // fillColor: "none",
      };

      if (hover) {
        boxProps.color = theme.palette.primaryCL.Blue100;
        boxProps.children = CheckmarkElement({
          big,
          color: theme.palette.primaryCL.Black70,
        });
      } else if (check) {
        boxProps.color = theme.palette.primaryCL.Blue100;
        boxProps.fillColor = theme.palette.primaryCL.Blue100;
        boxProps.children = CheckmarkElement({
          big,
          color: theme.palette.primaryCL.White100,
        });
      } else if (indet) {
        boxProps.color = theme.palette.primaryCL.Blue100;
        boxProps.children = DashElement({
          big,
          color: theme.palette.primaryCL.Black100,
        });
      }

      return BoxElement(boxProps);
    },
    [theme.palette.primaryCL, big, rx, ry],
  );

  const hoveredIcon = useMemo(
    () => getIcon({ hover: true, check: false, indet: false }),
    [getIcon],
  );

  const uncheckedIcon = useMemo(
    () => getIcon({ hover: false, check: false, indet: false }),
    [getIcon],
  );

  const checkedIcon = useMemo(
    () => getIcon({ hover: false, check: true, indet: false }),
    [getIcon],
  );

  const indeterminateIcon = useMemo(
    () => getIcon({ hover: false, check: false, indet: true }),
    [getIcon],
  );

  const baseControlProps = useMemo(() => {
    if (isHovered || hoverOverride) {
      return {
        icon: hoveredIcon,
        checkedIcon: hoveredIcon,
      };
    }

    if (indeterminate) {
      return {
        icon: indeterminateIcon,
        checkedIcon,
      };
    }

    return {
      icon: uncheckedIcon,
      checkedIcon,
    };
  }, [isHovered, hoverOverride, indeterminate, uncheckedIcon, checkedIcon, hoveredIcon, indeterminateIcon]);

  const handleChange = React.useCallback(
    (event) => {
      if (disabled) {
        event.preventDefault();
        return;
      }
      if (onChange) {
        onChange(event, event.target.checked);
      }
    },
    [disabled, onChange],
  );

  return (
    <ButtonBase
      sx={{
        borderRadius: 5,
        width: buttonSize[!!big],
        height: buttonSize[!!big],
      }}
    >
      <StyledPBaseControl
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onChange={handleChange}
        checked={checked}
        big={big}
        icon={baseControlProps.icon}
        checkedIcon={baseControlProps.checkedIcon}
      />
    </ButtonBase>
  );
}

PBaseControl.propTypes = {
  /** If true, the checkbox will be larger. */
  big: PropTypes.bool,

  /** If true, the checkbox will be disabled. */
  disabled: PropTypes.bool,

  /** If true, the checkbox will be checked.
   * Only one of `checked` or `indeterminate` should be true at any given time.
   * Note: the Storybook control for this has no effect. */
  checked: PropTypes.bool,

  /** If true, the checkbox will be in the indeterminate state.
   * Only one of `checked` or `indeterminate` should be true at any given time. */
  indeterminate: PropTypes.bool,

  /** Callback fired when the state is changed. Make sure to handle the checked state in the parent component. */
  onChange: PropTypes.func,

  /** The x-border-radius of the checkbox.
   * If a number is passed, it is interpreted as pixels.
   * Otherwise, standard CSS units can be used. */
  rx: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** The y-border-radius of the checkbox.
   * If a number is passed, it is interpreted as pixels.
   * Otherwise, standard CSS units can be used. */
  ry: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Used for Storybook. Let this component manage its own hover state in real usage. */
  hoverOverride: PropTypes.bool,
};

PBaseControl.defaultProps = {
  big: false,
  checked: false,
  indeterminate: false,
  disabled: false,
  onChange: () => {},
  rx: null,
  ry: null,
  hoverOverride: false,
};

export default PBaseControl;
