import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { ReactComponent as User } from '../../assets/Icons/User.svg';

import PTypography from './PTypography';
import PToolTip from './PToolTip';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Blue100,
        background: theme.palette.primaryCL.Blue50,
        hoverText: theme.palette.primaryCL.Blue100,
        hoverBackground: theme.palette.primaryCL.Blue40,
        activeText: theme.palette.primaryCL.Blue110,
        activeBackground: theme.palette.primaryCL.Blue60,
      };
      break;
  }

  return colorScheme;
};

const StyledPSideMenuUser = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'pVariant',
})(({ theme, pVariant, active, showLabel }) => {
  const {
    background,
    text,
    hoverBackground,
    hoverText,
    activeBackground,
    activeText
  } = getVariantStyles(theme, pVariant);

  return {
    ...theme.typography.bold.body2,
    borderRadius: '14px',
    padding: showLabel ? '16px 20px' : '16px',
    fontFamily: 'Inter',
    textTransform: 'none',
    width: '100%',
    color: active ? activeText : text,
    justifyContent: showLabel ? 'flex-start' : 'center',
    alignItems: 'flex-start',
    backgroundColor: active ? activeBackground : background,
    '&:hover': {
      backgroundColor: hoverBackground,
      color: hoverText,
      '& .MuiButton-startIcon': {
        color: hoverText,
      },
    },
    '&:active': {
      backgroundColor: activeBackground,
      color: activeText,
      '& .MuiButton-startIcon': {
        color: activeText,
      },
    },
    '& .MuiButton-startIcon': {
      margin: 0,
      marginRight: showLabel ? '14px' : '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      '& > *': {
        margin: '0 !important',
      },
    },
  };
});


function PSideMenuUser({ children, Icon, pVariant, onClick, active, showLabel, subtitle }) {
  const theme = useTheme();
  const iconFill = (active ? getVariantStyles(theme, pVariant).activeText : getVariantStyles(theme, pVariant).text) || null;

  // Map our variant to the MUI variant
  const translatedVariant = {
    primary: 'contained',
  }[pVariant] || 'contained';

  const menuItem = (
    <StyledPSideMenuUser
      variant={translatedVariant}
      disableElevation
      pVariant={pVariant}
      startIcon={<User fill={iconFill} />}
      onClick={onClick}
      showLabel={showLabel}
    >
      {showLabel && (
        <Stack alignItems="flex-start" gap="4px" sx={{ p: 0, m: 0, overflow: "hidden" }}>
          <PTypography
            size="body2"
            weight="bold"
            sx={{
              // truncate text overflow with an ellipsis
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {children}
          </PTypography>
          <PTypography size="footnote" weight="regular">
            {subtitle}
          </PTypography>
        </Stack>
      )}
    </StyledPSideMenuUser>
  );

  return showLabel ? (
    menuItem
  ) : (
    <PToolTip title={children} placement="right">
      {menuItem}
    </PToolTip>
  );
}

PSideMenuUser.propTypes = {
  /** React children */
  children: PropTypes.node,

  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary']),

  /** Click handler */
  onClick: PropTypes.func.isRequired,
};

PSideMenuUser.defaultProps = {
  Icon: null,
  pVariant: 'primary',
  showLabel: true,
  active: false,
};

export default PSideMenuUser;
