export const Industries = [
    {label: "3D Printing"},
    {label: "3D Technology"},
    {label: "Accounting"},
    {label: "Administrative Services"},
    {label: "Advertising"},
    {label: "Advertising Platforms"},
    {label: "Advertising Services"},
    {label: "Aerospace"},
    {label: "Agriculture and Farming"},
    {label: "Agtech"},
    {label: "Air Transportation"},
    {label: "Airlines and Airports"},
    {label: "Airlines and Aviations"},
    {label: "Alternative Medicine"},
    {label: "Analytics"},
    {label: "Angel Investment"},
    {label: "Animal Feed"},
    {label: "Animation"},
    {label: "Appliances, Electrical, And Electronics Manufacturing"},
    {label: "Apps"},
    {label: "Architecture and Planning"},
    {label: "Art"},
    {label: "Artificial Intelligence"},
    {label: "Artists and Writer"},
    {label: "Augmented Reality"},
    {label: "Automation Machinery Manufacturing"},
    {label: "Autonomous Vehicles"},
    {label: "B2b"},
    {label: "Banking"},
    {label: "Battery"},
    {label: "Beauty"},
    {label: "Big Data"},
    {label: "Biofuel"},
    {label: "Bioinformatics"},
    {label: "Biomass Energy"},
    {label: "Biometrics"},
    {label: "Biopharma"},
    {label: "Biotechnology"},
    {label: "Bitcoin"},
    {label: "Blockchain"},
    {label: "Book And Periodical Publishing"},
    {label: "Brand Marketing"},
    {label: "Broadcast Media Production And Distribution"},
    {label: "Broadcasting"},
    {label: "Building Maintenance"},
    {label: "Building Material"},
    {label: "Business Information Systems"},
    {label: "Business Intelligence"},
    {label: "Call Center"},
    {label: "Chemical Raw Materials Manufacturing"},
    {label: "Child Day Care Services"},
    {label: "Clothing And Apparel"},
    {label: "Cloud Computing"},
    {label: "Cloud Infrastructure"},
    {label: "Cloud Security"},
    {label: "Commerce And Shopping"},
    {label: "Commercial Real Estate"},
    {label: "Community And Lifestyle"},
    {label: "Computer And Network Security"},
    {label: "Computer Games"},
    {label: "Computer Hardware Manufacturing"},
    {label: "Computer Networking Products"},
    {label: "Computer Vision"},
    {label: "Construction"},
    {label: "Consulting"},
    {label: "Consumer Electronics"},
    {label: "Consumer Goods"},
    {label: "Content And Publishing"},
    {label: "Content Creators"},
    {label: "Content Marketing"},
    {label: "Corporate Training"},
    {label: "Crm"},
    {label: "Cryptocurrency"},
    {label: "Cyber Security"},
    {label: "Data And Analytics"},
    {label: "Data Center"},
    {label: "Data Mining"},
    {label: "Data Security Software Products"},
    {label: "Data Visualization"},
    {label: "Dating"},
    {label: "Defense & Space"},
    {label: "Defense And Space Manufacturing"},
    {label: "Delivery"},
    {label: "Design"},
    {label: "Dietary Supplements"},
    {label: "Digital Entertainment"},
    {label: "Digital Marketing"},
    {label: "Digital Media"},
    {label: "Document Management"},
    {label: "E Commerce"},
    {label: "E Learning"},
    {label: "E-Learning Providers"},
    {label: "Ebooks"},
    {label: "Edtech"},
    {label: "Education"},
    {label: "Electric Utilities"},
    {label: "Electric Vehicle"},
    {label: "Electrical Equipment Manufacturing"},
    {label: "Electronics"},
    {label: "Employee Benefits"},
    {label: "Energy"},
    {label: "Energy Efficiency"},
    {label: "Energy Management"},
    {label: "Energy Storage"},
    {label: "Enterprise Software"},
    {label: "Events"},
    {label: "Executive Offices"},
    {label: "Facilities Services"},
    {label: "Facilities Support Services"},
    {label: "Facility Management"},
    {label: "Family"},
    {label: "Fantasy Sports"},
    {label: "Farmers Market"},
    {label: "Farming"},
    {label: "Fashion"},
    {label: "Film"},
    {label: "Film Production"},
    {label: "Finance"},
    {label: "Financial Services"},
    {label: "Fintech"},
    {label: "Fitness"},
    {label: "Fleet Management"},
    {label: "Food And Beverage"},
    {label: "Food Delivery"},
    {label: "Food Processing"},
    {label: "Furniture"},
    {label: "Furniture And Home Furnishings Manufacturing"},
    {label: "Gambling"},
    {label: "Gambling Facilities And Casinos"},
    {label: "Gaming"},
    {label: "Genetics"},
    {label: "Geospatial"},
    {label: "Glass, Ceramics And Concrete Manufacturing"},
    {label: "Government"},
    {label: "Government Administration"},
    {label: "Government And Military"},
    {label: "Govtech"},
    {label: "Gps"},
    {label: "Grocery"},
    {label: "Hardware"},
    {label: "Health And Human Services"},
    {label: "Health Care"},
    {label: "Health Diagnostics"},
    {label: "Healthcare"},
    {label: "Hedge Fund"},
    {label: "Holding Companies"},
    {label: "Home And Garden"},
    {label: "Home Improvement"},
    {label: "Home Services"},
    {label: "Homeland Security"},
    {label: "Hospitality"},
    {label: "Hospitals And Health Care"},
    {label: "Hospitals And Physicians"},
    {label: "Hotel"},
    {label: "Household Appliance Manufacturing"},
    {label: "Human Resources"},
    {label: "Image Recognition"},
    {label: "Industrial"},
    {label: "Industrial Automation"},
    {label: "Industrial Machinery Manufacturing"},
    {label: "Information Services"},
    {label: "Information Technology"},
    {label: "Insurtech"},
    {label: "Intellectual Property"},
    {label: "International Trade And Development"},
    {label: "Internet"},
    {label: "Internet Of Things"},
    {label: "Internet Services"},
    {label: "Ios"},
    {label: "It Infrastructure"},
    {label: "It Management"},
    {label: "It Services And It Consulting"},
    {label: "Jewelry"},
    {label: "Landscaping"},
    {label: "Language Learning"},
    {label: "Laundry And Dry Cleaning"},
    {label: "Law Enforcement"},
    {label: "Law Practice"},
    {label: "Lead Generation"},
    {label: "Legal"},
    {label: "Legal Tech"},
    {label: "Leisure"},
    {label: "Lending"},
    {label: "Lending And Investments"},
    {label: "Life Science"},
    {label: "Lifestyle"},
    {label: "Local Business"},
    {label: "Location Based Services"},
    {label: "Lodging And Conventions"},
    {label: "Logistics"},
    {label: "Luxury Goods & Jewelry"},
    {label: "Machine Learning"},
    {label: "Machinery Manufacturing"},
    {label: "Management Information Systems"},
    {label: "Manufacturing"},
    {label: "Market Research"},
    {label: "Marketplace"},
    {label: "Measuring And Control Instrument Manufacturing"},
    {label: "Mechanical Or Industrial Engineering"},
    {label: "Media And Entertainment"},
    {label: "Medical"},
    {label: "Medical Device"},
    {label: "Medical Equipment Manufacturing"},
    {label: "Messaging"},
    {label: "Messaging And Telecommunications"},
    {label: "Mining Technology"},
    {label: "Mobile"},
    {label: "Motor Vehicle Manufacturing"},
    {label: "Movies, Videos, And Sound"},
    {label: "Museums And Historical Sites"},
    {label: "Music"},
    {label: "Music And Audio"},
    {label: "Nanotechnology Research"},
    {label: "National Security"},
    {label: "Natural Language Processing"},
    {label: "Natural Resources"},
    {label: "Navigation"},
    {label: "Navigation And Mapping"},
    {label: "Network Security"},
    {label: "News"},
    {label: "Non Profit"},
    {label: "Nuclear Electric Power Generation"},
    {label: "Nutrition"},
    {label: "Oil And Gas"},
    {label: "Online Audio And Video Media"},
    {label: "Online Media"},
    {label: "Optometrists"},
    {label: "Other"},
    {label: "Other Banking And Financial Services"},
    {label: "Other Investment Fund"},
    {label: "Other Real Estate"},
    {label: "Outsourcing"},
    {label: "Outsourcing And Offshoring Consulting"},
    {label: "Outsourcing/Offshoring"},
    {label: "Paper And Forest Product Manufacturing"},
    {label: "Payments"},
    {label: "Pet"},
    {label: "Pharmaceutical"},
    {label: "Platforms"},
    {label: "Politics"},
    {label: "Power Grid"},
    {label: "Precious Metals"},
    {label: "Primary Metal Manufacturing"},
    {label: "Printing"},
    {label: "Privacy And Security"},
    {label: "Product Design"},
    {label: "Product Management"},
    {label: "Professional Services"},
    {label: "Property Management"},
    {label: "Public Relations"},
    {label: "Public Safety"},
    {label: "Publishing"},
    {label: "Quality Assurance"},
    {label: "Real Estate"},
    {label: "Real Estate Investment"},
    {label: "Recruiting"},
    {label: "Renewable Energy"},
    {label: "Renewables & Environment"},
    {label: "Rental"},
    {label: "Research Services"},
    {label: "Reservations"},
    {label: "Residential"},
    {label: "Retail"},
    {label: "Retail Health And Personal Care Products"},
    {label: "Retail Luxury Goods And Jewelry"},
    {label: "Retail Motor Vehicles"},
    {label: "Retail Office Equipment"},
    {label: "Retail Technology"},
    {label: "Ride Sharing"},
    {label: "Risk Management"},
    {label: "Robotics"},
    {label: "Saas"},
    {label: "Sales And Marketing"},
    {label: "Science And Engineering"},
    {label: "Security"},
    {label: "Service Industry"},
    {label: "Social Media Management"},
    {label: "Social Media Marketing"},
    {label: "Social Networking Platforms"},
    {label: "Software"},
    {label: "Solar"},
    {label: "Solar Electric Power Generation"},
    {label: "Sporting Goods"},
    {label: "Sporting Goods Manufacturing"},
    {label: "Sports"},
    {label: "Staffing And Recruiting"},
    {label: "Strategic Management Services"},
    {label: "Supply Chain Management"},
    {label: "Sustainability"},
    {label: "Technical And Vocational Training"},
    {label: "Textiles"},
    {label: "Therapeutics"},
    {label: "Think Tanks"},
    {label: "Ticketing"},
    {label: "Tourism"},
    {label: "Tourism And Travel Services"},
    {label: "Training"},
    {label: "Translation And Localization"},
    {label: "Translation Service"},
    {label: "Transportation"},
    {label: "Transportation, Logistics, Supply Chain And Storage"},
    {label: "Travel"},
    {label: "Travel Accommodations"},
    {label: "Travel Agency"},
    {label: "Travel And Tourism"},
    {label: "Truck Transportation"},
    {label: "Urban Transit Services"},
    {label: "Utilities"},
    {label: "Utility System Construction"},
    {label: "Ux Design"},
    {label: "Vacation Rental"},
    {label: "Vehicle Repair And Maintenance"},
    {label: "Venture Capital"},
    {label: "Venture Capital Fund"},
    {label: "Veterinary"},
    {label: "Video"},
    {label: "Video Conferencing"},
    {label: "Video Games"},
    {label: "Virtual Reality"},
    {label: "Voip"},
    {label: "Warehousing"},
    {label: "Waste Collection"},
    {label: "Waste Management"},
    {label: "Water"},
    {label: "Water Purification"},
    {label: "Water Supply And Irrigation Systems"},
    {label: "Web Design"},
    {label: "Web Development"},
    {label: "Web Hosting"},
    {label: "Wedding"},
    {label: "Wellness"},
    {label: "Wellness And Fitness Services"},
    {label: "Wholesale"},
    {label: "Wholesale Building Materials"},
    {label: "Wholesale Machinery"},
    {label: "Wholesale Recyclable Materials"},
    {label: "Wind Energy"},
    {label: "Wine And Spirits"},
    {label: "Wireless"},
    {label: "Women's"}
    ]