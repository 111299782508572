import * as React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import PProgressStepper from "./PProgressStepper";
import PProgressBar from "./PProgressBar";
import PTypography from "./PTypography";

const dialogStyle = {
  display: "flex",
  justifyContent: "center",
  minWidth: "fit-content",
  minHeight: "fit-content",
  maxWidth: "95vw",
  maxHeight: "95vh",
  margin: "auto",
};
const paperStyle = {
  ...dialogStyle,
  borderRadius: "14px",
};
const dialogTitleStyle = {
  px: "32px",
  pt: "32px",
  display: "flex",
  alignItems: "center",
};
const backButtonContainerStyle = { width: "60px" };
const actionButtonsContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "16px",
};

const closeButtonStyle = {
  position: "absolute",
  top: "8px",
  right: "8px",
};

function PModalFloating(props) {
  const {
    children,
    open,
    onClose,
    progressList,
    progressPercent,
    backButton,
    actionButtons,
    headerText,
    inlineHeaderText,
    floatingDimensions,
    padding,
    showCloseButton,
  } = props;
  const dialogContentStyle = {
    // Only apply px and pb if padding is not defined
    ...(padding === undefined && {
      px: '32px', // Default horizontal padding
      pb: '24px', // Default bottom padding
    }),
    padding: padding !== undefined ? padding : '',
  };

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const progressStepperElement = React.useMemo(
    () => progressList && <PProgressStepper list={progressList} />,
    [progressList],
  );

  const progressBarElement = React.useMemo(
    () => progressPercent && <PProgressBar progress={progressPercent} />,
    [progressPercent],
  );

  const backButtonElement = React.useMemo(
    () => backButton && <span style={backButtonContainerStyle}>{backButton}</span>,
    [backButton],
  );

  const actionButtonsElement = React.useMemo(() => {
    if (!actionButtons || !actionButtons?.length) {
      return null;
    }

    return (
      <div style={actionButtonsContainerStyle}>
        {actionButtons.map((button, index) => (
          <React.Fragment key={index}>{button}</React.Fragment>
        ))}
      </div>
    );
  }, [actionButtons]);

  const headerTextElement = React.useMemo(
    () =>
      headerText && (
        <PTypography size="h3" weight="bold">
          {headerText}
        </PTypography>
      ),
    [headerText],
  );

  const closeButtonElement = React.useMemo(() => {
    if (!showCloseButton) {
      return null;
    }
    return (
      <IconButton style={closeButtonStyle} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );
  }, [showCloseButton, handleClose]);

  const headerContainerElement = React.useMemo(() => {
    if (
      !backButtonElement &&
      !actionButtonsElement &&
      !headerTextElement &&
      !progressStepperElement &&
      !progressBarElement
    ) {
      return null;
    }
    // If there are only action buttons on the top row,
    // we want to right-align them so they're in the top right
    let justifyContent = 'space-between';
    if (!inlineHeaderText && !backButtonElement && actionButtonsElement) {
      justifyContent = 'flex-end';
    }

    return (
      <>
        <Grid container>
          {!inlineHeaderText && headerTextElement && (
            <Grid item xs={12} display="flex">
              {headerTextElement}
            </Grid>
          )}
          <Grid item display="flex" xs={12} alignItems="center" justifyContent={justifyContent}>
            {backButtonElement}
            {inlineHeaderText && headerTextElement}
            {actionButtonsElement}
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            {progressStepperElement}
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            {progressBarElement}
          </Grid>
        </Grid>
      </>
    );
  }, [
    backButtonElement,
    actionButtonsElement,
    headerTextElement,
    progressStepperElement,
    progressBarElement,
    inlineHeaderText,
  ]);

  const floatingDialogWrapper = React.useMemo(() => {
    const determineStyle = (rootStyle) => {
      if (!floatingDimensions) {
        return rootStyle;
      }

      return {
        ...rootStyle,
        ...floatingDimensions,
      };
    };
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        style={determineStyle(dialogStyle)}
        PaperProps={{ style: determineStyle(paperStyle) }}
      >
        {closeButtonElement}
        {headerContainerElement && <DialogTitle sx={dialogTitleStyle}>{headerContainerElement}</DialogTitle>}
        <DialogContent sx={dialogContentStyle}>{children}</DialogContent>
      </Dialog>
    );
  }, [open, handleClose, closeButtonElement, headerContainerElement, dialogContentStyle, children, floatingDimensions]);

  return floatingDialogWrapper;
}

PModalFloating.propTypes = {
  /** Dialog open status */
  open: PropTypes.bool,

  /** Close function - back button does not show if this is not provided */
  onClose: PropTypes.func,

  /** Optional progress stepper list - required for progress stepper */
  progressList: PropTypes.array,

  /** Optional progress percent - required for progress bar - either string or number */
  progressPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Optional back button */
  backButton: PropTypes.node,

  /** Optional action buttons */
  actionButtons: PropTypes.array,

  /** Optional header text */
  headerText: PropTypes.string,

  /** Optional, floating modals only, makes the header text inline with the back button and action buttons */
  inlineHeaderText: PropTypes.bool,

  /** Optional floating modal dimensions - eg { width: "50%", height: "50%" } */
  floatingDimensions: PropTypes.object,

  /** Optional padding value */
  padding: PropTypes.string
};

PModalFloating.defaultProps = {
  open: true,
  onClose: null,
  progressList: null,
  progressPercent: null,
  backButton: null,
  actionButtons: [],
  headerText: null,
  inlineHeaderText: false,
  floatingDimensions: null,
  padding: undefined,
};

export default PModalFloating;
