import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import Http from '../../../http/httpClient';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import WhenToPostModal from './whenToPostModal';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../../redux/authSlice';
import Alert from '@mui/material/Alert';

import { ReactComponent as ArrowLeft } from '../../../assets/Icons/Arrow-Left.svg';
import { ReactComponent as Check } from '../../../assets/Icons/Check.svg';
import PModal from '../../../components/library/PModal';
import PBackButton from '../../../components/library/PBackButton';
import PButton from '../../../components/library/PButton';
import PostEdit from '../../CommonScreens/PostEdit';
import ProxyImage from '@/components/ProxyImage';

export default function PostModal({
  onClose,
  open,
  post,
  posts,
  setPosts,
  handleDelete,
  source,
  isDeleting,
  onCloseProfessionals,
  onCloseTopics,
  onCloseSocials,
  onCloseSamplePosts,
  socials,
  newPost,
  handlePostApprove,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState(post?.responseTo?.message ?? post?.message);
  const defaultPhraseProps = { tone: null, hashtags: null, length: null };
  const [currentMenu, setCurrentMenu] = React.useState(null);
  const [phraseProps, setPhraseProps] = React.useState(defaultPhraseProps);
  const [checked, setChecked] = React.useState(true);
  const [isRephrasing, setIsRephrasing] = React.useState(false);
  // const [isLoading, setIsLoading] = React.useState(false);
  const [selectedLength, setSelectedLength] = React.useState(null);
  const [isHashtags, setIsHashtags] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState(isDeleting);
  const [selectedTone, setSelectedTone] = React.useState(null);
  const [isWhenToPostModalOpen, setIsWhenToPostModalOpen] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [currentPost, setCurrentPost] = React.useState(post);
  const [refreshedPosts, setRefreshedPosts] = React.useState([]);
  const [pollInterval, setPollInterval] = React.useState(null);
  const [subjectLine, setSubjectLine] = React.useState(post?.subject);
  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation);

  const getUserPosts = async () => {
    try {
      const endpoint = authSliceSelectedDelegation?.userId
        ? 'userPosts'
        : `userPosts/${authSliceSelectedDelegation?.userId}`;

      const res = await Http.getData(endpoint);

      if (res?.contactPosts?.length > 0) {
        const viablePosts = res.contactPosts.filter(
          (p) => p !== null && !p.approved && !p.published && p.source === socials[0],
        );
        setRefreshedPosts(viablePosts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setRefreshedPosts([]);
      if (pollInterval) {
        clearInterval(pollInterval);
        setPollInterval(null);
      }
    }

    if (open) {
      if (pollInterval) {
        clearInterval(pollInterval);
        setPollInterval(null);
      }

      const interval = setInterval(() => {
        getUserPosts();
      }, 5000);

      setPollInterval(interval);

      setTimeout(() => {
        clearInterval(interval);
        setPollInterval(null);
      }, 120000);
    }
  }, [open]);

  const handleToneChange = (event, newTone) => {
    setSelectedTone(newTone);
  };
  const themeBadge = createTheme({
    palette: {
      primary: grey,
    },
  });

  const handleLengthChange = (event, newLength) => {
    setSelectedLength((prevLength) => (prevLength === newLength ? null : newLength));
  };

  const handleOpenWhenToPostModal = () => {
    setIsWhenToPostModalOpen(true);
  };

  const getCharacterLimit = () => {
    return currentPost?.source === 'twitter' ? 280 : 3000;
  };

  const handleApproveButton = (incomingPost) => {
    const targetPost = incomingPost ?? currentPost;
    const charecterLimit = getCharacterLimit();

    if (targetPost?.message?.length > charecterLimit) {
      setAlertMessage(`Your content is too long. Please shorten it to ${charecterLimit} characters or less.`);
      setShowAlert(true);
      return;
    }

    const origMessage = targetPost?.responseTo?.message ?? targetPost?.message;
    if (origMessage !== message) {
      handlePostApprove(targetPost, message);
    }
    handleOpenWhenToPostModal();
  };

  const handleNextPost = () => {
    if (!refreshedPosts) return;

    const currentPostIndex = refreshedPosts.findIndex((p) => p.message === message);
    const wrappedIndex = (currentPostIndex + 1) % refreshedPosts.length;
    const nextPost = refreshedPosts[wrappedIndex];
    setMessage(nextPost.message);
    setCurrentPost(nextPost);
  };

  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const handleCloseWhenToPostModal = () => {
    setIsWhenToPostModalOpen(false);
  };

  const handleCloseEditPostModal = () => onClose();

  const handleHashtagChange = () => {
    setIsHashtags(!isHashtags);
    handleChanges('hashtags', isHashtags);
  };

  const handleChanges = (name = null, value = null) => {
    if (name === 'hashtags') {
      setPhraseProps({
        ...phraseProps,
        [name]: value !== false ? true : null,
      });
    } else if (value === false) {
      const { hashtags, ...newPhraseProps } = phraseProps;
      setPhraseProps(newPhraseProps);
    } else {
      setPhraseProps({
        ...phraseProps,
        [name]: value,
      });
    }
  };

  const handleTextFieldChange = (event) => {
    setMessage(event.target.value);
  };

  const doItLater = (event) => {
    dispatch(setPage('retention'));
    onClose();

    if (typeof onCloseTopics === 'function') {
      onCloseTopics();
    }

    if (typeof onCloseProfessionals === 'function') {
      onCloseProfessionals();
    }

    if (typeof onCloseSocials === 'function') {
      onCloseSocials();
    }

    window.location.href = '/main';
  };

  const closeWithoutSaving = () => {
    onClose();

    if (typeof onCloseTopics === 'function') {
      onCloseTopics();
    }

    if (typeof onCloseProfessionals === 'function') {
      onCloseProfessionals();
    }

    if (typeof onCloseSocials === 'function') {
      onCloseSocials();
    }

    if (typeof onCloseSamplePosts === 'function') {
      onCloseSamplePosts();
    }
  };

  const resetDefaults = () => {
    setPhraseProps(defaultPhraseProps);
    setCurrentMenu(null);
  };

  const rephrasePost = () => {
    setIsRephrasing(true);
    const params = { post: currentPost, ...phraseProps };
    if (isHashtags) {
      params['hashtags'] = true;
    }
    Http.getData('rephrasePost', params)
      .then((res) => {
        setMessage(res.post);
        resetDefaults();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsRephrasing(false);
      });
  };

  const handleSubjectChange = (e) => {
    setSubjectLine(e.target.value);
  };

  const actionButtons = React.useMemo(() => {
    const buttons = [];
    if (source && source === 'brand') {
      buttons.push(
        <PButton pVariant="primary" onClick={doItLater}>
          I'll do it later
        </PButton>,
      );
    }

    buttons.push(
      <PButton pVariant="outlined" onClick={closeWithoutSaving}>
        Close Without Saving
      </PButton>,
    );
    return buttons;
  }, [source]);

  const handleIndexChange = (index) => {
    setCurrentPost(posts[index]);
  };

  return source === 'ComposePost' ? (
    <PModal
      onClose={onClose}
      open={open}
      backButton={<PBackButton onClick={onClose} iconType="back" />}
      actionButtons={actionButtons}
    >
      <PostEdit
        posts={posts}
        setPosts={setPosts}
        onChangeIndex={handleIndexChange}
        characterLimit={getCharacterLimit()}
        onApprove={handleApproveButton}
        disableInternalApprove
      />
      {isWhenToPostModalOpen && (
        <WhenToPostModal
          open={isWhenToPostModalOpen}
          onClose={handleCloseWhenToPostModal}
          post={currentPost}
          source={source}
          socials={socials}
          onCloseEditPostModal={handleCloseEditPostModal}
          onCloseSocials={onCloseSocials}
          onCloseProfessionals={onCloseProfessionals}
          onCloseTopics={onCloseTopics}
        />
      )}
    </PModal>
  ) : (
    // TODO: Refactor the rest of this out for non-ComposePost flows
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen
      sx={{
        px: {
          xs: 0,
          md: 0,
        },
        m: 0,
        maxWidth: '100vw',
      }}
    >
      <DialogTitle sx={{ m: 0, p: 0 }}>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ px: 2, pt: 2 }}>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <ArrowLeft fill={theme.palette.primary.black} />
            </IconButton>
          </Grid>
          <Grid item>{showAlert && <Alert severity="error">{alertMessage}</Alert>}</Grid>
          <Grid item>
            {source && source == 'brand' ? (
              <Button
                onClick={doItLater}
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.lightBlue,
                  'color': theme.palette.primary.blue,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'mr': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.lightBlue,
                    color: theme.palette.primary.blue,
                  },
                }}
              >
                I'll do it later
              </Button>
            ) : (
              ''
            )}
            <Button
              onClick={closeWithoutSaving}
              sx={{
                'maxWidth': '200px',
                'backgroundColor': theme.palette.primary.white,
                'color': theme.palette.primary.blue,
                'border': `1px solid ${theme.palette.primary.blue}`,
                'textTransform': 'none',
                'width': '167px',
                'py': 1,
                'px': 2,
                'mr': 1,
                'whiteSpace': 'nowrap',
                'overflow': 'hidden',
                'textOverflow': 'ellipsis',
                'borderRadius': 2,
                'boxShadow': '0px 4px 8px 0px #2C64E31F',
                '&:hover': {
                  backgroundColor: theme.palette.primary.lightBlue,
                  color: theme.palette.primary.blue,
                },
              }}
            >
              Close Without Saving
            </Button>

            <Button
              onClick={handleApproveButton}
              sx={{
                'maxWidth': '200px',
                'backgroundColor': theme.palette.primary.blue,
                'color': theme.palette.primary.white,
                'textTransform': 'none',
                'width': '167px',
                'py': 1,
                'px': 2,
                'whiteSpace': 'nowrap',
                'overflow': 'hidden',
                'textOverflow': 'ellipsis',
                'borderRadius': 2,
                'boxShadow': '0px 4px 8px 0px #2C64E31F',
                '&:hover': {
                  backgroundColor: theme.palette.primary.lightBlue,
                  color: theme.palette.primary.blue,
                },
              }}
            >
              Approve
            </Button>
            {isWhenToPostModalOpen && (
              <WhenToPostModal
                open={isWhenToPostModalOpen}
                onClose={handleCloseWhenToPostModal}
                post={{ ...currentPost, message: message }}
                source={source}
                socials={socials}
                onCloseEditPostModal={handleCloseEditPostModal}
                onCloseSocials={onCloseSocials}
                onCloseProfessionals={onCloseProfessionals}
                onCloseTopics={onCloseTopics}
              />
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={4}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 700,
                color: theme.palette.primary.black,
                mb: 1,
              }}
            >
              Adjust the style
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: theme.palette.primary.grey,
              }}
            >
              Tone
            </Typography>

            <ToggleButtonGroup
              value={selectedTone}
              exclusive
              onChange={handleToneChange}
              aria-label="tone"
              orientation="vertical"
              fullWidth
            >
              {['Controversial', 'Formal', 'Fun'].map((option, index) => (
                <ToggleButton
                  key={index}
                  selected={selectedTone === option}
                  value={option}
                  aria-label={`${option}`}
                  sx={{
                    'textTransform': 'none',
                    'border': 'none',
                    'justifyContent': 'flex-start',
                    '&.Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Check
                    fill={selectedTone === option ? theme.palette.primary.black : theme.palette.primary.white}
                    style={{ marginRight: 3 }}
                  />
                  {option}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: theme.palette.primary.grey,
                marginTop: '24px',
              }}
            >
              Length
            </Typography>

            <ToggleButtonGroup
              value={selectedLength}
              exclusive
              onChange={handleLengthChange}
              aria-label="length"
              orientation="vertical"
              fullWidth
            >
              {['Longer', 'Shorter'].map((option, index) => (
                <ToggleButton
                  key={index}
                  onClick={() => handleChanges('length', option)}
                  selected={selectedLength === option}
                  value={option}
                  aria-label={`${option}`}
                  sx={{
                    'textTransform': 'none',
                    'border': 'none',
                    'justifyContent': 'flex-start',
                    '&.Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'transparent',
                    },
                    'padding': '5px',
                  }}
                >
                  <Check
                    fill={selectedLength === option ? theme.palette.primary.black : theme.palette.primary.white}
                    style={{ marginRight: 3 }}
                  />
                  {option}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: theme.palette.primary.grey,
                pt: 2,
                marginTop: '15px',
              }}
            >
              Hashtags
            </Typography>

            <ToggleButtonGroup
              exclusive
              value={isHashtags}
              onChange={handleHashtagChange}
              aria-label="hashtags"
              orientation="vertical"
              fullWidth
            >
              <ToggleButton
                value={true}
                onClick={() => handleChanges('hashtags', true)}
                aria-label="hashtags"
                sx={{
                  'textTransform': 'none',
                  'border': 'none',
                  'justifyContent': 'flex-start',
                  '&.Mui-selected': {
                    backgroundColor: 'transparent',
                  },
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: 'transparent',
                  },
                  'marginBottom': '20px',
                }}
              >
                <Check
                  fill={isHashtags ? theme.palette.primary.black : theme.palette.primary.white}
                  style={{ marginRight: 3 }}
                />
                Include Hashtags
              </ToggleButton>
            </ToggleButtonGroup>

            <Stack direction="column" spacing={4} sx={{ mt: 1 }}>
              <Button
                disabled={!selectedTone && !selectedLength}
                onClick={rephrasePost}
                sx={{
                  'fontWeight': 500,
                  'fontSize': '14px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': 'max-content',
                  'py': 1,
                  'px': 2,
                  'my': 3,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.lightBlue,
                    color: theme.palette.primary.blue,
                  },
                  '&:disabled': {
                    backgroundColor: `${theme.palette.primary.blue}AA`,
                    color: `${theme.palette.primary.white}AA`,
                  },
                }}
              >
                Rephrase Post
              </Button>
              {!newPost && (
                <Button
                  sx={{
                    'fontWeight': 500,
                    'fontSize': '14px',
                    'backgroundColor': theme.palette.primary.lightBlue,
                    'color': theme.palette.primary.blue,
                    'textTransform': 'none',
                    'width': 'max-content',
                    'py': 1,
                    'px': 2,
                    'my': 3,
                    'whiteSpace': 'nowrap',
                    'overflow': 'hidden',
                    'textOverflow': 'ellipsis',
                    'borderRadius': 2,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.lightBlue,
                      color: theme.palette.primary.blue,
                    },
                  }}
                  disabled={isDeleting}
                  onClick={() => {
                    if (!isDeleted) {
                      setIsDeleted(true);
                      handleDelete();
                    }
                  }}
                >
                  Delete
                </Button>
              )}
              <Stack direction="column" sx={{ marginTop: '0px' }}></Stack>
            </Stack>
          </Grid>

          <Grid item xs={8}>
            {socials && socials.includes('twitter') && (
              <Chip
                label="X (Twitter)"
                sx={{
                  backgroundColor: theme.palette.primary.black,
                  color: 'white',
                  mx: 1,
                }}
              />
            )}
            {socials && socials.includes('linkedin') && (
              <Chip
                label="LinkedIn"
                sx={{
                  backgroundColor: theme.palette.primary.blue,
                  color: 'white',
                  mx: 1,
                }}
              />
            )}
            <Stack
              direction="row"
              spacing={8}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '90%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '32px',
                  fontWeight: 700,
                  pb: 3,
                  lineHeight: 'normal',
                }}
              >
                {source == 'brand' ? "You're ready to go—just approve your first post." : 'Compose your post. '}
              </Typography>

              {refreshedPosts?.length > 1 && (
                <Button
                  onClick={handleNextPost}
                  sx={{
                    'backgroundColor': theme.palette.primary.white,
                    'color': theme.palette.primary.blue,
                    'border': `1px solid ${theme.palette.primary.blue}`,
                    'textTransform': 'none',
                    'width': 'max-content',
                    'height': '42px',
                    'py': 1,
                    'px': 2,
                    'my': 3,
                    'whiteSpace': 'nowrap',
                    'overflow': 'hidden',
                    'textOverflow': 'ellipsis',
                    'borderRadius': 2,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.lightBlue,
                      color: theme.palette.primary.blue,
                    },
                    '&:disabled': {
                      backgroundColor: `${theme.palette.primary.blue}AA`,
                      color: `${theme.palette.primary.white}AA`,
                    },
                  }}
                >
                  Next Post
                </Button>
              )}
            </Stack>

            {!post?.subject && (
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: theme.palette.primary.black,
                }}
              >
                Your Post
                <span
                  style={{
                    color: theme.palette.primary.orange,
                  }}
                >
                  *
                </span>
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: theme.palette.primary.grey,
              }}
            >
              Scheduled: {dayjs(currentPost?.scheduledAt).format('DD MMM YYYY')}
            </Typography>
            {post?.subject && (
              <Typography
                sx={{
                  mt: 2,
                  fontSize: '16px',
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                }}
              >
                Subject line
                <span style={{ color: theme.palette.primary.orange }}>*</span>
              </Typography>
            )}
            {post?.subject && (
              <Paper
                component="form"
                sx={{
                  width: '90%',
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: 0,
                  mt: 1,
                  mb: 3,
                  p: 1,
                  border: `1px solid #E0E0E0`,
                  borderRadius: 4,
                }}
              >
                <InputBase
                  value={post?.subject}
                  onChange={handleSubjectChange}
                  // disabled={isLoading}
                  // readOnly={readOnly}
                  type="text"
                  // placeholder='[AI draft goes here]'
                  onFocus={(e) => (e.target.placeholder = '')}
                  sx={{
                    flex: 1,
                    ml: 1,
                    fontSize: '18px',
                    fontWeight: '500',
                    width: '100%',
                    alignSelf: 'start',
                  }}
                />
              </Paper>
            )}

            <Paper
              component="form"
              sx={{
                width: '90%',
                display: 'flex',
                boxShadow: 0,
                mt: 1,
                mb: 3,
                p: 2,
                border: `1px solid #E0E0E0`,
                borderRadius: 4,
              }}
            >
              <Stack direction="column" sx={{ width: '100%' }}>
                <Box
                  sx={{
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 100px)',
                  }}
                >
                  <InputBase
                    type="text"
                    value={message ?? post?.message}
                    placeholder="[AI draft goes here]"
                    onChange={handleTextFieldChange}
                    onFocus={(e) => (e.target.placeholder = '')}
                    multiline
                    sx={{
                      ml: 1,
                      fontSize: '18px',
                      fontWeight: '500',
                      width: '-webkit-fill-available',
                      alignSelf: 'start',
                    }}
                  />
                </Box>
                {currentPost?.articleUrl && (
                  <ThemeProvider theme={themeBadge}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        mt: 2,
                        ml: 1,
                        mb: 1,
                        textDecoration: 'underline',
                        fontWeight: 500,
                        color: theme.palette.primary.black,
                      }}
                    >
                      <a href={currentPost?.articleUrl}>Read more in the linked news article.🔗📰</a>
                    </Typography>
                    <Box>
                      <Badge badgeContent={'X'} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        <ProxyImage
                          sx={{
                            height: 200,
                            width: 250,
                            ml: 1,
                            borderRadius: 3,
                            objectFit: 'cover',
                          }}
                          alt="imagepost"
                          src={currentPost?.articleImageUrl}
                        />
                      </Badge>
                    </Box>
                  </ThemeProvider>
                )}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
