/**
 * useOutsideClick
 *
 * A React hook that triggers a callback function when a click event occurs outside of a specified element.
 *
 * @param {Object} ref - A React ref object pointing to the element to detect outside clicks for.
 * @param {Function} onOutsideClick - The callback function to be executed when an outside click is detected.
 * @param {boolean} [includeChildren=false] - A boolean flag indicating whether clicks on the children of the
 *                                            element should be considered as outside clicks.
 *
 * @example
 * // Basic usage: Detect clicks outside a specific element
 * import React, { useRef } from 'react';
 * import useOutsideClick from './useOutsideClick';
 *
 * const MyComponent = () => {
 *   const ref = useRef();
 *
 *   const handleOutsideClick = () => {
 *     console.log('Clicked outside the element');
 *   };
 *
 *   useOutsideClick(ref, handleOutsideClick);
 *
 *   return (
 *     <div ref={ref}>
 *       Click outside this element to trigger the callback.
 *     </div>
 *   );
 * };
 *
 * @example
 * // Usage with includeChildren set to true: Detect clicks outside the element and its children
 * import React, { useRef } from 'react';
 * import useOutsideClick from './useOutsideClick';
 *
 * const MyComponent = () => {
 *   const ref = useRef();
 *
 *   const handleOutsideClick = () => {
 *     console.log('Clicked outside the element and its children');
 *   };
 *
 *   useOutsideClick(ref, handleOutsideClick, true);
 *
 *   return (
 *     <div ref={ref}>
 *       <div>Child element</div>
 *       Click outside this element and its children to trigger the callback.
 *     </div>
 *   );
 * };
 *
 * @example
 * // Usage with includeChildren set to false: Detect clicks outside the element but not its children
 * import React, { useRef } from 'react';
 * import useOutsideClick from './useOutsideClick';
 *
 * const MyComponent = () => {
 *   const ref = useRef();
 *
 *   const handleOutsideClick = () => {
 *     console.log('Clicked outside the element but not its children');
 *   };
 *
 *   useOutsideClick(ref, handleOutsideClick, false);
 *
 *   return (
 *     <div ref={ref}>
 *       <div>Child element</div>
 *       Click outside this element but not its children to trigger the callback.
 *     </div>
 *   );
 * };
 */
import { useEffect, useCallback } from 'react';

const useOutsideClick = (ref, onOutsideClick, includeChildren = false) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (!ref.current || ref.current === event.target) return;

      if (includeChildren || !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    },
    [ref, onOutsideClick, includeChildren],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};

export default useOutsideClick;
