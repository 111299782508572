import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'

import Http from '../../../http/httpClient'
import { Providers } from './../../../constants/constant'

export default function TwitterContent () {
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState('')
  const [users, setUsers] = React.useState([])

  const refreshContent = (event) => {
    event.preventDefault()
    if (selectedOption.length > 0) {
      setIsLoading(true)
      Http.postData('refreshContent', { user_id: selectedOption, provider: Providers.TWITTER })
        .then((res) => {
          // handle if anything required
          setSelectedOption('')
          alert('Started to fetch the data!')
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  React.useEffect(() => {
    const fetchAvailableUsers = async () => {
      Http.getData('users/list')
        .then((res) => {
          const resUsers = []
          res.users.map((item) => {
            resUsers.push({ id: item.id, name: item.name, email: item.email })
          })
          setUsers(resUsers)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    fetchAvailableUsers()
  }, [])

  return (
    <>
      <Box display='flex' alignItems='center' gap={2} flexDirection='row'>
        <Box display='flex' alignItems='center' gap={2} sx={{ marginLeft: 'none' }}>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ minWidth: 200 }}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name} - {user.email}
              </MenuItem>
            ))}
          </Select>
          <Button
            sx={{
              mt: 3,
              ml: 1,
              px: 3,
              my: 2,
              backgroundColor: '#3588db',
              color: '#fff',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textTransform: 'none',
              fontFamily: 'Poppins'
            }}
            onClick={refreshContent}
          >
            {isLoading
              ? (
                <Box
                  sx={{
                    position: 'relative',
                    width: '52px',
                    height: '34px',
                    display: 'flex',
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress color='inherit' />
                </Box>
                )
              : (
                  'Refresh'
                )}
          </Button>
        </Box>
      </Box>
    </>
  )
}
