import Http from '../http/httpClient';

/**
 * This service provides methods to allow ADMINS fetching user invites.
 * @returns {Promise<Object>} The user invites.
 */
const getAllUserInvites = async () => {
  try {
    const response = await Http.getData('invites/admin/list');
    if (response && response.status) {
      return response.invites;
    }
    throw new Error('Unexpected response from invites/admin/list');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * This service provides methods to allow users to create invites.
 * @param {Object} invite - The invite to create.
 * @returns {Promise<Object>} The created invite.
 * const { inviter, invitee, companyId } = req.body.data;
 */
// ADMIN RELATED INVITE MANAGEMENT
const createInvite = async (invite) => {
  const { inviter = null, invitee, companyId = null } = invite;
  try {
    const response = await Http.postData('invites/admin/create', {
      inviter,
      invitee,
      companyId,
    });
    if (response && response.invite) {
      return response.invite;
    } else {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// Useage:
// createInvite('test@test.com');
const createUserInvite = async (invitee) => {
  try {
    const response = await Http.postData('invites/user/create', {
      invitee,
    });
    if (response && response.invite) {
      return response.invite;
    } else {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteInvite = async (invite) => {
  try {
    const response = await Http.postData(`invites/delete`, {invite});
    if (response && response.status) {
      return response.invite;
    }
    throw new Error('Unexpected response from invites/delete');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// NOT USED YET EXPORT WHEN READY
const getInvitedUser = async (email) => {
  try {
    const response = await Http.getData(`invites/${email}`);
    if (response && response.status) {
      return response.invite;
    }
    throw new Error('Unexpected response from invites/get');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {
  getAllUserInvites,
  createInvite,
  createUserInvite,
  deleteInvite,
};
