import React, { useState } from 'react';
import { Collapse, Box, IconButton, Divider } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import PTypography from './PTypography';

export default function PCollapsableContainer({
  title,
  titleColor,
  children,
  backgroundColor,
  action,
  initialExpanded = false,
}) {
  const [expanded, setExpanded] = useState(initialExpanded);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: 2,
        overflow: 'hidden',
        my: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <PTypography
          color={titleColor}
          sx={{ flexGrow: 1, cursor: 'pointer', fontSize: '1.1rem', fontWeight: 'medium' }}
          onClick={handleToggle}
        >
          {title}
        </PTypography>
        {action}
        <IconButton onClick={handleToggle} aria-label="Toggle">
          {expanded ? <Remove /> : <Add />}
        </IconButton>
      </Box>
      {expanded && <Divider />}
      <Collapse in={expanded} timeout="auto">
        <Box sx={{ paddingX: 2, paddingY: 2 }}>{children}</Box>
      </Collapse>
    </Box>
  );
}
