import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import GoalModal from '../../../setup/simpleSetupComponents/components/goalModal';
import { updateStrategiesWithGifts } from '../../../setup/simpleSetupComponents/utils/strategyUtils';
import PTypography from '@Library/PTypography';

const headerStyle = {
  mt: '24px',
};

const subheaderStyle = {
  mt: '8px',
  mb: '32px',
};

export default function ChooseGoal({
  sequenceData, setSequenceName, templates, defaultStrategies,
}) {
  const [strategies, setStrategies] = React.useState(defaultStrategies);
  const [strategiesLoading, setStrategiesLoading] = React.useState(true);
  const defaultTemplates = templates;

  const handleGoalClick = (goalIndex) => {
    setStrategies((prevStrategies) => prevStrategies.map((goal, index) => ({
      ...goal,
      active: index === goalIndex ? !goal.active : false,
    })));
  };

  // When the page loads fetch all the strategies
  React.useEffect(() => {
    const updatedStrategies = defaultStrategies?.map((strategy) => ({
      ...strategy,
      active: strategy.id === sequenceData?.sequenceName,
    }));

    setStrategies(updatedStrategies);
    setStrategiesLoading(false);
  }, []);

  React.useEffect(() => {
    if (templates.length === 1) {
      const activeGoalIndex = strategies.findIndex((goal) => goal.active);
      if (activeGoalIndex !== -1) {
        handleGoalClick(activeGoalIndex);
      }
    }
  }, [templates, strategies]);

  React.useEffect(() => {
    if (defaultTemplates.length > 0) {
      const updatedStrategies = updateStrategiesWithGifts(
        strategies,
        defaultTemplates,
      );
      setStrategies(updatedStrategies);
    }
  }, [defaultTemplates, strategiesLoading]);

  React.useEffect(() => {
    const activeGoal = strategies.find((goal) => goal.active === true);
    setSequenceName(activeGoal?.id);
  }, [strategies]);

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={8} >
        <Box sx={headerStyle}>
          <PTypography size="h3" weight="bold">
            Pick Your Assistant
          </PTypography>
        </Box>

        <Box sx={subheaderStyle}>
          <PTypography size="body2" weight="regular">
            On-demand assistance, freeing you from full-time hires.
          </PTypography>
        </Box>

        <Grid container spacing="12px">
          {strategies.map((goal, index) => {
            if (goal.enabled) {
              return <GoalModal key={goal.id} goal={goal} index={index} handleGoalClick={handleGoalClick} />;
            }
            return null;
          })}
        </Grid>
      </Grid>
      <Grid item xs={0} md={2} />
    </Grid>
  );
}
