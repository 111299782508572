import React from 'react'
import './authSuccess.css'

const AuthSuccess = () => {

  React.useEffect(() => {
    setTimeout(() => {
        window.close()
      }, 500)
  }, [])

  return (
    <div className="centered-container">
      <div className="tick-container">
        <div className="tick-icon">
          <div className="tick-line tick-line1"></div>
          <div className="tick-line tick-line2"></div>
        </div>
      </div>
    </div>
  )
}

export default AuthSuccess
