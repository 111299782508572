import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Slide, Grow, Zoom, Fade } from "@mui/material";
import { ReactComponent as Delete } from "../../assets/Icons/Delete.svg";
import { useTheme } from "@mui/material/styles";
import { TRANSITION_TIME } from "../../contexts/SnackbarContext";

const getVariantStyles = (theme, variant) => {
  switch (variant) {
    case 'success':
      return {
        backgroundColor: theme.palette.primaryCL.Green100,
        color: theme.palette.primaryCL.Black200,
      };
    case 'error':
    case 'destructive':
      return {
        backgroundColor: theme.palette.primaryCL.Red100,
        color: theme.palette.primaryCL.White100,
      };
    case 'primary':
    case 'info':
    case 'neutral':
    default:
      return {
        backgroundColor: theme.palette.primaryCL.Black200,
        color: theme.palette.primaryCL.White100,
      };
  }
};

const easingFn = {
  enter: "cubic-bezier(0, 0.6, 0, 0.6)",
  exit: "cubic-bezier(1, 0.6, 1, 0.6)",
};

function SlideTransition(props) {
  return <Slide {...props} direction="up" timeout={TRANSITION_TIME} easing={easingFn} />;
}

function GrowTransition(props) {
  return <Grow {...props} timeout={TRANSITION_TIME} easing={easingFn} />;
}

function ZoomTransition(props) {
  return <Zoom {...props} timeout={TRANSITION_TIME} easing={easingFn} />;
}

function FadeTransition(props) {
  return <Fade {...props} timeout={TRANSITION_TIME} easing={easingFn} />;
}

function PSnackbar({
  open = false,
  message = "",
  onClose = null,
  pVariant = "neutral",
  duration = 5000,
  transitionType = "grow",
  hideSeverityIcon = false,
  hideAction = false,
  customAction = null,
}) {
  const theme = useTheme();
  const variantStyles = getVariantStyles(theme, pVariant);

  const handleClose = (event, reason) => {
    // We can extend this in the future to support clickaway if necessary
    if (reason === "clickaway") {
      return;
    }

    onClose && onClose();
  };

  // map pVariant onto MUI Alert severity
  const severityMap = {
    success: "success",
    destructive: "error",
    neutral: "info",
  };

  const transitionMap = {
    slide: SlideTransition,
    grow: GrowTransition,
    zoom: ZoomTransition,
    fade: FadeTransition,
  };

  const DeleteIcon = (
    <div
      style={{
        alignItems: "center",
        alignSelf: "center",
        // marginLeft: "28px",
        // marginRight: "12px",
        fill: variantStyles.color,
        cursor: "pointer",
      }}
    >
      <Delete
        onClick={handleClose}
        style={{
          fill: variantStyles.color,
          width: "12px",
          height: "12px",
        }}
      />
    </div>
  );

  const getAction = () => {
    if (hideAction) {
      return null;
    } else if (customAction) {
      return customAction;
    } else {
      return DeleteIcon;
    }
  };

  const StyledAlert = (
    <Alert
      severity={severityMap[pVariant]}
      variant="filled"
      action={getAction()}
      icon={hideSeverityIcon ? false : undefined}
      style={{
        backgroundColor: variantStyles.backgroundColor,
        color: variantStyles.color,
        // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
        borderRadius: "14px",
      }}

      sx={{
        '& .MuiAlert-icon': {
          display: 'flex',
          alignItems: "center",
          padding: "0px",
          marginRight: '8px',
        },

        // the path has a scale factor of 0.835886659, so to get a 12px icon, we need to divide 12 by 0.835886659.
        // =>           12 / 0.835886659 = 14.3759920932
        '& .MuiAlert-icon > svg': {
          fill: variantStyles.color,
          width: '14.3759920932px',
          height: '14.3759920932px',
          padding: '0px',
        },

        "& .MuiAlert-action": {
          marginLeft: '24px',
          // We get 16px of whitespace from the parent's padding,
          // so for 20px spacing we use 4px of margin
          marginRight: '4px',
        },
      }}
    >
      {message}
    </Alert>
  );

  const Contents = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      TransitionComponent={transitionMap[transitionType]}
      sx={{
        // Override the default padding for the action icon
        "& .MuiAlert-action": {
          padding: "0px",
          margin: '0px,'
        },

      }}
    >
      {StyledAlert}
    </Snackbar>
  );

  return <>{Contents}</>;
}

PSnackbar.propTypes = {
  // Determines whether the Snackbar is open
  open: PropTypes.bool.isRequired,

  // The message to be displayed in the snackbar.
  message: PropTypes.string.isRequired,

  // Callback function triggered when the snackbar is closed.
  onClose: PropTypes.func,

  // The variant of the snackbar (success, destructive, neutral, or default).
  pVariant: PropTypes.oneOf(["success", "destructive", "neutral", "default"]),

  // The duration in milliseconds for which the snackbar will be displayed.
  duration: PropTypes.number,

  // The type of transition for the Snackbar. Supports all MUI transitions.
  transitionType: PropTypes.oneOf([
    "slide",
    "grow",
    "zoom",
    "fade",
  ]),

  // Whether to hide the severity icon on the left side of the Snackbar
  hideSeverityIcon: PropTypes.bool,

  // Whether to hide the `X` / close button
  hideAction: PropTypes.bool,

  // Custom element to be displayed in the Snackbar instead of the `X` icon.
  customAction: PropTypes.element,
};

PSnackbar.defaultProps = {
  open: false,
  message: "",
  onClose: null,
  pVariant: "neutral",
  duration: 5000,
  transitionType: "grow",
  hideSeverityIcon: false,
  hideAction: false,
  customAction: null,
};

export default PSnackbar;
