import * as React from 'react';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

import ConnectListItem from '../../setup/simpleSetupComponents/connectListItem';

import { ReactComponent as XLogo } from '../../../assets/logos/XLogoCircle.svg';
import { ReactComponent as LinkedInLogo } from '../../../assets/logos/LinkedInLogoCircle.svg';

import { Providers } from '../../../constants/constant'
import { SetCookie, GetCookie } from '../../../helper/cookieManager'

import { useDispatch } from 'react-redux';
import { setUser } from '../../../redux/authSlice';

export default function Social(props) {
  const theme = useTheme()
  const dispatch = useDispatch();
  const socialProviderList = [
    {
      title: 'Connect your Linkedin assistant',
      text: 'Connect to LinkedIn',
      subtitle: 'Connecting with anyone online and automatically follow, post, message, and comment on Linkedin.',
      logo: <LinkedInLogo />,
      provider: Providers.LINKED_IN,
      connected: null,
      recommended: true,
      chromeExtension: true,
    },
    {
      title: 'Connect your X assistant',
      text: 'Connect your Twitter Account',
      subtitle: 'Automate tweeting, following, liking, commenting, and sending DMs.',
      logo: <XLogo />,
      provider: Providers.TWITTER,
      connected: null,
      recommended: false,
      chromeExtension: false,
    }
  ];
  const [providerList, setProviderList] = React.useState(socialProviderList);

  async function getConnectedState (provider) {
    const luser = GetCookie('user')
    if(luser) {
      const p = luser.providers.find(acc => acc !== null && acc.provider === provider && acc.active) || []
      if (p && p.profileId) {
        return { connected: true,
          profileId: p.profileId,
          primary: p.primary,
          profileImage: (p && p.imageUrl ) ? p.imageUrl : luser.imageUrl
        }
      }
    }
    return { connected: false, profileId: null, primary: null, profileImage: null }
  }

  const fetchData = async () => {
    const updatedList = await Promise.all(providerList.map(async (item) => {
      const profile = await getConnectedState(item.provider);
      return {
        ...item,
        connected: profile.connected,
        primary: profile.primary,
        profileId: profile.profileId,
        profileImage: profile.profileImage
      };
    }));

    setProviderList(updatedList);
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    const bc = new BroadcastChannel("settingsSocialAuthChannel");
    bc.addEventListener('message', (event) => {
      if (event.data.status === 200) {
        fetchData()
      }
    })

    return () => {
      bc.removeEventListener('message', () => {});
      bc.close();
    };
  }, [])

  return (
    <>
      <Typography
        sx={{
          fontSize: '32px',
          fontWeight: 700,
          color: theme.palette.primary.black,
          pt: 5,
          pl: { xs: 5 },
          mt: { xs: 4 },
          lineHeight: 'normal',
        }}
      >
        Connect your social media accounts
      </Typography>
      <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 4, pl: { xs: 5 } }}>
        This allows you to engage with your clients.
      </Typography>

      <Grid container spacing={1.5} sx={{ px: 5 }}>
        {providerList.map((provider) => (
          <Grid key={`${provider.title}${provider.connected}${new Date().toString()}`} item xs={12} lg={8} xl={8}>
            <ConnectListItem
              currProvider={provider}
              source={'settingsSocial'}
              setProviderList={setProviderList}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
