import React, { useState, useEffect } from 'react';
import {
  Box,
  MenuItem,
  Select,
  CircularProgress,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';

import Http from '../../../http/httpClient';
import ReactJson from '@microlink/react-json-view';

const JsonTableCell = ({ data, collapsed = false, displayObjectSize = false, collapseStringsAfterLength = false }) => (
  <TableCell>
    <ReactJson
      src={data}
      theme="pop"
      enableClipboard={false}
      iconStyle="triangle"
      collapseStringsAfterLength={collapseStringsAfterLength}
      displayObjectSize={displayObjectSize}
      displayDataTypes={false}
      name={false}
      collapsed={collapsed}
    />
  </TableCell>
);

export default function UserRecommendations() {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState('');
  const [userRecommendations, setUserRecommendations] = useState(null);
  const [recommendedContacts, setRecommendedContacts] = useState(null);
  const [preferenceLoading, setPreferenceLoading] = useState(false);

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      Http.getData('users/list')
        .then((res) => {
          const resUsers = [];
          res.users.map((item) => {
            resUsers.push({ id: item._id.toString(), name: item.name, email: item.email });
          });
          setUsers(resUsers);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setUsersLoading(false);
        });
    };

    fetchAvailableUsers();
  }, []);

  const fetchUserRecommendations = async () => {
    Http.getData(`admin/userRecommendations`, { userId: selectedUser })
      .then((res) => {
        if (res && res.result !== undefined) {
          setUserRecommendations(res.result);
        }
      })
      .catch((error) => {
        console.error('Error fetching user preference:', error);
        setUserRecommendations(null);
      })
      .finally(() => {
        setPreferenceLoading(false);
      });
  };

  React.useEffect(() => {
    setPreferenceLoading(true);
    if (selectedUser?.length > 0) {
      fetchUserRecommendations();
    } else {
      setPreferenceLoading(false);
    }
  }, [selectedUser]);

  const handleUserChange = (e) => {
    setSelectedUser((prev) => e.target.value);
  };

  // const regenerateUserRecommendations = async () => {
  //   setPreferenceLoading(true);
  //   await Http.postData('admin/generateUserRecommendations', { userId: selectedUser });
  //   await new Promise((resolve) => setTimeout(resolve, 10000)); // wait 10 seconds
  //   fetchUserRecommendations();
  // };

  const refreshUserRecommendations = async () => {
    setPreferenceLoading(true);
    fetchUserRecommendations();
  };

  // const exportRecommendedContacts = () => {
  //   if (!recommendedContacts) return;

  //   const dataStr = JSON.stringify(recommendedContacts, null, 2);
  //   const blob = new Blob([dataStr], { type: 'application/json' });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = `recommended_contacts_${selectedUser}.json`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return (
    <>
      <h3>User Recommendations</h3>
      <Box display="flex" flexDirection="column" gap={2} alignItems="flex-start" sx={{ width: '100%' }}>
        <Box sx={{ flexGrow: 1, mx: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {usersLoading ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '52px',
                  height: '34px',
                  display: 'flex',
                  borderRadius: 16,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <>
                <Select value={selectedUser} onChange={handleUserChange} sx={{ minWidth: 200 }}>
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name} - {user.email}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  variant="outlined"
                  sx={{
                    'marginLeft': 2,
                    'color': 'white',
                    'backgroundColor': '#3983F7',
                    'textTransform': 'none',
                    'display': 'flex',
                    'alignItems': 'center',
                    'fontSize': '15px',
                    'borderRadius': 10,
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                  onClick={refreshUserRecommendations}
                >
                  Refresh
                </Button>
                {/* <Button
                  variant="outlined"
                  sx={{
                    'marginLeft': 2,
                    'color': 'white',
                    'backgroundColor': '#3983F7',
                    'textTransform': 'none',
                    'display': 'flex',
                    'alignItems': 'center',
                    'fontSize': '15px',
                    'borderRadius': 10,
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                  onClick={regenerateUserRecommendations}
                >
                  Regenerate
                </Button> */}
                {/* <Button
                  variant="outlined"
                  sx={{
                    'marginLeft': 2,
                    'color': 'white',
                    'backgroundColor': '#3983F7',
                    'textTransform': 'none',
                    'display': 'flex',
                    'alignItems': 'center',
                    'fontSize': '15px',
                    'borderRadius': 10,
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                  onClick={exportRecommendedContacts}
                >
                  Export Recommended Contacts
                </Button> */}
              </>
            )}
          </Box>
          {preferenceLoading ? (
            <Box
              sx={{
                position: 'relative',
                width: '52px',
                height: '34px',
                display: 'flex',
                borderRadius: 16,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            userRecommendations && (
              <Box sx={{ my: 2, mr: 3, p: 2 }}>
                <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
                  <Table sx={{ minWidth: 650 }} stickyHeader aria-label="preference table">
                    <TableBody>
                      <TableRow key={'User Recommendations'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left">
                          <JsonTableCell data={userRecommendations} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
          )}

          {/* <Button
            variant="outlined"
            sx={{
              'marginLeft': 2,
              'color': 'white',
              'backgroundColor': '#3983F7',
              'textTransform': 'none',
              'display': 'flex',
              'alignItems': 'center',
              'fontSize': '15px',
              'borderRadius': 10,
              '&:hover': {
                backgroundColor: 'black',
                color: 'white',
              },
            }}
            onClick={refreshUserRecommendations}
          >
            Add Recommendation
          </Button> */}
        </Box>
      </Box>
    </>
  );
}
