import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'

import Http from '../../../http/httpClient'
import CustomModal from '../../../pages/login/components/confirmModal'
import { Regexes } from '../../../constants/constant'
import featureFlagService from '../../../services/featureFlagService';

export default function AddCompanyModal({ open, onClose, loading, company, featureFlags }) {
  const [companyId, setCompanyId] = React.useState(company?._id ?? '')
  const [name, setName] = React.useState('')
  const [emailDomains, setEmailDomains] = React.useState('')
  const [websiteUrl, setWebsiteUrl] = React.useState('')
  const [photoUrl, setPhotoUrl] = React.useState('')
  const [connectionsCount, setConnectionsCount] = React.useState(0)
  const [users, setUsers] = React.useState([])
  const [admins, setAdmins] = React.useState([])
  const [selectedUser, setSelectedUser] = React.useState([])
  const [originalSelectedUser, setOriginalSelectedUser] = React.useState([])
  const [alertText, setAlertText] = React.useState('')
  const [validateModalOpen, setValidateModalOpen] = React.useState(false)
  const [isValidName, setIsValidName] = React.useState(true)
  const [isValidEmailDomains, setIsValidEmailDomains] = React.useState(true)
  const [isValidWebsiteUrl, setIsValidWebsiteUrl] = React.useState(true)
  const [selectedFeatureFlags, setSelectedFeatureFlags] = React.useState([])

  React.useEffect(() => {
    const fetchNotAssociatedUsers = async () => {
      Http.getData('companies/userList', { companyId } )
        .then((res) => {
          setUsers(res.users)

          if (companyId) {
            const selUsers = res.users.filter(user => user.companyId === companyId)
            setSelectedUser(selUsers)
            setOriginalSelectedUser(selUsers)
            setConnectionsCount(selUsers.length)
            setAdmins(selUsers.filter(user => company.admins.includes(user._id)))
          }
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {

        })
    }

    const fetchFeatureFlags = async () => {
      if(companyId) {
        const companyFeatureFlags = await featureFlagService.getFeatureFlagsForCompany(companyId);
        // remap the feature flags to the format expected by the select component with an index
        const companyFeatureFlagsMap = companyFeatureFlags.map((featureFlag) => {
          return {
            name: featureFlag.name,
          };
        });

        // Something weird is going on here. The featureFlags prop is an array of objects
        // the object as a whole get compared to the companyFeatureFlagsMap object
        // so if the isFeatureEnabled differs, ie company flag say false and the global flag says true
        // duplicates get added I need a UI expert to help me figure out how to fix this
        // This is a hack to fix the issue
        const unionFeatureFlags = featureFlags.filter(featureFlag => 
          companyFeatureFlagsMap.some(companyFeatureFlag => companyFeatureFlag.name === featureFlag.name)
        );
        setSelectedFeatureFlags(unionFeatureFlags);
      }
    }

    const resetCompanyModal = async () => {
      setName(company?.name ?? '')
      setEmailDomains(company?.emailDomains.join(', ') ?? '')
      setWebsiteUrl(company?.websiteUrl ?? '')
      setPhotoUrl(company?.photoUrl ?? '')
      setConnectionsCount(company?.connectionsCount ?? 0)
      setIsValidName(true)
      setIsValidEmailDomains(true)
      setIsValidWebsiteUrl(true)
      setSelectedUser([])
      setSelectedFeatureFlags([])
    }

    if (open) {
      fetchNotAssociatedUsers()
      fetchFeatureFlags()
    } else {
      resetCompanyModal()
    }
  }, [open])

  React.useEffect(() => {
    setConnectionsCount(selectedUser.length)
  }, [selectedUser])

  const handleSelectedChange = (event) => {
    const newValue = event.target.value
    setSelectedUser(newValue)
  }

  const handleFeatureChange = (event) => {
    console.log("handleFeatureChange ",event.target.value);
    setSelectedFeatureFlags(event.target.value);
  };

  const handleAdminChange = (event) => {
    const newValue = event.target.value
    setAdmins(newValue)
  }

  const handleSubmit = (event) => {
    if (name.length === 0) {
      let alertPrompt = `Company name shouldn't be empty.`
      setAlertText(alertPrompt)
      setValidateModalOpen(true)
      setIsValidName(false)
      return
    }

    const newlyAddedUsers = selectedUser.filter((user) => !originalSelectedUser.some((oriSel) => oriSel.id === user.id))
    const removedUsers = originalSelectedUser.filter((selUser) => !selectedUser.some((user) => user.id === selUser.id))

    const details = {
      companyId,
      name,
      emailDomains,
      websiteUrl,
      photoUrl,
      connectionsCount,
      addedUsers: newlyAddedUsers,
      removedUsers,
      admins,
      featureFlags: selectedFeatureFlags,
      goals: company?.goals,
      targetAudience: company?.targetAudience,
      emailPreference: company?.emailPreference
    }

    onClose(details)
  }

  const handleModalAction = (action) => {
    setValidateModalOpen(false)
    setAlertText('')
  }

  return (
    <>
      {validateModalOpen && <CustomModal clickAction={handleModalAction} alertText={alertText} />}
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth="true">
        <DialogTitle
          sx={{
            fontWeight: 800,
            fontSize: 18,
            letterSpacing: '-0.02em',
            py: 3,
          }}
        >
          Add a Company
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                fullWidth
                value={name}
                onChange={(e) => {
                  let nameVal = e.target.value;
                  setName(nameVal);
                  setIsValidName(nameVal.length > 0);
                }}
                sx={{ my: 2 }}
                error={!isValidName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Domains"
                fullWidth
                value={emailDomains}
                onChange={(e) => {
                  let emailDomainsVal = e.target.value;
                  setEmailDomains(emailDomainsVal);
                  setIsValidEmailDomains(
                    emailDomainsVal.length === 0 ? true : Regexes.EMAIL_DOMAINS.test(emailDomainsVal),
                  );
                }}
                sx={{ marginBottom: '16px' }}
                error={!isValidEmailDomains}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Website URL"
                fullWidth
                value={websiteUrl}
                onChange={(e) => {
                  let websiteVal = e.target.value;
                  setWebsiteUrl(websiteVal);
                  setIsValidWebsiteUrl(websiteVal.length === 0 ? true : Regexes.WEBSITE_URL.test(websiteVal));
                }}
                sx={{ marginBottom: '16px' }}
                error={!isValidWebsiteUrl}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Photo URL"
                fullWidth
                value={photoUrl}
                onChange={(e) => setPhotoUrl(e.target.value)}
                sx={{ marginBottom: '16px' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Connections Count"
                fullWidth
                value={connectionsCount}
                disabled
                sx={{ marginBottom: '16px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                <InputLabel id="users-label">Users</InputLabel>
                <Select
                  labelId="users-label"
                  label="Users"
                  multiple
                  value={selectedUser}
                  onChange={handleSelectedChange}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.name ? value.name : value.id} />
                      ))}
                    </div>
                  )}
                >
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user}>
                      <Checkbox color="secondary" checked={selectedUser.some((selected) => selected.id === user.id)} />
                      <ListItemText primary={user.name + ' - ' + user.email + ' - ' + user.id} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                <InputLabel id="admins-label">Admins</InputLabel>
                <Select
                  labelId="admins-label"
                  label="Admins"
                  multiple
                  value={admins}
                  onChange={handleAdminChange}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.name ? value.name : value.id} />
                      ))}
                    </div>
                  )}
                >
                  {selectedUser.map((user) => (
                    <MenuItem key={user.id} value={user}>
                      <Checkbox color="secondary" checked={admins.some((selected) => selected.id === user.id)} />
                      <ListItemText primary={user.name + ' - ' + user.email + ' - ' + user.id} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                <InputLabel id="featureFlag-label">Feature Flags</InputLabel>
                <Select
                  labelId="featureFlag-label"
                  label="Feature Flags"
                  multiple
                  value={selectedFeatureFlags}
                  onChange={handleFeatureChange}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value.name} label={value.name} />
                      ))}
                    </div>
                  )}
                >
                  {featureFlags.map((featureFlag) => (
                    <MenuItem key={featureFlag.name} value={featureFlag}>
                      <Checkbox
                        color="secondary"
                        checked={selectedFeatureFlags.some((selected) => selected.name === featureFlag.name)}
                      />
                      <ListItemText primary={featureFlag.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose(null)}
            sx={{
              mx: 1,
              my: 2,
              backgroundColor: '#EEEEEE',
              color: '#09092E',
              fontSize: '1.2rem',
              textTransform: 'none',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            sx={{
              mx: 1,
              my: 2,
              backgroundColor: '#3983F7',
              color: 'white',
              fontSize: '1.2rem',
              textTransform: 'none',
            }}
            disabled={loading || !(isValidEmailDomains && isValidName && isValidWebsiteUrl)}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            {loading ? (
              <React.Fragment>
                <span style={{ color: 'white' }}>Saving...</span>
              </React.Fragment>
            ) : (
              'Add'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}