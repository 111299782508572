import { useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import { handleAuthentication } from "@/helper/authUtility";
import { SetCookie } from '@/helper/cookieManager';
import { LoginLayout } from "./loginLayout";

import GoogleIcon from "@/assets/logos/Google-Icon.png";
import MicrosoftIcon from "@/assets/logos/MicrosoftLogo.png";
import { ThemeProvider } from "@emotion/react";
import { useTheme, Snackbar, Alert } from "@mui/material";
import PToolTip from "@Library/PToolTip";

const isFromChromeExtension = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const fromParam = urlParams.get('from');
  if (!fromParam) {
    return false;
  }
  return fromParam === 'extension';
};

const doAfterChromeExtensionLogin = (luser) => {
  SetCookie('chromeExtEmail', luser.email);
  window.close();
};

export default function Login() {
  const theme = useTheme();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const buttonStyle = {
    'mt': 3,
    'py': 2,
    'backgroundColor': theme.palette.primary.white,
    'color': 'rgba(0, 0, 0, 0.54)',
    'fontSize': '14px',
    'fontWeight': 500,
    'textTransform': 'none',
    'borderRadius': 1,
    'width': '100%',
    'boxShadow': 'none',
    'justifyContent': 'flex-start',
    'paddingLeft': '16px',
    'boxShadow': '0px 1px 1px 0px rgba(0, 0, 0, 0.168)',
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
    },
    '&:active': {
      backgroundColor: '#EEEEEE',
    },
  };
  const google = () => {
    if (!termsAccepted) {
      setShowSnackbar(true);
      return;
    }
    const isFromChromeExt = isFromChromeExtension();
    handleAuthentication('/api/auth/google', isFromChromeExt ? doAfterChromeExtensionLogin : null);
  };

  const microsoft = () => {
    if (!termsAccepted) {
      setShowSnackbar(true);
      return;
    }
    const isFromChromeExt = isFromChromeExtension();
    handleAuthentication('/api/auth/microsoft', isFromChromeExt ? doAfterChromeExtensionLogin : null);
  };

  const handleCheckboxChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  return (
    <LoginLayout termsAccepted={termsAccepted} handleCheckboxChange={handleCheckboxChange}>
      {!termsAccepted ? (
        <PToolTip
          title="You need to accept the Terms of Service and Postilize Privacy Policy"
          pVariant="black"
          placement="bottom"
        >
          <span>
            <Button variant="contained" component={Link} onClick={google} disabled={!termsAccepted} sx={buttonStyle}>
              <img src={GoogleIcon} width="18px" style={{ marginRight: '16px' }} />
              Sign in with Google
            </Button>
          </span>
        </PToolTip>
      ) : (
        <Button variant="contained" component={Link} onClick={google} disabled={!termsAccepted} sx={buttonStyle}>
          <img src={GoogleIcon} width="18px" style={{ marginRight: '16px' }} />
          Sign in with Google
        </Button>
      )}

      {!termsAccepted ? (
        <PToolTip
          title="You need to accept the Terms of Service and Postilize Privacy Policy"
          pVariant="black"
          placement="bottom"
        >
          <span>
            <Button variant="contained" component={Link} onClick={microsoft} disabled={!termsAccepted} sx={buttonStyle}>
              <img src={MicrosoftIcon} width="18px" style={{ marginRight: '16px' }} />
              Sign in with Microsoft
            </Button>
          </span>
        </PToolTip>
      ) : (
        <Button variant="contained" component={Link} onClick={microsoft} disabled={!termsAccepted} sx={buttonStyle}>
          <img src={MicrosoftIcon} width="18px" style={{ marginRight: '16px' }} />
          Sign in with Microsoft
        </Button>
      )}

      <Snackbar open={showSnackbar} autoHideDuration={4000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
          Please accept the Terms of Service and Postilize Privacy Policy before signing in.
        </Alert>
      </Snackbar>
    </LoginLayout>
  );
}
