import * as React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'

export default function ChooseList(props) {
  const theme = useTheme()
  const { listItem, setTargetListItem, sequenceData, fromEdit, fromListAddContacts, targetAudience, audienceType, existingList } = props;
  const [selectedGoal, setSelectedGoal] = React.useState('')
  const [listMethod, setListMethod] = React.useState(audienceType)
  const [availableList, setAvailableList] = React.useState(existingList)
  const [activeItem, setActiveItem] = React.useState(null)
  const listRef = React.useRef(null);

  const scrollToCurrentLists = () => {
    listRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    const matchingList = existingList?.find((list) => list._id === listItem?.lisId)
    setActiveItem((prev) => matchingList)
    setSelectedGoal(sequenceData?.sequenceName)

    setListMethod((prevListMethod) =>
      prevListMethod.map((method) => ({
        ...method,
        active: method.id === listItem?.providers?.[0]?.providerType
      }))
    )
  }, [])

  const handleOnSelect = (list) => {
    if (activeItem?._id === list._id) {
      setActiveItem(null);
      setTargetListItem(null);
    } else {
      setActiveItem(list);
      setTargetListItem({ lisId: list._id });
    }

    // Deselect all list methods
    const updatedListMethod = listMethod.map((method) => ({
      ...method,
      active: false,
    }));
    setListMethod(updatedListMethod);
  };

  const handleListClick = (listType) => {
    // Deselect any active item and reset the target list item
    setActiveItem(null);
    setTargetListItem(null);

    // Update list methods' active states
    const updatedListMethod = listMethod.map((method) => ({
      ...method,
      active: method.id === listType.id,
    }));
    setListMethod(updatedListMethod);
};

  React.useEffect(() => {
    const activeList = listMethod.find((list) => list.active === true)
    if (!activeList) { return }

    const listInfo = {
      providers: [{
        providerType: activeList.id,
        provider: activeList.id,
        isExpanded: false,
        enrichmentCompleted: false,
      }],
      provider: activeList.id,
      name: selectedGoal
    }

    setTargetListItem(listInfo)
  }, [listMethod])

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={3} />
        <Grid item xs={6}>
          {fromEdit ? (
            <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 5, pb: 3, lineHeight: 'normal' }}>
              {`Add contacts to ${sequenceData?.name}`}
            </Typography>
          ) : (
            <>
              <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 5, lineHeight: 'normal' }}>
                Select your target audience.
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5 }}>
                Choose from an{' '}
                <Link sx={{ cursor: 'pointer', color: theme.palette.primary.blue }} onClick={scrollToCurrentLists}>
                  existing list of people
                </Link>
              </Typography>
              <Grid item xs={3} />
              <Typography sx={{ fontWeight: 700, fontSize: '16px', color: theme.palette.primary.black, mb: 2 }}>
                Get started
              </Typography>
            </>
          )}
          <Grid container spacing={2}>
            {listMethod.map((method, index) => (
              <Grid item key={method.id} xs={12} sm={6} md={6}>
                <div
                  style={{
                    border: `1px solid ${method.active ? theme.palette.primary.lightBlue : '#E0E0E0'}`,
                    borderRadius: '10px',
                    padding: '24px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%', // Ensure all items are the same height
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '36px',
                    }}
                  >
                    <img src={method.icon} alt={method.title} style={{ height: '24px', width: '24px' }} />
                    {method.popular && (
                      <Typography
                        variant="caption"
                        sx={{
                          color: theme.palette.primary.orange,
                          my: 1,
                          backgroundColor: theme.palette.primary.lightOrange,
                          textTransform: 'uppercase',
                          px: '8px',
                          borderRadius: '16px',
                          fontWeight: 500,
                        }}
                      >
                        Popular
                      </Typography>
                    )}
                  </Box>
                  <Typography sx={{ fontWeight: 700, fontSize: '14px', color: theme.palette.primary.black, mt: 2 }}>
                    {method.title}
                  </Typography>
                  <Typography sx={{ fontWeight: 500, fontSize: '14px', color: theme.palette.primary.grey }}>
                    {method.subtitle}
                  </Typography>
                  <Button
                    sx={{
                      mt: '16px',
                      backgroundColor: method.active ? theme.palette.primary.blue : theme.palette.primary.lightBlue,
                      color: method.active ? theme.palette.primary.white : theme.palette.primary.blue,
                      fontSize: '14px',
                      fontWeight: 500,
                      textTransform: 'none',
                      width: '100%',
                      borderRadius: '6px',
                      boxShadow: 0,
                    }}
                    onClick={() => handleListClick(method)}
                  >
                    {method.active ? 'Selected' : 'Select'}
                  </Button>
                </div>
              </Grid>
            ))}
           {(targetAudience ? targetAudience.existing : true) && !fromListAddContacts && availableList.length !== 0 && (
                <Grid item xs={13} ref={listRef}>
                  <Typography sx={{ fontWeight: 700, fontSize: '16px', color: theme.palette.primary.black, mt: 2 }}>
                    Your current lists
                  </Typography>
                  {availableList.map((list) => (
                    <Container
                      onClick={() => handleOnSelect(list)}
                      sx={{

                        py: 3.5,
                        my: 2,
                        cursor: 'pointer',
                        mx: 0,
                        borderRadius: 3,
                        border: activeItem?._id === list._id ? '2px solid #3983F7' : '2px solid #E5E5E5',
                        width: '100%',
                        height: '69px',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                        }
                      }}>
                      <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                        <Typography sx={{ fontSize: '16px', fontWeight: 500, alignItems: 'center' }}>{list.name}</Typography>
                      </Grid>
                    </Container>
                  ))}
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid item xs={3} />
      </Grid>
      {/*modalToOpen && renderModal()*/}
    </>
  );
}
