import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import Http from '../../../http/httpClient';
import ActivationItem from './ActivationItem';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function UserActivations() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [newUserEmail, setNewUserEmail] = React.useState('');

  React.useEffect(() => {
    const fetchAllUsers = async () => {
      setIsLoading(true);
      try {
        const res = await Http.getData('users/list');
        setUsers(res.users);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllUsers();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (e) => setNewUserEmail(e.target.value);

  const handleCreateUser = async () => {
    try {
      const newUser = { email: newUserEmail };
      const res = await Http.postData('users/precreate', newUser);
      setUsers([...users, res.user]);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen} sx={{ mb: 2 }}>
        Create New User
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={modalStyle}>
          <h2 id="modal-title">Create New User</h2>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            value={newUserEmail}
            onChange={handleChange}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleCreateUser}>
              Create
            </Button>
          </Box>
        </Box>
      </Modal>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            'overflowX': 'auto',
            'overflowY': 'auto',
            'maxHeight': 'calc(85vh - 100px)',
            'maxWidth': '100%',
            '@media (max-height: 600px)': {
              maxHeight: 'calc(85vh - 50px)',
            },
          }}
        >
          <Table sx={{ maxWidth: '100%' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>E-mail</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
                <StyledTableCell>Notifications</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((u) => (
                <ActivationItem key={u.id} user={u} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
