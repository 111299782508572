import { PModal, PTypography, PButton } from '@Library';
import { Stack, Box } from '@mui/material';

const PYesNoDialog = ({ open, onYes, onNo, yesText, noText, message, detailedMessage }) => {
  if (!open) return null;

  return (
    <PModal floating ultraWideContent floatingDimensions={{ height: 'fit-content', width: '412px' }} overflow="hidden">
      {/* This is our root stack */}
      <Stack spacing="20px" maxHeight="fit-content">
        {/* This is our text stack */}
        <Stack direction="column" spacing="12px">
          <PTypography size="body2" weight="bold">
            {message}
          </PTypography>
          <PTypography size="body1" weight="regular">
            {detailedMessage}
          </PTypography>
        </Stack>
        {/* End text stack */}

        {/* This is our button box */}
        <Box display="flex" justifyContent="space-between">
          <PButton pVariant="secondary" onClick={onNo}>
            {noText}
          </PButton>
          <PButton pVariant="black" onClick={onYes}>
            {yesText}
          </PButton>
        </Box>
        {/* End button box */}
      </Stack>
      {/* End root stack */}
    </PModal>
  );
};

export default PYesNoDialog;
