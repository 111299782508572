import * as React from 'react';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';

import FullScreenModal from '../../../../components/FullScreenModal';
import BrandTopics from './brandTopics';

import Http from '../../../../http/httpClient';

export default function BrandModal(props) {
    const theme = useTheme()
    const { onClose, open, progress, source } = props;
    const [inputValue, setInputValue] = React.useState('');
    const [aiPrompt, setAIPrompt] = React.useState('');
    const [topicsOpen, setTopicsOpen] = React.useState(false)
    const [pro, setPro] = React.useState('')
    const [alertMessage, setAlertMessage] = React.useState(null)

    const handleDescChange = event => {
        const description = event.target.value
        setInputValue(description)
      }

      const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      };

      const closeTopics = async () => {
        setTopicsOpen(false)
    }

    const handleContinue = async () => {
      try {
        if (pro.length === 0) {
          setAlertMessage('Please enter a description');
          return;
        }
        console.log(pro);
        setAIPrompt(pro);
        const updateSuccess = await updateProfessionals();
        if (updateSuccess) {
          setTopicsOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    

    const updateProfessionals = async () => {
      try {
        const response = await Http.postData('setup/setProfessionals',  pro );
        if (response && response.status) {
          return true; 
        }
        return false;
      } catch (error) {
        console.log(error);
        return false;
      }
    };

    const content = (
        <>
            <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 1, lineHeight: 'normal' }}>
                Tell us about the professionals you want to connect with and their goals.
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5 }}>
                This will allow you to create better content.
            </Typography>

            <Typography sx={{ fontSize: '16px', fontWeight: 700, color: theme.palette.primary.black}}>
                Describe who you'd like to target and their goals.
            </Typography>
            <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, mt: 1, mb: 3, p: 1, border: `1px solid #E0E0E0`, borderRadius: 4, }}
              >
                <InputBase
                  type="text"
                  multiline
                  rows={4}
                  placeholder="E.g., Start up executives in biotech located in San Francisco who need to raise funds for their next venture."
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "E.g., Start up executives in biotech located in San Francisco who need to raise funds for their next venture.")}
                  sx={{ ml: 1, flex: 1 }}
                  value={props.inputValue}
                  onChange={(event) => {
                    setPro(event.target.value)
                  }}
                  onKeyDown={handleKeyDown}
                />
              </Paper>
        </>
    );

    return (
      <>
      {aiPrompt && (
        <BrandTopics open={topicsOpen} onClose={closeTopics} aiPrompt={aiPrompt} onCloseProfessionals={onClose} source={source}/>
      )}
        
        <FullScreenModal continueText="Continue" onClose={onClose} open={open} progress={50} content={content} handleContinue={handleContinue} alertMessage={alertMessage} />
        </>
    );
}