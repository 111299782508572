import React from 'react';
import { Button, Paper, IconButton, InputBase } from '@mui/material';
import { alpha } from '@mui/system';
import { useTheme } from '@mui/material';

import { ReactComponent as Delete } from '../../../assets/Icons/Delete.svg';
import { ReactComponent as Search } from '../../../assets/Icons/Search.svg';

const FileUploadComponent = ({
  selectedFile,
  apiEndpoint,
  handleSaveFile,
  buttonLabel,
  setFile,
  onUploadSuccess,
  onUploadError,
  handleDeleteFile,
}) => {
  const fileInputRef = React.useRef();
  const theme = useTheme();
  const [uploadSuccessful, setUploadSuccessful] = React.useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setUploadSuccessful(false);
  };

  // const handleDeleteFile = () => {
  //   setFile(null);
  // };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      console.warn('No file selected');
      return;
    }

    try {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(selectedFile);

      fileReader.onload = async (event) => {
        const fileContent = event.target.result;
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('originalname', selectedFile.name);
        formData.append('path', selectedFile.path);
        formData.append('fileContent', fileContent);

        const response = await fetch(apiEndpoint, {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          onUploadSuccess(data.fileName);
          setUploadSuccessful(true);
        } else {
          console.error('HTTP-Error: ' + response.status);
          onUploadError(response.status);
        }
      };
    } catch (error) {
      console.error(error);
      onUploadError(error);
    }
  };

  const buttonStyle = {
    backgroundColor: theme.palette.primary.blue,
    color: theme.palette.primary.white,
    textTransform: 'none',
    width: '98%',
    py: 1,
    px: 2,
    mx: 2,
    mt: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRadius: 4,
    boxShadow: '0px 4px 8px 0px #2C64E31F',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.blue, 0.8),
    },
  };

  return (
    <>
      {!selectedFile && (
        <Button onClick={() => fileInputRef.current.click()} sx={buttonStyle}>
          {buttonLabel}
        </Button>
      )}
      {selectedFile && (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            boxShadow: 0,
            mt: 4,
            mb: 3,
            p: 1,
            border: `1px solid #E0E0E0`,
            borderRadius: 4,
          }}
        >
          <IconButton sx={{ p: '10px' }}>
            <Search fill="grey" />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1, fontWeight: 500 }}
            value={selectedFile.name}
          />
          <IconButton aria-label="delete" onClick={handleDeleteFile}>
            <Delete fill="grey" />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            disabled={uploadSuccessful}
            onClick={() => handleFileUpload()}
          >
            {uploadSuccessful ? 'Uploaded' : 'Upload'}
          </Button>
        </Paper>
      )}

      <input
        ref={fileInputRef}
        type="file"
        id="fileInput"
        accept=".csv"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default FileUploadComponent;
