import { $createTextNode, $createParagraphNode, $getRoot, $insertNodes } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';

export function base64ToBlob(base64, contentType = '', sliceSize = 512) {
  // Decode the base64 string
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export function base64ImageToBlob(base64) {
  const contentType = base64.split(';')[0].split(':')[1];
  return base64ToBlob(base64, contentType);
}

export function blobImageToFile(blob, fileName) {
  return new File([blob], fileName, { type: blob.type });
}

export function base64ImageToFile(base64, fileName) {
  const blob = base64ImageToBlob(base64);
  return blobImageToFile(blob, fileName);
}

export function isLexicalJSON(content) {
  if (!content) {
    return false;
  }

  const contentNoWhitespace = content.replace(/\s/g, '');
  return contentNoWhitespace.startsWith('{"root":');
}

export function isLexicalHTML(content) {
  if (!content) {
    return false;
  }

  const contentNoWhitespace = content.trim();
  return contentNoWhitespace.startsWith('<p class="editor-paragraph"');
}


export const initializeFromJSON = (editor, initialValue) => {
  const parsedEditorState = editor.parseEditorState(initialValue);
  editor.setEditorState(parsedEditorState);
};

export const initializeFromHTML = (editor, initialValue) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(initialValue, 'text/html');
  editor.update(() => {
    const root = $getRoot();
    root.clear();
    const nodes = $generateNodesFromDOM(editor, dom);
    root.select();
    $insertNodes(nodes);
  });
};

export const initializeFromPlainText = (editor, initialValue) => {
  const paragraphs = initialValue.split('\n\n');
  editor.update(() => {
    const root = $getRoot();
    root.clear();
    const nodes = [];
    paragraphs.forEach((paragraphText) => {
      const paragraph = $createParagraphNode();
      const textNode = $createTextNode(paragraphText);
      paragraph.append(textNode);
      nodes.push(paragraph);
    });
    $insertNodes(nodes);
  });
};
