import React, { useState } from "react";
import { useTheme } from "@mui/material";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Add from "@mui/icons-material/Add";
import PropTypes from "prop-types";

const PTopicAdder = ({ onAdd, placeholder = "Add topic", ...keyHandlers }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [isIconHovered, setIsIconHovered] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAdd();
      event.target.blur();
    }
  };

  const handleAdd = () => {
    if (inputValue.trim() !== "") {
      onAdd(inputValue);
      setInputValue(""); // Reset the input field after adding the topic
    }
  };

  const chipStyles = {
    alignItems: "center",
    height: "42px",
    borderRadius: "14px",
    padding: "0px 12px",
    border: "1px solid",
    borderColor: theme.palette.primaryCL.Black70,
    backgroundColor: theme.palette.primaryCL.White100,
    transition: "0.2s ease",
  };

  const inputStyle = {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "130%",
    color: inputValue
      ? theme.palette.primaryCL.Black200
      : theme.palette.primaryCL.Black100, // Dynamic color based on input
    "&::placeholder": {
      color: theme.palette.primaryCL.Black70,
    },
  };

  const iconStyle = {
    fill: isIconHovered
      ? theme.palette.primaryCL.Black200
      : theme.palette.primaryCL.Black100,
  };

  return (
    <Chip
      label={
        <TextField
          value={inputValue}
          variant="standard"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          InputProps={{
            disableUnderline: true,
            style: inputStyle,
          }}
          {...keyHandlers}
        />
      }
      style={chipStyles}
      deleteIcon={
        <Add
          style={iconStyle}
          onMouseEnter={() => setIsIconHovered(true)}
          onMouseLeave={() => setIsIconHovered(false)}
        />
      }
      onDelete={handleAdd}
      sx={{ "& > span": { p: 0, m: 0 } }}
    />
  );
};

PTopicAdder.propTypes = {
  onAdd: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default PTopicAdder;
