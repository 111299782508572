import * as React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import IconButton from '@mui/material/IconButton'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'

import { Providers } from '../../constants/constant'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '5px',
  p: 4,
};

export default function ContactDetailsModal({ open, onClose, contact, contactLoading }) {
  const handleOnClose = (event) => {
    onClose(event)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleOnClose}
        aria-labelledby="contact-details-modal-title"
      >
        <Box sx={style}>
          <Typography id="contact-details-modal-title" variant="h6" component="h2" style={{display: 'inline-block'}}>
            {contact.name}
          </Typography>
          <Box sx={{ display: 'inline-block', pl: 1 }}>
            {!!contact.twitter_url && (
              <IconButton>
                <TwitterIcon sx={{ color: '#4999E9' }} />
              </IconButton>
            )}
            {!!contact.linkedin_url && (
              <IconButton>
                <LinkedInIcon sx={{ color: '#3275B2' }} />
              </IconButton>
            )}
          </Box>
          <Divider />
          {contactLoading ? (
            <Box sx={{ display: 'flex', mt: 3, mb: 3, width: '100%', minHeight: 250, alignItems: "center" }}>
              <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                <LinearProgress color="primary" />
                <LinearProgress color="inherit" />
                <LinearProgress color="primary" />
              </Stack>
            </Box>
          ) : (
            <Box sx={{ mt: 3, mb: 3, width: '100%' }}>
              <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <Card raised sx={{ maxWidth: 280, margin: "0", padding: "0.1em", borderRadius: 2 }}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={contact.photo_url}
                      alt={contact.name}
                      title={contact.name}
                      sx={{ objectFit: "fill" }}
                    />
                  </Card>

                  <Card raised sx={{ maxWidth: 280, margin: "0", padding: "0.1em" }}>
                    <CardContent>
                      <Typography>
                        {contact.company ?? ''}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {contact.title ?? ''}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <>
                      <Grid item xs={4}><Typography color="text.secondary">First name</Typography></Grid>
                      <Grid item xs={8}><Typography>{contact.first_name}</Typography></Grid>
                    </>
                    <>
                      <Grid item xs={4}><Typography color="text.secondary">Last name</Typography></Grid>
                      <Grid item xs={8}><Typography>{contact.last_name}</Typography></Grid>
                    </>
                    <>
                      <Grid item xs={4}><Typography color="text.secondary">Source</Typography></Grid>
                      <Grid item xs={8}><Typography variant='body1'>{contact.source}</Typography></Grid>
                    </>
                    <>
                      <Grid item xs={4}><Typography color="text.secondary">Type</Typography></Grid>
                      <Grid item xs={8}><Typography variant='body1'>{contact.type}</Typography></Grid>
                    </>
                    {(contact.source === Providers.TWITTER) &&
                      <>
                        <Grid item xs={4}><Typography color="text.secondary">Subscribers</Typography></Grid>
                        <Grid item xs={8}><Typography variant='body1'>{contact.subscribers_count}</Typography></Grid>
                      </>
                    }
                    <>
                      <Grid item xs={4}><Typography color="text.secondary">Focus based</Typography></Grid>
                      <Grid item xs={8}><Typography variant='body1'>{contact.isFocus ? "Automatic" : "Manual"}</Typography></Grid>
                    </>
                    {contact.lastTweetPullTime && (
                      <>
                        <Grid item xs={4}><Typography color="text.secondary">Recent tweets pulled</Typography></Grid>
                        <Grid item xs={8}><Typography>{contact.last_tweet_pull_time}</Typography></Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          <Divider />
          <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'right' }}>
            <Button sx={{ mt: 1 }}
                    variant="contained"
                    onClick={(e) => { handleOnClose(e) }}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
