import React, { useState } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip'
import { Divider, useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'

import Http from '../../http/httpClient'
import { setComposePostDetails } from '../../redux/authSlice'

export default function PostType(props) {
  const theme = useTheme();
  const postDetails = useSelector((state) => state.auth.composePostDetails)
  const [postUrl, setPostUrl] = useState(postDetails.postUrl ?? null)
  const [selectedTrendingTopic, setSelectedTrendingTopic] = useState(postDetails.postTopic ?? null);
  const [trendingTopics, setTrendingTopics] = useState([]);

  const dispatch = useDispatch()

  React.useEffect(() => {
    const fetchTopics = async () => {
      Http.getData('getTopics')
        .then((res) => {
          const { topics, trendings } = res
          setTrendingTopics(trendings)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    fetchTopics()
    }, [])

    React.useEffect(() => {
      if ((postUrl && postUrl.length > 0) || (selectedTrendingTopic && selectedTrendingTopic.length > 0)) {
        if ((postDetails.postUrl !== postUrl) || (postDetails.postTopic !== selectedTrendingTopic)) {
          dispatch(setComposePostDetails({ postUrl: postUrl, postTopic: selectedTrendingTopic}))
        } else {
          dispatch(setComposePostDetails({ ...postDetails, postUrl: postUrl, postTopic: selectedTrendingTopic}))
        }
      } else {
        dispatch(setComposePostDetails({}))
      }
    }, [postUrl, selectedTrendingTopic])

    const handleTopicSelection = (topic) => {
      setSelectedTrendingTopic(selectedTrendingTopic === topic ? null : topic)
    };

    const handleUrlChange = (e) => {
      setPostUrl(e.target.value)
    }

    return (
      <div>
        <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black, pl: 1, pt: 2 }}>
          Create your own
        </Typography>

        <Paper
          component="form"
          sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, my: 2, width: '30vw' }}
        >
          <InputBase
            disabled = {selectedTrendingTopic!=null && selectedTrendingTopic.length > 0}
            placeholder='Paste the URL of an article here'
            sx={{ ml: 1, flex: 1, }}
            onChange={handleUrlChange}
          />
        </Paper>
        <Box display="flex" alignItems="center" my={1}>
          <Divider flexItem style={{ flexGrow: 1, height: '0.5px', marginRight: '8px', alignSelf: 'center' }} />
          <Typography>Or</Typography>
          <Divider flexItem style={{ flexGrow: 1, height: '0.5px', marginLeft: '8px', alignSelf: 'center' }} />
        </Box>

        <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black, pl: 1, pt: 2 }}>
          Choose from a trending topic
        </Typography>

        <Container sx={{ py: 1, my: 1, borderRadius: 3, width: '30vw', height: '100%' }}>
          {trendingTopics.map((topic) => (
            <Chip
              key={topic}
              label={topic}
              disabled={postUrl!=null && postUrl.length > 0}
              onClick={() => handleTopicSelection(topic)}
              sx={{
                m: 1,
                backgroundColor: topic === selectedTrendingTopic ? theme.palette.primary.blue : theme.palette.primary.white,
                color: topic === selectedTrendingTopic ? theme.palette.primary.white : theme.palette.primary.grey,
                fontWeight: 500,
                fontSize: '14px',
                borderRadius: 2
              }}
            />
          ))}
        </Container>
      </div>
    )
}