import React, { useCallback, useEffect } from "react";
import Socials from "@/pages/setup/simpleSetupComponents/Brands/socials";
import FullScreenModal from "@/components/FullScreenModal";
import TopicSelection from "@CommonScreens/TopicSelection";
import { CircularProgress } from "@mui/material";

export default function BrandTopics(props) {
  const { onClose, open, source, initialSelectedTopics, selectedTopicsSetter, isLoading } = props;
  const [topics, setTopics] = React.useState([]);
  const [socialsOpen, setSocialsOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);

  useEffect(() => {
    // Propagate the selected topics to the parent component
    // when the socials modal gets opened
    if (socialsOpen && selectedTopicsSetter) {
      selectedTopicsSetter(topics);
    }
  }, [socialsOpen]);

  const handleSelectedTopicsSetter = useCallback((newTopics) => {
    setTopics(newTopics);
  }, []);

  const handleContinue = () => {
    if (topics?.length > 0) {
      console.log("Topics: ", topics);
      setSocialsOpen(true);
    } else {
      setAlertMessage("Please select at least one topic.");
    }
  };

  const handleOnCloseSocials = useCallback(() => {
    setSocialsOpen(false);
  }, [setSocialsOpen]);

  const handleOnClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const content = isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <CircularProgress size={72} color="inherit" />
    </div>
  ) : (
    <TopicSelection
      autosave
      initialSelectedTopics={initialSelectedTopics}
      // This callback passes an array of strings - the names of the selected topics.
      selectedTopicsSetter={handleSelectedTopicsSetter}
    />
  );

  return (
    <>
      {socialsOpen && (
        <Socials
          onClose={handleOnCloseSocials}
          onCloseTopics={handleOnClose}
          open={socialsOpen}
          source={source}
          topics={topics}
        />
      )}
      {!socialsOpen && open && (
        <FullScreenModal
          continueText="Continue"
          onClose={handleOnClose}
          open={open}
          progress={50}
          content={content}
          disableContinue={!topics?.length || topics?.length > 10}
          handleContinue={handleContinue}
          alertMessage={alertMessage}
        />
      )}
    </>
  );
}
