import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import { ReactComponent as ArrowLeft } from "../../../assets/Icons/Arrow-Left.svg";
import DoneModal from "./doneModal";
import PModal from "../../../components/library/PModal";
import PBackButton from "../../../components/library/PBackButton";
import DateTimePrompt from "../../CommonScreens/DateTimePrompt";
import PButton from "../../../components/library/PButton";
import { publishImmediateUserPost, publishScheduledUserPost } from "../../../helper/apiHelper";
import dayjs from "dayjs";
import { NOW_BUFFER, absTimeDeltaMinutes } from "../../../components/library/PTimePicker";

export default function WhenToPostModal({
  onClose,
  open,
  post,
  source,
  onCloseEditPostModal,
  onCloseSocials,
  onCloseProfessionals,
  onCloseTopics,
  socials
}) {
  const theme = useTheme();
  const [isDoneModalOpen, setIsDoneModalOpen] = React.useState(false);
  const [timing, setTiming] = React.useState(false);
  const [defaultDateTime, setDefaultDateTime] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!open) setDefaultDateTime(null);
    if (open && source === "ComposePost") {
      setDefaultDateTime(new Date());
    }
  }, [open, source]);

  const handleCloseDoneModal = () => {
    setIsDoneModalOpen(false);
    console.log(post, "post");
  };

  const handleCloseWhenToPostModal = () => onClose();

  const handleDoneModal = () => {
    setIsDoneModalOpen(true);
  };

  const handleComposeContinue = async () => {
    if (source !== "ComposePost") {
      return;
    }

    if (timing) {
      setIsLoading(true);

      let publishFn = null;
      const parsedTiming = dayjs(timing);
      if (absTimeDeltaMinutes(parsedTiming, dayjs()) < NOW_BUFFER) {
        publishFn = async () =>
          publishImmediateUserPost({ post, isStandalonePost: true });
      } else {
        publishFn = async () =>
          publishScheduledUserPost({
            post,
            time: timing,
            isStandalonePost: true,
          });
      }

      try {
        publishFn();
      } catch (error) {
        // TODO: Snackbar
        console.error("Error publishing post:", error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setIsDoneModalOpen(true);
    }
  };

  return source === "ComposePost" ? (
    <PModal
      open={open}
      onClose={onClose}
      loadingNext={isLoading}
      backButton={<PBackButton onClick={onClose} iconType="back" />}
      actionButtons={[
        <PButton pVariant="primary" onClick={handleComposeContinue}>
          Continue
        </PButton>,
      ]}
    >
      <DateTimePrompt
        initialDateTime={defaultDateTime}
        headerMessage="When do you want to post this?"
        handleDateTimeChange={(date) => {
          setTiming(date);
        }}
        showNowInTimePicker
      />
      <DoneModal
        open={isDoneModalOpen}
        timing={timing}
        source={source}
        onClose={handleCloseDoneModal}
        onCloseEditPostModal={onCloseEditPostModal}
        onCloseWhenToPostModal={handleCloseWhenToPostModal}
        onCloseTopics={onCloseTopics}
        onCloseSocials={onCloseSocials}
        onCloseProfessionals={onCloseProfessionals}
        post={post}
      />
    </PModal>
  ) : (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen
      sx={{
        px: {
          xs: 0,
          md: 0,
        },
        m: 0,
        maxWidth: "100vw",
      }}
    >
      <DialogTitle sx={{ m: 0, p: 0 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, pt: 2 }}
        >
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <ArrowLeft fill={theme.palette.primary.grey} />
            </IconButton>
          </Grid>
          <Grid item>
            <DoneModal
              open={isDoneModalOpen}
              timing={timing}
              onClose={handleCloseDoneModal}
              onCloseEditPostModal={onCloseEditPostModal}
              onCloseWhenToPostModal={handleCloseWhenToPostModal}
              onCloseTopics={onCloseTopics}
              onCloseSocials={onCloseSocials}
              onCloseProfessionals={onCloseProfessionals}
              post={post}
            />
          </Grid>
        </Grid>
        <Stack
          direction="column"
          alignItems="center"
          spacing={4}
          sx={{ mt: 1 }}
        >
          <Typography
            sx={{
              fontSize: "35px",
              fontWeight: 700,
              color: theme.palette.primary.black,
              mb: 1,
            }}
          >
            When do you want to post this?
          </Typography>
          {["Right Now", "Tomorrow", "Next Week", "In 2 Weeks"].map((item) => (
            <Button
              key={item}
              onClick={() => {
                setTiming(item);
                handleDoneModal();
              }}
              sx={{
                maxWidth: "580px",
                fontSize: "16px",
                fontWeight: 500,
                backgroundColor: theme.palette.primary.white,
                color: theme.palette.primary.black,
                textTransform: "none",
                width: "580px",
                py: 3,
                px: 2,
                border: "1px solid #E0E0E0",
                borderRadius: 2,
                "&:hover": {
                  backgroundColor: theme.palette.primary.lightBlue,
                  color: theme.palette.primary.blue,
                },
              }}
            >
              <span style={{ textAlign: "left", width: "100%" }}>{item}</span>
            </Button>
          ))}
        </Stack>
      </DialogTitle>
    </Dialog>
  );
}
