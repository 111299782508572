import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Http from '../../../http/httpClient';
import Stack from '@mui/system/Stack';
import Avatar from '@mui/material/Avatar'
import Snackbar from '@mui/material/Snackbar';

import { ReactComponent as Delete } from '../../../assets/Icons/Delete.svg';
import GoalModal from './components/goalModal';
import { updateStrategiesWithGifts } from './utils/strategyUtils';

export default function ChooseGoalModal(props) {
  const theme = useTheme();
  const { onClose, open, setupList, templates, defaultStrategies } = props;
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [isUpdateProgress, setIsUpdateProgress] = React.useState(false);
  const [strategies, setStrategies] = React.useState(defaultStrategies);
  const [defaultTemplates, setDefaultTemplates] = React.useState(templates);

  const handleClose = async () => {
    onClose();
  };

  React.useEffect(() => {
    if (defaultTemplates.length > 0) {
      const updatedStrategies = updateStrategiesWithGifts(
        strategies,
        defaultTemplates
      );
      setStrategies(updatedStrategies);
    }
  }, [defaultTemplates]);

  // This is the function that is called when the continue button is clicked
  const handleContinue = async () => {
    const goalStatus = strategies.find((goal) => goal.active === true);
    if (goalStatus) {
      setIsUpdateProgress(true);
      Http.postData('setup/setStrategy', {
        strategy: goalStatus.id,
        strategyTemplate: goalStatus._id,
      })
        .then(function (resp) {
          if (resp.status) {
            setIsUpdateProgress(false);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(() => {
          onClose();
        });
    } else {
      setAlertMessage('Select any item to continue');
      setShowAlert(true);
    }
  };

  // This is the function that is called when the alert is closed
  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const handleGoalClick = (goalIndex) => {
    const updatedGoals = strategies.map((goal, index) => {
      if (index === goalIndex) {
        return { ...goal, active: !goal.active };
      } else {
        return { ...goal, active: false };
      }
    });
    setStrategies(updatedGoals);
  };

  // This is the function that is called when the modal is closed
  React.useEffect(() => {
    props.setSelectedGoal((prev) => ({
      ...prev,
      goal: strategies.find((goal) => goal.active === true),
    }));
  }, [strategies]);

  // This is the styling for the continue button
  const continueStyle = {
    maxWidth: '200px',
    backgroundColor: theme.palette.primary.blue,
    color: theme.palette.primary.white,
    textTransform: 'none',
    width: '167px',
    py: 1,
    px: 2,
    mr: 2,
    mt: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: 2,
    boxShadow: '0px 4px 8px 0px #2C64E31F',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.blue, 0.8),
    },
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };
  return (
    <>
    <Dialog onClose={handleClose} open={open} fullScreen sx={{
      px: {
        xs: 0,
        md: 2,
      }
    }}>
      <DialogTitle sx={{ p: 0 }}>
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={() => {  onClose(true) }}
              sx={{ pt: 2, pl: 2 }}
            >
              <Delete fill={theme.palette.primary.grey} />
            </IconButton>
          </Grid>
          <Grid item>
          <Stack direction="row" spacing={10} sx={{my: 1.5}}>
                {setupList.map(item => (
                  <Stack justifyContent="center"
                  alignItems="center">
                  <Avatar sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    bgcolor: item.active ? theme.palette.primary.blue : theme.palette.primary.white,
                    color: item.active ? theme.palette.primary.white : theme.palette.primary.grey,
                    border: item.active ? `none` : `1px solid #E0E0E0`,
                    my: 1,
                  }}>
                    {item.index}
                  </Avatar>
                  <Typography sx={{
                    color: item.active ? theme.palette.primary.black : theme.palette.primary.grey,
                    fontWeight: 500
                  }}>
                    {item.listText}
                  </Typography>
                  </Stack>
                ))}
              </Stack>

          </Grid>
          <Grid item>
            <Button onClick={handleContinue} sx={continueStyle}>
              {isUpdateProgress ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Continue'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <Grid container justifyContent="space-between">
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={6} >
            <Typography
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.black,
                pt: 1,
                fontSize: {
                  xs: '28px',
                  md: '32px',
                },
                lineHeight: 'normal'
              }}
            >
              Choose a goal.
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: theme.palette.primary.grey,
                pb: 5,
              }}
            >
              This helps find the best way to reach your target audience.
            </Typography>
            <Grid container spacing={2}>
              {strategies.map((goal, index) => {
                if (goal.enabled) {
                  return (
                    <GoalModal
                      key={index}
                      goal={goal}
                      index={index}
                      handleGoalClick={handleGoalClick}
                    />
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
      </DialogContent>
    </Dialog>
    {alertMessage && <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
    <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
      {alertMessage}
    </Alert>
  </Snackbar>}

</>
  );
}
