import { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import {
  useTheme,
  CircularProgress,
  Button,
  Typography,
  Stack,
  Container,
  Avatar,
  Grid,
  Link,
  Paper,
  InputBase,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import Fuse from 'fuse.js';

import { ReactComponent as Search } from '@Assets/Icons/Search.svg';

import Http from '@/http/httpClient';
import MessageLoader from '@/components/messageLoader';
import { aiSearchLoadingMessages } from '@/constants/constant';
import { useSelector } from 'react-redux';

const styles = {
  textOverflow: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
};

// This is the AIResults I need to add the credits to this component
export default function AIResults(props) {
  const theme = useTheme();
  const { onClose, open, listItem, setselectedTargetContacts, credits, formData } = props;
  const [contactEnriched, setContactEnriched] = useState(false);
  const [totalContactsCount, setTotalContactsCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [aiContacts, setAiContacts] = useState([]);
  const [searchContact, setSearchContact] = useState('');
  const [checkboxChanged, setCheckboxChanged] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [skipfetch, setSkipfetch] = useState(false);
  const [checkedContacts, setCheckedContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [activeButton, setActiveButton] = useState('Relevance');
  const [sortFields, setSortFields] = useState(['hasNull', 'name', 'title', 'company']);
  // Fetch the feature flags from the store to check if the credits-ui feature is enabled
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  // Function to check if a feature is enabled
  const isFeatureEnabled = (featureName) => {
    return featureFlags.some((flag) => flag.name === featureName && flag.isFeatureEnabled);
  };

  // Feature flag to disable this feature until it's ready
  const showCreditInfo = isFeatureEnabled('credit-ui');

  const fuse = new Fuse(aiContacts, {
    keys: ['name', 'company', 'title'],
    threshold: 0.4,
    location: 0,
    distance: 100,
    findAllMatches: true,
    minMatchCharLength: 1,
  });

  const buttonStyles = {
    backgroundColor: theme.palette.primary.lightBlue,
    color: theme.palette.primary.blue,
    textTransform: 'none',
    py: 1,
    px: 1,
    borderRadius: 3,
    minWidth: '78px',
    lineHeight: 'unset',
  };

  const handleClose = () => {
    //onClose();
  };

  const handleButtonClick = (action) => {
    if (['Remove', 'Add'].includes(action)) {
      handleContactAction(action);
    } else if (['Relevance', 'Name', 'Company', 'Role'].includes(action)) {
      setActiveButton(action);
    }
  };

  const handleContactAction = (action) => {
    const newSelectedContacts =
      action === 'Add'
        ? [
            ...selectedContacts,
            ...checkedContacts.filter((c) => !selectedContacts.some((contact) => contact.id === c.id)),
          ]
        : selectedContacts.filter((contact) => !checkedContacts.some((c) => c.id === contact.id));

    setSelectedContacts([...newSelectedContacts]);

    setAiContacts((prevContacts) =>
      aiContacts.map((contact) => {
        if (checkedContacts.some((c) => c.id === contact.id)) {
          return { ...contact, focus: action === 'Add' };
        }
        return contact;
      }),
    );
  };

  useEffect(() => {
    const sortingFieldsMap = {
      All: ['hasNull', 'name', 'title', 'company'],
      Role: ['hasNull', 'title', 'name', 'company'],
      Company: ['hasNull', 'company', 'name', 'title'],
      Relevance: [],
      Channel: [],
    };

    const sortingFields = sortingFieldsMap[activeButton] || ['hasNull', 'name', 'title', 'company'];
    setSortFields([...sortingFields]);
    setSelectedContacts([]);
  }, [activeButton]);

  useEffect(() => {
    if (aiContacts.length > 0) {
      const searchQuery = searchContact.toLowerCase();
      const filtered = searchQuery
        ? fuse.search(searchQuery).map((contact) => contact.item)
        : aiContacts.filter((contact) => {
            return (
              contact !== null &&
              (contact.name?.toLowerCase().includes(searchQuery) ||
                contact.company?.toLowerCase().includes(searchQuery) ||
                contact.title?.toLowerCase().includes(searchQuery))
            );
          });

      const sortedContacts = _.orderBy(filtered, sortFields, ['asc']);
      setFilteredContacts([...sortedContacts]);
    }
  }, [searchContact, sortFields, aiContacts, checkboxChanged]);

  useEffect(() => {
    if (skipfetch) {
      const filteredContacts = listItem?.allContacts;
      setAiContacts((prevAiContacts) => {
        const uc = Array.from(new Map(filteredContacts.map((contact) => [contact.id, { ...contact }])).values());
        setSelectedContacts(uc);
        return uc;
      });
      setTotalContactsCount(filteredContacts.length ?? false);
    }

    setSkipfetch(false);
  }, [skipfetch]);

  const fetchData = async (page) => {
    if (totalPages > 0 && pageNumber > totalPages && !contactEnriched) {
      return;
    }

    try {
      if (listItem?.allContacts?.length < 0) {
        setSkipfetch(true);
      } else {
        setIsLoading(true);
        const aiProvider = listItem?.providers?.find((provider) => provider.providerType === 'AI');
        // Http.postData("generateAiList", { prompt: aiProvider?.AIRequest })
        Http.postData('getRocketReachContactsWithPayload', { payload: formData })
          .then((response) => {
            const filteredContacts = response.contacts;
            setAiContacts((prevAiContacts) => {
              const updatedContacts = filteredContacts.map((contact, index) => ({
                ...contact,
                // Set focus to true by default if credits is null or undefined, else check if index is less than available credits
                focus: credits ? index < credits : true,
              }));
              const uc = Array.from(new Map(updatedContacts.map((contact) => [contact.id, { ...contact }])).values());
              setSelectedContacts(uc.filter(c=>c.focus));
              return uc;
            });
            setTotalContactsCount(response.contacts.length ?? false);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!contactEnriched) {
      fetchData(pageNumber);
    }
  }, [pageNumber, formData]);

  useEffect(() => {
    let interval = null;

    const startInterval = () => {
      interval = setInterval(() => {
        if (contactEnriched) {
          fetchData();
        } else {
          clearInterval(interval);
        }
      }, 3000);
    };

    if (!interval) {
      startInterval();
    }

    return () => {
      clearInterval(interval);
    };
  }, [contactEnriched]);

  const clickedCheckbox = (contact) => {
    const exist = selectedContacts.some((s) => s !== null && s.id === contact.id);

    /*if (!exist && selectedContacts.length >= 10) {
      setModalOpen(true);
      return;
    } */

    if (exist) {
      setSelectedContacts([...selectedContacts.filter((s) => s !== null && s.id !== contact.id)]);
    } else {
      setSelectedContacts([...selectedContacts, contact]);
    }
    const currentContact = aiContacts.find((c) => c.id === contact.id);
    const newStatus = !currentContact.focus;

    onCheckBoxChange(newStatus, contact.id);
  };

  useEffect(() => {
    setselectedTargetContacts({
      selectedContacts: selectedContacts,
      allContacts: aiContacts,
    });
  }, [selectedContacts]);

  const onCheckBoxChange = useCallback(
    (newStatus, contactId) => {
      const updatedContacts = aiContacts.map((contact) => {
        if (contact.id === contactId) {
          return { ...contact, focus: newStatus };
        }
        return contact;
      });

      setAiContacts([...updatedContacts]);
      setCheckboxChanged((prev) => !prev);
    },
    [aiContacts],
  );

  const handleSearchAIResults = (event) => {
    event.preventDefault();
    setCheckedContacts([]);
    setSearchContact(event.target.value);
  };

  const handleCheckboxClick = (contact) => {
    setCheckedContacts((prev) => {
      const isAlreadyChecked = prev.some((c) => c.id === contact.id);
      if (isAlreadyChecked) {
        return prev.filter((c) => c.id !== contact.id);
      } else {
        return [...prev, contact];
      }
    });
  };

  const contactsForRender = () => {
    return filteredContacts.map((fc) => {
      return aiContacts.find((ct) => fc.id === ct.id); // Using == because cid is a string and ct.id is a number
    });
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Typography
            sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 2, lineHeight: 'normal' }}
          >
            Choose the professionals you want to build relationships with.
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5 }}>
            You can always add or remove more later.
          </Typography>
          {isLoading && aiContacts.length === 0 ? (
            <MessageLoader sentences={aiSearchLoadingMessages} />
          ) : aiContacts.length === 0 ? (
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, color: theme.palette.primary.grey, textAlign: 'center', mt: 5 }}
            >
              We were not able to find any contacts at this time that match your search criteria. Try again later or use
              a different{' '}
              <Link sx={{ cursor: 'pointer', color: theme.palette.primary.blue }} onClick={() => handleClose()}>
                search query
              </Link>
              .
            </Typography>
          ) : (
            <>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '95%',
                  my: 2,
                  boxShadow: 0,
                  border: '1px solid #E0E0E0',
                  borderRadius: '15px',
                }}
              >
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <Search fill={theme.palette.primary.grey} />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by name, email, company, role"
                  onChange={handleSearchAIResults}
                />
              </Paper>
              {showCreditInfo &&
                (credits && credits < aiContacts.length ? (
                  <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5 }}>
                    You do not have enough credits to add all the contacts. You have {credits} credits available.
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5 }}>
                    Selected Contacts: {selectedContacts.length} / {aiContacts.length}, Available Credits:{' '}
                    {credits ?? 0}
                  </Typography>
                ))}
              <Grid container spacing={2} alignItems="center" sx={{ my: 2, width: '100%' }}>
                <Grid item xs={1} container justifyContent="flex-start">
                  <FormGroup>
                    {/* The select-all checkbox */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filteredContacts?.length > 0 && filteredContacts.length === checkedContacts?.length}
                          sx={{
                            'color': theme.palette.primary.grey,
                            '&.Mui-checked': {
                              color: theme.palette.primary.blue,
                              backgroundColor: 'white',
                            },
                          }}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setCheckedContacts(filteredContacts);
                            } else {
                              setCheckedContacts([]);
                            }
                          }}
                        />
                      }
                      label=""
                      sx={{
                        '& .MuiTypography-root': {
                          color: theme.palette.primary.black,
                          fontSize: '12px',
                          fontWeight: 500,
                          margin: '0px',
                        },
                      }}
                    />
                  </FormGroup>
                </Grid>
                {(checkedContacts?.length ?? 0) === 0 ? (
                  <Grid item xs={7}>
                    {['Relevance', 'Name', 'Company', 'Role'].map((type) => (
                      <Button
                        key={type}
                        onClick={() => handleButtonClick(type)}
                        sx={{
                          textTransform: 'none',
                          border: 'none',
                          backgroundColor: theme.palette.primary.white,
                          color: type === activeButton ? theme.palette.primary.blue : theme.palette.primary.grey,
                          fontWeight: 500,
                          fontSize: '14px',
                          mx: 1,
                        }}
                      >
                        {type}
                      </Button>
                    ))}
                  </Grid>
                ) : (
                  // The Add & Remove group action
                  <Grid item xs={7}>
                    {['Add', 'Remove'].map((type) => (
                      <Button
                        key={type}
                        onClick={() => handleButtonClick(type)}
                        sx={{
                          textTransform: 'none',
                          border: 'none',
                          backgroundColor: theme.palette.primary.white,
                          color: activeButton === type ? theme.palette.primary.blue : theme.palette.primary.grey,
                          fontWeight: 500,
                          fontSize: '14px',
                          mx: 1,
                        }}
                      >
                        {type}
                      </Button>
                    ))}
                  </Grid>
                )}
                {contactsForRender().map((contact, index) => (
                  <Container
                    style={{ paddingLeft: '16px' }}
                    key={contact?.id}
                    sx={{
                      backgroundColor: theme.palette.primary.white,
                      py: 2,
                      my: 1,
                      borderRadius: 3,
                      width: '100%',
                      border: '1px solid #E0E0E0',
                    }}
                  >
                    <Grid container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                      <Grid item xs={0.5} container justifyContent="flex-start" sx={{ p: 0 }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkedContacts.some((c) => c.id === contact?.id)}
                                onChange={() => handleCheckboxClick(contact)}
                                sx={{
                                  'color': theme.palette.primary.grey,
                                  '&.Mui-checked': {
                                    color: theme.palette.primary.blue,
                                    backgroundColor: 'white',
                                  },
                                }}
                              />
                            }
                            label=""
                            sx={{
                              '& .MuiTypography-root': {
                                color: theme.palette.primary.black,
                                fontSize: '12px',
                                fontWeight: 500,
                                margin: '0px',
                              },
                            }}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid container item xs={10.5} alignItems="center" spacing={2}>
                        <Grid item>
                          <Avatar sx={{ my: 2, mt: 0, alignSelf: 'flex-start' }} src={contact?.photo_url || null} />
                        </Grid>
                        <Grid item sx={{ width: '80%' }}>
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                color: theme.palette.primary.black,
                                ...styles.textOverflow,
                              }}
                              title={contact?.name}
                            >
                              {contact?.name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: theme.palette.primary.black,
                                ...styles.textOverflow,
                              }}
                              title={contact?.company}
                            >
                              {contact?.company}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: theme.palette.primary.black,
                                ...styles.textOverflow,
                              }}
                              title={contact?.title}
                            >
                              {contact?.title}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid container item xs={1} alignItems="center">
                        <Button
                          onClick={() => clickedCheckbox(contact)}
                          sx={
                            !contact?.focus
                              ? buttonStyles
                              : {
                                  ...buttonStyles,
                                  backgroundColor: theme.palette.primary.white,
                                  border: '1px solid #e0e0e0',
                                }
                          }
                        >
                          {' '}
                          {contact?.updating ? (
                            <CircularProgress size={24} color="inherit" style={{ position: 'absolute' }} />
                          ) : !contact?.focus ? (
                            'Add'
                          ) : (
                            'Remove'
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                ))}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </>
  );
}
