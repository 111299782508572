export const replaceEmailBodyPlaceholders = (body, user, contact) => {
    let emailBody = body

    if (emailBody && contact) {
        emailBody = emailBody.replace(/<Their full name>/g, contact.name)
        emailBody = emailBody.replace(/<Their first name>/g, contact.first_name || contact.firstName)
        emailBody = emailBody.replace(/<Their last name>/g, contact.last_name || contact.lastName)
        emailBody = emailBody.replace(/<Their title>/g, contact?.title)
        emailBody = emailBody.replace(/<Their company>/g, contact?.company || 'your company')
        emailBody = emailBody.replace(/<My name>/g, user?.name || contact?.userFullName )
        emailBody = emailBody.replace(/<My company>/g, user?.company || contact?.userCompany)
    }

    return emailBody
}

export const replaceEmailSubjectPlaceholders = (subject, user, contact) => {
    let emailSubject = subject

    if (emailSubject && contact) {
        emailSubject = emailSubject.replace(/<Their full name>/g, contact.name)
        emailSubject = emailSubject.replace(/<Their first name>/g, contact.first_name || contact.firstName)
        emailSubject = emailSubject.replace(/<Their last name>/g, contact.last_name || contact.lastName)
        emailSubject = emailSubject.replace(/<Their title>/g, contact?.title)
        emailSubject = emailSubject.replace(/<Their company>/g, contact?.company || 'your company')
        emailSubject = emailSubject.replace(/<My name>/g, user?.name || contact?.userFullName )
        emailSubject = emailSubject.replace(/<My company>/g, user?.company || contact?.userCompany) 
    }

    return emailSubject
}