import React, { useState } from 'react'
import {
  Button,
  Container,
  Grid,
  TextField,
  Modal,
  Box,
  Typography,
  IconButton,
  Chip,
  Paper,
  InputBase,
  Divider
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

const testTopics = [
  '5G',
  'Rogers Communications',
  'Cybersecurity',
  'Mobile Phones'
]

export default function EditContactModal (props) {
  const [open, setOpen] = useState(false)
  const [manTopics, setManTopics] = useState([])
  const [newTopic, setNewTopic] = useState('')

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Add your form submission logic here
    handleClose()
  }

  const handleManDelete = (topic) => {
    setManTopics((prevTopics) => prevTopics.filter((t) => t !== topic))
  }

  const handleAddTopic = () => {
    console.log(newTopic)
    if (newTopic.trim() !== '') {
      setManTopics((prevTopics) => [...prevTopics, newTopic])
      setNewTopic('')
    }
    console.log(manTopics)
  }

  return (
    <>
      <Modal open={props.open} onClose={props.onClose}>
        <Container maxWidth='xl'>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: '8px',
              p: 4
            }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
            >
              <Typography sx={{
                fontWeight: 700,
                fontSize: '22pt',
                py: 1,
                mt: 1,
                mb: 2,
                fontFamily: 'Poppins',
                color: '#000000'
              }}
              >
                Edit Contact
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <IconButton>
                  <TwitterIcon sx={{ color: '#4999E9' }} />
                </IconButton>
                <IconButton>
                  <LinkedInIcon sx={{ color: '#3275B2' }} />
                </IconButton>
              </Box>
            </Box>
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label='First Name'
                    variant='outlined'
                    fullWidth
                    defaultValue={props.user.name.split(' ')[0]}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Last Name'
                    variant='outlined'
                    fullWidth
                    defaultValue={props.user.name.split(' ')[1]}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Company'
                    variant='outlined'
                    fullWidth
                    defaultValue={props.user.company}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Title'
                    variant='outlined'
                    fullWidth
                    defaultValue={props.user.role}
                    required
                  />
                </Grid>
                <Grid item xs={12} alignItems='center'>
                  <Typography
                    align='center' sx={{
                      fontWeight: 700,
                      fontSize: '18pt',
                      mt: 1,
                      fontFamily: 'Poppins',
                      color: '#09092E'
                    }}
                  >
                    Topics of Interest
                  </Typography>
                </Grid>
                <Grid item xs={6} alignItems='center'>
                  <Typography
                    align='center' sx={{
                      fontWeight: 700,
                      fontSize: '16pt',
                      fontFamily: 'Poppins',
                      color: '#3f3f4c'
                    }}
                  >
                    AI Derived
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
                    {testTopics.map((topic) => (
                      <Chip
                        key={topic}
                        label={topic}
                        sx={{
                          mx: 1,
                          backgroundColor: '#3983F7',
                          color: '#FFFFFF',
                          fontWeight: 'bold',
                          border: '2px solid #3983F7'
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={6} alignItems='center'>
                  <Typography
                    align='center' sx={{
                      fontWeight: 700,
                      fontSize: '16pt',
                      fontFamily: 'Poppins',
                      color: '#3f3f4c'
                    }}
                  >
                    Manually Entered
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
                    {manTopics.map((topic) => (
                      <Chip
                        key={topic}
                        label={topic}
                        onDelete={() => handleManDelete(topic)}
                        sx={{
                          mx: 1,
                          backgroundColor: '#FFFFFF',
                          color: '#3983F7',
                          fontWeight: 'bold',
                          border: '2px solid #3983F7'
                        }}
                      />
                    ))}
                  </Box>
                  <Paper
                    component='form'
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', mt: 2 }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder='Add a New Topic'
                      value={newTopic}
                      onChange={(e) => setNewTopic(e.target.value)}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
                    <IconButton sx={{ p: '10px', color: '#3983F7' }} onClick={handleAddTopic}>
                      <AddIcon />
                    </IconButton>
                  </Paper>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  <Button
                    type='submit' sx={{
                      mt: 3,
                      ml: 1,
                      py: 1,
                      mb: 1,
                      mt: 5,
                      px: 4,
                      backgroundColor: '#3983F7',
                      color: 'white',
                      fontSize: '16px',
                      textTransform: 'none',
                      borderRadius: '8px'
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Modal>
    </>
  )
};
