import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledPTypography = styled(Typography)(({ theme, weight, size, color }) => {
  return {
    ...theme.typography[weight][size],
    fontFamily: "Inter",
    color: color || "inherit",
  };
});

function PTypography({ size, weight, children, color, preformatted, sx, ...rest }) {
  return (
    <StyledPTypography
      weight={weight}
      size={size}
      color={color}
      sx={{ ...sx, whiteSpace: preformatted ? "pre-wrap" : undefined }}
      {...rest}
    >
      {children}
    </StyledPTypography>
  );
}

PTypography.propTypes = {
  /** Weight of the font. This is a key in the theme.typography object */
  weight: PropTypes.oneOf(["bold", "regular"]),

  /** Font size. This is a key in the theme.typography object */
  size: PropTypes.oneOf(["footnote", "body1", "body2", "h1", "h2", "h3", "caption"]),

  /** The color of the text */
  color: PropTypes.string,

  /** Whether the text is preformatted. This applies the CSS white-space: pre-wrap; */
  preformatted: PropTypes.bool,

  /** Additional styles to apply to the underlying Typography component */
  sx: PropTypes.object,
};

PTypography.defaultProps = {
  weight: "bold",
  size: "body1",
  color: "inherit",
  preformatted: false,
  sx: {},
};

export default PTypography;
