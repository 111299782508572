import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ReactComponent as ArrowRight } from '../../assets/Icons/Arrow-Right.svg';
import { ReactComponent as Check } from '../../assets/Icons/Check.svg';
import { Stack } from '@mui/system';

import PTypography from './PTypography';
import PButton from './PButton';

const getVariantStyles = (theme, pVariant) => {
    let colorScheme = {};

    switch (pVariant) {
        case 'primary':
            colorScheme = {
                text: theme.palette.primaryCL.Black110,
                background: theme.palette.primaryCL.White100,
                hoverBackground: theme.palette.primaryCL.White100,
                border: theme.palette.primaryCL.Black70,
                hoverBorder: theme.palette.primaryCL.Black100,
                activeBorder: theme.palette.primaryCL.Blue100,
                button: theme.palette.primaryCL.Blue100,
                buttonBG: theme.palette.primaryCL.White100,
                disabledText: theme.palette.primaryCL.Black100,
                disabledBackground: theme.palette.primaryCL.Black50,
                disabledButton: theme.palette.primaryCL.Black70,
                disabledButtonBG: theme.palette.primaryCL.White110,
            };
            break;

        // Add more cases for 'outlined', 'plain', etc.
    }

    return colorScheme;
};

const StyledPCard = styled('div', {
    shouldForwardProp: (prop) => prop !== 'pVariant' && prop !== 'row' && prop !== 'buttonize'
})(({ theme, pVariant, disabled, row, buttonize }) => {
    const {
        background,
        text,
        border,
        hoverBorder,
        activeBorder,
        disabledBackground,
        disabledText,
    } = getVariantStyles(theme, pVariant);

    const buttonizeStyles = {
        cursor: 'pointer',
        userSelect: 'none',
    };

    return {
        borderRadius: '14px',
        border: '1px solid',
        borderColor: border,
        padding: '16px',
        width: row ? '100%' : '255px',
        height: row ? 'auto' : '200px',
        fontFamily: 'Inter',
        color: disabled ? disabledText : text,
        backgroundColor: disabled ? disabledBackground : background,
        '&:hover': {
            borderColor: hoverBorder,
        },
        '&:active': {
            borderColor: activeBorder,
        },
        // Apply any additional styles you need here

        // Apply buttonize styles if the buttonize prop is true
        ...((buttonize && buttonizeStyles) || {}),
    };
});


function PCard({ title, helperText, Icon, Badge, pVariant, disabled, onClick, row, button, selected, buttonize }) {
    const theme = useTheme();
    const buttonCol = !!button && (disabled ? getVariantStyles(theme, pVariant).disabledButton : getVariantStyles(theme, pVariant).button) || null;

    return (
        <StyledPCard
            disableElevation
            pVariant={pVariant}
            disabled={disabled}
            onClick={onClick}
            row={row}
            buttonize={buttonize}
        >
            {row ? (
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        {Icon && <Icon />}
                        <Stack>
                            <div style={{ paddingBottom: helperText?.length > 0 ? '8px' : '0px' }}>
                                <PTypography size={row ? 'body2' : 'h1'} weight="bold">
                                    {title}
                                </PTypography>
                            </div>
                            <PTypography size="footnote" weight="regular" color={theme.palette.primaryCL.Black100}>
                                {helperText}
                            </PTypography>
                        </Stack>
                    </Stack>

                    <Stack direction="row" spacing={2} alignItems="center">
                        {Badge && Badge}
                        {button && (
                            <PButton pVariant="plain" disabled={disabled}>
                                {selected ?
                                    <Check fill={buttonCol} style={{ width: 20, height: 20 }} />
                                    :
                                    <ArrowRight fill={buttonCol} />
                                }
                            </PButton>
                        )}
                    </Stack>
                </Stack>
            ) : (
                <Grid container spacing={0} sx={{ height: '100%' }}>
                    <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', pb: 2 }}>
                        {Icon && <Icon />}
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', pb: 2 }}>
                        {Badge && Badge}
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div>
                            <div style={{ paddingBottom: '8px' }}>
                                <PTypography size="h1" weight="bold">
                                    {title}
                                </PTypography>
                            </div>
                            <PTypography size="footnote" weight="regular" color={theme.palette.primaryCL.Black100}>
                                {helperText}
                            </PTypography>
                        </div>
                        
                        <div style={{ flexGrow: 0.5 }}></div> 

                        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '16px' }}>
                            {button && (
                                <PButton pVariant="plain" disabled={disabled}>
                                    {selected ?
                                        <Check fill={buttonCol} style={{ width: 20, height: 20 }} />
                                        :
                                        <ArrowRight fill={buttonCol} />
                                    }
                                </PButton>
                            )}
                        </div>
                    </Grid>
                </Grid>

            )}
        </StyledPCard>
    );
}

PCard.propTypes = {
    /** React children */
    children: PropTypes.node,

    /** Icon component to display in the card.
     * NOTE: Storybook does not support React components in controls */
    Icon: PropTypes.elementType,

    /** Postilize design variant. So far, all we have is primary, keeping for posterity */
    pVariant: PropTypes.oneOf(['primary']),

    /** Disable state of the button */
    disabled: PropTypes.bool,

    /** Click handler */
    onClick: PropTypes.func.isRequired,

    /** Title */
    title: PropTypes.string,

    /** HelperText */
    helperText: PropTypes.string,

    /** Badge component to display in the card.
   * NOTE: Storybook does not support React components in controls */
    Badge: PropTypes.node,

    /** Row */
    row: PropTypes.bool,

    /** Tells the card to act like a "Button".
     * Cursor will be changed to pointer,
     * and text selection will be disabled.
    */
   buttonize: PropTypes.bool,
};

PCard.defaultProps = {
    Icon: null,
    Badge: null,
    pVariant: 'primary',
    title: '',
    helperText: '',
    button: false,
    disabled: false,
    row: false,
    buttonize: false,
};

export default PCard;
