import React, {
  memo, useCallback, useEffect, useState, useMemo,
} from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { getSavedTopics, refreshUserPostQueue } from '@/helper/apiHelper';
import { CharacterLimits, PostFrequencyOptions } from '@/constants/constant';

import PModal from '@Library/PModal';
import PButton from '@Library/PButton';
import PBackButton from '@Library/PBackButton';

import Socials from '@CommonScreens/Socials';
import PostFrequency from '@CommonScreens/PostFrequency';
import TopicSelection from '@CommonScreens/TopicSelection';
import PostEdit from '@CommonScreens/PostEdit';

// Which pages we want the modal to render the close early button on
const showCloseEarlyOnPages = ['postPreview'];

// Which pages we want the modal to render the continue button on
const showContinueOnPages = ['audienceAndTopicSelection'];

function BrandingFlow(props) {
  const { onClose } = props;

  const [isGeneratingPosts, setIsGeneratingPosts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSocials, setSelectedSocials] = useState([]);
  const [currentContent, setCurrentContent] = useState("socials");
  const [topics, setTopics] = useState([]);
  const [postFrequency, setPostFrequency] = useState("");
  const [posts, setPosts] = useState([]);
  const [pageHistory, setPageHistory] = useState([]);
  const [showCloseEarlyButton, setShowCloseEarlyButton] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [characterLimit, setCharacterLimit] = useState(280);
  const CLOSE_EARLY_BUTTON_TEXT = "Exit without saving";

  const handleSetTopics = useCallback((newTopics) => {
    setTopics(newTopics);
  }, []);

  const fetchSavedTopics = useCallback(async () => {
    setIsLoading(true);
    const savedTopics = await getSavedTopics();

    // Either there was an error, or the user has no saved topics.
    // In either case, we will generate topics based on the user's input.
    // TODO: Handle error response to prevent overwriting the user's saved topics.
    if (savedTopics === null || savedTopics?.length === 0) {
      setIsLoading(false);
      return;
    }

    handleSetTopics(savedTopics);
    setIsLoading(false);
  }, [handleSetTopics]);

  useEffect(() => {
    // Fetch topics from the API upon first render.
    // If the user has no saved topics, we will present
    // the "Tell us about your target audience" screen
    // and generate topics based on the user's input
    fetchSavedTopics();
  }, [fetchSavedTopics]);

  // Fetch entire posts from the user's post queue.
  const fetchGeneratedPosts = useCallback(async () => {
    setIsGeneratingPosts(true);
    const refreshedPosts = await refreshUserPostQueue(selectedSocials[0]);

    if (refreshedPosts === null || refreshedPosts?.length === 0) {
      return;
    }

    setPosts(refreshedPosts);
    setIsGeneratingPosts(false);
  }, [selectedSocials]);

  const nextPage = useCallback(
    (page) => {
      setPageHistory([...pageHistory, currentContent]);
      setCurrentContent(page);
    },
    [pageHistory, currentContent],
  );

  const handleContinue = useCallback(async () => {
    switch (currentContent) {
      case "socials":
        // No social network selected; do not proceed
        if (selectedSocials.length === 0) {
          // TODO: warning message
          return;
        }

        nextPage("audienceAndTopicSelection");
        break;

      case "audienceAndTopicSelection":
        // No topics selected; do not proceed
        if (!topics?.length) {
          // TODO: warning message
          return;
        }

        // Generate example posts for the user to review / choose from
        fetchGeneratedPosts();

        nextPage("postFrequencySelection");

        break;

      case "postFrequencySelection":
        // No post frequency selected; do not proceed
        // TODO: warning message
        if (!postFrequency) {
          return;
        }

        nextPage("postPreview");

        break;

      case "postPreview":
        // No posts to select from. This is a broken state.
        if (posts?.length === 0) {
          // TODO: handle broken state
          return;
        }

        onClose({ fullClose: true });
        break;

      default:
        break;
    }
  }, [
    currentContent,
    fetchGeneratedPosts,
    nextPage,
    onClose,
    postFrequency,
    posts?.length,
    selectedSocials.length,
    topics,
  ]);

  // If the user selects a social network, we will automatically trigger the topic selection screen.
  useEffect(() => {
    if (selectedSocials.length > 0) {
      if (currentContent === "socials") {
        handleContinue();
      }

      // Set character limits
      switch (selectedSocials[0]) {
        case "twitter":
          setCharacterLimit(CharacterLimits.TWITTER);
          break;
        case "linkedin":
          setCharacterLimit(CharacterLimits.LINKEDIN);
          break;
        default:
          break;
      }
    }
  }, [selectedSocials]);

  // If the user selects a post frequency, we will automatically trigger the post preview screen.
  useEffect(() => {
    if (currentContent === "postFrequencySelection" && postFrequency) {
      handleContinue();
    }
  }, [handleContinue, postFrequency]);

  // Automatically filter posts down whenever we receive a new payload
  useEffect(() => {
    const viablePosts = posts.filter(
      (p) => !p.approved && !p.published && p.source === selectedSocials[0],
    );
    if (viablePosts.length > 0 && viablePosts.length !== posts.length) {
      setPosts(viablePosts);
    }
  }, [posts, selectedSocials]);

  // Update the close early and continue button visibility based on the current content
  useEffect(() => {
    const shouldShowCloseEarlyButton = showCloseEarlyOnPages.includes(currentContent);
    setShowCloseEarlyButton(shouldShowCloseEarlyButton);

    const shouldShowContinueButton = showContinueOnPages.includes(currentContent);
    setShowContinueButton(shouldShowContinueButton);
  }, [currentContent, showCloseEarlyOnPages, showContinueOnPages]);

  const handleBack = useCallback(
    (event) => {
      if (event?.target?.textContent === CLOSE_EARLY_BUTTON_TEXT) {
        onClose({ fullClose: true });
        return;
      }

      if (currentContent === "socials") {
        onClose({ fullClose: false });
        return;
      }

      if (currentContent === "postFrequencySelection") {
        // setTopics([]);
      }

      if (currentContent === "postPreview") {
        setPostFrequency("");
      }

      if (pageHistory.length === 0) {
        // Should never happen, but just in case
        onClose({ fullClose: false });
        return;
      }

      // TODO: Add guards to prevent going back with unsaved work
      const previousContent = pageHistory[pageHistory.length - 1];
      const updatedPageHistory = pageHistory.slice(0, pageHistory.length - 1);

      setPageHistory(updatedPageHistory);
      setCurrentContent(previousContent);
    },
    [currentContent, onClose, pageHistory],
  );

  const renderContent = () => {
    switch (currentContent) {
      case "socials":
        return (
          <Socials
            selectedSocials={selectedSocials}
            setSelectedSocials={setSelectedSocials}
          />
        );
      case "audienceAndTopicSelection":
        return (
          <TopicSelection
            autosave
            initialSelectedTopics={topics}
            selectedTopicsSetter={handleSetTopics}
          />
        );
      case "postFrequencySelection":
        return (
          <PostFrequency
            postFrequencies={PostFrequencyOptions}
            onSelect={(newPostFrequency) => {
              setPostFrequency(newPostFrequency);

              // Handle the case where the user selects a post frequency,
              // goes back, and then selects the same post frequency again.
              if (newPostFrequency === postFrequency) {
                handleContinue();
              }
            }}
          />
        );
      case "postPreview":
        return (
          <PostEdit
            posts={posts}
            setPosts={setPosts}
            characterLimit={characterLimit}
            onApprove={handleContinue}
          />
        );
      default:
        return null;
    }
  };

  const actionButtons = useMemo(() => {
    const buttons = [];
    if (showCloseEarlyButton) {
      buttons.push(
        <PButton onClick={handleBack} pVariant="outlined">
          {CLOSE_EARLY_BUTTON_TEXT}
        </PButton>,
      );
    }

    if (showContinueButton) {
      const isContinueButtonDisabled = isLoading
      || (currentContent === "audienceAndTopicSelection"
      && (topics.length === 0 || topics.length > 10));

      buttons.push(
        <PButton
          onClick={handleContinue}
          pVariant="primary"
          disabled={isContinueButtonDisabled}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Continue"
          )}
        </PButton>,
      );
    }

    return buttons;
  }, [
    showCloseEarlyButton,
    showContinueButton,
    handleBack,
    isLoading,
    currentContent,
    topics.length,
    handleContinue,
  ]);

  return (
    <PModal
      open
      onClose={handleBack}
      loadingNext={
        isLoading || (isGeneratingPosts && currentContent === "postPreview")
      }
      backButton={<PBackButton onClick={handleBack} iconType="back" />}
      actionButtons={actionButtons}
    >
      {renderContent()}
    </PModal>
  );
}

export default memo(BrandingFlow);
