import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import FundingForm from './FundingForm';
import AcquisitionForm from './AcquisitionForm';
import { useTheme } from '@mui/material';

function App() {
  const [formType, setFormType] = useState('funding');
  const theme = useTheme();

  const handleSwitchForm = () => {
    setFormType(formType === 'funding' ? 'acquisition' : 'funding');
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {formType === 'funding' ? <FundingForm /> : <AcquisitionForm />}
        <Button
          variant="contained"
          sx={{
            color: theme.palette.primary.blue,
          }}
          onClick={handleSwitchForm}
        >
          Switch to {formType === 'funding' ? 'Acquisition' : 'Funding'} Form
        </Button>
      </Box>
    </>
  );
}

export default App;
