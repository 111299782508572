import * as React from 'react';
import PropTypes from 'prop-types';
import PBaseControl from './base/PBaseControl';

function PCheckbox({ big, onChange, checked, disabled, indeterminate, hoverOverride }) {
  return (
    <PBaseControl
      rx="5px"
      ry="5px"
      big={big}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      indeterminate={indeterminate}
      hoverOverride={hoverOverride}
    />
  );
}

PCheckbox.propTypes = {
  /** If true, the checkbox will be larger. */
  big: PropTypes.bool,

  /** If true, the checkbox will be disabled. */
  disabled: PropTypes.bool,

  /** If true, the checkbox will be checked.
   * Only one of `checked` or `indeterminate` should be true at any given time.
   * Note: the Storybook control for this has no effect. */
  checked: PropTypes.bool,

  /** If true, the checkbox will be in the indeterminate state.
   * Only one of `checked` or `indeterminate` should be true at any given time. */
  indeterminate: PropTypes.bool,

  /** Callback fired when the state is changed. Make sure to handle the checked state in the parent component. */
  onChange: PropTypes.func,

  /** This is for Storybook. Let the component manage its own hover state. */
  hoverOverride: PropTypes.bool,
};

PCheckbox.defaultProps = {
  big: false,
  checked: false,
  indeterminate: false,
  disabled: false,
  onChange: () => {},
  hoverOverride: false,
};

export default PCheckbox;
