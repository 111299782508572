import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invitees: [],
};

const fakingSlice = createSlice({
  name: 'faking',
  initialState,
  reducers: {
    addInvitee(state, action) {
      state.invitees = [
        ...state.invitees,
        {
          email: action.payload.email,
          permissionLevel: action.payload.permissionLevel,
          delegateUserIds: action.payload.delegateUserIds,
          name: `${action.payload.firstName} ${action.payload.lastName}`,
          assistant: action.payload.assistant,
          csvFile: action.payload.csvFile,
        },
      ];
    },
    setInvitees(state, action) {
      state.invitees = action.payload;
    },
  },
});

export const { addInvitee, setInvitees } = fakingSlice.actions;

export default fakingSlice.reducer;
