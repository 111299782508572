import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';

import ConnectListItem from '../../pages/setup/simpleSetupComponents/connectListItem';
//import CRMResults from './CRMResults';
//import CRMFilters from './CRMFilters';

import { ReactComponent as Salesforce } from '../../assets/logos/Salesforce.svg'
import { ReactComponent as Affinity } from '../../assets/logos/Affinity.svg'
import { ReactComponent as Hubspot } from '../../assets/logos/Hubspot.svg'

import { Providers, ProviderType } from '../../constants/constant'
import { SetCookie, GetCookie } from '../../helper/cookieManager'
import Http from '../../http/httpClient'

export default function ImportCRM(props) {
  const theme = useTheme();
  const { onClose, open, setSelectedItem, listItem, setProviderActiveList } = props;
  const [activeProvider, setActiveProvider] = React.useState(null);
  const [isConnectedAsNew, setIsConnectedAsNew] = React.useState(false);
  const [openFiltersModal, setOpenFiltersModal] = React.useState(false);

  const [providerList, setProviderList] = React.useState([
    {
      title: 'Salesforce',
      logo: <Salesforce />,
      provider: Providers.SALESFORCE,
      connected: null,
      selected: false
    }, {
      title: 'Affinity',
      logo: <Affinity />,
      provider: Providers.AFFINITY,
      connected: null,
      selected: false
    }, {
      title: 'HubSpot',
      logo: <Hubspot />,
      provider: Providers.HUBSPOT,
      connected: null,
      selected: false
    }
  ])

  async function getConnectedState (provider) {
    const luser = GetCookie('user')
    if(luser) {
      const p = luser.providers.find(acc => acc !== null && acc.provider === provider) || []
      if (p && p.profileId) {
        return { connected: true,
          profileId: p.profileId,
          primary: p.primary,
          profileImage: (p && p.imageUrl ) ? p.imageUrl : luser.imageUrl
        }
      }
    }
    return { connected: false, profileId: null, primary: null, profileImage: null }
  }

  const fetchData = async () => {
    const updatedList = await Promise.all(providerList.map(async (item) => {
      const profile = await getConnectedState(item.provider);
      return {
        ...item,
        connected: profile.connected,
        primary: profile.primary,
        profileId: profile.profileId,
        profileImage: profile.profileImage
      };
    }));
    if (setProviderActiveList) setProviderActiveList(updatedList)
    setProviderList(updatedList);
  };

  React.useEffect(() => {
    const value = listItem?.providers?.find(provider => ProviderType.IMPORT_LIST_FILTERS.includes(provider.provider))
    setActiveProvider(value?.provider ?? null)
    fetchData();
  }, []);

  React.useEffect(() => {
    const bc = new BroadcastChannel("setupAuthChannel");
    bc.addEventListener('message', (event) => {
      if (event.data.status === 200) {
        fetchData()
        setIsConnectedAsNew(true)
      }
    })

    return () => {
      bc.removeEventListener('message', () => {});
      bc.close();
    };
  }, [])

  const handleSelectedProvider = (item) => {
    if (item.connected) {
      setActiveProvider(item.provider);
      setSelectedItem((prev) => {
      const providers = (prev.providers || []).slice()
      const index = providers.findIndex(provider => provider.providerType === 'crm')

      if (index !== -1) {
        providers[index] = {
          ...providers[index],
          provider: item.provider,
          isExpanded: false,
          enrichmentCompleted: !isConnectedAsNew
        }
      }

      return {
        ...prev,
        providers: providers,
        provider: item.provider
      }});
    }
    if (setProviderActiveList) setProviderActiveList(providerList)
  }

  const getContainerStyles = (provider) => ({
    boxShadow: 0,
    fontSize: '14px',
    borderRadius: 4,
    width: '25vw',
    padding: '0px 14px 14px 0px ',
    backgroundColor: activeProvider === provider ? theme.palette.primary.lightBlue : theme.palette.primary.white,
    color: activeProvider === provider ? theme.palette.primary.white : theme.palette.primary.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  });

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 1, lineHeight: 'normal' }}>
            Import your CRM
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5 }}>
            This will help you identify your most important connections.
          </Typography>

          <Grid container spacing={2}>
            {providerList.map((provider) => (
              <Grid item xs={12} lg={4} xl={3}
                key={`${provider.title}${provider.connected}${new Date().toString()}`}
                onClick={() => handleSelectedProvider(provider)}
                sx={getContainerStyles(provider.provider)}>
                <ConnectListItem 
                setActiveProvider={setActiveProvider}
                currProvider={provider} 
                source={'setupAuth'} 
                setProviderList={setProviderList}/>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={3} />
      </Grid>

      {/*openFiltersModal && (
        <CRMFilters
          open={openFiltersModal}
          onClose={() => {
            setOpenFiltersModal(false)
            onClose();
          }}
          listItem={listItem}
          setListItem={setListItem}
          handleListCreation={handleListCreation}
        />
      )*/}
    </>
  );
}