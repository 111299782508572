import * as React from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import PTypography from './PTypography';
import { styled, useTheme } from '@mui/material/styles';

const getVariantStyles = (theme, pVariant) => {
    let colorScheme = {};

    switch (pVariant) {
        case 'primary':
            colorScheme = {
                text: theme.palette.primaryCL.Black200,
                header: theme.palette.primaryCL.Black100,
                divider: theme.palette.primaryCL.Black70,
            };
            break;

        // Add more cases for 'outlined', 'plain', etc.
    }

    return colorScheme;
};

const capitalize = (str) => {
    if (typeof str !== 'string' || str.length === 0) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const PTable = ({ data, columnAlignments, pVariant }) => {
    const theme = useTheme();
    const variantStyles = getVariantStyles(theme, pVariant);

    if (!data || data.length === 0) {
        return <div>No data available</div>;
    }

    const headers = Object.keys(data[0]);

    // Function to get alignment for a given header
    const getAlignment = (header) => {
        return columnAlignments && columnAlignments[header] ? columnAlignments[header] : 'inherit';
    };

    return (
        <TableContainer sx={{ width: '100%' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableCell key={index} align={getAlignment(header)}>
                                <div style={{ paddingTop: '4px', paddingBottom: '4px', paddingLeft: '24px', paddingRight: '24px' }}>
                                    <PTypography size="body2" weight="regular" color={variantStyles.header}>
                                        {capitalize(header)}
                                    </PTypography>
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {headers.map((header, headerIndex) => (
                                <TableCell key={headerIndex} align={getAlignment(header)}>
                                    <div style={{ paddingTop: '4px', paddingBottom: '4px', paddingLeft: '24px', paddingRight: '24px' }}>
                                        <PTypography size="body1" weight="regular" color={variantStyles.text}>
                                            {typeof row[header] === 'string' ? capitalize(row[header]) : row[header]}
                                        </PTypography>
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

PTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columnAlignments: PropTypes.object
};

PTable.defaultProps = {
    columnAlignments: {}
};

export default PTable;