import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'

import Http from '../../../http/httpClient'
import { handleAuthentication } from "../../../helper/authUtility";

export default function GoogleScope () {
  const [isLoading, setIsLoading] = React.useState(false)

  const addScope = (event) => {
    event.preventDefault()
    handleAuthentication("/api/auth/google?via=googleScope");
  }

  React.useEffect(() => {
    const bc = new BroadcastChannel("googleScopeChannel");
    bc.addEventListener('message', (event) => {
      if (event.data.status === 200) {
        console.log("googleScope inclusion done successfully.")
      }
    })

    return () => {
      bc.removeEventListener('message', () => {});
      bc.close();
    };
  }, [])

  return (
    <>
      <Box display='flex' alignItems='center' gap={2} flexDirection='row'>
        <Button
          sx={{
            mt: 3,
            ml: 1,
            px: 3,
            my: 2,
            backgroundColor: '#C4C4C4',
            color: '#000000',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            textTransform: 'none',
            fontFamily: 'Poppins'
          }}
          onClick={addScope}
        >
          Add scope
        </Button>
      </Box>
      <Box display='flex' justifyContent='center' flexDirection='column' sx={{ mx: 1 }} >
        <FormHelperText sx={{ color: 'blue' }}>* This scope is used to verify the triggered email delivered to the end user or not.</FormHelperText>
      </Box>
    </>
  )
}
