import * as React from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import Http from '../../../http/httpClient'

import { ReactComponent as Check } from '../../../assets/Icons/Check.svg'
import SocialMediaModal from './socialMediaModal'
import BioModal from './bioModal'
import CompanyWebsiteModal from './companyWebsiteModal'
import ChooseGoalModal from './chooseGoalModal';
import SequenceModal from './Sequences/sequenceModal';
import ListModal from './listModal';
import BrandModal from './Brands/brandModal';

// setFeatureFlags from authSlice
import { setFeatureFlags } from '../../../redux/authSlice'
import featureFlagService from '../../../services/featureFlagService';
import { getDefaultStrategies, getDefaultTemplates } from '../../../helper/apiHelper'


export default function SetupSteps({ setupList, setFetchedSteps }) {
    const theme = useTheme()
    const [bioOpen, setBioOpen] = React.useState(false)
    const [websiteOpen, seWebsiteOpen] = React.useState(false)
    const [socialOpen, setSocialOpen] = React.useState(false)
    const [goalOpen, setGoalOpen] = React.useState(false)
    const [listOpen, setListOpen] = React.useState(false)
    const [selectedGoal, setSelectedGoal] = React.useState()
    const [sequenceOpen, setSequenceOpen] = React.useState(false)
    const [brandOpen, setBrandOpen] = React.useState(false)
    const [createdListId, setCreatedListId] = React.useState(null);
    const dispatch = useDispatch();
    const [featureFlags, setFeatureFlagsLocal] = React.useState(null);
    const [timeoutId, setTimeoutId] = React.useState(null);
    const [strategies, setStrategies] = React.useState([])
    const [targetAudience, setTargetAudience] = React.useState({})
    const [defaultTemplates, setDefaultTemplates] = React.useState([])

    const closeModalWithDelay = (closeAction, delay = 300) => {
        if (timeoutId) clearTimeout(timeoutId);

        const newTimeoutId = setTimeout(() => {
            closeAction();
        }, delay);

        setTimeoutId(newTimeoutId);
    };

    const fetchStrategy = async () => {
        const response = await Http.getData('setup/getBio');
        setSelectedGoal(response.bio.strategy);
        return response.bio.strategy;
    };

    const setModal = async (step = null) => {
        if (step === 'List') {
            setListOpen(true);
        } else if (step === 'Details') {
            // setBioOpen(true);
            seWebsiteOpen(true)
        }
        else if (step === 'Social') {
            setSocialOpen(true);
        }
        else if (step === 'Strategy') {
            setGoalOpen(true);
        }
        else if (step === 'Sequence') {
            let strategy = selectedGoal?.goal?.id;
            if (!strategy) {
                const fetchedStrategy = await fetchStrategy();
                strategy = fetchedStrategy;
            }

            console.log(strategy);

            if (strategy === 'grow') {
                setBrandOpen(true);
            } else {
                setSequenceOpen(true);
            }
        }
    }

    const forwardToNextStep = (currentStep, allSteps) => {
        const currentStepIndex = allSteps.findIndex(s => s.id === currentStep);
        
        if (currentStepIndex !== -1) {
            const nextUncompletedStep = allSteps.slice(currentStepIndex + 1).find(step => !step.complete);
        
            if (nextUncompletedStep) {
            console.log(`Forwarding to the next uncompleted step: ${nextUncompletedStep.step}`);
                switch (nextUncompletedStep.step) {
                    case 'Details':
                        setBioOpen(true);
                        break;
                    case 'Social':   
                        setSocialOpen(true);
                        break;
                    case 'Strategy':
                        setGoalOpen(true);
                        break;
                    case 'List':
                        setListOpen(true);
                        break;
                    case 'Sequence':
                        setSequenceOpen(true)
                        break;
                    default:
                        break;
                }
            } else {
              console.log("All steps are completed.");
            }
        }
    };

    const closeBio = async (fromBack = false) => {
        if(!fromBack) { forwardToNextStep("addBioDone", setupList) }
        closeModalWithDelay(() => {
            setBioOpen(false);
            setFetchedSteps(false);
        });
    }

    const closeWebsite = async () => {
        setBioOpen(true)
        closeModalWithDelay(() => {
            seWebsiteOpen(false);
            setFetchedSteps(false);
        });
    }

    const closeSocial = async () => {
        setGoalOpen(true)
        closeModalWithDelay(() => {
            setSocialOpen(false);
            setFetchedSteps(false);
        });
    }

    const closeGoal = async (fromBack = false) => {
        if(!fromBack) { setListOpen(true) }
        closeModalWithDelay(() => {
            setGoalOpen(false)
            setFetchedSteps(false)
        });
    }

    const closeList = async (fromBack = false) => {
        if (!fromBack) {
            setSequenceOpen(true)
        }

        closeModalWithDelay(() => {
            setListOpen(false)
            setFetchedSteps(false)
        });
    }

    const closeSequence = async () => {
        let strategy = selectedGoal?.goal?.id;
        if (!strategy) {
            const fetchedStrategy = await fetchStrategy();
            strategy = fetchedStrategy;
        }

        if (strategy === 'grow') {
            setBrandOpen(true);
        } else {
            setSequenceOpen(true);
        }
        closeModalWithDelay(() => {
            setSequenceOpen(false)
            setFetchedSteps(false)
        });
    }

    const closeBrand = () => {
        setBrandOpen(false)
        setFetchedSteps(false)
    }

    const fetchDefaultTemplates = () => {
        getDefaultTemplates()
          .then(function (response) {
            if (response && response.status) {
              setDefaultTemplates([...response.templates])
            }
          })
          .catch(function (error) {
            console.error(error);
          })
      }

      const fetchStrategies = async () => {
        getDefaultStrategies()
          .then((response) => {
            if (response && response.status) {
              setStrategies([...response.strategies])
              setTargetAudience(response.targetAudience)
            }
          })
          .catch((error) => {
            console.error('Error : ', error)
          })
      }

    React.useEffect(() => {
        const fetchFeatureFlags = async () => {
            try {
                const featureFlags = await featureFlagService.getFeatureFlags();
                setFeatureFlagsLocal(featureFlags);
            } catch (error) {
                console.error('Failed to get feature flags:', error);
            }
        };
        fetchFeatureFlags();
        fetchDefaultTemplates();
        fetchStrategies()
    }, [])

    React.useEffect(() => {
        if (featureFlags) {
            dispatch(setFeatureFlags(featureFlags))
        }
    }, [featureFlags])

    React.useEffect(() => {
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [timeoutId]);

    const handleContinueToBio = () => {
        seWebsiteOpen(false);
        setBioOpen(true);
    }
    
    return (
        <>
            {websiteOpen  && <CompanyWebsiteModal open={websiteOpen} onContinue={handleContinueToBio} onClose={closeWebsite} setupList={setupList} />}
            {bioOpen && <BioModal open={bioOpen} onClose={closeBio} setupList={setupList} />}
            <BrandModal open={brandOpen} onClose={closeBrand} source="brand" />
            <SocialMediaModal open={socialOpen} onClose={closeSocial} setupList={setupList} />
            {goalOpen && <ChooseGoalModal open={goalOpen} onClose={closeGoal} setSelectedGoal={setSelectedGoal} setupList={setupList} templates={defaultTemplates} defaultStrategies={strategies} />}
            {listOpen && <ListModal open={listOpen} onClose={closeList} selectedGoal={selectedGoal} setCreatedListId={setCreatedListId} setupList={setupList} targetAudience={targetAudience}/> }
            {sequenceOpen && <SequenceModal open={sequenceOpen} onClose={closeSequence} selectedGoal={selectedGoal} createdListId={createdListId} setupList={setupList} />}
            <Box
                sx={{
                    textAlign: {
                        xs: "center",
                        md: "left",
                    }
                }}
            >
                <Typography
                    component='h2'
                    sx={{
                        fontWeight: 700,
                        fontSize: '35px',
                        color: theme.palette.primary.black,
                        fontFamily: 'Inter',
                        display: {
                            xs: 'none',
                            md: 'inline-block',
                        }
                    }}
                >
                    Hello, and welcome to Postilize!
                </Typography>
                <Typography
                    component='h2'
                    sx={{
                        fontWeight: 700,
                        fontSize: '35px',
                        color: theme.palette.primary.black,
                        fontFamily: 'Inter',
                        align: 'center',
                        display: {
                            xs: 'inline-block',
                            md: 'none',
                        }
                    }}
                >
                    Welcome to Postilize!
                </Typography>
            </Box>

            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    mb: 2,
                    color: theme.palette.primary.grey,
                    fontFamily: 'Inter',
                    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                        px: 2,
                    }
                }}
            >
                Follow these steps to unlock the full power of your connections
            </Typography>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                {setupList.map((item) => (
                    <Container
                        sx={{
                            backgroundColor: item.active
                                ? theme.palette.primary.lightGrey
                                : theme.palette.primary.white,
                            py: 3,
                            my: 1,
                            borderRadius: 3,
                            width: '100%',
                            border: item.active ? `1px solid ${theme.palette.primary.blue}` : 'none',
                        }}
                        key={item.index}
                    >
                        <Grid container alignItems='center' justifyContent='space-between'>
                            <Grid container item xs={12} md={6} spacing={3} alignItems='center'>
                                <Grid item xs={2}>
                                    <Avatar sx={{ fontSize: '12px', fontWeight: 500, bgcolor: item.active ? theme.palette.primary.white : theme.palette.primary.lightGrey, color: item.active ? theme.palette.primary.black : theme.palette.primary.grey }}>
                                        {item.index}
                                    </Avatar>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography sx={{ fontSize: '14px', color: theme.palette.primary.black, fontWeight: item.active ? 700 : 500, fontFamily: 'Inter' }}>
                                        {item.title}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={4} container justifyContent='flex-end'>
                                {item.complete
                                    ? <Avatar sx={{ bgcolor: theme.palette.primary.white, borderColor: theme.palette.primary.blue, borderWidth: 1, borderStyle: 'solid' }}>
                                        <Check fill={theme.palette.primary.blue} />
                                    </Avatar>
                                    : item.buttonText
                                        ? <Button
                                            onClick={() => setModal(item.step)}
                                            disabled={item.active ? false : true}
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                backgroundColor: theme.palette.primary.blue,
                                                color: theme.palette.primary.white,
                                                textTransform: 'none',
                                                width: '100%',
                                                py: 1,
                                                px: 2,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                borderRadius: 2,
                                                boxShadow: item.active ? '0px 4px 8px 0px #2C64E31F' : 'none',
                                                '&:hover': {
                                                    backgroundColor: item.active ? theme.palette.primary.blue : theme.palette.primary.lightBlue,
                                                    color: item.active ? theme.palette.primary.white : theme.palette.primary.blue
                                                },
                                                '&:disabled': {
                                                    color: theme.palette.primary.white,
                                                    opacity: 0.64,
                                                },
                                                [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                                                    mt: 2,
                                                },
                                                [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
                                                    maxWidth: '200px',
                                                },
                                            }}
                                        >
                                            {item.buttonText}
                                        </Button>
                                        : null}
                            </Grid>
                        </Grid>
                    </Container>
                ))}
            </div>
        </>
    );
}
