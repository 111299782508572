import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReactComponent as Delete } from "../../../assets/Icons/Delete.svg";

export default function TOSModal({ open, close }) {
  const theme = useTheme();
  return (
    <Dialog onClose={close} open={open} fullScreen>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <IconButton aria-label="close" onClick={close}>
              <Delete fill={theme.palette.primary.grey} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={3} />
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 700,
                color: theme.palette.primary.black,
                pt: 5,
              }}
            >
              Postilize Terms of Service
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: theme.palette.primary.grey,
                pb: 5,
              }}
            >
              Last updated: July 17, 2023
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Please read these terms of service ("Terms") carefully before
              using the Postilize website, API, and services (together, the
              "Service").
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              By accessing or using the Service, you signify that you have read,
              understood, and agree to be bound by these Terms. If you do not
              agree with these Terms, you are not granted permission to access
              or otherwise use the Service.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              1. Overview
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Postilize provides a service to automate and assist with
              generating social media content. Users can connect their social
              media accounts and CRM/marketing automation systems to Postilize,
              which will then generate content such as posts, comments, and
              messages based on the user's contacts, interests, and prior posts.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              2. Eligibilty
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              You must be 18 years of age or older to use Postilize. Postilize
              reserves the right to modify eligibility criteria at any time. We
              currently offer paid plans for using Postilize. We may introduce
              free plans in the future.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              3. Functionality
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Postilize generates text and image-based content. The number of
              posts generated per day depends on your subscription plan.
              Postilize does not guarantee any specific social media or
              marketing results from the use of our Service.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              4. Customer Responsibilities
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              You agree to comply with all applicable laws, rules, and
              regulations in your use of the Service. You are responsible for
              the content generated by Postilize on your behalf. You should
              review all content before publishing and not publish illegal,
              dangerous, or offensive material.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              5. Privacy
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Our Privacy Policy explains how we collect and use your data. We
              will retain your data for up to 3 months after cancellation to
              allow you to reactivate your account. You can request deletion
              sooner.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              6. Warranty Disclaimer
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We provide Postilize "as is" without warranty of any kind. We
              disclaim all warranties express or implied including warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              7. Limitation of Liability
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Postilize will not be liable for any indirect, incidental,
              special, consequential or exemplary damages arising from use of
              the Service. Our liability for damages will not exceed the amount
              paid by you to Postilize in the prior 12 months.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              8. Modifications
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may modify these Terms from time to time and will post updated
              versions on our website. Modified Terms become effective upon
              posting.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              9. Governing Law
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              These Terms are governed by the laws of the State of Florida
              without regard to conflict of law principles.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              10. Arbitration
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Any dispute arising from these Terms will be resolved through
              binding arbitration performed in Miami-Dade County, Florida.
              Arbitration will be conducted under the rules of the American
              Arbitration Association.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              11. Suspension; Termination
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may suspend or terminate your access to the Service if we
              reasonably believe you have violated these Terms. You may
              terminate your access at any time. If we are unable to provide the
              Service due to factors beyond our control for more than 1% of any
              monthly billing period, you will be entitled to a prorated refund
              upon request.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              12. Refunds
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              For monthly subscriptions, you may request a refund within 7 days
              of initial signup. Beyond 7 days, all fees are non-refundable. We
              will not bill you the following month if you cancel your
              subscription.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              For annual subscriptions, all fees are non-refundable after the
              initial 30 day period following signup. If you cancel an annual
              subscription, we will not bill you for the next annual term.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              13. Modifications
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may modify these Terms at any time by posting revised Terms on
              our website. Your continued use of Postilize after posted changes
              to the Terms indicates your acceptance of the revised Terms.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              14. Contact Us
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you have any questions about these Terms, please contact us at legal@postilize.com.
            </Typography>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
