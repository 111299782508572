import * as React from 'react';
import Menu from '@mui/material/Menu';
import PPopoverMenuItem from '@/components/library/PPopoverMenuItem';
import { Divider, Stack } from '@mui/material';
import useOutsideClick from '@Hooks/useOutsideClick';
import PropTypes from 'prop-types';
import PTypography from '@/components/library/PTypography';
import { Box } from '@mui/system';

function PPopoverMenu({ menu, anchorEl, anchorOrigin, dimensions, closeWhenClicked, isOpen, disableSelection }) {
  const { menuList, headerText, subheaderText, onClose, onClick, checkType } = menu;
  const menuRef = React.useRef(null);
  useOutsideClick(menuRef, onClose);

  const targetDimensions = {
    ...dimensions,
    ...menu?.overrideDimensions,
  };

  return (
    isOpen && (
      <Menu
        ref={menuRef}
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        onClick={(event) => {
          if (onClick) {
            onClick(event);
          }
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: '14px',
              maxHeight: targetDimensions.maxHeight,
              minWidth: targetDimensions.minWidth,
              maxWidth: targetDimensions.maxWidth,
              width: targetDimensions.width || 'auto',
              overflowY: 'auto',
              padding: '16px',
            },
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={anchorOrigin}
      >
        {headerText && (
          <>
            <Stack spacing={'8px'} sx={{ padding: '8px 16px', fontWeight: 'bold', marginBottom: '24px' }}>
              <PTypography size="h1" weight="bold">
                {headerText}
              </PTypography>
              <PTypography size="body1" weight="regular">
                {subheaderText}
              </PTypography>
            </Stack>
            {/* 
            <Divider
              sx={{
                // negate parent padding's effect on width:
                width: 'calc(100% + 32px)',

                // offset the divider's x location to override parent padding:
                marginLeft: '-16px',
                marginRight: '-16px',

                // make the divider extra thicc:
                borderBottomWidth: '2px',

                display: 'flex',
                marginBottom: '16px',
                marginTop: '16px',
              }}
            /> */}
          </>
        )}
        {menuList &&
          menuList?.map((item, index) => (
            // Vertically stacked items:
            <Stack key={`${item.label}-${index}`} direction="column">
              <Box
                onClick={() => {
                  if (closeWhenClicked) {
                    onClose();
                  }
                }}
              >
                <PPopoverMenuItem item={item} checkType={disableSelection ? 'none' : checkType} />
              </Box>
              {index < menuList.length - 1 && (
                <Divider
                  orientation="horizontal"
                  sx={{ width: '100%', display: 'flex', height: 1, marginTop: '4px', marginBottom: '4px' }}
                />
              )}
            </Stack>
          ))}
      </Menu>
    )
  );
}

PPopoverMenu.propTypes = {
  menu: PropTypes.shape({
    menuList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onSubmit: PropTypes.func,
        isSelected: PropTypes.bool,
        submenu: PropTypes.shape({
          menuList: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string.isRequired,
              onSubmit: PropTypes.func,
              isSelected: PropTypes.bool,
            }),
          ),
          headerText: PropTypes.string,
          isOpen: PropTypes.bool.isRequired,
          onClose: PropTypes.func.isRequired,
          onClick: PropTypes.func,
        }),
      }),
    ).isRequired,
    headerText: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    overrideDimensions: PropTypes.shape({
      minHeight: PropTypes.number,
      maxHeight: PropTypes.number,
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  anchorEl: PropTypes.object,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  dimensions: PropTypes.shape({
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    width: PropTypes.number,
  }),
  closeWhenClicked: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
};

PPopoverMenu.defaultProps = {
  menu: {
    overrideDimensions: {},
  },
  anchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  dimensions: {
    minHeight: 30,
    height: 'auto',
    maxHeight: 300,
    minWidth: 259,
    width: 259, // Added default width
    maxWidth: 259,
  },
  closeWhenClicked: false,
};

export default PPopoverMenu;