import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import { combineReducers } from 'redux'
import authReducer from './authSlice'
import fakingReducer from './fakingSlice'
import sequenceReviewReducer from './sequenceReviewSlice'

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['selectedDelegation', 'user'],
};

const reducers = combineReducers({
  auth: authReducer,
  faking: fakingReducer,
  sequenceReview: sequenceReviewReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
