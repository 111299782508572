import Http from '../http/httpClient';

/**
 * This service provides methods for posting acquisition data.
 * await acquisitionService.postAcquisitionData(fileName);
 **/
const postAcquisitionData = async (fileName) => {
  try {
    const response = await Http.postData('acquisition/processCsv', {
      fileName,
    });
    if (response && response.status) {
      return response;
    }
    throw new Error('Unexpected response from acquisition/postAcquisitionData');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postFundingData = async (fileName) => {
  try {
    const response = await Http.postData('funding/processCsv', {
      fileName,
    });
    if (response && response.status) {
      return response;
    }
    throw new Error('Unexpected response from acquisition/postFundingData');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// dateRange is an object with startDate and endDate properties
// Example: { startDate: '2021-01-01', endDate: '2021-01-31' }
const processFundzData = async (dateRange) => {
  try {
    const response = await Http.postData('processFundz', {
      dateRange,
    });
    console.log('fundzService.js: processFundzData response:', response);
    if (response && response.status) {
      return response;
    }
    throw new Error('Unexpected response from acquisition/processFundzData');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {
  postAcquisitionData,
  postFundingData,
  processFundzData,
};
