import * as React from 'react'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import GroupIcon from '@mui/icons-material/Group'
import SettingsIcon from '@mui/icons-material/Settings'
import CircularProgress from '@mui/material/CircularProgress'

import AddCompanyModal from './addCompanyModal'
import UserDetailsModal from './userDetailsModal'
import CompanyPreferenceModal from './companyPreference'
import CustomModal from '../../../pages/login/components/confirmModal'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function CompanyItem ({ company, handleCloseModal, handleDeleteCompany, buttonLoad, featureFlags }) {
  const [editOpen, setEditOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const [alertText, setAlertText] = React.useState('')
  const [alertResponse, setAlertResponse] = React.useState(null)
  const [deletionInProgress, setDeletionInProgress] = React.useState(false)
  const [userViewOpen, setUserViewOpen] = React.useState(false)
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = React.useState(false)
  const [preferenceOpen, setPreferenceOpen] = React.useState(false)

  const handleOpenEditModal = () => {
    setEditOpen(true)
  }

  const handleEditModalClose = (companyDetails) => {
    handleCloseModal(companyDetails)
    setEditOpen(false)
  }

  const handleSnackClose = () => {
    setOpen(false)
  }

  const handleDeleteModal = () => {
    let alertPrompt = `The deletion of the company '${company.name}' will result in the removal of all associated data. Press 'OK' if you agree.`
    setAlertText(alertPrompt)
    setDeleteOpen(true)
  }

  const handleModalAction = (action) => {
    setAlertResponse(action)
    setDeleteOpen(false)
  }

  React.useEffect(() => {
    if (alertResponse === 'OK') {
      setDeletionInProgress(true)
      handleDeleteCompany(company)
    } else if (alertResponse === 'Cancel') {
      setAlertResponse('')
    }
  }, [alertResponse])

  React.useEffect(() => {
    if (!buttonLoad) {
      setDeletionInProgress(buttonLoad)
    }
  }, [buttonLoad])

  const handleUserViewModal = () => {
    setUserViewOpen(true)
    setIsUserDetailsModalOpen(true)
  }

  const handleUserViewClose = () => {
    setUserViewOpen(false)
  }

  const handlePreferences = () => {
    setPreferenceOpen(true)
  }

  const handlePreferenceModalClose = (companyDetails) => {
    if (companyDetails) {
      handleCloseModal(companyDetails)
    }
    setPreferenceOpen(false)
  }

  return (
    <>
      <StyledTableRow key={'10'}>
        <StyledTableCell component='th' scope='row'>{company.name}</StyledTableCell>
        <StyledTableCell>{company.emailDomains}</StyledTableCell>
        <StyledTableCell>{company.websiteUrl}</StyledTableCell>
        <StyledTableCell>{company.connectionsCount}</StyledTableCell>
        <StyledTableCell>
          <PersonAddIcon sx={{ '&:hover': { cursor: 'pointer' }, marginRight: '4px' }} onClick={handleOpenEditModal} />
          <AddCompanyModal open={editOpen} onClose={handleEditModalClose} loading={false} company={company} featureFlags={featureFlags} />
          {(buttonLoad && deletionInProgress) ? (
            <CircularProgress size={24} />
          ) : (
            <DeleteIcon sx={{ '&:hover': { cursor: 'pointer' }, marginRight: '4px' }} onClick={handleDeleteModal} />
          )}
          {deleteOpen && <CustomModal clickAction={handleModalAction} alertText={alertText} />}
          <GroupIcon sx={{ '&:hover': { cursor: 'pointer' }, marginRight: '4px' }} onClick={handleUserViewModal} />
          {userViewOpen && <UserDetailsModal open={isUserDetailsModalOpen} onClose={handleUserViewClose} company={company}/>}
          <SettingsIcon sx={{ '&:hover': { cursor: 'pointer' }, marginRight: '4px' }} onClick={handlePreferences} />
          {preferenceOpen && <CompanyPreferenceModal open={preferenceOpen} onClose={handlePreferenceModalClose} company={company} />}
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}
