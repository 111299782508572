import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import GifBoxIcon from '@mui/icons-material/GifBox'
import ImageIcon from '@mui/icons-material/Image'
import ImageUploading from 'react-images-uploading'
import Fab from '@mui/material/Fab'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import EditIcon from '@mui/icons-material/Edit'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import CircularProgress from '@mui/material/CircularProgress'

export default function EditModal({ open, onClose, ori_post, preSignedURL, loading }) {
  let avatar = ori_post.ImageUrl
  let name = ori_post.name
  let date = ori_post.created_at
  let orgContent = ori_post.message

  const maxNumber = 1
  const maxFileSize = 5 * 1024 * 1024 // 5 MB
  const gifFileType = ["gif"]
  const imageFileType = ["jpg", "png"]
  const controlType = Object.freeze({ IMAGE: 0, GIF: 1, RESET: 2 })
  const resetElementRef = React.useRef()
  const imageElementRef = React.useRef()

  const [edittedText, setedittedText] = React.useState(orgContent)
  const [acceptType, setAcceptType] = React.useState(imageFileType)
  const [navValue, setNavValue] = React.useState(-1)
  const [images, setImages] = React.useState([])
  const [showOrgImage, setShowOrgImage] = React.useState(true)

  const handleEdittedTextChange = (event) => {
    setedittedText(event.target.value)
  }

  const onClickCancel = (event) => {
    resetElementRef.current.click()
    onClose(event)
  }

  const onImageDataChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
  }

  const toggleControlFields = (control) => {
    setAcceptType(control === controlType.GIF ? gifFileType : imageFileType)
  }

  const getError = (errors) => {
    if (errors.maxNumber)
      return 'Number of selected images exceeded the limit.'
    else if (errors.acceptType)
      return 'The selected file type is not supported.'
    else if (errors.maxFileSize)
      return 'Selected file size exceeded maxFileSize of 5 MB.'
    else if (errors.resolution)
      return 'Selected file is not match your desired resolution.'
    return ''
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth="true"
      PaperProps={{ style: { borderRadius: 15 } }}>

      <DialogTitle>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <Avatar sx={{ mx: 1 }} src={avatar} />
          </Grid>
          <Grid item xs={11}>
            <Box display='flex' flexDirection='row' alignItems='center' sx={{ my: 1 }}>

              <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mx: 1 }}>
                {name}
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#566370' }}>
                &bull;
              </Typography>
              <Typography sx={{ fontSize: '16px', mx: 1, color: '#566370' }}>
                {date}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={onClickCancel}><ClearIcon /></IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={11}>
            <TextField
              multiline
              rows={4}
              defaultValue={orgContent}
              onChange={handleEdittedTextChange}
              fullWidth
              inputProps={{ maxLength: 280 }}
            />
            <Box sx={{ mt: 1 }}>
              <Box sx={{ position: 'relative', display: showOrgImage ? '' : 'none' }}>
                <Box
                  component="img"
                  sx={{
                    minHeight: 300,
                    minWidth: 400,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    position: "relative",
                    borderRadius: 3 }}
                  alt="imagepost"
                  src={preSignedURL}
                />
                <Fab size="medium"
                  aria-label="remove"
                  sx={{ color: 'black', background: 'white', position: 'absolute', left: '335px', top: '15px' }}
                  onClick={() => {setShowOrgImage(false)}}
                >
                  <HighlightOffIcon fontSize="large" sx={{ '&:hover': { color: '#3983F7'} }} />
                </Fab>
                <Fab
                  size="medium"
                  aria-label="edit"
                  sx={{ color: 'black', background: 'white', position: 'absolute', left: '335px', bottom: '15px' }}
                  onClick={() => {
                    imageElementRef.current.click()
                    setShowOrgImage(false)}}
                >
                  <EditIcon fontSize="medium" sx={{ '&:hover': { color: '#3983F7'} }} />
                </Fab>
              </Box>
              <ImageUploading
                multiple={false}
                value={images}
                onChange={onImageDataChange}
                maxNumber={maxNumber}
                maxFileSize={maxFileSize}
                dataURLKey="data_url"
                acceptType={acceptType}
                sx={{ ml: 1, display: showOrgImage ? 'none' : '' }}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                  errors
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    {errors &&
                      <Typography
                        component='h6'
                        sx={{
                          ml: 1,
                          pt: 1,
                          fontFamily: 'Poppins',
                          color: '#D32F2F'
                        }}
                      >{getError(errors)}
                      </Typography>
                    }
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <Box sx={{ position: 'relative' }}>
                          <Box
                            component="img"
                            sx={{
                              minHeight: 300,
                              minWidth: 400,
                              maxHeight: { xs: 233, md: 167 },
                              maxWidth: { xs: 350, md: 250 },
                              position: "relative",
                              borderRadius: 3
                            }}
                            alt=""
                            src={image.data_url}
                          />
                          <Fab size="medium"
                            aria-label="remove"
                            sx={{ color: 'black', background: 'white', position: 'absolute', left: '335px', top: '15px' }}
                            onClick={() => {
                              onImageRemove(index)
                              setNavValue(-1)
                            }}
                          >
                            <HighlightOffIcon fontSize="large" sx={{ '&:hover': { color: '#3983F7'} }} />
                          </Fab>
                          <Fab
                            size="medium"
                            aria-label="edit"
                            sx={{ color: 'black', background: 'white', position: 'absolute', left: '335px', bottom: '15px' }}
                            onClick={() => onImageUpdate(index)}
                          >
                            <EditIcon fontSize="medium" sx={{ '&:hover': { color: '#3983F7'} }} />
                          </Fab>
                        </Box>
                      </div>
                    ))}
                    <Box sx={{ width: 300, mt: 1 }}>
                      <BottomNavigation
                        showLabels
                        value={navValue}
                        onChange={(event, newValue) => {
                          setNavValue(newValue)
                          toggleControlFields(newValue)
                        }}
                      >
                        <BottomNavigationAction
                          sx={{ border: '1px solid #6b95d6', borderRadius: 3, ml: 1 }}
                          label="image"
                          icon={<ImageIcon />}
                          ref={imageElementRef}
                          onClick={(event) => {
                            setShowOrgImage(false)
                            onImageRemoveAll()
                            onImageUpload(event)
                          }}
                          {...dragProps}
                        />
                        <BottomNavigationAction
                          sx={{ border: '1px solid #6b95d6', borderRadius: 3, ml: 1 }}
                          label="gif"
                          icon={<GifBoxIcon />}
                          onClick={(event) => {
                            setShowOrgImage(false)
                            onImageRemoveAll()
                            onImageUpload(event)
                          }}
                          {...dragProps}
                        />
                        <BottomNavigationAction
                          sx={{ border: '1px solid #fc9797', borderRadius: 3, ml: 3 }}
                          label="reset"
                          icon={<SettingsBackupRestoreIcon />}
                          ref={resetElementRef}
                          onClick={(event) => {
                            onImageRemoveAll()
                            setNavValue(-1)
                            setShowOrgImage(true)
                            setedittedText(orgContent)
                          }}
                        />
                      </BottomNavigation>
                    </Box>
                  </div>
                )}
              </ImageUploading>
            </Box>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClickCancel}
          sx={{
            mt: 3,
            ml: 1,
            my: 1,
            backgroundColor: '#EEEEEE',
            color: '#09092E',
            fontSize: '1.2rem',
            textTransform: 'none',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => {
            if (edittedText.length === 0) {
              alert("The post should contain the description. Please, give some text about that.")
              event.preventDefault()
              event.stopPropagation()
            } else {
              onClose(event, {post: ori_post, textToUpdate: edittedText, orgImageExist: showOrgImage, updatedImageList: images})
            }
          }}
          sx={{
            ml: 1,
            my: 1,
            mr: 3,
            backgroundColor: '#3983F7',
            color: 'white',
            fontSize: '1.2rem',
            textTransform: 'none'
          }}
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          {loading ? (
            <React.Fragment>
              <span style={{ color: 'white' }}>Saving...</span>
            </React.Fragment>
          ) : (
            'Save'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}