import * as React from 'react'

import SimpleSetup from '../pages/setup/simpleSetup'


export default function Setup () {


  return (
      <SimpleSetup />
  )
}
