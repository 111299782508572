// Usages.js
import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import FormHelperText from '@mui/material/FormHelperText'
import Http from '../../../http/httpClient'

export default function Usages () {
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState('')
  const [users, setUsers] = React.useState([])
  const [usageCount, setUsageCount] = React.useState(0)

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value)
    setUsageCount(0)
    fetchUsageCount(event.target.value)
  }

  const fetchUsageCount = (userId) => {
    setIsLoading(true)
    Http.getData(`admin/usage/track/${userId}`)
      .then((res) => {
        let usageCount = 0
        if (res.success){
          usageCount = res.enrichmentUsageCount
        }
        setUsageCount(usageCount)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    Http.getData('users/list')
      .then((res) => {
        const resUsers = res.users.map((item) => ({
          id: item._id,
          name: item.name,
          email: item.email
        }))
        setUsers(resUsers)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <Box display='flex' alignItems='center' gap={2} flexDirection='row'>
        <Select
          value={selectedUser}
          onChange={handleUserChange}
          sx={{ minWidth: 200 }}
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name} - {user.email}
            </MenuItem>
          ))}
        </Select>
        {isLoading && (
          <CircularProgress />
        )}
      </Box>
      <Box display='flex' justifyContent='center' flexDirection='column'>
        {!isLoading && selectedUser && (
          <FormHelperText sx={{ mt: 2, fontWeight: 'bold', fontSize: '1.2rem' }}>
            Premium Credit Usage Count: {usageCount}
          </FormHelperText>
        )}
      </Box>
    </>
  )
}
