import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Grid, Stack } from "@mui/material";
import PDatePicker from "@Library/PDatePicker";
import PTimePicker from "@Library/PTimePicker";
import PTypography from "@Library/PTypography";

dayjs.extend(customParseFormat);

const stackStyle = {
  py: 2.5,
  my: 4,
  borderRadius: 3,
  border: "1px solid #E5E5E5",
  width: "100%",
};

const isToday = (someDate) => {
  const today = dayjs();
  return someDate.isSame(today, 'day');
}

const DateTimePrompt = React.memo(({
  handleDateTimeChange,
  initialDateTime,
  headerMessage,
  subheaderMessage,
  readonly,
}) => {
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [dateTimeError, setDateTimeError] = React.useState(null);

  // Do something with the dateTimeErrors that bubble up from PDatePicker
  React.useEffect(() => {
    if (dateTimeError) {
      // TODO: Snackbars to communicate error states to the user
      console.error(dateTimeError);
    }
  }, [dateTimeError]);

  const setDateTimeValues = React.useCallback((dateTime) => {
    const parsedDateTime = dayjs(dateTime);
    if (parsedDateTime.isValid()) {

      const extractedTime = parsedDateTime.format("hh:mm A");
      setSelectedTime(dayjs(extractedTime, "hh:mm A"));
      setSelectedDate(parsedDateTime);
    }
  }, []);

  React.useEffect(() => {
    if (initialDateTime) {
      setDateTimeValues(initialDateTime);
    }
  }, [initialDateTime, setDateTimeValues]);

  const handleDateChange = (date) => {
    const newDate = dayjs(date);
    const isNewDateToday = isToday(newDate);
    const isNewDateFuture = newDate.isAfter(dayjs(), 'day');

    // Check if we should update the time option based on the date
    if (isNewDateToday && (!selectedTime || !isToday(selectedDate))) {
      setSelectedTime(dayjs()); // Set to current time if it's today
    } else if (isNewDateFuture) {
      const currentMinutes = selectedTime ? selectedTime.minute() : 0;
      let adjustedTime;
      if (currentMinutes >= 45) {
        adjustedTime = selectedTime.add(1, 'hour').startOf('hour');
      } else if (currentMinutes < 15) {
        adjustedTime = selectedTime.startOf('hour');
      } else {
        adjustedTime = selectedTime.minute(30).second(0);
      }
      setSelectedTime(adjustedTime);
    }

    setSelectedDate(newDate);
    handleDateTimeChange(newDate.hour(selectedTime ? selectedTime.hour() : 0).minute(selectedTime ? selectedTime.minute() : 0).toDate());
  }

  const handleTimeChange = (time) => {
    const newTime = dayjs(time, "hh:mm A");

    if (newTime.isValid()) {
      setSelectedTime(newTime);
    } else {
      console.error("Failed to parse time");
      setSelectedTime(null);
    }
  }

  React.useEffect(() => {
    if (!handleDateTimeChange || readonly || dateTimeError) {
      return;
    }

    if (selectedTime && selectedDate) {
      const dateTime = selectedDate.hour(selectedTime.hour()).minute(selectedTime.minute());
      if (dateTime.isValid()) {
        handleDateTimeChange(dateTime.toDate());
      }
    }
  }, [selectedDate, selectedTime, handleDateTimeChange, readonly, dateTimeError]);

  return (
    <>
      <PTypography size="h3" weight="bold">{headerMessage}</PTypography>
      <PTypography size="body1" weight="regular">{subheaderMessage}</PTypography>
      <Stack spacing={1} sx={stackStyle}>
        <Grid item xs={12} sx={{ pl: 3, pr: 3 }}>
          <PDatePicker
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
            setDateTimeError={setDateTimeError}
            readonly={readonly}
            autoCloseOnSelect={true}
          />
        </Grid>
        <Grid item xs={12} sx={{ pl: 3, pr: 3, pt: 0 }}>
          <PTimePicker
            selectedTime={selectedTime}
            onTimeChange={handleTimeChange}
            selectedDate={selectedDate}
            readonly={readonly}
            showNow={!readonly}
            show24hrs={readonly}
          />
        </Grid>
      </Stack>
    </>
  );
});

export default DateTimePrompt;
