import { Grid, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import PBadge from '@Library/PBadge.js';
import PRadio from '@Library/PRadio.js';
import PTypography from '@Library/PTypography.js';
import GrowAssistantAvatar from '@Assets/images/assistants/brand_building.png';
import AcquireAssistantAvatar from '@Assets/images/assistants/business_development.png';
import RetainAssistantAvatar from '@Assets/images/assistants/relationship_growth.png';

const imageMap = {
  retain: RetainAssistantAvatar,
  acquire: AcquireAssistantAvatar,
  grow: GrowAssistantAvatar,
};

// Why is this called a modal? It's not a modal. It's a card.
function GoalModal({ index, goal, handleGoalClick }) {
  const theme = useTheme();

  // Styles
  const ContainerStyle = {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #E3E3E3',
    borderRadius: '14px',
    padding: '16px',
    height: {
      xs: '194px',
      sm: '176px',
      md: '256px',
    },
    overflow: 'none',
    '&:hover': {
      border: '1px solid',
      borderColor: theme.palette.primary.blue,
    },
  };

  const BoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    mb: '12px',
  };

  const RadioBoxStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
  };

  const ImgStyle = {
    height: '40px',
    width: '40px',
  };

  // Render component
  return (
    <Grid item key={goal.id} xs={12} md={4}>
      <Box sx={ContainerStyle} onClick={() => handleGoalClick(index)}>
        <Box>
          <Box sx={BoxStyle}>
            <img src={imageMap[goal.id]} alt={goal.title} style={ImgStyle} />
            {goal.id === 'retain' && <PBadge pVariant="purple">Popular</PBadge>}
          </Box>
          <Box sx={{ mb: '8px' }}>
            <PTypography size="h1" weight="bold">
              {goal.title}
            </PTypography>
          </Box>
          <PTypography size="body1" weight="regular">
            {goal.subTitle}
          </PTypography>
        </Box>
        <Box>
          <Box sx={RadioBoxStyle}>
            <PRadio checked={goal.active} />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

export default GoalModal;
