import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";

export default function PProgressBar({progress, additionalStyles}) {

  const theme = useTheme();

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress
        sx={ additionalStyles || {
          "& .MuiLinearProgress-bar": {
          backgroundColor: theme.palette.primaryCL.Green100, 
        },
      }}
        variant="determinate"
        value={progress}
      />
    </Box>
  );
}
