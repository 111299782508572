import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, Link } from 'react-router-dom'
import { useSelector } from "react-redux";

import Box from '@mui/material/Box'
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom'

import { ReactComponent as LoginLogo } from '../../assets/Icons/LoginLogo.svg';

import httpClient from '../../http/httpClient'

import SetupSteps from './simpleSetupComponents/setupSteps.js'
import UserDropdown from './simpleSetupComponents/userDropdown';
import { setPage } from '../../redux/authSlice'
import featureFlagService from '../../services/featureFlagService';


export default function SimpleSetup() {
    const theme = useTheme()
    const navigate = useNavigate();
    const dispatch = useDispatch()

    // Define a state to keep track of the currently active item
    const [activeIndex, setActiveIndex] = React.useState(7)

    // Define the initial state for the setup and leads lists
    const [setupList, setSetupList] = React.useState([])

    const [fetchedSteps, setFetchedSteps] = React.useState(false);

    const [isComplete, setIsComplete] = React.useState(false);

    const addBioDone = { index: 1, id: 'addBioDone', title: 'Tell us about yourself', complete: false, active: true, optional: true, buttonText: 'Create profile', step: 'Details', listText: 'Profile' }
    const addConnectionsDone = { index: 2, id: 'addConnectionsDone', title: 'Connect your social media accounts', complete: false, active: false, buttonText: 'Connect accounts', step: 'Social', listText: 'Social' }
    const chooseStrategyDone = { index: 3, id: 'chooseStrategyDone', title: 'Set your goal', complete: false, active: false, buttonText: 'Select strategy', step: 'Strategy', listText: 'Goal' }
    const createListDone = { index: 4, id: 'createListDone', title: 'Add your contacts to a list', complete: false, active: false, buttonText: 'Add connections', step: 'List', listText: 'Contacts' }
    const createSequenceDone = { index: 5, id: 'createSequenceDone', title: 'Create your first assistant', complete: false, active: false, buttonText: 'Create assistant', step: 'Sequence', listText: 'Assistant' }

    React.useEffect(() => {
        if (isComplete === true) {
            dispatch(setPage('acquisition'));
            navigate('/main');
        }
    }, [isComplete]);

    React.useEffect(() => {
        const prepareSetupSteps = async () => {
            let list = [addBioDone, addConnectionsDone]
            const featureFlags = await featureFlagService.getFeatureFlags();
            const feature = featureFlags?.find((x) => x.name === 'sequences')

            if (feature?.isFeatureEnabled) list.push(chooseStrategyDone, createListDone, createSequenceDone)

            async function fetchSteps(list) {
                try {
                    const response = await httpClient.getData('setup/getSteps');
                    const { setupSteps } = response;

                    let foundActive = false;

                    const newSetupList = list.map(item => {
                        let newItem = { ...item };

                        if (item.id === 'signUpDone') {
                            newItem.complete = true;
                        } else {
                            newItem.complete = setupSteps[item.id] || false;
                        }

                        if (!newItem.complete && !foundActive) {
                            newItem.active = true;
                            foundActive = true;
                        } else {
                            newItem.active = false;
                        }

                        return newItem;
                    });

                    setSetupList(newSetupList);
                    setFetchedSteps(true);
                    setIsComplete(newSetupList.every(item => item.complete))

                } catch (error) {
                    console.error('Error while retrieving user steps:', error);
                }
            };

            if (!fetchedSteps) {
                fetchSteps(list);
            }
        }

        prepareSetupSteps();

    }, [fetchedSteps]);

    const loginTheme = createTheme({
        ...theme,
        components: {
            MuiCssBaseline: {
                styleOverrides: `
          html, body, #root {
            background: #3063EA
          }
        `,
            },
        },
    });

    return (
        <ThemeProvider theme={loginTheme}>
            <CssBaseline />
            <Container component="main" maxWidth='xl' sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{
                    backgroundColor: theme.palette.primary.white,
                    borderRadius: 5,
                    width: '100%',
                    margin: 'auto',

                    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                        px: '10px',
                        py: '11px',
                        mt: '15px',
                    },
                    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
                        px: 10,
                        py: 11,
                    },
                }}>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                            flexDirection: 'column',

                            // vertical spacing between elements
                            '& > *': {
                                mt: 1,
                                mb: 2,
                            },
                        },
                        [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            mb: 2,
                        },
                    }}
                    >
                        <LoginLogo width={150} height={25} />
                        <UserDropdown />
                    </Box>
                    <Grid container spacing={0}>
                        <Grid item xs={0} md={2} />
                        <Grid item xs={12} md={8}>
                            <SetupSteps setupList={setupList} setFetchedSteps={setFetchedSteps} />
                        </Grid>
                        <Grid item xs={0} md={2} />
                    </Grid>

                </Box>
            </Container>
            <Outlet />
        </ThemeProvider>
    );
}