import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/system'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Http from '../../http/httpClient'

const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 16px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.action.selected,
  borderBottom: '1px solid white'
}))

const CircularStatic = ({ value, max, innerValue }) => {
  value ||= 0
  max ||= 0
  innerValue ||= 0
  const normalizedValue = (value / max) * 100
  const normalizedInnerValue = (innerValue / max) * 100

  return (
    <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <ColorCircularProgress variant='determinate' value={normalizedValue} isInnerValue={false} size={100} thickness={2.5} sx={{ marginTop: 1 }} />
      <InnerColorCircularProgress variant='determinate' value={normalizedInnerValue} isInnerValue={true} size={84} thickness={2} sx={{ position: 'absolute', marginBottom: 5 }} />
      <Typography variant='body1' component='div' color='text.secondary' sx={{ color: getInnerColor(normalizedInnerValue), position: 'absolute', display: 'flex-inline', justifyContent: 'center', alignItems: 'center', marginBottom: 5, fontWeight: 'bold' }}>
        {`${normalizedInnerValue > 0 ? Math.round(normalizedInnerValue) : 0}%`}
      </Typography>
      <Typography variant='body2' sx={{ marginTop: 1, color: getColor(normalizedValue), fontWeight: 'bold' }}>
        {`Focus Contacts: ${value} / ${max}`}
      </Typography>
      <Typography variant='body2' sx={{ color: getInnerColor(normalizedInnerValue), fontWeight: 'bold' }}>
        {`Enriched Contacts: ${innerValue} / ${max}`}
      </Typography>
    </Box>
  )
}

CircularStatic.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
}

function getColor (value) {
  if (isNaN(value) || value === null || value === 0) {
    return '#B2B2B2'
  } else {
    return '#0089BE'
  }
}

function getInnerColor (value) {
  if (isNaN(value) || value === null || value === 0) {
    return '#E5E5E5'
  } else {
    return '#66B266'
  }
}

const InnerColorCircularProgress = styled(CircularProgress)(({ theme, value, isInnerValue }) => ({
  '& .MuiCircularProgress-circle': {
    color: isInnerValue === true ? getInnerColor(value) : getColor(value),
    boxShadow: `0 3px 10px 2px rgba(0,0,0,0.3)`,
    background: `linear-gradient(${isInnerValue === true ? getInnerColor(value) : getColor(value)} 40%, ${theme.palette.background.paper} 40%)`,
  }
}))

const ColorCircularProgress = styled(CircularProgress)(({ theme, value, isInnerValue }) => ({
  '& .MuiCircularProgress-circle': {
    color: isInnerValue === true ? getInnerColor(value) : getColor(value),
    boxShadow: `0 3px 10px 2px rgba(0,0,0,0.3)`,
    background: `linear-gradient(${isInnerValue === true ? getInnerColor(value) : getColor(value)} 40%,  40%)`
  }
}))

function TabPanel (props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

export default function SummaryTab ({ stats = [] }) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [users, setUsers] = React.useState([])

  React.useEffect(() => {
    const fetchPostQueueSummary = async () => {
      setIsLoading(true)
      Http.getData('getFocusContactSummary')
        .then((res) => {
          if (res && res.status) {
            setUsers(res.results)
          }
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          setIsLoading(false)
        })
    }

    fetchPostQueueSummary()
  }, [])

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 550, maxWidth: '95%', overflow: 'auto' }}>
      <Table sx={{ minWidth: 650 }} aria-label='focus contacts table'>
        <TableHead>
          <TableRow>
            <StyledHeaderTableCell>Name</StyledHeaderTableCell>
            <StyledHeaderTableCell align='center'>Twitter</StyledHeaderTableCell>
            <StyledHeaderTableCell align='center'>CSV</StyledHeaderTableCell>
            <StyledHeaderTableCell align='center'>Hubspot</StyledHeaderTableCell>
            <StyledHeaderTableCell align='center'>Salesforce</StyledHeaderTableCell>
            <StyledHeaderTableCell align='center'>Focus Contact Summary</StyledHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell sx={{ py: 0.2 }}>{user.name}</TableCell>
              <TableCell sx={{ py: 0.2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularStatic value={user.total_twitter_contacts} max={user.total_twitter_contacts} innerValue={user.total_enriched_twitter_contacts} />
                </Box>
              </TableCell>
              <TableCell sx={{ py: 0.2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularStatic value={user.total_csv_contacts} max={user.total_csv_contacts} innerValue={user.total_enriched_csv_contacts} />
                </Box>
              </TableCell>
              <TableCell sx={{ py: 0.2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularStatic value={user.total_hubspot_contacts} max={user.total_hubspot_contacts} innerValue={user.total_enriched_hubspot_contacts} />
                </Box>
              </TableCell>
              <TableCell sx={{ py: 0.2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularStatic value={user.total_salesforce_contacts} max={user.total_salesforce_contacts} innerValue={user.total_enriched_salesforce_contacts} />
                </Box>
              </TableCell>
              <TableCell sx={{ py: 0.2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularStatic value={user.total_focus_contacts} max={user.total_focus_contacts} innerValue={user.total_enriched_contacts} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
