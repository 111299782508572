import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

import { useInvites } from '../../../hooks/useInvites';
import { useAllUsers } from '../../../hooks/useAllUsers';
import InviteItem from './InviteItem';
import AddInviteModal from './AddInviteModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function InviteList() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [userInvites, setUserInvites] = useInvites();
  const [users, setUsers] = useAllUsers();
  const [isAddInviteModalOpen, setIsAddInviteModalOpen] = React.useState(false);
  const [buttonLoad, setButtonLoad] = React.useState(false);

  const handleOpenInviteModal = () => {
    setIsAddInviteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsAddInviteModalOpen(false);
  };

  return (
    <>
      <AddInviteModal
        open={isAddInviteModalOpen}
        onClose={handleCloseModal}
        loading={buttonLoad}
        users={users}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: 'white',
            backgroundColor: '#3983F7',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px',
            borderRadius: 10,
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
            },
          }}
          startIcon={<AddIcon />}
          onClick={handleOpenInviteModal}
        >
          Invite User
        </Button>
      </Box>
      {isLoading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>inviter</StyledTableCell>
                <StyledTableCell>invitee</StyledTableCell>
                <StyledTableCell>Company</StyledTableCell>
                <StyledTableCell>Token</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userInvites.length > 0 ? (
                userInvites.map((invite) => {
                  if (!invite) return null;
                  return (
                    <InviteItem
                      key={invite.invitee}
                      invite={invite}
                    />
                  );
                })
              ) : (
                <Typography align="right" gutterBottom>
                  There is no invites created yet.
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
