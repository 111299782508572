import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'

import Http from '../../../http/httpClient'
import { GetCookie } from '../../../helper/cookieManager'
import ReactTourComponent from '../../../components/ReactTourComponent'
import TourIconComponent from '../../../components/TourIconComponent'
import { editPostModalSteps } from '../../../assets/appGuide/appGuides'

export default function EditModalNew ({ open, onClose, ori_post }) {
  let avatar = ori_post.ImageUrl
  let name = ori_post.name
  let date = ori_post.created_at
  let org_content = ori_post.message

  if (ori_post.responseTo) {
    avatar = ori_post.responseTo.ImageUrl
    name = ori_post.responseTo.name
    date = ori_post.responseTo.created_at
    org_content = ori_post.responseTo.message
  }

  // Initialize state variables for each select box
  const [selectedTone, setSelectedTone] = React.useState('')
  const [selectedLength, setSelectedLength] = React.useState('')
  const [selectedHashtags, setSelectedHashtags] = React.useState('')
  const [selectedAccounts, setSelectedAccounts] = React.useState('')
  const [orgContent, setOrgContent] = React.useState(org_content)
  const [currContent, setCurrContent] = React.useState(org_content)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isTourOpen, setIsTourOpen] = React.useState(false)
  const [disableGuide, setDisableGuide] = React.useState(true)
  const [filterGuide, setFilterGuide] = React.useState(editPostModalSteps)

  // Track the state of the selects
  const handleSelectChange = (event, setState) => {
    const value = event.target.value
    setState(value)
  }

  const resetDropdowns = () => {
    setSelectedTone('')
    setSelectedAccounts('')
    setSelectedHashtags('')
    setSelectedLength('')
  }

  const savePhrase = () => {
    if (selectedTone || selectedLength || selectedHashtags) {
      setIsLoading(true)
      Http.getData('rephrasePost', { post: ori_post, tone: selectedTone, length: selectedLength, hashtags: selectedHashtags })
        .then((res) => {
          const _post = res.post
          setCurrContent(_post)
          setIsLoading(false)
          setOrgContent(_post)
          resetDropdowns()
          onClose(ori_post, _post)
        })
        .catch((error) => {
          console.log(error)
          setIsLoading(false)
        })
    } else {
      setOrgContent(currContent)
      resetDropdowns()
      onClose(ori_post, currContent)
    }
  }

  const cancelPhrase = () => {
    setCurrContent(orgContent)
    resetDropdowns()
    onClose()
  }

  const rephrasePost = () => {
    setIsLoading(true)
    console.log("rephrasing old flow post", ori_post)
    Http.getData('rephrasePost', { post: ori_post, tone: selectedTone, length: selectedLength, hashtags: selectedHashtags })
      .then((res) => {
        const _post = res.post
        setCurrContent(_post)
        resetDropdowns()
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const handleTextFieldChange = (event) => {
    const value = event.target.value
    setCurrContent(value)
  }

  React.useEffect(() => {
    const user = GetCookie('user')
    setDisableGuide(user.disableTourGuide)
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      PaperProps={{
        style: { borderRadius: 15 }
      }}
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <Avatar sx={{ mx: 1 }} src={avatar} />
          </Grid>
          <Grid item xs={11}>
            <Box display='flex' flexDirection='row' alignItems='center' sx={{ my: 1 }}>

              <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mx: 1 }}>
                {name}
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#566370' }}>
                &bull;
              </Typography>
              <Typography sx={{ fontSize: '16px', mx: 1, color: '#566370' }}>
                {date}
              </Typography>
              { !disableGuide && !isTourOpen && <TourIconComponent setDisableGuide={setDisableGuide} setIsTourOpen={setIsTourOpen}/> }
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={cancelPhrase}><ClearIcon /></IconButton>
            </Box>
            <Box id='step-edit-modal-content' sx={{ border: '1px solid #AAAAAA', borderRadius: 3 }}>
              <TextField
                onChange={handleTextFieldChange}
                variant='standard'
                value={currContent}
                sx={{ mx: 1, my: 1, width: '96%', px: 1 }}
                maxRows={4}
                multiline InputProps={{
                  disableUnderline: true
                }}
              />
              <Box display='flex' alignItems='center'>
                <Box marginLeft='auto'>
                  {isLoading
                    ? (
                      <Box
                        sx={{
                          position: 'relative',
                          width: '84px',
                          height: '44px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <CircularProgress color='inherit' size='2rem' />
                      </Box>
                      )
                    : (
                      <Button
                        id='step-adjust-post-rephrase'
                        onClick={rephrasePost}
                        sx={{
                          color: '#3983F7',
                          backgroundColor: '#FFFFFF',
                          fontWeight: 'bold',
                          textTransform: 'none',
                          alignItems: 'center',
                          fontSize: 13,
                          borderRadius: 8,
                          border: '2px #3983F7 solid',
                          my: 1,
                          mx: 1,
                          '&:hover': {
                            backgroundColor: '#3983F7',
                            color: 'white'
                          }
                        }}
                      >
                        Rephrase
                      </Button>)}
                </Box>
              </Box>

              <Divider />

              <Grid container spacing={0}>
                <Grid id='step-adjust-post-tone' item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id='adjTone'>Adjust tone</InputLabel>
                    <Select
                      labelId='adjTone'
                      label='Adjust tone'
                      value={selectedTone}
                      onChange={(event) => handleSelectChange(event, setSelectedTone)}
                    >
                      <MenuItem value='' />
                      <MenuItem value='concise'>Concise</MenuItem>
                      <MenuItem value='controversial'>Controversial</MenuItem>
                      <MenuItem value='formal'>Formal</MenuItem>
                      <MenuItem value='fun'>Fun</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid id='step-adjust-post-length' item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id='adjLength'>Length</InputLabel>
                    <Select
                      labelId='adjLength'
                      value={selectedLength}
                      onChange={(event) => handleSelectChange(event, setSelectedLength)}
                    >
                      <MenuItem value='' />
                      <MenuItem value='longer'>Longer</MenuItem>
                      <MenuItem value='shorter'>Shorter</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid id='step-adjust-post-hashtag' item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id='adjHashtags'>Hashtags</InputLabel>
                    <Select
                      labelId='adjHashtags'
                      label='Hashtags'
                      value={selectedHashtags}
                      onChange={(event) => handleSelectChange(event, setSelectedHashtags)}
                    >
                      <MenuItem value='' />
                      <MenuItem value='hashtagsOn'>On</MenuItem>
                      <MenuItem value='hashTagsOff'>Off</MenuItem>
                      <MenuItem value='refresh'>Refresh</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid id='step-adjust-post-account' item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id='adjAccounts'>Social Accounts</InputLabel>
                    <Select
                      labelId='adjAccounts'
                      label='Social Accounts'
                      value={selectedAccounts}
                      onChange={(event) => handleSelectChange(event, setSelectedAccounts)}
                    >
                      <MenuItem value='twitter'>Twitter</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

            </Box>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                id='step-post-save'
                variant='outlined'
                onClick={savePhrase}
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#3983F7',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  alignItems: 'center',
                  fontSize: 13,
                  borderRadius: 8,
                  border: '2px #3983F7 solid',
                  my: 1,
                  mx: 1,
                  '&:hover': {
                    backgroundColor: '#CEDBED',
                    color: '#3983F7',
                    border: '2px #3983F7 solid'
                  }
                }}
              >
                Save
              </Button>

            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      {isTourOpen && <ReactTourComponent steps={editPostModalSteps} isOpen={isTourOpen} setIsOpen={setIsTourOpen}/>}
    </Dialog>
  )
}
