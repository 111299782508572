import React, {useMemo} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PTypography from './PTypography';

export default function PProgressVerticalStepper({ list }) {
  const theme = useTheme();
  const numberStyle = (isActive) => ({
    width: '32px',
    height: '32px',
    fontSize: '12px',
    borderRadius: '50%',
    backgroundColor: isActive ? theme.palette.primaryCL.Black200 : theme.palette.primaryCL.White100,
    color: isActive ? 'white' : theme.palette.primaryCL.Black100,
    border: `1px solid ${isActive ? theme.palette.primaryCL.Black200 : theme.palette.primaryCL.Black70}`,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const stepStyle = {
    borderBottom: `1px solid ${theme.palette.primaryCL.Black70}`,
  };

  const renderedList = useMemo(() => {
    return list.map((item, index) => (
      <Box
        key={item.id}
        display="flex"
        flexDirection="column"
        sx={{
          ...stepStyle,
          paddingTop: index > 0 ? '32px' : '0',
          paddingBottom: '32px',
        }}
        gap="12px"
      >
        <span style={numberStyle(item.active)}>{index + 1}</span>

        {item?.header && (
          <PTypography weight="bold" size="h2">
            {item.header}
          </PTypography>
        )}

        {item?.subheader && (
          <PTypography weight="regular" size="body2">
            {item.subheader}
          </PTypography>
        )}

        {item.content}
      </Box>
    ));
  }, [list]);

  return renderedList;
}
