import React, { useState, useEffect } from 'react';
import Http from '../../../http/httpClient';
import {
  TextField,
  Button,
  List,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormControlLabel,
  Checkbox,
  useTheme,
  ListItemButton,
  ListItemIcon,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function StrategySettings() {
  // State variables
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editingStrategy, setEditingStrategy] = useState({});
  const [strategies, setStrategies] = useState([]);
  const [defaultTemplates, setDefaultTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [strategiesLoading, setStrategiesLoading] = useState(true);
  const [errors, setErrors] = useState({});

  // Theme
  const theme = useTheme();

  // Fetch all strategies when the component mounts
  useEffect(() => {
    const fetchStrategies = async () => {
      try {
        const response = await Http.getData('strategy/all');
        if (response && response.status) {
          setStrategies([...response.strategies]);
          setStrategiesLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchDefaultTemplates = async () => {
      Http.getData('getDefaultTemplates', { withStepText: 1 })
        .then(function (response) {
          if (response && response.status) {
            setDefaultTemplates([...response.templates]);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    fetchStrategies();
    fetchDefaultTemplates();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const template = defaultTemplates.find(
      (template) => template._id === selectedTemplate._id
    );
    // update the strategy with the selected template
    editingStrategy.sequenceTemplate = template._id;
    const newErrors = {};
    if (!editingStrategy.id) newErrors.id = 'Id is required';
    if (!editingStrategy.title) newErrors.title = 'Title is required';
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (editingStrategy._id) {
      // Find the index of the strategy in state
      const index = strategies.findIndex(
        (strategy) => strategy._id === editingStrategy._id
      );
      // update the strategy in state with the new values
      strategies[index] = editingStrategy;
      // Send a post to update the strategy in the database
      Http.postData('strategy/update', { strategy: editingStrategy });
    } else {
      // TODO: This is not yet supported but can easily be accomplished
      // just create endpoint and make the call here
    }
    setEditFormOpen(false);
  };

  const handleDelete = (id) => {
    // Delete the strategy
  };

  const handleEdit = (strategy) => {
    const template = defaultTemplates.find(
      (template) => template._id === strategy.sequenceTemplate
    );
    setSelectedTemplate(template);
    setEditingStrategy(strategy);
    setEditFormOpen(true);
  };

  const handleEditFormClose = () => {
    setEditFormOpen(false);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
  };

  // Styles
  const commonStyles = {
    boxShadow: 0,
    textTransform: 'none',
    fontWeight: 700,
    borderRadius: 2,
    width: '70%',
    mt: 2,
  };

  const buttonStyles = (
    bgColor,
    color,
    borderColor,
    hoverBgColor,
    hoverColor
  ) => ({
    ...commonStyles,
    backgroundColor: bgColor,
    color: color,
    borderColor: borderColor,
    '&:hover': {
      backgroundColor: hoverBgColor,
      color: hoverColor,
    },
  });

  const createTextField = (label, name, value) => (
    <TextField
      disabled={name === 'id'}
      label={label}
      name={name}
      value={value}
      onChange={(e) =>
        setEditingStrategy({
          ...editingStrategy,
          [name]: e.target.value,
        })
      }
      fullWidth
      margin="normal"
    />
  );

  // Render
  return (
    <div>
      <List
        style={{
          width: '100%',
          maxWidth: 500,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        {strategies.map((strategy) => (
          <ListItemButton
            key={strategy.id}
            onClick={() => handleEdit(strategy)}
          >
            <ListItemIcon>
              {strategy.icon}
            </ListItemIcon>
            <ListItemText primary={strategy.id} secondary={strategy.title} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => handleEdit(strategy)}
              >
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
        ))}
      </List>
      <Dialog open={editFormOpen} onClose={handleEditFormClose}>
        <DialogTitle>Edit Strategy</DialogTitle>
        <DialogContent>
          <form onSubmit={handleEditSubmit}>
            {createTextField('Id', 'id', editingStrategy.id)}
            {createTextField('Title', 'title', editingStrategy.title)}
            {createTextField('Sub Title', 'subTitle', editingStrategy.subTitle)}
            {createTextField(
              'Icon Location',
              'iconLocation',
              editingStrategy.iconLocation
            )}
            {createTextField(
              'Icon',
              'icon',
              editingStrategy.icon
            )}
            <InputLabel
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                color: theme.palette.primary.black,
              }}
            >
              Assign to Sequence Template
            </InputLabel>
            <FormControl fullWidth size="small">
              {/* <InputLabel id="demo-simple-select-label">Strategies</InputLabel> */}
              <Select
                borderColor="grey"
                value={selectedTemplate === null ? '' : selectedTemplate}
                onChange={(event) => {
                  const template = event.target.value;
                  setSelectedTemplate(template);
                }}
                sx={{
                  '&.Mui-focused': {
                    outline: 'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'grey',
                  },
                  '& .MuiMenu-paper': {
                    boxShadow: 0,
                  },
                  '& .MuiMenuItem-root': {
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                  },
                }}
              >
                {defaultTemplates.map((template) => (
                  <MenuItem key={template._id} value={template}>
                    {template.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editingStrategy.enabled}
                  onChange={(e) =>
                    setEditingStrategy({
                      ...editingStrategy,
                      enabled: e.target.checked,
                    })
                  }
                  name="enabled"
                  color="primary"
                  sx={{
                    '&.Mui-checked': {
                      color: theme.palette.primary.blue,
                    },
                  }}
                />
              }
              label="Enabled"
            />
          </form>
          <DialogActions>
            <Button
              onClick={handleEditFormClose}
              sx={buttonStyles(
                theme.palette.primary.white,
                theme.palette.warning.dark,
                theme.palette.warning.dark,
                theme.palette.warning.dark,
                theme.palette.primary.white
              )}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              sx={buttonStyles(
                theme.palette.primary.lightBlue,
                theme.palette.primary.blue,
                null,
                theme.palette.primary.blue,
                theme.palette.primary.white
              )}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
