import * as React from 'react'
import Typography from '@mui/material/Typography'
import PButton from '../../../components/library/PButton';
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme, useMediaQuery } from '@mui/material'
import { ReactComponent as Delete } from '../../../assets/Icons/Delete.svg'
import Http from "../../../http/httpClient"
import { Severity } from '../../../constants/constant'
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../redux/authSlice';

export default function Topics(props) {
    const theme = useTheme()
    const dispatch = useDispatch();
    const { addSnackbar } = useSnackbar();
    const user = useSelector((state) => state.auth.user);
    const [topics, setTopics] = React.useState([]);
    const [originalTopics, setOriginalTopics] = React.useState([]);
    const [newTopic, setNewTopic] = React.useState('');
    const [selectedTopics, setSelectedTopics] = React.useState([]);
    const [inProgress, setInProgress] = React.useState(false)
    const isInitialRender = React.useRef(true);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleNewTopicChange = (e) => {
        setNewTopic(e.target.value);
    };

    const handleAddTopic = () => {
        const newTopicTrimmed = newTopic.trim().toLowerCase()
        if (newTopicTrimmed === '') {
            addSnackbar({
                message: 'Invalid topic name.',
                pVariant: "destructive",
                hideAction: true,
            })
            return;
        }

        if(topics.length >= 10){
            addSnackbar({
                message: 'You can only have 10 Topics.',
                pVariant: "destructive",
                hideAction: true,
            })
            return;
        }

        if (topics.map(topic => topic.toLowerCase()).includes(newTopicTrimmed)) {
            addSnackbar({
                message: 'Topic already exists.',
                pVariant: "destructive",
                hideAction: true,
            })
            return;
        } 

        setTopics([...topics, newTopic]);
        setNewTopic('');
    }

    const handleDelete = (topic) => {
        const selTopics = topics.filter((t) => t !== topic);
        setTopics(selTopics);
    }

    const handleSave = () => {
        let doSave = true;
        if (topics.length < 3) {
            addSnackbar({
                message: "It's recommended to add at least 3 topics.",
                pVariant: "destructive",
                hideAction: true,
            })
        }
        else if (topics.length > 10) {
            addSnackbar({
                message: "You can only add up to 10 topics.",
                pVariant: "destructive",
                hideAction: true,
            })
            doSave = false;
        }

        if (doSave) {
            setSelectedTopics([...topics]);
            setOriginalTopics([...topics]);
            setInProgress(true);
        }
    };

    const areTopicsChanged = () => {
        if (topics.length !== originalTopics.length) {
            return true;
        }

        // sort both arrays and compare each element
        const sortedTopics = [...topics].sort();
        const sortedOriginalTopics = [...originalTopics].sort();
        for (let i = 0; i < sortedTopics.length; i++) {
            if (sortedTopics[i] !== sortedOriginalTopics[i]) {
                return true;
            }
        }

        return false;
    }

    const handleKeyDown =  (event) => {
        if(event.keyCode === 13 && newTopic) {
            handleAddTopic()
            event.preventDefault(); 
        }else if(event.keyCode === 13){
            event.preventDefault(); 
        }
    }

    React.useEffect(() => {
        const fetchTopics = async () => {
            Http.getData("getTopics")
                .then((res) => {
                    const { topics } = res;
                    setTopics(topics);
                    setOriginalTopics(topics);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        fetchTopics();
    }, []);


    React.useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        const updateTopics = async () => {
            let status = Severity.ERROR;
            let message = "Failed to save the topics.";
            try {
                const response = await Http.postData("topic", {
                    topics: { addedTopics: [...new Set(selectedTopics)] },
                });

                if (response && response.status === true) {
                    addSnackbar({
                        message: "Topics saved successfully.",
                        pVariant: "success",
                        hideAction: true,
                    });
                    dispatch(setUser({ ...user, topics: selectedTopics }));
                }
            } catch (error) {

                console.error(error);
            } finally {
                setInProgress(false);
            }
        };

        updateTopics();
    }, [selectedTopics]);

      
    return (
        <>
            <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 5,pl: { xs: 5 }, lineHeight: 'normal',
          mt: { xs: 4 }}}>
            What topics do you want to post about?
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5, pl: { xs: 5 }}}>
            This will help you in crafting posts that resonate with what your customers want to read.
            </Typography>

            <div style={{ marginTop: '10px', padding: '0px 40px' }}>
                {topics.map((topic, index) => (
                    <Chip
                        key={index}
                        
                        label={
                            <div style={{ display: 'flex', alignItems: 'center'}}>{topic}  </div>}
                        style={{
                            marginRight: '10px',
                            marginBottom: '10px',
                            border: '',
                            backgroundColor: theme.palette.primary.blue,
                            color: theme.palette.primary.white,
                            cursor: 'pointer',
                            fontSize: '14px',
                            fonntWeight: 500,
                            borderRadius: 8,
                            padding: '8px', 
                            ...(isMobile && { marginLeft: "14px" }),
                        }}
                        onDelete={() => handleDelete(topic) }
                        deleteIcon={<Delete fill={theme.palette.primary.white} width="10" />}
                    />
                ))}
            </div>
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Paper
                    component="form"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: 0,
                        border: `1px solid #E0E0E0`,
                        borderRadius: 4,
                        marginLeft: { xs: 5 },
                    }}
                >
                    <InputBase
                        type="text"
                        value={newTopic}
                        onChange={handleNewTopicChange}
                        placeholder="Add Topic"
                        onKeyDown={(e) => handleKeyDown(e)}
                        sx={{
                            flex: 1,
                            padding: '4px 8px',
                            fontSize: '12px',
                            fontWeight: 500,
                            width: { xs: "200px", md: "100px" },
                        }}
                    />
                    <IconButton
                        onClick={handleAddTopic}
                        color="primary"
                        sx={{
                            minWidth: 'auto',
                            borderRadius: 0,
                            borderTopLeftRadius: '4px',
                            borderBottomLeftRadius: '4px',
                        }}
                    >
                        <AddIcon sx={{color: theme.palette.primary.grey}}/>
                    </IconButton>
                </Paper>
            </div>

            {isMobile ? (
        <PButton
          onClick={handleSave}
          disabled={areTopicsChanged() === false}
        >
          {" "}
          {inProgress ? (
            <CircularProgress
              size={24}
              color="inherit"
              style={{ position: "absolute" }}
            />
          ) : (
            "Save"
          )}
        </PButton>
      ) : (
        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "10rem" }}>
          <PButton
            onClick={handleSave}
            disabled={areTopicsChanged() === false}
          >
            {" "}
            {inProgress ? (
              <CircularProgress
                size={24}
                color="inherit"
                style={{ position: "absolute" }}
              />
            ) : (
              "Save"
            )}
          </PButton>
        </div>
      )}
    </>
    );
}