import { SetCookie, GetCookie, RemoveCookie } from './cookieManager'
import Config from '../clientConfig/config'
import Http from '../http/httpClient'
import { UserConst } from '@/constants/userConst';

export const handleLocalLogin = async (credentials, onAuthSuccessCallback = null) => {
  try {
    // Adjust this URL to match your server configuration
    // The { withCredentials: true } option is not needed here since it's already applied in the axios instance
    const response = await Http.postUnauthenticatedData('auth/local', credentials);

    // Assuming the server responds with a redirectUrl for successful authentication
    if (response.data.redirectUrl) {
      window.location.href = response.data.redirectUrl;

      // Call the success callback if provided
      if (typeof onAuthSuccessCallback === 'function') {
        onAuthSuccessCallback();
      }
    } else {
      // Handle unexpected case where redirectUrl is not provided
      console.error('Successful login but no redirect URL provided.');
    }
  } catch (error) {
    // Since errors are now properly thrown by postUnauthenticatedData,
    // we can simplify the error handling
    console.error('Login failed:', error.response ? error.response.data.message : error.message);
    // Optionally, update UI here to reflect the error
  }
};


export const handleAuthentication = (url, onAuthSuccessCallback = null) => {
  const width = 500
  const height = 600
  const left = (window.screen.width / 2) - (width / 2)
  const top = (window.screen.height / 2) - (height / 2)

  const authWindow = window.open(Config.postilizeServer.baseUrl + url, '_blank', `width=${width}, height=${height}, top=${top}, left=${left}`)

  const bc = new BroadcastChannel('authchannel')
  bc.addEventListener('message', async (event) => {
    if (event.origin === window.location.origin && event.data.message === 'auth-success') {
      if (authWindow) authWindow.close();

      let redirectURL = null
      let luser;
      if (event.data.status === 200) {
        luser = GetCookie('user')
        if (luser) {
          luser.redirected = true
          if (luser.userType === UserConst.UserTypes.SIGNUP_USER || luser.userType === UserConst.UserTypes.PRECREATED_USER) {
            const now = new Date()
            const year = now.getFullYear()
            const month = (now.getMonth() + 1).toString().padStart(2, '0')
            const yearAndMonth = `${year}-${month}`
            const calendlyURL = `https://calendly.com/jody-glidden-pqw/15-minute-meeting?month=${yearAndMonth}`
            await Http.postData('/auth/logout').then((res) => {
            }).catch((err) => {
              console.log('err', err)
            }).finally(() => {
              RemoveCookie('user')
              redirectURL = calendlyURL
            })
          } else {
            SetCookie('user', JSON.stringify(luser))
            redirectURL = '/main'
            /** Currently, we have not implemented any special features for demo users while login. */
            /*if (luser && luser.userType === 'DemoUser') {
              redirectURL = '/setup'
            } else {*/
            // if (luser && luser.initialSetupDone) {
            //   redirectURL = '/main'
            // } else {
            //   redirectURL = '/setup'
            // }
            // }
          }
          // if (luser && luser.userType === 'DemoUser') {
          //   redirectURL = '/setup'
          // } else {
          //   
          //   if (luser && luser.initialSetupDone && !hasProviderOnBoard) {
          //     redirectURL = '/main'
          //   } else {
          //     redirectURL = '/setup'
          //   }
          // }
        }
      } else {
        redirectURL = event.data.url
      }

      if (event.data.status === 200 && typeof onAuthSuccessCallback === 'function') {
        onAuthSuccessCallback(luser);
      }

      if (event.data.url.endsWith('=authModal')) {
        sendMessage('modalAuthchannel', event)
      } else if (event.data.url.endsWith('=reauth')) {
        sendMessage('reauthChannel', event)
      } else if (event.data.url.endsWith('=listAuth')) {
        sendMessage('listAuthChannel', event)
      } else if (event.data.url.endsWith('=setupAuth')) {
        sendMessage('setupAuthChannel', event)
      } else if (event.data.url.endsWith('=social')) {
        sendMessage('socialsetupAuthChannel', event)
      } else if (event.data.url.endsWith('=settingsAuth')) {
        sendMessage('settingsCRMAuthChannel', event)
      } else if (event.data.url.endsWith('=settingsSocial')) {
        sendMessage('settingsSocialAuthChannel', event)
      } else if (event.data.url.endsWith('=sequenceTwitterAuth')) {
        sendMessage('sequenceTwitterAuthChannel', event)
      } else if (event.data.url.endsWith('=googleScope')) {
        sendMessage('googleScopeChannel', event)
      } else if (event.data.url.endsWith('=admin')) {
        const authChannel = new BroadcastChannel('adminAuthChannel')
        authChannel.postMessage({
          message: 'setupauth-success',
          url: event.data.url,
          status: event.data.status
        })
      } else {
        window.location.href = redirectURL
      }
    }
  })
}

const sendMessage = (channelName, event) => {
  const authChannel = new BroadcastChannel(channelName)
  authChannel.postMessage({
    message: 'auth-success',
    url: event.data.url,
    status: event.data.status
  })
}
