import * as React from "react";
import { useTheme } from '@mui/material'
import PPostCard from "../../../components/library/PPostCard";
import PTypography from "../../../components/library/PTypography"

export default function ApprovalItems({posts, handlePost, handleSendToMessage}) {
    const theme = useTheme()
    const [newPosts, setNewPosts] = React.useState(posts)

    React.useEffect(() => {
        setNewPosts(posts)
    }, [posts])

    return (
        <>
        {newPosts?.length > 0 ?
            (<>
                {newPosts.map((post) => (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        paddingTop: '28px',
                        paddingLeft: '14px',
                        paddingRight: '14px'
                         }}>
                     <PPostCard theme={theme} post={post} onClick={handlePost} handleSendToMessage={handleSendToMessage}></PPostCard>
                   </div>))}
            </>) :
            (<>
                <PTypography size="footnote" weight="regular" children="No posts available for approval." sx={{ p: 2}}></PTypography>
            </>)}
        </>
    )
}
  