import * as React from 'react'
import { Providers } from '../../../../constants/constant'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useTheme, CircularProgress } from '@mui/material'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import { alpha, styled } from '@mui/material/styles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import Alert from '@mui/material/Alert'
import Stack from '@mui/system/Stack';
import Avatar from '@mui/material/Avatar'
import Snackbar from '@mui/material/Snackbar';
import Http from '../../../../http/httpClient'

import { ReactComponent as Back } from '../../../../assets/Icons/Arrow-Left.svg'
import { ReactComponent as Delete } from '../../../../assets/Icons/Delete.svg'
import { ReactComponent as Search } from '../../../../assets/Icons/Search.svg'

export default function CSVModal(props) {
  const theme = useTheme()
  const { onClose, open, setListItem, handleListCreation, setupList } = props
  const [fileInputVisible, setFileInputVisible] = React.useState(false)
  const [selectedFile, setSelectedFile] = React.useState(null)
  const [isUploading, setIsUploading] = React.useState(false)
  const [uploadedLists, setUploadedLists] = React.useState([])
  const [isUpdateProgress, setIsUpdateProgress] = React.useState(false)
  const fileInputRef = React.useRef(null)

  const handleFileUpload = async (event) => {
    if (!selectedFile) {
      console.warn('No file selected')
      return
    }

    try {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(selectedFile)

      fileReader.onload = async (event) => {
        const fileContent = event.target.result
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('originalname', selectedFile.name)
        formData.append('path', selectedFile.path)
        formData.append('fileContent', fileContent)

        const response = await fetch('/api/uploadCsv', {
          method: 'POST',
          body: formData
        })
        
        if (response.ok) {
          const contentDisposition = response.headers.get('content-disposition')
          const matches = /filename="([^"]+)"/.exec(contentDisposition)
          const uploadedFileName = matches && matches.length > 1 ? matches[1] : null

          setListItem((prev) => {
            const providers = (prev.providers || []).slice()
            const index = providers.findIndex(provider => provider.providerType === 'csv')
            if (index !== -1) {
              providers[index] = {
                ...providers[index],
                provider: Providers.CSV,
                isExpanded: false,
                enrichmentCompleted: false,
                csvfileName: uploadedFileName
              }
            }

            return {
              ...prev,
              providers: providers,
              provider: Providers.CSV
            }
          })
          setUploadedLists([...uploadedLists, { fileName: selectedFile.name, uploadPath: `uploads/${uploadedFileName}` }])
        } else {
          console.error('File upload error:', response.statusText)
        }
      }
    } catch (error) {
      console.error(`Failed to upload the file. Error : ${error.message}`)
    }
  }

  const handleClose = () => {
    onClose()
  }

  React.useEffect(() => {
    if(uploadedLists && uploadedLists.length > 0) {
      setIsUpdateProgress(true)
      handleListCreation().then(() => {
        setIsUpdateProgress(false)
        onClose()
      }).catch((error) => {
        console.log(error)
      })
    }
  }, [uploadedLists])

  const handleFileInputChange = (event) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    setFileInputVisible(false)
  }   

  const handleDeleteFile = () => {
    setSelectedFile(null)
    setFileInputVisible(true)
  }

  const rows = [
    createData('John', 'Smith', 'Postilize'),
    createData('Jane', 'Doe', 'Acme', 'CEO', 'JD@Acme.com', '@Jane1987', 'https://www.linkedin.com/in/jane')
  ]

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.lightBlue,
      color: theme.palette.primary.black,
      fontWeight: 700,
      fontSize: '12px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '12px',
      fontWeight: 400,
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.primary.white,
      border: 0
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.primary.lightGrey,
      border: 0
    },
  }))

  function createData(fname, lname, company, role, email, twitter, linkedinUrl) {
    return { fname, lname, company, role, email, twitter, linkedinUrl }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullScreen>
      <DialogTitle sx={{ p: 0 }}>
        <Grid container alignItems="flex-start" justifyContent='space-between'>
          <Grid item>
            <IconButton aria-label='close' onClick={handleClose} sx={{pt: 2, pl: 2}}>
              <Back fill={theme.palette.primary.black} />
            </IconButton>
          </Grid>
          <Grid item>
          <Stack direction="row" spacing={10} sx={{my: 1.5}}>
                {setupList?.map(item => (
                  <Stack justifyContent="center"
                  alignItems="center">
                  <Avatar sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    bgcolor: item.active ? theme.palette.primary.blue : theme.palette.primary.white,
                    color: item.active ? theme.palette.primary.white : theme.palette.primary.grey,
                    border: item.active ? `none` : `1px solid #E0E0E0`,
                    my: 1,
                  }}>
                    {item.index}
                  </Avatar>
                  <Typography sx={{
                    color: item.active ? theme.palette.primary.black : theme.palette.primary.grey,
                    fontWeight: 500
                  }}>
                    {item.listText}
                  </Typography>
                  </Stack>
                ))}
              </Stack>

          </Grid>
          <Grid item>
            <Button
              onClick={handleFileUpload}
              sx={{
                maxWidth: '200px',
                backgroundColor: theme.palette.primary.blue,
                color: theme.palette.primary.white,
                textTransform: 'none',
                width: '167px',
                py: 1,
                px: 2,
                mr: 2,
                mt: 2,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                borderRadius: 2,
                boxShadow: '0px 4px 8px 0px #2C64E31F',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                },
              }}
            >
              {isUpdateProgress ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Continue'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <Grid container justifyContent='space-between'>
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6} >
            <Typography sx={{
              fontWeight: 700,
              color: theme.palette.primary.black,
              pt: 1,
              fontSize: {
                xs: '28px',
                md: '32px',
              },
              lineHeight: 'normal'
            }}>
              Upload your CSV. Please make sure it has columns for first name, last name, and company.
            </Typography>
            <Typography sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: theme.palette.primary.grey,
              pb: 5,
            }}>
              Here’s an example of a sample file.
            </Typography>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Table sx={{
                [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                  maxWidth: '90vw',
                  overflow: 'scroll',
                  display: 'inline-block',
                  flex: 0,
                },
                [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
                  width: '100%',
                  flex: 1,
                }

              }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>First Name</StyledTableCell>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>Company</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell>E-Mail</StyledTableCell>
                    <StyledTableCell>Twitter</StyledTableCell>
                    <StyledTableCell>LinkedinURL</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.fname + row.lname + row.email}>
                      <StyledTableCell component='th' scope='row'>
                        {row.fname}
                      </StyledTableCell>
                      <StyledTableCell>{row.lname}</StyledTableCell>
                      <StyledTableCell>{row.company}</StyledTableCell>
                      <StyledTableCell>{row.role || <em>Optional</em>}</StyledTableCell>
                      <StyledTableCell>{row.email || <em>Optional</em>}</StyledTableCell>
                      <StyledTableCell>{row.twitter || <em>Optional</em>}</StyledTableCell>
                      <StyledTableCell>{row.linkedinUrl || <em>Optional</em>}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              {!selectedFile && (
                <Button
                  onClick={() => fileInputRef.current.click()}
                  disabled={uploadedLists!=null && uploadedLists.length > 0}
                  sx={{
                    backgroundColor: theme.palette.primary.blue,
                    color: theme.palette.primary.white,
                    textTransform: 'none',
                    py: 1,
                    px: 2,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    borderRadius: 4,
                    boxShadow: '0px 4px 8px 0px #2C64E31F',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                    },
                    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                      mx: 1,
                      mt: 2,
                      width: '95%',
                    },
                    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
                      mx: 2,
                      mt: 4,
                      width: '98%',
                    }
                  }}
                >
                  Add a file
                </Button>
              )}
              {selectedFile && (
                <Paper
                  component='form'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: 0,
                    mt: 4,
                    mb: 3,
                    p: 1,
                    border: `1px solid #E0E0E0`,
                    borderRadius: 4,
                  }}
                >
                  <IconButton sx={{ p: '10px' }}>
                    <Search fill={theme.palette.primary.grey}/>
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1, fontWeight: 500 }}
                    value={selectedFile.name}
                  />
                  <IconButton aria-label='delete' onClick={handleDeleteFile}>
                    <Delete fill={theme.palette.primary.grey} />
                  </IconButton>
                </Paper>
              )}
              <input
                ref={fileInputRef}
                type='file'
                id='fileInput'
                accept='.csv'
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
              />
            </div>
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
