import Http from '../http/httpClient';

/**
 * This service provides methods for fetching feature flags.
 * @returns {Promise<Object>} The feature flags.
 */
const getFeatureFlags = async () => {
  try {
    const response = await Http.getData('featureFlags/list');
    if (response && response.status) {
      return response.featureFlags;
    }
    throw new Error('Unexpected response from featureFlags/list');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * This service provides methods for updating feature flags.
 * @param {String} companyId - The company id.
 * @param {Array} featureFlagsToUpdate - The feature flags to update.
 * @returns {Promise<Object>} The updated feature flags.
 */
const updateFeatureFlags = async (companyId, featureFlagsToUpdate) => {
  try {
    const response = await Http.postData('featureFlags/company/update', {
      companyId,
      featureFlagsToUpdate,
    });
    if (response && response.status) {
      return response.featureFlags;
    }
    throw new Error('Unexpected response from featureFlags/update');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * This service provides methods for fetching feature flags for a company.
 * @param {String} companyId - The company id.
 * @returns {Promise<Object>} The feature flags.
 */
const getFeatureFlagsForCompany = async (companyId) => {
  try {
    const response = await Http.getData('featureFlags/company', { companyId });
    if (response && response.status) {
      return response.featureFlags;
    }
    throw new Error('Unexpected response from featureFlags/company');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAdminFeatureFlags = async () => {
  try {
    const response = await Http.getData('featureFlags/admin/list');
    if (response && response.status) {
      return response.featureFlags;
    }
    throw new Error('Unexpected response from featureFlags/admin/list');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateAdminFeatureFlags = async (featureFlagToUpdate) => {
  // deconstruct the featureFlagToUpdate object
  const {
    _id,
    name,
    isFeatureEnabled,
    dependencies,
    enabledUserIds,
    enabledCompanyIds,
    updatedBy = 'System',
  } = featureFlagToUpdate;
  try {
    const response = await Http.postData('featureFlags/update', {
      _id,
      name,
      isFeatureEnabled,
      dependencies,
      enabledUserIds,
      enabledCompanyIds,
      updatedBy,
    });
    if (response && response.status) {
      return response.featureFlags;
    }
    throw new Error('Unexpected response from featureFlags/admin/update');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const createAdminFeatureFlag = async (featureFlagToCreate) => {
  // deconstruct the featureFlagToUpdate object
  const {
    name = 'no name provided',
    isFeatureEnabled = false,
    dependencies = [],
    enabledUserIds = [],
    enabledCompanyIds = [],
    createdBy = 'System',
  } = featureFlagToCreate;
  try {
    const response = await Http.postData('featureFlags/create', {
      name,
      isFeatureEnabled,
      dependencies,
      enabledUserIds,
      enabledCompanyIds,
      createdBy,
    });
    if (response && response.status) {
      return response.featureFlag;
    }
    throw new Error('Unexpected response from featureFlags/admin/create');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * A quick helper to see if a given feature is enabled (for a given set of featureFlags).
 * @param {String} featureName - The name of the feature.
 * @returns {boolean} Indicate if a feature is enabled or not. Undefined is generally interpreted as false but not forced.
 */
const isFeatureEnabled = (featureName, featureFlags) => {
  let feature = featureFlags?.find((x) => x.name === featureName)
  return feature?.isFeatureEnabled
};

export default {
  getFeatureFlags,
  updateFeatureFlags,
  getFeatureFlagsForCompany,
  getAdminFeatureFlags,
  updateAdminFeatureFlags,
  createAdminFeatureFlag,
  isFeatureEnabled,
};
