import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import * as React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material';
import Config from '../../../../clientConfig/config'
import { SetCookie, GetCookie } from '../../../../helper/cookieManager'
import { UserConst } from '../../../../constants/userConst'
import { SetupConst, Providers, ProviderType } from '../../../../constants/constant'
import { handleAuthentication } from '../../../../helper/authUtility'
import AffinityAuthModal from '../../../login/components/affinityAuthModal'
import CustomModal from '../../../login/components/confirmModal'
import Http from '../../../../http/httpClient'

const SocialButton = ({ currProvider, source, addSocial, selectedSocials }) => {
    const theme = useTheme()
    const { connected, provider, primary } = currProvider
    const { logo, ...exceptLogo } = currProvider

    const [modalOpen, setModalOpen] = React.useState(false)
    const [affinityModalOpen, setAffinityModalOpen] = React.useState(false)
    const [alertText, setAlertText] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [showAlert, setShowAlert] = React.useState(false);
    const [modalResponse, setModalResponse] = React.useState(null);
    const [isConnected, setIsConnected] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const isSelected = selectedSocials.includes(currProvider) ? true : false

    const dispatch = useDispatch()
    let userCookie = GetCookie('user')

    React.useEffect(() => {
        getConnectedState(currProvider)
    }, [])

    function toggleSocial(provider){
        //setIsSelected(!isSelected)
        addSocial(provider)
    }

    async function getConnectedState (currProvider) {
        const luser = GetCookie('user')
        if(luser) {
          const p = luser.providers.find(acc => acc !== null && acc.provider === currProvider) || []
          if (p && p.profileId) {
            setIsConnected(true)
          }
        }
      }

      const onAuthenticationComplete = () => {
        setIsConnected(true);
      };

    function handleModal(provider, source, event) {
        const action = event.target.innerText
        if (action === 'Connect') {
            if (!userCookie) { userCookie = GetCookie('user') }

            switch (provider) {
                case Providers.TWITTER:
                    handleAuthentication(`/api/auth/twitter?via=${source}`, onAuthenticationComplete)
                    break
                case Providers.LINKED_IN:
                    console.log('linkedin')
                    handleAuthentication(`/api/auth/linkedin?via=${source}`, onAuthenticationComplete)
                    break
                default:
                    break
            }
        }
    }

    const handleCloseModal = () => {
        setAffinityModalOpen(false)
    }

    const handleModalAction = (action) => {
        setModalResponse(action)
        setShowAlert(false)
    }

    return (
        <>
            {showAlert && <CustomModal clickAction={handleModalAction} alertText={alertText} />}
            <AffinityAuthModal open={affinityModalOpen} onClose={handleCloseModal} from={source} />
            <Button
                id={isConnected ? 'step-provider-connected' : 'step-connect-provider'}
                variant='contained'
                onClick={(event) => {
                    isConnected ? toggleSocial(currProvider) : handleModal(currProvider, source, event);
                }}
                sx={{
                    mt: 3,
                    ml: 1,
                    py: 1,
                    backgroundColor: isSelected ? theme.palette.primary.blue : theme.palette.primary.lightBlue,
                    color: isSelected ? theme.palette.primary.white : theme.palette.primary.blue,
                    fontSize: '14px',
                    fontWeight: 500,
                    textTransform: 'none',
                    width: '100%',
                    borderRadius: '12px',
                    boxShadow: 0,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.blue,
                        color: theme.palette.primary.white,
                        border: 'none',
                        boxShadow: 0,
                    },
                }}
            >
                {isLoading ? (
                    <CircularProgress size={24} color='inherit' />
                ) : (
                    isConnected ? isSelected ? 'Selected' : 'Select' : 'Connect'
                )}
            </Button>
        </>
    )
}

export default SocialButton