import * as React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { Typography, useTheme, Grid, Stack, Avatar, Divider, CircularProgress, Box } from '@mui/material';
import _ from 'lodash';
import Fuse from 'fuse.js';

import { ReactComponent as Search } from '../../../assets/Icons/Search.svg';

import ListModal from './listModal.js';
import ManualListModal from '../../setup/simpleSetupComponents/Lists/ManualListModal.js';
import { getDefaultStrategies } from '../../../helper/apiHelper.js';

export default function DelegationListDisplay(props) {
  const theme = useTheme();
  let { contacts, listItem, setCreatedListId, handleListModification } = props;
  const listContacts = React.useMemo(() => contacts, [contacts]);
  const [filteredContacts, setFilteredContacts] = React.useState([]);
  const [contactNumber, setContactNumber] = React.useState(contacts?.length || 0);
  const [activeButton, setActiveButton] = React.useState('All');
  const [sortFields, setSortFields] = React.useState(['hasNull', 'name', 'title', 'company']);
  const [searchContact, setSearchContact] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editContact, setEditContact] = React.useState(null);
  const [editContactModalOpen, setEditContactModalOpen] = React.useState(false);
  const [targetAudience, setTargetAudience] = React.useState({});
  const [inputHovered, setInputHovered] = React.useState(false);
  const [inputFocused, setInputFocused] = React.useState(false);
  const fuse = new Fuse(listContacts || [], {
    keys: ['name', 'company', 'title'],
    threshold: 0.4,
    location: 0,
    distance: 100,
    findAllMatches: true,
    minMatchCharLength: 1,
  });
  // const openNewContactModal = () => {
  //   setModalOpen(true)
  // }

  // const closeModal = () => setModalOpen(false)
  // const editContactModalClose = () =>  setEditContactModalOpen(false)

  const handleButtonClick = (type) => {
    setActiveButton(type);
  };

  // React.useEffect(() => {
  //   const sortingFieldsMap = {
  //     All: ['hasNull', 'name', 'title', 'company'],
  //     Role: ['hasNull', 'title', 'name', 'company'],
  //     Company: ['hasNull', 'company', 'name', 'title'],
  //     Channel: [],
  //   };

  //   const sortingFields = sortingFieldsMap[activeButton] || ['hasNull', 'name', 'title', 'company'];
  //   setSortFields(sortingFields);
  // }, [activeButton]);

  const handleSearchListContact = (event) => {
    event.preventDefault();
    setSearchContact(event.target.value);
  };

  React.useEffect(() => {
    setContactNumber(listContacts?.length || 0);
  }, [listContacts]);

  React.useEffect(() => {
    const searchQuery = searchContact.toLowerCase();
    const filteredContacts = searchQuery
      ? fuse.search(searchQuery).map((contact) => contact.item)
      : (listContacts || []).filter((contact) => {
          return (
            contact !== null &&
            (contact.name?.toLowerCase().includes(searchQuery) ||
              contact.company?.toLowerCase().includes(searchQuery) ||
              contact.title?.toLowerCase().includes(searchQuery))
          );
        });

    const sortedContacts = _.orderBy(filteredContacts, sortFields, ['asc']);
    setFilteredContacts(sortedContacts);
  }, [searchContact, sortFields, listContacts]);

  const fetchStrategies = async () => {
    getDefaultStrategies()
      .then((response) => {
        if (response && response.status) {
          setTargetAudience(response.targetAudience);
        }
      })
      .catch((error) => {
        console.error('Error : ', error);
      });
  };

  React.useEffect(() => {
    fetchStrategies();
  }, []);

  return (
    <>
      {/* {modalOpen && (
        <ListModal
          open={modalOpen}
          onClose={closeModal}
          forSequence={true}
          addNewContactsToList={true}
          setCreatedListId={setCreatedListId}
          selectedItem={listItem}
          targetAudience={targetAudience}
        />
      )} */}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Paper
          component="form"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '95%',
            my: 2,
            boxShadow: 0,
            border: '1px solid',
            borderColor: inputHovered || inputFocused ? theme.palette.primary.blue : theme.palette.primary.grey,
            borderRadius: '5px',
          }}
          onMouseEnter={() => setInputHovered(true)}
          onMouseLeave={() => setInputHovered(false)}
        >
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <Search fill={theme.palette.primary.grey} />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by name, email, company, role"
            onChange={handleSearchListContact}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
          {/* <Button
            onClick={openNewContactModal}
            sx={{
              backgroundColor: theme.palette.primary.white,
              color: theme.palette.primary.blue,
              textTransform: 'none',
              fontWeight: 500,
              fontSize: '14px',
              py: 1,
              px: 2,
              mx: 3,
              borderRadius: 2,
            }}
          >
            Add contact
          </Button> */}
        </Paper>
      </div>
      <Grid container spacing={2} alignItems="center" sx={{ my: 2, width: '95%' }}>
        {/* <Grid item xs={8}>
          {['All', 'Role', 'Company', 'Channel'].map((type) => (
            <Button
              key={type}
              onClick={() => handleButtonClick(type)}
              sx={{
                textTransform: 'none',
                border: 'none',
                backgroundColor: theme.palette.primary.white,
                color: activeButton === type ? theme.palette.primary.blue : theme.palette.primary.grey,
                fontWeight: 500,
                fontSize: '14px',
                mx: 1,
              }}
            >
              {type}
            </Button>
          ))}
        </Grid> */}

        <Grid item xs={12} container justifyContent="flex-end">
          <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.primary.grey }}>
            Showing: {contactNumber} contacts
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      {contacts === null ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%',
            color: 'black',
          }}
        >
          <CircularProgress color="inherit" size={80} />
        </Box>
      ) : filteredContacts.length === 0 ? (
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '16px',
            color: theme.palette.primary.grey,
            mt: 5,
            mb: 3,
          }}
        >
          Oops, it seems there are no contacts associated with the selected list.
          <br />
          We're working on finding some for you.
        </Typography>
      ) : (
        filteredContacts.map((contact, index) => (
          <React.Fragment key={index}>
            <Grid
              container
              alignItems="center"
              sx={{
                my: 2,
                // '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => {
                setEditContact(contact);
                setEditContactModalOpen(true);
              }}
            >
              <Grid item>
                <Avatar sx={{ mx: 3, my: 2 }} src={contact.photo_url} alt={contact.name} />
              </Grid>
              <Grid item>
                <Stack spacing={0.5}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black }}>
                    {contact.name}
                  </Typography>
                  <Typography sx={{ fontSize: '12px', fontWeight: 500, color: theme.palette.primary.black }}>
                    {contact.company}
                  </Typography>
                  <Typography sx={{ fontSize: '12px', fontWeight: 500, color: theme.palette.primary.black }}>
                    {contact.title}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        ))
      )}

      {/* {editContactModalOpen && (
        <ManualListModal
          open={editContactModalOpen}
          onClose={editContactModalClose}
          contact={editContact}
          forSetup={false}
          refreshListContacts={() => {
            editContactModalClose();
            handleListModification();
          }}
        />
      )} */}
    </>
  );
}
