import { useCallback } from 'react';
import { Autocomplete, Paper, TextField, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const PSingleSearch = ({ options, value, onChange, placeholder = 'Select an option', id = 'single-search', pSize }) => {
  const theme = useTheme();
  const buttonHeight = pSize === 'small' ? '32px' : '48px';
  const fontSize = pSize === 'small' ? '14px' : '16px';

  const TextFieldStyle = {
    'width': 170,
    'fontFamily': 'Inter',
    'lineHeight': buttonHeight,
    'textTransform': 'none',
    '& fieldset': {
      borderColor: theme.palette.primaryCL.Black70,
    },
  };

  const AutocompleteStyle = {
    'width': '80%',
    '.MuiOutlinedInput-root': {
      'fontSize': fontSize,
      'borderRadius': 4,
      'my': 1,
      'height': '3.1rem',
      'flex': 'initial',
      'alignContent': 'center',
      '&:focus-within': {
        border: `1px solid ${theme.palette.primaryCL.Blue100}`,
        h: 4,
      },
    },
  };

  const PaperStyle = { display: 'flex', alignItems: 'center', boxShadow: 0, px: 1 };

  const handleAutocompleteChange = useCallback(
    (event, newValue) => {
      onChange(newValue ? newValue.label : null);
    },
    [onChange],
  );

  return (
    <Paper component="form" elevation={3} sx={PaperStyle}>
      <Autocomplete
        id={id}
        options={options}
        value={options.find((option) => option.label === value) || null}
        onChange={handleAutocompleteChange}
        isOptionEqualToValue={(option, selectedValue) => option.label === selectedValue.label}
        sx={AutocompleteStyle}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} sx={TextFieldStyle} />}
      />
    </Paper>
  );
};

export default PSingleSearch;
