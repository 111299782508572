import { useState, useEffect } from 'react';
import userInviteService from '../services/userInviteService';

export const useInvites = () => {
  const [userInvites, setUserInvites] = useState([]);

  useEffect(() => {
    const fetchUserInvites = async () => {
      try {
        const allInvites = await userInviteService.getAllUserInvites();
        const mappedInvites = allInvites.map((invite) => {
          const inviter = invite.inviter?.name || 'No Inviter';
          const companyId = invite.companyId?.name || 'No Company';
          return { ...invite, inviter, companyId };
        });
        setUserInvites(mappedInvites);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserInvites();
  }, []);

  return userInvites ? [userInvites, setUserInvites] : [[], setUserInvites];
};
