import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import SetUserPassword from './SetUserPassword';

export default function Automation() {
  const [selectedUserTab, setSelectedUserTab] = React.useState('Set User Password');

  const handleTabChange = (event, selectedTab) => {
    setSelectedUserTab(selectedTab);
  };

  let content;
  switch (selectedUserTab) {
    case 'Set User Password':
      content = <SetUserPassword />;
      break;
    default:
      content = <SetUserPassword />;
      break;
  }

  return (
    <>
      <Box sx={{ maxWidth: { xs: 480, sm: 600 }, bgcolor: 'background.paper' }}>
        <Tabs
          value={selectedUserTab}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="automation menu"
        >
          <Tab label="Set User Password" value="Set User Password" />
        </Tabs>
      </Box>
      <Toolbar />
      <>{content}</>
    </>
  );
}
