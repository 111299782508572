import * as React from 'react';
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material';

import Http from '../../../http/httpClient'
import { GetCookie } from "../../../helper/cookieManager";

import { ReactComponent as ArrowDown } from '../../../assets/Icons/Arrow-Down.svg'

export default function UserDropdown() {
    const theme = useTheme()
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignout = () => {
        setAnchorEl(null);
        Http.postData('/auth/logout').then((res) => {
            navigate('/')
          })
    };

    const handleSupport = () => {
        setAnchorEl(null);
        window.location.href = `mailto:support@postilize.com?subject=Help request`;
    };

    const curUser = GetCookie("user");

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <Typography
                        onClick={handleClick}
                        sx={{
                            cursor: 'pointer',
                            fontWeight: 500,
                            color: theme.palette.primary.grey,
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center', 
                        }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <span>{curUser.email}</span>
                        <Box sx={{ ml: 1 }}> 
                            <ArrowDown fill={theme.palette.primary.grey} />
                        </Box>
                    </Typography>
                </Tooltip>
            </Box>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    style: {
                        borderRadius: '8px',
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleSignout} sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', color: theme.palette.primary.black }}>
                    Sign out
                </MenuItem>
                <MenuItem onClick={handleSupport} sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', color: theme.palette.primary.black }}>
                    Contact support
                </MenuItem>
            </Menu>

        </React.Fragment>
    );
}
