import * as React from 'react';
import PropTypes from 'prop-types';
import PModalFullscreen from '@Library/PModalFullscreen';
import PModalFloating from '@Library/PModalFloating';
import { Grid, Container, CircularProgress } from '@mui/material';

const whitespaceColumns = ({ extraWideContent, ultraWideContent }) => {
  if (ultraWideContent) {
    return 0;
  } else if (extraWideContent) {
    return 2;
  } else {
    return 3;
  }
};

function PModal(props) {
  const {
    children,
    floating,
    loadingNext,
    extraWideContent,
    ultraWideContent,
    floatingDimensions = {
      width: '75vw',
      height: 'auto',
    },
  } = props;

  const commonContents = React.useMemo(() => {
    const columns = whitespaceColumns({ extraWideContent, ultraWideContent });

    const containerStyle = {
      justifyContent: 'space-between',
      // fill the available space
      height: '100%',
      width: '100%',
    };

    return (
      <Grid container style={containerStyle}>
        {/* This is just whitespace padding for appearances */}
        <Grid item xs={0} md={columns} />

        <Grid item xs={12} md={12 - columns * 2}>
          {loadingNext ? (
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                paddingTop: '100px',
              }}
            >
              <CircularProgress color="inherit" size={72} />
            </Container>
          ) : (
            children
          )}
        </Grid>

        {/* This is just whitespace padding for appearances */}
        <Grid item xs={0} md={columns} />
      </Grid>
    );
  }, [extraWideContent, ultraWideContent, loadingNext, children]);

  if (floating) return <PModalFloating {...props}>{commonContents}</PModalFloating>;
  else return <PModalFullscreen {...props}>{commonContents}</PModalFullscreen>;
}

PModal.propTypes = {
  /** https://react.dev/reference/react/Children
   * Default is null
   */
  children: PropTypes.node,

  /** Dialog open status */
  open: PropTypes.bool,

  /** Close function */
  onClose: PropTypes.func,

  /** Optional progress stepper list - required for progress stepper */
  progressList: PropTypes.array,

  /** Optional progress percent - required for progress bar */
  progressPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Optional, uses 10/12 grid columns instead of 8/12.
   * Default is false
   */
  extraWideContent: PropTypes.bool,

  /** Optional, uses 12/12 columns instead of 8/12 */
  ultraWideContent: PropTypes.bool,

  /** Optional, shows a loading spinner instead of any children */
  loadingNext: PropTypes.bool,

  /** Optional back button */
  backButton: PropTypes.node,

  /** Optional action buttons */
  actionButtons: PropTypes.array,

  /** Optional, makes the modal float above the page */
  floating: PropTypes.bool,

  /** Optional header text, floating modals only */
  headerText: PropTypes.string,

  /** Optional, floating modals only, makes the header text inline with the back button and action buttons.
   * Default is false
   */
  inlineHeaderText: PropTypes.bool,

  /** Optional floating modal dimensions.
   * Default is { width: '75vw', height: 'auto' }
   */
  floatingDimensions: PropTypes.object,

  /** Optional padding for the modal.
   * Default is 24px
   */
  padding: PropTypes.string,

  /** Optional, shows a close button in the top right corner of the modal */
  showCloseButton: PropTypes.bool,
};

PModal.defaultProps = {
  actionButtons: [],
  backButton: null,
  children: null,
  extraWideContent: false,
  floating: false,
  floatingDimensions: {
    height: 'auto',
    width: '75vw',
  },
  headerText: null,
  inlineHeaderText: false,
  loadingNext: false,
  onClose: null,
  open: true,
  padding: undefined,
  progressList: null,
  progressPercent: null,
  showCloseButton: false,
  ultraWideContent: false,
};

export default PModal;
