import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PPopoverMenuItem from './PPopoverMenuItem';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.White110,
        background: theme.palette.primaryCL.Blue100,
        hoverText: theme.palette.primaryCL.White110,
        hoverBackground: theme.palette.primaryCL.Blue90,
      };
      break;

    case 'grey':
      colorScheme = {
        text: theme.palette.primaryCL.Black100,
        background: theme.palette.primaryCL.Black50,
        hoverText: theme.palette.primaryCL.Black200,
        hoverBackground: theme.palette.primaryCL.Black50,
      };
      break;

    default: // same as primary
      colorScheme = {
        text: theme.palette.primaryCL.White110,
        background: theme.palette.primaryCL.Blue100,
        hoverText: theme.palette.primaryCL.White110,
        hoverBackground: theme.palette.primaryCL.Blue90,
      };
      break;
  }

  return colorScheme;
};

function PDropdown({ menuList, selectedItem, truncateText, pVariant, closeWhenClicked }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const isOpen = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { background, text, hoverBackground, hoverText } = getVariantStyles(theme, pVariant);

  const buttonStyles = {
    'color': text,
    'backgroundColor': background,
    'borderRadius': '14px',
    'padding': '0 16px',
    'height': '48px',
    'width': '100%',
    'minWidth': 0,
    'fontFamily': 'Inter',
    'fontSize': '16px',
    'lineHeight': '48px',
    'textTransform': 'none',
    '&:hover': {
      backgroundColor: hoverBackground || theme.palette.primaryCL.Blue40,
      color: hoverText,
    },
  };

  const divStyles = {
    display: 'flex',
    alignItems: 'center',
    ...(truncateText && {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '10ch',
      display: 'block',
    }),
  };

  const anchorOrigin = { vertical: 'bottom', horizontal: 'right' };

  const slotProps = { paper: { style: { minWidth: 259, width: 'auto' } } };

  return (
    <div>
      <Button id="basic-button" onClick={handleClick} sx={buttonStyles}>
        <div style={divStyles}>{selectedItem}</div>
        <ExpandMoreIcon sx={{ marginLeft: '8px', verticalAlign: 'middle', fill: text }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        slotProps={slotProps}
        anchorOrigin={anchorOrigin}
      >
        {menuList?.map((item) => (
          <PPopoverMenuItem
            item={item}
            key={item.name}
            selectedItem={selectedItem}
            onClose={handleClose}
            onClick={() => {
              if (closeWhenClicked) {
                handleClose();
              }
            }}
          />
        ))}
      </Menu>
    </div>
  );
}


PDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  pVariant: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
};

PDropdown.defaultProps = {
  pVariant: 'primary',
  disabled: false,
};

export default PDropdown;
