import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

const styles = {
  textOverflow: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
};

export default function AssociatedSequenceReview({ sequenceToReview, listItem }) {
  const theme = useTheme();

  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 2, lineHeight: 'normal' }}>
            {`'${listItem?.name}' is being used by an existing assistant. Any contacts you've added will also be included in these assistants.`}
          </Typography>
          {sequenceToReview.map((sequence) => (
              <Container
                style={{ paddingLeft: '16px', marginTop: '32px' }}
                key={sequence}
                sx={{
                  backgroundColor: theme.palette.primary.white,
                  py: 2,
                  my: 1,
                  borderRadius: 3,
                  width: "100%",
                  border: '1px solid #E0E0E0',
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "97%" }}
                >
                  <Grid container item xs={11} alignItems="center" spacing={2}>
                    <Grid item sx={{ width: "80%" }}>
                      <Stack>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: theme.palette.primary.black,
                            ...styles.textOverflow,
                          }}
                          title={sequence}
                        >
                          {sequence}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            ))
          }
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </div>
  );
}
