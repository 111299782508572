export const UserConst = Object.freeze({
  UserTypes: {
    TRIAL_USER: "TrialUser",
    FULL_USER: "FullUser",
    DEMO_USER: "DemoUser",
    SIGNUP_USER: "SignUp",
    PRECREATED_USER: 'PreCreatedUser'
  },
  MaxLeadershipTopics: {
    TrialUser: 1,
    FullUser: 4,
    DemoUser: 10
  },
  OnboardingStatus: {
    PENDING: 'pending',
    APPROVED: 'approved',
    BOARDED: 'boarded'
  },
  UserStatus: {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
  },
  UserRoles: {
    USER: 'user',
    ADMIN: 'admin',
    SUPERADMIN: 'superadmin'
  }
})
