import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';

export default function EmployeeEngagement(props) {
    const theme = useTheme();
    const [activeButton, setActiveButton] = useState(null);

    const getButtonStyles = (label) => ({
        boxShadow: 0,
        textTransform: 'none',
        fontWeight: 500,
        borderRadius: 2,
        width: '90%',
        backgroundColor: activeButton === label ? theme.palette.primary.blue : theme.palette.primary.white,
        color: activeButton === label ? theme.palette.primary.white : theme.palette.primary.grey,
        mx: 2,
        my: label === 'Yes' ? 2 : 1,
        '&:hover': {
            backgroundColor: theme.palette.primary.blue,
            color: theme.palette.primary.white
        }
    });

    return (
        <>
            <Button
                sx={getButtonStyles('Yes')}
                onClick={() => setActiveButton('Yes')}
            >
                Yes
            </Button>

            <Button
                sx={getButtonStyles('No')}
                onClick={() => setActiveButton('No')}
            >
                No
            </Button>
        </>
    )
}