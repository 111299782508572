import { Button } from '@mui/material'
import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material';
import { SetCookie, GetCookie } from '../../../helper/cookieManager'
import { UserConst } from '../../../constants/userConst'
import { Providers, ProviderType } from '../../../constants/constant'
import { handleAuthentication } from '../../../helper/authUtility'
import AffinityAuthModal from '../../login/components/affinityAuthModal'
import CustomModal from '../../login/components/confirmModal'
import Http from '../../../http/httpClient'
import PTopicSelector from "@Library/PTopicSelector";
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../redux/authSlice';

const CustomButton = ({ currProvider, source, connectionStatus, setProviderList }) => {
  const dispatch = useDispatch();
  const theme = useTheme()
  const { connected, provider, primary } = currProvider
  const { logo, ...exceptLogo} = currProvider

  const [affinityModalOpen, setAffinityModalOpen] = React.useState(false)
  const [alertText, setAlertText] = React.useState('');
  const [alertType, setAlertType] = React.useState('');
  const [showAlert, setShowAlert] = React.useState(false);
  const [modalResponse, setModalResponse] = React.useState(null);
  const [isConnected, setIsConnected] = React.useState(connected)
  const [isLoading, setIsLoading] = React.useState(false)
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const isLinkedInMailEnabled = !!featureFlags?.find(featureFlag => featureFlag.name === 'enableLinkedInEmailStep')?.isFeatureEnabled;

  let userCookie = GetCookie('user')

  function newHandleModal(provider, source, buttonText) {
    if (buttonText === 'Connect' || buttonText === 'Upload' || buttonText === 'Reconnect' || buttonText.includes('Log in with')) {
      if (!userCookie) { userCookie = GetCookie('user') }
      if (userCookie && userCookie.userType == UserConst.UserTypes.TRIAL_USER && (provider !== 'twitter')) {
        alert('This feature is not available for Trial Users. Please, upgrade your account to proceed further.')
        return
      }
      switch (provider) {
        case Providers.TWITTER:
          handleAuthentication(`/api/auth/twitter?via=${source}`)
          break
        case Providers.LINKED_IN:
          handleAuthentication(`/api/auth/linkedin?via=${source}`)
          break
        case Providers.SALESFORCE:
          handleAuthentication(`/api/auth/salesforce?via=${source}`)
          break
        case Providers.HUBSPOT:
          handleAuthentication(`/api/auth/hubspot?via=${source}`)
          break
        case Providers.GOOGLE:
          handleAuthentication(`/api/auth/google?via=${source}`)
          break
        case Providers.AFFINITY:
          setAffinityModalOpen(true)
          break
        default:
          break
      } 
    }
  }

  function handleModal(provider, source, event) {
    console.log(event)
    const action = event.target.innerText
    if (!action) return
    newHandleModal(provider, source, action)
  }

  const isSociallyConnected = () => {
    let sociallyConnected = true

    if (ProviderType.SOCIAL_NETWORKS.includes(provider)) {
      if (userCookie && userCookie.providers) {
        const otherExpectingProvider = provider === Providers.TWITTER ? Providers.LINKED_IN : Providers.TWITTER
        sociallyConnected = userCookie.providers.some((acc) => acc !== null && (acc.provider === otherExpectingProvider))
      }
    }

    return sociallyConnected
  }

  const newHandleDisconnect = (buttonText) => {
    console.log(buttonText)
    if (buttonText === 'Remove' || buttonText === 'Connected'){
      let alertPrompt = `Disconnecting a social network will erase all related data from Postilize.  Are you sure you want to proceed?`;

      setAlertText(alertPrompt);
      setAlertType('Action');
      setShowAlert(true);
    }
  }

  const handleDisconnect = (_provider, _source, event) => {
    const action = event?.target?.innerText;
    if(!action) return;

    newHandleDisconnect(action);
  }

  const user = useSelector((state) => state.auth.user);

  React.useEffect(() => {
    if (modalResponse === 'OK' && alertType === 'Action') {
      setIsLoading(true);
      Http.postData('disconnectProvider', { provider, profile: exceptLogo })
        .then(async (res) => {
          if (res.status) {
            connectionStatus(false);
            const luser = GetCookie('user');
            if (luser && luser.providers) {
              luser.providers = luser.providers.filter((p) => p.profileId !== currProvider.profileId);
            }
            SetCookie('user', JSON.stringify(luser));

            const updatedReduxUser = {
              ...user,
              providers: user.providers.map((p) => p.profileId !== currProvider.profileId),
            };
            console.log("current redux user: ", user)
            console.log("updatedReduxUser: ", updatedReduxUser)
            dispatch(setUser(updatedReduxUser));
            setIsConnected(false);
          }

          setAlertText(res.message);
          setAlertType('Info');
          setShowAlert(true);
          setProviderList((prevList) =>
            prevList.map((item) => (item.profileId === currProvider.profileId ? { ...item, connected: false } : item)),
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setAlertType('');
          setIsLoading(false);
        });
    }
    setModalResponse('');
  }, [modalResponse]);

  const handleCloseModal = () => {
    setAffinityModalOpen(false)
  }

  const handleModalAction = (action) => {
    setModalResponse(action)
    setShowAlert(false)
  }

  const newConnectButtonText = React.useMemo(() => {
    if (provider === Providers.CSV) {
      return "Upload";
    } else {
      if (isConnected) {
        return "Connected";
      } else {
        return `Log in with ${currProvider?.provider}`
      }
    }
  }, [isConnected, primary, provider, isSociallyConnected]);

  // TODO: Refactor to use PButtons instead of PTopicSelectors
  return (
    <>
    {showAlert && <CustomModal clickAction={handleModalAction} alertText={alertText} />}
    <AffinityAuthModal open={affinityModalOpen} onClose={handleCloseModal} from={source}/>
      {Boolean(source === "settingsSocial" || source === "social") ? (
        <>
          <Box display='flex' gap={1}>
          {Boolean(isLinkedInMailEnabled && currProvider?.provider === "linkedin" && currProvider?.chromeExtension) && (
            <PTopicSelector
              key={`${currProvider?.provider}ExtensionButton`}
              topic={{name: "Get the Chrome Extension", isActive: false}}
              sx={{ mr: 1 }}
              onToggle={() => window.open('https://chromewebstore.google.com/detail/postilize-digital-assista/eheabolgahkaiemegedcpfeklmdmmbgb', '_blank')}
              pVariant="grey"
              hideIcon
            />
          )}
          {isLoading ? (
              <PTopicSelector
                key="loading"
                topic={{ name: 'Loading...', isActive: false }}
                sx={{ mr: 1 }}
                pVariant="grey"
                hideIcon
              />
            ) : (
              isConnected ? (
                <>
                  <PTopicSelector
                    key={`${currProvider?.provider}ConnectButton`}
                    topic={{ name: newConnectButtonText, isActive: false }}
                    onToggle={(event) => {
                      console.log(event)
                      isConnected
                        ? newHandleDisconnect(newConnectButtonText)
                        : newHandleModal(provider, source, newConnectButtonText);
                    }}
                    pVariant={provider === Providers.CSV ? 'Upload' : (isConnected ? (primary || !isSociallyConnected()) ? 'grey' : 'green' : 'green')}
                    hideIcon
                  />
                </>
              ) : (
                <PTopicSelector
                  key={`${currProvider?.provider}LogInButton`}
                  topic={{ name: `Log in with ${currProvider?.provider === "twitter" ? "X" : currProvider?.provider.charAt(0).toUpperCase() + currProvider?.provider.slice(1)}`, isActive: false }}
                  sx={{ mr: 1 }}
                  onToggle={() => {
                    newHandleModal(provider, source, `Log in with ${currProvider?.provider}`);
                  }}
                  pVariant="grey"
                  hideIcon
                />
              )
            )}
          </Box>
        </>
      ) : (
        <Button
          id={isConnected ? 'step-provider-connected' : 'step-connect-provider'}
          variant='contained'
          onClick={(event) => {
            isConnected ? handleDisconnect(provider, source, event) : handleModal(provider, source, event);
          }}
          sx={{
            py: 1,
            backgroundColor: isConnected ? theme.palette.primary.white : theme.palette.primary.lightBlue,
            color: theme.palette.primary.blue,
            border: isConnected ? '1px #E0E0E0 solid' : '',
            fontSize: '14px',
            fontWeight: 500,
            textTransform: 'none',
            width: '100%',
            borderRadius: '12px',
            boxShadow: 0,
            '&:hover': {
              backgroundColor: theme.palette.primary.blue,
              color: theme.palette.primary.white,
              border: 'none',
              boxShadow: 0,
            },
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color='inherit' />
          ) : (
            provider === Providers.CSV ? 'Upload' : (isConnected ? 'Remove' : 'Connect')
          )}
        </Button>
      )}
    </>
  )
}

export default CustomButton