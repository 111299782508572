import * as React from 'react'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import Http from '../../../http/httpClient'
import EditUserOnboardingModal from './editUserOnboardingModal'
import { UserConst } from './../../../constants/userConst'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function OnboardingItem ({ user }) {
  const [origUserType, setOrigUserType] = React.useState(user.userType)
  const [origStatus, setOrigStatus] = React.useState(user.status)
  const [origEmail, setOrigEmail] = React.useState(user.email)
  const [userType, setUserType] = React.useState(user.userType)
  const [status, setStatus] = React.useState(user.status)
  const [email, setEmail] = React.useState(user.email)
  const [editOpen, setEditOpen] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [snackmessage, setSnackmessage] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const handleOpenEditModal = () => {
    setEditOpen(true)
  }

  const handleCloseEditModal = (event, updatedData) => {
    if (updatedData && (typeof updatedData === 'object')) {
      setIsUpdating(true)
      Http.postData('userOnboarding/update', { updatedData })
        .then((res) => {
          // setSnackmessage(res.message)
          setUserType(updatedData.userType)
          setStatus(updatedData.status)
          setEmail(updatedData.email)
          setOrigUserType(updatedData.userType)
          setOrigStatus(updatedData.status)
          setOrigEmail(updatedData.email)
          alert('Record saved successfully')
        })
        .catch((error) => {
          console.log(error)
          alert(error)
        }).finally(() => {
          setIsUpdating(false)
        })
    } else {
      setUserType(origUserType)
      setStatus(origStatus)
      setEmail(origEmail)
    }
    setEditOpen(false)
  }

  const handleSnackClose = () => {
    setOpen(false)
  }

  return (
    <>
      <StyledTableRow key={user.id}>
        <StyledTableCell component='th' scope='row'>{user.name}</StyledTableCell>
        <StyledTableCell>{userType}</StyledTableCell>
        <StyledTableCell>{email}</StyledTableCell>
        <StyledTableCell>{status}</StyledTableCell>
        {(status === UserConst.OnboardingStatus.BOARDED)
          ? <>
            <StyledTableCell />
          </>
          : <>
            <StyledTableCell>
              {isUpdating
                ? <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
                : <EditIcon sx={{ '&:hover': { cursor: 'pointer' } }} onClick={handleOpenEditModal} />}
              <EditUserOnboardingModal open={editOpen} onClose={handleCloseEditModal} userData={user} />
            </StyledTableCell>
          </>}

      </StyledTableRow>
      {/* <Snackbar
        open={open}
        onClose={handleSnackClose}
        autoHideDuration={3000}
        message={snackmessage}
      /> */}
    </>
  )
}
