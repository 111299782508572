import React, { useState, useEffect } from 'react'
import Grow from '@mui/material/Grow'
import { CircularProgress, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const MessageLoader = ({
  sentences,
  typographyColor,
  progressColor,
  showSpinner = true
}) => {
  const theme = useTheme()
  const [currentSentence, setCurrentSentence] = useState(sentences[0])
  const [grow, setGrow] = useState(true)

  useEffect(() => {
    let index = 0

    const interval = setInterval(() => {
      setGrow(false)
      setTimeout(() => {
        index = (index + 1) % sentences.length
        setCurrentSentence(sentences[index])
        setGrow(true)
      }, 750)
    }, 3000)

    return () => clearInterval(interval)
  }, [sentences])

  // Default colors
  const defaultTypographyColor = theme.palette.primaryCL.Black100
  const defaultProgressColor = theme.palette.primaryCL.Black100

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '60px'
      }}
    >
      <Grow in={grow} timeout={750}>
        <div style={{ marginBottom: '30px' }}>
          <Typography color={typographyColor || defaultTypographyColor}>
            {currentSentence}
          </Typography>
        </div>
      </Grow>
      {showSpinner && (
        <CircularProgress
          style={{ color: progressColor || defaultProgressColor }}
        />
      )}
    </div>
  )
}

export default MessageLoader
