import httpClient from '../../../http/httpClient'
import axios from 'axios';
axios.defaults.headers.common['User-Agent'] = 'Postilize/1.0 (postilize.com)';

const BASE_URL = 'postTemplates'

export const fetchPostTemplates = () => httpClient.getData(`${BASE_URL}/`)
export const fetchPostTemplateById = (id) => httpClient.getData(`${BASE_URL}/${id}`)
export const createPostTemplate = (templateData) => httpClient.postData(BASE_URL, templateData)
export const updatePostTemplate = (id, templateData) => httpClient.putData(`${BASE_URL}/${id}`, templateData)
export const deletePostTemplate = (id) => httpClient.deleteData(`${BASE_URL}/${id}`)
export const importPostTemplates = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/${BASE_URL}/import`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    }).then(res => res.data);
};

