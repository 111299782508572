import { useState, useEffect, useRef } from 'react';
import { DialogTitle, DialogContent, Button, Dialog, IconButton, Alert, Grid } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useTheme, CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as Back } from '@Assets/Icons/Arrow-Left.svg';
import Http from '@/http/httpClient';

import ChooseGoal from './chooseGoal';
import ChooseList from '@/components/list/chooseList';
import AIListPrompt from '@/components/list/AIListPrompt';
import AIResults from '@/components/list/AIResults';
import ImportCSV from '@/components/list/importCSV';
import ImportCRM from '@/components/list/importCRM';
import CRMFilters from '@/components/list/crmFilters';
import SequenceTemplate from '@/components/sequence/sequenceTemplate';
import ManualContact from '@/components/list/manualContact';
import { validatedContacts } from '@/helper/utilities';
import { ErrorCodes, ErrorMessages } from '@/constants/errorHandlers';

import Dart from '@Assets/Icons/Dart.png';
import File from '@Assets/Icons/File.png';
import Person from '@Assets/Icons/Person.png';
import Star from '@Assets/Icons/Star.png';
import BrandingFlow from './brandingFlow';
import { ProviderType } from '@/constants/constant';
import DateTimePrompt from '@CommonScreens/DateTimePrompt';
import { useCredits } from '@Hooks/useCredits';
import AIListPayload from '@/components/list/AIListPayload';
import { generateAISearchPayload } from '@/helper/apiHelper';
import { addNewSequence } from "@/redux/authSlice";

export default function SequenceModal(props) {
  // Fetch the feature flags from the store to check if the credits-ui feature is enabled
  const dispatch = useDispatch();
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  // Function to check if a feature is enabled
  const isFeatureEnabled = (featureName) =>
    featureFlags.some((flag) => flag.name === featureName && flag.isFeatureEnabled);
  // Feature flag to disable this feature until it's ready
  const showCreditInfo = isFeatureEnabled('credit-ui');
  const noContactSequenceOption = isFeatureEnabled('createSequenceNoContacts');
  const theme = useTheme();
  const { onClose, open, setCreatedSequenceId, templates, templateSteps, strategies, targetAudience, availableList } =
    props;

  const audienceCount = Object.values(targetAudience || []).filter((value) => value === true).length;
  const [resetStep, setResetStep] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isUpdateProgress, setIsUpdateProgress] = useState(false);
  const [listItem, setListItem] = useState({});
  const [sequenceData, setSequenceData] = useState({
    sequenceName: strategies.length === 1 ? strategies?.[0]?.id : '',
    targetLists: [],
    targetContacts: [],
    template: null,
    timing: null,
    listItem: {},
  });

  const { credits, creditFetchError, areCreditsLoaded, creditHistory } = useCredits();

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [emailDrafted, setEmailDrafted] = useState(false);
  const childComponentRef = useRef();
  const [goalType, setGoalType] = useState(strategies.length === 1 ? strategies?.[0]?.id : null);
  const [initialLoading, setInitialLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [brandingModalOpen, setBrandingModalOpen] = useState(false);
  const [aiSearchPayload, setAiSearchPayload] = useState({});
  const [aiPayloadFormData, setAiPayloadFormData] = useState({});
  const [audienceType, setAudienceType] = useState(
    [
      {
        id: 'AI',
        icon: Star,
        title: 'Start with AI',
        subtitle: 'Quickly create targeted lists.',
        active: false,
        popular: true,
      },
      {
        id: 'ManualContact',
        icon: Person,
        title: 'Add people manually',
        subtitle: 'Simple way to add an audience.',
        active: false,
      },
      {
        id: 'csv',
        icon: File,
        title: 'Import CSV file',
        subtitle: 'Easily add your marketing lists.',
        active: false,
      },
      {
        id: 'crm',
        icon: Dart,
        title: 'From your CRM',
        subtitle: 'HubSpot, Salesforce, Affinity.',
        active: false,
      },
      {
        id: 'empty',
        icon: Person,
        title: 'Create an Assistant with an Empty Contact List',
        subtitle: 'Start from scratch with no pre-existing contacts.',
        active: false,
      },
    ].filter((item) => {
      // If the feature flag is not enabled, exclude the 'empty' option
      if (item.id === 'empty') {
        return noContactSequenceOption;
      }

      // Otherwise, use the existing filter condition
      return targetAudience ? targetAudience[item.id.toLowerCase()] : true;
    }),
  );
  const [providerActiveList, setProviderActiveList] = useState([]);
  const continueStyle = {
    'maxWidth': '200px',
    'backgroundColor': theme.palette.primary.blue,
    'color': theme.palette.primary.white,
    'textTransform': 'none',
    'width': '167px',
    'py': 1,
    'px': 2,
    'mr': 2,
    'mt': 2,
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    'borderRadius': 2,
    'boxShadow': '0px 4px 8px 0px #2C64E31F',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.blue, 0.8),
    },
  };

  const setStep = (step) => {
    const stepToSet = acquisitionSteps?.find((item) => item.id === step);
    setCurrentStep(stepToSet);
  };

  const handleBack = () => {
    if (!currentStep) {
      return;
    }

    const nextContent = null;

    switch (currentStep.id) {
      case 'CHOOSE_TIMING':
        setStep('SEQUENCE_TEMPLATE');
        break;
      case 'SEQUENCE_TEMPLATE':
        if (listItem?.providers?.length > 0) {
          const providerType = listItem?.providers?.[0]?.providerType;
          if (providerType === 'AI') {
            setStep('AI_RESULT');
          } else if (providerType === 'csv') {
            setStep(providerType);
          } else if (providerType === 'ManualContact') {
            setShowSaveButton(true);
            setStep(providerType);
          } else if (providerType === 'empty') {
            setStep('TARGET_LIST');
          } else {
            setStep('CRM_FILTERS');
          }
        } else if (listItem?.lisId) {
          if (availableList.length > 1) {
            setStep('TARGET_LIST');
          } else {
            setStep('CHOOSE_GOAL');
          }
        }
        break;
      case 'CRM_FILTERS':
        setStep('crm');
        break;
      case 'AI_RESULT':
        setStep('AI_PAYLOAD');
        break;
      case 'AI_PAYLOAD':
        setStep('AI');
        break;
      case 'AI':
      case 'csv':
      case 'crm':
      case 'empty':
      case 'ManualContact':
        if (currentStep.id === 'ManualContact' && listItem.providers?.[0].providerType === 'ManualContact') {
          setShowSaveButton(false);
        }

        if (audienceCount === 1) {
          if (strategies.length === 1) {
            handleClose();
          } else {
            setStep('CHOOSE_GOAL');
          }
        } else {
          setStep('TARGET_LIST');
        }
        break;
      case 'TARGET_LIST':
        setShowSaveButton(false);
        if (strategies.length === 1) {
          handleClose();
        } else {
          setStep('CHOOSE_GOAL');
        }
        break;
      default:
        handleClose();
    }
  };

  useEffect(() => {
    if (showCreditInfo) {
      if (!creditFetchError && areCreditsLoaded) {
        console.log(`sequenceModal.js -> credits: ${credits}`);
        console.log(`sequenceModal.js -> creditHistory: ${creditHistory}`);
      } else {
        console.error(`sequenceModal.js -> creditFetchError: ${creditFetchError}`);
        console.error(`sequenceModal.js -> areCreditsLoaded: ${areCreditsLoaded}`);
        console.error(`sequenceModal.js -> credits: ${credits}`);
      }
    }
  }, [credits]);

  const doesUserHaveCredits = () => {
    if (credits?.available > 0) {
      return true;
    }
    return false;
  };

  const handleListCreation = () =>
    new Promise((resolve, reject) => {
      const listId = listItem?.lisId;

      // Copy and pasted from: simpleSetupComponents/listModal.js
      if (listItem.provider === 'rocketreach') {
        listItem.name = listItem.providers[0].AIRequest;
      } else {
        listItem.name = listItem.provider;
      }

      if (listId) {
        resolve(listId);
      } else {
        Http.postData('createList', { listDetails: listItem })
          .then((res) => {
            if (res.status) {
              resolve(res.listId);
            } else {
              reject('Error creating list.');
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          })
          .finally(() => {});
      }
    });

  const handleSaveAndAddAnother = (addAnother = true) => {
    const result = validatedContacts(contactList, !addAnother);
    if (result.code === ErrorCodes.VALID_CONTACT) {
      const validContact = result.contact;
      if (validContact) {
        setListItem((prev) => ({
          ...prev,
          selectedContacts: [...(prev.selectedContacts ?? []), validContact],
        }));

        if (addAnother) {
          childComponentRef.current.addSavedContact(validContact);
        } else {
          setShowSaveButton(false);
        }
        setContactList([]);
        return true;
      }
    } else {
      if (!addAnother && listItem?.selectedContacts?.length > 0) {
        return true;
      }

      setAlertMessage(ErrorMessages[result.code]);
      setShowAlert(true);
    }
    return false;
  };

  const validGiftChoosen = (steps) => {
    let giftChoosen = true;
    steps.forEach((step) => {
      if (
        step.id === 'gift' &&
        !step.emailTemplate?.giftName &&
        !step.emailTemplate?.giftPrice &&
        !step.emailTemplate?.giftDetails?.touchId
      ) {
        setAlertMessage('Please choose a gift.');
        setShowAlert(true);
        return (giftChoosen = false);
      }
    });

    return giftChoosen;
  };

  useEffect(() => {
    if (aiSearchPayload && Object.keys(aiSearchPayload).length > 0) {
      // Payload is ready, now transition to AI_PAYLOAD
      setStep('AI_PAYLOAD');
    }
  }, [aiSearchPayload]);

  const handleNext = (event) => {
    if (!currentStep) {
      return;
    }

    switch (currentStep.id) {
      case 'CHOOSE_GOAL':
        if (sequenceData?.sequenceName && sequenceData.sequenceName.length > 0) {
          if (audienceCount === 1) {
            const stepToSet = handleUpdateAudienceStep();
            setCurrentStep(stepToSet);
          } else if (sequenceData.sequenceName === 'grow') {
            // Branding sequence
            setBrandingModalOpen(true);
          } else {
            setStep('TARGET_LIST');
          }
        } else {
          setAlertMessage('Please choose the goal for your sequence');
          setShowAlert(true);
        }

        const DEFAULT_SEQUENCE_NAMES = {
          acquire: 'Business Development Assistant',
          retain: 'Relationship Growth Assistant',
          grow: 'Social Brand Building Assistant',
        };
        setSequenceData((prev) => ({ ...prev, sequenceName: DEFAULT_SEQUENCE_NAMES[sequenceData.sequenceName] }));
        break;
      case 'TARGET_LIST':
        if (listItem?.providers?.length > 0) {
          const providerType = listItem?.providers?.[0]?.providerType;
          if (providerType === 'ManualContact') {
            setShowSaveButton(true);
          } else if (providerType === 'empty') {
            setStep('SEQUENCE_TEMPLATE');
            break;
          }
          setStep(providerType);
        } else if (listItem?.lisId) {
          setStep('SEQUENCE_TEMPLATE');
        } else {
          setAlertMessage('Please choose the contacts provider.');
          setShowAlert(true);
        }
        break;
      case 'AI':
        const aiProvider = listItem?.providers?.find((provider) => provider.providerType === 'AI');
        if (aiProvider?.AIRequest?.length > 0) {
          setIsLoading(true); // Show loading indicator

          generateAISearchPayload(aiProvider.AIRequest)
            .then((response) => {
              if (!response.error) {
                setAiSearchPayload(response.payload);
                setIsLoading(false);
              } else {
                setIsLoading(false);
                setAlertMessage(response.error);
                setShowAlert(true);
              }
            })
            .catch((error) => {
              console.error('Error:', error);
              setIsLoading(false);
              setAlertMessage('Error generating AI search payload.');
              setShowAlert(true);
            });
        } else {
          setAlertMessage('Please input your required prompt.');
          setShowAlert(true);
        }
        break;

      case 'AI_PAYLOAD':
        setStep('AI_RESULT');
        break;
      case 'crm':
        const filteredList = listItem?.providers?.find((provider) =>
          ProviderType.IMPORT_LIST_FILTERS.includes(provider.provider),
        );
        const crmProvider = providerActiveList?.find(
          (provider) => provider.title.toLowerCase() === filteredList?.provider,
        );
        if (crmProvider?.connected) {
          setStep('CRM_FILTERS');
        } else {
          setAlertMessage('Please select the crm provider.');
          setShowAlert(true);
        }
        break;
      case 'ManualContact':
        const step = handleSaveAndAddAnother(false) ? 'SEQUENCE_TEMPLATE' : 'ManualContact';
        setStep(step);
        break;
      case 'AI_RESULT':
        if (listItem?.selectedContacts?.length > 0) {
          setStep('SEQUENCE_TEMPLATE');
        } else {
          setAlertMessage('Please wait for the AI to generate the search.');
          setShowAlert(true);
        }
        break;
      case 'csv':
      case 'CRM_FILTERS':
        setStep('SEQUENCE_TEMPLATE');
        break;
      case 'SEQUENCE_TEMPLATE':
        const buttonText = event.target?.innerText.trim();
        if (buttonText === 'Draft Email') {
          childComponentRef.current
            .generateSequenceTemplateEmail()
            .then((result) => {
              if (result === 'Success') {
                setEmailDrafted((prev) => false);
              }
            })
            .catch((error) => {
              console.error('Error generating emails:', error);
            });
        } else if (buttonText === 'Continue') {
          const giftStep = sequenceData.template?.steps?.filter((s) => s.id === 'gift');
          const isValidStep = giftStep ? validGiftChoosen(giftStep) : true;
          if (isValidStep) setStep('CHOOSE_TIMING');
        }

        setShowSaveButton(false);
        break;
      case 'CHOOSE_TIMING':
        if (!currentStep.next && sequenceData.timing) {
          setIsUpdateProgress(true);
          handleListCreation()
            .then((listId) => {
              sequenceData.targetLists = [listId];
              sequenceData.template.steps.forEach((item) =>
                item.emailTemplate ? (item.emailTemplate.reviewed = true) : 'noop',
              );
              Http.postData('storeSequenceDetails', { sequenceDetails: sequenceData })
                .then((res) => {
                  if (res.status) {
                    setCreatedSequenceId(res.sequenceId);
                    const newSequence = {
                      _id: res.sequenceId,
                      name: sequenceData.sequenceName,
                      status: "Active",
                      startTime: sequenceData.timing,
                      createdAt: new Date().toISOString(),
                      skipSensitive: sequenceData.template.skipSensitive,
                      template: sequenceData.template,
                      contacts: {},
                    };

                    dispatch(addNewSequence(newSequence));                    
                    setIsUpdateProgress(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {
                  handleClose();
                });
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        } else {
          setAlertMessage('Select a time');
          setShowAlert(true);
        }
        break;
      default:
        console.log('no case matched');
    }
  };

  const sequenceDateTime = sequenceData?.timing?.date;

  const acquisitionSteps = [
    {
      id: 'CHOOSE_GOAL',
      stepId: null,
      title: 'Choose a goal',
      content: (
        <ChooseGoal
          setSequenceName={(name) => {
            setSequenceData((prev) => ({ ...prev, sequenceName: name }));
            setGoalType(name);
          }}
          sequenceData={sequenceData}
          templates={templates}
          defaultStrategies={strategies}
        />
      ),
      next: 'true',
    },
    {
      id: 'TARGET_LIST',
      stepId: null,
      title: 'Select who this sequence will target',
      content: (
        <ChooseList
          listItem={listItem}
          audienceType={audienceType}
          setTargetListItem={(item) => {
            setListItem((prev) => ({
              ...prev,
              providers: item?.providers,
              name: item?.name,
              provider: item?.provider,
              lisId: item?.lisId,
            }));
          }}
          sequenceData={sequenceData}
          targetAudience={targetAudience}
          existingList={availableList}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'AI',
      stepId: null,
      title: 'Start with AI',
      content: (
        <AIListPrompt
          setAiPrompt={(description) => {
            setListItem((prev) => {
              const providers = (prev.providers || []).slice();
              const index = providers.findIndex((provider) => provider.providerType === 'AI');
              if (index !== -1) {
                providers[index] = {
                  ...providers[index],
                  provider: 'rocketreach',
                  AIRequest: description,
                  isExpanded: false,
                };
              }

              return {
                ...prev,
                providers,
                provider: 'rocketreach',
                selectedContacts: [],
                allContacts: [],
              };
            });
          }}
          listItem={listItem}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'AI_PAYLOAD',
      stepId: null,
      title: 'Start with AI',
      content: (
        <AIListPayload
          updateFormData={(data) => setAiPayloadFormData(data)}
          formData={aiPayloadFormData}
          aiSearchPayload={aiSearchPayload}
          setAiSearchPayload={(data) => setAiSearchPayload(data)}
          setAiPrompt={(description) => {
            setListItem((prev) => {
              const providers = (prev.providers || []).slice();
              const index = providers.findIndex((provider) => provider.providerType === 'AI');
              if (index !== -1) {
                providers[index] = {
                  ...providers[index],
                  provider: 'rocketreach',
                  AIRequest: description,
                  isExpanded: false,
                };
              }

              return {
                ...prev,
                providers,
                provider: 'rocketreach',
                selectedContacts: [],
                allContacts: [],
              };
            });
          }}
          listItem={listItem}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'AI_RESULT',
      stepId: null,
      title: 'Start with AI',
      content: (
        <AIResults
          formData={aiPayloadFormData}
          listItem={listItem}
          setselectedTargetContacts={(c) => {
            setListItem((prev) => ({
              ...prev,
              selectedContacts: c.selectedContacts,
              allContacts: c.allContacts,
            }));
          }}
          credits={credits}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'csv',
      stepId: null,
      title: 'Start with CSV',
      content: <ImportCSV setSelectedItem={setListItem} listItem={listItem} />,
      next: 'true',
      back: 'true',
    },
    {
      id: 'crm',
      stepId: null,
      title: 'Start with CRM',
      content: (
        <ImportCRM setSelectedItem={setListItem} listItem={listItem} setProviderActiveList={setProviderActiveList} />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'CRM_FILTERS',
      stepId: null,
      title: 'Start with CRM',
      content: <CRMFilters listItem={listItem} setSelectedItem={setListItem} />,
      next: 'true',
      back: 'true',
    },
    {
      id: 'ManualContact',
      stepId: null,
      title: 'Create manual contact list',
      content: (
        <ManualContact
          listItem={listItem}
          setContactList={setContactList}
          ref={childComponentRef}
          setListItem={setListItem}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'SEQUENCE_TEMPLATE',
      stepId: null,
      title: 'Start with CRM',
      content: (
          <SequenceTemplate
            selectedGoalType={goalType}
            sequenceData={sequenceData}
            ref={childComponentRef}
            setSequenceTemplate={(templates) => {
              setSequenceData((prev) => ({
                ...prev,
                template: templates.template,
              }));
            }}
            setIsEmailLoading={setIsLoading}
            setEmailDrafted={setEmailDrafted}
            setSequenceData={setSequenceData}
          />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'CHOOSE_TIMING',
      stepId: null,
      title: 'How soon should we begin when a new contact is added?',
      content: (
        // We expect the parent component to define the bounds for our Common Screens.
        // This grid container is necessary until this SequenceModal is refactored to use PModals.
        <Grid container>
          <Grid xs={3} />
          <Grid xs={6}>
            <DateTimePrompt
              handleDateTimeChange={(item) => setSequenceData((prev) => ({ ...prev, timing: item }))}
              initialDateTime={sequenceDateTime}
              headerMessage="When do you want this assistant to begin?"
              subheaderMessage="You can pause it at any time."
            />
          </Grid>
          <Grid xs={3} />
        </Grid>
      ),
      next: null,
      back: 'true',
    },
  ];

  const [sequenceSteps, setSequenceSteps] = useState([...acquisitionSteps]);

  const handleClose = () => {
    onClose();
    setResetStep((prev) => !prev);
    setIsLoading(false);
    setIsUpdateProgress(false);
    setShowAlert(false);
    setAlertMessage('');
    setSequenceData({
      sequenceName: '',
      targetLists: [],
      targetContacts: [],
      template: null,
      timing: {},
    });
  };

  const handleBrandingModalClose = ({ fullClose } = {}) => {
    setBrandingModalOpen(false);
    setStep('CHOOSE_GOAL');
    if (fullClose) {
      handleClose();
    }
  };

  const fetchSequenceStep = async (sequenceId) => {
    try {
      const response = await Http.getData('getSequenceDetails', { seqId: sequenceId });
      if (response) {
        setSequenceData((prevSequenceData) => ({
          ...prevSequenceData,
          template: {
            id: sequenceData.template?.steps[0]?.type,
            title: sequenceData.template?.title,
            desc: sequenceData.template?.desc,
            steps: response.steps,
          },
          timing: sequenceData.template?.startType,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateAudienceStep = () => {
    const activeList = audienceType.find((audience) => targetAudience[audience.id.toLowerCase()]);

    if (activeList) {
      setListItem((prev) => ({
        ...prev,
        providers: [
          {
            providerType: activeList.id,
            provider: activeList.id,
            isExpanded: false,
            enrichmentCompleted: false,
          },
        ],
        name: sequenceData?.sequenceName,
        provider: activeList.id,
      }));

      const providerType = activeList.id;
      if (providerType === 'ManualContact') {
        setShowSaveButton(true);
      }
      const stepToSet = acquisitionSteps?.find((item) => item.id?.toLowerCase() === providerType.toLowerCase());

      return stepToSet;
    }
    if (targetAudience.existing) {
      if (availableList.length === 1) {
        const availaList = availableList?.[0];
        setListItem((prev) => ({ ...prev, name: sequenceData?.sequenceName, lisId: availaList._id }));

        return acquisitionSteps?.find((item) => item.id === 'SEQUENCE_TEMPLATE');
      }
      return acquisitionSteps?.find((item) => item.id === 'TARGET_LIST');
    }
    // None of the case matched. redirect to target list
    return acquisitionSteps?.find((item) => item.id === 'TARGET_LIST');
  };

  const calculateNextStep = () => {
    let nextStep = null;
    if (strategies.length === 1) {
      if (audienceCount === 1) {
        nextStep = handleUpdateAudienceStep();
      } else {
        nextStep = acquisitionSteps[1];
      }
    } else {
      nextStep = acquisitionSteps[0];
    }

    return nextStep;
  };

  useEffect(() => {
    const nextStep = calculateNextStep();
    setCurrentStep(nextStep);
  }, [resetStep]);

  useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  useEffect(() => {
    const nextStep = calculateNextStep();
    setCurrentStep(nextStep);
    setInitialLoading(true);
  }, []);

  return (
    <>
      {brandingModalOpen ? (
        <BrandingFlow onClose={handleBrandingModalClose} />
      ) : (
        <Dialog onClose={() => onClose()} open={open} fullScreen sx={{ width: '100vw' }}>
          <DialogTitle sx={{ p: 0 }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <IconButton aria-label="close" onClick={handleBack} sx={{ pt: 2, pl: 2 }}>
                  <Back fill={theme.palette.primary.black} />
                </IconButton>
              </Grid>
              <Grid item>{showAlert && <Alert severity="error">{alertMessage}</Alert>}</Grid>
              <Grid item>
                {showSaveButton && (
                  <Button
                    onClick={handleSaveAndAddAnother}
                    sx={{
                      'maxWidth': '200px',
                      'backgroundColor': theme.palette.primary.lightBlue,
                      'color': theme.palette.primary.blue,
                      'textTransform': 'none',
                      'width': '167px',
                      'py': 1,
                      'px': 2,
                      'mx': 2,
                      'mt': 2,
                      'whiteSpace': 'nowrap',
                      'overflow': 'hidden',
                      'textOverflow': 'ellipsis',
                      'borderRadius': 2,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.white,
                        color: theme.palette.primary.blue,
                      },
                    }}
                  >
                    Save & add another
                  </Button>
                )}
                {/* This appears to be the continue button here */}
                <Button onClick={handleNext} sx={continueStyle} disabled={isLoading}>
                  {isUpdateProgress ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : initialLoading && currentStep?.id === 'SEQUENCE_TEMPLATE' && emailDrafted ? (
                    'Draft Email'
                  ) : (
                    'Continue'
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={12}>
                <LinearProgress
                  variant={isLoading ? 'indeterminate' : 'determinate'}
                  value={75}
                  sx={{
                    my: 2,
                    height: 6,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                      backgroundColor: theme.palette.primary.lightBlue,
                    },
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: '#87E554',
                    },
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
            {initialLoading && currentStep?.content}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
