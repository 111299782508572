import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';

import PToolTip from './PToolTip';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Black100,
        background: theme.palette.primaryCL.White110,
        hoverText: theme.palette.primaryCL.Blue100,
        hoverBackground: theme.palette.primaryCL.White110,
        activeText: theme.palette.primaryCL.Blue100,
        activeBackground: theme.palette.primaryCL.White100,
      };
      break;

    case 'black':
      colorScheme = {
        text: theme.palette.primaryCL.White110,
        background: theme.palette.primaryCL.Black200,
        hoverText: theme.palette.primaryCL.White110,
        hoverBackground: theme.palette.primaryCL.Black200,
        activeText: theme.palette.primaryCL.White110,
        activeBackground: theme.palette.primaryCL.Black200,
      };
      break;

    default:
      colorScheme = {
        text: theme.palette.primaryCL.Black100,
        background: theme.palette.primaryCL.White110,
        hoverText: theme.palette.primaryCL.Blue100,
        hoverBackground: theme.palette.primaryCL.White110,
        activeText: theme.palette.primaryCL.Blue100,
        activeBackground: theme.palette.primaryCL.White100,
      };
    // Add more cases for 'outlined', 'plain', etc.
  }

  return colorScheme;
};

const StyledPSideMenuItem = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'pVariant',
})(({ theme, pVariant, active, post, showLabel }) => {
  const {
    background,
    text,
    hoverBackground,
    hoverText,
    activeBackground,
    activeText
  } = getVariantStyles(theme, pVariant);

  return {
    ...theme.typography.bold.body2,
    borderRadius: '14px',
    padding: showLabel ? '16px 20px' : '16px',
    fontFamily: 'Inter',
    textTransform: 'none',
    justifyContent: showLabel ? (post ? 'center' : 'flex-start') : 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '100%',
    color: active ? activeText : text,
    backgroundColor: active ? activeBackground : background,
    '&:hover': {
      backgroundColor: hoverBackground,
      color: hoverText,
      '& .MuiButton-startIcon': {
        '& *': {
          fill: hoverText,
        },
      },
    },
    '& .MuiButton-startIcon': {
      margin: 0,
      marginRight: showLabel ? '8px' : '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        margin: '0 !important',
      },
    },
  };
});

function PSideMenuItem({ children, Icon, pVariant, disabled, onClick, active, showLabel, post }) {
  const theme = useTheme();
  const iconFill = (Icon && (active ? getVariantStyles(theme, pVariant).activeText : getVariantStyles(theme, pVariant).text)) || null;

  // Map our variant to the MUI variant
  const translatedVariant = {
    primary: 'contained',
  }[pVariant] || 'contained';

  const menuItem = (
    <StyledPSideMenuItem
      variant={translatedVariant}
      disableElevation
      pVariant={pVariant}
      startIcon={Icon ? <Icon fill={iconFill} /> : null}
      onClick={onClick}
      active={active}
      post={post}
      showLabel={showLabel}
    >
      {showLabel && children}
    </StyledPSideMenuItem>
  );

  return showLabel ? (
    menuItem
  ) : (
    <PToolTip title={children} placement="right">
      {menuItem}
    </PToolTip>
  );
}

PSideMenuItem.propTypes = {
  /** React children */
  children: PropTypes.node,

  /** Icon component to display in the button.
   * NOTE: Storybook does not support React components in controls */
  Icon: PropTypes.elementType,

  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary']),

  /** Click handler */
  onClick: PropTypes.func.isRequired,
};

PSideMenuItem.defaultProps = {
  Icon: null,
  pVariant: 'primary',
  showLabel: true,
  active: false,
};

export default PSideMenuItem;
