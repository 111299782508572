import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
  Button,
  Divider,
  Typography,
  Paper,
} from "@mui/material";

import { ReactComponent as ArrowLeft } from "../../assets/Icons/Arrow-Left.svg";

import Bio from "./components/bio";
import CRM from "./components/crm";
import Social from "./components/social";
import Tone from "./components/tone";
import Topics from "./components/topics";
import Support from "./components/support";
import Http from "../../http/httpClient";
import OptionsDrawer from "./components/optionsDrawer";
import Lists from "../Lists/lists";

const SettingsDrawer = ({ open, closeSettingsNav }) => {
  const navigate = useNavigate();
  const [openOptionsDrawer, setOpenOptionsDrawer] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const theme = useTheme();
  const menu = [
    { title: "About", page: <Bio /> },
    { title: "Tone", page: <Tone /> },
    { title: "Topics", page: <Topics /> },
    { title: "CRM", page: <CRM /> },
    { title: "Social", page: <Social /> },
    { title: "List", page: <Lists /> },
    { title: "Support", page: <Support /> },
  ];

  const handleSignout = () => {
    Http.postData("/auth/logout").then((res) => {
      navigate("/");
    });
  };
  const handleSettingsItem = (title) => {
    console.log(title);
    setSelectedTitle(title);
    setOpenOptionsDrawer(!openOptionsDrawer);
  };
  const closeOptionsDrawer = () => {
    setOpenOptionsDrawer(false);
  };

  const handleBack = () => {
    closeSettingsNav();
  };

  return (
    <>
      <Drawer open={open} onClose={closeSettingsNav}>
        <Paper
          sx={{
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            backgroundColor: theme.palette.primary.lightGrey,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleBack}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: theme.palette.primary.blue,
            }}
          >
            <ArrowLeft fill={theme.palette.primary.blue} />
            <Typography sx={{ fontWeight: "700" }}>Back</Typography>
          </IconButton>
          <List sx={{ marginTop: "70px" }}>
            {menu.map((item, index) => (
              <div key={item.title}>
                <Divider orientation="horizontal" />

                <ListItemButton
                  onClick={() => handleSettingsItem(item.title)}
                  sx={{
                    fontWeight: 500,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    my: 2,
                    mx: 2,
                    borderRadius: 2,
                    color: theme.palette.primary.grey,
                    backgroundColor: theme.palette.primary.lightGrey,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ListItemText
                      primary={item.title}
                      sx={{
                        fontWeight: 500,
                        color: theme.palette.primary.grey,
                      }}
                    />
                  </div>
                </ListItemButton>
              </div>
            ))}
            <Divider orientation="horizontal" />
            <Button
              onClick={handleSignout}
              sx={{
                textTransform: "none",
                fontWeight: 700,
                borderRadius: 2,
                width: "90%",
                backgroundColor: theme.palette.primary.lightBlue,
                color: theme.palette.primary.blue,
                mx: 2,
                my: 2,
              }}
            >
              Sign Out
            </Button>
          </List>
        </Paper>
      </Drawer>
      {/* <IconButton
        sx={{ color: theme.palette.primary.grey }}
        onClick={() => {}}
      ></IconButton> */}
      <OptionsDrawer
        open={openOptionsDrawer}
        closeOptionsDrawer={closeOptionsDrawer}
        selectedTitle={selectedTitle}
      />
    </>
  );
};

export default SettingsDrawer;
