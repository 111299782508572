import * as React from "react";
import { ReactComponent as XLogoCircle } from "../../assets/logos/XLogoCircle.svg";
import { ReactComponent as LinkedInLogo } from "../../assets/logos/LinkedInLogoCircle.svg";
import { Providers } from "../../constants/constant";
import PTypography from "../../components/library/PTypography";
import PCard from "../../components/library/PCard";
import { GetConnectedProviders } from "../../helper/cookieManager";
import { handleAuthentication } from "../../helper/authUtility";

export default function Socials({ selectedSocials, setSelectedSocials }) {
  const [connectedProviders, setConnectedProviders] = React.useState([]);

  const availableSocialProviders = {
    LinkedIn: {
      logo: LinkedInLogo,
      label: "LinkedIn",
      provider: Providers.LINKED_IN,
      authEndpoint: "/api/auth/linkedin?via=social",
    },
    Twitter: {
      logo: XLogoCircle,
      label: "X (Twitter)",
      provider: Providers.TWITTER,
      authEndpoint: "/api/auth/twitter?via=social",
    },
  };

  React.useEffect(() => {
    refreshConnectedProviders();
  }, []);

  const refreshConnectedProviders = () => {
    const connectedProvs = GetConnectedProviders();
    setConnectedProviders(connectedProvs.map((provider) => provider.provider));
  }

  const toggleSocial = (social) => {
    if (setSelectedSocials) {
      setSelectedSocials([social]);
    }
  };

  const renderCard = (socialObject) => {
    const connected = connectedProviders.includes(socialObject.provider);
    const clickHandler = () => {
      if (connected) {
        toggleSocial(socialObject.provider);
      }
      else {
        handleAuthentication(socialObject.authEndpoint, refreshConnectedProviders);
      }
    }

    return (
      <PCard
        key={socialObject.label}
        title={socialObject.label}
        Icon={socialObject.logo}
        onClick={clickHandler}
        row={true}
        selected={false}
        button={true}
        buttonize={true}
      />
    );
  };

  return (
    <>
      <div style={{ marginBottom: "32px" }}>
        <PTypography size="h3" weight="bold">
          Where would you like to post?
        </PTypography>
      </div>

      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}>
        <PTypography size="body1" weight="bold">
          Choose one
        </PTypography>
        {Object.keys(availableSocialProviders).map((socialName) => renderCard(availableSocialProviders[socialName]))}
      </div>
    </>
  );
}