import { useMemo, useState, useEffect, useRef } from 'react';
import { Box, IconButton, InputBase, Paper } from '@mui/material';
import PTopicSelector from './PTopicSelector';
import { ReactComponent as Search } from '@Assets/Icons/Search.svg';
import { useTheme } from '@mui/material';
import PTypography from '@Library/PTypography';

const inputStyles = {
  'flexGrow': 1,
  'display': 'flex',
  'alignItems': 'center',
  'flexWrap': 'wrap',
  'width': '100%',
  '& > *': {
    flex: '0 0 auto',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  'scrollbarWidth': 'none',
  '-ms-overflow-style': 'none',
};
const boxStyle = { mr: 1, my: 0.5 };
const iconStyle = { width: 2.5, height: 2.5 };
const typographyStyle = {
  mb: 1,
};
const iconButtonStyle = { pr: 1, width: 'auto' };

const inputStyle = { ml: 1, p: 1 };
const PChipInputField = ({ values, label, placeholder, onChipDelete, onClick, style, onChange, value, autoFocus }) => {
  const [isInputFocused, setInputFocused] = useState(false);
  const inputRef = useRef(null);
  const THEME = useTheme();
  const layoutStyle = useMemo(
    () => ({
      cursor: 'pointer',
      width: '100%',
      ...style,
    }),
    [style],
  );
  const paperFormStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 0,
    py: 1,
    px: '12px',
    borderRadius: '14px',
    border: `1px solid ${isInputFocused ? THEME.palette.primary.blue : THEME.palette.primaryCL.Black70}`,
  };

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const renderIcon = (
    <IconButton onClick={onClick} sx={iconButtonStyle}>
      <Search fill={THEME.palette.primaryCL.Black100} sx={iconStyle} />
    </IconButton>
  );

  const topicSelectors = useMemo(
    () =>
      values.map((value) => (
        <Box sx={boxStyle}>
          <PTopicSelector
            key={value}
            topic={{ name: value, isActive: true }}
            onToggle={() => onChipDelete(value)}
            pVariant="default"
          />
        </Box>
      )),
    [values, onChipDelete],
  );

  return (
    <Box>
      {label && (
        <PTypography size="body2" weight="bold" sx={typographyStyle}>
          {label}
        </PTypography>
      )}
      <Paper component="form" sx={paperFormStyle}>
        <Box onClick={onClick} sx={layoutStyle}>
          <Box sx={inputStyles}>
            {renderIcon}
            {topicSelectors}
            <InputBase
              type="text"
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              inputRef={inputRef}
              sx={inputStyle}
              onKeyDown={handleEnterKeyPress}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

PChipInputField.defaultProps = {
  values: [],
  autoFocus: false,
};

export default PChipInputField;
