import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  CssBaseline,
  Stack,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
  Box,
} from '@mui/material';

import Http from '@/http/httpClient';
import { getListContacts } from '@/helper/apiHelper';
import { PTypography, PButton, PYesNoDialog } from '@Library';
import DelegationListDisplay from '@/pages/Lists/components/DelegationListDisplay';
import { setDelegationListContacts, setDelegationLists } from '@/redux/authSlice';

export default function DelegationLists() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const delegationLists = useSelector((state) => state.auth.delegationLists);
  const delegationListContacts = useSelector((state) => state.auth.delegationListContacts);
  const delegationAccessId = useSelector((state) => state.auth.delegationAccessId);
  const companyDetails = useSelector((state) => state.auth.company);
  const memoizedDelegationLists = useMemo(() => JSON.parse(JSON.stringify(delegationLists)), [delegationLists]);
  const targetUser = companyDetails?.users?.find((user) => user._id === delegationAccessId);
  const targetUserDisplayString = targetUser?.name || targetUser?.primaryEmail || 'Unknown User';

  const [activeItem, setActiveItem] = useState(memoizedDelegationLists[0]);
  const parentRef = useRef(null);
  const [parentHeight, setParentHeight] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (parentRef.current) {
      setParentHeight(parentRef.current.clientHeight);
    }
  }, [parentRef]);

  useEffect(() => {
    if (!activeItem) {
      dispatch(setDelegationListContacts([]));
      return;
    }

    dispatch(setDelegationListContacts(null));

    const fetchContacts = async () => {
      try {
        const response = await getListContacts(activeItem._id, delegationAccessId);
        if (response && response.status) {
          dispatch(setDelegationListContacts(response.contacts));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchContacts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  const handleDeleteList = useCallback(() => {
    Http.postData(`deleteList/${activeItem?._id}/${delegationAccessId}`)
      .then((res) => {
        if (res && res.status) {
          const filteredLists = memoizedDelegationLists.filter((listDetail) => listDetail._id !== activeItem?._id);
          const selectedItem = filteredLists?.length > 0 ? filteredLists[0] : null;
          setActiveItem(selectedItem);
          dispatch(setDelegationLists(filteredLists));
          dispatch(setDelegationListContacts([]));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [activeItem?._id, delegationAccessId, memoizedDelegationLists, dispatch]);

  const handleListModification = () => {
    setActiveItem({ ...activeItem });
  };

  const handleDeleteClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (confirmed) => {
    setDialogOpen(false);
    if (confirmed) {
      handleDeleteList();
    }
  };

  return (
    <Box sx={{ height: '100%' }} ref={parentRef}>
      <CssBaseline />
      <Stack direction="row" sx={{ maxHeight: '100%' }}>
        <div
          style={{
            height: `${parentHeight}px`,
            maxWidth: '325px',
            width: '325px',
            overflow: 'scroll',
          }}
        >
          <Container
            style={{
              minWidth: '325px',
              width: '325px',
              maxWidth: '325px',
              height: '100%',
              paddingRight: '0px',
              paddingLeft: '0px',
              overflow: 'scroll',
            }}
          >
            <PTypography sx={{ my: 2, ml: 3 }} size="h2" weight="regular">
              {targetUserDisplayString}'s contact lists
            </PTypography>
            <PButton pVariant="secondary" onClick={handleDeleteClick}>
              Delete selected list
            </PButton>
            <List sx={{ flexGrow: 1 }}>
              {memoizedDelegationLists.map((item) => (
                <div key={item.name}>
                  <ListItemButton
                    onClick={() => {
                      setActiveItem(item);
                    }}
                    sx={{
                      'color': activeItem._id === item._id ? theme.palette.primary.blue : theme.palette.primary.grey,
                      'backgroundColor':
                        activeItem._id === item._id ? theme.palette.primary.lightGrey : theme.palette.primary.white,
                      'fontWeight': 500,
                      'display': 'flex',
                      'justifyContent': 'space-between',
                      'alignItems': 'center',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.lightBlue,
                      },
                      'minHeight': '64px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemText primary={item.name} sx={{ fontWeight: 500, paddingLeft: '24px' }} />
                    </div>
                  </ListItemButton>
                  <Divider />
                </div>
              ))}
            </List>
          </Container>
        </div>

        <Divider orientation="vertical" flexItem />

        <div style={{ height: `${parentHeight}px`, width: 'calc(95vw - 400px)', flexGrow: 1, overflow: 'scroll' }}>
          <div
            style={{
              minWidth: '65%',
              height: '100%',
              paddingLeft: '0px',
              paddingRight: '0px',
              overflow: 'scroll',
            }}
          >
            <DelegationListDisplay
              contacts={delegationListContacts}
              listItem={activeItem}
              handleListModification={handleListModification}
            />
          </div>
        </div>
      </Stack>
      <PYesNoDialog
        open={dialogOpen}
        onYes={() => handleDialogClose(true)}
        onNo={() => handleDialogClose(false)}
        message="Delete List?"
        detailedMessage={`Are you sure you want to delete the list "${activeItem?.name}"?`}
        yesText="Delete"
        noText="Cancel"
      />
    </Box>
  );
}
