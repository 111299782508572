import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Pagination,
  Collapse,
  useTheme,
  ListItemText,
  Chip,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAdminFeatureFlags } from '../../hooks/useFeatureFlags';
import { useCompanies } from '../../hooks/useAllCompanies';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import featureFlagService from '../../services/featureFlagService';
import CreateFeatureFlagModal from './featureflags/createFeatureflagModal';

const FeatureFlagPanel = () => {
  const [featureFlags, setFeatureFlags] = useAdminFeatureFlags();
  const [companies, setCompanies] = useCompanies();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [expandedRow, setExpandedRow] = useState(null);
  const [isCreateFeatureFlagModalOpen, setIsCreateFeatureFlagModalOpen] =
    useState(false);
  const theme = useTheme();

  const FeatureFlagRow = ({ flag }) => {
    const isExpanded = expandedRow === flag.name;
    const [editFeatureFlag, setEditFeatureFlag] = useState(flag);
    const handleChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      setEditFeatureFlag({
        ...editFeatureFlag,
        [name]: value,
      });
    };

    // Create a lookup table for company IDs and names
    const companyLookup = companies.reduce((lookup, company) => {
      lookup[company._id] = company.name;
      return lookup;
    }, {});

    const handleSubmit = (event) => {
      event.preventDefault();
      // update the global featureFlags state
      // with the editFeatureFlag
      setFeatureFlags((prevFeatureFlags) => {
        const index = prevFeatureFlags.findIndex(
          (prevFlag) => prevFlag.name === flag.name
        );
        const newFeatureFlags = [...prevFeatureFlags];
        newFeatureFlags[index] = editFeatureFlag;
        return newFeatureFlags;
      });

      // send a api request to update the feature flag we just changed

      const updatedFeatureFlag =
        featureFlagService.updateAdminFeatureFlags(editFeatureFlag);
      console.log(`Updated feature flag ${updatedFeatureFlag}!`);

      // close the edit form/dialog
      setExpandedRow(null);
    };

    const handleExpandClick = () => {
      console.log(`feature flag ${flag} expanded`);
      setExpandedRow(isExpanded ? null : flag.name);
    };

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={isExpanded}
              aria-label="show more"
            >
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {flag.name}
          </TableCell>
          <TableCell>{flag.isFeatureEnabled ? 'True' : 'False'}</TableCell>
          <TableCell>{flag.enabledCompanyIds.length}</TableCell>
          <TableCell>{flag.enabledUserIds.length}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Edit Feature Flag
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    name="name"
                    label="Name"
                    value={editFeatureFlag.name}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isFeatureEnabled"
                        checked={editFeatureFlag.isFeatureEnabled}
                        onChange={handleChange}
                        color="primary"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.primary.blue,
                          },
                        }}
                      />
                    }
                    label="Is Feature Enabled (Global)"
                  />
                  <TextField
                    name="enabledUserIds"
                    disabled={true}
                    label="Enabled User IDs"
                    value={editFeatureFlag.enabledUserIds.join(', ')}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <InputLabel id="enabled-company-ids-label">
                      Enabled Company IDs
                    </InputLabel>
                    <Select
                      labelId="enabled-company-ids-label"
                      id="enabled-company-ids"
                      name="enabledCompanyIds"
                      multiple
                      value={editFeatureFlag.enabledCompanyIds}
                      onChange={handleChange}
                      label="Enabled Company IDs"
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => (
                            <Chip key={value} label={companyLookup[value]} />
                          ))}
                        </div>
                      )}
                    >
                      {companies.map((company) => (
                        <MenuItem key={company._id} value={company._id}>
                          <Checkbox
                            color="secondary"
                            checked={editFeatureFlag.enabledCompanyIds.includes(
                              company._id
                            )}
                          />
                          <ListItemText primary={company.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    name="dependencies"
                    label="Dependencies"
                    disabled={true}
                    value={editFeatureFlag.dependencies.join(', ')}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: theme.palette.primary.blue,
                      color: theme.palette.primary.black,
                      borderColor: theme.palette.primary.blue,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                      },
                      boxShadow: 0,
                      textTransform: 'none',
                      fontWeight: 700,
                      borderRadius: 2,
                      width: '30%',
                      mt: 2,
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </form>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const FeatureFlagHeader = () => (
    <TableRow>
      <TableCell>Name</TableCell>
      <TableCell>isFeatureEnabled</TableCell>
      <TableCell>Enabled Company Count</TableCell>
      <TableCell>Enabled User Count</TableCell>
    </TableRow>
  );

  const paginatedFeatureFlags = featureFlags.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const handleCreateFeatureflagModal = () => {
    setIsCreateFeatureFlagModalOpen(true);
  };

  const handleAddFeatureFlagModalClose = () => {
    setIsCreateFeatureFlagModalOpen(false);
  };

  return (
    <>
      <CreateFeatureFlagModal
        open={isCreateFeatureFlagModalOpen}
        onClose={handleAddFeatureFlagModalClose}
        loading={false}
        companies={companies}
        setFeatureFlags={setFeatureFlags}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: 'white',
            backgroundColor: '#3983F7',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px',
            borderRadius: 10,
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
            },
          }}
          startIcon={<AddIcon />}
          onClick={handleCreateFeatureflagModal}
        >
          Create a Feature Flag
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>{FeatureFlagHeader()}</TableHead>
          <TableBody>
            {paginatedFeatureFlags.map((flag) => (
              <FeatureFlagRow key={flag.name} flag={flag} />
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(featureFlags.length / rowsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
        />
      </TableContainer>
    </>
  );
};

export default FeatureFlagPanel;
