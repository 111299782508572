import { $applyNodeReplacement, createEditor, DecoratorNode } from 'lexical';
import * as React from 'react';
import { Suspense } from 'react';

const ImageComponent = React.lazy(() => import('../components/ImageComponent'));

function isGoogleDocCheckboxImg(img) {
  return (
    img.parentElement != null &&
    img.parentElement.tagName === 'LI' &&
    img.previousSibling === null &&
    img.getAttribute('aria-roledescription') === 'checkbox'
  );
}

function $convertImageElement(domNode) {
  const img = domNode;
  if (img.src.startsWith('file:///') || isGoogleDocCheckboxImg(img)) {
    return null;
  }
  const { alt: altText, src } = img;
  const widthAttr = img.getAttribute('width');
  const heightAttr = img.getAttribute('height');
  const width = widthAttr ? parseInt(widthAttr, 10) || undefined : undefined;
  const height = heightAttr ? parseInt(heightAttr, 10) || undefined : undefined;
  const node = $createImageNode({ altText, height, src, width });
  return { node };
}

export class ImageNode extends DecoratorNode {
  constructor(
    src,
    altText,
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    showCaption = false,
    caption = createEditor({ nodes: [] }),
    captionsEnabled = true,
    key,
    onImageLoad,
    onImageError,
  ) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__width = typeof width === 'number' && !isNaN(width) ? Math.min(Math.max(width, minWidth), maxWidth) : undefined;
    this.__height = typeof height === 'number' && !isNaN(height) ? Math.min(Math.max(height, minHeight), maxHeight) : undefined;
    this.__minWidth = minWidth;
    this.__minHeight = minHeight;
    this.__maxWidth = maxWidth;
    this.__maxHeight = maxHeight;
    this.__showCaption = showCaption;
    this.__caption = caption;
    this.__captionsEnabled = captionsEnabled;
    this.__onImageLoad = onImageLoad;
    this.__onImageError = onImageError;
  }

  static getType() {
    return 'image';
  }

  static clone(node) {
    return new ImageNode(
      node.__src,
      node.__altText,
      node.__width,
      node.__height,
      node.__minWidth,
      node.__minHeight,
      node.__maxWidth,
      node.__maxHeight,
      node.__showCaption,
      node.__caption,
      node.__captionsEnabled,
      node.__key,
      node.__onImageLoad,
      node.__onImageError,
    );
  }

  static importJSON(serializedNode) {
    const {
      altText,
      height,
      width,
      minHeight,
      minWidth,
      maxHeight,
      maxWidth,
      caption,
      src,
      showCaption,
      onImageLoad,
      onImageError,
    } = serializedNode;
    const node = $createImageNode({
      altText,
      width,
      height,
      minHeight,
      minWidth,
      maxHeight,
      maxWidth,
      showCaption,
      src,
      onImageLoad,
      onImageError,
    });
    const nestedEditor = node.__caption;
    const editorState = nestedEditor.parseEditorState(caption?.editorState);
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState);
    }
    return node;
  }

  exportDOM() {
    const element = document.createElement('img');
    element.setAttribute('src', this.__src);
    element.setAttribute('alt', this.__altText);
    if (this?.__width) {
      element.setAttribute('width', this.__width.toString());
    }
    if (this?.__height) {
      element.setAttribute('height', this.__height.toString());
    }
    return { element };
  }

  static importDOM() {
    return {
      img: (node) => ({
        conversion: $convertImageElement,
        priority: 0,
      }),
    };
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      caption: this?.__caption?.toJSON(),
      height: this.__height,
      width: this.__width,
      minHeight: this.__minHeight,
      minWidth: this.__minWidth,
      maxHeight: this.__maxHeight,
      maxWidth: this.__maxWidth,
      showCaption: this.__showCaption,
      src: this.getSrc(),
      type: 'image',
      version: 1,
      onImageLoad: this.__onImageLoad,
      onImageError: this.__onImageError,
    };
  }

  setWidthAndHeight(width, height) {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  setShowCaption(showCaption) {
    const writable = this.getWritable();
    writable.__showCaption = showCaption;
  }

  setOnImageLoad(onImageLoad) {
    const writable = this.getWritable();
    writable.__onImageLoad = onImageLoad;
  }

  setOnImageError(onImageError) {
    const writable = this.getWritable();
    writable.__onImageError = onImageError;
  }

  setSrc(newSrc) {
    const writable = this.getWritable();
    writable.__src = newSrc;
  }

  createDOM(config) {
    const span = document.createElement('span');
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  decorate() {
    return (
      <ImageComponent
        src={this.__src}
        altText={this.__altText}
        {...(this.__width !== undefined ? { width: this.__width } : {})}
        {...(this.__height !== undefined ? { height: this.__height } : {})}
        nodeKey={this.getKey()}
        showCaption={this.__showCaption}
        caption={this.__caption}
        captionsEnabled={false}
        resizable={true}
        onLoad={this.__onImageLoad}
        onError={this.__onImageError}
      />
    );
  }
}

export function $createImageNode({
  altText,
  width,
  height,
  minWidth = 50,
  minHeight = 50,
  maxWidth = 1500,
  maxHeight = 1500,
  captionsEnabled,
  src,
  showCaption,
  caption,
  key,
  onImageLoad,
  onImageError,
}) {
  return $applyNodeReplacement(
    new ImageNode(
      src,
      altText,
      width !== undefined ? Math.min(Math.max(width, minWidth), maxWidth) : undefined,
      height !== undefined ? Math.min(Math.max(height, minHeight), maxHeight) : undefined,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      showCaption,
      caption,
      captionsEnabled,
      key,
      onImageLoad,
      onImageError,
    ),
  );
}

export function $isImageNode(node) {
  return node instanceof ImageNode;
}
