import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";

import { Container, Typography, useTheme } from '@mui/material';
import { GetCookie } from '../../../../helper/cookieManager'
import { UserConst } from '../../../../constants/userConst'
import { ReactComponent as Delete } from '../../../../assets/Icons/Delete.svg';

export default function AddStep({ open, onClose, onSubmit, steps, templateDetails }) {
  const theme = useTheme();
  const [selectedStep, setSelectedStep] = React.useState(templateDetails.content ?? steps);
  const [availableSteps, setAvailableSteps] = React.useState([])
  const curUser = GetCookie('user')
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const recurringEmailFeatureFlag = featureFlags?.find(featureFlag => featureFlag.name === 'enableRecurringEmailStep')
  const isRecurringEmailFeatureEnabled = recurringEmailFeatureFlag && recurringEmailFeatureFlag.isFeatureEnabled

  const stepText = [
    { id: "connect", desc: "Auto-request to connect on social media" },
    { id: "follow", desc: "Auto-follow them on social media" },
    { id: "like", desc: "Auto-like their posts" },
    { id: "share", desc: "Auto-reshare their posts" },
    { id: "comment", desc: "Auto-comment on their posts" },
    { id: "meeting", desc: "Send email to book a meeting" },
    { id: "followup", desc: "Send email to follow up" },
    { id: "gift", desc: "Send a gift" },
    { id: 'checkin.recurring', desc: 'Keep in touch' }
  ]

  const handleAddStep = (step) => {
    setSelectedStep([...selectedStep, step])
    onSubmit(step.id)
    onClose()
  }

  const handleAddStepDone = () => {
    onClose()
  }

  React.useEffect(() => {
    if (!isRecurringEmailFeatureEnabled || (templateDetails && !['retain', 'grow'].includes(templateDetails.id)) || selectedStep.some(s => s !== null && s.step === 'checkin.recurring')) {
      setAvailableSteps(stepText.filter(s => s !== null && s.id !== 'checkin.recurring'))
    } else {
      setAvailableSteps(stepText)
    }
  }, [templateDetails, selectedStep, steps])

  return (
    <Dialog onClose={onClose} open={open} maxWidth='lg' sx={{ px: 2 }}>
      <DialogTitle>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Delete fill={theme.palette.primary.grey} />
        </IconButton>

        <Typography sx={{ fontSize: '24px', fontWeight: 500, color: theme.palette.primary.black, pl: 1, pt: 5 }}>
          What kind of step would you like to add?
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.lightGrey }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black, pl: 1, pt: 2 }}>
          Automations
        </Typography>
        {availableSteps.map((step, index) => (
          <Container sx={{
            boxShadow: 0,
            fontWeight: 500,
            fontSize: '14px',
            borderRadius: 2,
            width: '25vw',
            backgroundColor: theme.palette.primary.white,
            color: theme.palette.primary.black,
            mx: 2,
            my: 1,
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2),
            justifyContent: 'space-between',
          }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.black }}>
              {step.desc}
            </Typography>
            <Button
              onClick={() => handleAddStep(step)}
              disabled={
                (['connect', 'follow', 'like', 'share'].includes(step.id) && selectedStep.some((s) => (s.id === step.id || s.step === step.id)) || (step.id === 'gift' && curUser.userType !== UserConst.UserTypes.DEMO_USER))}
              sx={{
                backgroundColor: theme.palette.primary.lightBlue,
                color: theme.palette.primary.blue,
                textTransform: 'none',
                py: 1,
                px: 2,
                borderRadius: 3,
              }}
            >
              {['connect', 'follow', 'like', 'share'].includes(step.id) && selectedStep.some((s) => (s.id === step.id || s.step === step.id)) ? "Added" : "Add"}
            </Button>
          </Container>
        ))}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: theme.palette.primary.lightGrey, display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        sx={{
          backgroundColor: theme.palette.primary.blue,
          color: theme.palette.primary.white,
          textTransform: 'none',
          fontWeight: 700,
          fontSize: '16px',
          py: 1,
          px: 2,
          mx: 3,
          my: 2,
          borderRadius: 2,
        }}
        onClick={handleAddStepDone}>Done</Button>
      </DialogActions>
    </Dialog>
  );
}
