export const ErrorCodes = Object.freeze({
  VALID_CONTACT: 'VALID_CONTACT',
  INVALID_BIRTHDAY: 'INVALID_BIRTHDAY',
  INCOMPLETE_BIRTHDAY: 'INCOMPLETE_BIRTHDAY',
  REQUIRED_FIELDS_MISSING: 'REQUIRED_FIELDS_MISSING',
  INVALID_ANNIVERSARY: 'INVALID_ANNIVERSARY',
  INCOMPLETE_ANNIVERSARY: 'INCOMPLETE_ANNIVERSARY',
  INVALID_CONTRACT_RENEWAL: 'INVALID_CONTRACT_RENEWAL',
  INCOMPLETE_CONTRACT_RENEWAL: 'INCOMPLETE_CONTRACT_RENEWAL',
  INVALID_EMAIL: 'INVALID_EMAIL'
})

export const ErrorMessages = Object.freeze({
  VALID_CONTACT: 'Valid contact',
  INVALID_BIRTHDAY: 'Please provide a valid birthday date.',
  INCOMPLETE_BIRTHDAY: 'Please fill in all birthday fields.',
  REQUIRED_FIELDS_MISSING: 'Please fill in all required fields.',
  INVALID_ANNIVERSARY: 'Please provide a valid anniversary date.',
  INCOMPLETE_ANNIVERSARY: 'Please fill in all anniversary fields.',
  INVALID_CONTRACT_RENEWAL: 'Please provide a valid contract renewal date.',
  INCOMPLETE_CONTRACT_RENEWAL: 'Please fill in all contract renewal fields.',
  INVALID_EMAIL: 'Please provide a valid email.'
})
