import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material';

import { ReactComponent as Retention } from '../../../assets/emptyState/Retention.svg';

const EmptyState = () => {
    const theme = useTheme();

    return (
        <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 5 }}>
            <Retention />
            <Typography sx={{ fontSize: '24px', fontWeight: 500, color: theme.palette.primary.black }}>
                Retain your clients and keep them engaged
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, mb: 2 }}>
            Automatically respond to your clients' social media posts and create engaging content that captures their interest.
                </Typography>
            <Button
                sx={{
                    boxShadow: 0,
                    textTransform: 'none',
                    fontWeight: 500,
                    borderRadius: 2,
                    backgroundColor: theme.palette.primary.blue,
                    color: theme.palette.primary.white,
                    mx: 2,
                    my: 4,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.blue,
                        color: theme.palette.primary.white,
                    },
                }}
            >
                Get started
            </Button>
        </Stack>
    );
};

export default EmptyState;