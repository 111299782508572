import * as React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case "blue":
      colorScheme = {
        text: theme.palette.primaryCL.Blue100,
        background: theme.palette.primaryCL.Blue50,
      };
      break;
    case "teal":
      colorScheme = {
        text: theme.palette.primaryCL.Teal100,
        background: theme.palette.primaryCL.Teal50,
      };
      break;
    case "green":
      colorScheme = {
        text: theme.palette.primaryCL.Green100,
        background: theme.palette.primaryCL.Green50,
      };
      break;
    case "pink":
      colorScheme = {
        text: theme.palette.primaryCL.Pink100,
        background: theme.palette.primaryCL.Pink50,
      };
      break;
    case "purple":
      colorScheme = {
        text: theme.palette.primaryCL.Purple100,
        background: theme.palette.primaryCL.Purple50,
      };
      break;
    case "orange":
      colorScheme = {
        text: theme.palette.primaryCL.Orange100,
        background: theme.palette.primaryCL.Orange50,
      };
      break;
    case "black":
      colorScheme = {
        text: theme.palette.primaryCL.Black110,
        background: theme.palette.primaryCL.Black50,
      };
      break;
    case "grey":
      colorScheme = {
        text: theme.palette.primaryCL.Black100,
        background: theme.palette.primaryCL.White110,
      };
      break;
    case "red":
      colorScheme = {
        text: theme.palette.primaryCL.Red100,
        background: theme.palette.primaryCL.Red50,
      };
      break;
    default: // same as blue
      colorScheme = {
        text: theme.palette.primaryCL.Blue100,
        background: theme.palette.primaryCL.Blue50,
      };
      break;
  }
  return colorScheme;
};

const StyledPBadge = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "pVariant",
})(({ theme, pVariant }) => {
  const { background, text } = getVariantStyles(theme, pVariant);

  return {
    ...theme.typography.regular.footnote,
    height: "fit-content",
    borderRadius: 100,
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "4px",
    paddingBottom: "4px",
    fontFamily: "Inter",
    color: text,
    backgroundColor: background,
    "& .MuiChip-label": {
      padding: 0,
    },
  };
});

function PBadge({ children, pVariant }) {
  return <StyledPBadge pVariant={pVariant} label={children} />;
}

PBadge.propTypes = {
  /** Label */
  children: PropTypes.node,
  /** Postilize design variant */
  pVariant: PropTypes.oneOf(["blue", "teal", "green", "pink", "purple", "orange", "black", "grey", "red"]),
};

PBadge.defaultProps = {
  pVariant: "blue",
};

export default PBadge;
