import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import PContactListType from './PContactListType';
import PContactListHeader from './PContactListHeader';
import PInput from './PInput';
import PSequenceFilter from './PSequenceFilter';

import Divider from '@mui/material/Divider';

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  paddingTop: '100px',
};

const contactsStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '20px',
};

function LoadingComponent() {
  return (
    <Container sx={loadingStyle}>
      <CircularProgress color="inherit" size={72} />
    </Container>
  );
}

function ContactsListComponent({ contacts, disableCheckbox }) {
  return contacts.map((contact, index) => (
    <PContactListType key={index} contact={contact} disableCheckbox={disableCheckbox} />
  ));
}

function NoContactsComponent() {
  return <div style={contactsStyle}>None found</div>;
}
function PContactList({
  headerText,
  headerComponent,
  header1OnClick,
  header1Text,
  header1Theme,
  header1Disabled,
  header2OnClick,
  header2Text,
  header2Theme,
  header2Disabled,
  inputPlaceHolder,
  inputIcon,
  inputIconClick,
  inputOnChange,
  inputDisabled,
  filterTotalCount,
  filterSelectedCount,
  filterChange,
  filterAction,
  filterToggleAll,
  activeFilter,
  contacts,
  custFilterButtons,
  disableCheckbox,
  loadingState,
  onClear,
}) {
  const theme = useTheme();
  const filterCheckboxChecked =
    filterSelectedCount > 0 && (filterSelectedCount === filterTotalCount || !filterTotalCount);
  const filterCheckboxIndeterminate =
    filterSelectedCount > 0 && filterTotalCount > 0 && filterSelectedCount < filterTotalCount;
  const handleClearSearch = () => {
    if (onClear) onClear();
  };
  return (
    <div style={{ width: '100%' }}>
      <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
        <PContactListHeader
          headerText={headerText}
          headerComponent={headerComponent}
          onClick={header1OnClick}
          buttonText={header1Text}
          buttonTheme={header1Theme}
          disabled1={header1Disabled}
          onClick2={header2OnClick}
          button2Text={header2Text}
          button2Theme={header2Theme}
          disabled2={header2Disabled}
        />
      </div>

      <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
        <PInput
          placeholder={inputPlaceHolder}
          Icon={inputIcon}
          onIconClick={inputIconClick}
          onChange={inputOnChange}
          disabled={inputDisabled}
          onClear={handleClearSearch}
          type="text"
        />
        <PSequenceFilter
          custFilterButtons={custFilterButtons}
          showActionButtons={filterSelectedCount > 0}
          onFilterChange={filterChange}
          onAction={filterAction}
          onToggleSelectAll={filterToggleAll}
          activeFilter={activeFilter}
          checkbox={disableCheckbox ? false : true}
          checkboxProps={{
            isChecked: filterCheckboxChecked,
            isIndeterminate: filterCheckboxIndeterminate,
          }}
        />
      </div>
      <Divider />
      {loadingState ? (
        <LoadingComponent />
      ) : contacts && contacts.length > 0 ? (
        <ContactsListComponent disableCheckbox={disableCheckbox} contacts={contacts} />
      ) : (
        <NoContactsComponent />
      )}
    </div>
  );
}

PContactList.propTypes = {
  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary']),

  /** Header Text */
  headerText: PropTypes.string,

  /** Text for the first button */
  header1Text: PropTypes.string,

  /** Handler for the first button */
  header1OnClick: PropTypes.func,

  /** Theme for the first button */
  header1Theme: PropTypes.oneOf(['primary', 'secondary', 'outlined', 'plain', 'black']),

  /** Status for first button */
  header1Disabled: PropTypes.bool,

  /** Text for the secondary button */
  header2Text: PropTypes.string,

  /** Handler for the secondary button */
  header2OnClick: PropTypes.func,

  /** Theme for the secondary button */
  header2Theme: PropTypes.oneOf(['primary', 'secondary', 'outlined', 'plain', 'black']),

  /** Status for secondary button */
  header2Disabled: PropTypes.bool,

  /** Placeholder for the input */
  inputPlaceHolder: PropTypes.string,

  /** Icon for the input */
  inputIcon: PropTypes.elementType,

  /** Click handler for the input icon */
  inputIconClick: PropTypes.func,

  /** Change handler for the input */
  inputOnChange: PropTypes.func,

  /** Input disabled */
  inputDisabled: PropTypes.bool,

  /** Loading state */
  loadingState: PropTypes.bool,

  /** OnClear handler to clear the input */
  onClear: PropTypes.func,
};

PContactListHeader.defaultProps = {
  pVariant: 'primary',
  headerText: 'Header',
  header1Text: 'Edit',
  header1Theme: 'secondary',
  header1Disabled: false,
  header2Text: 'Add',
  header2Theme: 'primary',
  header2Disabled: false,
  inputPlaceHolder: 'Search',
  inputDisabled: false,
  loadingState: false,
};

export default PContactList;
