import React from 'react';
import { Container, CssBaseline, Divider, Stack } from '@mui/material';
import { useTheme } from '@mui/material';

import EmptyState from './components/emptyState.js';

const ClientGrowth = () => {
  const theme = useTheme();
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);


  return (
    <>
      <CssBaseline />
      <Stack direction="row">
        <Container
          style={{
            minWidth: isEmpty ? '55%' : '35%',
            height: '100vh',
          }}
        >
          {isEmpty ? <EmptyState/>
          : null }
        </Container>

        <Divider orientation="vertical" flexItem sx={{ height: '100vh' }} />

        <Container style={{ minWidth: isEmpty ? '44%' : '64%', height: '100vh' }}>

        </Container>
      </Stack>
    </>
  );
};

export default ClientGrowth;