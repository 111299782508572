import * as React from 'react'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

import { useTheme } from '@mui/material';
import PTypography from "@Library/PTypography";
import PBadge from '@Library/PBadge'

import ConnectButton from './connectButton'
import { Providers } from '../../../constants/constant'

export default function ConnectListItem({ currProvider, source, setProviderList }) {
  const theme = useTheme()
  const { title, subtitle, logo, provider, connected, recommended = false } = currProvider
  const recommendedStyle = {
    color: theme.palette.primary.orange,
    px: 1,
    py: 0,
    my: 1,
    fontWeight: 500,
    borderRadius: '12px',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.primary.lightOrange,
    alignItems: 'center',
    display: 'flex',
  };

  return (
    <>
      <ListItem
        alignItems='flex-start'
        sx={{
          border: `1px solid #E0E0E0`,
          backgroundColor: theme.palette.primary.white,
          borderRadius: '16px',
          p: 2,
          display: 'flex',
          cursor: 'pointer',
          '&:hover': {
            borderColor: theme.palette.primary.blue,
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1
          }}
        >
          <Paper elevation={0}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >

              {logo}
              {recommended &&
                <PBadge pVariant="purple">Recommended to log in using Chrome</PBadge>
              }
            </Box>
            <PTypography size="h1" weight="bold" sx={{ pt: 1.5 }}>
              {title}
            </PTypography>
            {source !== 'settingsAuth' && 
              <PTypography size="body1" weight="regular" color="inherit" sx={{ pt: 1 }}>
                {subtitle}
              </PTypography>
            }
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                mt: 3,
              }}
            >
              <ConnectButton
                currProvider={currProvider}
                text={provider === Providers.CSV ? "Upload" : "Connect"}
                source={source}
                connectionStatus={(status) => {
                  setProviderList((prevProviderList) => {
                    return prevProviderList.map((provider) => {
                      if (provider.title === currProvider.title) {
                        return { ...provider, connected: status };
                      }
                      return provider;
                    });
                  });
                }}
                setProviderList={setProviderList}
              />

            </Box>
          </Paper>

        </Box>
      </ListItem>
    </>
  )
}