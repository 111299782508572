import React, { useEffect, useState } from 'react';
import HttpClient from '../http/httpClient';
import { useLocation } from 'react-router-dom';
import { PTypography } from '@/components/library';

const Unsubscribe = () => {
  const [message, setMessage] = useState('Processing your request...');

  const location = useLocation();

  useEffect(() => {
    // Extract query parameters from the URL
    const params = new URLSearchParams(location.search);
    const userId = params.get('userId');
    const token = params.get('token');
    const emailType = params.get('emailType');

    // Validate parameters
    if (!userId || !token || !emailType) {
      setMessage('Invalid unsubscribe link.');
      return;
    }

    // Make API call to unsubscribe
    const unsubscribe = async () => {
      try {
        const url = `user/unsubscribe`;
        const data = { userId, token, emailType };
        const response = await HttpClient.postUnauthenticatedData(url, data);

        if (response.success && response.status === 200) {
          setMessage('You have successfully unsubscribed.');
        } else {
          setMessage(response.data?.message || 'An error occurred while processing your request.');
        }
      } catch (error) {
        console.error('Error unsubscribing:', error);
        setMessage('An error occurred while processing your request.');
      }
    };

    unsubscribe();
  }, [location.search]);

  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      <PTypography variant='body1' color='text.primary'>{message}</PTypography>
    </div>
  );
};

export default Unsubscribe;
