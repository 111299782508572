import React, { useRef } from 'react';
import {
  ButtonGroup
} from '@mui/material';
import PButton from './PButton'; // Import the PButton component
import PToolTip from './PToolTip'; // Import your PToolTip component
import { ReactComponent as CheckIcon } from '@Assets/Icons/Check.svg';
import SendIcon from '@mui/icons-material/Send';
import ScheduleSend from '@mui/icons-material/ScheduleSend';

const options = {
  now: 'Approve and Send Now',
  // later: 'Approve and Send Later',
  schedule: 'Approve',
};

const PMultiApproveButton = ({ onApprove, onApproveAndSend, onApproveWithCustomTime }) => {
  const anchorRef = useRef(null);

  return (
    <>
      <ButtonGroup ref={anchorRef} aria-label="split button">
        <PToolTip title={options.schedule} pVariant="black" placement="bottom">
          <PButton
            pVariant="green" // Adjust the variant as needed
            onClick={onApprove}
            Icon={CheckIcon}
            iconProps={{ sx: { fontSize: '15px' } }} // Adjust icon size here
            isIconicButton
          />
        </PToolTip>
        {/* <PToolTip title={options.later} pVariant="black" placement="bottom">

          <PButton
            pVariant="green" // Adjust the variant as needed
            onClick={onApproveWithCustomTime}
            Icon={ScheduleSend}
            iconProps={{ sx: { fontSize: '15px' } }} // Adjust icon size here
            isIconicButton
          />
        </PToolTip> */}
        <PToolTip title={options.now} pVariant="black" placement="bottom">
          <span>
            <PButton
              pVariant="green" // Adjust the variant as needed
              onClick={onApproveAndSend}
              Icon={SendIcon}  // Use SendNowIcon here
              iconProps={{ sx: { fontSize: '15px' } }} // Adjust icon size here
              isIconicButton
            />
          </span>
        </PToolTip>

      </ButtonGroup>
    </>
  );
};

export default PMultiApproveButton;
