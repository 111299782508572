import React from "react";

import {
  Drawer,
  IconButton,
  useTheme,
  Stack,
  Typography,
  Paper,
} from "@mui/material";

import { ReactComponent as ArrowLeft } from "../../../assets/Icons/Arrow-Left.svg";

import Bio from "./bio";
import CRM from "./crm";
import Social from "./social";
import Tone from "./tone";
import Topics from "./topics";
import Support from "./support";
import List from "../../Lists/lists";

const OptionsDrawer = ({ open, closeOptionsDrawer, selectedTitle }) => {
  const theme = useTheme();

  const titleToComponent = {
    About: <Bio />,
    Tone: <Tone />,
    Topics: <Topics />,
    CRM: <CRM />,
    Social: <Social />,
    List: <List />,
    Support: <Support />,
  };
  const renderComponent = () => {
    const selectedComponent = titleToComponent[selectedTitle];
    return selectedComponent || null;
  };
  const handleBackToSettings = () => {
    closeOptionsDrawer();
  };

  return (
    <>
      <Drawer open={open} onClose={closeOptionsDrawer}>
        <Paper
          sx={{
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            backgroundColor: theme.palette.primary.white,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleBackToSettings}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: theme.palette.primary.blue,
            }}
          >
            <ArrowLeft fill={theme.palette.primary.blue} />
            <Typography sx={{ fontWeight: "700" }}>Back</Typography>
          </IconButton>
          <Stack>{renderComponent()}</Stack>
        </Paper>
      </Drawer>
    </>
  );
};

export default OptionsDrawer;