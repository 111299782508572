import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material';

import { ReactComponent as Growth } from '../../../assets/emptyState/Growth.svg';

const EmptyState = () => {
    const theme = useTheme();

    return (
        <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 5 }}>
            <Growth />
            <Typography sx={{ fontSize: '24px', fontWeight: 500, color: theme.palette.primary.black }}>
            This is where you can follow your progress and get to know your clients better.
            </Typography>
        </Stack>
    );
};

export default EmptyState;