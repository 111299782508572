import * as React from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularProgress from '@mui/material/CircularProgress';

import CustomModal from '../../../pages/login/components/confirmModal';
import userInviteService from '../../../services/userInviteService';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function InviteItem({ invite }) {
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const [alertText, setAlertText] = React.useState('')

  const handleModalAction = (action) => {
    if (action === 'OK') {
      userInviteService.deleteInvite(invite);
    }
    setDeleteOpen(false)
  }

  const handleDeleteModal = () => {
    const alertPrompt = `Are you sure you want to delete "${invite.invitee}'s" invite?`
    setAlertText(alertPrompt)
    setDeleteOpen(true)
  };

  // 0: pending, 1: accepted, 2: declined
  const statusMap = {
    0: 'Pending',
    1: 'Accepted',
    2: 'Declined'
  };
  
  const statusString = statusMap[invite.status];

  return (
    <>
    {deleteOpen && <CustomModal clickAction={handleModalAction} alertText={alertText} />}
      <StyledTableRow key={'10'}>
        <StyledTableCell component="th" scope="row">
          {invite.inviter}
        </StyledTableCell>
        <StyledTableCell>{invite.invitee}</StyledTableCell>
        <StyledTableCell>{invite.companyId}</StyledTableCell>
        <StyledTableCell>{invite.token}</StyledTableCell>
        <StyledTableCell>{statusString}</StyledTableCell>
        <StyledTableCell>
          {
            <DeleteIcon
              sx={{ '&:hover': { cursor: 'pointer' }, marginRight: '4px' }}
              onClick={handleDeleteModal}
            />
          }
          {/* <SettingsIcon
            sx={{ '&:hover': { cursor: 'pointer' }, marginRight: '4px' }}
            onClick={handleEdit}
          /> */}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
