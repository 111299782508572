import { useState, useEffect } from 'react';
import Http from '../http/httpClient';

// useCompanies is a custom hook that fetches and manages a list of companies
export const useCompanies = () => {
  console.log(`useCompanies hook`);
  const [companies, setCompanies] = useState([]);

  // Use the useEffect hook to fetch the companies when the component mounts
  useEffect(() => {
    const fetchCompanyList = async () => {
      // Send a GET request to the 'companies/list' endpoint
      Http.getData('companies/list')
        .then((res) => {
          setCompanies(res.companies);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchCompanyList();
  }, []);

  // Return the companies state and its setter function
  // If companies is null or undefined, return an empty array instead
  // This prevents the issue when the data hasnt been fetched yet
  // and the component tries to render an undefined companies array
  return companies ? [companies, setCompanies] : [[], setCompanies];
};
