import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { ReactComponent as Delete } from '../../assets/Icons/Delete.svg';
import Paper from '@mui/material/Paper';
import PTypography from './PTypography';

const getVariantStyles = (theme, pVariant) => {
  let colorSchemes = {
    primary: {
      text: theme.palette.primaryCL.Black200,
      textDisabled: theme.palette.primaryCL.Black70,
      placeholder: theme.palette.primaryCL.Black100,
      border: theme.palette.primaryCL.Black70,
      borderFocus: theme.palette.primaryCL.Blue100,
      borderComplete: theme.palette.primaryCL.Black70,
      iconFill: theme.palette.primaryCL.Black100,
      iconDisabled: theme.palette.primaryCL.Black70,
    },
    secondary: {
      text: theme.palette.primaryCL.Black200,
      textDisabled: theme.palette.primaryCL.Black70,
      placeholder: theme.palette.primaryCL.Black100,
      border: theme.palette.primaryCL.Black70,
      borderFocus: theme.palette.primaryCL.Black70,
      borderComplete: theme.palette.primaryCL.Black70,
      iconFill: theme.palette.primaryCL.Black100,
      iconDisabled: theme.palette.primaryCL.Black70,
    },
  };

  return colorSchemes[pVariant || 'primary'];
};

function useOutsideClick(ref, onOutsideClick) {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    },
    [ref, onOutsideClick],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
}

function PInput({
  disabled,
  disableKeyPress,
  disableClearButton,
  Icon,
  keyPressFunc,
  label,
  multiline,
  name,
  onChange,
  onClear,
  onClick,
  onIconClick,
  placeholder,
  pVariant,
  readOnly,
  rows,
  value,
}) {
  const theme = useTheme();
  const [isInputFocused, setInputFocused] = useState(false);
  const [isInputHovered, setInputHovered] = useState(false);
  const [inputValue, setInputValue] = useState(value ?? '');
  const [isInputComplete, setInputComplete] = useState(false);
  const [rowsCount, setRowsCount] = useState(!multiline ? 1 : !rows ? 4 : rows);
  const inputRef = useRef(null); // Ref for the input container
  const colorScheme = getVariantStyles(theme, pVariant);

  useOutsideClick(inputRef, () => {
    setInputFocused(false);
  });

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  const handleClearInput = () => {
    setInputValue('');
    setInputFocused(false);
    if (onClear) onClear(); 
  };

  const changeInput = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setInputComplete(value.trim().length > 0);
    if (onChange) onChange(e);
  };

  const disableKeyPressAction = (event) => {
    if (disableKeyPress && event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div onClick={onClick}>
      {label && (
        <div style={{ marginBottom: '8px' }}>
          <PTypography size="body2" weight="bold">
            {label}
          </PTypography>
        </div>
      )}
      <Paper
        component="form"
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          boxShadow: 0,
          px: '14px',
          py: '16px',
          borderRadius: '14px',
          width: '100%',
          border: `1px solid ${
            isInputComplete
              ? colorScheme.borderComplete
              : isInputFocused || isInputHovered
                ? colorScheme.borderFocus
                : colorScheme.border
          }`,
        }}
        ref={inputRef}
        onMouseEnter={() => setInputHovered(true)}
        onMouseLeave={() => setInputHovered(false)}
      >
        {Icon && (
          <IconButton onClick={onIconClick} sx={{ p: 0 }}>
            <Icon fill={disabled ? colorScheme.iconDisabled : colorScheme.iconFill} style={{ width: '20px' }} />
          </IconButton>
        )}
        <InputBase
          name={name}
          sx={{
            width: '100%',
            fontFamily: 'Inter',
            py: 0,
            my: 0,
          }}
          inputProps={{
            sx: {
              'padding': 0,
              'fontSize': '16px',
              'fontWeight': '500',
              'lineHeight': '140%',
              'color': disabled ? colorScheme.textDisabled : colorScheme.text,
              '&::placeholder': {
                color: colorScheme.placeholder,
                opacity: 1,
              },
            },
          }}
          multiline={multiline}
          rows={rowsCount}
          type="text"
          disabled={disabled}
          value={inputValue}
          onChange={(e) => changeInput(e)}
          placeholder={placeholder}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          readOnly={readOnly}
          onKeyDown={disableKeyPress ? disableKeyPressAction : keyPressFunc}
        />

        {!disableClearButton && (
          <IconButton aria-label="delete" onClick={handleClearInput} sx={{ p: 0 }} tabIndex="-1">
            <Delete
              fill={disabled || !isInputFocused ? colorScheme.iconDisabled : colorScheme.iconFill}
              style={{ width: '12px' }}
            />
          </IconButton>
        )}
      </Paper>
    </div>
  );
}

PInput.propTypes = {
  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary', 'secondary']),

  /** Label Text */
  label: PropTypes.string,

  /** name Text */
  name: PropTypes.string,

  /** Placeholder Text */
  placeholder: PropTypes.string,

  /** OnClick handler for the button */
  onClick: PropTypes.func,

  /** OnChange handler for the input */
  onChange: PropTypes.func,

  /** Disabled */
  disabled: PropTypes.bool,

  /** Multiline */
  multiline: PropTypes.bool,

  /** Icon component to display in the input.
   * NOTE: Storybook does not support React components in controls */
  Icon: PropTypes.elementType,

  value: PropTypes.string,

  readOnly: PropTypes.bool,

  disableKeyPress: PropTypes.bool,

  /** keyPressFunc handler for the key press actions */
  keyPressFunc: PropTypes.func,

  /** OnClear handler to clear the input */
  onClear: PropTypes.func,
};

PInput.defaultProps = {
  pVariant: 'primary',
  placeholder: 'Search',
  disabled: false,
  multiline: false,
  readOnly: false,
  onClear: null
};

export default PInput;
