import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepIcon from "@mui/material/StepIcon";
import { StepConnector } from '@mui/material';

export default function PProgressStepper({ list, badgeSize }) {
  const theme = useTheme();
  const connectorStyles = {
    top: '45%',
    marginLeft: '10px',
    // const activeIconStyle = {
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   borderRadius: "50%",
    //   border: `2px solid ${theme.palette.primaryCL.Green100}`,
    //   width: theme.spacing(4),
    //   height: theme.spacing(4),
    //   backgroundColor: "white",
    // };

    marginRight: '10px'
  };

  function CustomStepIcon(props) {
  
    const { active, icon, badgeSize } = props;

    const size = badgeSize === 'big' ? theme.spacing(6) : theme.spacing(4);
  
    const activeIconStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: active ? theme.palette.primaryCL.Black200 : theme.palette.primaryCL.White100,
        color: active ? 'white' : theme.palette.primaryCL.Black100,
        border: active ? `2px solid ${theme.palette.primaryCL.Black200}` : `2px solid ${theme.palette.primaryCL.Black70}`,
        width: size,
        height: size,
      };
  
    return (
      <StepIcon
        {...props}
        icon={
            <div style={activeIconStyle}>
              {icon}
            </div>
          }
      />
    );
  }

  let defaultList = [
    { index: 1, name: "Bio", active: true },
    { index: 2, name: "Profile", active: false },
    { index: 3, name: "List", active: false },
    { index: 4, name: "Settings", active: false },
    { index: 5, name: "Bank", active: false },
    { index: 6, name: "Portfolio", active: false },
  ];

  const steps = list || defaultList;

  const activeStep = steps.findIndex((item) => item.active);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper sx={{marginTop: "7px"}} activeStep={activeStep} alternativeLabel connector={<StepConnector sx={connectorStyles} />}>
        {steps.map((item) => (
          <Step key={item.index}>
            <StepLabel StepIconComponent={CustomStepIcon} StepIconProps={{ badgeSize, active: item.active }}>
              {item.name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
