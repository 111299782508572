import React, { useState } from 'react';
import PButton from '../../../components/library/PButton';
import Stack from '@mui/system/Stack';
import PModal from '../../../components/library/PModal';
import { Grid, Box } from '@mui/material';
import dayjs from 'dayjs';
import PTimePicker from '../../../components/library/PTimePicker';
import PDatePicker from '../../../components/library/PDatePicker';
import PBackButton from '../../../components/library/PBackButton';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const SchedulePostModal = ({ open, onClose, onSubmit, posts }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [dateTimeError, setDateTimeError] = useState(null);
  const [height, setHeight] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);

  dayjs.extend(customParseFormat); // Extend dayjs with the customParseFormat plugin to allow parsing dates in custom formats

  const handleDateChange = (date) => {
    setSelectedDate(dayjs(date));
  };

  const onTimeChange = (time) => {
    setSelectedTime(dayjs(time, 'hh:mm A'));
  };

  const handleSave = () => {
    if (!dateTimeError) {
      const dateTime = convertToDateTime();
      onSubmit(posts, 'scheduled', dateTime);
      onClose();
    }
  };

  const convertToDateTime = () => {
    if (selectedDate && selectedTime) {
      const dateTime = selectedDate.hour(selectedTime.hour()).minute(selectedTime.minute());
      return dateTime.toDate();
    }
    return null;
  };

  const handleOnClick = (value) => {
    setHeight(value);
  };

  return (
    <>
      <PModal
        floating
        ultraWideContent
        inlineHeaderText
        floatingDimensions={{
          width: '576px',
          height: height || 'auto',
        }}
        open={open}
        onClose={onClose}
        actionButtons={[<PBackButton onClick={onClose} iconType="close" />]}
        headerText={'Schedule Post'}
      >
        <Stack spacing={2}>
          <Grid item xs={12}>
            <PDatePicker
              handleOnClick={(value) => handleOnClick(value)}
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
              setDateTimeError={setDateTimeError}
            />
          </Grid>

          <Grid item xs={12}>
            <PTimePicker selectedTime={selectedTime} onTimeChange={onTimeChange} selectedDate={selectedDate} />
          </Grid>
        </Stack>

        <Box mt={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <PButton onClick={handleSave}>Save</PButton>
        </Box>
      </PModal>
    </>
  );
};

export default SchedulePostModal;
