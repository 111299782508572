import { Avatar } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import PTypography from "./PTypography";
import { styled } from "@mui/material/styles";

const StyledCard = styled("div")(({theme, cursor}) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  height: '100%',
  padding: "16px",
  borderRadius: "14px",
  backgroundColor: theme.palette.primaryCL.Blue40,
  cursor: cursor,
}));

const PQuerySuggestionCard = ({ image, text, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <StyledCard onClick={handleClick} cursor={onClick ? "pointer" : "default"}>
      <Avatar src={image} />
      <PTypography size="body1" weight="regular">
        {text}
      </PTypography>
    </StyledCard>
  );
};

PQuerySuggestionCard.propTypes = {
  /** image URL */
  image: PropTypes.string.isRequired,

  /** text */
  text: PropTypes.string.isRequired,
};

export default PQuerySuggestionCard;