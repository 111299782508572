import { useState, useCallback, useEffect } from 'react';
import PTypography from '@Library/PTypography';
import PInput from '@Library/PInput';
import { Grid } from '@mui/material';
import PRadioSelector from '@Library/PRadioSelector';
import PPickerModal from '@CommonScreens/PPickerModal';
import { getSearchResults } from '@/helper/apiHelper';
import PChipInputField from '@Library/PChipInputField';
import { debounce } from 'lodash';

const companySizeOptions = [
  { label: 'Under 250', value: '<250' },
  { label: '250-500', value: '250-500' },
  { label: '500-1k', value: '500-1000' },
  { label: '1k-5k', value: '1000-5000' },
  { label: 'Over 5k', value: '5000<' },
];

const tenureOptions = [
  { label: 'Under 6 months', value: '<180' },
  { label: '6-12 months', value: '181-365' },
  { label: '1-5 yrs', value: '366-1825' },
  { label: 'Over 5 yrs', value: '1825<' },
];

const companyRevenueOptions = [
  { label: 'Less than $1M', value: '<1000000' },
  { label: '$5M', value: '1000000-5000000' },
  { label: '$10M', value: '5000000-10000000' },
  { label: '$20M', value: '10000000-20000000' },
  { label: '$50M', value: '20000000-50000000' },
  { label: '$100M+', value: '50000000<' },
];

const formControlStyle = {};

export default function AIListPayload({ aiSearchPayload, formData, updateFormData, setAiSearchPayload }) {
  const [formState, setFormState] = useState({
    amount: '',
    jobTitle: [],
    industry: [],
    company: '',
    companySize: '',
    tenure: '',
    companyRevenue: '',
    location: '',
  });

  const [modalState, setModalState] = useState({
    open: false,
    type: '',
    searchTerm: '',
    options: [],
    selectedOptions: [], // Initialize as an empty array
    headerTitle: '',
    placeholder: '',
  });

  const getHeaderName = (type) => {
    const headers = {
      jobTitle: 'Search job titles',
      industry: 'Search industries',
    };
    return headers[type] || 'Search'; // Default header name if type is not found
  };

  const handleFormChange = (newData) => {
    // Call updateFormData with new data
    updateFormData((prev) => ({ ...prev, ...newData }));
  };

  useEffect(() => {
    // Check if the payload is not empty and perhaps even if it has specific properties you expect
    if (aiSearchPayload && Object.keys(aiSearchPayload).length > 0) {
      // Use a function to safely update the state based on the previous state
      setFormState((prevState) => ({
        // Update each part of the state as needed based on aiSearchPayload;
        // this assumes aiSearchPayload structure matches formState's structure
        amount: aiSearchPayload.amount || prevState.amount,
        jobTitle: aiSearchPayload.jobTitle ? aiSearchPayload.jobTitle : prevState.jobTitle,
        industry: aiSearchPayload.industry ? aiSearchPayload.industry : prevState.industry,
        company: aiSearchPayload.company || prevState.company,
        companySize: aiSearchPayload.companySize || prevState.companySize,
        tenure: aiSearchPayload.tenure || prevState.tenure,
        companyRevenue: aiSearchPayload.companyRevenue || prevState.companyRevenue,
        location: aiSearchPayload.location || prevState.location,
      }));
    }
    // This useEffect depends on aiSearchPayload; it'll re-run when aiSearchPayload changes.
  }, [aiSearchPayload]);

  useEffect(() => {
    // Call handleFormChange with the updated form state
    handleFormChange(formState);
  }, [formState, handleFormChange]);

  const endpoints = {
    jobTitle: {
      path: 'searchJobTitles',
      payload: 'jobTitles',
    },
    industry: {
      path: 'searchIndustries',
      payload: 'industries',
    },
  };

  // const openModal = (type) => setModalState({ open: true, type, searchTerm: '' });
  // const closeModal = () => setModalState((prev) => ({ ...prev, open: false }));

  const handleSearch = async (type, searchTerm) => {
    const endpoint = endpoints[type];
    try {
      const response = await getSearchResults(endpoint.path, searchTerm);
      const options = response[endpoint.payload] || [];
      setModalState((prev) => ({
        ...prev,
        options: options,
      }));
    } catch (error) {
      console.error(`Error fetching ${type} titles in component:`, error);
    }
  };

  const handleSearchDebounced = useCallback(debounce(handleSearch, 500), []); // Keep this as is

  const handleSearchTermChange = useCallback(
    (type, newSearchTerm) => {
      setModalState((prev) => ({ ...prev, searchTerm: newSearchTerm }));
      // Pass both type and searchTerm to the debounced function
      handleSearchDebounced(type, newSearchTerm);
    },
    [handleSearchDebounced],
  );

  // Cleanup the debounced function on component unmount
  useEffect(() => {
    return () => {
      handleSearchDebounced.cancel();
    };
  }, [handleSearchDebounced]);

  const handleSelect = (selectedItems) => {
    const { type } = modalState;
    setFormState((prev) => ({ ...prev, [type]: selectedItems }));
    setAiSearchPayload((prev) => ({ ...prev, [type]: selectedItems }));
  };

  const openModal = useCallback(
    (type) => {
      const existingSelections = formState[type] || [];
      setModalState({
        open: true,
        type,
        searchTerm: '',
        options: [],
        selectedOptions: existingSelections,
        headerTitle: getHeaderName(type),
        placeholder: '',
      });
    },
    [formState, setModalState],
  );

  const closeModal = useCallback(
    (selectedOptions = []) => {
      // Assuming `type` is either 'jobTitle' or 'industry' as per your modal opening logic
      const { type } = modalState;
      if (selectedOptions.length > 0) {
        // Update the respective field in the form state if selections were made
        setFormState((prev) => ({
          ...prev,
          [type]: selectedOptions,
        }));
      }
      // Reset the modal state, including selected options and search term
      setModalState({
        open: false,
        type: '',
        searchTerm: '',
        options: [],
      });
    },
    [modalState, setFormState, setModalState],
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setAiSearchPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChipDelete = useCallback(
    (field) => (chipToDelete) => {
      setFormState((prevState) => ({
        ...prevState,
        [field]: prevState[field].filter((item) => item !== chipToDelete),
      }));
      setAiSearchPayload((prevState) => ({
        ...prevState,
        [field]: prevState[field].filter((item) => item !== chipToDelete),
      }));
    },
    [],
  );

  const handleRadioChange = useCallback(
    (name) => (value) => {
      const event = { target: { name, value } };
      handleChange(event);
    },
    [handleChange],
  );

  const changePlaceHolder = (type, placeholder) => {
    openModal(type);
    setModalState((prev) => ({
      ...prev,
      placeholder: placeholder,
    }));
  };
  return (
    <>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12} md={8} lg={6}>
          <PTypography size="h3" weight="bold" sx={{ mb: 1 }}>
            Please confirm what you're looking for.
          </PTypography>

          <PTypography size="body2" weight="regular" sx={{ mb: 4 }}>
            Feel free to fill out as many fields as you're comfortable with.
          </PTypography>

          <Grid container spacing={2}>
            {' '}
            {/* Use Grid container here for form fields */}
            <Grid item xs={12}>
              <PInput
                pVariant="primary"
                label="Amount of contacts"
                name="amount"
                onChange={handleChange}
                value={formState.amount}
                placeholder="E.g., 25"
                disableKeyPress={true}
              />
            </Grid>
            <Grid item xs={12}>
              <PChipInputField
                values={formState.jobTitle || []} // Ensure you pass an array even if it's empty
                label="Job Title"
                placeholder="E.g., Sales Representative"
                onChipDelete={handleChipDelete('jobTitle')}
                onClick={() => changePlaceHolder('jobTitle', 'E.g., Sales Representative')}
                // You can still apply custom styles through a prop if your ChipInputField supports it
                style={formControlStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <PInput
                pVariant="primary"
                label="Location"
                name="location"
                onChange={handleChange}
                value={formState.location}
                style={formControlStyle}
                placeholder="E.g., Austin, Texas"
                disableKeyPress={true}
              />
            </Grid>
            <Grid item xs={12}>
              <PChipInputField
                values={formState.industry || []} // Ensure you pass an array even if it's empty
                label="Industry"
                placeholder="E.g., Technology"
                onChipDelete={handleChipDelete('industry')}
                onClick={() => changePlaceHolder('industry', 'E.g., Technology')}
                // You can still apply custom styles through a prop if your ChipInputField supports it
                style={formControlStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <PInput
                pVariant="primary"
                label="Company name"
                name="company"
                onChange={handleChange}
                value={formState.company}
                style={formControlStyle}
                placeholder="E.g., Acme Innovations Inc."
                disableKeyPress={true}
              />
            </Grid>
            <Grid item xs={12}>
              <PRadioSelector
                label="Number of employees"
                options={companySizeOptions}
                value={formState.companySize}
                onChange={handleRadioChange('companySize')}
              />
            </Grid>
            <Grid item xs={12}>
              <PRadioSelector
                label="Tenure"
                options={tenureOptions}
                value={formState.tenure}
                onChange={handleRadioChange('tenure')}
              />
            </Grid>
            <Grid item xs={12}>
              <PRadioSelector
                label="Company revenue"
                options={companyRevenueOptions}
                value={formState.companyRevenue}
                onChange={handleRadioChange('companyRevenue')}
              />
            </Grid>
            <PPickerModal
              open={modalState.open}
              headerTitle={modalState.headerTitle}
              type={modalState.type}
              searchTerm={modalState.searchTerm}
              onSearchTermChange={(searchTerm) => handleSearchTermChange(modalState.type, searchTerm)}
              onSelect={handleSelect}
              onClose={closeModal}
              options={modalState.options}
              selectedOptions={modalState.selectedOptions}
              placeholder={modalState.placeholder}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
