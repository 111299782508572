import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

import Http from '../../../http/httpClient'
import UserItem from './userItem'

export default function UserDetailsModal({ open, onClose, company }) {
  const [companyId, setCompanyId] = React.useState(company?._id ?? '')
  const [users, setUsers] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    const fetchUsersFromCompany = async () => {
      setIsLoading(true)
      Http.getData('companies/userListUnderCompany', { companyId })
        .then((res) => {
          if (res && res.status) {
            setUsers(res.users)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    if (companyId) {
      fetchUsersFromCompany()
    }
  }, [])

  const handleOnClose = (event) => {
    onClose(event)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth="true">
      <DialogTitle
        sx={{
          fontWeight: 800,
          fontSize: 18,
          letterSpacing: '-0.02em',
          py: 3,
        }}
      >
        User Details
      </DialogTitle>
      {isLoading ? (
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent>
          {users && users.length > 0 ? (
            users.map((user) => (user && <UserItem user={user} />))
          ) : (
            <Typography align='center' gutterBottom>
              There is no user exists under this company
            </Typography>
          )}
        </DialogContent>
      )}
      <Divider />
      <Box sx={{ mr: 2, mb: 2, mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={{ mr: 2, mt: 1 }}
            variant="contained"
            onClick={(e) => { handleOnClose(e) }}>
          Close
        </Button>
      </Box>
    </Dialog>
  )
}
