import { useState, useEffect } from 'react';
import { isChromeExtensionInstalled } from '../helper/utilities';

export function useChromeExtensionInstalled({ delay = 100, maxAttempts = 5 } = {}) {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  useEffect(() => {
    let attempts = 0;
    const intervalId = setInterval(() => {
      const installed = isChromeExtensionInstalled();
      if (installed || attempts >= maxAttempts) {
        setIsExtensionInstalled(installed);
        clearInterval(intervalId);
      }
      attempts++;
    }, delay);

    return () => clearInterval(intervalId);
  }, [delay, maxAttempts]);

  return isExtensionInstalled;
}
