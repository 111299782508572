import { useState, useEffect } from 'react';
import { getRemainingCredits } from '../helper/apiHelper'

// Usage:
// const { credits, creditFetchError, areCreditsLoaded, creditHistory } = useCredits();
export const useCredits = () => {
    const [credits, setCredits] = useState(null);
    const [creditFetchError, setCreditFetchError] = useState(null);
    const [areCreditsLoaded, setAreCreditsLoaded] = useState(false);
    const [creditHistory, setCreditHistory] = useState([]);
    const [creditsUsed, setCreditsUsed] = useState(0);

    useEffect(() => {
        const fetchCredits = async () => {
            try {
                const credits = await getRemainingCredits();
                if (!credits) {
                    setCreditFetchError('No credits found');
                    setAreCreditsLoaded(true);
                    return;
                }
                setCredits(credits.available);
                setCreditHistory(credits.transactions);
                setAreCreditsLoaded(true);
                setCreditsUsed(credits.used);
            } catch (error) {
                console.error("Issue when fetching credits: ",error);
                setCreditFetchError(error);
                setAreCreditsLoaded(true);
            }
        };

        fetchCredits();
    }, []);

    return { credits, creditFetchError, areCreditsLoaded, creditHistory, creditsUsed };
};