import * as React from 'react';
import { useAllUsers } from '../../../hooks/useAllUsers';
import httpClient from '../../../http/httpClient';
import { Container, TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography, Box, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';



export default function SetUserPassword() {
    const [users, setUsers] = useAllUsers();
    const [selectedUserId, setSelectedUserId] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [message, setMessage] = React.useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setMessage('');
  
      try {
        const response = await httpClient.postData('user/addPassword', {
          userId: selectedUserId,
          username: username,
          password: password,
        });
        setMessage(response.data?.message || response.message);
      } catch (error) {
        setMessage(error.response?.data?.message || 'An error occurred.');
      }
    };

    const passwordRules = [
      "Minimum length of 16 characters.",
      "Maximum length of 100 characters.",
      "Must contain uppercase letters.",
      "Must contain lowercase letters.",
      "Must contain digits.",
      "Must contain symbols.",
      "Must not contain spaces.",
    ];
  
    return (
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom>
          Enable Password Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="user-select-label">Select User</InputLabel>
            <Select
              labelId="user-select-label"
              id="userSelect"
              value={selectedUserId}
              label="Select User"
              onChange={(e) => setSelectedUserId(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name ? `${user.name} (${user.email})` : user.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="off"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Enable Password Login
          </Button>
          {message && <Typography color="textSecondary">{message}</Typography>}

          <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <List sx={{ listStyleType: 'disc', marginLeft: '20px' }}>
              {' '}
              {/* Custom styling for bullet points */}
              {passwordRules.map((rule, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemText primary={`• ${rule}`} /> {/* Manually added bullet points */}
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Container>
    );
}
