import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  Box,
  Paper,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  IconButton
} from '@mui/material'
import {
  ChevronRight,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material'
import ReactJson from '@microlink/react-json-view'
import Http from '../../http/httpClient'

const JsonTableCell = ({ data, collapsed = false, displayObjectSize = false, collapseStringsAfterLength = false }) => (
  <TableCell>
    <ReactJson
      src={data}
      theme='pop'
      enableClipboard={false}
      iconStyle='triangle'
      collapseStringsAfterLength={collapseStringsAfterLength}
      displayObjectSize={displayObjectSize}
      displayDataTypes={false}
      name={false}
      collapsed={collapsed}
    />
  </TableCell>
)

const CollapsibleTableRow = ({ log, onReview }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const handleReview = () => {
    onReview(log._id)
  }

  // user.name if user exists otherwise 'Deleted User'
  const userName = log?.user?.name || 'Deleted User'
  // user._id if user exists otherwise 'Deleted User'
  const user_id = log?.user?._id || 'Deleted User'

  return (
    <>
      <TableRow key={log._id}>
        <TableCell style={{ verticalAlign: 'top' }}>
          <IconButton onClick={handleToggleCollapse} aria-label='Toggle details' size='small'>
            {!isCollapsed ? <ExpandMoreIcon /> : <ChevronRight />}
          </IconButton>
        </TableCell>
        {!isCollapsed
          ? (
            <>
              <TableCell>
                <Typography paragraph variant='string'>{userName}</Typography>
                <Typography paragraph variant='caption'>{user_id}</Typography>
                <Typography paragraph variant='string'>{moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
              </TableCell>
              <TableCell>{log.prompt}</TableCell>
              <TableCell>{log.chatResponse}</TableCell>
              <JsonTableCell data={log.chatJson} />
              <JsonTableCell data={log.searchQuery} />
              <JsonTableCell data={log.searchResults} collapsed={1} displayObjectSize collapseStringsAfterLength={15} />
              <TableCell>
                <FormControlLabel control={<Checkbox checked={log.reviewed} onChange={() => handleReview(log._id)} color='secondary' />} />
              </TableCell>
            </>
            )
          : (
            <>
              <TableCell>
                {userName} <br />
                {moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </TableCell>
              <TableCell title={log.prompt.length > 20 ? log.prompt : null}>
                {log.prompt.slice(0, 20)} {log.prompt.length > 20 ? '...' : ''}
              </TableCell>
              <TableCell colSpan={3} />
              <TableCell>
                {log.searchResults.length} results
              </TableCell>
              <TableCell>
                <FormControlLabel control={<Checkbox checked={log.reviewed} onChange={() => handleReview(log._id)} color='secondary' />} />
              </TableCell>
            </>
            )}
      </TableRow>
    </>
  )
}

const AISearchAuditLogs = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [logData, setLogData] = useState([])
  const [failsOnly, setFailsOnly] = useState(false)
  const [unreviewedOnly, setUnreviewedOnly] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [pageSize, setPageSize] = useState(10) // Default page size

  useEffect(() => {
    const fetchAiSearchLogs = async () => {
      setIsLoading(true)

      const queryParams = `?page=${currentPage}&pageSize=${pageSize}&failsOnly=${failsOnly}&unreviewedOnly=${unreviewedOnly}`

      Http.getData(`aiSearchAuditLogs${queryParams}`)
        .then(response => {
          setLogData(response.aiSearches)
          setTotalRecords(response.totalRecords)
        }).finally(() => {
          setIsLoading(false)
        })
        .catch(error => console.error('Error fetching log data:', error))
    }
    fetchAiSearchLogs()
  }, [currentPage, failsOnly, pageSize, unreviewedOnly])

  const handleCheckboxChange = () => {
    setFailsOnly(!failsOnly)
  }

  const handleReviewCheckboxChange = () => {
    setUnreviewedOnly(!unreviewedOnly)
  }

  const handleReview = (logId, reviewed) => {
    Http.postData('updateSearchReview', { reviewed: !reviewed, logId })
      .then(response => {
        setLogData(logData.map(log => {
          if (log._id === logId) {
            return { ...log, reviewed: !reviewed }
          }
          return log
        }))
      }).catch(error => console.error('Error updating log data:', error))
  }

  const handlePageChange = (value) => {
    setCurrentPage(value)
  }

  const handlePageSizeChange = (value) => {
    setPageSize(value)

    // Reset currentPage to 1 when page size changes
    setCurrentPage(1)
  }

  return (
    <Paper elevation={3}>
      <FormGroup style={{ marginLeft: '20px' }}>
        <FormControlLabel
          control={<Checkbox checked={failsOnly} onChange={handleCheckboxChange} color='secondary' />}
          label='Failures only'
        />
        <FormControlLabel
          control={<Checkbox checked={unreviewedOnly} onChange={handleReviewCheckboxChange} color='secondary' />}
          label='Unreviewed only'
        />
      </FormGroup>

      <TablePagination
        rowsPerPageOptions={[1, 10, 20, 50]}
        component='div'
        count={totalRecords}
        rowsPerPage={pageSize}
        page={currentPage - 1} // Page is 0-based in TablePagination, but your logic seems to be 1-based
        onPageChange={(event, value) => handlePageChange(value + 1)}
        onRowsPerPageChange={(event) => handlePageSizeChange(event.target.value)}
        style={{ float: 'left' }}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width='1%' />
              <TableCell width='12%'>user</TableCell>
              <TableCell width='15%'>prompt</TableCell>
              <TableCell width='15%'>chatResponse</TableCell>
              <TableCell width='20%'>chatJson</TableCell>
              <TableCell width='20%'>searchQuery</TableCell>
              <TableCell width='20%'>searchResults</TableCell>
              <TableCell width='2%'>reviewed</TableCell>
            </TableRow>
          </TableHead>

          {!isLoading
            ? (
              <TableBody>
                {logData.map((log) => (
                  <CollapsibleTableRow
                    key={log._id}
                    log={log}
                    onReview={handleReview}
                  />
                ))}
              </TableBody>
              )
            : (
              <Box width='100%' display='flex' alignItems='center' justifyContent='center' textAlign='center' marginTop='25%'>
                <CircularProgress color='secondary' />
              </Box>
              )}
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default AISearchAuditLogs
