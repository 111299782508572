import React, { useState, useCallback } from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import { useTheme, CircularProgress } from "@mui/material";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import Alert from '@mui/material/Alert'

import { ReactComponent as Back } from '../../assets/Icons/Arrow-Left.svg';

import Http from '../../http/httpClient'
import GiftDetailsModal from './giftDetailsModal'

const styles = {
  textOverflow: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
};

export default function ChooseGiftModal(props) {
  const theme = useTheme();
  const { onClose, open, step, setupList } = props;
  const giftCardsRef = React.useRef([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [giftCards, setGiftCards] = React.useState([]);
  const [checkboxChanged, setCheckboxChanged] = useState(false);
  const [selectedGift, setSelectedGift] = React.useState(step?.emailTemplate?.giftDetails); // Converted this into array, once we allow to choose multiple gift
  const [isUpdateProgress, setIsUpdateProgress] = React.useState(false)
  const [detailsOpen, setDetailsOpen] = React.useState(false)
  const [giftForDetails, setGiftForDetails] = React.useState({})
  const [showAlert, setShowAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")

  const buttonStyles = {
    backgroundColor: theme.palette.primary.lightBlue,
    color: theme.palette.primary.blue,
    textTransform: "none",
    py: 1,
    px: 1,
    borderRadius: 3,
    minWidth: '78px',
    lineHeight: 'unset'
  };

  const handleClose = () => {
    onClose();
  };

  const handleContinue = () => {
    if(selectedGift && selectedGift?.id && selectedGift?.name &&  selectedGift?.price) {
      step.emailTemplate = step.emailTemplate ?? {}
      step.emailTemplate.giftName = selectedGift?.name;
      step.emailTemplate.giftPrice = selectedGift?.price;
      step.emailTemplate.giftDetails = {
        ...selectedGift
      };
      step.emailTemplate.emailBody = `Dear <Their first name>,
  
      We hope this email finds you well! 🌟
      As a token of our appreciation for your interest in <My company or product>, we're excited to present you with an exclusive gift! To claim your special surprise, simply click on the link below:
      <Insert Gift Claim Link>
  
      This gift is our way of saying thank you for considering us. We hope it adds a little extra joy to your day.`;
      step.emailTemplate.subjectLine = `A special gift for you!`;
      onClose();
    }else{
      setAlertMessage("Please select a gift to continue.")
      setShowAlert(true)

      return
    }
  };

  React.useEffect(() => {
    setIsLoading(true)
    Http.getData('getSendosoGifts')
      .then(function (response) {
        console.log("==> response:", response)
        if (response && response.status) {
          const gifts = response.gifts
          const updatedGifts = gifts.map((gift) => {
          if (gift.id === selectedGift?.id) {
            return { ...gift, selected: true };
          } else {
            return { ...gift, selected: false };
          }
        });
          setGiftCards((prev) => updatedGifts)
          giftCardsRef.current = updatedGifts;
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const ChooseGift = (gift) => {
    const isCurrentlySelected = selectedGift && selectedGift.id === gift.id;
  
    if (!isCurrentlySelected) {
      setSelectedGift(gift);
    } else {
      // If the same gift is selected again, deselect it
      setSelectedGift(null);
    }
    onCheckBoxChange(gift.id);
  };

  const onCheckBoxChange = useCallback(
    (selectedGiftId) => {
      const updatedGifts = giftCardsRef.current.map((gift) => {
        return { ...gift, selected: gift.id === selectedGiftId };
      });
  
      // If the currently selected gift is clicked again, deselect it by setting all to not selected
      const isDeselecting = selectedGift && selectedGift.id === selectedGiftId;
      if (isDeselecting) {
        updatedGifts.forEach(gift => gift.selected = false);
      }
  
      setGiftCards(updatedGifts);
      giftCardsRef.current = updatedGifts;
      setCheckboxChanged((prev) => !prev);
    },
    [selectedGift]
  );

  React.useEffect(() => {
    const initialGiftCards = giftCardsRef.current
    if(initialGiftCards.length === 1 && !initialGiftCards[0].selected) {
      initialGiftCards[0].selected = false
      setSelectedGift()
    }
    setGiftCards(initialGiftCards)
  }, [checkboxChanged])

  const closeDetailsModal = () => {
    setDetailsOpen(false)
  }

  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);


  return (
    <>
    <Dialog onClose={handleClose} open={open} fullScreen sx={{ 
      px: {
        xs: 0,
        md: 2,
      }
    }}>
      <DialogTitle sx={{ p: 0 }}>
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose} sx={{ pt: 2, pl: 2 }}>
              <Back fill={theme.palette.primary.grey} />
            </IconButton>
          </Grid>
          <Grid item>
            {showAlert && <Alert severity="error">{alertMessage}</Alert>}
          </Grid>
          <Grid item>
            <Button
              onClick={handleContinue}
              disabled={giftCards.length === 0}
              sx={{
                maxWidth: '200px',
                backgroundColor: theme.palette.primary.blue,
                color: theme.palette.primary.white,
                textTransform: 'none',
                width: '167px',
                py: 1,
                px: 2,
                mr: 2,
                mt: 2,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                borderRadius: 2,
                boxShadow: '0px 4px 8px 0px #2C64E31F',
                '&:hover': {
                  backgroundColor: theme.palette.primary.lightBlue,
                  color: theme.palette.primary.blue,
                },
              }}
            >
              {isUpdateProgress ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Continue'
                )}
            </Button>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            <LinearProgress
              variant={isLoading ? "indeterminate" : "determinate"} 
              value={75}
              sx={{
                my: 2,
                height: 6,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: theme.palette.primary.lightBlue,
                },
                [`& .${linearProgressClasses.bar}`]: {
                  backgroundColor: '#87E554',
                },
              }}
            />
          </Grid>
        </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6} >
            <Typography sx={{
              fontWeight: 700,
              color: theme.palette.primary.black,
              pt: 1,
              fontSize: {
                xs: '28px',
                md: '32px',
              },
              lineHeight: 'normal'
            }}>
              Choose a gift you'd like to send.
            </Typography>
            <Typography sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: theme.palette.primary.grey,
              pb: 5,
            }}>
              Choose, personalize, and send gifts.
            </Typography>
            {isLoading && giftCards.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "50px",
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            ) : (
              <>
                {giftCards.length === 0 ? (
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      textAlign: 'center',
                      mt: 5,
                    }}
                  >
                    We were not able to find any e-gifts at this time. Try again later.
                  </Typography>
                ) : (
                  giftCards.map((gift, index) => (
                    <Container
                      style={{ paddingLeft: '16px' }}
                      key={gift.id}
                      sx={{
                        backgroundColor: theme.palette.primary.white,
                        py: 2,
                        my: 1,
                        borderRadius: 3,
                        width: "100%",
                        border: '1px solid #E0E0E0',
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                      >
                        <Grid container item xs={9} sm={10} xl={11} alignItems="center" spacing={2}>
                          <Grid item>
                            <Avatar
                              sx={{ my: 2, mt: 0, alignSelf: "flex-start", borderRadius: 3, width: '120px', height: '70px' }}
                              src={gift.full_image_url || null}
                            />
                          </Grid>
                          <Grid item sx={{ width: "80%" }}>
                            <Stack>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  color: theme.palette.primary.black,
                                  ...styles.textOverflow,
                                }}
                                title={gift.name}
                              >
                                {gift.name}
                              </Typography>
                              <Box display='flex' alignItems='center'>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: theme.palette.primary.black,
                                  ...styles.textOverflow,
                                }}
                                title={gift.price}
                              >
                                {`Est. $${gift.price}`}
                              </Typography>
                              <Typography
                                onClick={() => { setGiftForDetails(gift); setDetailsOpen(true) }}
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  color: theme.palette.primary.blue,
                                  marginLeft: 1,
                                  cursor: 'pointer'
                                }}
                              >
                                · See details
                              </Typography>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container item xs={3} sm={2} xl={1} alignItems="center">
                          <Button
                            onClick={() => ChooseGift(gift)}
                            sx={
                              !gift.selected
                                ? buttonStyles
                                : {
                                  ...buttonStyles,
                                  backgroundColor: theme.palette.primary.white,
                                  border: '1px solid #e0e0e0'
                              }
                            }
                          >
                            {" "}
                            {gift.updating ? (
                              <CircularProgress
                                size={24}
                                color="inherit"
                                style={{ position: "absolute" }}
                              />
                            ) : !gift.selected ? (
                              "Choose"
                            ) : (
                              "Remove"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Container>
                  ))
                )}
              </>
            )}
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
      </DialogContent>
    </Dialog>
    {detailsOpen &&
      <GiftDetailsModal
        open={detailsOpen}
        onClose={closeDetailsModal}
        gift={giftForDetails}
        handleChooseGift={() => {
          ChooseGift(giftForDetails, true)
          closeDetailsModal()
        } } /> }
    </>
  );
}