import * as React from 'react'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

import Http from '../../../http/httpClient'
import EditUserModal from './editUserModal'
import { SetCookie, GetCookie } from './../../../helper/cookieManager'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function ActivationItem({ user }) {
  const [origUserType, setOrigUserType] = React.useState(user.userType)
  const [origStatus, setOrigStatus] = React.useState(user.status)
  const [userType, setUserType] = React.useState(user.userType)
  const [status, setStatus] = React.useState(user.status)
  const [isVip, setIsVip] = React.useState(user.vip)

  const [editOpen, setEditOpen] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [snackmessage, setSnackmessage] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const handleOpenEditModal = () => {
    setEditOpen(true)
  }

  const handleCloseEditModal = (event, updatedData) => {
    if (updatedData && (typeof updatedData === 'object')) {
      setIsUpdating(true)
      Http.postData('users/update', { updatedData })
        .then((res) => {
          // setSnackmessage(res.message)
          setUserType(updatedData.userType)
          setStatus(updatedData.status)
          setOrigUserType(updatedData.userType)
          setOrigStatus(updatedData.status)
          setIsVip(updatedData.isVip)

          // TODO: spcmt: It will work for now.
          // As of now no admin account to do this.
          // So, the user type will be reset for the current logged in user.
          // Later, we need to ask users to logout in order to clear the session and then login again.
          const user = GetCookie('user')
          if (!user.userType) {
            user.userType = updatedData.userType
          } else {
            user.userType = updatedData.userType
          }
          SetCookie('user', JSON.stringify(user))
          alert('Record saved successfully')
        })
        .catch((error) => {
          console.log(error)
          alert(error)
        }).finally(() => {
          setIsUpdating(false)
        })
    } else {
      setUserType(origUserType)
      setStatus(origStatus)
    }
    setEditOpen(false)
  }

  const handleSnackClose = () => {
    setOpen(false)
  }

  const handleSendQueueNotification = async () => {
    try {
      const res = await Http.postData('external/sendQueueNotification', { userId: user._id })
      setSnackmessage(res.message)
      setOpen(true)
    } catch (error) {
      console.log(error)
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <>
      <StyledTableRow key={user.id}>
        <StyledTableCell component='th' scope='row'>
          {user.name}
        </StyledTableCell>
        <StyledTableCell>{user.email}</StyledTableCell>
        <StyledTableCell>{userType}</StyledTableCell>
        <StyledTableCell>{status}</StyledTableCell>
        <StyledTableCell>
          {isUpdating
            ? <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
            : <EditIcon sx={{ '&:hover': { cursor: 'pointer' } }} onClick={handleOpenEditModal} />}
          <EditUserModal open={editOpen} onClose={handleCloseEditModal} userData={user} />
        </StyledTableCell>
        <StyledTableCell>
          <Button variant="contained" color="primary" onClick={handleSendQueueNotification}>
            Send Queue
          </Button>
        </StyledTableCell>
      </StyledTableRow>
      {/* <Snackbar
        open={open}
        onClose={handleSnackClose}
        autoHideDuration={3000}
        message={snackmessage}
      /> */}
    </>
  )
}
