import * as React from 'react'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/styled-engine'

const rotateKeyframes = keyframes`
  20% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.7;
  }
`;

const waveKeyframes = keyframes`
  0% {
    transform: scale(1);
  }
  45% {
    opacity: 0.7;
    transform: scale(0.75);
  }
  100% {
    opacity: 0.9
    transform: scale(1);
  }
`;

const TourIconButton = styled(IconButton)(({ theme, background }) => ({
  position: 'relative',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  background: background,
  '&::before, &::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    borderRadius: '50%',
    border: '2px solid #3983F7',
    opacity: 0.5
  },
  '&::before': {
    top: '15%',
    left: '15%',
    width: '30px',
    height: '30px',
    'transform-origin': 'center center',
    animation: `1.2s ease-in-out 0s infinite normal none running ${waveKeyframes}`
  },
  '&::after': {
    top: '5%',
    left: '5%',
    width: '40px',
    height: '40px',
    'transform-origin': 'center center',
    animation: `1.2s ease-in-out 0s infinite normal none running ${waveKeyframes}`
  },
  '& .MuiSvgIcon-root': {
    fontSize: 28,
    color: '#3983F7',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animation: `${rotateKeyframes} 2s linear infinite`
  }
}))


const TourIconComponent = ({ background='#fff', setDisableGuide, setIsTourOpen }) => {
  const handleTourIconClick = () => {
    setIsTourOpen(true)
    setDisableGuide(true)
  }

  return (
    <Tooltip title="Take a Tour" placement="top" arrow>
      <TourIconButton
        background={background}
        onClick={handleTourIconClick}
      >
        <InfoSharpIcon />
      </TourIconButton>
    </Tooltip>
  )
}

export default TourIconComponent
