import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import Http from '../../http/httpClient'
import { handleAuthentication } from '../../helper/authUtility'

export default function ApiTokenManager() {
  const [isLoading, setIsLoading] = React.useState(false)
  const [provider, setProvider] = React.useState('')
  const [showAlert, setShowAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")

  const handleProviderChange = (event) => {
    setProvider(event.target.value)
  }

  const handleReauth = async () => {
    await handleAuthentication('/api/auth/sendoso?via=admin')

    const bc = new BroadcastChannel('adminAuthChannel');
    const eventPromise = new Promise((resolve) => {
      bc.addEventListener('message', async (event) => {
        if (event.data.status === 200) {
          setAlertMessage('Sendoso token generated successfully!.')
          setShowAlert(true)
          resolve();
        }
      });
    });
  }

  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false)
      }, 3000)

      return () => clearTimeout(timeoutId)
    }
  }, [showAlert])

  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
      <Typography variant='h6' gutterBottom>
        API Token Manager
      </Typography>
      <Select
        value={provider}
        onChange={handleProviderChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{ minWidth: 200 }}
      >
        <MenuItem value='' disabled>
          Select Provider
        </MenuItem>
        <MenuItem value='Sendoso'>Sendoso</MenuItem>
      </Select>
      <Button
        sx={{
          mt: 2,
          ml: 1,
          px: 3,
          my: 2,
          backgroundColor: '#C4C4C4',
          color: '#000000',
          fontSize: '1.2rem',
          fontWeight: 'bold',
          textTransform: 'none',
          fontFamily: 'Poppins'
        }}
        onClick={handleReauth}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress color='inherit' size={24} />
        ) : (
          'Reauth'
        )}
      </Button>
      <Box>
        {showAlert && (<Alert severity="success">{alertMessage}</Alert>)}
      </Box>
    </Box>
  )
}
