import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

export default function OnChangePlugin({ onChange }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      // console.log('DATA', JSON.stringify(listener.editorState.toJSON()));
      //   const data = JSON.stringify(editorState.toJSON());
      if (typeof onChange === 'function') {
        onChange(editorState);
      }
    });
  }, [editor]);

  return null;
}
