import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PTypography from '../PTypography';
import { Providers, TopicType } from '@/constants/constant';
import PPostCardCommonContent from './PPostCardCommonContent';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useSelector } from 'react-redux';
import featureFlagService from '@/services/featureFlagService';
import { isLexicalHTML } from '@/utils/richTextUtils';
import ProxyImage from '@/components/ProxyImage';

const getHeaderText = (source) => {
  if (source === Providers.TWITTER) {
    return 'Tweet';
  }

  if (source === Providers.LINKED_IN) {
    return 'LinkedIn Post';
  }
};

const SocialMediaPostCard = ({ post }) => {
  const featureFlags = useSelector((state) => state.auth.featureFlags);

  const determineMessageDisplay = useCallback(
    (msg) => {
      const isPRTEditorEnabled = featureFlagService.isFeatureEnabled('ui.richTextEditor', featureFlags);

      if (isPRTEditorEnabled && isLexicalHTML(msg)) {
        return <div dangerouslySetInnerHTML={{ __html: msg }} />;
      }

      return (
        <PTypography size="body2" weight="regular">
          {msg}
        </PTypography>
      );
    },
    [featureFlags],
  );

  const messageDisplay = useMemo(() => {
    return <div style={{ marginTop: '8px' }}>{determineMessageDisplay(post?.message)}</div>;
  }, [post, determineMessageDisplay]);

  const imageUrl = useMemo(() => {
    if (post?.topic_type === TopicType.IMAGE_POST && post?.uploadDetails?.preSignedURL) {
      return post?.uploadDetails?.preSignedURL;
    }

    if (post?.articleImageUrl) {
      return post?.articleImageUrl;
    }
  }, [post]);

  const headerNode = useMemo(() => {
    return (
      <PTypography size="body2" weight="bold">
        {post.source === 'linkedin' && <LinkedInIcon sx={{ fontSize: 18 }} style={{ verticalAlign: 'top' }} />}
        {getHeaderText(post.source)}
      </PTypography>
    );
  }, [post.source]);

  return (
    <PPostCardCommonContent post={post} headerNode={headerNode}>
      {messageDisplay}
      {imageUrl && (
        <ProxyImage
          style={{ height: 200, width: 350, marginTop: '16px', borderRadius: 3, objectFit: 'cover' }}
          alt="imagepost"
          src={imageUrl}
        />
      )}
    </PPostCardCommonContent>
  );
};

SocialMediaPostCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default SocialMediaPostCard;
