import React from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

import { ReactComponent as Check } from '../../assets/Icons/Check.svg';

export default function Success(props) {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{mt: 3, mx: 8}}>
            <Avatar sx={{ bgcolor: theme.palette.primary.white, borderColor: theme.palette.primary.blue, borderWidth: 1, borderStyle: 'solid' }}>
                <Check fill={theme.palette.primary.blue} />
            </Avatar>

            <Typography sx={{ fontWeight: 500, fontSize: '24px', color: theme.palette.primary.black, mt: 2, mb: 3 }}>
                Your post is now live!
            </Typography>
        </Box>
    )
}
