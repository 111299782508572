import { memo, useMemo } from 'react';
import { useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import { ReactComponent as CheckSVG } from "@Assets/Icons/Check.svg";
import PropTypes from 'prop-types';

const chipLabelStyles = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '12px',
  marginTop: '14px',
  marginBottom: '14px',
  marginLeft: '14px',
  p: 0,
  m: 0,
};

const chipCommonStyles = {
  display: 'inline-flex',
  height: '42px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '130%',
  borderRadius: '14px',
  padding: '0px',
  border: '1px solid',
  transition: '0.1s ease',
  '& > span': { p: 0, m: 0 },
};

const iconCommonStyles = {
  // We have to "inflate" MUI icons a bit to make them visually match the design,
  // so the actual numbers are a bit off. Icons are +4px in width and height.
  width: '20px',
  height: '20px',
  // Intended right margin is 16px, but with the inflated icon size, we we need to use 12px.
  marginRight: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const postilizeVariants = (theme) => {
  const pVariants = {
    green: {
      backgroundColor: theme.palette.primaryCL.Green50,
      color: theme.palette.primaryCL.Green100,
      borderColor: 'transparent',
      '&&:hover': {
        backgroundColor: theme.palette.primaryCL.Green50,
        borderColor: theme.palette.primaryCL.Green100,
      },
    },

    red: {
      backgroundColor: theme.palette.primaryCL.Red50,
      color: theme.palette.primaryCL.Red100,
      borderColor: 'transparent',
      '&&:hover': {
        backgroundColor: theme.palette.primaryCL.Red50,
        borderColor: theme.palette.primaryCL.Red100,
      },
    },

    grey: {
      backgroundColor: theme.palette.primaryCL.Black50,
      color: theme.palette.primaryCL.Black200,
      borderColor: 'transparent',
      '&&:hover': {
        backgroundColor: theme.palette.primaryCL.Black50,
        borderColor: theme.palette.primaryCL.Black200,
      },
    },

    darkGrey: {
      backgroundColor: theme.palette.primaryCL.Black70,
      color: theme.palette.primaryCL.Black200,
      borderColor: 'transparent',
      '&&:hover': {
        backgroundColor: theme.palette.primaryCL.Black70,
        borderColor: theme.palette.primaryCL.Black200,
      },
    },
  };

  pVariants.default = pVariants.grey;

  return pVariants;
};

const PTopicSelector = memo(({
  topic, onToggle, hideIcon, pVariant, useCheckIcon
}) => {
  const theme = useTheme();
  const renderedPVariant = useMemo(
    () => postilizeVariants(theme)[pVariant],
    [theme, pVariant],
  );

  /* For hover functionality:
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = useCallback(
    (newHovered) => {
      setIsHovered(newHovered);
    },
    [setIsHovered],
  );
  */

  const determineIcon = useMemo(() => {
    if (hideIcon) return null;

    const iconStyle = {
      ...iconCommonStyles,
      fill: renderedPVariant?.color,
    };

    let icon = null;

    if (topic?.isActive) {
      // Use custom Check SVG if useCheckIcon is true, otherwise use MUI Close icon
      icon = useCheckIcon ? <CheckSVG style={{ ...iconStyle, width: '12px', height: '12px', marginLeft: '0px' }} /> : <Close style={iconStyle} />;
    } else {
      icon = <Add style={iconStyle} />;
    }

    return icon;
  }, [hideIcon, renderedPVariant, topic?.isActive, useCheckIcon]);

  if (!pVariant) return null;

  const chipStyle = { ...chipCommonStyles, ...renderedPVariant }
  return (
    <Chip
      key={`${topic.name}Chip`}
      label={<span style={chipLabelStyles}>{topic.name}</span>}
      sx={chipStyle}
      onClick={() => onToggle(topic.name)}
      onDelete={hideIcon ? undefined : () => onToggle(topic.name)}
      deleteIcon={determineIcon}
    /* For hover functionality:
    onMouseEnter={() => handleHover(true)}
    onMouseLeave={() => handleHover(false)}
    */
    />
  );
});

PTopicSelector.propTypes = {
  topic: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }).isRequired,

  onToggle: PropTypes.func.isRequired,

  pVariant: PropTypes.oneOf(['default', 'green', 'red', 'grey']).isRequired,

  /* If truthy, the icon will not be rendered. */
  hideIcon: PropTypes.bool,
  useCheckIcon: PropTypes.bool,
};

PTopicSelector.defaultProps = {
  hideIcon: false,
  useCheckIcon: false,
};

export default PTopicSelector;
