import { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/system/Stack';
import Avatar from '@mui/material/Avatar';
import PTypography from '@Library/PTypography';

import { ReactComponent as CRMWait } from '@Assets/emptyState/CRMWait.svg';
import { ReactComponent as Back } from '@Assets/Icons/Arrow-Left.svg';

import { GetCookie } from '../../../../helper/cookieManager';
import Http from '../../../../http/httpClient';
import CustomModal from '../../../login/components/confirmModal';

export default function CRMResults(props) {
  const theme = useTheme();
  const { onClose, open, onContinue, isUpdateProgress, setupList } = props;
  const [email, setEmail] = useState('');
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [modalResponse, setModalResponse] = useState(null);
  const [mailUpdateProgress, setMailUpdateProgress] = useState(false);

  const handleContinue = async () => {
    onContinue();
  };

  useEffect(() => {
    const lUser = GetCookie('user');
    const emailAddress = lUser && lUser.email ? lUser.email : '';
    setEmail(emailAddress);
  }, []);

  const handleEmail = () => {
    let alertPrompt = `We will let you know when your contacts are ready at '${email}'. Press 'OK' if you agree.`;
    setAlertText(alertPrompt);
    setShowAlert(true);
  };

  useEffect(() => {
    if (modalResponse === 'OK') {
      setMailUpdateProgress(true);
      Http.postData('postEmail', { email_address: email })
        .then(function (resp) {
          if (resp.status) {
            handleContinue();
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setModalResponse('');
          setMailUpdateProgress(false);
        });
    } else if (modalResponse === 'Cancel') {
      setModalResponse('');
    }
  }, [modalResponse]);

  const handleModalAction = (action) => {
    setModalResponse(action);
    setShowAlert(false);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {showAlert && <CustomModal clickAction={handleModalAction} alertText={alertText} />}
      <Dialog onClose={handleClose} open={open} fullScreen>
        <DialogTitle sx={{ p: 0 }}>
          <Grid container sx={{ mt: 2, px: 2 }} alignItems="flex-start" justifyContent="space-between">
            <Grid item>
              <IconButton aria-label="close" onClick={handleClose}>
                <Back fill={theme.palette.primary.black} />
              </IconButton>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={10} sx={{ my: 1.5 }}>
                {setupList?.map((item) => (
                  <Stack justifyContent="center" alignItems="center">
                    <Avatar
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        bgcolor: item.active ? theme.palette.primary.blue : theme.palette.primary.white,
                        color: item.active ? theme.palette.primary.white : theme.palette.primary.grey,
                        border: item.active ? `none` : `1px solid #E0E0E0`,
                        my: 1,
                      }}
                    >
                      {item.index}
                    </Avatar>
                    <Typography
                      sx={{
                        color: item.active ? theme.palette.primary.black : theme.palette.primary.grey,
                        fontWeight: 500,
                      }}
                    >
                      {item.listText}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid item>
              <Button
                onClick={handleContinue}
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
              >
                {isUpdateProgress ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
              </Button>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12}>
              <LinearProgress
                variant="determinate"
                value={85}
                sx={{
                  my: 2,
                  height: 6,
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: theme.palette.primary.lightBlue,
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: '#87E554',
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={4} />
            <Grid item xs={4}>
              <PTypography sx={{ pt: 5, mb: 3 }} size="h3" weight="bold">
                <span style={{ color: theme.palette.primary.black }}>We’re getting your list ready.</span>{' '}
                <span style={{ color: theme.palette.primary.grey }}>
                  We'll send an email directly to your inbox when it’s ready.
                </span>
              </PTypography>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme.palette.primary.lightGrey,
                  p: 3,
                  borderRadius: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CRMWait />
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 500, mt: 2, mb: 2, color: theme.palette.primary.black }}
                >
                  Opt in to receive notifications when your list is ready, and we'll send an email directly to your
                  inbox.
                </Typography>
                <Button
                  onClick={handleEmail}
                  sx={{
                    'backgroundColor': theme.palette.primary.blue,
                    'color': theme.palette.primary.white,
                    'textTransform': 'none',
                    'width': '100%',
                    'py': 1,
                    'px': 2,
                    'mx': 2,
                    'mt': 2,
                    'whiteSpace': 'nowrap',
                    'overflow': 'hidden',
                    'borderRadius': 2,
                    'boxShadow': '0px 4px 8px 0px #2C64E31F',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                    },
                  }}
                >
                  {mailUpdateProgress ? <CircularProgress size={24} color="inherit" /> : 'Notify me via Email'}
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
