import React from 'react'

import { Container, CssBaseline, Stack, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import { useNavigate } from 'react-router-dom'

import MemberListItem from './memberListItem'
import Http from '../../../http/httpClient'
import { GetCookie } from '../../../helper/cookieManager'
import MemberInviteItem from './memberInviteItem'

const Invites = ({ currentUser }) => {
  const theme = useTheme()
  const [invited, setInvited] = React.useState([])
  const [isLoading, setLoading] = React.useState(true)

  React.useEffect(() => {
    async function getCompany () {
      try {
        setLoading(true)
        const response = await Http.getData('company/get')
        setInvited(response.company.invitations)
      } catch (error) {
        console.error('Error fetching company data:', error)
        // Handle error, e.g., set an error state or display an error message
      } finally {
        setLoading(false)
      }
    }
    getCompany()
  }, [])

  return (

    <>
      {isLoading
        ? <></>
        : <><Typography sx={{ fontWeight: 700, fontSize: '16px', color: theme.palette.primary.grey, py: 2 }}>
          Invited
            </Typography>
          <List>
            {invited.map((invite) => (
              <>
                <MemberInviteItem key={invite._id} invite={invite} />
              </>
            ))}
          </List>
          </>}
    </>

  )
}

export default Invites
