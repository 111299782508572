import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { TextField, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'
import PButton from '../../../components/library/PButton';
import {useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setUserBio } from "../../../redux/authSlice";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import Http from '../../../http/httpClient';
import PTextfield from '@/components/library/PTextfield';

export default function Bio(props) {
  const dispatch = useDispatch();
  const theme = useTheme()
  const { addSnackbar } = useSnackbar();
  const { setIsChangesSaved } = props;
  const nameInputRef = React.useRef(null)
  const benefitsInputRef = React.useRef(null)
  const descInputRef = React.useRef(null)
  const [name, setName] = React.useState('')
  const [benefits, setBenefits] = React.useState('')
  const [desc, setDesc] = React.useState('')
  const [tempName, setTempName] = React.useState('')
  const [tempBenefits, setTempBenefits] = React.useState('')
  const [tempDesc, setTempDesc] = React.useState('')

  const [nameRows, setNameRows] = React.useState(1);
  const [descRows, setDescRows] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    const fetchBio = async () => {
      const response = await Http.getData('setup/getBio')
      setName(response?.bio?.companyName)
      setDesc(response?.bio?.product)
      setBenefits(response?.bio?.customerBenefits)
      setTempName(response?.bio?.companyName)
      setTempDesc(response?.bio?.product)
      setTempBenefits(response?.bio?.customerBenefits)
      dispatch(setUserBio({
        companyName: response?.bio?.companyName,
        product: response?.bio?.product,
        customerBenefits: response?.bio?.customerBenefits
      }))
    }
    fetchBio()
  }, [dispatch])

  React.useEffect(() => {

    if (tempName !== name || tempDesc !== desc || tempBenefits !== benefits) {
      setIsChangesSaved(false)
    } else {
      setIsChangesSaved(true)
    }

  }, [name, desc, benefits])

  const bio = useSelector(state => state.auth.userBio);

  const updateBio = async () => {
    try {
      if (name.trim().length === 0 || desc.trim().length === 0 || benefits.trim().length === 0) {
        addSnackbar({
          message: 'Please fill all the required fields.',
          pVariant: "destructive",
          hideAction: true,
        })
        return;
      }

      setIsLoading(true)
      Http.postData('setup/setBio', { bio } )
        .then(async (response) => {
          if (response && response.status) {
            addSnackbar({
              message: response.message,
              pVariant: "success",
              hideAction: true,
            })
            setIsChangesSaved(true)
            setTempName(tempName)
            setTempDesc(tempDesc)
            setTempBenefits(tempBenefits)
          }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleInputChange = (event, setState, setRows) => {
    const value = event.target.value;
    setState(value);

    const lineBreaks = (value.match(/\n/g) || []).length;
    const newRows = Math.max(1, lineBreaks + 1);
    setRows(newRows);
  };

  return (
    <>      
      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: 700,
          color: theme.palette.primary.black,
          pt: { xs: 5, md: 2 },
          textAlign: { xs: "left" },
          pl: { xs: 5 },
          mt: { xs: 4 },
          lineHeight: "normal",
        }}
      >
        Tell us about yourself
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: theme.palette.primary.grey,
          pb: 5,
          pl: { xs: 5 },
          pr: { xs: 2 },
        }}
      >
        This will allow you to craft messages that feel more personalized.
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: theme.palette.primary.black,
          pl: { xs: 5 },
        }}
      >
        Your company's name?{' '}
        <span style={{ color: theme.palette.primary.orange}}>*</span>
      </Typography>

      <PTextfield
        ref={nameInputRef}
        type='text'
        value={name}
        placeholder='E.g, Acme inc.'
        sx={{
          ml: 1,
          flex: 1,
          minHeight: `${nameRows * 1.5}em`, // Adjust the height based on rows
        }}
        onChange={(event) => {
          handleInputChange(event, setName, setNameRows);
          dispatch(setUserBio({
            companyName: event.target.value,
            product: desc,
            customerBenefits: benefits
          }));
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            descInputRef.current.focus();
          }
        }}
        paperStyle={{
          mb: 3,
          width: { xs: "90vw", md: "80%" },
          ml: { xs: 5 },
        }}
      />
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: theme.palette.primary.black,
          pl: { xs: 5 },
        }}
      >
        Describe what your company does{' '}
        <span style={{ color: theme.palette.primary.orange}}>*</span>
      </Typography>
      <PTextfield
        inputRef={descInputRef}
        value={desc}
        multiline
        maxRows={10}
        type='text'
        variant='standard'
        InputProps={{ disableUnderline: true }}
        placeholder='E.g, We provide expert advice and solutions for technology-related challenges'
        sx={{ ml: 1, flex: 1 }}
        onChange={(event) => {
          handleInputChange(event, setDesc, setDescRows);
          dispatch(setUserBio({
            companyName: name,
            product: event.target.value,
            customerBenefits: benefits
          }));
        }}
        paperStyle={{
          mb: 3,
          width: { xs: "90vw", md: "80%" },
          ml: { xs: 5 },
        }}
      />
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: theme.palette.primary.black,
          pl: { xs: 5 },
        }}
      >
        List the benefits you offer potential customers{' '}
        <span style={{ color: theme.palette.primary.orange}}>*</span>
      </Typography>
      <PTextfield
        ref={benefitsInputRef}
        value={benefits}
        multiline
        maxRows={10}
        type='text'
        variant='standard'
        InputProps={{ disableUnderline: true }}
        placeholder='E.g. Cost-savings, excellent customer service, specialty expertise'
        sx={{ ml: 1, flex: 1, }}
        onChange={(event) => {
          setBenefits(event.target.value)
          dispatch(setUserBio({
            companyName: name,
            product: desc,
            customerBenefits: event.target.value
          }))
        }}
        paperStyle={{
          mb: 3,
          width: { xs: "90vw", md: "80%" },
          ml: { xs: 5 },
        }}
      />
      {isMobile ? (
        <Button
          onClick={updateBio}
          sx={{
            boxShadow: 0,
            fontWeight: 500,
            fontSize: "14px",
            borderRadius: 2,
            backgroundColor: theme.palette.primary.lightBlue,
            color: theme.palette.primary.blue,
            px: 7,
            py: 1,
            mr: 16,
            my: 2,
            "&:hover": {
              backgroundColor: theme.palette.primary.blue,
              color: theme.palette.primary.white,
            },
            width: "90vw",
            marginLeft: "20px",
          }}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Save"}
        </Button>
        ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10rem",
          }}
        >
          <PButton
            onClick={updateBio}
          >
            {" "}
            {isLoading ? (
              <CircularProgress
                size={24}
                color="inherit"
              />
            ) : (
              "Save"
            )}
          </PButton>
        </div>
      )}
    </>
  );
}
