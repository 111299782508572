import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField';
import { useTheme, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import CustomModal from '../../login/components/confirmModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '5px',
  boxShadow: 2,
  p: 4
}

const CheckboxItem = ({ name, label, checked, onChange }) => {
  const theme = useTheme()

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          name={name}
          onChange={() => onChange(name)}
          sx={{
            color: theme.palette.primary.blue,
            '&.Mui-checked': {
              color: theme.palette.primary.blue,
              backgroundColor: 'white'
            },
            '&.Mui-disabled': {
              color: theme.palette.primary.lightGrey,
              backgroundColor: 'white'
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 255, 0.5)'
            }
          }}
        />
      }
      label={label}
      sx={{
        '& .MuiTypography-root': {
          color: theme.palette.primary.black,
          fontSize: '12px',
          fontWeight: 500
        },
        marginRight: 6
      }}
    />
  );
}

export default function CompanyPreferenceModal ({ open, onClose, company }) {
  const theme = useTheme()
  const [goals, setGoals] = React.useState(company.goals || {})
  const [audience, setAudience] = React.useState(company.targetAudience || {})
  const [companyState, setCompanyState] = React.useState(company);
  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [alertText, setAlertText] = React.useState('');
  const [validateModalOpen, setValidateModalOpen] = React.useState(false);
  const [adminInvite, setAdminInvite] = React.useState(company.adminInvite?.invitee || '');
  const [maxEmailCount, setMaxEmailCount] = React.useState(company.emailPreference.maxEmailCount)

  const resetWithOriginalValues = () => {
  }

  const handleOnClose = () => {
    resetWithOriginalValues()
  }

  const handleGoalChange = (goal) => {
    setGoals(prevGoals => ({
      ...prevGoals,
      [goal]: !prevGoals[goal]
    }))
  }

  const handleAudienceChange = (audienceType) => {
    setAudience(prevAudience => ({
      ...prevAudience,
      [audienceType]: !prevAudience[audienceType]
    }))
  }

  const handleModalAction = (action) => {
    setValidateModalOpen(false);
    setAlertText('');
  };

  const handleEmailCount = (e) =>{
    setMaxEmailCount(e.target.value)
  }

  return (
    <div>
      {validateModalOpen && (
        <CustomModal clickAction={handleModalAction} alertText={alertText} />
      )}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='edit-user-modal-title'
        aria-describedby='edit-user-modal-description'
      >
        <Box sx={style}>
          <Typography id='edit-user-modal-title' variant='h6' component='h2'>
            Company  Preferences
          </Typography>
          <Divider />
          <Box sx={{ mt: 3, mb: 3, width: '100%' }}>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <Typography variant='h6' component='h2'>
                  {company?.name}
                </Typography>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12}>
                <InputLabel>Goals</InputLabel>
                <FormGroup row>
                  <CheckboxItem name="grow" label="Grow" checked={goals.grow} onChange={handleGoalChange}/>
                  <CheckboxItem name="retain" label="Retain" checked={goals.retain} onChange={handleGoalChange}/>
                  <CheckboxItem name="acquire" label="Acquire" checked={goals.acquire} onChange={handleGoalChange}/>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Target Audience</InputLabel>
                <FormGroup row>
                  <CheckboxItem name="ai" label="Start with AI" checked={audience.ai} onChange={handleAudienceChange}/>
                  <CheckboxItem name="manualcontact" label="Add people manually" checked={audience.manualcontact} onChange={handleAudienceChange}/>
                  <CheckboxItem name="csv" label="Import CSV file" checked={audience.csv} onChange={handleAudienceChange}/>
                  <CheckboxItem name="crm" label="From your CRM" checked={audience.crm} onChange={handleAudienceChange} />
                  <CheckboxItem name="existing" label="Existing Lists" checked={audience.existing} onChange={handleAudienceChange} />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Emails Settings</InputLabel>
              <FormGroup row>
                <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                  <Box component='span' mr={2}>Max Sequence Emails Per Day</Box>
                  <TextField
                    type='number'
                    InputProps={{
                      inputProps: {
                        min: 1,
                        style: {
                          height: '25px'
                        }
                      }
                    }}
                    onChange={handleEmailCount}
                    value={maxEmailCount}
                    sx={{
                      minWidth: 100,
                      '.MuiInputBase-input': {
                        paddingTop: '5px',
                        paddingBottom: '5px'
                      }
                    }}
                  />
                </Box>
              </FormGroup>
            </Grid>
            <Box mt={4}> 
              <Grid item xs={12}>
                <FormGroup row>
                  <TextField
                    id='maxUsers'
                    label='Max Users'
                    value={companyState?.maxUsers}
                    onChange={(e) => {
                      setCompanyState(prevState => ({ ...prevState, maxUsers: e.target.value }));
                    }}
                  />
                  {/* <TextField
                    id='maxContactEnrichments'
                    label='Max Contact Enrichments'
                    value={companyState?.maxContactEnrichments}
                    onChange={(e) => {
                      setCompanyState(prevState => ({ ...prevState, maxContactEnrichments: e.target.value }));
                    }}
                  /> */}
                </FormGroup>
              </Grid>
            </Box>
            <Grid item xs={12}>
              <FormGroup>
                <TextField
                  id='adminInvite'
                  label='Admin Invite'
                  value={adminInvite}
                  onChange={(e) => {
                    let emailVal = e.target.value;
                    setAdminInvite(emailVal);
                    const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailVal);
                    setIsValidEmail(isValid);
                  }}
                  error={!isValidEmail}
                />
              </FormGroup>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ mt: 3, width: '100%' }}>
            <Button
              sx={{ mt: 1, xp: 1, width: '100%' }}
              variant='contained'
              onClick={(e) => {
                const maxUsers = parseInt(companyState.maxUsers, 10);
                // const maxContactEnrichments = parseInt(companyState.maxContactEnrichments, 10);

                // if (isNaN(maxUsers) || maxUsers < 0 || isNaN(maxContactEnrichments) || maxContactEnrichments < 0) {
                //   setAlertText('Max Users and Max Contact Enrichment must be numbers greater than or equal to 0');
                //   setValidateModalOpen(true);
                //   return;
                // }

                if (!isValidEmail) {
                  let alertPrompt = `email is invalid.`;
                  setAlertText(alertPrompt);
                  setValidateModalOpen(true);
                  return;
                }
                
                company.companyId = company?._id
                company.goals = goals
                company.targetAudience = audience
                company.maxUsers = maxUsers || company.maxUsers
                // company.maxContactEnrichments = maxContactEnrichments || company.maxContactEnrichments
                company.adminInvite = adminInvite || company?.adminInvite?.invitee
                company.emailPreference.maxEmailCount = maxEmailCount
                onClose(company)
              }}
            >
              Save
            </Button>
            <Button
              sx={{ mt: 1, width: '100%' }}
              variant='outlined'
              color='error'
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
