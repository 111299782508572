import React, {useState, useEffect} from "react";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

import Http from '../../../http/httpClient'

const UserEmailSettings = () => {

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState('')
    const [maxEmailCount, setMaxEmailCount] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = React.useState(false)
    const [success, setSuccess] = React.useState(false)

    useEffect(() => {
        const fetchAvailableUsers = async () => {
        Http.getData('users/list')
            .then((res) => {
            const resUsers = []
            res.users.map((item) => {
                resUsers.push({ id: item._id, name: item.name, email: item.email})
            })
            setUsers(resUsers)
            })
            .catch((error) => {
            console.log(error)
            })
        }

        fetchAvailableUsers()
    }, [])

    const handleEmailCount = (e) =>{
        setMaxEmailCount(e.target.value)
    }

    const resetEmailCount = () => {
        setSelectedUser('')
        setMaxEmailCount('')
      }

    const handleConfirm = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setSuccess(false)
    }

    const handleSelectedUser = async (e) => {
        setSelectedUser(e.target.value);
        try {
          const response = await Http.getData(`user/${e.target.value}/maxEmailCount`)

          console.log('response', response)
          setMaxEmailCount(response?.maxEmailCount)
        } catch (error) {
          console.error("Error Raised :", error);
        }
      };

    const handleUpdateEmailCount = async () => {
        setIsLoading(true)
        try {
            const response = await Http.postData(`user/${selectedUser}/maxEmailCount`, { maxEmailCount: maxEmailCount })
            if (response.status){
                setSuccess(true)
            }else{
                setSuccess(false)
            }
        } catch (error) {
            console.log('Failed to update email count', error)
        } finally {
            setOpen(false)
            setIsLoading(false)
            resetEmailCount()
        }
    }


    return (
        <>
        <Box display='flex' flexDirection='column' gap={2} alignItems='flex-start'>
          <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
            <Box component='span' mr={2}>Select User:</Box>
            <Select
                value={selectedUser}
                onChange={handleSelectedUser}
                sx={{ minWidth: 200 }}
            >
                {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                    {user.name} - {user.email}
                </MenuItem>
                ))}

            </Select>
          </Box>
            <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                <Box component='span' mr={2}>Max Sequence Emails Per Day</Box>
                <TextField
                    type='number'
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={handleEmailCount}
                    value={maxEmailCount}
                    sx={{ minWidth: 200 }}
                />
            </Box>
            <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
            <Button
                disabled={selectedUser === '' || maxEmailCount === ''}  
                sx={{
                mt: 3,
                px: 3,
                backgroundColor: '#C4C4C4',
                color: '#000000',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                textTransform: 'none',
                fontFamily: 'Poppins'
                }}
                onClick={handleConfirm}
            >
                {isLoading
                ? (
                    <Box
                    sx={{
                        position: 'relative',
                        width: '52px',
                        height: '34px',
                        display: 'flex',
                        borderRadius: 16,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    >
                    <CircularProgress color='inherit' />
                    </Box>
                    )
                : (
                    'Confirm'
                    )}
            </Button>
            <Button
                sx={{
                mt: 3,
                px: 3,
                backgroundColor: '#C4C4C4',
                color: '#000000',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                textTransform: 'none',
                fontFamily: 'Poppins'
                }}
                onClick={resetEmailCount}
            >
                Reset
            </Button>
            </Box>
            <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            >
            <DialogTitle id='alert-dialog-title'>Daily Email Limit</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Are you sure you want to update this user's daily email send limit?
                </DialogContentText>
                </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="inherit" >Cancel</Button>
            <Button onClick={handleUpdateEmailCount} autoFocus color="inherit">
                Yes
            </Button>
            </DialogActions>

            </Dialog>
        </Box>
        <Snackbar open={success} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity='success' sx={{ width: '100%' }}>
            Email send lmit has been updated!
        </Alert>
        </Snackbar>
    </>
    )
}

export default UserEmailSettings