import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { ReactComponent as Check } from '../../assets/Icons/Check.svg';

import { Providers } from '../../constants/constant'
import { SetCookie, GetCookie } from '../../helper/cookieManager'
import Http from '../../http/httpClient'
import { snakeCaseToCamelCase } from '../../helper/utilities'

export default function CRMFilters(props) {
  const theme = useTheme();
  const { onClose, open, listItem, setSelectedItem } = props;
  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isConnectedAsNew, setIsConnectedAsNew] = React.useState(false);
  const [isUpdateProgress, setIsUpdateProgress] = React.useState(false)

  const options = [
    { val: 'allLeads', text: 'All Leads' },
    { val: 'allAccounts', text: 'All current customers' },
    { val: 'allOpportunities', text: 'All open opportunities' }
  ];

  const toggleSelected = (provider, optionVal) => {
    setSelectedOptions(prev => ({
      ...prev,
      [provider]: {
        ...prev[provider],
        [optionVal]: !prev[provider]?.[optionVal]
      }
    }));
  }

  React.useEffect(() => {
    setSelectedItem((prev) => {
      const providers = (prev.providers || []).slice()
      const index = providers.findIndex(provider => provider.providerType === 'crm' && provider.provider === prev.provider)

      if (index !== -1) {
        providers[index] = {
          ...providers[index],
          filters: selectedOptions[snakeCaseToCamelCase(prev.provider)]
        }
      }

      return {
        ...prev,
        providers: providers
      }});
  }, [selectedOptions])

  React.useEffect(() => {
    setIsLoading(true);
    Http.getData('fetchFilters')
      .then(function (response) {
        const filters = response.filters
        setSelectedOptions(filters)
      }).catch(function (error) {
        console.error('Error while retrieving hubspot filters:', error)
      }).finally(() => {
        setIsLoading(false);

        //Identify whether provider configured as new
        const configProvider = listItem?.providers.find(p => p !== null && p.provider === listItem?.provider)
        setIsConnectedAsNew(!configProvider?.enrichmentCompleted)
      });
  }, [])

  const handleClose = async () => {
    onClose();
  };

  function getSelectedProviderConnectedState (provider) {
    if (listItem?.provider !== provider) { return }

    const luser = GetCookie('user')
    let connected = false
    if (luser && luser.providers) {
      const p = luser.providers.find(p => p.provider === provider)

      if (p && p.profileId) {
        connected = true
      }
    }

    return connected
  }

  const [openResultsModal, setOpenResultsModal] = React.useState(false);

  const renderOptions = (provider) => (
    options.map((item, index) => (
      <Container key={index} sx={{ backgroundColor: theme.palette.primary.white, py: 1, my: 1, borderRadius: 3, width: '25vw', height: '4rem' }}>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ width: '100%', height: '100%' }}>
          <Grid container item xs={8} alignItems="center" spacing={2} sx={{ py: 1, height: '100%' }}>
            <Typography sx={{ fontWeight: 500, fontSize: '14px', pl: 1 }}>{item.text}</Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="flex-end" alignItems="center" sx={{ height: '100%' }}>
            <Button
              onClick={() => toggleSelected(provider, item.val)}
              sx={{
                backgroundColor: selectedOptions[provider]?.[item.val] ? theme.palette.primary.white : theme.palette.primary.lightBlue,
                color: theme.palette.primary.blue,
                textTransform: 'none',
                py: 1,
                px: 2,
                borderRadius: 3,
              }}
            >
              {selectedOptions[provider]?.[item.val] ? <Check fill={theme.palette.primary.blue} /> : 'Add'}
            </Button>
          </Grid>
        </Grid>
      </Container>
    ))
  );

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={3} />
        <Box>
          <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 2, lineHeight: 'normal' }}>
            Choose types of customers to focus on
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5 }}>
            This will help to identify your most important customers.
          </Typography>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
              <CircularProgress color='inherit' />
            </div>
          ) : (
            <Grid item container xs={6}>
              {getSelectedProviderConnectedState(Providers.SALESFORCE) && (
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black, pl: 1, pt: 2 }}>
                    Salesforce
                  </Typography>
                  {renderOptions('Salesforce')}
                </Grid>
              )}
              {getSelectedProviderConnectedState(Providers.HUBSPOT) && (
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black, pl: 1, pt: 2 }}>
                    Hubspot
                  </Typography>
                  {renderOptions('Hubspot')}
                </Grid>
              )}
              {(![Providers.SALESFORCE, Providers.HUBSPOT].includes(listItem?.provider) &&
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 400, color: theme.palette.primary.black, pl: 1, pt: 2 }}>
                    {`No filters to configure for ${listItem?.provider} list. Hence, please proceed further.`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
        <Grid item xs={3} />
      </Grid>

      {/*openResultsModal && (
        <CRMResults
          open={openResultsModal}
          onClose={() => setOpenResultsModal(false)}
          onContinue={() => handleContinue(true)}
          isUpdateProgress={isUpdateProgress}
        />
      )*/}
    </>
  );
}