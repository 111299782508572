import * as React from "react";
import Button from "@mui/material/Button";
import PButton from '../../../components/library/PButton';
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useTheme } from "@mui/material";
import { alpha } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";

import { SetCookie, GetCookie } from "../../../helper/cookieManager";
import { useSelector, useDispatch } from "react-redux";
import { setUserTones } from "../../../redux/authSlice";
import { useSnackbar } from "../../../contexts/SnackbarContext";

import Http from "../../../http/httpClient";

export default function Tone(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { addSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false)
  const tones = useSelector((state) => state.auth.userTones);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeTones, setActiveTones] = useState({
    Concise: tones?.Concise || null,
    Controversial: tones?.Controversial || null,
    Funny: tones?.Funny || null,
  });

  const buttonStyle = {
    unselected: {
      border: `1px solid #E0E0E0`,
      boxShadow: 0,
      textTransform: "none",
      fontWeight: 500,
      fontSize: "14px",
      borderRadius: 8,
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.primary.grey,
      px: 2,
      py: 1,
      mx: 2,
      my: 2,
      '&:hover': {
        borderColor: theme.palette.primary.blue,
        color: theme.palette.primary.blue,
        backgroundColor: theme.palette.primary.white,
      }
    },
    selected: {
      boxShadow: 0,
      textTransform: "none",
      fontWeight: 500,
      fontSize: "14px",
      borderRadius: 8,
      backgroundColor: theme.palette.primary.blue,
      color: theme.palette.primary.white,
      px: 2,
      py: 1,
      mx: 2,
      my: 2,
      '&:hover': {
        borderColor: theme.palette.primary.blue,
        color: theme.palette.primary.blue,
        backgroundColor: theme.palette.primary.white,
      }
    },
  };

  useEffect(() => {
    Http.getData("userTones/list")
      .then(function (response) {
        const respTones = response.tones.reduce((acc, tone) => {
          acc[tone.name] = tone.value;
          return acc;
        }, {});

        setActiveTones((prevTones) => ({
          ...prevTones,
          ...respTones,
        }));

        dispatch(setUserTones(respTones));
      })
      .catch(function (error) {
        console.log("Error while retrieving user tones:");
        console.error(error);
      })
  }, [dispatch]);

  const handleChange = (toneName, newValue) => {
    setActiveTones((prevTones) => ({
      ...prevTones,
      [toneName]: newValue,
    }));
  };

  const updateUserTones = async () => {
    setIsLoading(true)

    const userTonePayload = Object.entries(activeTones)
      .filter(([name, value]) => name !== "name" && value !== "name")
      .map(([name, value]) => ({ name, value }));

    try {
      const response = await Http.postData("userTones/update", userTonePayload);
      if (response && response.status === true) {
        const user = GetCookie("user");
        if (user) {
          user.tonesSetupDone = true;
        }
        SetCookie("user", JSON.stringify(user));

        addSnackbar({
          message: 'Tones saved successfully!',
          pVariant: "success",
          hideAction: true,
        })
      } else {
        addSnackbar({
          message: 'Failed to save the tones.',
          pVariant: "destructive",
          hideAction: true,
        })
      }
    } catch (e) {
      console.error(e);
      addSnackbar({
        message: 'Failed to save the tones.',
        pVariant: "destructive",
        hideAction: true,
      })
    } finally {
      setIsLoading(false)
    }
  };

  const renderToneButtons = (toneName, options) => (
    <ButtonGroup
      variant="outlined"
      aria-label={`${toneName.toLowerCase()}-options`}
    >
      {options.map((option, idx) => (
        <Button
          key={option}
          onClick={() => handleChange(toneName, option)}
          sx={{
            ...(activeTones[toneName] === option
              ? buttonStyle.selected
              : buttonStyle.unselected),
            ...(idx === 0
              ? { borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }
              : {}),
            ...(idx === options.length - 1
              ? { borderTopRightRadius: "2px", borderBottomRightRadius: "2px" }
              : {}),
                      mr: 0,
                      ml: 5,
                      '&:hover': {
                          backgroundColor: activeTones[toneName] === option ? alpha(theme.palette.primary.blue, 0.8) : alpha(theme.palette.primary.grey, 0.2)
                      },
          }}
        >
          {option}
        </Button>
      ))}
    </ButtonGroup>
  );

  return (
    <>
      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: 700,
          color: theme.palette.primary.black,
          pt: { xs: 5, md: 2 },
          pl: { xs: 5 },
          mt: { xs: 4 },
          lineHeight: "normal"
        }}
      >
        How do you want to sound?
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: theme.palette.primary.grey,
          pb: 5,
          pl: { xs: 5 },
        }}
      >
        This will help you shape your voice to match how you want customers to
        perceive you.
      </Typography>

      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: theme.palette.primary.black,
          pl: { xs: 5 },
        }}
      >
        Concise
      </Typography>
      {renderToneButtons("Concise", [
        "Not very concise",
        "Somewhat concise",
        "Very concise",
      ])}

      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: theme.palette.primary.black,
          pl: { xs: 5 },
        }}
      >
        Controversial
      </Typography>
      {renderToneButtons("Controversial", ["Rarely", "Sometimes", "Always"])}

      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: theme.palette.primary.black,
          pl: { xs: 5 },
        }}
      >
        Funny
      </Typography>
      {renderToneButtons("Funny", ["Rarely", "Sometimes", "Always"])}

      {isMobile ? (
        <Button
          onClick={updateUserTones}
          sx={{
            boxShadow: 0,
            textTransform: "none",
            fontWeight: 500,
            fontSize: "14px",
            borderRadius: 2,
            backgroundColor: theme.palette.primary.lightBlue,
            color: theme.palette.primary.blue,
            px: 7,
            py: 1,
            mr: 16,
            my: 2,
            "&:hover": {
              backgroundColor: theme.palette.primary.blue,
              color: theme.palette.primary.white,
            },
            width: "90vw",
            marginLeft: "20px",
          }}
        >
          Save
        </Button>
      ) : (
        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "10rem", }}>
          <PButton
            onClick={updateUserTones}
          >
            {" "}
            {isLoading ? (
              <CircularProgress
                size={24}
                color="inherit"
              />
            ) : (
              "Save"
            )}
          </PButton>
        </div>
      )}
    </>
  );
}
