import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReactComponent as Delete } from "../../../assets/Icons/Delete.svg";

export default function PrivacyModal({ open, close }) {
  const theme = useTheme();
  return (
    <Dialog onClose={close} open={open} fullScreen>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <IconButton aria-label="close" onClick={close}>
              <Delete fill={theme.palette.primary.grey} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={3} />
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 700,
                color: theme.palette.primary.black,
                pt: 5,
              }}
            >
              PRIVACY POLICY
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: theme.palette.primary.grey,
                pb: 5,
              }}
            >
              Last updated May 05, 2023
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              This privacy notice for Postilize Inc (doing business as
              Postilize) ("Postilize," "we," "us," or "our"), describes how and
              why we might collect, store, use, and/or share ("process") your
              information when you use our services ("Services"), such as when
              you:
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Visit our website at http://postilize.com, or any website of
                  ours that links to this privacy notice
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Postilize’s use and transfer to any other app of information
                  received from Google APIs will adhere to{" "}
                  <a
                    href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                    rel="noopener"
                  >
                    Google API Services User Data Policy
                  </a>{" "}
                  , including the Limited Use requirements.
                </Typography>
              </li>
            </ul>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Questions or concerns? Reading this privacy notice will help you
              understand your privacy rights and choices. If you do not agree
              with our policies and practices, please do not use our Services.
              If you still have any questions or concerns, please contact us at
              privacy@postilize.com.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              SUMMARY OF KEY POINTS
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              What personal information do we process? When you visit, use, or
              navigate our Services, we may process personal information
              depending on how you interact with Postilize and the Services, the
              choices you make, and the products and features you use. Learn
              more about personal information you disclose to us.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Do we process any sensitive personal information? We do not
              process sensitive personal information.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Do we receive any information from third parties? We may receive
              information from public databases, marketing partners, social
              media platforms, and other outside sources. Learn more about
              information collected from other sources.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              How do we process your information? We process your information to
              provide, improve, and administer our Services, communicate with
              you, for security and fraud prevention, and to comply with law. We
              may also process your information for other purposes with your
              consent. We process your information only when we have a valid
              legal reason to do so. Learn more about how we process your
              information.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In what situations and with which parties do we share personal
              information? We may share information in specific situations and
              with specific third parties. Learn more about when and with whom
              we share your personal information.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              How do we keep your information safe? We have organizational and
              technical processes and procedures in place to protect your
              personal information. However, no electronic transmission over the
              internet or information storage technology can be guaranteed to be
              100% secure, so we cannot promise or guarantee that hackers,
              cybercriminals, or other unauthorized third parties will not be
              able to defeat our security and improperly collect, access, steal,
              or modify your information. Learn more about how we keep your
              information safe.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              What are your rights? Depending on where you are located
              geographically, the applicable privacy law may mean you have
              certain rights regarding your personal information. Learn more
              about your privacy rights.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              How do you exercise your rights? The easiest way to exercise your
              rights is by submitting a data subject access request, or by
              contacting us. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Want to learn more about what Postilize does with any information
              we collect? Review the privacy notice in full.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We provide Postilize "as is" without warranty of any kind. We
              disclaim all warranties express or implied including warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              TABLE OF CONTENTS
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              1. WHAT INFORMATION DO WE COLLECT?.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              7. HOW LONG DO WE KEEP YOUR INFORMATION?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              8. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              9. DO WE COLLECT INFORMATION FROM MINORS?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              10. WHAT ARE YOUR PRIVACY RIGHTS?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              11. CONTROLS FOR DO-NOT-TRACK FEATURES
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              14. DO WE MAKE UPDATES TO THIS NOTICE?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              1. WHAT INFORMATION DO WE COLLECT?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Personal information you disclose to us
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: We collect personal information that you provide to us.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Personal Information Provided by You. The personal information
              that we collect depends on the context of your interactions with
              us and the Services, the choices you make, and the products and
              features you use. The personal information we collect may include
              the following:
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  debit/credit card numbers
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  contact or authentication data
                </Typography>
              </li>
            </ul>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Sensitive Information. We do not process sensitive information.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Social Media Login Data. We may provide you with the option to
              register with us using your existing social media account details,
              like your Facebook, Twitter, or other social media account. If you
              choose to register in this way, we will collect the information
              described in the section called "HOW DO WE HANDLE YOUR SOCIAL
              LOGINS?" below.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Information collected from other sources
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: We may collect limited data from public databases,
              marketing partners, social media platforms, and other outside
              sources.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In order to enhance our ability to provide relevant marketing,
              offers, and services to you and update our records, we may obtain
              information about you from other sources, such as public
              databases, joint marketing partners, affiliate programs, data
              providers, social media platforms, and from other third parties.
              This information includes mailing addresses, job titles, email
              addresses, phone numbers, intent data (or user behavior data),
              Internet Protocol (IP) addresses, social media profiles, social
              media URLs, and custom profiles, for purposes of targeted
              advertising and event promotion. If you interact with us on a
              social media platform using your social media account (e.g.,
              Facebook or Twitter), we receive personal information about you
              such as your name, email address, and gender. Any personal
              information that we collect from your social media account depends
              on your social media account's privacy settings.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: We process your information to provide, improve, and
              administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  To facilitate account creation and authentication and
                  otherwise manage user accounts. We may process your
                  information so you can create and log in to your account, as
                  well as keep your account in working order.
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  To deliver and facilitate delivery of services to the user. We
                  may process your information to provide you with the requested
                  service.
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  To respond to user inquiries/offer support to users. We may
                  process your information to respond to your inquiries and
                  solve any potential issues you might have with the requested
                  service.
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  To send administrative information to you. We may process your
                  information to send you details about our products and
                  services, changes to our terms and policies, and other similar
                  information.
                </Typography>
              </li>
            </ul>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: We only process your personal information when we
              believe it is necessary and we have a valid legal reason (i.e.,
              legal basis) to do so under applicable law, like with your
              consent, to comply with laws, to provide you with services to
              enter into or fulfill our contractual obligations, to protect your
              rights, or to fulfill our legitimate business interests.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you are located in Canada, this section applies to you.
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can
              withdraw your consent at any time.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If collection is clearly in the interests of an individual and
                  consent cannot be obtained in a timely way
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  For investigations and fraud detection and prevention
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  For business transactions provided certain conditions are met
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If it is contained in a witness statement and the collection
                  is necessary to assess, process, or settle an insurance claim
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  For identifying injured, ill, or deceased persons and
                  communicating with next of kin
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If it is contained in a witness statement and the collection
                  is necessary to assess, process, or settle an insurance claim
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  For identifying injured, ill, or deceased persons and
                  communicating with next of kin
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If it is contained in a witness statement and the collection
                  is necessary to assess, process, or settle an insurance claim
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If we have reasonable grounds to believe an individual has
                  been, is, or may be victim of financial abuse
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If it is reasonable to expect collection and use with consent
                  would compromise the availability or the accuracy of the
                  information and the collection is reasonable for purposes
                  related to investigating a breach of an agreement or a
                  contravention of the laws of Canada or a province
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If disclosure is required to comply with a subpoena, warrant,
                  court order, or rules of the court relating to the production
                  of records
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If it was produced by an individual in the course of their
                  employment, business, or profession and the collection is
                  consistent with the purposes for which the information was
                  produced
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If the collection is solely for journalistic, artistic, or
                  literary purposes
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  If the information is publicly available and is specified by
                  the regulations
                </Typography>
              </li>
            </ul>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: We may share information in specific situations
              described in this section and/or with the following third parties.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may need to share your personal information in the following
              situations:
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Business Transfers. We may share or transfer your information
                  in connection with, or during negotiations of, any merger,
                  sale of company assets, financing, or acquisition of all or a
                  portion of our business to another company.
                </Typography>
              </li>
            </ul>
            {/* ////// */}
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: We may use cookies and other tracking technologies to
              collect and store your information.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: If you choose to register or log in to our Services
              using a social media account, we may have access to certain
              information about you.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              7. HOW LONG DO WE KEEP YOUR INFORMATION?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              8. HOW DO WE KEEP YOUR INFORMATION SAFE?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              9. DO WE COLLECT INFORMATION FROM MINORS?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: We do not knowingly collect data from or market to
              children under 18 years of age.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at privacy@postilize.com.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              10. WHAT ARE YOUR PRIVACY RIGHTS?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: In some regions, such as Canada, you have rights that
              allow you greater access to and control over your personal
              information. You may review, change, or terminate your account at
              any time.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In some regions (like Canada), you have certain rights under
              applicable data protection laws. These may include the right (i)
              to request access and obtain a copy of your personal information,
              (ii) to request rectification or erasure; (iii) to restrict the
              processing of your personal information; and (iv) if applicable,
              to data portability. In certain circumstances, you may also have
              the right to object to the processing of your personal
              information. You can make such a request by contacting us by using
              the contact details provided in the section "HOW CAN YOU CONTACT
              US ABOUT THIS NOTICE?" below.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We will consider and act upon any request in accordance with
              applicable data protection laws.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              &nbsp;{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your{" "}
              <a
                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                rel="noopener"
              >
                Member State data protection authority
              </a>{" "}
              or{" "}
              <a
                href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                rel="noopener"
              >
                UK data protection authority
              </a>
              .
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you are located in Switzerland, you may contact the{" "}
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                rel="noopener"
              >
                Federal Data Protection and Information Commissioner
              </a>
              .
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Withdrawing your consent: If we are relying on your consent to
              process your personal information, which may be express and/or
              implied consent depending on the applicable law, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent at any time by contacting us by using the contact details
              provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
              NOTICE?" below.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Account Information{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:{" "}
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Login with Twitter or LinkedIn{" "}
                </Typography>
              </li>
            </ul>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Cookies and similar technologies: Most Web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Services. You may also{" "}
              <a href="http://www.aboutads.info/choices/" rel="noopener">
                {" "}
                opt out of interest-based advertising by advertisers
              </a>{" "}
              on our Services.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you have questions or comments about your privacy rights, you
              may email us at privacy@postilize.com.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              11. CONTROLS FOR DO-NOT-TRACK FEATURES{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: Yes, if you are a resident of California, you are
              granted specific rights regarding access to your personal
              information.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.,
              backups, etc.).{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              CCPA Privacy Notice{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              The California Code of Regulations defines a "resident" as:{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              All other individuals are defined as "non-residents."{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              What categories of personal information do we collect?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We have collected the following categories of personal information
              in the past twelve (12) months:{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              CategoryExamplesCollectedA. IdentifiersContact details, such as
              real name, alias, postal address, telephone or mobile contact
              number, unique personal identifier, online identifier, Internet
              Protocol address, email address, and account nameYESB. Personal
              information categories listed in the California Customer Records
              statuteName, contact information, education, employment,
              employment history, and financial informationNOC. Protected
              classification characteristics under California or federal
              lawGender and date of birthNOD. Commercial informationTransaction
              information, purchase history, financial details, and payment
              informationNOE. Biometric informationFingerprints and
              voiceprintsNOF. Internet or other similar network activityBrowsing
              history, search history, online behavior, interest data, and
              interactions with our and other websites, applications, systems,
              and advertisementsNOG. Geolocation dataDevice locationNOH. Audio,
              electronic, visual, thermal, olfactory, or similar
              informationImages and audio, video or call recordings created in
              connection with our business activitiesNOI. Professional or
              employment-related informationBusiness contact details in order to
              provide you our Services at a business level or job title, work
              history, and professional qualifications if you apply for a job
              with usNOJ. Education InformationStudent records and directory
              informationNOK. Inferences drawn from other personal
              informationInferences drawn from any of the collected personal
              information listed above to create a profile or summary about, for
              example, an individual’s preferences and characteristicsNOL.
              Sensitive Personal InformationNO{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We will use and retain the collected personal information as
              needed to provide the Services or for:{" "}
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Category A - As long as the user has an account with us{" "}
                </Typography>
              </li>
            </ul>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:{" "}
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Receiving help through our customer support channels;{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Participation in customer surveys or contests; and{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries.{" "}
                </Typography>
              </li>
            </ul>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              How do we use and share your personal information?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              More information about our data collection and sharing practices
              can be found in this privacy notice.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              You may contact us by email at privacy@postilize.com, or by
              referring to the contact details at the bottom of this document.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you are using an authorized agent to exercise your right to opt
              out we may deny a request if the authorized agent does not submit
              proof that they have been validly authorized to act on your
              behalf.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Will your information be shared with anyone else?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf, following the same
              strict privacy protection obligations mandated by the CCPA.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal information.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Postilize Inc has not disclosed, sold, or shared any personal
              information to third parties for a business or commercial purpose
              in the preceding twelve (12) months. Postilize Inc will not sell
              or share personal information in the future belonging to website
              visitors, users, and other consumers.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Your rights with respect to your personal data{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Right to request deletion of the data — Request to delete{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Right to be informed — Request to know{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Depending on the circumstances, you have a right to know:{" "}
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  whether we collect and use your personal information;{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  the categories of personal information that we collect;{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  the purposes for which the collected personal information is
                  used;{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  whether we sell or share personal information to third
                  parties;{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  the categories of personal information that we sold, shared,
                  or disclosed for a business purpose;{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  the categories of third parties to whom the personal
                  information was sold, shared, or disclosed for a business
                  purpose;{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  the business or commercial purpose for collecting, selling, or
                  sharing personal information; and{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  the specific pieces of personal information we collected about
                  you.{" "}
                </Typography>
              </li>
            </ul>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We will not discriminate against you if you exercise your privacy
              rights.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Right to Limit Use and Disclosure of Sensitive Personal
              Information{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We do not process consumer's sensitive personal information.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Verification process{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g., phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Other privacy rights{" "}
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  You may object to the processing of your personal information.{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  You may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the information.{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  You can designate an authorized agent to make a request under
                  the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  You may request to opt out from future selling or sharing of
                  your personal information to third parties. Upon receiving an
                  opt-out request, we will act upon the request as soon as
                  feasibly possible, but no later than fifteen (15) days from
                  the date of the request submission.{" "}
                </Typography>
              </li>
            </ul>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              To exercise these rights, you can contact us by email at
              privacy@postilize.com, or by referring to the contact details at
              the bottom of this document. If you have a complaint about how we
              handle your data, we would like to hear from you.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: Yes, if you are a resident of Virginia, you may be
              granted specific rights regarding access to and use of your
              personal information.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Virginia CDPA Privacy Notice{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Under the Virginia Consumer Data Protection Act (CDPA):{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              "Consumer" means a natural person who is a resident of the
              Commonwealth acting only in an individual or household context. It
              does not include a natural person acting in a commercial or
              employment context.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              "Personal data" means any information that is linked or reasonably
              linkable to an identified or identifiable natural person.
              "Personal data" does not include de-identified data or publicly
              available information.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              "Sale of personal data" means the exchange of personal data for
              monetary consideration.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If this definition "consumer" applies to you, we must adhere to
              certain rights and obligations regarding your personal data.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              The information we collect, use, and disclose about you will vary
              depending on how you interact with Postilize Inc and our Services.
              To find out more, please visit the following links:{" "}
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Personal data we collect{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  How we use your personal data{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  When and with whom we share your personal data{" "}
                </Typography>
              </li>
            </ul>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Your rights with respect to your personal data{" "}
            </Typography>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Right to be informed whether or not we are processing your
                  personal data{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Right to access your personal data{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Right to correct inaccuracies in your personal data{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Right to request deletion of your personal data{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Right to obtain a copy of the personal data you previously
                  shared with us{" "}
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 300,
                    color: theme.palette.primary.grey,
                    pb: 2,
                  }}
                >
                  Right to opt out of the processing of your personal data if it
                  is used for targeted advertising, the sale of personal data,
                  or profiling in furtherance of decisions that produce legal or
                  similarly significant effects ("profiling"){" "}
                </Typography>
              </li>
            </ul>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Postilize Inc has not sold any personal data to third parties for
              business or commercial purposes. Postilize Inc will not sell
              personal data in the future belonging to website visitors, users,
              and other consumers.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Exercise your rights provided under the Virginia CDPA{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              More information about our data collection and sharing practices
              can be found in this privacy notice.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              You may contact us by email at privacy@postilize.com, by
              submitting a{" "}
              <a
                href="https://app.termly.io/notify/092cf6ca-2c53-4c30-bb4a-778a86cf1fd1"
                rel="noopener"
              >
                data subject access request
              </a>
              , or by referring to the contact details at the bottom of this
              document.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you are using an authorized agent to exercise your rights, we
              may deny a request if the authorized agent does not submit proof
              that they have been validly authorized to act on your behalf.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Verification process{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may request that you provide additional information reasonably
              necessary to verify you and your consumer's request. If you submit
              the request through an authorized agent, we may need to collect
              additional information to verify your identity before processing
              your request.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Upon receiving your request, we will respond without undue delay,
              but in all cases, within forty-five (45) days of receipt. The
              response period may be extended once by forty-five (45) additional
              days when reasonably necessary. We will inform you of any such
              extension within the initial 45-day response period, together with
              the reason for the extension.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Right to appeal{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If we decline to take action regarding your request, we will
              inform you of our decision and reasoning behind it. If you wish to
              appeal our decision, please email us at privacy@postilize.com.
              Within sixty (60) days of receipt of an appeal, we will inform you
              in writing of any action taken or not taken in response to the
              appeal, including a written explanation of the reasons for the
              decisions. If your appeal if denied, you may contact the {" "}
              <a
                href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                rel="noopener"
              >
                Attorney General to submit a complaint
              </a>
              .
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              14. DO WE MAKE UPDATES TO THIS NOTICE?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              In Short: Yes, we will update this notice as necessary to stay
              compliant with relevant laws.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              If you have questions or comments about this notice, you may
              contact our Data Protection Officer (DPO) , Corey Ganong, by email
              at privacy@postilize.com, or by post
              to:{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Postilize Inc{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Corey Ganong{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              250 s hibiscus dr{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Miami Beach, FL 33139{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              United States{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information, please fill
              out and submit a{" "}
              <a
                href="https://app.termly.io/notify/092cf6ca-2c53-4c30-bb4a-778a86cf1fd1"
                rel="noopener"
              >
                data subject access request
              </a>
              .
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 300,
                color: theme.palette.primary.grey,
                pb: 2,
              }}
            >
              This privacy policy was created using Termly's Privacy Policy
              Generator .
            </Typography>
            {/* ///////// */}
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
