import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';


import { ReactComponent as ArrowLeft } from '../assets/Icons/Arrow-Left.svg';
import PButton from 'components/library/PButton';
import { Box } from '@mui/system';

export default function FullScreenModal(props) {
  const theme = useTheme()
  const { onClose, open, disableContinue, handleContinue, continueText, content, alertMessage, isLoading} = props;
  const [displayAlert, setDisplayAlert] = React.useState(false);

  React.useEffect(() => {
    if (alertMessage) {
      setDisplayAlert(true);
      const timeoutId = setTimeout(() => {
        setDisplayAlert(false);
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [alertMessage]);

  return (
    <Dialog onClose={onClose} open={open} fullScreen
      sx={{ 
        px: {
          xs: 0,
          md: 0,
        },
        m: 0,
        maxWidth: '100vw',
      }}>
      <DialogTitle sx={{ m: 0, p: 0 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item sx={{ pt: 2, pl: 2 }}>
            <IconButton aria-label="close" onClick={onClose}>
              <ArrowLeft fill={theme.palette.primary.black} />
            </IconButton>
          </Grid>
          <Grid item>
          {displayAlert && <Alert severity="error">{alertMessage}</Alert>}
          </Grid>
          <Grid item>
           <Box
              sx={{
                py: 1,
                px: 2,
                mr: 2,
                mt: 2,
              }}
            >
              <PButton
                pVariant="primary"
                onClick={handleContinue}
                disabled={isLoading || disableContinue}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : continueText ? (
                  continueText
                ) : (
                  "Continue"
                )}
              </PButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={3} />
          <Grid item xs={6}>

            {content}

          </Grid>
          <Grid item xs={3} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}