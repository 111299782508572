import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { useTheme, CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useMemo, useCallback } from 'react';

import { ReactComponent as Back } from '../../../../assets/Icons/Arrow-Left.svg';
import SequenceTemplate from '../../../../components/sequence/sequenceTemplate';
import Http from '../../../../http/httpClient';
import { StepSaleValuesInverse } from '@/constants/constant';
import { Milestones } from '@/constants/constant';
import { useSelector, useDispatch } from 'react-redux';
import { setActionFlag } from '@/redux/authSlice';

export default function EditModal(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { onClose, open, activeItem, template, onRefresh } = props;

  const [sequenceItem, setSequenceItem] = React.useState(activeItem);
  const [isLoading, setIsLoading] = React.useState(false);
  const [requiresEmailGen, setRequiresEmailGen] = React.useState(null);
  const [goalType, setGoalType] = React.useState(null);
  const childComponentRef = React.useRef();

  const { delegationAccessId } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (!activeItem) return;
    setSequenceItem(activeItem);
    setGoalType(activeItem?.template?.type);
  }, [activeItem]);

  // React.useEffect(() => {
  //   const activeItem2 = {
  //     ...activeItem,
  //     template: {
  //       ...activeItem.template,
  //       steps: activeItem?.template?.steps.map((step) => {
  //         if (step?.milestone === Milestones.SEC144) {
  //           return { ...step, desc: 'Spot large stock sales' };
  //         }
  //         if (step?.milestone === Milestones.FUNDING) {
  //           return { ...step, desc: 'New funding email' };
  //         }
  //         if (step?.milestone === Milestones.ACQUISITION) {
  //           return { ...step, desc: 'New acquisition email' };
  //         }
  //         return step;
  //       }),
  //     },
  //   };
  // }, [activeItem]);

  const sequenceData = useMemo(
    () => ({
      ...activeItem,
      template: {
        ...activeItem?.template,
        steps: activeItem?.template?.steps.map((step) => ({
          ...step,
          fromEdit: true,
          stepSaleValues: StepSaleValuesInverse[step.saleValueGreaterThan] || step.saleValueGreaterThan,
          saleValueGreaterThan: step.saleValueGreaterThan,
          fundzValues: StepSaleValuesInverse[step.fundzValueGreaterThan] || step.fundzValueGreaterThan,
          fundzValueGreaterThan: step.fundzValueGreaterThan,
          fundzValueLessThan: step.fundzValueLessThan,
          fundzValueOption:
            step.fundzValueOption ??
            (step.fundzValueGreaterThan !== null && step.fundzValueLessThan !== null
              ? 'between'
              : step.fundzValueGreaterThan !== null
                ? 'greater'
                : 'less'),
        })),
      },
    }),
    [activeItem],
  );

  const setSequenceTemplate = useCallback(
    (templates) => {
      setSequenceItem((prev) => ({
        ...prev,
        template: templates.template,
      }));
    },
    [setSequenceItem],
  );

  const onSave = async () => {
    setIsLoading(true);

    // ======================================================================================================================
    // Fix for previous payload mismatch where fundzGeographical field in data is expected to be an array of strings, but it's being provided as an array of objects.
    // ======================================================================================================================
    // Create a deep copy to avoid mutating the original sequenceItem
    const cleanedSequenceItem = JSON.parse(JSON.stringify(sequenceItem));

    // Map over the steps and transform fundzGeographical
    if (cleanedSequenceItem.template && cleanedSequenceItem.template.steps) {
      cleanedSequenceItem.template.steps = cleanedSequenceItem.template.steps.map((step) => {
        if (step.fundzGeographical) {
          step.fundzGeographical = step.fundzGeographical.map((item) => item.label || item);
        }
        // Ensure emailTemplate.reviewed is set to true
        if (step.emailTemplate) {
          step.emailTemplate.reviewed = true;
        }
        return step;
      });
    }
    // ======================================================================================================================

    const endpoint = delegationAccessId ? `updateSequenceSettings/${delegationAccessId}` : 'updateSequenceSettings';
    Http.postData(endpoint, { type: 'Edit sequence', sequenceData: cleanedSequenceItem })
      .then((res) => {
        if (res.status) {
          if (delegationAccessId) {
            dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: true }));
          }
          onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        onRefresh(sequenceItem._id);
        setIsLoading(false);
      });
  };

  const handleGenerateEmails = () => {
    setIsLoading(true);
    childComponentRef.current
      .generateSequenceTemplateEmail()
      .then((result) => {
        if (result === 'Success') {
          setRequiresEmailGen((prev) => false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error generating emails:', error);
      });
  };

  return (
    <Dialog onClose={onClose} open={open} fullScreen>
      <DialogTitle sx={{ p: 0 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            px: 5,
            pt: 3,
          }}
        >
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <Back fill={theme.palette.primary.black} />
            </IconButton>
          </Grid>
          <Grid item>
            {!requiresEmailGen && (
              <Button
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
                onClick={() => {
                  onSave();
                }}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
              </Button>
            )}
            {requiresEmailGen && (
              <Button
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
                onClick={() => {
                  handleGenerateEmails();
                }}
                disabled={isLoading}
              >
                {isLoading ? 'Drafting email...' : 'Draft Email'}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            <LinearProgress
              variant="determinate"
              value={80}
              sx={{
                my: 2,
                height: 6,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: theme.palette.primary.lightBlue,
                },
                [`& .${linearProgressClasses.bar}`]: {
                  backgroundColor: '#87E554',
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <SequenceTemplate
          selectedGoalType={goalType}
          sequenceData={sequenceData}
          ref={childComponentRef}
          setSequenceTemplate={setSequenceTemplate}
          setIsEmailLoading={setIsLoading}
          setEmailDrafted={setRequiresEmailGen}
        />
      </DialogContent>
    </Dialog>
  );
}
