import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Stack } from '@mui/material';
import PTypography from '@Library/PTypography';
import { useTheme } from '@mui/material/styles';
import { formatLargeNumber } from '@/helper/utilities';
import { isLexicalHTML } from '@/utils/richTextUtils';
import { useSelector } from 'react-redux';
import featureFlagService from '@/services/featureFlagService';

const avatarStyle = {
  height: '32px',
  width: '32px',
};
const boxStyle = {
  mt: '12px',
  maxWidth: '100%',
};
const messageStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const StockSalesCard = ({ post, onContactClick }) => {
  const { milestoneDetails } = post.sequenceAttr;
  const theme = useTheme();
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const stackStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.primaryCL.Black50,
      borderRadius: 'var(--8, 8px)',
      width: '100%',
      ml: '1px',
      mb: '20px',
      p: '12px',
      overflow: 'hidden',
    }),
    [theme],
  );

  const getEmailLabel = () => {
    const { email } = post?.sequenceAttr?.contactDetails || {};
    if (email) {
      return ` - ${email}`;
    }
    return ' - LinkedIn Message';
  };

  const handleContactClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      if (onContactClick) {
        onContactClick();
        event.stopPropagation();
      }
    },
    [onContactClick],
  );

  const messageDisplay = useMemo(() => {
    const isPRTEditorEnabled = featureFlagService.isFeatureEnabled('ui.richTextEditor', featureFlags);

    if (isPRTEditorEnabled && isLexicalHTML(post?.message)) {
      return <div dangerouslySetInnerHTML={{ __html: post?.message }} />;
    }

    return (
      <PTypography size="body2" weight="regular" preformatted sx={messageStyle}>
        {post?.message}
      </PTypography>
    );
  }, [featureFlags, post?.message]);

  return (
    <Box>
      <Stack direction="column" spacing="24px">
        <Stack direction="column" justifyContent="space-between" sx={stackStyle}>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Box onClick={handleContactClick}>
              <Avatar sx={avatarStyle} src={post.ImageUrl} />
            </Box>
            <Stack direction="column" spacing="2px" alignItems="flex-start">
              {post?.name && (
                <PTypography size="body2" weight="bold">
                  {post?.name}
                  {getEmailLabel()}
                </PTypography>
              )}
              {post?.role && (
                <PTypography size="footnote" weight="regular">
                  {post.role}
                </PTypography>
              )}
              {post?.company && (
                <PTypography size="footnote" weight="regular">
                  {post.company}
                </PTypography>
              )}
            </Stack>
          </Stack>

          <Box sx={boxStyle}>{messageDisplay}</Box>
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ mt: 3 }} spacing={1} justifyContent="space-between" alignItems="flex-start">
        <Stack direction="column" spacing="2px" alignItems="flex-start">
          <PTypography size="body2" weight="bold">
            Funding Round
          </PTypography>
        </Stack>
      </Stack>
      <Box sx={{ mt: 0.5 }}>
        <PTypography size="footnote" weight="regular">
          {milestoneDetails?.company && `${milestoneDetails?.company} ᛫ `}
          {milestoneDetails?.industry && `${milestoneDetails?.industry} ᛫ `}
          {milestoneDetails?.roundType && `${milestoneDetails?.roundType} ᛫ `}
          {milestoneDetails?.moneyRaisedUSD && `$${formatLargeNumber(milestoneDetails?.moneyRaisedUSD)} raised`}
        </PTypography>
      </Box>
    </Box>
  );
};

StockSalesCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default StockSalesCard;
