import React from 'react';
import { useTheme, IconButton, Grid, Button, LinearProgress, Dialog, Box } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { ReactComponent as Back } from '@Assets/Icons/Arrow-Left.svg';

import AIListPrompt from '@/components/list/AIListPrompt';
import AIListPayload from '@/components/list/AIListPayload';
import AIResults from '@/components/list/AIResults';
import { alpha } from '@mui/material/styles';

const AISearchModal = (props) => {
  const theme = useTheme();
  const {
    openAIModal,
    openAIPayloadModal,
    openAIResultModal,
    isLoading,
    setModalToOpen,
    setOpenAIModal,
    setOpenAIPayloadModal,
    setOpenAIResultModal,
    handleAI,
    handleAIResult,
    handleListCreation,
    aiListPromptProps,
    aiListPayloadProps,
    aiResultsProps,
  } = props;

  const buttonStyle = {
    'maxWidth': '200px',
    'backgroundColor': theme.palette.primary.blue,
    'color': theme.palette.primary.white,
    'textTransform': 'none',
    'width': '167px',
    'py': 1,
    'px': 2,
    'mr': 2,
    'mt': 2,
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    'borderRadius': 2,
    'boxShadow': '0px 4px 8px 0px #2C64E31F',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.blue, 0.8),
    },
  };

  const LinearProgressStyle = {
    my: 2,
    height: 6,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.primary.lightBlue,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: '#87E554',
    },
  };

  const renderModal = (modalType) => {
    switch (modalType) {
      case 'AI':
        return <AIListPrompt {...aiListPromptProps} />;
      case 'AI_PAYLOAD':
        return <AIListPayload {...aiListPayloadProps} />;
      case 'AI_RESULT':
        return <AIResults {...aiResultsProps} />;
      default:
        return null;
    }
  };

  const renderButtonHandler = (modalType) => {
    switch (modalType) {
      case 'AI':
        return handleAI;
      case 'AI_PAYLOAD':
        return handleAIResult;
      case 'AI_RESULT':
        return handleListCreation;
      default:
        return () => {};
    }
  };

  const closeButtonHandler = (modalType) => {
    switch (modalType) {
      case 'AI':
        setModalToOpen(null);
        break;
      case 'AI_PAYLOAD':
        setOpenAIModal(true);
        setOpenAIPayloadModal(false);
        setModalToOpen('AI');
        break;
      case 'AI_RESULT':
        setOpenAIPayloadModal(true);
        setOpenAIResultModal(false);
        setModalToOpen('AI_PAYLOAD');
        break;
      default:
        setModalToOpen(null);
    }
  };

  const openModal = {
    AI: openAIModal,
    AI_PAYLOAD: openAIPayloadModal,
    AI_RESULT: openAIResultModal,
  };

  return (
    <Dialog onClose={() => closeButtonHandler(props.modalType)} open={openModal[props.modalType]} fullScreen>
      <Box>
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Grid item>
            <IconButton aria-label="close" onClick={() => closeButtonHandler(props.modalType)} sx={{ pt: 2, pl: 2 }}>
              <Back fill={theme.palette.primary.black} />
            </IconButton>
          </Grid>
          <Grid item>
            <Button onClick={renderButtonHandler(props.modalType)} sx={buttonStyle}>
              Continue
            </Button>
          </Grid>
        </Grid>
        {isLoading && <LinearProgress variant="indeterminate" value={75} sx={LinearProgressStyle} />}
        {renderModal(props.modalType)}
      </Box>
    </Dialog>
  );
};

export default AISearchModal;
