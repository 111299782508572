import React, { useState } from 'react';
import { Button, TextField, Box, Grid } from '@mui/material';
import { faker } from '@faker-js/faker';
// import Http from '../http/httpClient';
import Http from '../../../http/httpClient';

function FundingForm() {
  const [funding, setFunding] = useState({
    company: faker.company.name(),
    moneyRaised: faker.finance.amount(),
    currency: 'USD',
    moneyRaisedUSD: faker.finance.amount(),
    roundType: 'Seed Round',
    companyDescription: faker.company.catchPhrase(),
    domain: faker.internet.domainName(),
    industry: faker.commerce.department(),
    relatedPersons: faker.person.fullName(),
    linkedIn: faker.internet.url(),
    location: faker.location.city(),
    companyUUID: faker.string.uuid(),
    date: new Date(),
  });

  const handleChange = (event) => {
    setFunding({
      ...funding,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Call API to save funding
    try {
      const response = await Http.postData('fundings/admin/create', {
        funding,
      });
      if (response && response.status) {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
    console.log(funding);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="company"
            label="Company Name"
            value={funding.company}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="moneyRaised"
            label="Money Raised"
            value={funding.moneyRaised}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="currency"
            label="Currency"
            value={funding.currency}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="moneyRaisedUSD"
            label="Money Raised USD"
            value={funding.moneyRaisedUSD}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="roundType"
            label="Round Type"
            value={funding.roundType}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="companyDescription"
            label="Company Description"
            value={funding.companyDescription}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="domain"
            label="Domain"
            value={funding.domain}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="industry"
            label="Industry"
            value={funding.industry}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="relatedPersons"
            label="Related Persons"
            value={funding.relatedPersons}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="linkedIn"
            label="LinkedIn"
            value={funding.linkedIn}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="location"
            label="Location"
            value={funding.location}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="companyUUID"
            label="Company UUID"
            value={funding.companyUUID}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Button type="submit" variant="contained" color="primary">
          Create Funding
        </Button>
      </Box>
    </Box>
  );
}

export default FundingForm;
