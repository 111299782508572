import * as React from 'react'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Button from '@mui/material/Button'
import PButton from '../../../components/library/PButton';
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { useTheme,useMediaQuery } from '@mui/material'

import Http from '../../../http/httpClient'
import PTextfield from '@/components/library/PTextfield'

export default function Support(props) {
  const theme = useTheme()
  const [emailError, setEmailError] = React.useState(false)
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    message: ''
  })
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [isSending, setIsSending] = React.useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
   
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const sendEmail = () => {
    if (!emailRegex.test(formData.email)) {
      setEmailError(true);
      console.error('Invalid email address');
      return;
    }
    setIsSending(true)
    const emailData = {
      name: formData.name,
      email: formData.email,
      message: formData.message
    }

    Http.postData('support/send-support-email', emailData)
      .then(response => {
        if (response.status) {
          console.log('Email sent:', response)
          setFormData({
            name: '',
            email: '',
            message: ''
          })
          setDialogOpen(true)
        } else {
          console.error('Email not sent. Response not ok:', response)
        }
      })
      .catch(error => {
        console.error('Error sending email:', error)
      })
      .finally(() => {
        setIsSending(false)
      })
  }


    return (
        <>
            <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 5,pl: { xs: 5 },
          mt: { xs: 4 }, lineHeight: 'normal'}}>
                Contact Postilize
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5,pl: { xs: 5 } }}>
                If you have any questions about Postilize, please be as specific as you can and make sure to include the correct email address. We're looking forward to hearing from you.
            </Typography>
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                    pl: { xs: 5 },
                }}
            >
                Your name{' '}
                <span style={{ color: theme.palette.primary.orange, marginLeft: '4px' }}>*</span>
            </Typography>
            <PTextfield
              type='text'
              name='name'
              value={formData.name}
              onChange={handleInputChange}
              paperStyle={{
                ml: '40px',
                mb: '24px',
                width: '80%',
              }}
            />

            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                color: theme.palette.primary.black,
                pl: { xs: 5 },
              }}
            >
                Your email{' '}
                <span style={{ color: theme.palette.primary.orange, marginLeft: '4px' }}>*</span>
            </Typography>
            <PTextfield
              type='text'
              name='email'
              value={formData.email}
              onChange={handleInputChange}
              paperStyle={{
                ml: '40px',
                mb: '24px',
                width: '80%',
              }}
            />
            {emailError && (
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: theme.palette.primary.orange,
                  pl: { xs: 5},
                  ml: 1
                }}
              >
                Please enter a valid email address.
              </Typography>
            )}
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.palette.primary.black,
                    pl: { xs: 5 },
                    mt: 3
                }}
            >
                Your message{' '}
                <span style={{ color: theme.palette.primary.orange, marginLeft: '4px' }}>*</span>
            </Typography>
            <PTextfield
              type='text'
              name='message'
              value={formData.message}
              onChange={handleInputChange}
              multiline
              rows={5}
              paperStyle={{
                ml: '40px',
                mb: '24px',
                width: '80%',
              }}
            />
            {isMobile ? (
        <>
          <Button
            onClick={sendEmail}
            disabled={isSending}
            sx={{
              boxShadow: 0,
              textTransform: "none",
              fontWeight: 500,
              fontSize: "14px",
              borderRadius: 2,
              backgroundColor: theme.palette.primary.lightBlue,
              color: theme.palette.primary.blue,
              px: 7,
              py: 1,
              mr: 16,
              my: 2,
              "&:hover": {
                backgroundColor: theme.palette.primary.blue,
                color: theme.palette.primary.white,
              },
              "&:disabled": {
                backgroundColor: theme.palette.action.disabledBackground,
              },
              width: "90vw",
              marginLeft: "20px",
            }}
          >
            {isSending ? "Sending..." : "Send"}
          </Button>

          <Dialog open={dialogOpen} onClose={handleClose}>
            <DialogTitle>{"Email Sent"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Your message has been successfully sent. We will get back to you
                as soon as possible.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "10rem", }}>
          <PButton
            onClick={sendEmail}
            disabled={isSending}
          >
            {" "}
            {isSending ? (
              <CircularProgress
                size={24}
                color="inherit"
              />
            ) : (
              <>
                {isSending ? "Sending..." : "Send"}
              </>
            )}
          </PButton>

          <Dialog open={dialogOpen} onClose={handleClose}>
            <DialogTitle>{"Email Sent"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Your message has been successfully sent. We will get back to you
                as soon as possible.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  )
}