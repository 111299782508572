import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PostItem from '../../postQueue/postItem'
import { Select, MenuItem, Box, Typography, CircularProgress } from '@mui/material'
import { Severity } from '../../../constants/constant'
import { GetCookie } from '../../../helper/cookieManager'
import CustomSnackBar from './../../../components/CustomSnackBar'

import Http from '../../../http/httpClient'

const UserPostQueueReview = () => {
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [posts, setPosts] = useState([])
  const [open, setOpen] = useState(false)
  const [snackmessage, setSnackmessage] = useState('')
  const [severity, setSeverity] = useState('')
  const [isLoading, setIsLoading] = useState([])
  const [inProgress, setInProgress] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation)

  let postQueueIndex = 0
  let tweetIndex = 0

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      Http.getData('users/list?vip=true')
        .then((res) => {
          const resUsers = []
          res.users.map((item) => {
            resUsers.push({ id: item.id, name: item.name })
          })
          setUsers(resUsers)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    fetchAvailableUsers()
  }, [])

  useEffect(() => {
    if (selectedUser) {
      setInProgress(true)

      const fetchUserPosts = async () => {
        Http.getData(`userPosts?postUserId=${selectedUser}`)
          .then((res) => {
            setPosts(res.contactPosts)
          })
          .catch((error) => {
            console.log(error)
          }).finally(() => setInProgress(false))
      }

      fetchUserPosts()
    } else {
      setPosts([])
    }
  }, [selectedUser])

  const handleOptionChange = async (e) => {
    setSelectedUser(e.target.value)
  }

  const handleDelete = (postUserId, userPost) => {
    setIsDeleting(true)
    const endpoint = authSliceSelectedDelegation?.userId
      ? `deleteUserPost/${authSliceSelectedDelegation.userId}`
      : 'deleteUserPost';
    Http.postData(endpoint, { post: userPost, postUserId: postUserId })
      .then((res) => {
        if (res.success === true) {
          setPosts(posts.filter(post => post._id !== userPost._id))
          setStatus(Severity.SUCCESS, 'Post deleted successfully!')
        } else {
          setStatus(Severity.ERROR, 'Failed to delete the post!')
        }
      })
      .catch((error) => {
        console.error(error)
        setStatus(Severity.ERROR, 'Failed to delete the post!')
      })
      .finally(()=>{
        setIsDeleting(false)
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const setStatus = (severity, message) => {
    setOpen(true)
    setSnackmessage(message)
    setSeverity(severity)
  }

  const approvePost = (userId, postId) => {
    Http.postData(`/user/${userId}/approve/${postId}`)
      .then((res) => {
        if (res.success === true) {
          setPosts(posts.filter(post => post._id !== postId))
          setStatus(Severity.SUCCESS, 'Post approved successfully!')
        } else {
          setStatus(Severity.ERROR, 'Failed to approve the post!')
        }
      })
      .catch((error) => {
        console.error(error)
        setStatus(Severity.ERROR, 'Failed to approve the post!')
      })
      .finally(() => {
      })
  }

  return (
    <Box>
      <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
        <Box component='span' mr={2} sx={{ marginLeft: 3 }}>Select User:</Box>
        <Select
          value={selectedUser}
          onChange={handleOptionChange}
          sx={{ minWidth: 200 }}
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name} - {user.email}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box sx={{ height: '58vh', overflowY: 'auto', px: 1.5, mt: 2 }}>
        {inProgress &&
          <Box
            sx={{
              p: 3,
              position: 'relative',
              width: '84px',
              height: '34px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color='inherit' />
          </Box>
        }
        {selectedUser && posts.length === 0 && (
          <Typography align='center' gutterBottom>
            There are no posts available to approve.
          </Typography>
        )}
        {selectedUser && posts.map((post) => {
          const controls = post.responseTo ? false : true
          const index = post.responseTo ? tweetIndex++ : postQueueIndex++
          const isAdminPage = true

          return (
            <PostItem
              isAdmin={isAdminPage}
              postUserId={selectedUser}
              key={post._id}
              user={post}
              ori_post={post}
              index={index}
              handleDelete={() => handleDelete(selectedUser, post)}
              handleApprove={() => approvePost(selectedUser, post._id)}
              isLoading={isLoading.includes(post._id)}
              isDeleting={isDeleting}
              controls={post.responseTo ? false : true}
            />
          )
        })}
      </Box>
      <CustomSnackBar
        open={open}
        onClose={handleClose}
        message={snackmessage}
        severity={severity}
      />
    </Box>
  )
}

export default UserPostQueueReview
