import * as React from 'react'
import Link from '@mui/material/Link'
import Tour from 'reactour'

import Http from '../http/httpClient'
import { SetCookie, GetCookie } from '../helper/cookieManager'

const backButtonStyle = {
  color: '#fff',
  backgroundColor: '#3983F7',
  borderRadius: '4px',
  fontSize: '14px',
  padding: '8px 16px',
  marginRight: '8px',
  border: 'none'
}

const nextButtonStyle = {
  ...backButtonStyle,
  marginLeft: '8px'
}

const ReactTourComponent = ({ steps, isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false)
  }

  const handleDoNotShow = () => {
    Http.postData('disableTourGuide', { disableTourGuide: true })
      .then(function (response) {
        if (response && response.updated) {
          const user = GetCookie('user')
          if (user) { user.disableTourGuide = true }
          SetCookie('user', JSON.stringify(user))
          setIsOpen(false)
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsOpen(false)
      })
  }

  const handleTourOpen = (step) => {
    const isLinkAdded = steps[0].hasOwnProperty('isLinkAdded') ? steps[0].isLinkAdded : false
    if (steps[0].content && !isLinkAdded) {
      steps[0].content = (
        <>
          {steps[0].content}
          <br/><br/>
          <Link onClick={handleDoNotShow} sx={{cursor: 'pointer'}}>
            Don't show these hints
          </Link>
        </>
      )
      steps[0].isLinkAdded = true
    }
  }

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      onRequestClose={handleClose}
      closeWithMask={false}
      onAfterOpen={handleTourOpen}
      lastStepNextButton={
        <button style={nextButtonStyle}>Finish</button>
      }
      nextButton={
        <button style={nextButtonStyle}>Next</button>
      }
      prevButton={
        <button style={backButtonStyle}>Back</button>
      }
      styles={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)'
        },
        content: {
          backgroundColor: '#fff',
          color: '#333',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)'
        },
        beacon: {
          backgroundColor: '#3983F7'
        },
        button: {
          color: '#fff',
          backgroundColor: '#3983F7',
          borderRadius: '4px',
          fontSize: '14px',
          padding: '8px 16px'
        },
        close: {
          fill: '#333',
          fontSize: '24px',
          marginRight: '-10px',
          marginTop: '-10px'
        }
      }}
    />
  )
}

export default ReactTourComponent
