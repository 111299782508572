import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import PTopicSelector from '@Library/PTopicSelector';
import PTypography from '@Library/PTypography';

const PRadioSelector = (props) => {
  const { label, options, value, onChange } = props;
  const handleToggle = useCallback(
    (selectedValue) => {
      const newValue = value === selectedValue ? '' : selectedValue;
      onChange(newValue);
    },
    [value, onChange],
  );

  return (
    <>
      <PTypography size="body2" weight="bold" sx={{ mb: 1 }}>
        {label}
      </PTypography>
      <Box display="flex" flexDirection="row" gap={1}>
        {options.map((option) => {
          const topic = { name: option.label, isActive: value === option.value }
          return (
            <PTopicSelector
              key={option.value}
              topic={topic}
              onToggle={() => handleToggle(option.value)}
              pVariant={value === option.value ? 'darkGrey' : 'grey'}
              useCheckIcon
            />
          )
        })}
      </Box>
    </>
  );
};

PRadioSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default PRadioSelector;
