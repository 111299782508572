import React, { useCallback, useState } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import PModal from '@Library/PModal';
import PBackButton from '@Library/PBackButton';
import PTypography from '@Library/PTypography';
import PDropdown from '@Library/PDropdown.Charles';
import PTextfield from '@Library/PTextfield';
import PButton from '@/components/library/PButton';

const dropdownOptions = [
  { value: 'Greater than 5%', label: 'Greater than 5%' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' }
];
const frequencyOptions = [
  { value: 'Sends 5 times or until reply', label: 'Sends 5 times or until reply' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' }
];
const providerOptions = [
  { value: 'Default to LinkedIn', label: 'Default to LinkedIn' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' }
];
function ResponsibilityStockSalesModal({ onClose, open, step, setupList }) {

  const [selectedShares, setSelectedShares] = useState('');
  const [selectedFrequency, setSelectedFrequency] = useState(frequencyOptions[0].value);
  const [selectedProvider, setSelectedProvider] = useState(providerOptions[0].value);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSharesChange = value => setSelectedShares(value);
  const handleFrequencyChange = value => setSelectedFrequency(value);
  const handleProviderChange = value => setSelectedProvider(value);
  const handleSubjectChange = event => setSubject(event.target.value);
  const handleMessageChange = event => setMessage(event.target.value);

  const handleSaveClick = useCallback(() => {
    const payload = {
      shares: selectedShares,
      subject,
      message,
      frequency: selectedFrequency,
      provider: selectedProvider,
    };
    onClose(); // Assuming you want to close the modal upon save
  }, [selectedShares, subject, message, selectedFrequency, selectedProvider, onClose]);

  return (
    <PModal
      floating
      floatingDimensions={{ height: 'fit-content' }}
      ultraWideContent
      onClose={onClose}
      open={open}
    >
      <Stack direction="column" spacing="24px">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction={'column'} spacing={'8px'}>
            <PTypography size="h2" weight="bold">
              Examples—Spot Large Stock Sales
            </PTypography>
            <PTypography size="footnote" weight="regular">
              Act on insider sales information and send timely messages to the right people.
            </PTypography>
          </Stack>
          <PBackButton onClick={onClose} />
        </Stack>

        <PDropdown
          label="Shares"
          options={dropdownOptions}
          onChange={handleSharesChange}
          pVariant="primary"
          defaultValue={selectedShares}
        />

        <PTextfield
          label="Subject line"
          placeholder="Enter your subject line"
          value={subject}
          onChange={handleSubjectChange}
        />

        <PTextfield
          label="Your message"
          multiline
          rows={4}
          placeholder="Enter your message"
          value={message}
          onChange={handleMessageChange}
        />

        <Box maxWidth="100%" display="flex" justifyContent="space-between">
        <Stack direction="row" spacing="12px">
          <PDropdown
            options={frequencyOptions}
            onChange={handleFrequencyChange}
            pVariant="primary"
            defaultValue={selectedFrequency}
            paperStyle={{ width: '198px' }}
          />
          <PDropdown
            options={providerOptions}
            onChange={handleProviderChange}
            pVariant="primary"
            defaultValue={selectedProvider}
            paperStyle={{ width: '198px' }}
          />
        </Stack>

        <Stack direction="row" display="flex" spacing="8px">
            <PButton pVariant='outlined' onClick={handleSaveClick}>
                Variable
            </PButton>
            <PButton pVariant='outlined' onClick={handleSaveClick}>
                Rephrase
            </PButton>
            <PButton onClick={handleSaveClick}>
                Save
            </PButton>
        </Stack>
        </Box>
      </Stack>
    </PModal>
  );
}

export default ResponsibilityStockSalesModal;
