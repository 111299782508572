import * as React from 'react'
import { Providers } from '../../constants/constant'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import { alpha, styled } from '@mui/material/styles'

import { ReactComponent as Back } from '../../assets/Icons/Arrow-Left.svg'
import { ReactComponent as Delete } from '../../assets/Icons/Delete.svg'
import { ReactComponent as Search } from '../../assets/Icons/Search.svg'

import PButton from '../library/PButton'

export default function ImportCSV(props) {
  const theme = useTheme()
  const { onClose, open, listItem, setSelectedItem } = props
  const [fileInputVisible, setFileInputVisible] = React.useState(false)

  const [csvProvider, setCsvProvider] = React.useState(listItem?.providers?.find(provider => provider.providerType === 'csv'))
  const [selectedFile, setSelectedFile] = React.useState(csvProvider.originalFileName ? { name: csvProvider.originalFileName } : null)
  const [isUploading, setIsUploading] = React.useState(false)
  const [uploadedLists, setUploadedLists] = React.useState(csvProvider ? [{ fileName: csvProvider.originalFileName, uploadPath: csvProvider.uploadedPath }] : [])
  const fileInputRef = React.useRef(null)

  const handleFileUpload = async (event) => {
    if (!selectedFile) {
      console.warn('No file selected')
      return
    }

    if (csvProvider?.csvfileName?.length > 0) { return }

    try {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(selectedFile)

      fileReader.onload = async (event) => {
        const fileContent = event.target.result
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('originalname', selectedFile.name)
        formData.append('path', selectedFile.path)
        formData.append('fileContent', fileContent)

        const response = await fetch('/api/uploadCsv', {
          method: 'POST',
          body: formData
        })

        if (response.ok) {
          const contentDisposition = response.headers.get('content-disposition')
          const matches = /filename="([^"]+)"/.exec(contentDisposition)
          const uploadedFileName = matches && matches.length > 1 ? matches[1] : null

          setSelectedItem((prev) => {
            const providers = (prev.providers || []).slice()
            const index = providers.findIndex(provider => provider.providerType === 'csv')
            if (index !== -1) {
              providers[index] = {
                ...providers[index],
                provider: Providers.CSV,
                isExpanded: false,
                enrichmentCompleted: false,
                csvfileName: uploadedFileName,
                originalFileName: selectedFile.name,
                uploadedPath: `uploads/${uploadedFileName}`
              }
            }

            return {
              ...prev,
              providers: providers,
              provider: Providers.CSV
            }
          })
          setUploadedLists([...uploadedLists, { fileName: selectedFile.name, uploadPath: `uploads/${uploadedFileName}` }])
        } else {
          console.error('File upload error:', response.statusText)
        }
      }
    } catch (error) {
      console.error(`Failed to upload the file. Error : ${error.message}`)
    }
  }

  const handleClose = () => {
    onClose()
  }

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    setFileInputVisible(false)
  }

  const handleDeleteFile = () => {
    setSelectedItem((prev) => {
      const providers = (prev.providers || []).slice()
      const index = providers.findIndex(provider => provider.providerType === 'csv')
      if (index !== -1) {
        providers[index] = {
          ...providers[index],
          csvfileName: null,
          originalFileName: null,
          uploadedPath: null
        }
      }

      return {
        ...prev,
        providers: providers,
        lisId: null
      }
    })

    setCsvProvider((prev) => {
      return {
        ...prev,
        csvfileName: null,
        originalFileName: null,
        uploadedPath: null
      }
    })
    setSelectedFile(null)
    setUploadedLists([])
    setFileInputVisible(true)
  }

  React.useEffect(() => {
    handleFileUpload()
  }, [selectedFile])

  const rows = [
    createData('John', 'Smith', 'Postilize'),
    createData('Jane', 'Doe', 'Acme', 'CEO', 'JD@Acme.com', '@Jane1987', 'https://www.linkedin.com/in/jane')
  ]

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.lightBlue,
      color: theme.palette.primary.black,
      fontWeight: 700,
      fontSize: '12px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '12px',
      fontWeight: 400,
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.primary.white,
      border: 0
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.primary.lightGrey,
      border: 0
    },
  }))

  function createData(fname, lname, company, role, email, twitter, linkedinUrl) {
    return { fname, lname, company, role, email, twitter, linkedinUrl }
  }

  return (
    <>
      <Grid container justifyContent='space-between'>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 5, lineHeight: 'normal' }}>
            Upload your CSV. Please make sure it has columns for first name, last name, and company.
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5 }}>
            Here’s an example of a sample file.
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Table sx={{ width: '100%', flex: 1 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>First Name</StyledTableCell>
                  <StyledTableCell>Last Name</StyledTableCell>
                  <StyledTableCell>Company</StyledTableCell>
                  <StyledTableCell>Role</StyledTableCell>
                  <StyledTableCell>E-Mail</StyledTableCell>
                  <StyledTableCell>Twitter</StyledTableCell>
                  <StyledTableCell>LinkedinURL</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component='th' scope='row'>
                      {row.fname}
                    </StyledTableCell>
                    <StyledTableCell>{row.lname}</StyledTableCell>
                    <StyledTableCell>{row.company}</StyledTableCell>
                    <StyledTableCell>{row.role || <em>Optional</em>}</StyledTableCell>
                    <StyledTableCell>{row.email || <em>Optional</em>}</StyledTableCell>
                    <StyledTableCell>{row.twitter || <em>Optional</em>}</StyledTableCell>
                    <StyledTableCell>{row.linkedinUrl || <em>Optional</em>}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {!selectedFile && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <PButton
                onClick={() => fileInputRef.current.click()}
                disabled={uploadedLists?.[0]?.fileName && uploadedLists.length > 0}
                pVariant={'primary'}
              >
                Add a file
              </PButton>
            </div>
            )}
            {selectedFile && (
              <Paper
                component='form'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: 0,
                  mt: 4,
                  mb: 3,
                  p: 1,
                  border: `1px solid #E0E0E0`,
                  borderRadius: 4,
                }}
              >
                <IconButton sx={{ p: '10px' }}>
                  <Search fill={theme.palette.primary.grey} />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1, fontWeight: 500 }}
                  value={selectedFile.name}
                />
                <IconButton aria-label='delete' onClick={handleDeleteFile}>
                  <Delete fill={theme.palette.primary.grey} />
                </IconButton>
              </Paper>
            )}
            <input
              ref={fileInputRef}
              type='file'
              id='fileInput'
              accept='.csv'
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
            />
          </div>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </>
  )
}
