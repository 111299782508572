import React, { useState, useEffect, useMemo } from 'react'
import { useTheme, styled } from '@mui/material/styles';
import { Box, Stack, Link } from '@mui/material';
import PTypography from './PTypography';
import { useDispatch, useSelector } from 'react-redux';
import WarningIcon from '@mui/icons-material/Warning';
import Http from '../../http/httpClient';

const StyledPLinkedInNoticeArea = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderRadius: '14px',
  border: `1px solid ${theme.palette.primaryCL.Black70}`,
  padding: '12px',
  backgroundColor: '#FFFFE0'
}));



function PLinkedInNoticeArea() {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const [preferencesLoading, setPreferencesLoading] = useState(true);
  const [userPreferences, setUserPreferences] = useState(null);

  const fetchUserPreference = (userId) => {
    Http.getData(`user/${userId}/preference`)
      .then((res) => {
        if (res && res.userPreference !== undefined) {
          setUserPreferences(res.userPreference);
        }
      })
      .catch((error) => {
        console.error('Error fetching user preference:', error)
      })
      .finally(() => {
        setPreferencesLoading(false)
      });
  }

  useEffect(() => {
    fetchUserPreference(user._id);
  }, []);

  const memoizedContent = useMemo(() => {
    if (preferencesLoading) return null;
    if (!userPreferences) return null;
    if (!userPreferences.linkedInLimitInfo) return null;
    return (userPreferences.linkedInLimitInfo.hideUpgradePrompt)?null:(
      <StyledPLinkedInNoticeArea>
        <Stack spacing={1}>
          <Box display="flex" alignItems="center">
            <WarningIcon sx={{ color: theme.palette.warning.main, marginRight: 1 }} />
            <PTypography size="body1" weight={'regular'} sx={{ fontSize: '13px' }}>
              To speed up the processing of your contacts, please consider upgrading your <Link href="https://www.linkedin.com/premium/my-premium/" sx={{ color: 'inherit', textDecoration: 'underline' }} target="_blank" rel="noopener">LinkedIn subscription</Link> or removing contacts you don’t care about.
            </PTypography>
          </Box>
        </Stack>
      </StyledPLinkedInNoticeArea>
    );
  }, [theme, userPreferences]);

  return memoizedContent;
}

PLinkedInNoticeArea.propTypes = {};

export default PLinkedInNoticeArea;
