import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Regexes } from "../../../constants/constant";

import { useDispatch } from "react-redux";
import { setUserCompanyWebsite } from "../../../redux/authSlice";

import { ReactComponent as Back } from "../../../assets/Icons/Arrow-Left.svg";
import { Stack } from "@mui/system";

export default function CompanyWebsiteModal(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { onClose, open, onContinue, setupList } = props;
  const websiteInputRef = React.useRef(null);
  const [website, setWebsite] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [isWebsiteValid, setIsWebsiteValid] = React.useState(true);
  const [alertMessage, setAlertMessage] = React.useState("");

  const validateWebsite = (url) => {
    if (url === "") {
      return true;
    }
    return Regexes.WEBSITE_URL.test(url);
  };

  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const handleContinue = async () => {
    if (website && !Regexes.WEBSITE_URL.test(website)) {
      setAlertMessage("Please enter a valid website URL.");
      setShowAlert(true);
      return;
    } else {
      handleClose();
      if (onContinue) {
        onContinue();
      }
    }
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
    setAlertMessage("");
  };

  const handleClose = () => {
    onClose();
  };

  const handleWebsiteChange = (event) => {
    const input = event.target.value;
    setWebsite(input);
    setIsWebsiteValid(validateWebsite(input));
    dispatch(
      setUserCompanyWebsite({
        companyWebsite: event.target.value,
      })
    );
  };

  return (
    <>
      {alertMessage && (
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
      <Dialog onClose={handleClose} open={open} fullScreen>
        <DialogTitle sx={{ p: 0 }}>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ pt: 2, pl: 2 }}
              >
                <Back fill={theme.palette.primary.black} />
              </IconButton>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={10} sx={{ my: 1.5 }}>
                {setupList.map((item) => (
                  <Stack justifyContent="center" alignItems="center">
                    <Avatar
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        bgcolor: item.active
                          ? theme.palette.primary.blue
                          : theme.palette.primary.white,
                        color: item.active
                          ? theme.palette.primary.white
                          : theme.palette.primary.grey,
                        border: item.active ? `none` : `1px solid #E0E0E0`,
                        my: 1,
                      }}
                    >
                      {item.index}
                    </Avatar>
                    <Typography
                      sx={{
                        color: item.active
                          ? theme.palette.primary.black
                          : theme.palette.primary.grey,
                        fontWeight: 500,
                      }}
                    >
                      {item.listText}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid item>
              <Button
                onClick={handleContinue}
                sx={{
                  maxWidth: "200px",
                  backgroundColor: theme.palette.primary.blue,
                  color: theme.palette.primary.white,
                  textTransform: "none",
                  width: "167px",
                  py: 1,
                  px: 2,
                  mr: 2,
                  mt: 2,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px 0px #2C64E31F",
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={0} md={3} />
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                  pt: 1,
                  lineHeight: "normal",
                  fontSize: {
                    xs: "28px",
                    md: "35px",
                  },
                }}
              >
                What’s your company website?
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: theme.palette.primary.grey,
                  pb: 5,
                }}
              >
                This will allow us to fast-track your profile creation.
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                }}
              >
                Your company website
              </Typography>

              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  boxShadow: 0,
                  mt: 1.5,
                  mb: 3,
                  p: 1,
                  border: `1px solid #E0E0E0`,
                  borderRadius: 4,
                  width: {
                    xs: "100%",
                    md: "90%",
                  },
                }}
              >
                <InputBase
                  ref={websiteInputRef}
                  type="text"
                  value={website}
                  placeholder="www.company.com"
                  onBlur={(e) => (e.target.placeholder = "www.company.com")}
                  sx={{
                    ml: 1,
                    flex: 1,
                  }}
                  onChange={handleWebsiteChange}
                  error={!isWebsiteValid}
                  helperText={
                    !isWebsiteValid && "Please enter a valid website URL"
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleContinue();
                    }
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={0} md={3} />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
