import PropTypes from "prop-types";
import React from "react";
import PTypography from "../../components/library/PTypography";
import PCard from "../../components/library/PCard";

function PostFrequency({ postFrequencies, onSelect }) {
  return (
    <>
      <div style={{ marginBottom: "32px" }}>
        <PTypography size="h3" weight="bold">
          How often do you want to post?
        </PTypography>
      </div>

      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}>
        <PTypography size="body1" weight="bold">
          Choose one
        </PTypography>
        {postFrequencies.map((postFrequency, index) => {
          return (
            <PCard
              key={index}
              title={postFrequency.name}
              onClick={onSelect}
              Badge={postFrequency.badge}
              row={true}
              selected={false}
              button={true}
              buttonize={true}
            />
          );
        })}
      </div>
    </>
  );
}

PostFrequency.propTypes = {
  postFrequencies: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PostFrequency;