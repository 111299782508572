import React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'

import { setComposePostDetails } from '../../redux/authSlice'
import XIconSquare from '../../assets/logos/XIconSquare.png'
import LinkedInIconSquare from '../../assets/logos/LinkedInIconSquare.png'
import { Providers } from '../../constants/constant'


const networks = [{icon: XIconSquare, label: "X (Twitter)", provider: Providers.TWITTER},
                  {icon: LinkedInIconSquare, label: "LinkedIn", provider: Providers.LINKED_IN}]

export default function PostLocation(props) {
    const theme = useTheme();
    const postDetails = useSelector((state) => state.auth.composePostDetails)
    const [selectedProvider, setSelectedProvider] = React.useState(postDetails.provider ?? null)
    const dispatch = useDispatch()

    const handleRadioClick = (provider) => {
      setSelectedProvider(provider)
      if (provider?.length > 0) {
        if (postDetails.provider !== provider) {
          dispatch(setComposePostDetails({ postUrl: postDetails.postUrl, postTopic: postDetails.postTopic, provider: provider}))
        } else {
          dispatch(setComposePostDetails({...postDetails, provider: provider}))
        }
      }
    }

    return (
      <div>
        {networks.map((network, index) => (
          <Container
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.primary.white,
              py: 3,
              my: 2,
              borderRadius: 3,
              width: '30vw'
            }}
          >
            <Box display="flex" alignItems="center">
              <img src={network.icon} alt={network.label} sx={{ mr: 1 }} />
              <Typography sx= {{mx: 2, fontSize: '14px', color: theme.palette.primary.black, fontWeight: 500}}>
                {network.label}
              </Typography>
            </Box>
            {/* <FormControlLabel value={network.label} control={<Radio />} label={network.label} /> */}
            <Radio
              checked={selectedProvider === network.provider}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              onClick={() => handleRadioClick(network.provider)}
            />
          </Container>
        ))}
      </div>
    )
}
