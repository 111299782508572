import { createSlice } from '@reduxjs/toolkit';

const filterMetrics = [
  { topLeads: true },
  { topAccounts: true },
  { topOpportunities: true },
  { allLeads: false },
  { allAccounts: false },
  { allOpportunities: false },
];

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    sidebarOpen: false,
    setupStep: 1,
    twitter: false,
    salesforce: false,
    linkedIn: false,
    page: 'retention',
    settingsPageOverride: null,
    support: 'purchaseLicense',
    user: {},
    userTones: [{ Concise: 50 }, { Controversial: 0 }, { Funny: 0 }],
    userBio: {
      companyName: '',
      product: '',
      customerBenefits: '',
    },
    userScrapedBio: {
      companyName: '',
      product: '',
      customerBenefits: '',
    },
    twitterFilterSetupDone: false,
    salesforceFilterSetupDone: false,
    hubspotFilterSetupDone: false,
    googleFilterSetupDone: false,
    tonesSetupDone: false,
    topicsSetupDone: false,
    initialSetupDone: false,
    googleScopeIncluded: false,
    /* filters: [],
    inFilter: false, */
    twitterFilterMetrics: [{ followers: 0 }, { followings: 50 }],
    salesforceFilterMetrics: filterMetrics,
    hubspotFilterMetrics: filterMetrics,
    googleFilterMetrics: [{ allContacts: true }],
    postCount: 0,
    sequenceTwitterActions: { twitter_auto_like: false, twitter_auto_retweet: false, twitter_auto_follow: false },
    filters: {},
    composePostDetails: {},
    contacts: {},
    sequences: {},
    posts: [],
    delegations: [],
    selectedDelegation: null,
    delegationAccessId: null,
    delegationSequences: [],
    delegationLists: [],
    delegationListContacts: [],
    company: {},
    actionFlags: {
      refreshPosts: false,
      refreshDelegationSequences: false,
      refreshDelegationLists: false,
    },
  },
  // feature flags
  featureFlags: [],
  reducers: {
    setState(state, action) {
      Object.assign(state, action.payload);
    },
    setFeatureFlags(state, action) {
      state.featureFlags = action.payload;
    },
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    openBar(state) {
      state.sidebarOpen = true;
    },
    closeBar(state) {
      state.sidebarOpen = false;
    },
    toggleBar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    incrementSetup(state) {
      state.setupStep = state.setupStep + 1;
    },
    navigateSetupStep(state, action) {
      state.setupStep = action.payload;
    },
    salesForceLogIn(state) {
      state.salesforce = true;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSettingsPageOverride(state, action) {
      state.settingsPageOverride = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setUserTones(state, action) {
      state.userTones = action.payload;
    },
    setUserCompanyWebsite(state, action) {
      state.userCompanyWebsite = action.payload;
    },
    setUserScrapedBio(state, action) {
      state.userScrapedBio = action.payload;
    },
    setUserBio(state, action) {
      state.userBio = action.payload;
    },
    setTwitterFilters(state, action) {
      state.twitterFilterMetrics = action.payload;
    },
    setSalesforceFilters(state, action) {
      state.salesforceFilterMetrics = action.payload;
    },
    setHubspotFilters(state, action) {
      state.hubspotFilterMetrics = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setGoogleFilters(state, action) {
      state.googleFilterMetrics = action.payload;
    },
    setPostCount(state, action) {
      state.postCount = action.payload;
    },
    toggleTwitterFilter(state, action) {
      state.twitterFilterSetupDone = action.payload;
    },
    toggleSalesforceFilter(state, action) {
      state.salesforceFilterSetupDone = action.payload;
    },
    toggleHubspotFilter(state, action) {
      state.hubspotFilterSetupDone = action.payload;
    },
    toggleGoogleFilter(state, action) {
      state.googleFilterSetupDone = action.payload;
    },
    toggleToneSetup(state) {
      state.tonesSetupDone = true;
    },
    toggleTopicsSetup(state) {
      state.topicsSetupDone = true;
    },
    toggleInitialSetup(state) {
      state.initialSetupDone = true;
    },
    setSequenceTwitterActions(state, action) {
      state.sequenceTwitterActions = action.payload;
    },
    setComposePostDetails(state, action) {
      state.composePostDetails = action.payload;
    },
    toggleGoogleScopeIncluded(state, action) {
      state.googleScopeIncluded = action.payload;
    },
    setContacts(state, action) {
      state.contacts = action.payload;
    },
    setSequences(state, action) {
      state.sequences = action.payload;
    },
    setPosts(state, action) {
      state.posts = action.payload;
    },
    setDelegations(state, action) {
      state.delegations = action.payload;
    },
    setSelectedDelegation(state, action) {
      state.selectedDelegation = action.payload;
      localStorage.setItem('selectedDelegation', JSON.stringify(action.payload));
    },
    hydrateSelectedDelegation(state) {
      const savedDelegation = localStorage.getItem('selectedDelegation');
      if (savedDelegation) {
        state.selectedDelegation = JSON.parse(savedDelegation);
      }
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    setActionFlag(state, action) {
      const { flag, value } = action.payload;
      if (state.actionFlags.hasOwnProperty(flag)) {
        state.actionFlags[flag] = value;
      }
    },
    setDelegationAccessId(state, action) {
      state.delegationAccessId = action.payload;
    },
    setDelegationSequences(state, action) {
      state.delegationSequences = action.payload;
    },
    setDelegationLists(state, action) {
      state.delegationLists = action.payload;
    },
    setDelegationListContacts(state, action) {
      state.delegationListContacts = action.payload;
    },
    /*,
    addToFilter(state, action) {
      state.filters.push(action.payload)
    },
    removeFromFilter (state){
      state.filters.shift()
    },
    toggleFilter (state) {
      state.inFilter = !state.inFilter
    },
    setTwitterFilter (state) {
      state.twitterFilter = true
    } */
    addNewSequence: (state, action) => {
      const newSequence = action.payload;
      state.sequences = {
        ...state.sequences,
        [newSequence._id]: newSequence,
      };
    },
  },
});

export const { setState } = authSlice.actions;
export const { login, logout } = authSlice.actions;
export const { openBar, closeBar, toggleBar } = authSlice.actions;
export const { incrementSetup } = authSlice.actions;
export const { salesForceLogIn } = authSlice.actions;
export const { setPage } = authSlice.actions;
export const { setUser } = authSlice.actions;
export const { setSettingsPageOverride } = authSlice.actions;
export const { setUserTones } = authSlice.actions;
export const { setUserCompanyWebsite } = authSlice.actions;
export const { setUserScrapedBio } = authSlice.actions;
export const { setUserBio } = authSlice.actions;
export const { setTwitterFilters, setSalesforceFilters, setHubspotFilters, setGoogleFilters, setFilters } =
  authSlice.actions;
export const { navigateSetupStep } = authSlice.actions;
export const { setPostCount } = authSlice.actions;
export const { toggleTwitterFilter, toggleSalesforceFilter, toggleHubspotFilter, toggleGoogleFilter } =
  authSlice.actions;
export const { setUpdateTwitterFilters, setUpdateSalesforceFilters, setUpdateHubspotFilters, setUpdateGoogleFilters } =
  authSlice.actions;
// export const { addToFilter, removeFromFilter, toggleFilter } = authSlice.actions
export const { setSequenceTwitterActions } = authSlice.actions;
export const { setComposePostDetails } = authSlice.actions;
export const { setFeatureFlags } = authSlice.actions;
export const { toggleGoogleScopeIncluded } = authSlice.actions;
export const { setContacts } = authSlice.actions;
export const { setSequences } = authSlice.actions;
export const { setPosts } = authSlice.actions;
export const { setDelegations } = authSlice.actions;
export const { setSelectedDelegation } = authSlice.actions;
export const { hydrateSelectedDelegation } = authSlice.actions;
export const { setCompany } = authSlice.actions;
export const { setActionFlag } = authSlice.actions;
export const { setDelegationAccessId } = authSlice.actions;
export const { setDelegationSequences } = authSlice.actions;
export const { setDelegationLists } = authSlice.actions;
export const { setDelegationListContacts } = authSlice.actions;
export const { addNewSequence } = authSlice.actions;

export default authSlice.reducer;
