import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useTheme, Checkbox, FormGroup, FormControlLabel } from '@mui/material'

import { UserConst } from '../../../constants/userConst'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '5px',
  boxShadow: 2,
  p: 4
}

const CheckboxItem = ({ name, label, checked, onChange }) => {
  const theme = useTheme()

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          name={name}
          onChange={() => onChange(name)}
          sx={{
            color: theme.palette.primary.blue,
            '&.Mui-checked': {
              color: theme.palette.primary.blue,
              backgroundColor: 'white'
            },
            '&.Mui-disabled': {
              color: theme.palette.primary.lightGrey,
              backgroundColor: 'white'
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 255, 0.5)'
            }
          }}
        />
      }
      label={label}
      sx={{
        '& .MuiTypography-root': {
          color: theme.palette.primary.black,
          fontSize: '12px',
          fontWeight: 500
        },
        marginRight: 6
      }}
    />
  );
}

export default function EditUserModal({ open, onClose, userData }) {
  const theme = useTheme()
  const userName = userData.name
  const availableUserTypes = [UserConst.UserTypes.TRIAL_USER, UserConst.UserTypes.FULL_USER, UserConst.UserTypes.DEMO_USER, UserConst.UserTypes.SIGNUP_USER, UserConst.UserTypes.PRECREATED_USER]
  const approvalStatuses = [UserConst.UserStatus.ACTIVE, UserConst.UserStatus.INACTIVE]
  const [selectedUserType, setSelectedUserType] = React.useState(userData.userType)
  const [selectedStatus, setSelectedStatus] = React.useState(userData.status)
  const [isVip, setIsVip] = React.useState(userData.vip)
  const [goals, setGoals] = React.useState(userData.goals || {})
  const [audience, setAudience] = React.useState(userData.targetAudience || {})


  const capitalize = (word) => {
    if (word.length <= 0) {
      return word
    } else if (word.length == 1) {
      return word.toUpperCase()
    }
    return (word[0].toUpperCase() + word.slice(1))
  }

  const handleTypeOptionChange = (event) => {
    setSelectedUserType(event.target.value)
  }

  const handleStatusOptionChange = (event) => {
    setSelectedStatus(event.target.value)
  }

  const resetWithOriginalValues = () => {
    setSelectedUserType(userData.userType)
    setSelectedStatus(userData.status)
    setIsVip(userData.vip)
  }

  const handleOnClose = (event) => {
    onClose(event)
    resetWithOriginalValues()
  }

  const handleGoalChange = (goal) => {
    setGoals(prevGoals => ({
      ...prevGoals,
      [goal]: !prevGoals[goal]
    }))
  }

  const handleAudienceChange = (audienceType) => {
    setAudience(prevAudience => ({
      ...prevAudience,
      [audienceType]: !prevAudience[audienceType]
    }))
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleOnClose}
        aria-labelledby='edit-user-modal-title'
        aria-describedby='edit-user-modal-description'
      >
        <Box sx={style}>
          <Typography id='edit-user-modal-title' variant='h6' component='h2'>
            User Details
          </Typography>
          <Divider />
          <Box sx={{ mt: 3, mb: 3, width: '100%' }}>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <Typography variant='h6' component='h2'>
                  {userName}
                </Typography>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12}>
                <InputLabel id='edit-user-modal-user-type-label'>Type</InputLabel>
                <Select
                  labelId='edit-user-modal-user-type-label'
                  defaultValue={userData.userType}
                  value={selectedUserType}
                  onChange={handleTypeOptionChange}
                  sx={{ minWidth: 200 }}
                >
                  {availableUserTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {capitalize(type)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel id='edit-user-modal-user-status-label'>Status</InputLabel>
                <Select
                  labelId='edit-user-modal-user-status-label'
                  defaultValue={userData.status}
                  value={selectedStatus}
                  onChange={handleStatusOptionChange}
                  sx={{ minWidth: 200 }}
                >
                  {approvalStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {capitalize(status)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isVip}
                      onChange={() => setIsVip(!isVip)}
                      name='vipStatus'
                    />
                  }
                  label='Is Vip'
                  labelPlacement='start'
                  sx={{ marginLeft: 3, marginTop: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Goals</InputLabel>
                <FormGroup row>
                  <CheckboxItem name="grow" label="Grow" checked={goals.grow} onChange={handleGoalChange} />
                  <CheckboxItem name="retain" label="Retain" checked={goals.retain} onChange={handleGoalChange} />
                  <CheckboxItem name="acquire" label="Acquire" checked={goals.acquire} onChange={handleGoalChange} />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Target Audience</InputLabel>
                <FormGroup row>
                  <CheckboxItem name="ai" label="Start with AI" checked={audience.ai} onChange={handleAudienceChange} />
                  <CheckboxItem name="manualcontact" label="Add people manually" checked={audience.manualcontact} onChange={handleAudienceChange} />
                  <CheckboxItem name="csv" label="Import CSV file" checked={audience.csv} onChange={handleAudienceChange} />
                  <CheckboxItem name="crm" label="From your CRM" checked={audience.crm} onChange={handleAudienceChange} />
                  <CheckboxItem name="existing" label="Existing Lists" checked={audience.existing} onChange={handleAudienceChange} />
                </FormGroup>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ mt: 3, width: '100%' }}>
            <Button
              sx={{ mt: 1, xp: 1, width: '100%' }}
              variant='contained'
              onClick={(e) => {
                userData.userType = selectedUserType
                userData.status = selectedStatus
                userData.vip = isVip
                userData.goals = goals
                userData.audience = audience
                onClose(e, userData)
              }}
            >
              Save
            </Button>
            <Button
              sx={{ mt: 1, width: '100%' }}
              variant='outlined'
              color='error'
              onClick={(e) => {
                resetWithOriginalValues()
                onClose(e)
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
