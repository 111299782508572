// useStrategies.js
import { useState, useEffect } from 'react';
import Http from '../http/httpClient';

export const useStrategies = () => {
  const [strategies, setStrategies] = useState([]);

  useEffect(() => {
    const fetchStrategies = async () => {
      Http.getData('strategy/all')
        .then(function (response) {
          if (response && response.status) {
            setStrategies([...response.strategies]);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    fetchStrategies();
  }, []);

  return strategies ? [strategies, setStrategies] : [[], setStrategies];
};
