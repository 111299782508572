import { useEffect, useMemo } from "react";
import { Select, Grid, MenuItem, FormControl } from "@mui/material";
import PTypography from "./PTypography";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// The +/- window for the "Now" option.
// If the time is within this threshold, 
// it will be displayed as "Now" when showNow is true.
export const NOW_BUFFER = 2;

export const absTimeDeltaMinutes = (time1, time2) => {
  const delta = time1.diff(time2, "minute");
  return Math.abs(delta);
}

const timeOptions = Array.from({ length: 48 }, (_, index) => {
  const hour = Math.floor(index / 2);
  const minute = index % 2 === 0 ? "00" : "30";
  const period = hour < 12 ? "AM" : "PM";
  const displayHour = hour % 12 === 0 ? 12 : hour % 12;

  return `${displayHour.toString().padStart(2, "0")}:${minute} ${period}`;
});

const PTimePicker = ({
  // We use the selected date to determine if time options are in the past
  selectedDate,
  selectedTime,
  onTimeChange,
  readonly,
  show24hrs,
  showNow,
}) => {
  dayjs.extend(customParseFormat);
  const now = dayjs();

  const shouldReplaceTimeWithNow = (time) => {
    if(!showNow) return false;

    const isToday = dayjs(selectedDate).isSame(now, "day");
    if(!isToday) return false;

    const isWithinBufferWindow = absTimeDeltaMinutes(now, dayjs(time, "hh:mm A")) < NOW_BUFFER;
    if(!isWithinBufferWindow) return false;
  
    return true;
  };

  const filteredTimeOptions = useMemo(() => {
    // Filter out times that are in the past
    const availableOptions = timeOptions.filter((timeOption) => {
      if (show24hrs) {
        return true;
      }

      const parsedTimeOption = dayjs(timeOption, "hh:mm A");
      const dateTime = selectedDate
        .hour(parsedTimeOption.hour())
        .minute(parsedTimeOption.minute());

      // Prevent showing "Now" twice in the list
      if (shouldReplaceTimeWithNow(timeOption)) {
        return false;
      }

      return dateTime.isAfter(now);
    });

    if (showNow) {
      const selectedDateIsToday = dayjs(selectedDate).isSame(dayjs(), "day");
      if (selectedDateIsToday)
        availableOptions.unshift(dayjs().format("hh:mm A"));
    }

    if (selectedTime && !availableOptions.includes(selectedTime.format("hh:mm A"))) {
      availableOptions.unshift(selectedTime.format("hh:mm A"));
    }

    return availableOptions;
  }, [selectedDate, show24hrs, showNow]);

  useEffect(() => {
    if (onTimeChange) {
      onTimeChange(timeToHandle);
    }
  }, [filteredTimeOptions]);

  const timeToHandle = useMemo(() => {
    if (!selectedTime) {
      if (filteredTimeOptions?.length > 0) {
        return filteredTimeOptions[0];
      }

      return null;
    }
    return selectedTime.format("hh:mm A");
  }, [selectedTime, filteredTimeOptions]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={12}>
          {/* TODO: Refactor this string literal to a prop */}
          <PTypography size={"body2"}>Time</PTypography>
        </Grid>

        <Grid item xs={12} sx={{ mt: "12px", mb: "12px" }}>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={timeToHandle}
              disabled={readonly}
              onChange={(e) => {
                onTimeChange(e.target.value);
              }}
              sx={{
                fontFamily: "Inter",
                borderRadius: "14px",
                border: "1px solid #E0E0E0",
                height: "56px",

                px: 1,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  height: "56px",
                },
                "& .MuiSelect-icon": {
                  display: "none",
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    borderRadius: "14px",
                    maxHeight: "200px",
                  },
                },
              }}
            >
              {filteredTimeOptions.map((time, index) => (
                <MenuItem
                  key={index}
                  value={time}
                  divider={true}
                  sx={{
                    height: "50px",
                  }}
                >
                  {shouldReplaceTimeWithNow(time) ? "Now" : time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </LocalizationProvider>
    </>
  );
};
export default PTimePicker;
