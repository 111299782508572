import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material';

import Http from '../../../http/httpClient';
import CustomModal from '../../../pages/login/components/confirmModal';
import { Regexes } from '../../../constants/constant';
import featureFlagService from '../../../services/featureFlagService';

export default function CreateFeatureFlagModal({
  open,
  onClose,
  loading,
  companies,
  setFeatureFlags,
}) {
  const [name, setName] = React.useState('');
  const [isValidName, setIsValidName] = React.useState(true);
  const [isFeatureEnabled, setIsFeatureEnabled] = React.useState(false);
  const [selectedCompanyIds, setSelectedCompanyIds] = React.useState([]);
  const theme = useTheme();

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'isFeatureEnabled') {
      setIsFeatureEnabled(value);
    } else if (name === 'enabledCompanyIds') {
      setSelectedCompanyIds(value);
    }
  };

  const handleSubmit = async () => {
    const newFeatureFlag = {
      name: name,
      isFeatureEnabled,
      enabledCompanyIds: selectedCompanyIds,
    };

    const response = await featureFlagService.createAdminFeatureFlag(
      newFeatureFlag
    );
    if (response) {
      setFeatureFlags((prevFeatureFlags) => [...prevFeatureFlags, response]);
      onClose(null);
    }
  };

  const companyLookup = companies.reduce((lookup, company) => {
    lookup[company._id] = company.name;
    return lookup;
  }, {});

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth="true">
        <DialogTitle
          sx={{
            fontWeight: 800,
            fontSize: 18,
            letterSpacing: '-0.02em',
            py: 3,
          }}
        >
          Create a new Feature Flag
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Feature Flag Name"
                fullWidth
                value={name}
                onChange={(e) => {
                  let nameVal = e.target.value;
                  setName(nameVal);
                  setIsValidName(nameVal.length > 0);
                }}
                sx={{ my: 2 }}
                error={!isValidName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isFeatureEnabled"
                    checked={isFeatureEnabled}
                    onChange={handleChange}
                    color="primary"
                    sx={{
                      '&.Mui-checked': {
                        color: theme.palette.primary.blue,
                      },
                    }}
                  />
                }
                label="Is Feature Enabled (Global)"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="enabled-company-ids-label">
                  Enabled Company IDs
                </InputLabel>
                <Select
                  labelId="enabled-company-ids-label"
                  id="enabled-company-ids"
                  name="enabledCompanyIds"
                  multiple
                  value={selectedCompanyIds}
                  onChange={handleChange}
                  label="Enabled Company IDs"
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value} label={companyLookup[value]} />
                      ))}
                    </div>
                  )}
                >
                  {companies.map((company) => (
                    <MenuItem key={company._id} value={company._id}>
                      <Checkbox
                        color="secondary"
                        checked={selectedCompanyIds.includes(company._id)}
                      />
                      <ListItemText primary={company.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose(null)}
            sx={{
              mx: 1,
              my: 2,
              backgroundColor: '#EEEEEE',
              color: '#09092E',
              fontSize: '1.2rem',
              textTransform: 'none',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            sx={{
              mx: 1,
              my: 2,
              backgroundColor: '#3983F7',
              color: 'white',
              fontSize: '1.2rem',
              textTransform: 'none',
            }}
            disabled={loading}
            startIcon={
              loading && <CircularProgress size={20} color="inherit" />
            }
          >
            {loading ? (
              <React.Fragment>
                <span style={{ color: 'white' }}>Saving...</span>
              </React.Fragment>
            ) : (
              'Add'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
