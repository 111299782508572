import * as React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material'

const CustomModal = ({ clickAction, alertText }) => {
  const [open, setOpen] = React.useState(true)
  const theme = useTheme()

  const handleClose = (action) => {
    setOpen(false)
    if (clickAction) {
      clickAction(action)
    }
  }

  return (
    <Dialog open={open} onClose={() => handleClose('Cancel')}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        { alertText }
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            backgroundColor: theme.palette.primary.lightBlue,
            color: theme.palette.primary.blue,
            textTransform: 'none',
            py: 1,
            px: 2,
            borderRadius: 2,
        }}
          onClick={() => handleClose('Cancel')}
        >
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: theme.palette.primary.blue,
            color: theme.palette.primary.white,
            textTransform: 'none',
            py: 1,
            px: 2,
            borderRadius: 2,
        }}
          onClick={() => handleClose('OK')}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomModal
