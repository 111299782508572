import * as React from 'react'
import { styled } from '@mui/system'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse, Paper } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Http from '../../http/httpClient'

const StyledTableCell = styled(TableCell)(() => ({
  padding: '8px 16px',
  borderBottom: 'none'
}))

const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 16px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.action.selected,
  borderBottom: '1px solid white'
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  transition: theme.transitions.create('background-color'),
  '&:hover': {
    backgroundColor: theme.palette.action.selected
  },
  borderBottom: 'none'
}))

const PostQueueAnalysticsTab = () => {
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [rows, setRows] = React.useState([])

  const handleOpen = (id) => {
    setOpen(open === id ? false : id)
  }

  React.useEffect(() => {
    const fetchPostQueueSummary = async () => {
      setIsLoading(true)
      Http.getData('getPostQueueSummary')
        .then((res) => {
          console.log(JSON.stringify(res))
          setRows(res.results)
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          setIsLoading(false)
        })
    }

    fetchPostQueueSummary()
  }, [])

  return (
    <>
      {
        isLoading
          ? <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          : <Box sx={{ width: '100%' }}>
              {rows.length == 0 ?
                <Typography align='center' sx={{
                  fontWeight: 700,
                  fontSize: '14pt',
                  py: 1,
                  mt: 1,
                  mb: 2,
                  fontFamily: 'Poppins',
                  color: '#000000',
                }}
                >
                  No post queue audit logs available so far.
                </Typography> :
                <TableContainer component={Paper} sx={{ maxHeight: 440, maxWidth: '80%', overflow: 'auto' }}>
                  <Table>
                    <TableHead>
                      <StyledTableRow>
                        <StyledHeaderTableCell />
                        <StyledHeaderTableCell>User Name</StyledHeaderTableCell>
                        <StyledHeaderTableCell>Queue Limit</StyledHeaderTableCell>
                        <StyledHeaderTableCell>Pulled</StyledHeaderTableCell>
                        <StyledHeaderTableCell>Queued</StyledHeaderTableCell>
                        <StyledHeaderTableCell>Processed</StyledHeaderTableCell>
                        <StyledHeaderTableCell>Published</StyledHeaderTableCell>
                        <StyledHeaderTableCell>Deleted</StyledHeaderTableCell>
                        <StyledHeaderTableCell>Refreshed</StyledHeaderTableCell>
                        <StyledHeaderTableCell>Failed</StyledHeaderTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                          <React.Fragment key={row.id}>
                            <StyledTableRow>
                                <StyledTableCell>
                                    <IconButton onClick={() => handleOpen(row.id)}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Tooltip title={row.id}>
                                    <Typography>{row.name}</Typography>
                                  </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell>{row.queueLimit || 50}</StyledTableCell>
                                <StyledTableCell>{row.pulled || 0}</StyledTableCell>
                                <StyledTableCell>{row.queued || 0}</StyledTableCell>
                                <StyledTableCell>{row.processed || 0}</StyledTableCell>
                                <StyledTableCell>{row.published || 0}</StyledTableCell>
                                <StyledTableCell>{row.deleted || 0}</StyledTableCell>
                                <StyledTableCell>{row.refreshed || 0}</StyledTableCell>
                                <StyledTableCell>{row.failed || 0}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                <Collapse in={open === row.id} timeout='auto' unmountOnExit>
                                  <Table size='small'>
                                    <TableHead>
                                      <StyledTableRow>
                                        <StyledHeaderTableCell>Contact ID</StyledHeaderTableCell>
                                        <StyledHeaderTableCell>Contact Name</StyledHeaderTableCell>
                                        <StyledHeaderTableCell>Activity Type</StyledHeaderTableCell>
                                        <StyledHeaderTableCell>Refresh Count</StyledHeaderTableCell>
                                        <StyledHeaderTableCell>Status</StyledHeaderTableCell>
                                        <StyledHeaderTableCell>Message</StyledHeaderTableCell>
                                        <StyledHeaderTableCell>Success</StyledHeaderTableCell>
                                        <StyledHeaderTableCell>Processed Date</StyledHeaderTableCell>
                                        <StyledHeaderTableCell>Contact Token</StyledHeaderTableCell>
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row.auditLogs.map((log) => (
                                        <StyledTableRow key={log.contactId}>
                                          <TableCell>{log.contactId}</TableCell>
                                          <TableCell>{log.contactName}</TableCell>
                                          <TableCell>{log.activity_type}</TableCell>
                                          <TableCell>{log.refreshCount}</TableCell>
                                          <TableCell>{log.status}</TableCell>
                                          <TableCell>{log.message}</TableCell>
                                          <TableCell>{log.success}</TableCell>
                                          <TableCell>{log.processedDate}</TableCell>
                                          <TableCell>{log.contactToken}</TableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Collapse>
                              </StyledTableCell>
                            </StyledTableRow>
                          </React.Fragment>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </Box>
      }
    </>
  )
}

export default PostQueueAnalysticsTab
