import * as React from 'react'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'

export default function Unauthorized () {
  const theme = useTheme()

  return (
    <Container component='main' maxWidth='lg' sx={{ mb: 4, backgroundColor: '#F3F3F3' }}>
      <Paper elevation={0} sx={{ my: { md: 6 }, p: { md: 3 }, backgroundColor: '#F3F3F3' }}>
        <Typography
          sx={{
            color: theme.palette.primary.black,
            fontSize: '32px',
            fontWeight: 700,
            my: 2,
            ml: 2
          }}
        >
          Unauthorized Access!
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.grey,
            fontSize: '16px',
            fontWeight: 300,
            my: 2,
            ml: 2
          }}
        >
          You are trying to access either an invalid or unauthorized page.
        </Typography>
        <Box sx={{ mt: 3, mb: 3, width: '100%' }}>
          <Button
            onClick={() => {window.location.href = '/main'}}
            sx={{
              boxShadow: 0,
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '14px',
              borderRadius: 3,
              backgroundColor: theme.palette.primary.blue,
              color: theme.palette.primary.white,
              mx: 2,
              my: 2,
              py: 1,
              px: 2,
              '&:hover': {
                backgroundColor: theme.palette.primary.blue,
                color: theme.palette.primary.white
              }
            }}
          >
            Go to Main Page
          </Button>
        </Box>
      </Paper>
    </Container>
  )
}
