import * as React from 'react'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
} from '@mui/material';

import Http from '../../../http/httpClient';
import UserStat from './userStat';

const StyledTableCell = styled(TableCell)(() => ({
  padding: '8px 16px',
  borderBottom: 'none',
  color: 'black', // Set text color to black
}));

const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 16px',
  fontWeight: 'bold',
  textAlign: 'left',
  width: '15%',
  backgroundColor: theme.palette.action.selected,
  borderBottom: '1px solid white',
  color: 'black', // Set text color to black
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  'transition': theme.transitions.create('background-color'),
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  'borderBottom': 'none',
}));

export default function UserStats() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [userStats, setUserStats] = React.useState([]);
  const [filteredStats, setFilteredStats] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState('');
  const [buttonLoad, setButtonLoad] = React.useState(false);

  React.useEffect(() => {
    const fetchUserStats = async () => {
      setIsLoading(true);
      try {
        const res = await Http.getData('userStats/list');
        setUserStats(res.userStats);
        setFilteredStats(res.userStats);
        const uniqueCompanies = [...new Set(res.userStats.map((stat) => stat.company))];
        setCompanies(uniqueCompanies);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserStats();
  }, []);

  const handleCloseModal = React.useCallback(async (userStats) => {
    if (userStats) {
      try {
        /*const response = await Http.postData('companies/updateOrCreate', { companyDetails })
        if (response && response.status) {
          setCompanies(prevCompanies => {
            const index = prevCompanies.findIndex(company => company._id === response.company._id)
            if (index === -1) {
              const newCompanies = [...prevCompanies, response.company]
              return newCompanies
            } else {
              const updated = [...prevCompanies]
              updated[index] = response.company
              return updated
            }
          })
        } */
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const handleCompanyChange = React.useCallback(
    (event) => {
      const company = event.target.value;
      setSelectedCompany(company);
      if (company) {
        setFilteredStats(userStats.filter((stat) => stat.company === company));
      } else {
        setFilteredStats(userStats);
      }
    },
    [userStats],
  );

  const memoizedFilteredStats = React.useMemo(() => filteredStats, [filteredStats]);

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
            <TextField
              select
              label="Filter by Company"
              value={selectedCompany}
              onChange={handleCompanyChange}
              variant="outlined"
              margin="normal"
              sx={{ minWidth: 200, maxWidth: 300 }}
            >
              <MenuItem value="">
                <em>All Companies</em>
              </MenuItem>
              {companies.map((company) => (
                <MenuItem key={company} value={company}>
                  {company || "'NULL' company"}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledHeaderTableCell>User Name</StyledHeaderTableCell>
                  <StyledHeaderTableCell>User ID</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Company Name</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Company ID</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Setup Steps</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Recently Online (Last 7 days)</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Latest Queue Fetch Web</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Latest Queue Fetch iOS</StyledHeaderTableCell>
                  <StyledHeaderTableCell>No. of Logins</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Replies</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Posts</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Deleted Replies</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Deleted Posts</StyledHeaderTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {memoizedFilteredStats && memoizedFilteredStats.length > 0 ? (
                  memoizedFilteredStats.map(
                    (userStat) =>
                      userStat && (
                        <UserStat
                          key={userStat.user.id}
                          stats={userStat}
                          handleCloseModal={handleCloseModal}
                          buttonLoad={buttonLoad}
                        />
                      ),
                  )
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={'13'}>
                      <Typography align="center" gutterBottom>
                        There are no user stats in this environment.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
