import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme, CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/system/Stack';
import Avatar from '@mui/material/Avatar'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Http from '../../../http/httpClient';

import { useSelector, useDispatch } from 'react-redux';

import ConnectListItem from './connectListItem';

import { Providers } from '../../../constants/constant'
import { GetCookie } from '../../../helper/cookieManager'


import { ReactComponent as Delete } from '../../../assets/Icons/Delete.svg';
import { ReactComponent as XLogo } from '../../../assets/logos/XLogoCircle.svg';
import { ReactComponent as LinkedInLogo } from '../../../assets/logos/LinkedInLogoCircle.svg';
import PTypography  from '@Library/PTypography'

export default function SocialMediaModal(props) {
  const dispatch = useDispatch();
  const theme = useTheme()
  const { onClose, open, setupList } = props;
  const [skipConnection, setSkipConnection] = React.useState(false)
  const [isUpdateProgress, setIsUpdateProgress] = React.useState(false)
  const [isAnyProviderConnected, setIsAnyProviderConnected] = React.useState(false);

  const socialProviderList = [
    {
      title: 'Connect your Linkedin assistant',
      text: 'Connect your Linkedin assistant',
      subtitle: 'Connecting with anyone online and automatically follow, post, message, and comment on Linkedin.',
      logo: <LinkedInLogo />,
      provider: Providers.LINKED_IN,
      connected: null,
      recommended: true,
      chromeExtension: true,
    },
    {
      title: 'Connect your X assistant',
      text: 'Connect your X assistant',
      subtitle: 'Automate tweeting, following, liking, commenting, and sending DMs.',
      logo: <XLogo />,
      provider: Providers.TWITTER,
      connected: null,
      recommended: false,
      chromeExtension: false,
    }
  ];
  const [providerList, setProviderList] = React.useState(socialProviderList);

  async function getConnectedState (provider) {
    const luser = GetCookie('user')
    if(luser) {
      const p = luser.providers.find(acc => acc !== null && acc.provider === provider && acc.active) || []
      if (p && p.profileId) {
        return { connected: true,
          profileId: p.profileId,
          primary: p.primary,
          profileImage: (p && p.imageUrl ) ? p.imageUrl : luser.imageUrl
        }
      }
    }
    return { connected: false, profileId: null, primary: null, profileImage: null }
  }

  const fetchData = async () => {
    const updatedList = await Promise.all(providerList.map(async (item) => {
      const profile = await getConnectedState(item.provider);
      return {
        ...item,
        connected: profile.connected,
        primary: profile.primary,
        profileId: profile.profileId,
        profileImage: profile.profileImage
      };
    }));

    setProviderList(updatedList);
    setIsAnyProviderConnected(updatedList.some(provider => provider.connected))
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleContinue = async () => {
    setIsUpdateProgress(true)

    try {
      if (skipConnection) {
        await Http.postData('setup/skipSocialConnection');
        fetchData();
      } else {
        await Http.getData('setup/checkProviders');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdateProgress(false);
      handleClose();
    }
  };

  React.useEffect(() => {
    const bc = new BroadcastChannel("socialsetupAuthChannel");
    bc.addEventListener('message', (event) => {
      if (event.data.status === 200) {
        fetchData()
      }
    })

    return () => {
      bc.removeEventListener('message', () => {});
      bc.close();
    };
  }, [])

  const handleCheckboxStateChange = (event) => {
    setSkipConnection(prev => event.target.checked)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullScreen sx={{ 
      px: {
        xs: 0,
        md: 2,
      }
    }}>
       <DialogTitle sx={{p: 0}}>
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Grid item>
            <IconButton aria-label="close" onClick={() => { onClose(true) }} sx={{pt: 2, pl: 2}}>
              <Delete fill={theme.palette.primary.grey} />
            </IconButton>
          </Grid>
          <Grid item>
              <Stack direction="row" spacing={10} sx={{my: 1.5}}>
                {setupList.map(item => (
                  <Stack justifyContent="center"
                  alignItems="center">
                  <Avatar sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    bgcolor: item.active ? theme.palette.primary.blue : theme.palette.primary.white,
                    color: item.active ? theme.palette.primary.white : theme.palette.primary.grey,
                    border: item.active ? `none` : `1px solid #E0E0E0`,
                    my: 1,
                  }}>
                    {item.index}
                  </Avatar>
                  <Typography sx={{
                    color: item.active ? theme.palette.primary.black : theme.palette.primary.grey,
                    fontWeight: 500
                  }}>
                    {item.listText}
                  </Typography>
                  </Stack>
                ))}
              </Stack>

            </Grid>
          <Grid item>
            <Button
            onClick={handleContinue}
              sx={{
                maxWidth: '200px',
                backgroundColor: theme.palette.primary.blue,
                color: theme.palette.primary.white,
                textTransform: 'none',
                width: '167px',
                py: 1,
                px: 2,
                mr: 2,
                mt: 2,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                borderRadius: 2,
                boxShadow: '0px 4px 8px 0px #2C64E31F',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                },
              }}
            >
              {isUpdateProgress ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Continue'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6} >
            <PTypography size="h3" weight="bold" sx={{ pt: 1, mb: 4, fontSize: { xs: '28px', md: '32px' }}}>
              Set up your social media assistant
            </PTypography>
            <>
              <Grid container spacing={1.5}>
                {providerList.map((provider) => (
                  <Grid item xs={12} sm={12} key={`${provider.title}${provider.connected}${new Date().toString()}`}>
                    <ConnectListItem currProvider={provider} source={'social'}/>
                  </Grid>
                ))}
              </Grid>

              {/* Removing Skip Button */}
              {/* <Grid item xs={6} container justifyContent="flex-start" sx={{mt: 2}}>
                <FormGroup>
                  <FormControlLabel
                    disabled={isAnyProviderConnected}
                    control={<Checkbox
                      sx={{
                        color: theme.palette.primary.grey,
                        '&.Mui-checked': {
                          color: theme.palette.primary.blue,
                          backgroundColor: 'white',
                        }
                      }}
                      onChange={(event) => { handleCheckboxStateChange(event) }}
                    />
                    }
                    label={<Typography
                      sx={{
                        fontSize: '1rem',
                        ml: 0,
                        fontWeight: 500,
                        color: isAnyProviderConnected ? theme.palette.primary.grey : theme.palette.primary.blue
                      }}>Skip now, I will connect later.</Typography>}
                  />
                </FormGroup>
              </Grid> */}
            </>
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}