import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import UserOnboardings from './userOnboardings';
import UserActivations from './userActivations';
import UserResets from './userResets';
import UserQueueSettings from './userQueueSettings';
import UserEmailSettings from './userEmailSettings';
import UserFocusContactSettings from './userFocusContactSettings';
import UserPostQueueReview from './userPostQueueReview';
import UserPreferences from './userPreferences';
import SequenceTemplate from './sequenceTemplate';
import StrategySettings from './StrategySettings';
import MailScheduler from './mailScheduler';
import GoogleScope from './googleScope';
import Usages from './Usages'

export default function ManageUsers() {
  const [selectedUserTab, setSelectedUserTab] = React.useState('Onboardings');

  const handleTabChange = (event, selectedTab) => {
    setSelectedUserTab(selectedTab);
  };

  let content;
  switch (selectedUserTab) {
    case 'Onboardings':
      content = <UserOnboardings />;
      break;
    case 'Activations':
      content = <UserActivations />;
      break;
    case 'UserPreferences':
      content = <UserPreferences />;
      break;
    case 'Usages':
      content = <Usages />;
      break;
    case 'Resets':
      content = <UserResets />;
      break;
    case 'QueueSettings':
      content = <UserQueueSettings />;
      break;
    case 'EmailSettings':
      content = <UserEmailSettings />;
      break;
    case 'FocusContactSettings':
      content = <UserFocusContactSettings />;
      break;
    case 'PostQueueReview':
      content = <UserPostQueueReview />;
      break;
    case 'SequenceTemplate':
      content = <SequenceTemplate />;
      break;
    case 'StrategySettings':
      content = <StrategySettings />;
      break;
    case 'MailScheduler':
      content = <MailScheduler />;
      break;
    case 'GoogleScope':
      content = <GoogleScope />;
      break;
    default:
      content = <UserOnboardings />;
      break;
  }

  // Created a tabs array to make it easier to add new tabs
  // The value property is used to determine which tab is selected
  // The label property is used to display the tab label
  const tabs = [
    { label: 'Onboardings', value: 'Onboardings' },
    { label: 'Activations', value: 'Activations' },
    { label: 'Preferences', value: 'UserPreferences' },
    { label: 'Usages', value: 'Usages' },
    { label: 'User Resets', value: 'Resets' },
    { label: 'Queue Settings', value: 'QueueSettings' },
    { label: 'Email Settings', value: 'EmailSettings' },
    { label: 'Focus Contact Settings', value: 'FocusContactSettings' },
    { label: 'Post Queue Review', value: 'PostQueueReview' },
    { label: 'Sequence Templates', value: 'SequenceTemplate' },
    { label: 'Strategy Settings', value: 'StrategySettings' },
    { label: 'Mail Scheduler', value: 'MailScheduler' },
    { label: 'Google Scope', value: 'GoogleScope' },
  ];

  return (
    <>
      <Box sx={{ bgcolor: 'background.paper' }}>
        <Tabs
          value={selectedUserTab}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="manage users menu"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              sx={{
                backgroundColor:
                  selectedUserTab === tab.value ? '#C7C7C7' : 'inherit',
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Toolbar />
      <>{content}</>
    </>
  );
}
