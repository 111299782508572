import * as React from "react";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Button,
  useTheme,
} from "@mui/material";
import PCheckbox from "./PCheckbox";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import featureFlagService from "@/services/featureFlagService";

const FilterButton = ({ buttonLabel, bgColor, color, hoverColor, onClick }) => {
  return (
    <Button
      key={buttonLabel}
      onClick={onClick}
      sx={{
        minWidth: "auto",
        textTransform: "none",
        border: "none",
        backgroundColor: bgColor,
        color: color,
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "100%",
        mx: "8px",
        p: "8px",

        // Awful hack
        ml: buttonLabel === "All" ? "16px" : "8px",
        "&:hover": {
          color: hoverColor,
        },
      }}
    >
      {buttonLabel}
    </Button>
  );
};

export default function PSequenceFilter({
  showActionButtons,
  onFilterChange,
  onAction,
  onToggleSelectAll,
  activeFilter,
  custFilterButtons,
  checkbox,
  checkboxProps,
}) {
  const theme = useTheme();
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const showAdvancedSequenceFilters = featureFlagService?.isFeatureEnabled('ui.sequences.review.advancedFilters', featureFlags);

  // Determine which set of buttons to display based on the number of selected contacts
  let filterButtons = [];
  if (custFilterButtons) filterButtons = custFilterButtons;
  else if (showActionButtons)
    filterButtons = [
      { label: "Pause", isDestructive: false },
      { label: "Unpause", isDestructive: false },
      { label: "Remove", isDestructive: true },
      { label: "Expired", isDestructive: false },
    ];
  else
    if (showAdvancedSequenceFilters) {
      filterButtons = [
        { label: "All", isDestructive: false },
        { label: "Upcoming", isDestructive: false },
        { label: "Recent", isDestructive: false },
        { label: "Active", isDestructive: false },
        { label: "Paused", isDestructive: false },
        { label: "Expired", isDestructive: false },
      ];
    } else {
      filterButtons = [
        { label: "All", isDestructive: false },
        { label: "Upcoming", isDestructive: false },
        // { label: "Recent", isDestructive: false },
        // { label: "Active", isDestructive: false },
        { label: "Paused", isDestructive: false },
        { label: "Expired", isDestructive: false },
      ];
    }

  const { isChecked, isIndeterminate } = checkboxProps || {};

  return (
    <>
      <Grid container alignItems="center" sx={{ my: 2, width: "95%" }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          {checkbox && (
            <FormGroup>
              <FormControlLabel
                sx={{ m: 0 }}
                onClick={(e) => onToggleSelectAll(!isChecked)}
                control={
                  <PCheckbox
                    checked={isChecked}
                    indeterminate={isIndeterminate}
                    onChange={(e) => onToggleSelectAll(e.target.checked)}
                  />
                }
              />
            </FormGroup>
          )}

          {filterButtons.map(({ label, isDestructive }) => {
            const isActive = activeFilter === label;

            const color = isDestructive
              ? theme.palette.primaryCL.Red100
              : isActive
                ? theme.palette.primaryCL.Blue100
                : theme.palette.primaryCL.Black200;
            const bgColor = isDestructive
              ? theme.palette.primaryCL.White100
              : isActive
                ? theme.palette.primaryCL.Blue40
                : theme.palette.primaryCL.White100;
            const hoverColor = isDestructive
              ? theme.palette.primaryCL.Red100
              : isActive
                ? theme.palette.primaryCL.Blue100
                : theme.palette.primaryCL.Black100;

            return (
              <FilterButton
                key={label}
                buttonLabel={label}
                color={color}
                bgColor={bgColor}
                hoverColor={hoverColor}
                onClick={() =>
                  showActionButtons
                    ? onAction(label)
                    : onFilterChange(label)
                }
              />
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}

PSequenceFilter.propTypes = {
  selectedContactsCount: PropTypes.number.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  onToggleSelectAll: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
};
