import { useState, useEffect, useRef } from 'react';
import {
  useTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Button,
  Alert,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { ReactComponent as Back } from '@Assets/Icons/Arrow-Left.svg';
import Dart from '@Assets/Icons/Dart.png';
import File from '@Assets/Icons/File.png';
import Person from '@Assets/Icons/Person.png';
import Star from '@Assets/Icons/Star.png';

import ChooseList from '@/components/list/chooseList';
import AIListPrompt from '@/components/list/AIListPrompt';
import AIResults from '@/components/list/AIResults';
import ImportCSV from '@/components/list/importCSV';
import ImportCRM from '@/components/list/importCRM';
import CRMFilters from '@/components/list/crmFilters';
import ManualContact from '@/components/list/manualContact';
import SequenceContactsReview from './sequenceContactsReview';
import { validatedContacts } from '@/helper/utilities';
import { ErrorCodes, ErrorMessages } from '@/constants/errorHandlers';
import { handleListCreation, updateSequenceContacts } from '@/helper/apiHelper';
import DateTimePrompt from '@CommonScreens/DateTimePrompt';
import AIListPayload from '@/components/list/AIListPayload';
import { generateAISearchPayload } from '@/helper/apiHelper';

import Http from '@/http/httpClient';

// Determine when the new contacts will begin receiving sequence steps.
// If the sequence has not started yet, the new contacts will begin receiving sequence steps at the sequence's start time.
// If the sequence has already started, the new contacts will begin receiving sequence steps at the current time,
// plus the delay the user originally chose when creating the sequence.
const determineStartingDate = (createdDate, startedTime, type) => {
  const createdDateObj = new Date(createdDate);
  const startedTimeObj = new Date(startedTime);
  const currentDateObj = new Date();

  if (type === 'Tomorrow') {
    // return currentDateObj + 24hrs in milliseconds
    return new Date(currentDateObj.getTime() + 24 * 60 * 60 * 1000);
  }

  if (!createdDateObj || !startedTimeObj) {
    // fallback if things go wrong
    return currentDateObj;
  }

  // The delay, if any, that was selected when creating the sequence.
  const originalDelay = startedTimeObj - createdDateObj;

  // If startTime is in the past, display the current time + the original delay chosen by the user
  if (startedTimeObj < currentDateObj) {
    return new Date(currentDateObj.getTime() + originalDelay);
  }

  // If startTime is in the future, just use whatever the scheduled start time is
  return startedTimeObj;
};

export default function AddContactsModal(props) {
  const theme = useTheme();
  const { onClose, open, source, sequenceItem, handleModification, targetAudience, existingList } = props;

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateProgress, setIsUpdateProgress] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [saveManualList, setSaveManualList] = useState(false);

  const [listItem, setListItem] = useState({});
  const [sequenceData, setSequenceData] = useState(sequenceItem);
  const [contactList, setContactList] = useState([]);
  const [contactsToReview, setContactsToReview] = useState([]);
  const [listContacts, setlistContacts] = useState([]);
  const [listId, setListId] = useState(null);
  const childComponentRef = useRef();
  const [audienceType, setAudienceType] = useState(
    [
      {
        id: 'AI',
        icon: Star,
        title: 'Start with AI',
        subtitle: 'Quickly create targeted lists.',
        active: false,
        popular: true,
      },
      {
        id: 'ManualContact',
        icon: Person,
        title: 'Add people manually',
        subtitle: 'Simple way to add an audience.',
        active: false,
      },
      { id: 'csv', icon: File, title: 'Import CSV file', subtitle: 'Easily add your marketing lists.', active: false },
      { id: 'crm', icon: Dart, title: 'From your CRM', subtitle: 'HubSpot, Salesforce, Affinity.', active: false },
    ].filter((item) => (targetAudience ? targetAudience[item.id.toLowerCase()] : true)),
  );
  const [aiSearchPayload, setAiSearchPayload] = useState({});
  const [aiPayloadFormData, setAiPayloadFormData] = useState({});

  useEffect(() => {
    if (aiSearchPayload && Object.keys(aiSearchPayload).length > 0) {
      // Payload is ready, now transition to AI_PAYLOAD
      setStep('AI_PAYLOAD');
    }
  }, [aiSearchPayload]);

  const acquisitionSteps = [
    {
      id: 'TARGET_LIST',
      stepId: null,
      title: 'Select who this sequence will target',
      content: (
        <ChooseList
          listItem={listItem}
          audienceType={audienceType}
          setTargetListItem={(item) => {
            setListItem((prev) => ({
              ...prev,
              providers: item?.providers,
              name: item?.name,
              provider: item?.provider,
              lisId: item?.lisId,
            }));
          }}
          sequenceData={sequenceData}
          fromEdit={true}
          targetAudience={targetAudience}
          existingList={existingList}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'AI',
      stepId: null,
      title: 'Start with AI',
      content: (
        <AIListPrompt
          setAiPrompt={(description) => {
            setListItem((prev) => {
              const providers = (prev.providers || []).slice();
              const index = providers.findIndex((provider) => provider.providerType === 'AI');
              if (index !== -1) {
                providers[index] = {
                  ...providers[index],
                  provider: 'rocketreach',
                  AIRequest: description,
                  isExpanded: false,
                };
              }

              return {
                ...prev,
                providers: providers,
                provider: 'rocketreach',
                selectedContacts: [],
                allContacts: [],
              };
            });
          }}
          listItem={listItem}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'AI_PAYLOAD',
      stepId: null,
      title: 'Start with AI',
      content: (
        <AIListPayload
          updateFormData={(data) => setAiPayloadFormData(data)}
          formData={aiPayloadFormData}
          aiSearchPayload={aiSearchPayload}
          setAiSearchPayload={(data) => setAiSearchPayload(data)}
          setAiPrompt={(description) => {
            setListItem((prev) => {
              const providers = (prev.providers || []).slice();
              const index = providers.findIndex((provider) => provider.providerType === 'AI');
              if (index !== -1) {
                providers[index] = {
                  ...providers[index],
                  provider: 'rocketreach',
                  AIRequest: description,
                  isExpanded: false,
                };
              }

              return {
                ...prev,
                providers,
                provider: 'rocketreach',
                selectedContacts: [],
                allContacts: [],
              };
            });
          }}
          listItem={listItem}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'AI_RESULT',
      stepId: null,
      title: 'Start with AI',
      content: (
        <AIResults
          formData={aiPayloadFormData}
          listItem={listItem}
          setselectedTargetContacts={(c) => {
            setListItem((prev) => ({
              ...prev,
              selectedContacts: c.selectedContacts,
              allContacts: c.allContacts,
            }));
          }}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'csv',
      stepId: null,
      title: 'Start with CSV',
      content: <ImportCSV setSelectedItem={setListItem} listItem={listItem} />,
      next: 'true',
      back: 'true',
    },
    {
      id: 'crm',
      stepId: null,
      title: 'Start with CRM',
      content: <ImportCRM setSelectedItem={setListItem} listItem={listItem} />,
      next: 'true',
      back: 'true',
    },
    {
      id: 'CRM_FILTERS',
      stepId: null,
      title: 'Start with CRM',
      content: <CRMFilters listItem={listItem} setSelectedItem={setListItem} />,
      next: 'true',
      back: 'true',
    },
    {
      id: 'ManualContact',
      stepId: null,
      title: 'Create manual contact list',
      content: (
        <ManualContact
          listItem={listItem}
          setContactList={setContactList}
          ref={childComponentRef}
          setListItem={setListItem}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'CONTACTS_REVIEW',
      stepId: null,
      title: 'Start with AI',
      content: (
        <SequenceContactsReview
          contactsToReview={contactsToReview}
          sequenceData={sequenceData}
          setlistContacts={setlistContacts}
        />
      ),
      next: 'true',
      back: 'true',
    },
    {
      id: 'CHOOSE_TIMING',
      stepId: null,
      title: 'How soon should we begin when a new contact is added?',
      content: (
        // We expect the parent component to define the bounds for our Common Screens.
        // This grid container is necessary until this SequenceModal is refactored to use PModals.
        <Grid container>
          <Grid xs={3} />
          <Grid xs={6}>
            <DateTimePrompt
              initialDateTime={determineStartingDate(sequenceData?.createdAt, sequenceData?.startTime)}
              readonly={true}
              headerMessage="Newly added contacts will be scheduled according to the original assistant start time below."
            />
          </Grid>
          <Grid xs={3} />
        </Grid>
      ),
      next: null,
      back: 'true',
    },
  ];

  const [currentStep, setCurrentStep] = useState(acquisitionSteps[0]);

  const continueStyle = {
    'maxWidth': '200px',
    'backgroundColor': theme.palette.primary.blue,
    'color': theme.palette.primary.white,
    'textTransform': 'none',
    'width': '167px',
    'py': 1,
    'px': 2,
    'mr': 2,
    'mt': 2,
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    'borderRadius': 2,
    'boxShadow': '0px 4px 8px 0px #2C64E31F',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.blue, 0.8),
    },
  };

  const setStep = (step) => {
    const stepToSet = acquisitionSteps?.find((item) => item.id === step);
    setCurrentStep(stepToSet);
  };

  const handleBack = () => {
    let nextContent = null;

    switch (currentStep.id) {
      case 'CHOOSE_TIMING':
        if (listContacts?.allContacts?.length > 0) {
          setStep('CONTACTS_REVIEW');
        } else {
          const providerType = listItem?.providers?.[0]?.providerType;
          if (providerType === 'AI') {
            setStep('AI_RESULT');
          } else if (providerType === 'csv') {
            setStep(providerType);
          } else if (providerType === 'ManualContact') {
            setShowSaveButton(true);
            setStep(providerType);
          } else if (providerType === 'crm') {
            setStep('CRM_FILTERS');
          } else {
            setStep('TARGET_LIST');
          }
        }
        break;
      case 'CONTACTS_REVIEW':
        if (listItem?.providers?.length > 0) {
          const providerType = listItem?.providers?.[0]?.providerType;
          if (providerType === 'AI') {
            setStep('AI_RESULT');
          } else if (providerType === 'csv') {
            setStep(providerType);
          } else if (providerType === 'ManualContact') {
            setShowSaveButton(true);
            setStep(providerType);
          } else {
            setStep('CRM_FILTERS');
          }
        } else if (listItem?.lisId) {
          setStep('TARGET_LIST');
        }
        break;
      case 'CRM_FILTERS':
        setStep('crm');
        break;
      case 'AI_RESULT':
        setStep('AI_PAYLOAD');
        break;
      case 'AI_PAYLOAD':
        setStep('AI');
        break;
      case 'AI':
      case 'csv':
      case 'crm':
      case 'ManualContact':
        if (currentStep.id === 'ManualContact' && listItem.providers?.[0].providerType === 'ManualContact') {
          setShowSaveButton(false);
        }
        setStep('TARGET_LIST');
        break;
      default:
        handleClose();
    }
  };

  const handleNext = (event) => {
    switch (currentStep.id) {
      case 'TARGET_LIST':
        if (listItem?.providers?.length > 0) {
          const providerType = listItem?.providers?.[0]?.providerType;
          if (providerType === 'ManualContact') {
            setShowSaveButton(true);
          }

          setStep(providerType);
        } else if (listItem?.lisId) {
          getAndUpdateSequenceContacts();
        } else {
          setAlertMessage('Please choose the contacts provider.');
          setShowAlert(true);
        }
        break;
      case 'AI':
        const aiProvider = listItem?.providers?.find((provider) => provider.providerType === 'AI');
        if (aiProvider?.AIRequest?.length > 0) {
          setIsLoading(true);
          generateAISearchPayload(aiProvider.AIRequest)
            .then((response) => {
              if (!response.error) {
                setAiSearchPayload(response.payload);
                setIsLoading(false);
              } else {
                setIsLoading(false);
                setAlertMessage(response.error);
                setShowAlert(true);
              }
            })
            .catch((error) => {
              console.error('Error:', error);
              setIsLoading(false);
              setAlertMessage('Error generating AI search payload.');
              setShowAlert(true);
            });
        } else {
          setAlertMessage('Please input your required prompt.');
          setShowAlert(true);
        }
        break;
      case 'AI_PAYLOAD':
        setStep('AI_RESULT');
        break;
      case 'crm':
        const crmProvider = listItem?.providers?.find((provider) => provider.providerType === 'crm');
        if (crmProvider) {
          setStep(`CRM_FILTERS`);
        } else {
          setAlertMessage('Please select the crm provider.');
          setShowAlert(true);
        }
        break;
      case 'ManualContact':
        if (handleSaveAndAddAnother(false)) {
        } else {
          setStep('ManualContact');
        }
        break;
      case 'AI_RESULT':
      case 'csv':
      case 'CRM_FILTERS':
        /*if(currentStep.id === 'ManualContact' && listItem.providers?.[0].providerType === 'ManualContact'){
          setShowSaveButton(false)
        }*/
        getAndUpdateSequenceContacts();
        break;
      case 'CONTACTS_REVIEW':
        setStep(`CHOOSE_TIMING`);
        break;
      case 'CHOOSE_TIMING':
        setIsUpdateProgress(true);
        updateSequenceContacts(sequenceData._id, listId, [], listContacts?.removedContacts, sequenceData)
          .then((status) => {
            if (status) {
              handleModification();
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          })
          .finally(() => {
            setIsUpdateProgress(false);
          });
        break;
      default:
        console.log('no case matched');
    }
  };

  const getAndUpdateSequenceContacts = () => {
    setIsUpdateProgress(true);
    handleListCreation(listItem)
      .then((newListId) => {
        setListId(newListId);
        setListItem((prev) => ({ ...prev, lisId: newListId }));
        Http.getData('getContactsAssociatedWithSequence', { listId: newListId })
          .then(function (response) {
            if (response && response.status) {
              const filteredContacts = response.contacts?.filter((contact) => {
                return !contact.sequenceNames.includes(sequenceItem.name);
              });
              if (filteredContacts.length) {
                setContactsToReview((prev) => filteredContacts);
                setIsUpdateProgress(false);
              } else {
                setIsUpdateProgress(false);
                setStep(`CHOOSE_TIMING`);
              }
            }
          })
          .catch(function (error) {
            console.error(error);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if (contactsToReview.length) {
      setStep(`CONTACTS_REVIEW`);
    }
  }, [contactsToReview]);

  // Resolve race condition between setting the list item and dispatching the save event
  useEffect(() => {
    // Workaround for "Should not already be working" error
    // https://stackoverflow.com/questions/76944918
    const doTheStuff = async () => {
      try {
        getAndUpdateSequenceContacts();
      } catch (error) {
        console.error('Error:', error);
      }
    };
    if (saveManualList) {
      setTimeout(() => {
        doTheStuff();
      }, 0);
    }

    setSaveManualList(false);
  }, [saveManualList]);

  const handleSaveAndAddAnother = (addAnother = true) => {
    const result = validatedContacts(contactList, !addAnother);
    if (result.code === ErrorCodes.VALID_CONTACT) {
      const validContact = result.contact;
      if (validContact) {
        setListItem((prev) => {
          const updatedState = {
            ...prev,
            selectedContacts: [...(prev.selectedContacts ?? []), { ...validContact }],
          };
          return updatedState;
        });

        childComponentRef.current.addSavedContact(validContact);
        if (!addAnother) {
          setShowSaveButton(false);
          setSaveManualList(true);
        }
        setContactList([]);
        return true;
      }
    } else {
      if (!addAnother && listItem?.selectedContacts?.length > 0) {
        return true;
      }

      setAlertMessage(ErrorMessages[result.code]);
      setShowAlert(true);
    }
    return false;
  };

  const handleClose = () => {
    onClose();
    setIsLoading(false);
    setIsUpdateProgress(false);
    setShowAlert(false);
    setAlertMessage('');
    setSequenceData({
      targetLists: [],
      targetContacts: [],
    });
  };

  return (
    <Dialog onClose={() => onClose()} open={open} fullScreen>
      <DialogTitle sx={{ p: 0 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <IconButton aria-label="close" onClick={handleBack} sx={{ pt: 2, pl: 2 }}>
              <Back fill={theme.palette.primary.black} />
            </IconButton>
          </Grid>
          <Grid item>{showAlert && <Alert severity="error">{alertMessage}</Alert>}</Grid>
          <Grid item>
            {showSaveButton && (
              <Button
                onClick={handleSaveAndAddAnother}
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.lightBlue,
                  'color': theme.palette.primary.blue,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'mx': 2,
                  'mt': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.white,
                    color: theme.palette.primary.blue,
                  },
                }}
              >
                Save & add another
              </Button>
            )}
            <Button onClick={handleNext} sx={continueStyle} disabled={isLoading}>
              {isUpdateProgress ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            <LinearProgress
              variant={isLoading ? 'indeterminate' : 'determinate'}
              value={75}
              sx={{
                my: 2,
                height: 6,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: theme.palette.primary.lightBlue,
                },
                [`& .${linearProgressClasses.bar}`]: {
                  backgroundColor: '#87E554',
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>{currentStep?.content}</DialogContent>
    </Dialog>
  );
}
