import React from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material";
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as Back } from '../../assets/Icons/Arrow-Left.svg';

export default function GiftDetailsModal(props) {
  const theme = useTheme();
  const { onClose, open, gift, handleChooseGift } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen sx={{ 
      px: {
        xs: 0,
        md: 2,
      }
    }}>
      <DialogTitle sx={{ p: 0 }}>
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose} sx={{ pt: 2, pl: 2 }}>
              <Back fill={theme.palette.primary.grey} />
            </IconButton>
          </Grid>
          <Grid item>
            <Button
              onClick={handleChooseGift}
              sx={{
                maxWidth: '200px',
                backgroundColor: theme.palette.primary.blue,
                color: theme.palette.primary.white,
                textTransform: 'none',
                width: '167px',
                py: 1,
                px: 2,
                mr: 2,
                mt: 2,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                borderRadius: 2,
                boxShadow: '0px 4px 8px 0px #2C64E31F',
                '&:hover': {
                  backgroundColor: theme.palette.primary.lightBlue,
                  color: theme.palette.primary.blue,
                },
              }}
            >
              Choose
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6} >
            <Typography
              component="img"
              src={gift.full_image_url}
              alt="Gift Image"
              sx={{ pt: 1, width: { xs: '40%', md: '40%', } }}
            />
            <Typography sx={{
              fontWeight: 700,
              color: theme.palette.primary.black,
              pt: 1,
              ml: 2,
              fontSize: {
                xs: '28px',
                md: '34px',
              },
            }}>
              {gift.name}
            </Typography>
            <Typography sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: theme.palette.primary.black,
              pb: 3,
              ml: 2,
            }}>
              {`Est. cost $${gift.price} / per gift`}
            </Typography>
            <Typography sx={{
              fontSize: '22px',
              fontWeight: 700,
              color: theme.palette.primary.black,
              ml: 2,
            }}>
              Average cost
            </Typography>
            <Typography sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: theme.palette.primary.black,
              pb: 3,
              ml: 2
            }}>
              {`Est. cost $${gift.price}.00/gift`}
            </Typography>
            <Typography sx={{
              fontSize: '22px',
              fontWeight: 700,
              color: theme.palette.primary.black,
              ml: 2,
            }}>
              Ships to
            </Typography>
            <Typography sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: theme.palette.primary.black,
              pb: 3,
              ml: 2,
            }}>
              {`Canada, United States`}
            </Typography>
            <Typography sx={{
              fontSize: '22px',
              fontWeight: 700,
              color: theme.palette.primary.black,
              ml: 2,
            }}>
              Processing time
            </Typography>
            <Typography sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: theme.palette.primary.black,
              pb: 3,
              ml: 2,
            }}>
              {`Valid for next 30 days.`}
            </Typography>
            <Typography sx={{
              fontSize: '22px',
              fontWeight: 700,
              color: theme.palette.primary.black,
              ml: 2,
            }}>
              Description
            </Typography>
            <Typography sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: theme.palette.primary.black,
              pb: 3,
              ml: 2,
            }}>
              {gift.description}
            </Typography>
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
