import React from 'react'
import { Typography, List, useTheme } from '@mui/material'
import Http from '../../../http/httpClient'
import PContactListHeader from '../../../components/library/PContactListHeader'

import { Box, Paper, Grid, Switch, Divider } from '@mui/material';
import { green, grey, red } from '@mui/material/colors';

import PTypography from '../../../components/library/PTypography';
import PBadge from '../../../components/library/PBadge';
import PTable from '../../../components/library/PTable';
import { useCredits } from '../../../hooks/useCredits';
import { snakeCaseToCamelCase } from '../../../helper/utilities'


const Credits = ({ currentUser }) => {
    const theme = useTheme()
    const [company, setCompany] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(true)
    const [availableCredits, setAvailableCredits] = React.useState(0)
    const [usedCredits, setUsedCredits] = React.useState(0)
    const [autoRenew, setAutoRenew] = React.useState(false)
    const [transactionHistory, setTransactionHistory] = React.useState([])
    const { credits, creditFetchError, areCreditsLoaded, creditHistory, creditsUsed } = useCredits();

    React.useEffect(() => {
        if(areCreditsLoaded && !creditFetchError) {
            setAvailableCredits(credits);
            setUsedCredits(creditsUsed);
            const formattedTransactions = creditHistory.map(transaction => ({
                transaction: snakeCaseToCamelCase(transaction.transactionType),
                amount: transaction.transactionAmount,
                member: transaction.userName,
                date: new Date(transaction.createdAt).toLocaleDateString("en-US", {
                    year: 'numeric', month: 'long', day: 'numeric'
                })
            }));
            setTransactionHistory(formattedTransactions);
        } else {
            setAvailableCredits(0);
            setUsedCredits(0);
            setTransactionHistory([]);
        }
    }, [areCreditsLoaded, credits, creditHistory]);

    React.useEffect(() => {
        async function getCompany() {
            try {
                setIsLoading(true)
                const response = await Http.getData('company/get')
                setCompany({ ...response.company, users: response.users })
            } catch (error) {
                console.error('Error fetching company data:', error)
            } finally {
                setIsLoading(false)
            }
        }
        getCompany()
    }, [])

    const buyCredits = () => {
        console.log('buy credits')
    }

    const toggleAutoRenew = () => {
        setAutoRenew(!autoRenew)
    }

    const columnAlignments = {
        transaction: 'left',
        amount: 'left',
        member: 'right',
        date: 'right'
    }


    return (
        <>
            {isLoading
                ? null
                : (
                    <>
                        <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                            <PContactListHeader
                                headerText='Credits'
                                buttonText='Buy credits'
                                buttonTheme='primary'
                                onClick={buyCredits}
                            />
                        </div>
                        <Grid container sx={{ borderTop: 1, borderBottom: 1, borderColor: theme.palette.primaryCL.Black70, }}>
                            <Grid item xs={4} sx={{
                                borderRight: 1,
                                borderColor: theme.palette.primaryCL.Black70,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                px: '40px',
                                py: '16px'
                            }}>
                                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                                    <PTypography size="h2" weight="bold">🟢</PTypography>
                                </div>
                                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                                    <PTypography size="body2" weight="bold">
                                        {availableCredits} Available
                                    </PTypography>
                                </div>
                                <div style={{ paddingBottom: '8px' }}>
                                    <PTypography size="footnote" weight="regular">
                                        Credits are assigned to a main account and then distributed to team members.
                                    </PTypography>
                                </div>
                            </Grid>
                            <Grid item xs={4} sx={{
                                borderRight: 1,
                                borderColor: theme.palette.primaryCL.Black70,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                px: '40px',
                                py: '16px'
                            }}>
                                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                                    <PTypography size="h2" weight="bold">⚪️</PTypography>
                                </div>
                                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                                    <PTypography size="body2" weight="bold">
                                        {usedCredits} Used
                                    </PTypography>
                                </div>
                                <div style={{ paddingBottom: '8px' }}>
                                    <PTypography size="footnote" weight="regular">
                                        This helps in understanding the consumption trends and planning for future credit purchases.
                                    </PTypography>
                                </div>
                            </Grid>
                            <Grid item xs={4} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                px: '40px',
                                py: '16px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    paddingTop: '8px',
                                    paddingBottom: '8px'
                                }}>
                                    <PTypography size="h2" weight="bold">🔴️</PTypography>
                                    {autoRenew ?
                                        <PBadge pVariant='green' onClick={() => toggleAutoRenew}>On</PBadge>
                                        :
                                        <PBadge pVariant='red' onClick={() => toggleAutoRenew}>Off</PBadge>
                                    }
                                </div>
                                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                                    <PTypography size="body2" weight="bold">
                                        {/*} Turn {autoRenew ? 'off' : 'on'} auto renew*/}
                                        Coming soon
                                    </PTypography>
                                </div>
                                <div style={{ paddingBottom: '8px' }}>
                                    <PTypography size="footnote" weight="regular">
                                        Turn on auto-renew to guarantee the team always has enough credits.
                                    </PTypography>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ paddingTop: '20px' }}>
                            <PTable data={transactionHistory} columnAlignments={columnAlignments} pVariant='primary' />
                        </div>
                    </>
                )}
        </>
    )
}

export default Credits
