// AutomationLogin.js
import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LoginLayout, loginTheme } from "./loginLayout";
import httpClient from "../../http/httpClient";
import { handleLocalLogin } from "../../helper/authUtility";

const AutomationLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const credentials = { username, password }; // Assuming these are from your form's state
    handleLocalLogin(credentials, () => {
      console.log('Login successful');
      // Additional actions post-login, if necessary
    });
  };

  return (
    <LoginLayout title="Sign in">
      <form onSubmit={handleFormSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
      </form>
    </LoginLayout>
  );
};

export default AutomationLogin;
