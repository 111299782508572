import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import httpClientInstance from '../http/httpClient'; // Adjust the path as needed

const ProxyImage = ({ src, alt, ...props }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const loadImageThroughProxy = async () => {
      try {
        const proxyImage = await httpClientInstance.fetchImageThroughProxy(src);
        setImageSrc(proxyImage);
      } catch (error) {
        setHasError(true); // Set an error state on failure
      }
    };

    loadImageThroughProxy();
  }, [src]);

  // If there was an error, don't render the image
  if (hasError) {
    return null;
  }

  return imageSrc ? <Box component="img" src={imageSrc} alt={alt} {...props} /> : null;
};

export default ProxyImage;
