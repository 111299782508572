import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import { useTheme } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Delete } from '../../../assets/Icons/Delete.svg';

export default function EditListModal ({ editList, onClose, open, handleEditedList, handleDeleteList }) {
  const theme = useTheme()
  const [editName, setEditName] = React.useState(editList?.name);

  React.useEffect(() => {
    setEditName(editList?.name)
  }, [editList])

  const handleClose = () => {
    setEditName(editList?.name)
    onClose();
  }

  return (

    <Dialog onClose={handleClose} open={open} maxWidth='lg' sx={{ px: 2 }}>
      <DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Delete fill={theme.palette.primary.grey} />
        </IconButton>

        <Typography sx={{ fontSize: '24px', fontWeight: 500, color: theme.palette.primary.black, pl: 1, pt: 5 }}>
          Edit Your List
        </Typography>

      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.primary.lightGrey }}>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            color: theme.palette.primary.black,
            pl: 1,
            pt: 2
          }}
        >
          Name
        </Typography>

        <Paper
          component="form"
          sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, my: 2, p: 1, width: '25vw' }}
          >
          <InputBase
            type='text'
            placeholder='E.g., High-network leads'
            onFocus={e => (e.target.placeholder = '')}
            value={editName}
            onChange={(e)=> setEditName(e.target.value)}
            sx={{ ml: 1, flex: 1, }}
          />
        </Paper>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: theme.palette.primary.lightGrey, display: 'flex', alignItems: 'end' }}>
         <Button
          onClick={() => { 
              handleDeleteList(editList._id)
              onClose()
          }}
          sx={{
              backgroundColor: theme.palette.primary.white,
              color: theme.palette.primary.blue,
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '16px',
              py: 1,
              px: 2,
              mx: 2,
              my: 2,
              borderRadius: 2,
              '&:hover': {
                  backgroundColor: theme.palette.primary.lightBlue,
                  color: theme.palette.primary.blue,
                },
              '&:active': {
                  backgroundColor: theme.palette.primary.lightGrey,
                  color: theme.palette.primary.blue,
                },
          }}>Delete</Button>
          <Button
            onClick={() => {
              if (editName === editList.name) {
                handleClose()
              } else {
                handleEditedList(editList._id, editName)
              }
            }}
            sx={{
              backgroundColor: theme.palette.primary.blue,
              color: theme.palette.primary.white,
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '16px',
              py: 1,
              px: 2,
              mx: 4,
              my: 2,
              borderRadius: 2,
              '&:hover': {
                backgroundColor: '#4966ff',
                color: theme.palette.primary.lightGrey
              },
              '&:active': {
                backgroundColor: '#0d47a1',
                color: theme.palette.primary.white,
              },
            }}
          >
            Done
          </Button>
      </DialogActions>
    </Dialog>
  )
}
