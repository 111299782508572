import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Delete } from "../../assets/Icons/Delete.svg";

export default function PToast({ text, severity }) {
  const theme = useTheme();
  const [visible, setVisible] = React.useState(true);

  const getColors = (severity) => {
    switch (severity) {
      case "error":
        return {
          bgColor: theme.palette.primaryCL.Red100, 
          textColor: theme.palette.primary.white
        };
      case "info":
        return {
          bgColor: theme.palette.primaryCL.Black200,
          textColor: theme.palette.primary.white
        };
      case "success":
        return {
          bgColor: theme.palette.primaryCL.Green100,
          textColor: theme.palette.primaryCL.Black200
        };
      default:
        return {
          bgColor: theme.palette.primaryCL.main,
          textColor: theme.palette.primaryCL.White
        };
    }
  };

  const { bgColor, textColor } = getColors(severity);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const handleDeleteClick = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Stack sx={{ width: "fit-content" }}>
      <Alert
        icon={false}
        variant="filled"
        severity={severity}
        sx={{
          borderRadius: "14px",
          backgroundColor: bgColor,
          "& .MuiAlert-message": {
            width: "100%",
            textAlign: "center",
            fontSize: theme.typography.body2.fontSize,
            color: textColor,
          },
          position: "relative",
        }}
        action={
          <Delete
            style={{
              alignSelf: "center",
              top: "35%",
              marginRight: '8px',
              marginBottom: '4px',
              alignItems: "center",
              width: "11.91px",
              height: "11.91px",
              fill: textColor,
              cursor: 'pointer'
            }}
            onClick={handleDeleteClick}
          />
        }
      >
        {text}
      </Alert>
    </Stack>
  );
}
