import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import PCheckbox from './PCheckbox';
import PTypography from './PTypography';
import PToolTip from './PToolTip';
import PDropdownVDeux from './PDropdownVDeux';

import { styled, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Divider, Box } from '@mui/material';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    default:
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Black200,
        subtitleText: theme.palette.primaryCL.Black100,
        link: theme.palette.primaryCL.Blue110,
        linkHover: theme.palette.primaryCL.Blue90,
        border: theme.palette.primaryCL.Black70,
      };
      break;

    // Add more cases for 'outlined', 'plain', etc.
  }

  return colorScheme;
};

function PContactListType({ pVariant, contact, checked, onCheck, disableCheckbox }) {
  const theme = useTheme();
  const variantStyles = getVariantStyles(theme, pVariant);

  // Add a state to manage the anchor element for the dropdown
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  // Add a function to handle opening the dropdown
  const handleOptionsClick = () => {
    setIsDropdownOpen(true);
  };

  // Add a function to handle closing the dropdown
  const handleClose = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          position: 'relative',
          px: '40px',
          py: '16px',
          width: '100%',
          height: '87px',
        }}
      >
        <Grid item justifyContent="flex-start" sx={{ height: '100%', marginRight: '14px' }}>
          <div
            style={{
              width: '64px',
              height: '32px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {disableCheckbox ? (
              <Box />
            ) : (
              <PCheckbox checked={contact.checked} onChange={contact.onCheck} disabled={disableCheckbox} />
            )}
            <Avatar src={contact.photo_url} alt={contact.name} sx={{ width: 32, height: 32 }} />
          </div>
        </Grid>
        <Grid item xs sx={{ px: 0, height: '100%' }}>
          <Stack gap="4px" onClick={contact.onClickName} sx={{ height: '100% ' }}>
            <PTypography weight="bold" size="body2" color={variantStyles.text}>
              {contact.name}
            </PTypography>
            {contact.subtitle && (
              <PTypography weight="regular" size="footnote" color={variantStyles.text}>
                {contact.subtitle}
              </PTypography>
            )}
            {contact.role && (
              <>
                <PTypography weight="regular" size="footnote" color={variantStyles.text}>
                  {contact.role}
                </PTypography>
              </>
            )}
            <PTypography weight="regular" size="footnote" color={variantStyles.text}>
              {contact.company}
            </PTypography>
          </Stack>
        </Grid>

        {contact.optionHandlers && (
          <Grid item xs={12} md={6} display="flex" justifyContent="flex-end" flexGrow={1}>
            <PDropdownVDeux
              open={isDropdownOpen}
              onClose={handleClose}
              onClick={handleOptionsClick}
              closeWhenClicked
              displayAs="custom"
              customNode={
                <PTypography
                  weight="regular"
                  size="body2"
                  sx={{
                    'color': variantStyles.link,
                    '&:hover': {
                      color: variantStyles.linkHover,
                    },
                    'cursor': 'pointer',
                  }}
                >
                  Options
                </PTypography>
              }
              menu={{
                headerText: 'Options',
                disableSelection: true,
                dimensions: {
                  width: '360px',
                },
                menuList: [
                  // { label: 'Delegate', onSubmit: contact.optionHandlers?.delegate },
                  { label: 'Manage Assistants', onSubmit: contact.optionHandlers?.manageAssistants },
                  // { label: 'Manage Contacts', onSubmit: contact.optionHandlers?.manageContacts },
                  { label: 'Remove', onSubmit: contact.optionHandlers?.remove },
                ],
              }}
            />
          </Grid>
        )}
        {/* {contact.time && ( */}
        <Grid item xs={12} md={6}>
          <Grid container justifyContent="flex-end">
            <Stack alignItems="flex-end" spacing={'4px'} sx={{ height: '100%' }}>
              {contact.timeToolTip ? (
                <PTypography weight="regular" size="footnote" color={variantStyles.subtitleText}>
                  <PToolTip title={contact.timeToolTip} placement="left">
                    {contact.time}
                  </PToolTip>
                </PTypography>
              ) : (
                <PTypography weight="regular" size="footnote" color={variantStyles.subtitleText}>
                  {contact.time}
                </PTypography>
              )}
              {contact.giftName ? (
                <Stack direction="row" spacing={0.25}>
                  <PTypography weight="regular" size="footnote" color={variantStyles.text}>
                    {contact.giftName}
                  </PTypography>
                  <PTypography weight="regular" size="footnote" color={variantStyles.text}>
                    ·
                  </PTypography>
                  <div onClick={contact.seeGift} style={{ cursor: 'pointer' }}>
                    <PTypography weight="regular" size="footnote" color={variantStyles.link}>
                      See Gift
                    </PTypography>
                  </div>
                  <PTypography weight="regular" size="footnote" color={variantStyles.text}>
                    ·
                  </PTypography>
                  <PTypography weight="regular" size="footnote" onClick={contact.seeGift} color={variantStyles.link}>
                    See Email
                  </PTypography>
                </Stack>
              ) : contact.emailSubject ? (
                <Stack direction="row" spacing={0.25}>
                  <PTypography weight="regular" size="footnote" color={variantStyles.text}>
                    {contact.emailSubject}
                  </PTypography>
                  <PTypography weight="regular" size="footnote" color={variantStyles.text}>
                    ·
                  </PTypography>
                  <div onClick={contact.seeEmail} style={{ cursor: 'pointer' }}>
                    <PTypography weight="regular" size="footnote" onClick={contact.seeGift} color={variantStyles.link}>
                      {contact.isLinkedInMail ? 'See LinkedIn' : 'See Email'}
                    </PTypography>
                  </div>
                </Stack>
              ) : null}
              {contact.stepSubtitle && (
                <PTypography weight="regular" size="footnote" color={variantStyles.subtitleText}>
                  {contact.stepSubtitle}
                </PTypography>
              )}
            </Stack>
          </Grid>
        </Grid>
        {/* )} */}
      </Grid>
      <Divider />
    </>
  );
}

PContactListType.propTypes = {
  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary']),

  /** Contact information
   * Structure: Name : Contact's name
   *            Photo URL : Url to image we have on file
   *            Subtitle : Alternate subtitle, used in example for teams
   *            Role: Role title
   *            Company: Company name
   *            time : Timing of sequence action - Ongoing or
   *            Gift name : Name of gift
   *            See Gift OnClick: Function to see gift
   *            Email Subject: Subject of email
   *            See email onClick: Body of email
   *            Step subtitle: EX/ Step 5/9. Not used for ongoing steps
   */
  contact: PropTypes.object.isRequired,

  /** Checkbox status */
  checked: PropTypes.bool,

  /** Function that happens when the contact is clicked */
  onClickName: PropTypes.func,
};

PContactListType.defaultProps = {
  pVariant: 'primary',
  checked: false,
};

export default PContactListType;