import * as React from 'react'
import { useSelector } from 'react-redux'

//import PostQueue from '../pages/postQueue/postQueue'
import PurchaseLicense from '../pages/support/purchaseLicense'

export default function Support () {
  const page = useSelector(state => state.auth.support)
  console.log(page)
  let content
  switch (page.payload) {
    case 'purchaseLicense':
      content = <PurchaseLicense />
      break
    default:
      content = <PurchaseLicense />
  }

  return (
    <>
      {content}
    </>
  )
}
