import { useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';

export const useIsCompanyAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  const admins = useSelector((state) => state?.auth?.company?.company?.admins);
  const currentUserId = useSelector((state) => state?.auth?.user?._id);

  useEffect(() => {
    setIsAdmin(admins?.includes(currentUserId));
  }, [currentUserId, admins]);

  return useMemo(() => isAdmin, [isAdmin]);
};

// Internal hook; don't export
const useUserData = () => {
  const availableDelegates = useSelector((state) => state?.auth?.company?.users);
  const currentUserId = useSelector((state) => state?.auth?.user?._id);
  const assignedDelegations = useSelector((state) => state?.auth?.delegations);
  return { availableDelegates, currentUserId, assignedDelegations };
};

// Internal helper to emulate the payload returned by the server when constructing the available delegations list
// When currentUserId is provided, exclude the current user from the list
const mapUserToAccess = (user, currentUserId = null) => {
  if (currentUserId && user._id === currentUserId) return null;
  return {
    access: [
      {
        userId: user._id,
        name: user.name,
        email: user.primaryEmail,
      },
    ],
  };
};

// Retrieves all available constituents for admins.
// ie, all users except the current user. no delegation records are required
export const useAdminDelegations = () => {
  const { availableDelegates, currentUserId } = useUserData();

  return useMemo(
    () => availableDelegates?.map((user) => mapUserToAccess(user, currentUserId)).filter(Boolean),
    [availableDelegates, currentUserId],
  );
};

// Retrieves all existing constituents for the current user.
// ie, all delegation records where the delegateId is the current user
export const useExistingDelegations = () => {
  const { assignedDelegations, currentUserId } = useUserData();

  return useMemo(
    () => assignedDelegations.filter((delegation) => delegation?.delegateId === currentUserId),
    [assignedDelegations, currentUserId],
  );
};

// Retrieves all available constituents for the current user.
// Determines whether the user is an admin or not, and returns the appropriate list.
export const useAvailableDelegations = () => {
  const isAdmin = useIsCompanyAdmin();
  const adminDelegations = useAdminDelegations();
  const userDelegations = useExistingDelegations();

  return useMemo(() => (isAdmin ? adminDelegations : userDelegations), [isAdmin, adminDelegations, userDelegations]);
};

// Retrieves all available delegates in the system.
// Right now, this just means all users in the company.
export const useAvailableDelegates = () => {
  // TODO: This is roughly how we will get the available delegates in the future.
  // const availableDelegates = useSelector((state) => state?.auth?.company?.users).filter(
  //   (user) => user.userType === 'Delegate',
  // );

  const availableDelegates = useSelector((state) => state?.auth?.company?.users);

  return useMemo(() => availableDelegates, [availableDelegates]);
};
