import { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, IconButton, List, ListItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PTopicSelector from '@Library/PTopicSelector';
import PTypography from '@Library/PTypography';
import PChipInputField from '@Library/PChipInputField';
import PModal from '@Library/PModal';
import { useTheme } from '@mui/material/styles';

const listItemSxStyle = { px: 0, py: 0.5 };
const headerStyle = { justifyContent: 'space-between', display: 'flex', mb: 2.5 };
const iconStyle = { color: 'black', p: 0 };
const inputBoxStyle = { overflowY: 'auto', mr: 4, width: '100%' };
const floatingDimensionStyle = { height: 'auto', width: '40vw' };

const PPickerModal = ({
  open,
  headerTitle,
  searchTerm,
  onSearchTermChange,
  onSelect,
  onClose,
  options,
  selectedOptions,
  placeholder,
}) => {
  const theme = useTheme();
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState(selectedOptions || []);
  const [displayedOptions, setDisplayedOptions] = useState([]);

  useEffect(() => {
    setCurrentSelectedOptions(selectedOptions || []);
  }, [selectedOptions]);

  useEffect(() => {
    // Filter options that are not in currentSelectedOptions and slice to the first 5
    setDisplayedOptions(options.filter((opt) => !currentSelectedOptions.includes(opt)).slice(0, 5));
  }, [options, currentSelectedOptions]);

  const handleSelect = useCallback(
    (option) => {
      const updatedSelectedOptions = currentSelectedOptions.includes(option)
        ? currentSelectedOptions.filter((item) => item !== option)
        : [...currentSelectedOptions, option];
      setCurrentSelectedOptions(updatedSelectedOptions);
      onSelect(updatedSelectedOptions);
      // Update displayed options excluding the new selections
      setDisplayedOptions(options.filter((opt) => !updatedSelectedOptions.includes(opt)).slice(0, 5));
    },
    [currentSelectedOptions, onSelect, options],
  );

  const handleChipDelete = useCallback(
    (chipToDelete) => {
      const updatedSelectedOptions = currentSelectedOptions.filter((item) => item !== chipToDelete);
      setCurrentSelectedOptions(updatedSelectedOptions);
      onSelect(updatedSelectedOptions);
      setDisplayedOptions(options.filter((opt) => !updatedSelectedOptions.includes(opt)).slice(0, 5));
    },
    [currentSelectedOptions, onSelect, options],
  );

  const handleClose = useCallback(() => {
    onClose(currentSelectedOptions);
  }, [currentSelectedOptions, onClose]);

  const optionsList = useMemo(() => {
    if (displayedOptions.length === 0) {
      return null; // Return nothing if no visible options are available
    }

    return (
      <Box sx={{ mt: 2 }}>
        <PTypography size="body1" weight="regular" color={theme.palette.primaryCL.Black100}>
          Suggestions
        </PTypography>
        <List>
          {displayedOptions.map((option) => {
            const topic = { name: option, isActive: false };
            return (
              <ListItem key={option} sx={listItemSxStyle}>
                <PTopicSelector topic={topic} onToggle={() => handleSelect(option)} pVariant="default" />
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  }, [displayedOptions, handleSelect]);

  const renderContent = () => (
    <Box>
      <Box sx={headerStyle}>
        <PTypography size="h1" weight="bold">
          {headerTitle}
        </PTypography>
        <IconButton aria-label="close" onClick={handleClose} sx={iconStyle}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={inputBoxStyle}>
        <PChipInputField
          values={currentSelectedOptions}
          placeholder={placeholder}
          onChipDelete={handleChipDelete}
          onChange={(e) => onSearchTermChange(e.target.value)}
          value={searchTerm}
          autoFocus={open}
        />
        {optionsList}
      </Box>
    </Box>
  );

  return (
    <PModal
      open={open}
      onClose={handleClose}
      floating
      inlineHeaderText={false}
      extraWideContent
      ultraWideContent
      floatingDimensions={floatingDimensionStyle}
    >
      {renderContent()}
    </PModal>
  );
};

export default PPickerModal;
