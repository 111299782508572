import React from 'react';
import { Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material';
import PTypography from '@Library/PTypography';
import PButton from '@Library/PButton';

import { ReactComponent as Acquisition } from '@Assets/emptyState/Acquisition.svg';

const EmptyState = ({ buttonClick }) => {
  const theme = useTheme();

  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 5, px: 4 }}>
      <Acquisition />
      <PTypography size="h2" weight="bold">
        Build relationships automatically
      </PTypography>
      <PTypography size="body1" sx={{ color: theme.palette.primary.grey }} weight="regular">
        When you set up an acquisition strategy, your social media accounts can automatically build relationships.
      </PTypography>
        <PButton sx={{ width: "100%" }} onClick={buttonClick} pVariant="primary">
            Start building relationships
        </PButton>
    </Stack>
  );
};

export default EmptyState;
