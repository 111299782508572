import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Forward } from "../../assets/Icons/Arrow-Right.svg";

export default function PDropdownSubmenu({ menuList, anchorEl, isOpen, onClick, onClose, onSubmit }) {
  const theme = useTheme();

  const handleClick = (event) => {
    if (typeof onSubmit === 'function') {
      onSubmit(event.currentTarget.outerText);
    }

  }

  return (
    <div>
      <Forward
        onClick={onClick}
        fill={theme.palette.primary.black}
        style={{ width: "12px", height: "12px", cursor: "pointer" }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        slotProps={{
          paper: {
            style: { minWidth: 259, width: 'auto' },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {menuList.map((item) => {
          return (
            <MenuItem
              sx={{
                fontSize: theme.typography.body1.fontSize,
                fontHeight: theme.typography.body1.fontHeight,
                "&:hover": {
                  backgroundColor: theme.palette.primaryCL.Blue40,
                },
              }}
              onClick={handleClick}
            >
              {item}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
