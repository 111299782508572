import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Typography, useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs'

import PostType from './postType';
import PostLocation from './postLocation';
import EmployeeEngagement from './employeeEngagement';
import Success from './success';

import { setComposePostDetails } from '../../redux/authSlice'
import Http from '../../http/httpClient'
import ComposePostModal from './composePost';
import PostModal from '../../pages/ClientRetention/components/PostModal';
import { GetCookie } from '../../helper/cookieManager'
import { ReactComponent as Delete } from '../../assets/Icons/Delete.svg';
import { ReactComponent as Trash } from '../../assets/Icons/Trash.svg';
import { ReactComponent as Tags } from '../../assets/Icons/Tags.svg';
import { ReactComponent as Photo } from '../../assets/Icons/Photo.svg';

export default function AddPostModal(props) {
  const theme = useTheme();
  const postDetails = useSelector((state) => state.auth.composePostDetails)
  const { onClose, open, step} = props;
  const [isLoading, setIsLoading] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")
  const curUser = GetCookie('user')
  const dispatch = useDispatch()
  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation)

  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const handleClose = () => {
    onClose();
    setIsLoading(false)
    dispatch(setComposePostDetails({}))
    setCurrentStep(addPostSteps[0].id);
  };

  const deleteUserPost = async (postDetails) => {
    let result = false
    if (postDetails.post) {
      return new Promise((resolve, reject) => {
        const endpoint = authSliceSelectedDelegation?.userId
          ? `deleteUserPost/${authSliceSelectedDelegation.userId}`
          : 'deleteUserPost';

        Http.postData(endpoint, { post: postDetails.post })
          .then((res) => {
            if (res.success === true) {
              result = res.success
              resolve(true)
            } else {
              reject("error")
            }
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      });
    }

    return result
  }

  const deletePost = async (postDetails) => {
    try {
      setIsDeleting(true)
      const res = await deleteUserPost(postDetails);
      setIsDeleting(false)
      if (res) {
        handleClose()
      } else {
        setAlertMessage("Unable to delete the poost.")
        setShowAlert(true)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const generateUserPost = async (postDetails) => {
    let result = null
    if (!postDetails.post) {
      return new Promise((resolve, reject) => {
        Http.postData('composePost', { text: postDetails.postUrl, topic: postDetails.postTopic, provider: postDetails.provider })
          .then(response => {
            result = { ...response.post }
            resolve(result)
          })
          .catch(error => {
            console.log('Error generating post:', error)
            reject(error)
          })
      });
    }

    return result
  }

  const generatePost = async (postDetails, nextStepId) => {
    try {
      setIsLoading(true)
      const post = await generateUserPost(postDetails);
      setIsLoading(false)
      if (post) {
        dispatch(setComposePostDetails({...postDetails, post: post}))
        setCurrentStep(nextStepId)
      } else {
        setAlertMessage("Unable to generate the poost.")
        setShowAlert(true)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const publishUserPost = async (postDetails) => {
    let result = false
    if (postDetails.post) {
      const postToPublish = { ...postDetails.post }
      if (postDetails.message && postDetails.message.length > 0 && postToPublish.message !== postDetails.message) {
        postToPublish.message = postDetails.message
      }

      return new Promise((resolve, reject) => {
        const endpoint = authSliceSelectedDelegation?.userId
          ? `publishPost/${authSliceSelectedDelegation.userId}`
          : 'publishPost';

        Http.postData(endpoint, { post: postToPublish, priority: 'immediate', time: dayjs(new Date()) })
          .then(res => {
            if (res && res.status) {
              result = res.status
              resolve(true)
            } else {
              reject("error")
            }
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      });
    }
    return result
  }

  const handlePostApprove = async (postDetails, message) => {
    let result = false
    if (postDetails.post) {
      const postToPublish = { ...postDetails.post }
      if (message && message.length > 0 && postToPublish.message !== message) {
        return new Promise((resolve, reject) => {
          const endpoint = authSliceSelectedDelegation?.userId
            ? `updateUserPost/${authSliceSelectedDelegation.userId}`
            : 'updateUserPost';

          Http.postData(endpoint, { post: postToPublish, textToUpdate: message })
            .then(res => {
              if (res) {
                result = true
                resolve(true)
              } else {
                reject("error")
              }
            })
            .catch(error => {
              console.error(error)
              reject(error)
            })
        });
      } else {
        result = true
      }
    }
    return result
  }

  const publishPost = async (postDetails, nextStepId) => {
    try {
      setIsLoading(true)
      const res = await publishUserPost(postDetails);
      setIsLoading(false)
      if (res) {
        setCurrentStep(nextStepId)
      } else {
        // add alert to inform the publish failed.
        setAlertMessage("Unable to publish the poost.")
        setShowAlert(true)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleNext = () => {
    const index = addPostSteps.findIndex(s => s.id === currentStep)
    switch (currentStep) {
      case 'AddPost':
        if ((postDetails.postUrl && postDetails.postUrl.length > 0) || (postDetails.postTopic && postDetails.postTopic.length > 0)) {
          setCurrentStep(addPostSteps[index + 1].id)
        } else {
          setAlertMessage("Please choose a topic or provide url to proceed.")
          setShowAlert(true)
        }
        break;
      case 'Location':
        if (postDetails.provider) {
          generatePost(postDetails, addPostSteps[index + 1].id)
        } else {
          setAlertMessage("Select anyone of the providers.")
          setShowAlert(true)
        }
        break;
      case 'Post':
        publishPost(postDetails, addPostSteps[index + (!curUser.companyId ? 2 : 1)].id)
        break;
      default:
        if (index !== -1 && index < addPostSteps.length - 1) {
          const nextStepId = (!curUser.companyId && currStep.next === 'Engagement') ? addPostSteps[index + 2].id : addPostSteps[index + 1].id;
          setCurrentStep(nextStepId)
        }
        break;
    }
  };

  const handleDeletePost = () => {
    deletePost(postDetails)
  }

  const addPostSteps = [
    {id: 'AddPost',
      title: "There are two ways to create posts",
      content: <PostType />,
      buttonAction: 'Next',
      next: 'Location'
    },
    {id: 'Location',
      title: "Where do you want to make your post?",
      content: <PostLocation />,
      buttonAction: 'Generate',
      next: 'Post'
    },
    {id: 'Post',
      title: "Compose Post",
      content: <PostModal handlePublish={publishUserPost} handleDelete={deletePost} isDeleting={isDeleting} source="first" handlePostApprove={handlePostApprove} />,
      buttonAction: 'Post',
      next: 'Engagement'
    },
    {id: 'Engagement',
      title: "Do you want your employees to auto-like or reshare your post?",
      content: <EmployeeEngagement />,
      buttonAction: 'Done',
      next: 'Success'
    },
    {id: 'Success',
      content: <Success />,
      buttonAction: 'Ok',
      next: 'Success'
    },
  ];

  const [currentStep, setCurrentStep] = React.useState(step || addPostSteps[0].id);

  React.useEffect(() => {
    if (step) {
      setCurrentStep(step);
    } else {
      // Default to the first step if 'step' is not provided
      setCurrentStep(addPostSteps[0].id);
    }
  }, [step]);

  const handleBack = () => {
    const index = addPostSteps.findIndex(s => s.id === currentStep);
    if (index !== -1 && index > 0) {
      const backStepId = addPostSteps[index - 1].id;
      setCurrentStep(backStepId);
    }
  };

  const currStep = React.useMemo(() => {
    return addPostSteps.find(s => s.id === currentStep);
  }, [currentStep]);
  

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='lg' sx={{ px: 2 }}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Delete fill={theme.palette.primary.grey} />
        </IconButton>
        {currStep ? (
          <>
            {currentStep === 'Post' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: 500, color: theme.palette.primary.black, pl: 1, pt: 5 }}>
                  {currStep.title}
                </Typography>
                <Stack direction="row" spacing={2} sx={{ mt: 5 }}>
                  {isDeleting ?
                    (<CircularProgress
                      size={20}
                      color='inherit'
                      thickness={4}
                    />) : (
                    <IconButton aria-label="Delete" disabled={isDeleting} onClick={handleDeletePost}>
                      <Trash fill={theme.palette.primary.grey} />
                    </IconButton>)
                  }

                  <IconButton aria-label="Tags">
                    <Tags fill={theme.palette.primary.grey} />
                  </IconButton>
                  <IconButton aria-label="Photo">
                    <Photo fill={theme.palette.primary.grey} />
                  </IconButton>
                </Stack>
              </Box>) : (
                <Typography sx={{ fontSize: '24px', fontWeight: 500, color: theme.palette.primary.black, pl: 1, pt: 5 }}>
                  {currStep.title}
                </Typography>
              )
            }
          </>
        ) : (
          "Step not found"
        )}
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: currentStep === 'Success' ? theme.palette.primary.white : theme.palette.primary.lightGrey, maxHeight: '50vh' }}>
        {currStep.content}
      </DialogContent>
      {currentStep !== 'Success' && (
      <DialogActions sx={{ backgroundColor: theme.palette.primary.lightGrey, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={handleBack}
          sx={{
            backgroundColor: theme.palette.primary.white,
            color: theme.palette.primary.grey,
            textTransform: 'none',
            fontWeight: 700,
            visibility: currentStep === "AddPost" && "hidden",
            fontSize: '16px',
            py: 1,
            px: 2,
            mx: 3,
            my: 2,
            borderRadius: 2,
        }}>Back</Button>

        {showAlert && (<Alert severity="error">{alertMessage}</Alert>)}

        <Button
          onClick={handleNext}
          disabled={isLoading}
          sx={{
            backgroundColor: theme.palette.primary.blue,
            color: theme.palette.primary.white,
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '16px',
            py: 1,
            px: 2,
            mx: 3,
            my: 2,
            borderRadius: 2,
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={20}
              color='inherit'
              thickness={4}
            />
          ) : currStep.buttonAction}
        </Button>
      </DialogActions>)}
    </Dialog>
  );
}