// Handle all of the logic for topic selection.
//
// This component is batteries-included and is a black box,
// following the pattern of (input, callback) => callback(output)
//
// 1. Input: initialAvailableTopics, initialSelectedTopics, selectedTopicsSetter
// 2. Output: selectedTopics

import PropTypes from "prop-types";
import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import PTopicSelector from "@Library/PTopicSelector";
import PTextfield from "@Library/PTextfield";
import PTypography from "@Library/PTypography";
import PBadge from "@Library/PBadge";
import { Box } from "@mui/material";
import { generateTopics } from "@/helper/apiHelper";
import PProgressVerticalStepper from "@Library/PProgressVerticalStepper";
import { CircularProgress } from "@mui/material";
import PButton from "@Library/PButton";
import { updateTopics } from "@/helper/apiHelper";

const sendUpdatedTopics = async (topics) => {
  const updatedTopics = await updateTopics(topics);

  // There was an error updating the user's topics.
  if (updatedTopics === null || updatedTopics === false) {
    // TODO: Handle error response and retry update.
    //       Display a message to the user that we are having issues.
  }
};

const constructTopicObjects = (topics, allActive = false) => {
  if (!topics?.length) {
    return [];
  }

  const topicObjects = topics.map((topic) => ({
    name: topic,
    isActive: allActive,
  }));

  return topicObjects;
};

const TopicPrompt = ({ onChange, onEnter }) => {
  const handleTextChange = (event) => {
    const targetAudience = event.target.value;
    if (targetAudience && onChange) {
      onChange(targetAudience);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (onEnter) {
        onEnter(event);
      }
    }
  };

  return (
    <PTextfield
      rows={1}
      onChange={handleTextChange}
      paperStyle={{
        width: "100%",
      }}
      inputStyle={{
        width: "100%",
      }}
      placeholder="E.g., biotechnology CEOs located in San Francisco"
      onKeyDown={handleKeyDown}
    />
  );
};

const TopicSelect = ({ topics, onToggle, isFetchingTopics }) => {
  if (isFetchingTopics) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        minHeight="200px"
      >
        <CircularProgress size={24} color="inherit" />
      </Box>
    );
  }

  if (topics?.length) {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "16px",
          gap: "8px",
        }}
      >
        {topics.map((topic) => {
          return (
            <PTopicSelector
              key={topic.name}
              topic={topic}
              onToggle={onToggle}
              pVariant="grey"
              hideIcon
            />
          );
        })}
      </div>
    );
  }

  return null;
};

const TopicConfirm = ({ selectedTopics, onToggle }) => {
  return (
    <>
      {!!selectedTopics?.length && (
        <Box display="flex" justifyContent="space-between">
          <PTypography size="body2" weight="regular" color="inherit">
            Select up to 10 items
          </PTypography>

          <PBadge pVariant={selectedTopics.length > 10 ? "red" : "grey"}>
            <>{selectedTopics?.length || "0"} / 10</>
          </PBadge>
        </Box>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "16px",
          gap: "8px",
        }}
      >
        {Array.from(selectedTopics).map((topic, index) => (
          <PTopicSelector
            key={topic.name + "PTopicSelector2"}
            topic={topic}
            onToggle={onToggle}
            pVariant={index >= 10 ? "red" : "green"}
          />
        ))}
      </div>
    </>
  );
};

const fetchGeneratedTopics = async (targetAudience) => {
  const generatedTopics = await generateTopics(targetAudience);

  // There was an error generating topics.
  if (generatedTopics === null || generatedTopics?.length === 0) {
    // TODO: Handle error response and retry generation.
    //       Display error message to user.
    return [];
  }

  return generatedTopics;
};

function TopicSelection({
  autosave,
  initialAvailableTopics,
  initialSelectedTopics,
  selectedTopicsSetter,
}) {
  const [isFetchingTopics, setIsFetchingTopics] = useState(false);
  const [targetAudience, setTargetAudience] = useState("");
  const [topicObjects, setTopicObjects] = useState([
    ...constructTopicObjects(initialAvailableTopics),
    ...constructTopicObjects(initialSelectedTopics, true),
  ]);
  const selectedTopics = useMemo(
    () => topicObjects?.filter((topic) => topic.isActive),
    [topicObjects],
  );

  const autosaveRef = useRef(autosave);
  const selectedTopicsRef = useRef(selectedTopics);

  // Update the current props in the ref objects on each render
  autosaveRef.current = autosave;
  selectedTopicsRef.current = selectedTopics;

  useEffect(
    () => () => {
      // Cleanup function that will be called on unmount
      if (autosaveRef.current) {
        const topicNames = selectedTopicsRef.current.map((topic) => topic.name);
        sendUpdatedTopics(topicNames);
      }
    },
    [],
  ); // Empty dependency array to run only on mount and unmount

  useEffect(() => {
    if (selectedTopicsSetter) {
      if (selectedTopics?.length) {
        const topicNames = selectedTopics.map((topic) => topic.name);
        selectedTopicsSetter(topicNames);
      } else {
        selectedTopicsSetter([]);
      }
    }
  }, [selectedTopics, selectedTopicsSetter]);

  const availableTopics = useMemo(() => {
    const selectedTopicNames = Array.from(selectedTopics)?.map(
      (topic) => topic.name,
    );
    return topicObjects?.filter(
      (topic) => !selectedTopicNames.includes(topic.name),
    );
  }, [selectedTopics, topicObjects]);

  const handleTopicToggle = useCallback(
    (topicName) => {
      // Update the topicObjects state to reflect the change
      const updatedTopicObjects = topicObjects.map((topic) => {
        if (topic.name === topicName) {
          return { ...topic, isActive: !topic.isActive };
        }
        return topic;
      });

      setTopicObjects(updatedTopicObjects);
    },
    [topicObjects],
  );

  const doGenerate = useCallback(async () => {
    setIsFetchingTopics(true);
    const generatedTopics = await fetchGeneratedTopics(targetAudience);

    // Construct new inactive topic objects
    const newTopicObjects = constructTopicObjects(generatedTopics);

    // Combine the new inactive topics with existing active topics
    const combinedTopics = [...selectedTopics, ...newTopicObjects];

    // Deduplicate the combined topics, just in case
    const deduplicatedTopics = Array.from(
      new Set(combinedTopics.map((topic) => topic.name)),
    ).map((name) => ({
      name,
      isActive: combinedTopics.some(
        (topic) => topic.name === name && topic.isActive,
      ),
    }));

    setTopicObjects(deduplicatedTopics);
    setIsFetchingTopics(false);
  }, [selectedTopics, targetAudience]);

  const handlePromptChange = useCallback(
    (newTargetAudience) => {
      setTargetAudience(newTargetAudience);
    },
    [setTargetAudience],
  );

  const list = [
    {
      id: "TopicPrompt",
      header: "Describe your audience",
      subheader: "",
      content: (
        <>
          <TopicPrompt onChange={handlePromptChange} onEnter={doGenerate} />
          <Box>
            <PButton
              onClick={doGenerate}
              disabled={isFetchingTopics || !targetAudience}
            >
              Submit
            </PButton>
          </Box>
        </>
      ),
      active: true,
    },
    {
      id: "AvailableTopics",
      header: "Topics for you to choose from",
      subheader: availableTopics?.length
        ? ""
        : "After you tell us about your audience, we'll suggest topics that they'll like for you to pick from.",
      content: (
        <TopicSelect
          topics={availableTopics}
          onToggle={handleTopicToggle}
          isFetchingTopics={isFetchingTopics}
        />
      ),
      active: topicObjects?.length > 0 && !isFetchingTopics,
    },
    {
      id: "SelectedTopics",
      header: "Topics you've selected",
      subheader: selectedTopics?.length
        ? ""
        : "Once you've selected topics, we'll use them to create your posts.",
      content: (
        <TopicConfirm
          selectedTopics={selectedTopics}
          onToggle={handleTopicToggle}
        />
      ),
      active: selectedTopics?.length > 0,
    },
  ];

  return (
    <>
      <Box sx={{ mb: "32px" }}>
        <PTypography size="h3" weight="bold">
          Prepare your messaging with your audience in mind.
        </PTypography>
      </Box>

      <PProgressVerticalStepper list={list} />
    </>
  );
}

TopicSelection.propTypes = {
  /**
   * Whether to autosave the selected topics.
   * If true, the selected topics will be saved to the user record
   * when this component unmounts.
   */
  autosave: PropTypes.bool,

  /**
   * Initial list of available topics.
   */
  initialAvailableTopics: PropTypes.arrayOf(PropTypes.string),

  /**
   * Initial list of selected topics.
   */
  initialSelectedTopics: PropTypes.arrayOf(PropTypes.string),

  /**
   * Callback setter for the selected topics.
   * Passes an array of strings - the names of the selected topics.
   */
  selectedTopicsSetter: PropTypes.func.isRequired,
};

TopicSelection.defaultProps = {
  autosave: false,
  initialAvailableTopics: [],
  initialSelectedTopics: [],
};

export default TopicSelection;
