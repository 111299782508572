export const updateStrategiesWithGifts = (strategies, defaultTemplates) => {
  return strategies.map((strategy) => {
    const hasGift = defaultTemplates.some((defaultTemplate) => {
      return (
        strategy.sequenceTemplate === defaultTemplate._id &&
        defaultTemplate.content.some((step) => step.step === 'gift')
      );
    });
    return { ...strategy, gift: hasGift };
  });
};
