import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { styled, useTheme  } from '@mui/material/styles';

const getTooltipVariantStyles = (theme, pVariant) => {
  switch (pVariant) {
    case 'black':
      return {
        backgroundColor: theme.palette.primaryCL.Black200,
        color: theme.palette.primaryCL.White110,
      };
    default:
      return {
        backgroundColor: theme.palette.primaryCL.Black200,
        color: theme.palette.primaryCL.White110,
      };
  }
};

const StyledTooltip = styled(({ className, pVariant, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, pVariant }) => {
  const variantStyles = getTooltipVariantStyles(theme, pVariant);
  return {
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: variantStyles.backgroundColor,
      borderRadius: '12px',
      color: variantStyles.color,
    },
    [`& .MuiTooltip-arrow`]: {
      color: 'transparent',
    },
  };
});

const PToolTip = ({ title, children, pVariant, placement }) => {
  const theme = useTheme();
  const variantStyles = getTooltipVariantStyles(theme, pVariant);
  const customTitleStyles = {
    color: variantStyles.color,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
  };

  const customContentStyles = {
    display: 'flex',
    height: '40px',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '12px'
  };

  const childStyles = {
    backgroundColor: 'transparent',
  };

  return (
    <StyledTooltip
      title={
        <div style={customContentStyles}>
          <span style={{ ...customTitleStyles }}>{title}</span>
        </div>
      }
      arrow
      followCursor
      placement={placement}
      pVariant={pVariant}
    >
      <div style={childStyles}>{children}</div>
    </StyledTooltip>
  );
}

PToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  pVariant: PropTypes.oneOf(['black']),
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

PToolTip.defaultProps = {
  pVariant: 'black',
  placement: 'bottom',
};

export default PToolTip;
