import React, { useState } from 'react';
import { Button, TextField, Box, Grid } from '@mui/material';
import { faker } from '@faker-js/faker';
import Http from '../../../http/httpClient';

function AcquisitionForm() {
  const [acquisition, setAcquisition] = useState({
    title: faker.company.name(),
    description: faker.company.catchPhrase(),
    acquirers: faker.company.name(),
    acquirersDomain: faker.internet.domainName(),
    acquirersUUID: faker.string.uuid(),
    acquirees: faker.company.name(),
    acquireesDomain: faker.internet.domainName(),
    acquireesUUID: faker.string.uuid(),
    date: new Date(),
  });

  const handleChange = (event) => {
    setAcquisition({
      ...acquisition,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Call API to save acquisition
    try {
      const response = await Http.postData('acquisition/admin/create', {
        acquisition,
      });
      if (response && response.status) {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
    console.log(acquisition);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="title"
            label="Title"
            value={acquisition.title}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="description"
            label="Description"
            value={acquisition.description}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="acquirers"
            label="Acquirers"
            value={acquisition.acquirers}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="acquirersDomain"
            label="Acquirers Domain"
            value={acquisition.acquirersDomain}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="acquirersUUID"
            label="Acquirers UUID"
            value={acquisition.acquirersUUID}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="acquirees"
            label="Acquirees"
            value={acquisition.acquirees}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="acquireesDomain"
            label="Acquirees Domain"
            value={acquisition.acquireesDomain}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="acquireesUUID"
            label="Acquirees UUID"
            value={acquisition.acquireesUUID}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="date"
            label="Date"
            value={acquisition.date}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Button type="submit" variant="contained" color="primary">
          Create Acquisition
        </Button>
      </Box>
    </Box>
  );
}

export default AcquisitionForm;
