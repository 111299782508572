import React from 'react';
import { TableRow, TableCell, Tooltip } from '@mui/material';

// Function to format date to a more readable format
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(date);
};

// Function to truncate text
const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const UserStat = React.memo(({ stats, handleCloseModal, buttonLoad }) => {
  const {
    user,
    company,
    logins,
    repliesPosted,
    postsPosted,
    repliesDeleted,
    postsDeleted,
    latestQueueFetch,
    recentlyUsedPostilize,
  } = stats;

  const truncatedUserId = truncateText(user.id, 22);

  return (
    <TableRow>
      <TableCell>{user.name}</TableCell>
      <TableCell>
        <Tooltip title={user.id} arrow>
          <span style={{ cursor: 'pointer' }}>{truncatedUserId}</span>
        </Tooltip>
      </TableCell>
      <TableCell>{company}</TableCell>
      <TableCell>{user.companyId}</TableCell>
      <TableCell>
        {Object.entries(user.stepStatus).map(([step, status]) => (
          <div key={step}>
            {step}: {status ? '✅' : '❌'}
          </div>
        ))}
      </TableCell>
      <TableCell>{recentlyUsedPostilize ? '✅' : '❌'}</TableCell>
      <TableCell>{formatDate(latestQueueFetch?.web?.latestFetchTime)}</TableCell>
      <TableCell>{formatDate(latestQueueFetch?.ios?.latestFetchTime)}</TableCell>
      <TableCell>{logins.length}</TableCell>
      <TableCell>{repliesPosted.length}</TableCell>
      <TableCell>{postsPosted.length}</TableCell>
      <TableCell>{repliesDeleted.length}</TableCell>
      <TableCell>{postsDeleted.length}</TableCell>
    </TableRow>
  );
});

export default UserStat;
