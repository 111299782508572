import React, { useEffect } from 'react';

import { CssBaseline, Stack, useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setSettingsPageOverride } from '@/redux/authSlice';

import Bio from './components/bio';
import CRM from './components/crm';
import Personas from './components/personas';
import Social from './components/social';
import Tone from './components/tone';
import Topics from './components/topics';
import Support from './components/support';
import Http from '../../http/httpClient';
import Lists from '../Lists/lists';

import PSectionNav from '../../components/library/PSectionNav';
import PButton from '../../components/library/PButton';
import Email from '@/pages/settings/components/email';

const Settings = () => {
  const dispatch = useDispatch();

  const [isChangesSaved, setIsChangesSaved] = React.useState(true);
  const [showToast, setShowToast] = React.useState(false);
  const [currPage, setCurrPage] = React.useState({
    title: 'About',
    page: <Bio setIsChangesSaved={setIsChangesSaved} />,
  });

  const [settingsOptions, setSettingsOptions] = React.useState([
    {
      label: 'About',
      page: <Bio setIsChangesSaved={setIsChangesSaved} />,
      onClick: () => handleListItemClick('About'),
    },
    { label: 'Personas', page: <Personas />, onClick: () => handleListItemClick('Personas') },
    { label: 'Tone', page: <Tone />, onClick: () => handleListItemClick('Tone') },
    { label: 'Topics', page: <Topics />, onClick: () => handleListItemClick('Topics') },
    { label: 'CRM', page: <CRM />, onClick: () => handleListItemClick('CRM') },
    { label: 'Social', page: <Social />, onClick: () => handleListItemClick('Social') },
    { label: 'List', page: <Lists />, onClick: () => handleListItemClick('List') },
    { label: 'Email', page: <Email />, onClick: () => handleListItemClick('Email') },
    { label: 'Support', page: <Support />, onClick: () => handleListItemClick('Support') },
  ]);

  const settingsPageOverride = useSelector((state) => state.auth.settingsPageOverride);
  useEffect(() => {
    if (settingsPageOverride) {
      const targetPage = settingsOptions.find((option) => option.label === settingsPageOverride);
      if (targetPage) {
        setCurrPage({ title: targetPage.label, page: targetPage.page });
      }
      dispatch(setSettingsPageOverride(null));
    }
  }, [dispatch, settingsOptions, settingsPageOverride]);

  const featureFlags = useSelector((state) => state.auth.featureFlags);
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');

    if (tab) {
      const selectedOption = settingsOptions.find((option) => option.label.toLowerCase() === tab.toLowerCase());
      if (selectedOption) {
        setCurrPage({ title: selectedOption.label, page: selectedOption.page });
      }
    }
  }, [settingsOptions]);

  const handleListItemClick = (label) => {
    if (!isChangesSaved) {
      setShowToast(true);
    } else {
      const selectedOption = settingsOptions.find((option) => option.label === label);
      if (selectedOption) {
        setCurrPage({ title: selectedOption.label, page: selectedOption.page });
      }
    }
  };

  React.useEffect(() => {
    if (!isChangesSaved && showToast) {
      setCurrPage({
        title: 'About',
        page: <Bio setIsChangesSaved={setIsChangesSaved} showToast={true} />,
      });

      setTimeout(() => {
        setCurrPage({
          title: 'About',
          page: <Bio setIsChangesSaved={setIsChangesSaved} showToast={false} />,
        });
        setShowToast(false);
      }, 4000);
    }
  }, [showToast]);

  const handleSignout = () => {
    Http.postData('/auth/logout').then((res) => {
      try {
        if (window?.location?.hostname === 'postilize-stage.herokuapp.com') window.checksumai?.reset();
      } catch (error) {
        console.error('Failed to reset checksumai user');
      }
      window.location.href = '/';
    });
  };

  React.useEffect(() => {
    // Remove 'List' from options if sequences flag is disabled
    const sequencesFlag = featureFlags?.find((x) => x.name === 'sequences');
    const listIndex = settingsOptions.findIndex((option) => option.label === 'List');
    if (!sequencesFlag?.isFeatureEnabled && listIndex !== -1) {
      setSettingsOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions.splice(listIndex, 1);
        return newOptions;
      });
    }

    // Remove 'Email' from options if userEmailOptions flag is disabled
    const userEmailOptionsFlag = featureFlags?.find((x) => x.name === 'userEmailOptions');
    const emailIndex = settingsOptions.findIndex((option) => option.label === 'Email');
    if (!userEmailOptionsFlag?.isFeatureEnabled && emailIndex !== -1) {
      setSettingsOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions.splice(emailIndex, 1);
        return newOptions;
      });
    }
  }, [featureFlags]);

  return (
    <>
      <CssBaseline />
      <Stack direction="row" sx={{ height: '100%' }}>
        <PSectionNav
          pVariant="primary"
          headerText="Settings"
          menu={settingsOptions.map((option) => ({
            ...option,
            active: option.label === currPage?.title,
          }))}
          width="325px"
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              paddingTop: '8px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PButton onClick={handleSignout} pVariant="secondary">
              Sign out
            </PButton>
          </div>
        </PSectionNav>

        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {currPage?.page || null}
        </div>
      </Stack>
    </>
  );
};

export default Settings;
