import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import PTextfield from './PTextfield';
import PDropdownVDeux from '@Library/PDropdownVDeux';

export default function PPriceRangeSelector({ initialOption, amount1, amount2, onChange, geoEnabled }) {
  const [option, setOption] = useState(initialOption || 'greater');
  const [amount1State, setAmount1] = useState(amount1 || '0');
  const [amount2State, setAmount2] = useState(amount2 || '');

  useEffect(() => {
    onChange(option, amount1State, amount2State || null);
  }, [option, amount1State, amount2State]);

  const handleOptionChange = (selectedOption) => {
    setOption(selectedOption);
    if (selectedOption === 'greater') {
      setAmount1('0');
      setAmount2('');
    } else if (selectedOption === 'less') {
      setAmount1('');
      setAmount2('');
    } else if (selectedOption === 'between') {
      setAmount1('');
      setAmount2('');
    }
  };

  const handleAmount1Change = (values) => {
    const { value } = values;
    setAmount1(value);
  };

  const handleAmount2Change = (values) => {
    const { value } = values;
    setAmount2(value);
  };

  const options = [
    { value: 'greater', label: 'Greater than' },
    { value: 'less', label: 'Less than' },
    { value: 'between', label: 'Between' },
  ];

  return (
    <Grid container spacing={0} alignItems="center" justifyContent={geoEnabled ? 'flex-start' : 'flex-end'}>
      {/* Dropdown Menu */}
      <Grid item xs={12} sm={4} sx={{ pl: 1 }}>
        <Box sx={{ width: '100%' }}>
          <PDropdownVDeux
            pVariant="grey"
            displayAs="text"
            buttonText="Select Range"
            inputValue={options.find((opt) => opt.value === option)?.label || 'Select Range'}
            menu={{
              menuList: options.map((opt) => ({
                isEnabled: true,
                label: opt.label,
                onSubmit: () => handleOptionChange(opt.value),
                isSelected: option === opt.value,
              })),
            }}
            closeWhenClicked={true}
          />
        </Box>
      </Grid>

      {/* Amount Input Fields */}
      {(option === 'greater' || option === 'less') && (
        <Grid item xs={12} sm={8} sx={{ pl: 1 }}>
          <Box sx={{ width: '100%' }}>
            <NumericFormat
              customInput={PTextfield}
              paperStyle={{ height: '48px', margin: '0px' }}
              placeholder="Amount"
              value={amount1State}
              onValueChange={handleAmount1Change}
              thousandSeparator=","
              prefix="$"
              allowNegative={false}
              decimalScale={2}
              fullWidth
            />
          </Box>
        </Grid>
      )}

      {option === 'between' && (
        <>
          <Grid item xs={12} sm={4} sx={{ pl: 1 }}>
            <Box sx={{ width: '100%' }}>
              <NumericFormat
                customInput={PTextfield}
                paperStyle={{ height: '48px', margin: '0px' }}
                placeholder="Minimum amount"
                value={amount1State}
                onValueChange={handleAmount1Change}
                thousandSeparator=","
                prefix="$"
                allowNegative={false}
                decimalScale={2}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ pl: 1 }}>
            <Box sx={{ width: '100%' }}>
              <NumericFormat
                customInput={PTextfield}
                paperStyle={{ height: '48px', margin: '0px' }}
                placeholder="Maximum amount"
                value={amount2State}
                onValueChange={handleAmount2Change}
                thousandSeparator=","
                prefix="$"
                allowNegative={false}
                decimalScale={2}
                fullWidth
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
}
