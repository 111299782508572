import React, { useState, useEffect } from 'react'
import { createPostTemplate, fetchPostTemplateById, updatePostTemplate } from './postTemplateService'
import { Alert, TextField, Button, Box, Typography, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { SocialNetworks } from '../../../constants/constant'
import { postTemplateSchema } from '../../../constants/payloadSchemas'

// Helper function to convert array to object
const initializeSocialNetworks = (networks) => {
  return networks.reduce((acc, network) => {
    acc[network] = true; // default each network to true
    return acc;
  }, {});
};

const PostTemplateForm = ({ templateId, setEditing }) => {
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [formState, setFormState] = useState({
    title: '',
    content: '',
    weight: 1,
    example: '',
    article: false,
    published: true,
    socialNetworks: initializeSocialNetworks(SocialNetworks)
  })

  useEffect(() => {
    if (templateId) {
      fetchPostTemplateById(templateId)
        .then(response => {
          const { title, content, weight, example, article, published, socialNetworks } = response;
          setFormState({
            title,
            content,
            weight,
            example,
            article: article ?? false,
            published: published ?? true,
            socialNetworks: socialNetworks || initializeSocialNetworks(SocialNetworks)
          });
        })
        .catch(error => console.error('Fetch error:', error));
    }
  }, [templateId]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target
    if (name in formState.socialNetworks) { // Handles social network checkboxes
      setFormState(prevState => ({
        ...prevState,
        socialNetworks: {
          ...prevState.socialNetworks,
          [name]: checked
        }
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { title, content, weight, example, article, published, socialNetworks } = formState;
    const submissionData = { title, content, weight, example, article, published, socialNetworks };

    const { error } = postTemplateSchema.validate(submissionData, { abortEarly: false });
    if (error) {
      const newErrors = error.details.reduce((acc, current) => {
        acc[current.path[0]] = current.message;
        return acc;
      }, {});
      setErrors(newErrors);
      return;
    }

    setErrors({});
    let response;
    if (templateId) {
      response = await updatePostTemplate(templateId, submissionData);
    } else {
      response = await createPostTemplate(submissionData);
    }

    if (!response || (response?.status && response?.status !== 200 && response?.status !== 201)) {
      setApiError('An error occurred while saving the template. Please try again.');
    } else {

      setEditing(false);
    }
  }

  return (
    <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Button
        variant='outlined'
        onClick={() => setEditing(false)} // Use setEditing to go back
        color='info'
        sx={{ m: 1 }}
      >
        Back
      </Button>
      <Typography variant='h6' gutterBottom>{templateId ? 'Edit Post Template' : 'Create Post Template'}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Title'
            name='title'
            value={formState.title}
            onChange={handleChange}
            variant='outlined'
            color='secondary'
            required
            error={!!errors.title}
            helperText={errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Content'
            name='content'
            value={formState.content}
            onChange={handleChange}
            variant='outlined'
            multiline
            minRows={3}
            color='secondary'
            required
            error={!!errors.content}
            helperText={errors.content}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Weight'
            name='weight'
            type='number'
            value={formState.weight}
            onChange={handleChange}
            variant='outlined'
            color='secondary'
            required
            error={!!errors.weight}
            helperText={errors.weight}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox checked={formState.article} onChange={handleChange} name='article' color='secondary' />}
            label={
              <Typography variant='body2'>
                This is an article post
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox checked={formState.published} onChange={handleChange} name='published' color='secondary' />}
            label={
              <Typography variant='body2'>
                Active
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            Social Networks
          </Typography>
        </Grid>
        {Object.keys(formState.socialNetworks).map(network => (
          <Grid item xs={12} sm={6} key={network}>
            <FormControlLabel
              control={<Checkbox
                checked={formState.socialNetworks[network]}
                onChange={handleChange}
                name={network}
                color='secondary'
              />}
              label={network.charAt(0).toUpperCase() + network.slice(1)} // Capitalize the first letter
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Example Post (Optional)'
            name='example'
            value={formState.example}
            onChange={handleChange}
            variant='outlined'
            color='secondary'
            required={false}
            minRows={3}
            multiline
            error={!!errors.example}
            helperText={errors.example}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' variant='contained' color='info' sx={{ mr: 1 }}>
              Save Template
            </Button>
            <Button variant='outlined' color='secondary' onClick={() => setEditing(false)}>
              Cancel
            </Button>
          </Box>
        </Grid>
        {apiError && (
          <Alert severity='error' sx={{ width: '100%', mt: 2 }}>
            {apiError}
          </Alert>
        )}
      </Grid>
    </Box>
  )
}

export default PostTemplateForm
