import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';

import Http from '../../../http/httpClient'

export default function TwitterTokenReplacer () {
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState('')
  const [token, setToken] = React.useState('')
  const [bearerTokens, setBearerTokens] = React.useState([])
  const [replace, setReplace] = React.useState(false)

  const replaceBearerTokens = (event) => {
    event.preventDefault()

    setIsLoading(true)
    Http.postData('twitterBearerTokens/update', { oldToken: selectedOption, newToken: token })
      .then((res) => {
        // handle if anything required
        const index = bearerTokens.indexOf(selectedOption)
        if (index !== -1) { bearerTokens[index] = token }
        setSelectedOption('')
        setToken('')
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
        setReplace(false)
      })
  }

  const handleReset = (event) => {
    event.preventDefault()

    setSelectedOption('')
    setToken('')
    setReplace(false)
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
    setReplace((event.target.value.length > 0 && token.length > 0))
  }

  const handleTokenChange = (event) => {
    setToken(event.target.value)
    setReplace((event.target.value.length > 0 && selectedOption.length > 0))
  }

  React.useEffect(() => {
    const fetchAllTwitterBearerTokens = async () => {
      Http.getData('twitterBearerTokens/list')
        .then((res) => {
          setBearerTokens(res.bearerTokens)
        })
        .catch((error) => {
          console.error("Error while fetching twitter bearer tokens. ", error)
        })
    }

    fetchAllTwitterBearerTokens()
  }, [])

  return (
    <>
      <Box alignItems='center' gap={2} flexDirection='row'>
        <Typography sx={{ mt: 0,
                          mx: 3,
                          fontSize: '1.0rem',
                          fontFamily: 'Poppins' }}
                          >
          Old Token:
        </Typography>
        <Select
          value={selectedOption}
          onChange={handleOptionChange}
          sx={{ mt: 1, mx: 3, minWidth: 1000 }}
        >
          {bearerTokens.map((bearerToken) => (
            <MenuItem key={bearerToken} value={bearerToken}>
              {bearerToken}
            </MenuItem>
          ))}
        </Select>

        <Typography sx={{ mt: 5,
                          mx: 3,
                          fontSize: '1.0rem',
                          fontFamily: 'Poppins' }}
                          >
          New Token:
        </Typography>
        <TextField id="new-token"
                   variant="outlined"
                   value={token}
                   sx={{ mt: 1,
                          mx: 3,
                          minWidth: 1000,
                          fontSize: '1.0rem',
                          fontFamily: 'Poppins' }}
                    onChange={handleTokenChange} />

        <Box sx={{ mt: 3 }}>
          <Button
            disabled={isLoading ? 'disabled' : ''}
            variant="outlined"
            color="error"
            sx={{ mt: 3,
                  mx: 3,
                  fontSize: '1.2rem',
                  textTransform: 'none',
                  fontFamily: 'Poppins'
                }}
            onClick={handleReset}
          > Reset </Button>
          <Button
            disabled={replace ? '' : 'disabled'}
            variant="contained"
            sx={{ mt: 3,
                  mx: 1,
                  fontSize: '1.2rem',
                  textTransform: 'none',
                  fontFamily: 'Poppins'
                }}
            onClick={replaceBearerTokens}
          >
            {isLoading
              ? (
                <Box
                  sx={{
                    position: 'relative',
                    width: '52px',
                    height: '34px',
                    display: 'flex',
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress variant='outlined' color='info'/>
                </Box>
                )
              : (
                  'Replace'
                )}
          </Button>
        </Box>
      </Box>
    </>
  )
}
