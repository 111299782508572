import * as React from 'react';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

import ConnectListItem from '../../setup/simpleSetupComponents/connectListItem';

import { ReactComponent as Salesforce } from '../../../assets/logos/Salesforce.svg'
import { ReactComponent as Affinity } from '../../../assets/logos/Affinity.svg'
import { ReactComponent as Hubspot } from '../../../assets/logos/Hubspot.svg'

import { Providers } from '../../../constants/constant'
import { GetCookie } from '../../../helper/cookieManager'

export default function CRM(props) {
	const theme = useTheme()

	const [providerList, setProviderList] = React.useState([
    {
      title: 'Salesforce',
      logo: <Salesforce />,
      provider: Providers.SALESFORCE,
      connected: null
    }, {
      title: 'Affinity',
      logo: <Affinity />,
      provider: Providers.AFFINITY,
      connected: null
    }, {
      title: 'HubSpot',
      logo: <Hubspot />,
      provider: Providers.HUBSPOT,
      connected: null
    }
  ])

  async function getConnectedState (provider) {
    const luser = GetCookie('user')
    if(luser) {
      const p = luser.providers.find(acc => acc !== null && acc.provider === provider) || []
      if (p && p.profileId) {
        return { connected: true,
          profileId: p.profileId,
          primary: p.primary,
          profileImage: (p && p.imageUrl ) ? p.imageUrl : luser.imageUrl
        }
      }
    }
    return { connected: false, profileId: null, primary: null, profileImage: null }
  }

  const fetchData = async () => {
    const updatedList = await Promise.all(providerList.map(async (item) => {
      const profile = await getConnectedState(item.provider);
      return {
        ...item,
        connected: profile.connected,
        primary: profile.primary,
        profileId: profile.profileId,
        profileImage: profile.profileImage
     	};
   	}));

    setProviderList(updatedList)
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    const bc = new BroadcastChannel("settingsCRMAuthChannel");
    bc.addEventListener('message', (event) => {
      if (event.data.status === 200) {
        fetchData()
      }
    })

    return () => {
      bc.removeEventListener('message', () => {});
      bc.close();
    };
  }, [])

	return (
		<>
			<Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 5,pl: {xs: 5},
          mt: { xs: 4 },
          lineHeight: 'normal' }}>
				Connect your CRM
			</Typography>
			<Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5, pl: { xs: 5 } }}>
				This will help you identify your most important connections.
			</Typography>

			<Grid container spacing={2} sx={{ pl: { xs: 5, sm: 2, lg: 5 } }}>
				{providerList.map((provider) => (
          <Grid key={`${provider.title}${provider.connected}${new Date().toString()}`} item xs={12} lg={3} sx={{mx: {xs: "30px", lg: "2px"}}}>
            <ConnectListItem currProvider={provider} source={'settingsAuth'}/>
          </Grid>
        ))}
			</Grid>
		</>
	);
}