/* app guide content for tones page */
const toneSteps = [{
    id: 'concise',
    label: 'Concise'
  }, {
    id: '#step-button-continue',
    label: 'Continue',
    btnType: 'continue'
  }, {
    id: '#step-save-button',
    label: 'Save',
    btnType: 'save'
  }]

const generateContent = (label, type) => {
  let content = String.empty
  if (type === 'continue') {
    content = "When you're ready to continue, click the 'continue' button to proceed."
  } else {
    content = `Let's get you set up! First, set the sliders to adjust your tone. Whether you like to make people laugh, or stir the pot, postilize will create posts to your personal tone.`
  }

  return content
}

export const toneTourSteps = toneSteps.map((step) => ({
  target: ['concise'].includes(step.id) ? `#step-${step.id}-slider` : step.id,
  content: generateContent(step.label, step.btnType),
  disableBeacon: true
}))

/* app guide content for topics page */
export const topicSteps = [{
    target: '#step-choose-topic',
    content: 'Next, we\'ll select some topics that you like to post about. Simply click on any of our options...',
     disableBeacon: true
  }, {
    target: '#step-add-topic',
    content: "Or input your own! Type in your topic here, and then press the enter key or the add button to the left, and we'll take care of the rest."
  }, {
    target: '#step-trending-topic',
    content: "Select the trending topics you want to generate posts for. The chosen topics will reset every 24 hours. Click the 'Continue' button to proceed."
  }, {
    target: '#step-button-continue',
    content: "Once you've set up your topics, press continue to proceed."
  }, {
    target: '#step-button-back',
    content: "Or, click the back button to go back to make some changes."
  }, {
    target: '#step-save-button',
    content: "Simply click the 'Save' button to effortlessly update your selection and generate the captivating post for the selected topic."
  }]

/* app guide content for crm account page */
export const importListSteps = [{
    target: '#step-account-card',
    content: 'Here we show your connected CRM accounts.',
    disableBeacon: true
  }, {
    target: '#step-provider-connected',
    content: "Indicated that this particular provider was connected or not."
  }, {
    target: '#step-provider-disconnect',
    content: "Click this menu to disconnect the contact provider from your account. Please note that this action will permanently erase all data related to this particular provider."
  }, {
    target: '#step-connect-provider',
    content: "Click the 'Connect' button to authorize us to import your contacts and keep you connected."
  }, {
    target: '#step-button-continue',
    content: "Once you're done connecting your accounts, press the 'Continue' button to proceed."
  }]

/* app guide content for filters page */
export const filtersSteps = [{
    target: '#step-followers-slider',
    content: "Use the sliders to filter the Twitter followers. We'll import your top contacts and start formulating tailor made responses to their posts.",
    disableBeacon: true
  }, {
    target: '#step-followers-label',
    content: "You can set whatever ratio you'd like of the top people you follow, or your top followers that you haven't leveraged yet."
  }, {
    target: '#step-accordion-salesforce-expand',
    content: "Click this icon to configure the Salesforce contacts filter."
  }, {
    target: '#step-salesforce-filters',
    content: "Customize the Salesforce contact filter by selecting the corresponding checkboxes. The focus contacts will be set based on your selections here."
  }, {
    target: '#step-accordion-salesforce-collapse',
    content: "Click this icon to collapse the Salesforce contacts filter."
  }, {
    target: '#step-accordion-hubspot-expand',
    content: "Click this icon to configure the hubspot contacts filter."
  }, {
    target: '#step-hubspot-filters',
    content: "Customize the hubspot contact filter by selecting the corresponding checkboxes. The focus contacts will be set based on your selections here."
  }, {
    target: '#step-accordion-hubspot-collapse',
    content: "Click this icon to collapse the hubspot contacts filter."
  }, {
    target: '#step-accordion-google-expand',
    content: "Click this icon to configure the google contacts filter."
  }, {
    target: '#step-google-filters',
    content: "Customize the google contact filter by selecting the corresponding checkboxes. The focus contacts will be set based on your selections here."
  }, {
    target: '#step-accordion-google-collapse',
    content: "Click this icon to collapse the google contacts filter."
  }, {
    target: '#step-button-continue',
    content: "Click the 'Continue' button to proceed. Almost done!"
  },  {
    target: '#step-save-button',
    content: "With a simple click on the 'Save' button, effortlessly refine your contacts filter."
  }]

/* app guide content for focus contact page */
export const focusContactsSteps = [{
    target: '#step-focus-checkbox-0',
    content: "We've found some contacts! Contacts with a checked box are your focus contacts. These are the people we'll be focusing your interactions on.",
    disableBeacon: true
  }, {
    target: '#step-contact-provider-0',
    content: "Here's where we found your contact. We may have them across multiple services"
  }, {
    target: '#step-advance-button',
    content: "Click the 'Advanced' button for additional filters and search options."
  }, {
    target: '#step-button-continue',
    content: "Once you've selected your contacts, press here to get started!."
  }, {
    target: '#step-button-back',
    content: "Or, feel free to go back and make some adjustments before we get start generating your posts. Don't worry, you can return to these pages at any time."
  }]

/* app guide content for focus contact advanced options */
export const fcAdvancedOptionSteps = [{
    selector: '#step-search-contact',
    content: "Search for the desired focus contact using their name."
  }, {
    selector: '#step-filter-contact',
    content: "Filter the contacts based on their enrichment or focus status."
  }, {
    selector: '#step-filter-provider',
    content: "Filter the contacts based on their provider."
  }, {
    selector: '#step-sort-contacts',
    content: "Sort the contacts based on their Name or Company."
  }, {
    selector: '#step-add-new-contact-button',
    content: "Click the 'Add New' button to add an additional Twitter contact."
  }]

/* app guide content for social account page */
export const socialAccountSteps = [{
    target: '#step-social-account-card',
    content: 'Connect your social media accounts for better engagement. Here you can see all of our supported social networks.',
    disableBeacon: true
  }, {
    target: '#step-provider-disconnect',
    content: "Click this menu to disconnect the contact provider from your account. Please note that this action will erase all data we have currently, but you can always reconnect in the future."
  }, {
    target: '#step-connect-provider',
    content: "Click the 'Connect' button to associate a data provider with your account. This will authenticate with your provider and retrieve your contacts."
  }, {
    target: '#step-button-continue',
    content: "Click the 'Continue' button to proceed."
  }]

/* app guide content for post queue page */
export const postQueueSteps = [{
    selector: '#step-leadership-post-0',
    content: "Discover AI-generated leadership posts tailored to your chosen topics. Each day, new posts are created based on your configured preferences."
  },{
    selector: '#step-leadership-post-action-regenerate-0',
    content: "Click the sync icon to 'Regenerate' the AI-generated leadership post if you are not satisfied with the current one."
  }, {
    selector: '#step-leadership-post-action-edit-0',
    content: "Click the edit icon to 'Edit' the AI-generated leadership post if you want to add or remove anything from the current post."
  }, {
    selector: '#step-leadership-post-action-delete-0',
    content: "Click the delete icon to 'Remove' the AI-generated leadership post from the list if you are not happy with the existing post."
  }, {
    selector: '#step-leadership-post-action-publish-0',
    content: "Click the 'Publish' button to publish the specific social engagement post."
  }, {
    selector: '#step-social-post-0',
    content: "We'll also generate replies to your Focus Contacts who've made posts in the last 24 hours to keep you up to date."
  }, {
    selector: '#step-social-post-like-0',
    content: "Click here to Like the post."
  }, {
    selector: '#step-social-post-retweet-0',
    content: "Click here to Repost"
  },  {
    selector: '#step-social-post-publish-0',
    content: "Click here to send your response."
  }, {
    selector: '#step-social-post-regenerate-0',
    content: "If you're unhappy with this response, we can also regenerate the reply"
  }, {
    selector: '#step-social-post-edit-0',
    content: "Or you can edit the post directly."
  }, {
    selector: '#step-social-post-delete-0',
    content: "If this isn't a post you'd like to respond to, click here to delete it."
  }, {
    selector: '#step-mass-publish',
    content: "If everything looks good, click here to publish it all! We'll stagger your posts and replies, and get everything queued up."
  }, {
    selector: '#step-add-custom-post',
    content: "If you'd like to post about a specific topic or article, you can generate a post on the fly."
  }, {
    selector: '#step-trending-topics',
    content: "Keep current with trending topics! We'll let you know what's hot, and formulate some posts for you"
  }]

/* app guide content for add post modal page */
export const addPostModalSteps = [{
    selector: '#step-add-modal-topic',
    content: 'Input a URL to a news post, or a topic you\'d like to post about, and we\'ll get started!'
  }, {
    selector: '#step-choose-image',
    content: "Select and upload the image or gif to catch people's eye. Note that currently, we only support image uploads for LinkedIn ."
  },]

/* app guide content for edit post modal page */
export const editPostModalSteps = [{
    selector: '#step-edit-modal-content',
    content: 'Refine your post with ease. Feel free to add or remove content from this text field as you edit.'
  }, {
    selector: '#step-adjust-post-tone',
    content: "Customize the tone to craft an AI-based post or response. This step is optional, but it adds a personalized touch."
  }, {
    selector: '#step-adjust-post-length',
    content: "Select your preferred post length. Customize it to be longer or shorter, depending on your preference."
  }, {
    selector: '#step-adjust-post-hashtag',
    content: "Tailor your hashtag preference. Choose between 'ON' to include hashtags or 'OFF' to exclude them in the generated post."
  }, {
    selector: '#step-adjust-post-account',
    content: "Specify the social platform your post is targeted for."
  }, {
    selector: '#step-adjust-post-rephrase',
    content: "Click 'Rephrase' for a preview. If you prefer not to preview, you can directly click the 'Save' button."
  }, {
    selector: '#step-post-save',
    content: "Simply click the 'Save' button to add the newly AI-generated post to the queue."
  }]

/* app guide content for cadence sequence modal page */
export const cadenceSequenceSteps = [{
    target: '#step-create-sequence-btn',
    content: "Click the 'Create Sequence' button to add a sequence.",
    disableBeacon: true
  }, {
    target: '#step-sequence-card-0',
    content: 'You can see the basic details about the created cadence sequence.'
  }, {
    target: '#step-sequence-card-edit-0',
    content: "To edit the created sequence details, click the edit icon."
  },{
    target: '#step-sequence-card-delete-0',
    content: "Click the delete icon to 'Remove' the desired sequence."
  }]

/* app guide content for cadence sequence create modal page */
export const cadenceSequenceCreateSteps = [{
    selector: '#step-sequence-title',
    content: 'Enter the title for the new sequence that you want to create.'
  }, {
    selector: '#step-sequence-description',
    content: 'Give a brief description of the new sequence that needs to be created.'
  }, {
    selector: '#step-sequence-contacts',
    content: 'Select the contacts you want to schedule the cadence email for. Only the email address associated focus contacts will be listed here.'
  }, {
    selector: '#step-sequence-startdate',
    content: 'Select the start date and time for the sequence, which determines when it should start.'
  }, {
    selector: '#step-sequence-enddate',
    content: 'Choose the end date and time for the sequence, which determines its validity until when.'
  }]

/* app guide content for cadence sequence edit modal page */
export const cadenceSequenceEditSteps = [{
    selector: '#step-sequence-edit-title',
    content: 'Edit the title of the sequence you wish to modify.'
  }, {
    selector: '#step-sequence-edit-description',
    content: 'Edit the description of the sequence that you want to modify.'
  }, {
    selector: '#step-sequence-edit-contacts',
    content: 'Select or deselect the contacts you want to schedule the cadence email for. Only the focus contacts with an email address will be listed here.'
  }, {
    selector: '#step-sequence-edit-startdate',
    content: 'Update the start time for the sequence, which determines when it should start the pocess.'
  }, {
    selector: '#step-sequence-edit-enddate',
    content: 'Update the end time for the sequence, which determines its validity until when.'
  }, {
    selector: '#step-sequence-edit-status',
    content: 'The status of the sequence. You can enable or disable a sequence using this control.'
  }]

/* app guide content for cadence sequence step create modal page */
export const cadenceSequenceStepCreate = [{
    selector: '#step-sequence-step-title',
    content: 'Enter the title for the new sequence step that you want to create.'
  }, {
    selector: '#step-sequence-step-type',
    content: 'Select the sequence step type you wish to schedule.'
  }]

export const cadenceSequenceStepEdit = [{
  selector: '#step-sequence-step-edit-title',
  content: 'Enter the title for the sequence step that you wist to modify.'
}, {
  selector: '#step-sequence-step-edit-type',
  content: 'Select the sequence step type you wish to schedule.'
}]

export const cadenceEmailGuide = [{
    selector: '#step-sequence-step-scheduleAt',
    content: 'Select the schedule time for the sequence step, which determines when the email notification should be triggered.'
  }, {
    selector: '#step-sequence-step-subject',
    content: 'Enter the subject for the cadence step email.'
  }, {
    selector: '#step-sequence-step-template',
    content: 'Enter the required email template for the cadence step email.'
  }]

export const cadenceEmailTemplateGuide = [{
    selector: '#step-sequence-step-template-preview',
    content: 'Preview of how the cadence email will be delivered.'
  }]
