import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { ReactComponent as LoginLogo } from "../../assets/Icons/LoginLogo.svg";
import { ReactComponent as LogoIcon } from '../../assets/Icons/LogoIcon.svg';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Black200,
      };
      break;

    // Add more cases for 'outlined', 'plain', etc.
  }

  return colorScheme;
};


function PLogo({ pVariant, onClick, showLabel }) {
  const theme = useTheme();
  const colorScheme = getVariantStyles(theme, pVariant);

  return (
    <Stack direction="row" onClick={onClick} gap="4px" alignItems="center">
      {showLabel ? <LoginLogo width={120} height={25} /> : <LogoIcon width={120} height={20} />}
    </Stack>
  );
}

PLogo.propTypes = {
  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary']),

  /** Click handler */
  onClick: PropTypes.func.isRequired,
};

PLogo.defaultProps = {
  pVariant: 'primary',
  showLabel: true,
};

export default PLogo;
