import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import PTypography from '@Library/PTypography';
import PDropdownVDeux from '@Library/PDropdownVDeux';
import { Box } from '@mui/system';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { TopicType } from '@/constants/constant';

const PPostCardCommonContent = ({
  children,
  avatarNode = null,
  headerNode = null,
  post,
  setPost,
  handleSendToMessage,
}) => {
  const featureFlags = useFeatureFlags();
  const contactDetails = post?.sequenceAttr?.contactDetails || {};
  const [selectedPlatform, setSelectedPlatform] = useState(post.messagingPlatform || 'Email');
  const messagePlatformEnabled = featureFlags.some(
    (flag) => flag.name === 'ui.sendToMessagePlatform' && flag.isFeatureEnabled,
  );
  useEffect(() => {
    if (post.messagingPlatform) {
      setSelectedPlatform(post.messagingPlatform);
    }
  }, [post.messagingPlatform]);
  const onDropdownChange = (platform) => {
    setSelectedPlatform(platform);
    if (handleSendToMessage) {
      handleSendToMessage(post, platform, setPost);
    }
  };

  const menuList = [];

  if (contactDetails.email) {
    menuList.push({
      isEnabled: true,
      label: 'Email',
      onSubmit: () => onDropdownChange('Email'),
    });
  }

  if (contactDetails.linkedin) {
    menuList.push({
      isEnabled: true,
      label: 'LinkedIn',
      onSubmit: () => onDropdownChange('LinkedIn'),
    });
  }

  if (contactDetails.phone) {
    menuList.push({
      isEnabled: true,
      label: 'iMessage',
      onSubmit: () => onDropdownChange('iMessage'),
    });
  }

  const handleDropdownClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const buttonText = selectedPlatform ? `${selectedPlatform}` : '';

  return (
    <Stack direction="row" spacing="12px" maxWidth="100%">
      {avatarNode}
      <Stack direction="column" spacing="4px" justifyContent="space-between" width="100%">
        <Stack direction="row" spacing="12px" justifyContent="space-between" width="100%">
          {headerNode}
          {post?.scheduledDate && messagePlatformEnabled && post.topic_type !== TopicType.LINKEDIN_EMAIL && (
            <PTypography size="footnote" weight="regular">
              <PDropdownVDeux
                buttonText={buttonText}
                closeWhenClicked={true}
                menu={{
                  headerText: '',
                  menuList: menuList,
                }}
                pSize="small"
                pVariant="secondary"
                onClick={handleDropdownClick}
              />
            </PTypography>
          )}
        </Stack>
        <Box sx={{ maxWidth: 'calc(100% - 43px)' }}>{children}</Box>
      </Stack>
    </Stack>
  );
};

PPostCardCommonContent.propTypes = {
  avatarNode: PropTypes.node,
  headerNode: PropTypes.node,
  post: PropTypes.object.isRequired,
  setPost: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  handleSendToMessage: PropTypes.func.isRequired,
};

export default PPostCardCommonContent;
