import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

const PublishModal = ({ open, onClose, onSubmit, posts }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);

  const now = dayjs(new Date())
  const minStartTime = posts && typeof posts === 'object' && posts.post_create_date ? dayjs(new Date(posts.post_create_date)) : now
  const defaultStartTime = minStartTime.add(1, 'minute')
  const defaultEndDate = minStartTime.add(1, 'day')
  const [selectedDate, setSelectedDate] = useState(now.add(1, 'minute'))
  const [startTime, setStartTime] = useState(defaultStartTime)
  const [endTime, setEndTime] = useState(defaultEndDate)
  const [dateTimeError, setDateTimeError] = useState(null)

  const handlePublishNow = () => {
    onSubmit(posts, 'immediate');
    onClose();
  };

  const handlePublishLater = () => {
    setShowDatePicker(true);
  };

  const handleSubmit = () => {
    if (!dateTimeError) {
      onSubmit(posts, "scheduled", selectedDate);
      onClose();
    } else {
      alert('The selected time is outside the available window. Please choose another time.')
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth="md" sx={{ overflowX: 'hidden' }}>
      <DialogTitle sx={{ overflowX: 'hidden', fontFamily: 'Poppins' }}>
        Publish Options
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ alignItems: 'center', overflowX: 'hidden' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" gap={2}>
            <Button sx={{
                  px: 3,
                  color: '#FFFFFF',
                  backgroundColor: '#3983F7',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  alignItems: 'center',
                  fontSize: 13,
                  borderRadius: 8,
                  border: '2px #3983F7 solid',
                  my: 1,
                  mx: 1,
                  '&:hover': {
                    backgroundColor: '#CEDBED',
                    color: '#3983F7',
                    border: '2px #3983F7 solid'
                  }
                }} onClick={handlePublishNow}>
              Publish Now
            </Button>
            <Button sx={{
              px:3,
                          color: '#3983F7',
                          backgroundColor: '#FFFFFF',
                          fontWeight: 'bold',
                          textTransform: 'none',
                          alignItems: 'center',
                          fontSize: 13,
                          borderRadius: 8,
                          border: '2px #3983F7 solid',
                          my: 1,
                          mx: 1,
                          '&:hover': {
                            backgroundColor: '#3983F7',
                            color: 'white'
                          }
                        }} onClick={handlePublishLater}>
              Publish Later
            </Button>
          </Box>
          {showDatePicker && (
            <Box mt={2} sx={{ overflowX: 'hidden', pt: 2, pb: 3 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Select Date"
                  value={selectedDate}
                  disablePast={true}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 1}}
                  minDateTime={defaultStartTime}
                  maxDateTime={defaultEndDate}
                  onError={(newError) => {
                    setDateTimeError(newError)
                  }}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
                <Button sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#3983F7',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  alignItems: 'center',
                  fontSize: 13,
                  borderRadius: 8,
                  border: '2px #3983F7 solid',
                  my: 1,
                  mx: 1,
                  '&:hover': {
                    backgroundColor: '#CEDBED',
                    color: '#3983F7',
                    border: '2px #3983F7 solid'
                  }
                }} onClick={handleSubmit}>
                  Submit
                </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PublishModal;