import * as React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import Http from '../../../http/httpClient'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { Table, TableCell, TableHead, TableRow } from '@mui/material'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

export default function DeletedPosts () {
  const [isLoading, setIsLoading] = React.useState(true)
  const [posts, setPosts] = React.useState([])

  React.useEffect(() => {
    const fetchDeletedPosts = async () => {
      setIsLoading(true)
      Http.getData('deletedPosts/list')
        .then((res) => {
          console.log(JSON.stringify(res))
          setPosts(res.delPosts)
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          setIsLoading(false)
        })
    }

    fetchDeletedPosts()
  }, [])

  return (
    <>
      {
        isLoading
          ? <Box sx={{ display: 'flex' }}>
            <CircularProgress />
            </Box>
          : <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label='customized table'>
              {(!posts || posts.length ==  0) ?
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={"7"}>
                        <Typography align='center' gutterBottom>
                          There is no deleted posts available.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </> :
                <>
                  <TableHead>
                    <TableRow>
                      {Object.keys(posts[0]).map((key) => (
                        <StyledTableCell>{key}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {posts.map((post) => (
                      <TableRow>
                        {Object.values(post).map((val) => (
                          <TableCell>{JSON.stringify(val)}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              }
            </Table>
            </TableContainer>
        }
    </>
  )
}
