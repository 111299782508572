import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PContactCardInfo from '@Library/PContactCardInfo';
import { handleContactRecommendation } from '@/helper/apiHelper';
import { useSnackbar } from '@/contexts/SnackbarContext';

export default function ContactCard({ contactPosts, setPosts, refreshPost, updateFilterPosts }) {
  const { addSnackbar } = useSnackbar();

  useEffect(() => {
    if (Array.isArray(contactPosts)) {
      setPosts((prevPosts) => {
        const nonRecommendedPosts = prevPosts.filter((post) => !post.recommendationKey);
        const recommendedPosts = contactPosts.filter((post) => post.recommendationKey);
        const isDifferent =
          JSON.stringify(recommendedPosts) !== JSON.stringify(prevPosts.filter((post) => post.recommendationKey));
        return isDifferent ? [...nonRecommendedPosts, ...recommendedPosts] : prevPosts;
      });
    }
  }, [contactPosts, setPosts]);

  // Function to handle adding or removing contact recommendations
  const handleContactAction = async (contact) => {
    const { recommendationKey, action } = contact;

    try {
      let response;
      if (action === 'Approve') {
        response = await handleContactRecommendation(recommendationKey, 'accept');
      } else if (action === 'Delete') {
        response = await handleContactRecommendation(recommendationKey, 'reject');
      }

      if (response?.result === true) {
        setPosts((prevPosts) => prevPosts.filter((post) => post.recommendationKey !== recommendationKey));
        if (updateFilterPosts) {
          updateFilterPosts((prevFilterPosts) =>
            prevFilterPosts.filter((post) => post.recommendationKey !== recommendationKey),
          );
        }
        addSnackbar({
          message: action === 'Approve' ? 'Contact added successfully!' : 'Contact removed successfully!',
          pVariant: 'success',
        });

        if (refreshPost) {
          refreshPost();
        }
      } else {
        throw new Error('Action failed');
      }
    } catch (error) {
      addSnackbar({
        message: `Failed to ${action === 'Approve' ? 'add' : 'remove'} contact`,
        pVariant: 'destructive',
      });
    }
  };

  return (
    <Box>
      {contactPosts?.length > 0 &&
        contactPosts.map((post, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              paddingLeft: '14px',
              paddingRight: '14px',
              paddingTop: '28px',
            }}
          >
            <PContactCardInfo
              sequenceDetails={post.sequenceDetails}
              contact={{ ...post.contactDetails, sequenceName: post?.sequenceDetails?.sequenceName }}
              recommendationKey={post.recommendationKey}
              onClick={handleContactAction}
            />
          </div>
        ))}
    </Box>
  );
}

ContactCard.propTypes = {
  contactPosts: PropTypes.array.isRequired,
  setPosts: PropTypes.func.isRequired,
  refreshPost: PropTypes.func.isRequired,
  updateFilterPosts: PropTypes.func.isRequired,
};
