import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import FundzUploadData from './fundzUploadData';
import FundzTestData from './FundzTestData';
import FundzDataRecordMatches from './FundzDataRecordMatches';

export default function ManageFundz() {
  const [selectedFundzTab, setSelectedFundzTab] = React.useState('Fundz');

  const handleTabChange = (event, selectedTab) => {
    setSelectedFundzTab(selectedTab);
  };

  let content;
  switch (selectedFundzTab) {
    case 'FundzTestData':
      content = <FundzTestData />;
      break;
    case 'FundzUploadData':
      content = <FundzUploadData />;
      break;
    case 'FundzDataRecordMatches':
      content = <FundzDataRecordMatches />;
      break;
    default:
      content = <FundzUploadData />;
      break;
  }

  const tabs = [
    { label: 'Fundz Upload Data', value: 'FundzUploadData' },
    { label: 'Create Fundz Test', value: 'FundzTestData' },
    { label: 'Fundz Data Record Matches', value: 'FundzDataRecordMatches' },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Toolbar />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedFundzTab} onChange={handleTabChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              value={tab.value}
              sx={{
                backgroundColor: selectedFundzTab === tab.value ? '#C7C7C7' : 'inherit',
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Toolbar />
      {content}
    </Box>
  );
}
