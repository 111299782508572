import * as React from 'react'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Http from '../../http/httpClient'

export default function PurchaseLicense () {
  const [email, setEmail] = React.useState('')
  const [isEmailValid, setIsEmailValid] = React.useState(true)

  const handleEmailChange = (event) => {
    let emailAddress = event.target.value
    if (emailAddress.length == 0) {
      setEmail(emailAddress)
      setIsEmailValid(true)
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isValid = emailRegex.test(emailAddress)
      if (isValid) { setEmail(emailAddress) }
      setIsEmailValid(isValid)
    }
  }

  const handleEmailUpdate = () => {
    if (email.length === 0) {
      alert('Please enter a valid email.')
      return
    }

    let url = window.location.href
    let params = url.split('?')[1]
    let query = params.split('&')
    let state = query[0].split('=')[1]
    let code = query[1].split('=')[1]
    Http.postDataAnonymously('userOnboarding/updateContactEmail', { userData: {email: email, state: state, code: code} })
      .then((res) => {
        alert('Email updated successfully')
        let index = url.indexOf("/support")
        if (index !== -1) {
          let homepage = url.slice(0, index)
          console.log(homepage)
          window.location.replace(homepage)
        }
      })
      .catch((error) => {
        console.log(error)
        alert(error)
      }).finally(() => {
        setEmail('')
      })
  }

  return (
    <Container component='main' maxWidth='lg' sx={{ mb: 4, backgroundColor: '#F3F3F3' }}>
      <Paper elevation={0} sx={{ my: { md: 6 }, p: { md: 3 }, backgroundColor: '#F3F3F3' }}>
        <Typography
            variant='h4'
            sx={{
              py: 1,
              fontFamily: 'Poppins',
              color: '#000000'
            }}
          >
            Your license is about to expire!
          </Typography>
          <Typography
            variant='h6'
            paragraph
            sx={{
              py: 1,
              fontFamily: 'Poppins',
              color: '#000000'
            }}
          >
            To extend your license validity, you can take one of the following steps:
            <ul>
            <li><a href="https://calendly.com/mark-timmons/30min">Set up a meeting with us here</a> and we'll get you licensed.</li>
            <li>Send an email to sales@postilize.com to set up a time that works for you.</li>
            {/*<li>Input your email here, and we'll reach out to get you started.</li> */}
            </ul>
          </Typography>
            {/* <Box sx={{ mt: 3, mb: 3, width: '100%' }}>
            <TextField
                id='support-user-email'
                variant='outlined'
                placeholder='Enter a valid e-mail'
                onChange={handleEmailChange}
                error={!isEmailValid}
              />
            <Button
              sx={{ mx: 3, height: 55, width: '20%' }}
              variant='contained'
              disabled={!isEmailValid ? 'disabled' : ''}
              onClick={handleEmailUpdate}
            >
              Submit
            </Button>
          </Box> */}
      </Paper>
    </Container>
  )
}
