import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import TwitterContent from './twitterContent'
import TwitterTokenReplacer from './twitterTokenReplacer'

export default function UserContents () {
  const [selectedTab, setSelectedTab] = React.useState('TwitterContent')

  const handleTabChange = (event, selected) => {
    setSelectedTab(selected)
  }

  let content
  switch (selectedTab) {
    case 'TwitterContent':
      content = <TwitterContent />
      break
    case 'TwitterTokenReplacer':
      content = <TwitterTokenReplacer />
      break
    default:
      content = <TwitterContent />
      break
  }

  return (
    <>
      <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant='fullWidth'
          aria-label='user content menu'
        >
          <Tab label='Twitter Posts' value='TwitterContent' />
          <Tab label='Twitter Tokens' value='TwitterTokenReplacer' />
        </Tabs>
      </Box>
      <Toolbar />
      <>
        {content}
      </>
    </>
  )
}
