import * as React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import PLogo from './PLogo';
import PSideMenuUser from './PSideMenuUser';
import PSideMenuItem from './PSideMenuItem';
import VZeroSideMenuItem from '@/v0/VZeroSideMenuItem';

import { ReactComponent as SendIcon } from '../../assets/Icons/Send.svg';
import POnboardingCompletionPanel from './POnboardingCompletionPanel';
import PLinkedInNoticeArea from './PLinkedInNoticeArea';
import { VZeroOnboardingCompletionPanel } from '@/v0/VZeroOnboardingCompletionPanel';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
    default:
      colorScheme = {
        background: theme.palette.primaryCL.White110,
        stroke: theme.palette.primaryCL.Black70,
      };
      break;
  }

  return colorScheme;
};

const StyledPDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'pVariant',
})(({ theme, pVariant, full }) => {
  const { background } = getVariantStyles(theme, pVariant);

  return {
    'flexShrink': 0,
    'minHeight': '50vh',
    '& .MuiDrawer-paper': {
      width: full ? '350px' : '88px',
      backgroundColor: background,
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid',
      borderColor: '#E3E3E3',
      // "@media (max-width: 1280px)": {
      //   width: "88px",
      // },
    },
  };
});

function PSidebar({ pVariant, full, user, userOnClick, postOnClick, menu, menuOnClick }) {
  const theme = useTheme();

  return (
    <StyledPDrawer variant="permanent" disableElevation pVariant={pVariant} full={full}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          paddingTop: '28px',
          paddingLeft: '14px',
          paddingRight: '14px',
        }}
      >
        <div
          style={{
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingBottom: '32px',
            display: 'flex',
            justifyContent: full ? 'flex-start' : 'center',
            width: '100%',
          }}
        >
          <PLogo showLabel={full} />
        </div>
        {menu.map((item, index) => (
          <VZeroSideMenuItem
            active={item.active}
            onClick={() => menuOnClick(item.title, item.hasSubmenu, item.page)}
            showLabel={full}
            Icon={item.icon}
          >
            {item.title}
          </VZeroSideMenuItem>
        ))}
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '16px 0' }}>
          <PSideMenuItem pVariant="black" onClick={() => postOnClick()} showLabel={full} Icon={SendIcon} post={true}>
            Write Post
          </PSideMenuItem>
        </div>

        <VZeroOnboardingCompletionPanel />
        <PLinkedInNoticeArea />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingBottom: '28px',
            paddingLeft: '14px',
            paddingRight: '14px',
            marginTop: 'auto',
          }}
        >
          <PSideMenuUser showLabel={full} children={user.name} subtitle={user.type} />
        </div>
      </div>
    </StyledPDrawer>
  );
}

PSidebar.propTypes = {
  /** Postilize design variant, separate from MUI button variant. MUI button variant is determined automatically */
  pVariant: PropTypes.oneOf(['primary']),

  /** Whether or not the sidebar is collapsed */
  full: PropTypes.bool,

  /** User object */
  user: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),

  /** Menu items */
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.elementType,
      page: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),

  /** Click handler */
  onClick: PropTypes.func.isRequired,
};

PSidebar.defaultProps = {
  pVariant: 'primary',
  full: 'true',
  user: { name: 'User Name', type: 'User Type' },
};

export default PSidebar;
