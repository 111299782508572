import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { styled, useTheme } from '@mui/material/styles';

import PButton from './PButton';
import PTypography from './PTypography';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
    default:
      colorScheme = {
        text: theme.palette.primaryCL.Black100,
        background: theme.palette.primaryCL.Black50,
        borderColor: theme.palette.primaryCL.Black70,
      };
      break;

    // Add more cases for 'outlined', 'plain', etc.
  }

  return colorScheme;
};

function PSectionNavHeader({ pVariant, onClick, buttonText, onClick2, buttonText2, buttonTheme, buttonTheme2, headerText }) {
  const theme = useTheme();
  const variantStyles = getVariantStyles(theme, pVariant);

  return (
    <Container sx={{ width: '100%', borderBottom: '1px solid', borderBottomColor: variantStyles.borderColor, display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, backgroundColor: variantStyles.background }}>
      {headerText && (
        <div style={{paddingTop: '24px', paddingBottom: '24px'}}>
          <PTypography weight="bold" size="h2">
            {headerText}
          </PTypography>
        </div>
      )}
      {buttonText && (
        <PButton onClick={onClick} pVariant={buttonTheme}>
          {buttonText}
        </PButton>
      )}
      {buttonText2 && (
        <PButton onClick={onClick2} pVariant={buttonTheme2}>
          {buttonText2}
        </PButton>
      )}
    </Container>
  );
}

PSectionNavHeader.propTypes = {
  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary']),

  /** Header Text */
  headerText: PropTypes.string,

  /** Text for the button */
  buttonText: PropTypes.string,

  /** Theme for the button */
  buttonTheme: PropTypes.oneOf(['primary', 'secondary', 'outlined', 'plain', 'black']),

  /** Click handler */
  onClick: PropTypes.func.isRequired,
};

PSectionNavHeader.defaultProps = {
  pVariant: 'primary',
  buttonTheme: 'primary'
};

export default PSectionNavHeader;
