import React, { useState, useEffect } from 'react'
import { Box, MenuItem, Select, CircularProgress, Button,
         Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
         Paper, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
         Grid, List, ListItem, ListItemText, Divider,  } from '@mui/material'

import Http from '../../../http/httpClient'
import CustomSnackBar from './../../../components/CustomSnackBar'
import { Severity } from './../../../constants/constant'

const attributeTextValue = Object.freeze({
  'twitterAutoLike': 'Twitter Auto Like',
  'twitterAutoRetweet': 'Twitter Auto Retweet',
  'twitterAutoResponse': 'Twitter Auto Response',
  'reqTwitterAutoLike': 'Request Twitter Auto Like',
  'reqTwitterAutoRetweet': 'Request Twitter Auto Retweet',
  'reqTwitterAutoResponse': 'Request Twitter Auto Response',
})

function PostActionItem ({name, value, handleChange}) {
  return (
    <ListItem
      secondaryAction={
        <Checkbox
          edge='end'
          name={name}
          defaultChecked={value}
          onChange={handleChange}
        />
      }
    >
      <ListItemText
        primary={attributeTextValue[name]}
      />
    </ListItem>
  )
}

function CompanyPostActions ({open, actions, onClose, handlePostActionsChange }) {
  const [postActions, setPostActions] = useState(actions)

  const handleChange = (e) => {
    let newActions = {...postActions}
    newActions[e.target.name] = e.target.checked
    setPostActions(prevActions => newActions)
  }

  const handleSubmit = () => {
    handlePostActionsChange(postActions)
  }

  return (
    <>
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
        <DialogTitle
          sx={{
            fontWeight: 800,
            fontSize: 18,
            letterSpacing: '-0.02em',
            py: 3,
          }}
        >
          Company Post Actions
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', m: 1, px: 3 }}>
                {postActions && Object.keys(postActions).map((actionName) => (
                  <PostActionItem
                    key={actionName}
                    name={actionName}
                    value={postActions[actionName]}
                    handleChange={handleChange}
                  />
                ))}
              </List>
          </Grid>
        </DialogContent>
        <Divider sx={{ mx: 3 }} />
        <DialogActions>
          <Button
            onClick={() => {
              onClose()
              setPostActions(actions)
            }}
            sx={{
              mx: 1,
              my: 1,
              backgroundColor: '#EEEEEE',
              color: '#09092E',
              fontSize: '1.2rem',
              textTransform: 'none',
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              handleSubmit()
              onClose()
            }}
            sx={{
              mr: 3,
              ml: 1,
              my: 1,
              fontSize: '1.2rem',
              textTransform: 'none',
            }}
          >Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default function UserPreferences () {
  const [users, setUsers] = useState([])
  const [usersLoading, setUsersLoading] = useState(true)
  const [selectedUser, setSelectedUser] = useState('')
  const [preferences, setPreferences] = useState(null)
  const [preferenceLoading, setPreferenceLoading] = useState(false)
  const [openPostActionDialog, setOpenPostActionDialog] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const [snackmessage, setSnackmessage] = React.useState('')
  const [severity, setSeverity] = React.useState(Severity.SUCCESS)

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      Http.getData('users/list')
        .then((res) => {
          const resUsers = []
          res.users.map((item) => {
            resUsers.push({ id: item._id.toString(), name: item.name, email: item.email })
          })
          setUsers(resUsers)
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          setUsersLoading(false)
        })
    }

    fetchAvailableUsers()
  }, [])

  React.useEffect(() => {
    const fetchUserPreference = async () => {
      setPreferenceLoading(true)
      Http.getData(`user/${selectedUser}/preference`)
        .then((res) => {
          if (res && res.userPreference !== undefined) {
            setPreferences(res.userPreference)
          }
        })
        .catch((error) => {
          console.error('Error fetching user preference:', error)
        })
        .finally(() => {
          setPreferenceLoading(false)
        })
    }

    if (selectedUser.length > 0) {
      fetchUserPreference()
    }
  }, [selectedUser])

  const handleUserChange = (e) => {
    setSelectedUser(prev => e.target.value)
  }

  const handleSnackClose = () => {
    setOpenSnack(false)
  }

  const handlePostActionConfigClick = () => {
    setOpenPostActionDialog(true)
  }

  const handlePostActionConfigClose = () => {
    setOpenPostActionDialog(false)
  }

  const handlePostActionsChange = (actions) => {
    const {_id, __v, userId, ...updatedPreferences} =  preferences
    updatedPreferences.companyPostActions = actions

    Http.postData(`user/${selectedUser}/preference`, { updatedPreferences })
      .then((res) => {
        if (res.status === true) {
          setSnackmessage('User preference saved successfully!')
          setSeverity(Severity.SUCCESS)
        } else {
          setSnackmessage('Unable to save user preference!')
          setSeverity(Severity.SUCCESS)
        }
        setOpenSnack(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <Box display='flex' flexDirection='column' gap={2} alignItems='flex-start' sx={{ width: '100%' }}>
        <Box sx={{ flexGrow: 1, mx: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            { usersLoading ?
              (
                <Box
                  sx={{
                    position: 'relative',
                    width: '52px',
                    height: '34px',
                    display: 'flex',
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress color='inherit' />
                </Box>
              ) :
              (
                <>
                  <Select
                    value={selectedUser}
                    onChange={handleUserChange}
                    sx={{ minWidth: 200 }}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name} - {user.email}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )
            }
          </Box>
          { preferenceLoading ?
              (
                <Box
                  sx={{
                    position: 'relative',
                    width: '52px',
                    height: '34px',
                    display: 'flex',
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress color='inherit' />
                </Box>
              ) :
              (preferences &&
                <Box sx={{ my: 2, mr: 3, p: 2 }} >
                  <TableContainer component={Paper} sx={{maxHeight: 400}} >
                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="preference table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="left">Configuration</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={'Company Post Actions'}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Company Post Actions
                          </TableCell>
                          <TableCell align="left">
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={4}>
                                <Button
                                  variant='contained'
                                  sx={{
                                    mt: 1,
                                    px: 1,
                                    textTransform: 'none',
                                    fontFamily: 'Poppins'
                                  }}
                                  onClick={handlePostActionConfigClick}
                                >
                                  Config
                                </Button>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <CompanyPostActions
                            open={openPostActionDialog}
                            actions={preferences.companyPostActions}
                            onClose={handlePostActionConfigClose}
                            handlePostActionsChange={handlePostActionsChange}
                            />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )
            }
        </Box>
        <CustomSnackBar
          open={openSnack}
          onClose={handleSnackClose}
          message={snackmessage}
          severity={severity}
        />
      </Box>
    </>
  )
}
